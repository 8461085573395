:root, .light, .light-theme {
  --gray-1: #fcfcfc;
  --gray-2: #f9f9f9;
  --gray-3: #f0f0f0;
  --gray-4: #e8e8e8;
  --gray-5: #e0e0e0;
  --gray-6: #d9d9d9;
  --gray-7: #cecece;
  --gray-8: #bbb;
  --gray-9: #8d8d8d;
  --gray-10: #838383;
  --gray-11: #646464;
  --gray-12: #202020;
  --gray-a1: rgba(0, 0, 0, .01);
  --gray-a2: rgba(0, 0, 0, .024);
  --gray-a3: rgba(0, 0, 0, .06);
  --gray-a4: rgba(0, 0, 0, .09);
  --gray-a5: rgba(0, 0, 0, .12);
  --gray-a6: rgba(0, 0, 0, .15);
  --gray-a7: rgba(0, 0, 0, .192);
  --gray-a8: rgba(0, 0, 0, .267);
  --gray-a9: rgba(0, 0, 0, .447);
  --gray-a10: rgba(0, 0, 0, .486);
  --gray-a11: rgba(0, 0, 0, .608);
  --gray-a12: rgba(0, 0, 0, .875);
  --mauve-1: #fdfcfd;
  --mauve-2: #faf9fb;
  --mauve-3: #f2eff3;
  --mauve-4: #eae7ec;
  --mauve-5: #e3dfe6;
  --mauve-6: #dbd8e0;
  --mauve-7: #d0cdd7;
  --mauve-8: #bcbac7;
  --mauve-9: #8e8c99;
  --mauve-10: #84828e;
  --mauve-11: #65636d;
  --mauve-12: #211f26;
  --mauve-a1: rgba(85, 0, 85, .01);
  --mauve-a2: rgba(43, 0, 85, .024);
  --mauve-a3: rgba(48, 0, 64, .063);
  --mauve-a4: rgba(32, 0, 54, .094);
  --mauve-a5: rgba(32, 0, 56, .125);
  --mauve-a6: rgba(20, 0, 53, .153);
  --mauve-a7: rgba(16, 0, 51, .196);
  --mauve-a8: rgba(8, 0, 49, .27);
  --mauve-a9: rgba(5, 0, 29, .45);
  --mauve-a10: rgba(5, 0, 25, .49);
  --mauve-a11: rgba(4, 0, 17, .61);
  --mauve-a12: rgba(2, 0, 8, .88);
  --slate-1: #fcfcfd;
  --slate-2: #f9f9fb;
  --slate-3: #f0f0f3;
  --slate-4: #e8e8ec;
  --slate-5: #e0e1e6;
  --slate-6: #d9d9e0;
  --slate-7: #cdced6;
  --slate-8: #b9bbc6;
  --slate-9: #8b8d98;
  --slate-10: #80838d;
  --slate-11: #60646c;
  --slate-12: #1c2024;
  --slate-a1: rgba(0, 0, 85, .01);
  --slate-a2: rgba(0, 0, 85, .024);
  --slate-a3: rgba(0, 0, 51, .06);
  --slate-a4: rgba(0, 0, 45, .09);
  --slate-a5: rgba(0, 9, 50, .12);
  --slate-a6: rgba(0, 0, 47, .15);
  --slate-a7: rgba(0, 6, 46, .196);
  --slate-a8: rgba(0, 8, 48, .275);
  --slate-a9: rgba(0, 5, 29, .455);
  --slate-a10: rgba(0, 7, 27, .498);
  --slate-a11: rgba(0, 7, 20, .624);
  --slate-a12: rgba(0, 5, 9, .89);
  --sage-1: #fbfdfc;
  --sage-2: #f7f9f8;
  --sage-3: #eef1f0;
  --sage-4: #e6e9e8;
  --sage-5: #dfe2e0;
  --sage-6: #d7dad9;
  --sage-7: #cbcfcd;
  --sage-8: #b8bcba;
  --sage-9: #868e8b;
  --sage-10: #7c8481;
  --sage-11: #5f6563;
  --sage-12: #1a211e;
  --sage-a1: rgba(0, 128, 64, .016);
  --sage-a2: rgba(0, 64, 32, .03);
  --sage-a3: rgba(0, 45, 30, .067);
  --sage-a4: rgba(0, 31, 21, .098);
  --sage-a5: rgba(0, 24, 8, .125);
  --sage-a6: rgba(0, 20, 13, .157);
  --sage-a7: rgba(0, 20, 10, .204);
  --sage-a8: rgba(0, 15, 8, .28);
  --sage-a9: rgba(0, 17, 11, .475);
  --sage-a10: rgba(0, 16, 10, .514);
  --sage-a11: rgba(0, 10, 7, .627);
  --sage-a12: rgba(0, 8, 5, .898);
  --olive-1: #fcfdfc;
  --olive-2: #f8faf8;
  --olive-3: #eff1ef;
  --olive-4: #e7e9e7;
  --olive-5: #dfe2df;
  --olive-6: #d7dad7;
  --olive-7: #cccfcc;
  --olive-8: #b9bcb8;
  --olive-9: #898e87;
  --olive-10: #7f847d;
  --olive-11: #60655f;
  --olive-12: #1d211c;
  --olive-a1: rgba(0, 85, 0, .01);
  --olive-a2: rgba(0, 73, 0, .027);
  --olive-a3: rgba(0, 32, 0, .063);
  --olive-a4: rgba(0, 22, 0, .094);
  --olive-a5: rgba(0, 24, 0, .125);
  --olive-a6: rgba(0, 20, 0, .157);
  --olive-a7: rgba(0, 15, 0, .2);
  --olive-a8: rgba(4, 15, 0, .28);
  --olive-a9: rgba(5, 15, 0, .47);
  --olive-a10: rgba(4, 14, 0, .51);
  --olive-a11: rgba(2, 10, 0, .627);
  --olive-a12: rgba(1, 6, 0, .89);
  --sand-1: #fdfdfc;
  --sand-2: #f9f9f8;
  --sand-3: #f1f0ef;
  --sand-4: #e9e8e6;
  --sand-5: #e2e1de;
  --sand-6: #dad9d6;
  --sand-7: #cfceca;
  --sand-8: #bcbbb5;
  --sand-9: #8d8d86;
  --sand-10: #82827c;
  --sand-11: #63635e;
  --sand-12: #21201c;
  --sand-a1: rgba(85, 85, 0, .01);
  --sand-a2: rgba(37, 37, 0, .027);
  --sand-a3: rgba(32, 16, 0, .063);
  --sand-a4: rgba(31, 21, 0, .098);
  --sand-a5: rgba(31, 24, 0, .13);
  --sand-a6: rgba(25, 19, 0, .16);
  --sand-a7: rgba(25, 20, 0, .208);
  --sand-a8: rgba(25, 21, 1, .29);
  --sand-a9: rgba(15, 15, 0, .475);
  --sand-a10: rgba(12, 12, 0, .514);
  --sand-a11: rgba(8, 8, 0, .63);
  --sand-a12: rgba(6, 5, 0, .89);
  --amber-1: #fefdfb;
  --amber-2: #fefbe9;
  --amber-3: #fff7c2;
  --amber-4: #ffee9c;
  --amber-5: #fbe577;
  --amber-6: #f3d673;
  --amber-7: #e9c162;
  --amber-8: #e2a336;
  --amber-9: #ffc53d;
  --amber-10: #ffba18;
  --amber-11: #ab6400;
  --amber-12: #4f3422;
  --amber-a1: rgba(192, 128, 0, .016);
  --amber-a2: rgba(244, 209, 0, .086);
  --amber-a3: rgba(255, 222, 0, .24);
  --amber-a4: rgba(255, 212, 0, .39);
  --amber-a5: rgba(248, 207, 0, .533);
  --amber-a6: rgba(234, 181, 0, .55);
  --amber-a7: rgba(220, 155, 0, .616);
  --amber-a8: rgba(218, 138, 0, .79);
  --amber-a9: rgba(255, 179, 0, .76);
  --amber-a10: rgba(255, 179, 0, .906);
  --amber-a11: #ab6400;
  --amber-a12: rgba(52, 21, 0, .867);
  --blue-1: #fbfdff;
  --blue-2: #f4faff;
  --blue-3: #e6f4fe;
  --blue-4: #d5efff;
  --blue-5: #c2e5ff;
  --blue-6: #acd8fc;
  --blue-7: #8ec8f6;
  --blue-8: #5eb1ef;
  --blue-9: #0090ff;
  --blue-10: #0588f0;
  --blue-11: #0d74ce;
  --blue-12: #113264;
  --blue-a1: rgba(0, 128, 255, .016);
  --blue-a2: rgba(0, 140, 255, .043);
  --blue-a3: rgba(0, 143, 245, .098);
  --blue-a4: rgba(0, 158, 255, .165);
  --blue-a5: rgba(0, 147, 255, .24);
  --blue-a6: rgba(0, 136, 246, .325);
  --blue-a7: rgba(0, 131, 235, .443);
  --blue-a8: rgba(0, 132, 230, .63);
  --blue-a9: #0090ff;
  --blue-a10: rgba(0, 134, 240, .98);
  --blue-a11: rgba(0, 109, 203, .95);
  --blue-a12: rgba(0, 35, 89, .933);
  --bronze-1: #fdfcfc;
  --bronze-2: #fdf7f5;
  --bronze-3: #f6edea;
  --bronze-4: #efe4df;
  --bronze-5: #e7d9d3;
  --bronze-6: #dfcdc5;
  --bronze-7: #d3bcb3;
  --bronze-8: #c2a499;
  --bronze-9: #a18072;
  --bronze-10: #957468;
  --bronze-11: #7d5e54;
  --bronze-12: #43302b;
  --bronze-a1: rgba(85, 0, 0, .01);
  --bronze-a2: rgba(204, 51, 0, .04);
  --bronze-a3: rgba(146, 37, 0, .082);
  --bronze-a4: rgba(128, 40, 0, .125);
  --bronze-a5: rgba(116, 35, 0, .173);
  --bronze-a6: rgba(115, 36, 0, .227);
  --bronze-a7: rgba(108, 31, 0, .298);
  --bronze-a8: rgba(103, 28, 0, .4);
  --bronze-a9: rgba(85, 26, 0, .553);
  --bronze-a10: rgba(76, 21, 0, .592);
  --bronze-a11: rgba(61, 15, 0, .67);
  --bronze-a12: rgba(29, 6, 0, .83);
  --brown-1: #fefdfc;
  --brown-2: #fcf9f6;
  --brown-3: #f6eee7;
  --brown-4: #f0e4d9;
  --brown-5: #ebdaca;
  --brown-6: #e4cdb7;
  --brown-7: #dcbc9f;
  --brown-8: #cea37e;
  --brown-9: #ad7f58;
  --brown-10: #a07553;
  --brown-11: #815e46;
  --brown-12: #3e332e;
  --brown-a1: rgba(170, 85, 0, .01);
  --brown-a2: rgba(170, 85, 0, .035);
  --brown-a3: rgba(160, 75, 0, .094);
  --brown-a4: rgba(155, 74, 0, .15);
  --brown-a5: rgba(159, 77, 0, .208);
  --brown-a6: rgba(160, 78, 0, .282);
  --brown-a7: rgba(163, 78, 0, .376);
  --brown-a8: rgba(159, 74, 0, .506);
  --brown-a9: rgba(130, 60, 0, .655);
  --brown-a10: rgba(114, 51, 0, .675);
  --brown-a11: rgba(82, 33, 0, .725);
  --brown-a12: rgba(20, 6, 0, .82);
  --crimson-1: #fffcfd;
  --crimson-2: #fef7f9;
  --crimson-3: #ffe9f0;
  --crimson-4: #fedce7;
  --crimson-5: #facedd;
  --crimson-6: #f3bed1;
  --crimson-7: #eaacc3;
  --crimson-8: #e093b2;
  --crimson-9: #e93d82;
  --crimson-10: #df3478;
  --crimson-11: #cb1d63;
  --crimson-12: #621639;
  --crimson-a1: rgba(255, 0, 85, .01);
  --crimson-a2: rgba(224, 0, 64, .03);
  --crimson-a3: rgba(255, 0, 82, .086);
  --crimson-a4: rgba(248, 0, 81, .137);
  --crimson-a5: rgba(229, 0, 79, .192);
  --crimson-a6: rgba(208, 0, 75, .255);
  --crimson-a7: rgba(191, 0, 71, .325);
  --crimson-a8: rgba(182, 0, 74, .424);
  --crimson-a9: rgba(226, 0, 91, .76);
  --crimson-a10: rgba(215, 0, 86, .796);
  --crimson-a11: rgba(196, 0, 79, .886);
  --crimson-a12: rgba(83, 0, 38, .914);
  --cyan-1: #fafdfe;
  --cyan-2: #f2fafb;
  --cyan-3: #def7f9;
  --cyan-4: #caf1f6;
  --cyan-5: #b5e9f0;
  --cyan-6: #9ddde7;
  --cyan-7: #7dcedc;
  --cyan-8: #3db9cf;
  --cyan-9: #00a2c7;
  --cyan-10: #0797b9;
  --cyan-11: #107d98;
  --cyan-12: #0d3c48;
  --cyan-a1: rgba(0, 153, 204, .02);
  --cyan-a2: rgba(0, 157, 177, .05);
  --cyan-a3: rgba(0, 194, 209, .13);
  --cyan-a4: rgba(0, 188, 212, .208);
  --cyan-a5: rgba(1, 180, 204, .29);
  --cyan-a6: rgba(0, 167, 193, .384);
  --cyan-a7: rgba(0, 159, 187, .51);
  --cyan-a8: rgba(0, 163, 192, .76);
  --cyan-a9: #00a2c7;
  --cyan-a10: rgba(0, 148, 183, .973);
  --cyan-a11: rgba(0, 116, 145, .937);
  --cyan-a12: rgba(0, 50, 62, .95);
  --gold-1: #fdfdfc;
  --gold-2: #faf9f2;
  --gold-3: #f2f0e7;
  --gold-4: #eae6db;
  --gold-5: #e1dccf;
  --gold-6: #d8d0bf;
  --gold-7: #cbc0aa;
  --gold-8: #b9a88d;
  --gold-9: #978365;
  --gold-10: #8c7a5e;
  --gold-11: #71624b;
  --gold-12: #3b352b;
  --gold-a1: rgba(85, 85, 0, .01);
  --gold-a2: rgba(157, 138, 0, .05);
  --gold-a3: rgba(117, 96, 0, .094);
  --gold-a4: rgba(107, 78, 0, .14);
  --gold-a5: rgba(96, 70, 0, .19);
  --gold-a6: rgba(100, 68, 0, .25);
  --gold-a7: rgba(99, 66, 0, .333);
  --gold-a8: rgba(99, 61, 0, .447);
  --gold-a9: rgba(83, 50, 0, .604);
  --gold-a10: rgba(73, 45, 0, .63);
  --gold-a11: rgba(54, 33, 0, .706);
  --gold-a12: rgba(19, 12, 0, .83);
  --grass-1: #fbfefb;
  --grass-2: #f5fbf5;
  --grass-3: #e9f6e9;
  --grass-4: #daf1db;
  --grass-5: #c9e8ca;
  --grass-6: #b2ddb5;
  --grass-7: #94ce9a;
  --grass-8: #65ba74;
  --grass-9: #46a758;
  --grass-10: #3e9b4f;
  --grass-11: #2a7e3b;
  --grass-12: #203c25;
  --grass-a1: rgba(0, 192, 0, .016);
  --grass-a2: rgba(0, 153, 0, .04);
  --grass-a3: rgba(0, 151, 0, .086);
  --grass-a4: rgba(0, 159, 7, .145);
  --grass-a5: rgba(0, 147, 5, .21);
  --grass-a6: rgba(0, 143, 10, .3);
  --grass-a7: rgba(1, 139, 15, .42);
  --grass-a8: rgba(0, 141, 25, .604);
  --grass-a9: rgba(0, 134, 25, .725);
  --grass-a10: rgba(0, 123, 23, .757);
  --grass-a11: rgba(0, 101, 20, .835);
  --grass-a12: rgba(0, 32, 6, .875);
  --green-1: #fbfefc;
  --green-2: #f4fbf6;
  --green-3: #e6f6eb;
  --green-4: #d6f1df;
  --green-5: #c4e8d1;
  --green-6: #adddc0;
  --green-7: #8eceaa;
  --green-8: #5bb98b;
  --green-9: #30a46c;
  --green-10: #2b9a66;
  --green-11: #218358;
  --green-12: #193b2d;
  --green-a1: rgba(0, 192, 64, .016);
  --green-a2: rgba(0, 163, 47, .043);
  --green-a3: rgba(0, 164, 51, .098);
  --green-a4: rgba(0, 168, 56, .16);
  --green-a5: rgba(1, 156, 57, .23);
  --green-a6: rgba(0, 150, 60, .32);
  --green-a7: rgba(0, 145, 64, .443);
  --green-a8: rgba(0, 146, 75, .643);
  --green-a9: rgba(0, 143, 74, .81);
  --green-a10: rgba(0, 134, 71, .83);
  --green-a11: rgba(0, 113, 63, .87);
  --green-a12: rgba(0, 38, 22, .9);
  --indigo-1: #fdfdfe;
  --indigo-2: #f7f9ff;
  --indigo-3: #edf2fe;
  --indigo-4: #e1e9ff;
  --indigo-5: #d2deff;
  --indigo-6: #c1d0ff;
  --indigo-7: #abbdf9;
  --indigo-8: #8da4ef;
  --indigo-9: #3e63dd;
  --indigo-10: #3358d4;
  --indigo-11: #3a5bc7;
  --indigo-12: #1f2d5c;
  --indigo-a1: rgba(0, 0, 128, .008);
  --indigo-a2: rgba(0, 64, 255, .03);
  --indigo-a3: rgba(0, 71, 241, .07);
  --indigo-a4: rgba(0, 68, 255, .118);
  --indigo-a5: rgba(0, 68, 255, .176);
  --indigo-a6: rgba(0, 62, 255, .243);
  --indigo-a7: rgba(0, 55, 237, .33);
  --indigo-a8: rgba(0, 52, 220, .447);
  --indigo-a9: rgba(0, 49, 210, .757);
  --indigo-a10: rgba(0, 46, 201, .8);
  --indigo-a11: rgba(0, 43, 183, .773);
  --indigo-a12: rgba(0, 16, 70, .88);
  --iris-1: #fdfdff;
  --iris-2: #f8f8ff;
  --iris-3: #f0f1fe;
  --iris-4: #e6e7ff;
  --iris-5: #dadcff;
  --iris-6: #cbcdff;
  --iris-7: #b8baf8;
  --iris-8: #9b9ef0;
  --iris-9: #5b5bd6;
  --iris-10: #5151cd;
  --iris-11: #5753c6;
  --iris-12: #272962;
  --iris-a1: rgba(0, 0, 255, .008);
  --iris-a2: rgba(0, 0, 255, .027);
  --iris-a3: rgba(0, 17, 238, .06);
  --iris-a4: rgba(0, 11, 255, .098);
  --iris-a5: rgba(0, 14, 255, .145);
  --iris-a6: rgba(0, 10, 255, .204);
  --iris-a7: rgba(0, 8, 230, .28);
  --iris-a8: rgba(0, 8, 217, .392);
  --iris-a9: rgba(0, 0, 192, .643);
  --iris-a10: rgba(0, 0, 182, .682);
  --iris-a11: rgba(6, 0, 171, .675);
  --iris-a12: rgba(0, 2, 70, .847);
  --jade-1: #fbfefd;
  --jade-2: #f4fbf7;
  --jade-3: #e6f7ed;
  --jade-4: #d6f1e3;
  --jade-5: #c3e9d7;
  --jade-6: #acdec8;
  --jade-7: #8bceb6;
  --jade-8: #56ba9f;
  --jade-9: #29a383;
  --jade-10: #26997b;
  --jade-11: #208368;
  --jade-12: #1d3b31;
  --jade-a1: rgba(0, 192, 128, .016);
  --jade-a2: rgba(0, 163, 70, .043);
  --jade-a3: rgba(0, 174, 72, .098);
  --jade-a4: rgba(0, 168, 81, .16);
  --jade-a5: rgba(0, 162, 85, .235);
  --jade-a6: rgba(0, 154, 87, .325);
  --jade-a7: rgba(0, 148, 95, .455);
  --jade-a8: rgba(0, 151, 110, .663);
  --jade-a9: rgba(0, 145, 107, .84);
  --jade-a10: rgba(0, 135, 100, .85);
  --jade-a11: rgba(0, 113, 82, .875);
  --jade-a12: rgba(0, 34, 23, .886);
  --lime-1: #fcfdfa;
  --lime-2: #f8faf3;
  --lime-3: #eef6d6;
  --lime-4: #e2f0bd;
  --lime-5: #d3e7a6;
  --lime-6: #c2da91;
  --lime-7: #abc978;
  --lime-8: #8db654;
  --lime-9: #bdee63;
  --lime-10: #b0e64c;
  --lime-11: #5c7c2f;
  --lime-12: #37401c;
  --lime-a1: rgba(102, 153, 0, .02);
  --lime-a2: rgba(107, 149, 0, .047);
  --lime-a3: rgba(150, 200, 0, .16);
  --lime-a4: rgba(143, 198, 0, .26);
  --lime-a5: rgba(129, 187, 0, .35);
  --lime-a6: rgba(114, 170, 0, .43);
  --lime-a7: rgba(97, 153, 0, .53);
  --lime-a8: rgba(85, 146, 0, .67);
  --lime-a9: rgba(147, 228, 0, .61);
  --lime-a10: rgba(143, 220, 0, .7);
  --lime-a11: rgba(55, 95, 0, .816);
  --lime-a12: rgba(30, 41, 0, .89);
  --mint-1: #f9fefd;
  --mint-2: #f2fbf9;
  --mint-3: #ddf9f2;
  --mint-4: #c8f4e9;
  --mint-5: #b3ecde;
  --mint-6: #9ce0d0;
  --mint-7: #7ecfbd;
  --mint-8: #4cbba5;
  --mint-9: #86ead4;
  --mint-10: #7de0cb;
  --mint-11: #027864;
  --mint-12: #16433c;
  --mint-a1: rgba(0, 213, 170, .024);
  --mint-a2: rgba(0, 177, 138, .05);
  --mint-a3: rgba(0, 210, 158, .133);
  --mint-a4: rgba(0, 204, 153, .216);
  --mint-a5: rgba(0, 192, 145, .298);
  --mint-a6: rgba(0, 176, 134, .39);
  --mint-a7: rgba(0, 161, 125, .506);
  --mint-a8: rgba(0, 158, 127, .7);
  --mint-a9: rgba(0, 211, 165, .475);
  --mint-a10: rgba(0, 195, 153, .51);
  --mint-a11: rgba(0, 119, 99, .992);
  --mint-a12: rgba(0, 49, 42, .914);
  --orange-1: #fefcfb;
  --orange-2: #fff7ed;
  --orange-3: #ffefd6;
  --orange-4: #ffdfb5;
  --orange-5: #ffd19a;
  --orange-6: #ffc182;
  --orange-7: #f5ae73;
  --orange-8: #ec9455;
  --orange-9: #f76b15;
  --orange-10: #ef5f00;
  --orange-11: #cc4e00;
  --orange-12: #582d1d;
  --orange-a1: rgba(192, 64, 0, .016);
  --orange-a2: rgba(255, 142, 0, .07);
  --orange-a3: rgba(255, 156, 0, .16);
  --orange-a4: rgba(255, 145, 1, .29);
  --orange-a5: rgba(255, 139, 0, .396);
  --orange-a6: rgba(255, 129, 0, .49);
  --orange-a7: rgba(237, 108, 0, .55);
  --orange-a8: rgba(227, 95, 0, .667);
  --orange-a9: rgba(246, 94, 0, .918);
  --orange-a10: #ef5f00;
  --orange-a11: #cc4e00;
  --orange-a12: rgba(67, 18, 0, .886);
  --pink-1: #fffcfe;
  --pink-2: #fef7fb;
  --pink-3: #fee9f5;
  --pink-4: #fbdcef;
  --pink-5: #f6cee7;
  --pink-6: #efbfdd;
  --pink-7: #e7acd0;
  --pink-8: #dd93c2;
  --pink-9: #d6409f;
  --pink-10: #cf3897;
  --pink-11: #c2298a;
  --pink-12: #651249;
  --pink-a1: rgba(255, 0, 170, .01);
  --pink-a2: rgba(224, 0, 128, .03);
  --pink-a3: rgba(244, 0, 140, .086);
  --pink-a4: rgba(226, 0, 139, .137);
  --pink-a5: rgba(209, 0, 131, .192);
  --pink-a6: rgba(192, 0, 120, .25);
  --pink-a7: rgba(182, 0, 111, .325);
  --pink-a8: rgba(175, 0, 111, .424);
  --pink-a9: rgba(200, 0, 127, .75);
  --pink-a10: rgba(194, 0, 122, .78);
  --pink-a11: rgba(182, 0, 116, .84);
  --pink-a12: rgba(89, 0, 59, .93);
  --plum-1: #fefcff;
  --plum-2: #fdf7fd;
  --plum-3: #fbebfb;
  --plum-4: #f7def8;
  --plum-5: #f2d1f3;
  --plum-6: #e9c2ec;
  --plum-7: #deade3;
  --plum-8: #cf91d8;
  --plum-9: #ab4aba;
  --plum-10: #a144af;
  --plum-11: #953ea3;
  --plum-12: #53195d;
  --plum-a1: rgba(170, 0, 255, .01);
  --plum-a2: rgba(192, 0, 192, .03);
  --plum-a3: rgba(204, 0, 204, .08);
  --plum-a4: rgba(194, 0, 201, .13);
  --plum-a5: rgba(183, 0, 189, .18);
  --plum-a6: rgba(164, 0, 176, .24);
  --plum-a7: rgba(153, 0, 168, .32);
  --plum-a8: rgba(144, 0, 165, .43);
  --plum-a9: rgba(137, 0, 158, .71);
  --plum-a10: rgba(127, 0, 146, .733);
  --plum-a11: rgba(115, 0, 134, .757);
  --plum-a12: rgba(64, 0, 75, .9);
  --purple-1: #fefcfe;
  --purple-2: #fbf7fe;
  --purple-3: #f7edfe;
  --purple-4: #f2e2fc;
  --purple-5: #ead5f9;
  --purple-6: #e0c4f4;
  --purple-7: #d1afec;
  --purple-8: #be93e4;
  --purple-9: #8e4ec6;
  --purple-10: #8347b9;
  --purple-11: #8145b5;
  --purple-12: #402060;
  --purple-a1: rgba(170, 0, 170, .01);
  --purple-a2: rgba(128, 0, 224, .03);
  --purple-a3: rgba(142, 0, 241, .07);
  --purple-a4: rgba(141, 0, 229, .114);
  --purple-a5: rgba(128, 0, 219, .165);
  --purple-a6: rgba(122, 1, 208, .23);
  --purple-a7: rgba(109, 0, 195, .314);
  --purple-a8: rgba(102, 0, 192, .424);
  --purple-a9: rgba(92, 0, 173, .694);
  --purple-a10: rgba(83, 0, 158, .72);
  --purple-a11: rgba(82, 0, 154, .73);
  --purple-a12: rgba(37, 0, 73, .875);
  --red-1: #fffcfc;
  --red-2: #fff7f7;
  --red-3: #feebec;
  --red-4: #ffdbdc;
  --red-5: #ffcdce;
  --red-6: #fdbdbe;
  --red-7: #f4a9aa;
  --red-8: #eb8e90;
  --red-9: #e5484d;
  --red-10: #dc3e42;
  --red-11: #ce2c31;
  --red-12: #641723;
  --red-a1: rgba(255, 0, 0, .01);
  --red-a2: rgba(255, 0, 0, .03);
  --red-a3: rgba(243, 0, 13, .08);
  --red-a4: rgba(255, 0, 8, .14);
  --red-a5: rgba(255, 0, 6, .196);
  --red-a6: rgba(248, 0, 4, .26);
  --red-a7: rgba(223, 0, 3, .337);
  --red-a8: rgba(210, 0, 5, .443);
  --red-a9: rgba(219, 0, 7, .718);
  --red-a10: rgba(209, 0, 5, .757);
  --red-a11: rgba(196, 0, 6, .827);
  --red-a12: rgba(85, 0, 13, .91);
  --ruby-1: #fffcfd;
  --ruby-2: #fff7f8;
  --ruby-3: #feeaed;
  --ruby-4: #ffdce1;
  --ruby-5: #ffced6;
  --ruby-6: #f8bfc8;
  --ruby-7: #efacb8;
  --ruby-8: #e592a3;
  --ruby-9: #e54666;
  --ruby-10: #dc3b5d;
  --ruby-11: #ca244d;
  --ruby-12: #64172b;
  --ruby-a1: rgba(255, 0, 85, .01);
  --ruby-a2: rgba(255, 0, 32, .03);
  --ruby-a3: rgba(243, 0, 37, .082);
  --ruby-a4: rgba(255, 0, 37, .137);
  --ruby-a5: rgba(255, 0, 42, .192);
  --ruby-a6: rgba(228, 0, 36, .25);
  --ruby-a7: rgba(206, 0, 37, .325);
  --ruby-a8: rgba(195, 0, 40, .427);
  --ruby-a9: rgba(219, 0, 44, .725);
  --ruby-a10: rgba(210, 0, 44, .77);
  --ruby-a11: rgba(193, 0, 48, .86);
  --ruby-a12: rgba(85, 0, 22, .91);
  --sky-1: #f9feff;
  --sky-2: #f1fafd;
  --sky-3: #e1f6fd;
  --sky-4: #d1f0fa;
  --sky-5: #bee7f5;
  --sky-6: #a9daed;
  --sky-7: #8dcae3;
  --sky-8: #60b3d7;
  --sky-9: #7ce2fe;
  --sky-10: #74daf8;
  --sky-11: #00749e;
  --sky-12: #1d3e56;
  --sky-a1: rgba(0, 213, 255, .024);
  --sky-a2: rgba(0, 164, 219, .055);
  --sky-a3: rgba(0, 179, 238, .118);
  --sky-a4: rgba(0, 172, 228, .18);
  --sky-a5: rgba(0, 161, 216, .255);
  --sky-a6: rgba(0, 146, 202, .337);
  --sky-a7: rgba(0, 137, 193, .447);
  --sky-a8: rgba(0, 133, 191, .624);
  --sky-a9: rgba(0, 199, 254, .514);
  --sky-a10: rgba(0, 188, 243, .545);
  --sky-a11: #00749e;
  --sky-a12: rgba(0, 37, 64, .886);
  --teal-1: #fafefd;
  --teal-2: #f3fbf9;
  --teal-3: #e0f8f3;
  --teal-4: #ccf3ea;
  --teal-5: #b8eae0;
  --teal-6: #a1ded2;
  --teal-7: #83cdc1;
  --teal-8: #53b9ab;
  --teal-9: #12a594;
  --teal-10: #0d9b8a;
  --teal-11: #008573;
  --teal-12: #0d3d38;
  --teal-a1: rgba(0, 204, 153, .02);
  --teal-a2: rgba(0, 170, 128, .047);
  --teal-a3: rgba(0, 198, 157, .12);
  --teal-a4: rgba(0, 195, 150, .2);
  --teal-a5: rgba(0, 180, 144, .28);
  --teal-a6: rgba(0, 166, 133, .37);
  --teal-a7: rgba(0, 153, 128, .486);
  --teal-a8: rgba(0, 151, 131, .675);
  --teal-a9: rgba(0, 158, 140, .93);
  --teal-a10: rgba(0, 150, 132, .95);
  --teal-a11: #008573;
  --teal-a12: rgba(0, 51, 45, .95);
  --tomato-1: #fffcfc;
  --tomato-2: #fff8f7;
  --tomato-3: #feebe7;
  --tomato-4: #ffdcd3;
  --tomato-5: #ffcdc2;
  --tomato-6: #fdbdaf;
  --tomato-7: #f5a898;
  --tomato-8: #ec8e7b;
  --tomato-9: #e54d2e;
  --tomato-10: #dd4425;
  --tomato-11: #d13415;
  --tomato-12: #5c271f;
  --tomato-a1: rgba(255, 0, 0, .01);
  --tomato-a2: rgba(255, 32, 0, .03);
  --tomato-a3: rgba(245, 43, 0, .094);
  --tomato-a4: rgba(255, 53, 0, .173);
  --tomato-a5: rgba(255, 46, 0, .24);
  --tomato-a6: rgba(249, 45, 0, .314);
  --tomato-a7: rgba(231, 40, 0, .404);
  --tomato-a8: rgba(219, 37, 0, .518);
  --tomato-a9: rgba(223, 38, 0, .82);
  --tomato-a10: rgba(215, 36, 0, .855);
  --tomato-a11: rgba(205, 34, 0, .918);
  --tomato-a12: rgba(70, 9, 0, .88);
  --violet-1: #fdfcfe;
  --violet-2: #faf8ff;
  --violet-3: #f4f0fe;
  --violet-4: #ebe4ff;
  --violet-5: #e1d9ff;
  --violet-6: #d4cafe;
  --violet-7: #c2b5f5;
  --violet-8: #aa99ec;
  --violet-9: #6e56cf;
  --violet-10: #654dc4;
  --violet-11: #6550b9;
  --violet-12: #2f265f;
  --violet-a1: rgba(85, 0, 170, .01);
  --violet-a2: rgba(73, 0, 255, .027);
  --violet-a3: rgba(68, 0, 238, .06);
  --violet-a4: rgba(67, 0, 255, .106);
  --violet-a5: rgba(54, 0, 255, .15);
  --violet-a6: rgba(49, 0, 251, .208);
  --violet-a7: rgba(45, 1, 221, .29);
  --violet-a8: rgba(43, 0, 208, .4);
  --violet-a9: rgba(36, 0, 183, .663);
  --violet-a10: rgba(35, 0, 171, .698);
  --violet-a11: rgba(31, 0, 153, .686);
  --violet-a12: rgba(11, 0, 67, .85);
  --yellow-1: #fdfdf9;
  --yellow-2: #fefce9;
  --yellow-3: #fffab8;
  --yellow-4: #fff394;
  --yellow-5: #ffe770;
  --yellow-6: #f3d768;
  --yellow-7: #e4c767;
  --yellow-8: #d5ae39;
  --yellow-9: #ffe629;
  --yellow-10: #ffdc00;
  --yellow-11: #9e6c00;
  --yellow-12: #473b1f;
  --yellow-a1: rgba(170, 170, 0, .024);
  --yellow-a2: rgba(244, 221, 0, .086);
  --yellow-a3: rgba(255, 238, 0, .28);
  --yellow-a4: rgba(255, 227, 1, .42);
  --yellow-a5: rgba(255, 213, 0, .56);
  --yellow-a6: rgba(235, 188, 0, .592);
  --yellow-a7: rgba(210, 161, 0, .596);
  --yellow-a8: rgba(201, 151, 0, .776);
  --yellow-a9: rgba(255, 225, 0, .84);
  --yellow-a10: #ffdc00;
  --yellow-a11: #9e6c00;
  --yellow-a12: rgba(46, 32, 0, .88);
  --gray-surface: rgba(255, 255, 255, .8);
  --gray-indicator: var(--gray-9);
  --gray-track: var(--gray-9);
  --mauve-surface: rgba(255, 255, 255, .8);
  --mauve-indicator: var(--mauve-9);
  --mauve-track: var(--mauve-9);
  --slate-surface: rgba(255, 255, 255, .8);
  --slate-indicator: var(--slate-9);
  --slate-track: var(--slate-9);
  --sage-surface: rgba(255, 255, 255, .8);
  --sage-indicator: var(--sage-9);
  --sage-track: var(--sage-9);
  --olive-surface: rgba(255, 255, 255, .8);
  --olive-indicator: var(--olive-9);
  --olive-track: var(--olive-9);
  --sand-surface: rgba(255, 255, 255, .8);
  --sand-indicator: var(--sand-9);
  --sand-track: var(--sand-9);
  --amber-surface: rgba(254, 250, 228, .8);
  --amber-indicator: var(--amber-9);
  --amber-track: var(--amber-9);
  --blue-surface: rgba(241, 249, 255, .8);
  --blue-indicator: var(--blue-9);
  --blue-track: var(--blue-9);
  --bronze-surface: rgba(253, 245, 243, .8);
  --bronze-indicator: var(--bronze-9);
  --bronze-track: var(--bronze-9);
  --brown-surface: rgba(251, 248, 244, .8);
  --brown-indicator: var(--brown-9);
  --brown-track: var(--brown-9);
  --crimson-surface: rgba(254, 245, 248, .8);
  --crimson-indicator: var(--crimson-9);
  --crimson-track: var(--crimson-9);
  --cyan-surface: rgba(239, 249, 250, .8);
  --cyan-indicator: var(--cyan-9);
  --cyan-track: var(--cyan-9);
  --gold-surface: rgba(249, 248, 239, .8);
  --gold-indicator: var(--gold-9);
  --gold-track: var(--gold-9);
  --grass-surface: rgba(243, 250, 243, .8);
  --grass-indicator: var(--grass-9);
  --grass-track: var(--grass-9);
  --green-surface: rgba(241, 250, 244, .8);
  --green-indicator: var(--green-9);
  --green-track: var(--green-9);
  --indigo-surface: rgba(245, 248, 255, .8);
  --indigo-indicator: var(--indigo-9);
  --indigo-track: var(--indigo-9);
  --iris-surface: rgba(246, 246, 255, .8);
  --iris-indicator: var(--iris-9);
  --iris-track: var(--iris-9);
  --jade-surface: rgba(241, 250, 245, .8);
  --jade-indicator: var(--jade-9);
  --jade-track: var(--jade-9);
  --lime-surface: rgba(246, 249, 240, .8);
  --lime-indicator: var(--lime-9);
  --lime-track: var(--lime-9);
  --mint-surface: rgba(239, 250, 248, .8);
  --mint-indicator: var(--mint-9);
  --mint-track: var(--mint-9);
  --orange-surface: rgba(255, 245, 233, .8);
  --orange-indicator: var(--orange-9);
  --orange-track: var(--orange-9);
  --pink-surface: rgba(254, 245, 250, .8);
  --pink-indicator: var(--pink-9);
  --pink-track: var(--pink-9);
  --plum-surface: rgba(253, 245, 253, .8);
  --plum-indicator: var(--plum-9);
  --plum-track: var(--plum-9);
  --purple-surface: rgba(250, 245, 254, .8);
  --purple-indicator: var(--purple-9);
  --purple-track: var(--purple-9);
  --red-surface: rgba(255, 245, 245, .8);
  --red-indicator: var(--red-9);
  --red-track: var(--red-9);
  --ruby-surface: rgba(255, 245, 246, .8);
  --ruby-indicator: var(--ruby-9);
  --ruby-track: var(--ruby-9);
  --sky-surface: rgba(238, 249, 253, .8);
  --sky-indicator: var(--sky-9);
  --sky-track: var(--sky-9);
  --teal-surface: rgba(240, 250, 248, .8);
  --teal-indicator: var(--teal-9);
  --teal-track: var(--teal-9);
  --tomato-surface: rgba(255, 246, 245, .8);
  --tomato-indicator: var(--tomato-9);
  --tomato-track: var(--tomato-9);
  --violet-surface: rgba(249, 246, 255, .8);
  --violet-indicator: var(--violet-9);
  --violet-track: var(--violet-9);
  --yellow-surface: rgba(254, 251, 228, .8);
  --yellow-indicator: var(--yellow-10);
  --yellow-track: var(--yellow-10);
}

@supports (color: color(display-p3 1 1 1)) {
  @media (color-gamut: p3) {
    :root, .light, .light-theme {
      --gray-1: #fcfcfc;
      --gray-2: #f9f9f9;
      --gray-3: #efefef;
      --gray-4: #e8e8e8;
      --gray-5: #e0e0e0;
      --gray-6: #d8d8d8;
      --gray-7: #cecece;
      --gray-8: #bbb;
      --gray-9: #8d8d8d;
      --gray-10: #838383;
      --gray-11: #646464;
      --gray-12: #202020;
      --gray-a1: rgba(0, 0, 0, .01);
      --gray-a2: rgba(0, 0, 0, .024);
      --gray-a3: rgba(0, 0, 0, .063);
      --gray-a4: rgba(0, 0, 0, .09);
      --gray-a5: rgba(0, 0, 0, .12);
      --gray-a6: rgba(0, 0, 0, .153);
      --gray-a7: rgba(0, 0, 0, .192);
      --gray-a8: rgba(0, 0, 0, .267);
      --gray-a9: rgba(0, 0, 0, .447);
      --gray-a10: rgba(0, 0, 0, .486);
      --gray-a11: rgba(0, 0, 0, .608);
      --gray-a12: rgba(0, 0, 0, .875);
      --mauve-1: #fdfcfd;
      --mauve-2: #faf9fb;
      --mauve-3: #f2eff3;
      --mauve-4: #eae7ec;
      --mauve-5: #e2dfe6;
      --mauve-6: #dbd8e0;
      --mauve-7: #d0cdd7;
      --mauve-8: #bcbac7;
      --mauve-9: #8e8c99;
      --mauve-10: #83818e;
      --mauve-11: #65636d;
      --mauve-12: #211f26;
      --mauve-a1: rgba(97, 0, 91, .01);
      --mauve-a2: rgba(52, 2, 93, .024);
      --mauve-a3: rgba(37, 0, 68, .063);
      --mauve-a4: rgba(27, 2, 58, .094);
      --mauve-a5: rgba(28, 1, 60, .125);
      --mauve-a6: rgba(16, 0, 48, .153);
      --mauve-a7: rgba(20, 1, 49, .196);
      --mauve-a8: rgba(6, 1, 47, .27);
      --mauve-a9: rgba(6, 1, 29, .45);
      --mauve-a10: rgba(4, 1, 24, .49);
      --mauve-a11: rgba(5, 0, 16, .61);
      --mauve-a12: rgba(2, 0, 7, .88);
      --slate-1: #fcfcfd;
      --slate-2: #f9f9fb;
      --slate-3: #f0f0f3;
      --slate-4: #e7e8ec;
      --slate-5: #e0e1e6;
      --slate-6: #d9d9e0;
      --slate-7: #cdced6;
      --slate-8: #b9bbc6;
      --slate-9: #8b8d98;
      --slate-10: #80838d;
      --slate-11: #60646c;
      --slate-12: #1c2024;
      --slate-a1: rgba(6, 6, 93, .01);
      --slate-a2: rgba(6, 6, 93, .024);
      --slate-a3: rgba(1, 1, 55, .06);
      --slate-a4: rgba(3, 3, 49, .09);
      --slate-a5: rgba(0, 11, 51, .12);
      --slate-a6: rgba(2, 2, 44, .15);
      --slate-a7: rgba(1, 7, 49, .196);
      --slate-a8: rgba(0, 9, 45, .275);
      --slate-a9: rgba(0, 5, 28, .455);
      --slate-a10: rgba(0, 7, 26, .498);
      --slate-a11: rgba(0, 5, 15, .62);
      --slate-a12: rgba(0, 3, 8, .886);
      --sage-1: #fbfdfc;
      --sage-2: #f7f9f8;
      --sage-3: #eef1f0;
      --sage-4: #e6e9e8;
      --sage-5: #dfe2e0;
      --sage-6: #d7dad8;
      --sage-7: #cbcfcd;
      --sage-8: #b8bcbb;
      --sage-9: #868e8b;
      --sage-10: #7c8481;
      --sage-11: #5f6563;
      --sage-12: #1a211e;
      --sage-a1: rgba(0, 128, 74, .016);
      --sage-a2: rgba(0, 68, 37, .03);
      --sage-a3: rgba(0, 47, 32, .067);
      --sage-a4: rgba(0, 24, 13, .094);
      --sage-a5: rgba(0, 25, 9, .125);
      --sage-a6: rgba(0, 20, 7, .157);
      --sage-a7: rgba(0, 14, 10, .2);
      --sage-a8: rgba(0, 12, 8, .275);
      --sage-a9: rgba(0, 15, 9, .47);
      --sage-a10: rgba(0, 11, 8, .51);
      --sage-a11: rgba(0, 8, 5, .624);
      --sage-a12: rgba(0, 7, 3, .894);
      --olive-1: #fcfdfc;
      --olive-2: #f8faf8;
      --olive-3: #eff1ef;
      --olive-4: #e7e9e7;
      --olive-5: #dfe2df;
      --olive-6: #d7dad7;
      --olive-7: #cccfcc;
      --olive-8: #b9bcb8;
      --olive-9: #898e87;
      --olive-10: #7f837d;
      --olive-11: #60655f;
      --olive-12: #1d211c;
      --olive-a1: rgba(0, 87, 21, .01);
      --olive-a2: rgba(0, 75, 18, .027);
      --olive-a3: rgba(0, 33, 1, .063);
      --olive-a4: rgba(0, 24, 3, .094);
      --olive-a5: rgba(4, 25, 0, .125);
      --olive-a6: rgba(4, 20, 0, .157);
      --olive-a7: rgba(4, 15, 0, .2);
      --olive-a8: rgba(3, 15, 0, .28);
      --olive-a9: rgba(3, 13, 0, .467);
      --olive-a10: rgba(5, 12, 0, .51);
      --olive-a11: rgba(2, 10, 0, .627);
      --olive-a12: rgba(1, 6, 0, .89);
      --sand-1: #fdfdfc;
      --sand-2: #f9f9f8;
      --sand-3: #f1f0ef;
      --sand-4: #e9e9e6;
      --sand-5: #e2e1de;
      --sand-6: #dad9d6;
      --sand-7: #cfcfca;
      --sand-8: #bcbbb5;
      --sand-9: #8d8d86;
      --sand-10: #82827c;
      --sand-11: #63635e;
      --sand-12: #21201c;
      --sand-a1: rgba(89, 89, 0, .01);
      --sand-a2: rgba(41, 41, 0, .027);
      --sand-a3: rgba(17, 17, 0, .063);
      --sand-a4: rgba(33, 33, 0, .098);
      --sand-a5: rgba(26, 17, 0, .125);
      --sand-a6: rgba(27, 19, 0, .16);
      --sand-a7: rgba(25, 25, 0, .208);
      --sand-a8: rgba(22, 19, 0, .286);
      --sand-a9: rgba(13, 13, 0, .47);
      --sand-a10: rgba(12, 12, 0, .514);
      --sand-a11: rgba(8, 8, 0, .63);
      --sand-a12: rgba(6, 5, 0, .89);
      --amber-1: #fefdfb;
      --amber-2: #fefbe9;
      --amber-3: #fff7c2;
      --amber-4: #ffee9c;
      --amber-5: #fbe577;
      --amber-6: #f4d673;
      --amber-7: #e9c163;
      --amber-8: #e2a336;
      --amber-9: #ffc442;
      --amber-10: #ffba18;
      --amber-11: #a76600;
      --amber-12: #4f3422;
      --amber-a1: rgba(200, 129, 0, .016);
      --amber-a2: rgba(231, 205, 0, .08);
      --amber-a3: rgba(247, 219, 0, .22);
      --amber-a4: rgba(249, 209, 0, .35);
      --amber-a5: rgba(240, 203, 0, .475);
      --amber-a6: rgba(226, 174, 0, .494);
      --amber-a7: rgba(209, 146, 0, .557);
      --amber-a8: rgba(206, 128, 0, .698);
      --amber-a9: rgba(255, 175, 0, .74);
      --amber-a10: rgba(246, 164, 0, .725);
      --amber-a11: #a76600;
      --amber-a12: #4f3422;
      --blue-1: #fbfdff;
      --blue-2: #f4faff;
      --blue-3: #e6f4fe;
      --blue-4: #d6eeff;
      --blue-5: #c4e5ff;
      --blue-6: #acd8fc;
      --blue-7: #8ec8f6;
      --blue-8: #5eb1ef;
      --blue-9: #0190ff;
      --blue-10: #0687f0;
      --blue-11: #0073d7;
      --blue-12: #113264;
      --blue-a1: rgba(0, 136, 248, .016);
      --blue-a2: rgba(0, 135, 225, .04);
      --blue-a3: rgba(0, 133, 227, .086);
      --blue-a4: rgba(0, 143, 248, .145);
      --blue-a5: rgba(0, 133, 244, .21);
      --blue-a6: rgba(0, 123, 229, .29);
      --blue-a7: rgba(0, 114, 214, .392);
      --blue-a8: rgba(0, 113, 211, .55);
      --blue-a9: rgba(0, 111, 237, .753);
      --blue-a10: rgba(0, 102, 219, .765);
      --blue-a11: #0073d7;
      --blue-a12: #113264;
      --bronze-1: #fdfcfc;
      --bronze-2: #fdf7f5;
      --bronze-3: #f6edea;
      --bronze-4: #efe4df;
      --bronze-5: #e7d9d3;
      --bronze-6: #dfcdc5;
      --bronze-7: #d3bcb3;
      --bronze-8: #c2a499;
      --bronze-9: #a18072;
      --bronze-10: #957468;
      --bronze-11: #7d5e54;
      --bronze-12: #43302b;
      --bronze-a1: rgba(97, 0, 2, .01);
      --bronze-a2: rgba(192, 50, 0, .04);
      --bronze-a3: rgba(130, 48, 0, .082);
      --bronze-a4: rgba(115, 31, 0, .12);
      --bronze-a5: rgba(108, 34, 0, .17);
      --bronze-a6: rgba(105, 29, 0, .224);
      --bronze-a7: rgba(99, 25, 0, .294);
      --bronze-a8: rgba(93, 23, 0, .392);
      --bronze-a9: rgba(79, 22, 0, .545);
      --bronze-a10: rgba(69, 19, 0, .584);
      --bronze-a11: #7d5e54;
      --bronze-a12: #43302b;
      --brown-1: #fefdfc;
      --brown-2: #fcf9f5;
      --brown-3: #f6eee7;
      --brown-4: #f0e4d9;
      --brown-5: #ebdaca;
      --brown-6: #e4cdb8;
      --brown-7: #dcbc9f;
      --brown-8: #cea37e;
      --brown-9: #ad7f58;
      --brown-10: #a07553;
      --brown-11: #815e46;
      --brown-12: #3e332e;
      --brown-a1: rgba(181, 86, 0, .01);
      --brown-a2: rgba(181, 86, 0, .035);
      --brown-a3: rgba(153, 78, 0, .09);
      --brown-a4: rgba(146, 75, 0, .145);
      --brown-a5: rgba(150, 72, 0, .204);
      --brown-a6: rgba(148, 73, 0, .27);
      --brown-a7: rgba(149, 71, 0, .36);
      --brown-a8: rgba(147, 68, 0, .486);
      --brown-a9: rgba(120, 54, 0, .63);
      --brown-a10: rgba(104, 46, 0, .655);
      --brown-a11: #815e46;
      --brown-a12: #3e332e;
      --crimson-1: #fffcfd;
      --crimson-2: #fef7f9;
      --crimson-3: #ffe9f0;
      --crimson-4: #fedce7;
      --crimson-5: #facedd;
      --crimson-6: #f3bed1;
      --crimson-7: #eaacc3;
      --crimson-8: #e094b2;
      --crimson-9: #e93d82;
      --crimson-10: #df3478;
      --crimson-11: #cb1d63;
      --crimson-12: #621639;
      --crimson-a1: rgba(184, 0, 90, .01);
      --crimson-a2: rgba(206, 0, 68, .03);
      --crimson-a3: rgba(234, 0, 75, .082);
      --crimson-a4: rgba(225, 0, 76, .133);
      --crimson-a5: rgba(205, 0, 70, .19);
      --crimson-a6: rgba(186, 0, 63, .243);
      --crimson-a7: rgba(169, 0, 65, .318);
      --crimson-a8: rgba(164, 0, 65, .408);
      --crimson-a9: rgba(211, 0, 76, .7);
      --crimson-a10: rgba(201, 0, 70, .733);
      --crimson-a11: #cb1d63;
      --crimson-a12: #621639;
      --cyan-1: #fafdfe;
      --cyan-2: #f2fafb;
      --cyan-3: #def7f9;
      --cyan-4: #caf1f6;
      --cyan-5: #b5e9f0;
      --cyan-6: #9ddee7;
      --cyan-7: #7dcedc;
      --cyan-8: #3db9ce;
      --cyan-9: #01a2c7;
      --cyan-10: #0797b9;
      --cyan-11: #007b9e;
      --cyan-12: #0d3c48;
      --cyan-a1: rgba(0, 156, 202, .02);
      --cyan-a2: rgba(0, 143, 163, .043);
      --cyan-a3: rgba(0, 176, 197, .114);
      --cyan-a4: rgba(0, 174, 197, .18);
      --cyan-a5: rgba(0, 160, 184, .247);
      --cyan-a6: rgba(0, 150, 177, .33);
      --cyan-a7: rgba(0, 139, 168, .435);
      --cyan-a8: rgba(0, 137, 167, .61);
      --cyan-a9: rgba(0, 124, 169, .718);
      --cyan-a10: rgba(0, 112, 152, .737);
      --cyan-a11: #007b9e;
      --cyan-a12: #0d3c48;
      --gold-1: #fdfdfc;
      --gold-2: #faf9f2;
      --gold-3: #f2f0e7;
      --gold-4: #eae6dc;
      --gold-5: #e1dccf;
      --gold-6: #d7d0bf;
      --gold-7: #cbc0aa;
      --gold-8: #b9a88d;
      --gold-9: #978365;
      --gold-10: #8c795e;
      --gold-11: #71624b;
      --gold-12: #3b352b;
      --gold-a1: rgba(89, 89, 0, .01);
      --gold-a2: rgba(152, 131, 0, .047);
      --gold-a3: rgba(103, 91, 0, .09);
      --gold-a4: rgba(93, 76, 0, .133);
      --gold-a5: rgba(89, 72, 0, .184);
      --gold-a6: rgba(88, 67, 0, .243);
      --gold-a7: rgba(91, 59, 0, .32);
      --gold-a8: rgba(92, 55, 0, .435);
      --gold-a9: rgba(75, 46, 0, .59);
      --gold-a10: rgba(67, 41, 0, .62);
      --gold-a11: #71624b;
      --gold-a12: #3b352b;
      --grass-1: #fbfefb;
      --grass-2: #f5fbf5;
      --grass-3: #e9f7e9;
      --grass-4: #daf0db;
      --grass-5: #c9e9ca;
      --grass-6: #b2deb5;
      --grass-7: #94ce9a;
      --grass-8: #65ba74;
      --grass-9: #46a758;
      --grass-10: #3e9a4f;
      --grass-11: #2a7e3b;
      --grass-12: #203c25;
      --grass-a1: rgba(0, 188, 49, .016);
      --grass-a2: rgba(0, 140, 35, .035);
      --grass-a3: rgba(0, 143, 35, .082);
      --grass-a4: rgba(0, 140, 34, .133);
      --grass-a5: rgba(0, 135, 32, .196);
      --grass-a6: rgba(0, 125, 29, .275);
      --grass-a7: rgba(0, 120, 27, .376);
      --grass-a8: rgba(0, 116, 27, .52);
      --grass-a9: rgba(0, 108, 23, .624);
      --grass-a10: rgba(0, 96, 20, .66);
      --grass-a11: #2a7e3b;
      --grass-a12: #203c25;
      --green-1: #fbfefc;
      --green-2: #f4fbf6;
      --green-3: #e6f6eb;
      --green-4: #d6f1df;
      --green-5: #c4e8d1;
      --green-6: #adddc0;
      --green-7: #8eceaa;
      --green-8: #5bb88b;
      --green-9: #30a46c;
      --green-10: #2b9a66;
      --green-11: #00804f;
      --green-12: #193b2d;
      --green-a1: rgba(0, 188, 83, .016);
      --green-a2: rgba(0, 140, 49, .035);
      --green-a3: rgba(0, 148, 53, .086);
      --green-a4: rgba(0, 146, 53, .14);
      --green-a5: rgba(0, 134, 52, .204);
      --green-a6: rgba(0, 129, 51, .282);
      --green-a7: rgba(0, 121, 52, .39);
      --green-a8: rgba(0, 119, 58, .55);
      --green-a9: rgba(0, 113, 50, .667);
      --green-a10: rgba(0, 103, 46, .69);
      --green-a11: #00804f;
      --green-a12: #193b2d;
      --indigo-1: #fdfdfe;
      --indigo-2: #f7f9ff;
      --indigo-3: #edf2fe;
      --indigo-4: #e1e9ff;
      --indigo-5: #d2deff;
      --indigo-6: #c1d0ff;
      --indigo-7: #abbdf9;
      --indigo-8: #8da4ef;
      --indigo-9: #3e63dd;
      --indigo-10: #3358d4;
      --indigo-11: #3a5bc7;
      --indigo-12: #1f2d5c;
      --indigo-a1: rgba(5, 5, 136, .008);
      --indigo-a2: rgba(0, 57, 211, .027);
      --indigo-a3: rgba(0, 72, 217, .067);
      --indigo-a4: rgba(0, 77, 245, .114);
      --indigo-a5: rgba(0, 75, 245, .17);
      --indigo-a6: rgba(0, 70, 241, .23);
      --indigo-a7: rgba(0, 60, 211, .314);
      --indigo-a8: rgba(0, 56, 199, .43);
      --indigo-a9: rgba(0, 52, 189, .725);
      --indigo-a10: rgba(0, 48, 180, .765);
      --indigo-a11: #3a5bc7;
      --indigo-a12: #1f2d5c;
      --iris-1: #fdfdff;
      --iris-2: #f8f8ff;
      --iris-3: #f0f1fe;
      --iris-4: #e6e7ff;
      --iris-5: #dbdcff;
      --iris-6: #cbcdff;
      --iris-7: #b8b9f8;
      --iris-8: #9b9ef0;
      --iris-9: #5b5bd6;
      --iris-10: #5151cd;
      --iris-11: #5753c6;
      --iris-12: #272962;
      --iris-a1: rgba(4, 24, 255, .008);
      --iris-a2: rgba(6, 6, 229, .027);
      --iris-a3: rgba(0, 45, 212, .06);
      --iris-a4: rgba(0, 37, 255, .098);
      --iris-a5: rgba(0, 35, 255, .14);
      --iris-a6: rgba(0, 45, 229, .2);
      --iris-a7: rgba(0, 5, 225, .28);
      --iris-a8: rgba(0, 36, 192, .39);
      --iris-a9: rgba(0, 30, 172, .643);
      --iris-a10: rgba(0, 27, 162, .682);
      --iris-a11: #5753c6;
      --iris-a12: #272962;
      --jade-1: #fbfefd;
      --jade-2: #f4fbf7;
      --jade-3: #e5f7ed;
      --jade-4: #d6f1e3;
      --jade-5: #c3e9d7;
      --jade-6: #acdec9;
      --jade-7: #8bceb6;
      --jade-8: #55ba9f;
      --jade-9: #29a383;
      --jade-10: #26997b;
      --jade-11: #007f5e;
      --jade-12: #1d3b31;
      --jade-a1: rgba(0, 189, 135, .016);
      --jade-a2: rgba(0, 152, 68, .04);
      --jade-a3: rgba(0, 148, 70, .086);
      --jade-a4: rgba(0, 146, 74, .14);
      --jade-a5: rgba(0, 139, 72, .204);
      --jade-a6: rgba(0, 131, 76, .286);
      --jade-a7: rgba(0, 126, 78, .396);
      --jade-a8: rgba(0, 126, 89, .56);
      --jade-a9: rgba(0, 114, 79, .682);
      --jade-a10: rgba(0, 104, 72, .7);
      --jade-a11: #007f5e;
      --jade-a12: #1d3b31;
      --lime-1: #fcfdfa;
      --lime-2: #f8faf3;
      --lime-3: #eef6d6;
      --lime-4: #e2f0bd;
      --lime-5: #d3e7a6;
      --lime-6: #c2da91;
      --lime-7: #abc978;
      --lime-8: #8db554;
      --lime-9: #bdee63;
      --lime-10: #b0e64c;
      --lime-11: #5c7c2f;
      --lime-12: #37401c;
      --lime-a1: rgba(96, 155, 0, .02);
      --lime-a2: rgba(127, 151, 0, .047);
      --lime-a3: rgba(141, 195, 0, .15);
      --lime-a4: rgba(134, 193, 0, .24);
      --lime-a5: rgba(122, 178, 0, .32);
      --lime-a6: rgba(104, 159, 0, .4);
      --lime-a7: rgba(87, 143, 0, .49);
      --lime-a8: rgba(75, 135, 0, .624);
      --lime-a9: rgba(136, 221, 0, .533);
      --lime-a10: rgba(130, 210, 0, .604);
      --lime-a11: #5c7c2f;
      --lime-a12: #37401c;
      --mint-1: #f9fefd;
      --mint-2: #f2fbf9;
      --mint-3: #ddf9f2;
      --mint-4: #c8f4e9;
      --mint-5: #b3ecde;
      --mint-6: #9ce0d0;
      --mint-7: #7ecfbd;
      --mint-8: #4cbba5;
      --mint-9: #86ead4;
      --mint-10: #7de1cb;
      --mint-11: #037864;
      --mint-12: #16433c;
      --mint-a1: rgba(0, 201, 157, .02);
      --mint-a2: rgba(0, 161, 121, .043);
      --mint-a3: rgba(0, 190, 143, .114);
      --mint-a4: rgba(0, 185, 141, .18);
      --mint-a5: rgba(0, 169, 131, .255);
      --mint-a6: rgba(0, 154, 119, .333);
      --mint-a7: rgba(0, 137, 106, .43);
      --mint-a8: rgba(0, 132, 101, .58);
      --mint-a9: rgba(0, 191, 147, .38);
      --mint-a10: rgba(0, 172, 132, .416);
      --mint-a11: #037864;
      --mint-a12: #16433c;
      --orange-1: #fefcfb;
      --orange-2: #fff7ed;
      --orange-3: #ffefd6;
      --orange-4: #ffdeb1;
      --orange-5: #ffd196;
      --orange-6: #ffc287;
      --orange-7: #f5ae73;
      --orange-8: #ec9455;
      --orange-9: #f76a15;
      --orange-10: #ef5f00;
      --orange-11: #cd5300;
      --orange-12: #582d1d;
      --orange-a1: rgba(205, 62, 0, .016);
      --orange-a2: rgba(236, 133, 0, .067);
      --orange-a3: rgba(241, 149, 0, .15);
      --orange-a4: rgba(255, 154, 0, .314);
      --orange-a5: rgba(255, 145, 2, .416);
      --orange-a6: rgba(254, 122, 0, .455);
      --orange-a7: rgba(225, 100, 0, .514);
      --orange-a8: rgba(217, 85, 0, .62);
      --orange-a9: rgba(237, 74, 0, .8);
      --orange-a10: rgba(228, 67, 0, .835);
      --orange-a11: #cd5300;
      --orange-a12: #582d1d;
      --pink-1: #fffcfe;
      --pink-2: #fef7fb;
      --pink-3: #fee9f6;
      --pink-4: #fbdcef;
      --pink-5: #f6cee7;
      --pink-6: #efbfdd;
      --pink-7: #e7acd1;
      --pink-8: #dd93c2;
      --pink-9: #d6409f;
      --pink-10: #cf3897;
      --pink-11: #c2298a;
      --pink-12: #651249;
      --pink-a1: rgba(184, 0, 174, .01);
      --pink-a2: rgba(206, 0, 132, .03);
      --pink-a3: rgba(209, 0, 137, .082);
      --pink-a4: rgba(201, 0, 131, .133);
      --pink-a5: rgba(181, 0, 117, .184);
      --pink-a6: rgba(168, 0, 110, .243);
      --pink-a7: rgba(163, 0, 107, .318);
      --pink-a8: rgba(156, 0, 105, .41);
      --pink-a9: rgba(186, 0, 116, .7);
      --pink-a10: rgba(179, 0, 110, .73);
      --pink-a11: #c2298a;
      --pink-a12: #651249;
      --plum-1: #fefcff;
      --plum-2: #fdf7fd;
      --plum-3: #fbebfb;
      --plum-4: #f7def8;
      --plum-5: #f1d1f3;
      --plum-6: #e9c2ec;
      --plum-7: #deade3;
      --plum-8: #cf91d8;
      --plum-9: #ab4aba;
      --plum-10: #a144af;
      --plum-11: #953ea3;
      --plum-12: #53195d;
      --plum-a1: rgba(185, 12, 255, .01);
      --plum-a2: rgba(159, 0, 150, .027);
      --plum-a3: rgba(179, 0, 194, .08);
      --plum-a4: rgba(172, 0, 186, .125);
      --plum-a5: rgba(159, 0, 178, .176);
      --plum-a6: rgba(147, 0, 169, .235);
      --plum-a7: rgba(134, 0, 158, .314);
      --plum-a8: rgba(129, 0, 154, .42);
      --plum-a9: rgba(124, 0, 148, .686);
      --plum-a10: rgba(115, 0, 136, .71);
      --plum-a11: #953ea3;
      --plum-a12: #53195d;
      --purple-1: #fefcfe;
      --purple-2: #fbf7fe;
      --purple-3: #f7edfd;
      --purple-4: #f2e2fc;
      --purple-5: #ead5f9;
      --purple-6: #e0c4f4;
      --purple-7: #d1afec;
      --purple-8: #be93e4;
      --purple-9: #8e4ec6;
      --purple-10: #8347b9;
      --purple-11: #8145b5;
      --purple-12: #402060;
      --purple-a1: rgba(184, 0, 174, .01);
      --purple-a2: rgba(123, 0, 188, .027);
      --purple-a3: rgba(140, 0, 218, .07);
      --purple-a4: rgba(125, 0, 217, .114);
      --purple-a5: rgba(120, 0, 205, .165);
      --purple-a6: rgba(107, 0, 194, .227);
      --purple-a7: rgba(99, 0, 185, .31);
      --purple-a8: rgba(90, 0, 185, .416);
      --purple-a9: rgba(83, 0, 167, .682);
      --purple-a10: rgba(76, 0, 151, .71);
      --purple-a11: #8145b5;
      --purple-a12: #402060;
      --red-1: #fffcfc;
      --red-2: #fff7f7;
      --red-3: #feebec;
      --red-4: #fdd;
      --red-5: #ffcecf;
      --red-6: #fdbdbe;
      --red-7: #f4a9aa;
      --red-8: #eb8e90;
      --red-9: #e5484d;
      --red-10: #dd3e42;
      --red-11: #ce2c31;
      --red-12: #641723;
      --red-a1: rgba(184, 0, 0, .01);
      --red-a2: rgba(235, 0, 0, .027);
      --red-a3: rgba(215, 0, 0, .075);
      --red-a4: rgba(255, 16, 15, .133);
      --red-a5: rgba(249, 0, 0, .19);
      --red-a6: rgba(226, 0, 0, .25);
      --red-a7: rgba(202, 0, 0, .33);
      --red-a8: rgba(190, 0, 0, .427);
      --red-a9: rgba(204, 0, 0, .675);
      --red-a10: rgba(194, 0, 0, .714);
      --red-a11: #ce2c31;
      --red-a12: #641723;
      --ruby-1: #fffcfd;
      --ruby-2: #fff7f8;
      --ruby-3: #feeaed;
      --ruby-4: #ffdde2;
      --ruby-5: #fecfd6;
      --ruby-6: #f8bfc8;
      --ruby-7: #efacb8;
      --ruby-8: #e592a3;
      --ruby-9: #e54666;
      --ruby-10: #dc3b5d;
      --ruby-11: #ca244d;
      --ruby-12: #64172b;
      --ruby-a1: rgba(184, 0, 90, .01);
      --ruby-a2: rgba(235, 0, 0, .027);
      --ruby-a3: rgba(219, 0, 22, .08);
      --ruby-a4: rgba(247, 0, 24, .13);
      --ruby-a5: rgba(226, 0, 29, .184);
      --ruby-a6: rgba(203, 0, 26, .243);
      --ruby-a7: rgba(185, 0, 26, .314);
      --ruby-a8: rgba(174, 0, 30, .41);
      --ruby-a9: rgba(205, 0, 29, .68);
      --ruby-a10: rgba(194, 0, 28, .714);
      --ruby-a11: #ca244d;
      --ruby-a12: #64172b;
      --sky-1: #f9feff;
      --sky-2: #f1fafd;
      --sky-3: #e1f6fd;
      --sky-4: #d1f0fb;
      --sky-5: #bee7f5;
      --sky-6: #a9daed;
      --sky-7: #8dcae3;
      --sky-8: #60b3d7;
      --sky-9: #7ce2fe;
      --sky-10: #74daf8;
      --sky-11: #00749e;
      --sky-12: #1d3e56;
      --sky-a1: rgba(0, 205, 246, .02);
      --sky-a2: rgba(0, 152, 190, .047);
      --sky-a3: rgba(0, 169, 222, .1);
      --sky-a4: rgba(0, 156, 213, .157);
      --sky-a5: rgba(0, 146, 198, .224);
      --sky-a6: rgba(0, 130, 184, .298);
      --sky-a7: rgba(0, 119, 173, .396);
      --sky-a8: rgba(0, 113, 170, .54);
      --sky-a9: rgba(0, 183, 242, .416);
      --sky-a10: rgba(0, 167, 229, .443);
      --sky-a11: #00749e;
      --sky-a12: #1d3e56;
      --teal-1: #fafefd;
      --teal-2: #f3fbf9;
      --teal-3: #e0f8f3;
      --teal-4: #ccf3ea;
      --teal-5: #b8ebe0;
      --teal-6: #a1ded2;
      --teal-7: #83cdc1;
      --teal-8: #53b9ab;
      --teal-9: #12a594;
      --teal-10: #0d9b8a;
      --teal-11: #007f6d;
      --teal-12: #0d3d38;
      --teal-a1: rgba(0, 189, 135, .016);
      --teal-a2: rgba(0, 161, 121, .043);
      --teal-a3: rgba(0, 185, 144, .106);
      --teal-a4: rgba(0, 175, 138, .17);
      --teal-a5: rgba(0, 160, 127, .24);
      --teal-a6: rgba(0, 142, 114, .318);
      --teal-a7: rgba(0, 129, 108, .42);
      --teal-a8: rgba(0, 126, 107, .57);
      --teal-a9: rgba(0, 120, 102, .7);
      --teal-a10: rgba(0, 113, 94, .725);
      --teal-a11: #007f6d;
      --teal-a12: #0d3d38;
      --tomato-1: #fffcfc;
      --tomato-2: #fff8f7;
      --tomato-3: #ffebe7;
      --tomato-4: #ffddd6;
      --tomato-5: #ffcec4;
      --tomato-6: #fdbdaf;
      --tomato-7: #f5a898;
      --tomato-8: #ec8e7b;
      --tomato-9: #e54d2e;
      --tomato-10: #dd4425;
      --tomato-11: #d13415;
      --tomato-12: #5c271f;
      --tomato-a1: rgba(184, 0, 0, .01);
      --tomato-a2: rgba(205, 24, 0, .03);
      --tomato-a3: rgba(225, 35, 0, .09);
      --tomato-a4: rgba(255, 52, 13, .165);
      --tomato-a5: rgba(249, 34, 0, .23);
      --tomato-a6: rgba(230, 30, 0, .3);
      --tomato-a7: rgba(214, 30, 0, .39);
      --tomato-a8: rgba(203, 27, 0, .498);
      --tomato-a9: rgba(212, 24, 0, .77);
      --tomato-a10: rgba(206, 22, 0, .8);
      --tomato-a11: #d13415;
      --tomato-a12: #5c271f;
      --violet-1: #fdfcfe;
      --violet-2: #faf8ff;
      --violet-3: #f4f0fe;
      --violet-4: #ebe5ff;
      --violet-5: #e1d9ff;
      --violet-6: #d4cafe;
      --violet-7: #c2b5f5;
      --violet-8: #aa99ec;
      --violet-9: #6e56cf;
      --violet-10: #654dc4;
      --violet-11: #6550b9;
      --violet-12: #2f265f;
      --violet-a1: rgba(97, 0, 178, .01);
      --violet-a2: rgba(46, 3, 229, .027);
      --violet-a3: rgba(58, 0, 231, .06);
      --violet-a4: rgba(55, 19, 255, .1);
      --violet-a5: rgba(46, 20, 255, .15);
      --violet-a6: rgba(44, 0, 240, .208);
      --violet-a7: rgba(40, 0, 211, .286);
      --violet-a8: rgba(38, 0, 200, .396);
      --violet-a9: rgba(33, 0, 179, .66);
      --violet-a10: rgba(32, 0, 166, .694);
      --violet-a11: #6550b9;
      --violet-a12: #2f265f;
      --yellow-1: #fdfdf9;
      --yellow-2: #fefce9;
      --yellow-3: #fffab8;
      --yellow-4: #fff394;
      --yellow-5: #ffe770;
      --yellow-6: #f3d768;
      --yellow-7: #e4c767;
      --yellow-8: #d5ae39;
      --yellow-9: #ffea34;
      --yellow-10: #ffdc00;
      --yellow-11: #9c7000;
      --yellow-12: #473b1f;
      --yellow-a1: rgba(171, 172, 0, .024);
      --yellow-a2: rgba(244, 218, 0, .08);
      --yellow-a3: rgba(252, 235, 0, .25);
      --yellow-a4: rgba(251, 223, 0, .373);
      --yellow-a5: rgba(249, 208, 0, .49);
      --yellow-a6: rgba(226, 181, 0, .525);
      --yellow-a7: rgba(199, 154, 0, .54);
      --yellow-a8: rgba(188, 140, 0, .686);
      --yellow-a9: rgba(255, 229, 0, .78);
      --yellow-a10: rgba(249, 207, 0, .71);
      --yellow-a11: #9c7000;
      --yellow-a12: #473b1f;
      --gray-surface: rgba(255, 255, 255, .8);
      --mauve-surface: rgba(255, 255, 255, .8);
      --slate-surface: rgba(255, 255, 255, .8);
      --sage-surface: rgba(255, 255, 255, .8);
      --olive-surface: rgba(255, 255, 255, .8);
      --sand-surface: rgba(255, 255, 255, .8);
      --amber-surface: rgba(253, 251, 228, .8);
      --blue-surface: rgba(242, 249, 255, .8);
      --bronze-surface: rgba(252, 245, 243, .8);
      --brown-surface: rgba(252, 248, 244, .8);
      --crimson-surface: rgba(255, 245, 248, .8);
      --cyan-surface: rgba(238, 249, 250, .8);
      --gold-surface: rgba(249, 248, 239, .8);
      --grass-surface: rgba(243, 250, 244, .8);
      --green-surface: rgba(243, 250, 245, .8);
      --indigo-surface: rgba(246, 248, 255, .8);
      --iris-surface: rgba(246, 246, 255, .8);
      --jade-surface: rgba(241, 250, 245, .8);
      --lime-surface: rgba(248, 249, 239, .8);
      --mint-surface: rgba(239, 250, 248, .8);
      --orange-surface: rgba(255, 245, 234, .8);
      --pink-surface: rgba(255, 245, 250, .8);
      --plum-surface: rgba(252, 246, 251, .8);
      --purple-surface: rgba(251, 246, 254, .8);
      --red-surface: rgba(255, 246, 246, .8);
      --ruby-surface: rgba(255, 246, 246, .8);
      --sky-surface: rgba(238, 249, 251, .8);
      --teal-surface: rgba(239, 250, 248, .8);
      --tomato-surface: rgba(255, 246, 245, .8);
      --violet-surface: rgba(248, 246, 255, .8);
      --yellow-surface: rgba(254, 253, 228, .8);
    }

    @supports (color: color(display-p3 0 0 0)) {
      :root, .light, .light-theme {
        --gray-1: color(display-p3 .988 .988 .988);
        --gray-2: color(display-p3 .975 .975 .975);
        --gray-3: color(display-p3 .939 .939 .939);
        --gray-4: color(display-p3 .908 .908 .908);
        --gray-5: color(display-p3 .88 .88 .88);
        --gray-6: color(display-p3 .849 .849 .849);
        --gray-7: color(display-p3 .807 .807 .807);
        --gray-8: color(display-p3 .732 .732 .732);
        --gray-9: color(display-p3 .553 .553 .553);
        --gray-10: color(display-p3 .512 .512 .512);
        --gray-11: color(display-p3 .392 .392 .392);
        --gray-12: color(display-p3 .125 .125 .125);
        --gray-a1: color(display-p3 0 0 0 / .012);
        --gray-a2: color(display-p3 0 0 0 / .024);
        --gray-a3: color(display-p3 0 0 0 / .063);
        --gray-a4: color(display-p3 0 0 0 / .09);
        --gray-a5: color(display-p3 0 0 0 / .122);
        --gray-a6: color(display-p3 0 0 0 / .153);
        --gray-a7: color(display-p3 0 0 0 / .192);
        --gray-a8: color(display-p3 0 0 0 / .267);
        --gray-a9: color(display-p3 0 0 0 / .447);
        --gray-a10: color(display-p3 0 0 0 / .486);
        --gray-a11: color(display-p3 0 0 0 / .608);
        --gray-a12: color(display-p3 0 0 0 / .875);
        --mauve-1: color(display-p3 .991 .988 .992);
        --mauve-2: color(display-p3 .98 .976 .984);
        --mauve-3: color(display-p3 .946 .938 .952);
        --mauve-4: color(display-p3 .915 .906 .925);
        --mauve-5: color(display-p3 .886 .876 .901);
        --mauve-6: color(display-p3 .856 .846 .875);
        --mauve-7: color(display-p3 .814 .804 .84);
        --mauve-8: color(display-p3 .735 .728 .777);
        --mauve-9: color(display-p3 .555 .549 .596);
        --mauve-10: color(display-p3 .514 .508 .552);
        --mauve-11: color(display-p3 .395 .388 .424);
        --mauve-12: color(display-p3 .128 .122 .147);
        --mauve-a1: color(display-p3 .349 .024 .349 / .012);
        --mauve-a2: color(display-p3 .184 .024 .349 / .024);
        --mauve-a3: color(display-p3 .129 .008 .255 / .063);
        --mauve-a4: color(display-p3 .094 .012 .216 / .095);
        --mauve-a5: color(display-p3 .098 .008 .224 / .126);
        --mauve-a6: color(display-p3 .055 .004 .18 / .153);
        --mauve-a7: color(display-p3 .067 .008 .184 / .197);
        --mauve-a8: color(display-p3 .02 .004 .176 / .271);
        --mauve-a9: color(display-p3 .02 .004 .106 / .451);
        --mauve-a10: color(display-p3 .012 .004 .09 / .491);
        --mauve-a11: color(display-p3 .016 0 .059 / .612);
        --mauve-a12: color(display-p3 .008 0 .027 / .879);
        --slate-1: color(display-p3 .988 .988 .992);
        --slate-2: color(display-p3 .976 .976 .984);
        --slate-3: color(display-p3 .94 .941 .953);
        --slate-4: color(display-p3 .908 .909 .925);
        --slate-5: color(display-p3 .88 .881 .901);
        --slate-6: color(display-p3 .85 .852 .876);
        --slate-7: color(display-p3 .805 .808 .838);
        --slate-8: color(display-p3 .727 .733 .773);
        --slate-9: color(display-p3 .547 .553 .592);
        --slate-10: color(display-p3 .503 .512 .549);
        --slate-11: color(display-p3 .379 .392 .421);
        --slate-12: color(display-p3 .113 .125 .14);
        --slate-a1: color(display-p3 .024 .024 .349 / .012);
        --slate-a2: color(display-p3 .024 .024 .349 / .024);
        --slate-a3: color(display-p3 .004 .004 .204 / .059);
        --slate-a4: color(display-p3 .012 .012 .184 / .091);
        --slate-a5: color(display-p3 .004 .039 .2 / .122);
        --slate-a6: color(display-p3 .008 .008 .165 / .15);
        --slate-a7: color(display-p3 .008 .027 .184 / .197);
        --slate-a8: color(display-p3 .004 .031 .176 / .275);
        --slate-a9: color(display-p3 .004 .02 .106 / .455);
        --slate-a10: color(display-p3 .004 .027 .098 / .499);
        --slate-a11: color(display-p3 0 .02 .063 / .62);
        --slate-a12: color(display-p3 0 .012 .031 / .887);
        --sage-1: color(display-p3 .986 .992 .988);
        --sage-2: color(display-p3 .97 .977 .974);
        --sage-3: color(display-p3 .935 .944 .94);
        --sage-4: color(display-p3 .904 .913 .909);
        --sage-5: color(display-p3 .875 .885 .88);
        --sage-6: color(display-p3 .844 .854 .849);
        --sage-7: color(display-p3 .8 .811 .806);
        --sage-8: color(display-p3 .725 .738 .732);
        --sage-9: color(display-p3 .531 .556 .546);
        --sage-10: color(display-p3 .492 .515 .506);
        --sage-11: color(display-p3 .377 .395 .389);
        --sage-12: color(display-p3 .107 .129 .118);
        --sage-a1: color(display-p3 .024 .514 .267 / .016);
        --sage-a2: color(display-p3 .02 .267 .145 / .032);
        --sage-a3: color(display-p3 .008 .184 .125 / .067);
        --sage-a4: color(display-p3 .012 .094 .051 / .095);
        --sage-a5: color(display-p3 .008 .098 .035 / .126);
        --sage-a6: color(display-p3 .004 .078 .027 / .157);
        --sage-a7: color(display-p3 0 .059 .039 / .2);
        --sage-a8: color(display-p3 .004 .047 .031 / .275);
        --sage-a9: color(display-p3 .004 .059 .035 / .471);
        --sage-a10: color(display-p3 0 .047 .031 / .51);
        --sage-a11: color(display-p3 0 .031 .02 / .624);
        --sage-a12: color(display-p3 0 .027 .012 / .895);
        --olive-1: color(display-p3 .989 .992 .989);
        --olive-2: color(display-p3 .974 .98 .973);
        --olive-3: color(display-p3 .939 .945 .937);
        --olive-4: color(display-p3 .907 .914 .905);
        --olive-5: color(display-p3 .878 .885 .875);
        --olive-6: color(display-p3 .846 .855 .843);
        --olive-7: color(display-p3 .803 .812 .8);
        --olive-8: color(display-p3 .727 .738 .723);
        --olive-9: color(display-p3 .541 .556 .532);
        --olive-10: color(display-p3 .5 .515 .491);
        --olive-11: color(display-p3 .38 .395 .374);
        --olive-12: color(display-p3 .117 .129 .111);
        --olive-a1: color(display-p3 .024 .349 .024 / .012);
        --olive-a2: color(display-p3 .024 .302 .024 / .028);
        --olive-a3: color(display-p3 .008 .129 .008 / .063);
        --olive-a4: color(display-p3 .012 .094 .012 / .095);
        --olive-a5: color(display-p3 .035 .098 .008 / .126);
        --olive-a6: color(display-p3 .027 .078 .004 / .157);
        --olive-a7: color(display-p3 .02 .059 0 / .2);
        --olive-a8: color(display-p3 .02 .059 .004 / .279);
        --olive-a9: color(display-p3 .02 .051 .004 / .467);
        --olive-a10: color(display-p3 .024 .047 0 / .51);
        --olive-a11: color(display-p3 .012 .039 0 / .628);
        --olive-a12: color(display-p3 .008 .024 0 / .891);
        --sand-1: color(display-p3 .992 .992 .989);
        --sand-2: color(display-p3 .977 .977 .973);
        --sand-3: color(display-p3 .943 .942 .936);
        --sand-4: color(display-p3 .913 .912 .903);
        --sand-5: color(display-p3 .885 .883 .873);
        --sand-6: color(display-p3 .854 .852 .839);
        --sand-7: color(display-p3 .813 .81 .794);
        --sand-8: color(display-p3 .738 .734 .713);
        --sand-9: color(display-p3 .553 .553 .528);
        --sand-10: color(display-p3 .511 .511 .488);
        --sand-11: color(display-p3 .388 .388 .37);
        --sand-12: color(display-p3 .129 .126 .111);
        --sand-a1: color(display-p3 .349 .349 .024 / .012);
        --sand-a2: color(display-p3 .161 .161 .024 / .028);
        --sand-a3: color(display-p3 .067 .067 .008 / .063);
        --sand-a4: color(display-p3 .129 .129 .012 / .099);
        --sand-a5: color(display-p3 .098 .067 .008 / .126);
        --sand-a6: color(display-p3 .102 .075 .004 / .161);
        --sand-a7: color(display-p3 .098 .098 .004 / .208);
        --sand-a8: color(display-p3 .086 .075 .004 / .287);
        --sand-a9: color(display-p3 .051 .051 .004 / .471);
        --sand-a10: color(display-p3 .047 .047 0 / .514);
        --sand-a11: color(display-p3 .031 .031 0 / .632);
        --sand-a12: color(display-p3 .024 .02 0 / .891);
        --amber-1: color(display-p3 .995 .992 .985);
        --amber-2: color(display-p3 .994 .986 .921);
        --amber-3: color(display-p3 .994 .969 .782);
        --amber-4: color(display-p3 .989 .937 .65);
        --amber-5: color(display-p3 .97 .902 .527);
        --amber-6: color(display-p3 .936 .844 .506);
        --amber-7: color(display-p3 .89 .762 .443);
        --amber-8: color(display-p3 .85 .65 .3);
        --amber-9: color(display-p3 1 .77 .26);
        --amber-10: color(display-p3 .959 .741 .274);
        --amber-11: color(display-p3 .64 .4 0);
        --amber-12: color(display-p3 .294 .208 .145);
        --amber-a1: color(display-p3 .757 .514 .024 / .016);
        --amber-a2: color(display-p3 .902 .804 .008 / .079);
        --amber-a3: color(display-p3 .965 .859 .004 / .22);
        --amber-a4: color(display-p3 .969 .82 .004 / .35);
        --amber-a5: color(display-p3 .933 .796 .004 / .475);
        --amber-a6: color(display-p3 .875 .682 .004 / .495);
        --amber-a7: color(display-p3 .804 .573 0 / .557);
        --amber-a8: color(display-p3 .788 .502 0 / .699);
        --amber-a9: color(display-p3 1 .686 0 / .742);
        --amber-a10: color(display-p3 .945 .643 0 / .726);
        --amber-a11: color(display-p3 .64 .4 0);
        --amber-a12: color(display-p3 .294 .208 .145);
        --blue-1: color(display-p3 .986 .992 .999);
        --blue-2: color(display-p3 .96 .979 .998);
        --blue-3: color(display-p3 .912 .956 .991);
        --blue-4: color(display-p3 .853 .932 1);
        --blue-5: color(display-p3 .788 .894 .998);
        --blue-6: color(display-p3 .709 .843 .976);
        --blue-7: color(display-p3 .606 .777 .947);
        --blue-8: color(display-p3 .451 .688 .917);
        --blue-9: color(display-p3 .247 .556 .969);
        --blue-10: color(display-p3 .234 .523 .912);
        --blue-11: color(display-p3 .15 .44 .84);
        --blue-12: color(display-p3 .102 .193 .379);
        --blue-a1: color(display-p3 .024 .514 1 / .016);
        --blue-a2: color(display-p3 .024 .514 .906 / .04);
        --blue-a3: color(display-p3 .012 .506 .914 / .087);
        --blue-a4: color(display-p3 .008 .545 1 / .146);
        --blue-a5: color(display-p3 .004 .502 .984 / .212);
        --blue-a6: color(display-p3 .004 .463 .922 / .291);
        --blue-a7: color(display-p3 .004 .431 .863 / .393);
        --blue-a8: color(display-p3 0 .427 .851 / .55);
        --blue-a9: color(display-p3 0 .412 .961 / .753);
        --blue-a10: color(display-p3 0 .376 .886 / .765);
        --blue-a11: color(display-p3 .15 .44 .84);
        --blue-a12: color(display-p3 .102 .193 .379);
        --bronze-1: color(display-p3 .991 .988 .988);
        --bronze-2: color(display-p3 .989 .97 .961);
        --bronze-3: color(display-p3 .958 .932 .919);
        --bronze-4: color(display-p3 .929 .894 .877);
        --bronze-5: color(display-p3 .898 .853 .832);
        --bronze-6: color(display-p3 .861 .805 .778);
        --bronze-7: color(display-p3 .812 .739 .706);
        --bronze-8: color(display-p3 .741 .647 .606);
        --bronze-9: color(display-p3 .611 .507 .455);
        --bronze-10: color(display-p3 .563 .461 .414);
        --bronze-11: color(display-p3 .471 .373 .336);
        --bronze-12: color(display-p3 .251 .191 .172);
        --bronze-a1: color(display-p3 .349 .024 .024 / .012);
        --bronze-a2: color(display-p3 .71 .22 .024 / .04);
        --bronze-a3: color(display-p3 .482 .2 .008 / .083);
        --bronze-a4: color(display-p3 .424 .133 .004 / .122);
        --bronze-a5: color(display-p3 .4 .145 .004 / .169);
        --bronze-a6: color(display-p3 .388 .125 .004 / .224);
        --bronze-a7: color(display-p3 .365 .11 .004 / .295);
        --bronze-a8: color(display-p3 .341 .102 .004 / .393);
        --bronze-a9: color(display-p3 .29 .094 0 / .546);
        --bronze-a10: color(display-p3 .255 .082 0 / .585);
        --bronze-a11: color(display-p3 .471 .373 .336);
        --bronze-a12: color(display-p3 .251 .191 .172);
        --brown-1: color(display-p3 .995 .992 .989);
        --brown-2: color(display-p3 .987 .976 .964);
        --brown-3: color(display-p3 .959 .936 .909);
        --brown-4: color(display-p3 .934 .897 .855);
        --brown-5: color(display-p3 .909 .856 .798);
        --brown-6: color(display-p3 .88 .808 .73);
        --brown-7: color(display-p3 .841 .742 .639);
        --brown-8: color(display-p3 .782 .647 .514);
        --brown-9: color(display-p3 .651 .505 .368);
        --brown-10: color(display-p3 .601 .465 .344);
        --brown-11: color(display-p3 .485 .374 .288);
        --brown-12: color(display-p3 .236 .202 .183);
        --brown-a1: color(display-p3 .675 .349 .024 / .012);
        --brown-a2: color(display-p3 .675 .349 .024 / .036);
        --brown-a3: color(display-p3 .573 .314 .012 / .091);
        --brown-a4: color(display-p3 .545 .302 .008 / .146);
        --brown-a5: color(display-p3 .561 .29 .004 / .204);
        --brown-a6: color(display-p3 .553 .294 .004 / .271);
        --brown-a7: color(display-p3 .557 .286 .004 / .361);
        --brown-a8: color(display-p3 .549 .275 .004 / .487);
        --brown-a9: color(display-p3 .447 .22 0 / .632);
        --brown-a10: color(display-p3 .388 .188 0 / .655);
        --brown-a11: color(display-p3 .485 .374 .288);
        --brown-a12: color(display-p3 .236 .202 .183);
        --crimson-1: color(display-p3 .998 .989 .992);
        --crimson-2: color(display-p3 .991 .969 .976);
        --crimson-3: color(display-p3 .987 .917 .941);
        --crimson-4: color(display-p3 .975 .866 .904);
        --crimson-5: color(display-p3 .953 .813 .864);
        --crimson-6: color(display-p3 .921 .755 .817);
        --crimson-7: color(display-p3 .88 .683 .761);
        --crimson-8: color(display-p3 .834 .592 .694);
        --crimson-9: color(display-p3 .843 .298 .507);
        --crimson-10: color(display-p3 .807 .266 .468);
        --crimson-11: color(display-p3 .731 .195 .388);
        --crimson-12: color(display-p3 .352 .111 .221);
        --crimson-a1: color(display-p3 .675 .024 .349 / .012);
        --crimson-a2: color(display-p3 .757 .02 .267 / .032);
        --crimson-a3: color(display-p3 .859 .008 .294 / .083);
        --crimson-a4: color(display-p3 .827 .008 .298 / .134);
        --crimson-a5: color(display-p3 .753 .008 .275 / .189);
        --crimson-a6: color(display-p3 .682 .004 .247 / .244);
        --crimson-a7: color(display-p3 .62 .004 .251 / .318);
        --crimson-a8: color(display-p3 .6 .004 .251 / .408);
        --crimson-a9: color(display-p3 .776 0 .298 / .702);
        --crimson-a10: color(display-p3 .737 0 .275 / .734);
        --crimson-a11: color(display-p3 .731 .195 .388);
        --crimson-a12: color(display-p3 .352 .111 .221);
        --cyan-1: color(display-p3 .982 .992 .996);
        --cyan-2: color(display-p3 .955 .981 .984);
        --cyan-3: color(display-p3 .888 .965 .975);
        --cyan-4: color(display-p3 .821 .941 .959);
        --cyan-5: color(display-p3 .751 .907 .935);
        --cyan-6: color(display-p3 .671 .862 .9);
        --cyan-7: color(display-p3 .564 .8 .854);
        --cyan-8: color(display-p3 .388 .715 .798);
        --cyan-9: color(display-p3 .282 .627 .765);
        --cyan-10: color(display-p3 .264 .583 .71);
        --cyan-11: color(display-p3 .08 .48 .63);
        --cyan-12: color(display-p3 .108 .232 .277);
        --cyan-a1: color(display-p3 .02 .608 .804 / .02);
        --cyan-a2: color(display-p3 .02 .557 .647 / .044);
        --cyan-a3: color(display-p3 .004 .694 .796 / .114);
        --cyan-a4: color(display-p3 .004 .678 .784 / .181);
        --cyan-a5: color(display-p3 .004 .624 .733 / .248);
        --cyan-a6: color(display-p3 .004 .584 .706 / .33);
        --cyan-a7: color(display-p3 .004 .541 .667 / .436);
        --cyan-a8: color(display-p3 0 .533 .667 / .612);
        --cyan-a9: color(display-p3 0 .482 .675 / .718);
        --cyan-a10: color(display-p3 0 .435 .608 / .738);
        --cyan-a11: color(display-p3 .08 .48 .63);
        --cyan-a12: color(display-p3 .108 .232 .277);
        --gold-1: color(display-p3 .992 .992 .989);
        --gold-2: color(display-p3 .98 .976 .953);
        --gold-3: color(display-p3 .947 .94 .909);
        --gold-4: color(display-p3 .914 .904 .865);
        --gold-5: color(display-p3 .88 .865 .816);
        --gold-6: color(display-p3 .84 .818 .756);
        --gold-7: color(display-p3 .788 .753 .677);
        --gold-8: color(display-p3 .715 .66 .565);
        --gold-9: color(display-p3 .579 .517 .41);
        --gold-10: color(display-p3 .538 .479 .38);
        --gold-11: color(display-p3 .433 .386 .305);
        --gold-12: color(display-p3 .227 .209 .173);
        --gold-a1: color(display-p3 .349 .349 .024 / .012);
        --gold-a2: color(display-p3 .592 .514 .024 / .048);
        --gold-a3: color(display-p3 .4 .357 .012 / .091);
        --gold-a4: color(display-p3 .357 .298 .008 / .134);
        --gold-a5: color(display-p3 .345 .282 .004 / .185);
        --gold-a6: color(display-p3 .341 .263 .004 / .244);
        --gold-a7: color(display-p3 .345 .235 .004 / .322);
        --gold-a8: color(display-p3 .345 .22 .004 / .436);
        --gold-a9: color(display-p3 .286 .18 0 / .589);
        --gold-a10: color(display-p3 .255 .161 0 / .62);
        --gold-a11: color(display-p3 .433 .386 .305);
        --gold-a12: color(display-p3 .227 .209 .173);
        --grass-1: color(display-p3 .986 .996 .985);
        --grass-2: color(display-p3 .966 .983 .964);
        --grass-3: color(display-p3 .923 .965 .917);
        --grass-4: color(display-p3 .872 .94 .865);
        --grass-5: color(display-p3 .811 .908 .802);
        --grass-6: color(display-p3 .733 .864 .724);
        --grass-7: color(display-p3 .628 .803 .622);
        --grass-8: color(display-p3 .477 .72 .482);
        --grass-9: color(display-p3 .38 .647 .378);
        --grass-10: color(display-p3 .344 .598 .342);
        --grass-11: color(display-p3 .263 .488 .261);
        --grass-12: color(display-p3 .151 .233 .153);
        --grass-a1: color(display-p3 .024 .757 .024 / .016);
        --grass-a2: color(display-p3 .024 .565 .024 / .036);
        --grass-a3: color(display-p3 .059 .576 .008 / .083);
        --grass-a4: color(display-p3 .035 .565 .008 / .134);
        --grass-a5: color(display-p3 .047 .545 .008 / .197);
        --grass-a6: color(display-p3 .031 .502 .004 / .275);
        --grass-a7: color(display-p3 .012 .482 .004 / .377);
        --grass-a8: color(display-p3 0 .467 .008 / .522);
        --grass-a9: color(display-p3 .008 .435 0 / .624);
        --grass-a10: color(display-p3 .008 .388 0 / .659);
        --grass-a11: color(display-p3 .263 .488 .261);
        --grass-a12: color(display-p3 .151 .233 .153);
        --green-1: color(display-p3 .986 .996 .989);
        --green-2: color(display-p3 .963 .983 .967);
        --green-3: color(display-p3 .913 .964 .925);
        --green-4: color(display-p3 .859 .94 .879);
        --green-5: color(display-p3 .796 .907 .826);
        --green-6: color(display-p3 .718 .863 .761);
        --green-7: color(display-p3 .61 .801 .675);
        --green-8: color(display-p3 .451 .715 .559);
        --green-9: color(display-p3 .332 .634 .442);
        --green-10: color(display-p3 .308 .595 .417);
        --green-11: color(display-p3 .19 .5 .32);
        --green-12: color(display-p3 .132 .228 .18);
        --green-a1: color(display-p3 .024 .757 .267 / .016);
        --green-a2: color(display-p3 .024 .565 .129 / .036);
        --green-a3: color(display-p3 .012 .596 .145 / .087);
        --green-a4: color(display-p3 .008 .588 .145 / .142);
        --green-a5: color(display-p3 .004 .541 .157 / .204);
        --green-a6: color(display-p3 .004 .518 .157 / .283);
        --green-a7: color(display-p3 .004 .486 .165 / .389);
        --green-a8: color(display-p3 0 .478 .2 / .55);
        --green-a9: color(display-p3 0 .455 .165 / .667);
        --green-a10: color(display-p3 0 .416 .153 / .691);
        --green-a11: color(display-p3 .19 .5 .32);
        --green-a12: color(display-p3 .132 .228 .18);
        --indigo-1: color(display-p3 .992 .992 .996);
        --indigo-2: color(display-p3 .971 .977 .998);
        --indigo-3: color(display-p3 .933 .948 .992);
        --indigo-4: color(display-p3 .885 .914 1);
        --indigo-5: color(display-p3 .831 .87 1);
        --indigo-6: color(display-p3 .767 .814 .995);
        --indigo-7: color(display-p3 .685 .74 .957);
        --indigo-8: color(display-p3 .569 .639 .916);
        --indigo-9: color(display-p3 .276 .384 .837);
        --indigo-10: color(display-p3 .234 .343 .801);
        --indigo-11: color(display-p3 .256 .354 .755);
        --indigo-12: color(display-p3 .133 .175 .348);
        --indigo-a1: color(display-p3 .02 .02 .51 / .008);
        --indigo-a2: color(display-p3 .024 .161 .863 / .028);
        --indigo-a3: color(display-p3 .008 .239 .886 / .067);
        --indigo-a4: color(display-p3 .004 .247 1 / .114);
        --indigo-a5: color(display-p3 .004 .235 1 / .169);
        --indigo-a6: color(display-p3 .004 .208 .984 / .232);
        --indigo-a7: color(display-p3 .004 .176 .863 / .314);
        --indigo-a8: color(display-p3 .004 .165 .812 / .432);
        --indigo-a9: color(display-p3 0 .153 .773 / .726);
        --indigo-a10: color(display-p3 0 .137 .737 / .765);
        --indigo-a11: color(display-p3 .256 .354 .755);
        --indigo-a12: color(display-p3 .133 .175 .348);
        --iris-1: color(display-p3 .992 .992 .999);
        --iris-2: color(display-p3 .972 .973 .998);
        --iris-3: color(display-p3 .943 .945 .992);
        --iris-4: color(display-p3 .902 .906 1);
        --iris-5: color(display-p3 .857 .861 1);
        --iris-6: color(display-p3 .799 .805 .987);
        --iris-7: color(display-p3 .721 .727 .955);
        --iris-8: color(display-p3 .61 .619 .918);
        --iris-9: color(display-p3 .357 .357 .81);
        --iris-10: color(display-p3 .318 .318 .774);
        --iris-11: color(display-p3 .337 .326 .748);
        --iris-12: color(display-p3 .154 .161 .371);
        --iris-a1: color(display-p3 .02 .02 1 / .008);
        --iris-a2: color(display-p3 .024 .024 .863 / .028);
        --iris-a3: color(display-p3 .004 .071 .871 / .059);
        --iris-a4: color(display-p3 .012 .051 1 / .099);
        --iris-a5: color(display-p3 .008 .035 1 / .142);
        --iris-a6: color(display-p3 0 .02 .941 / .2);
        --iris-a7: color(display-p3 .004 .02 .847 / .279);
        --iris-a8: color(display-p3 .004 .024 .788 / .389);
        --iris-a9: color(display-p3 0 0 .706 / .644);
        --iris-a10: color(display-p3 0 0 .667 / .683);
        --iris-a11: color(display-p3 .337 .326 .748);
        --iris-a12: color(display-p3 .154 .161 .371);
        --jade-1: color(display-p3 .986 .996 .992);
        --jade-2: color(display-p3 .962 .983 .969);
        --jade-3: color(display-p3 .912 .965 .932);
        --jade-4: color(display-p3 .858 .941 .893);
        --jade-5: color(display-p3 .795 .909 .847);
        --jade-6: color(display-p3 .715 .864 .791);
        --jade-7: color(display-p3 .603 .802 .718);
        --jade-8: color(display-p3 .44 .72 .629);
        --jade-9: color(display-p3 .319 .63 .521);
        --jade-10: color(display-p3 .299 .592 .488);
        --jade-11: color(display-p3 .15 .5 .37);
        --jade-12: color(display-p3 .142 .229 .194);
        --jade-a1: color(display-p3 .024 .757 .514 / .016);
        --jade-a2: color(display-p3 .024 .612 .22 / .04);
        --jade-a3: color(display-p3 .012 .596 .235 / .087);
        --jade-a4: color(display-p3 .008 .588 .255 / .142);
        --jade-a5: color(display-p3 .004 .561 .251 / .204);
        --jade-a6: color(display-p3 .004 .525 .278 / .287);
        --jade-a7: color(display-p3 .004 .506 .29 / .397);
        --jade-a8: color(display-p3 0 .506 .337 / .561);
        --jade-a9: color(display-p3 0 .459 .298 / .683);
        --jade-a10: color(display-p3 0 .42 .271 / .702);
        --jade-a11: color(display-p3 .15 .5 .37);
        --jade-a12: color(display-p3 .142 .229 .194);
        --lime-1: color(display-p3 .989 .992 .981);
        --lime-2: color(display-p3 .975 .98 .954);
        --lime-3: color(display-p3 .939 .965 .851);
        --lime-4: color(display-p3 .896 .94 .76);
        --lime-5: color(display-p3 .843 .903 .678);
        --lime-6: color(display-p3 .778 .852 .599);
        --lime-7: color(display-p3 .694 .784 .508);
        --lime-8: color(display-p3 .585 .707 .378);
        --lime-9: color(display-p3 .78 .928 .466);
        --lime-10: color(display-p3 .734 .896 .397);
        --lime-11: color(display-p3 .386 .482 .227);
        --lime-12: color(display-p3 .222 .25 .128);
        --lime-a1: color(display-p3 .412 .608 .02 / .02);
        --lime-a2: color(display-p3 .514 .592 .024 / .048);
        --lime-a3: color(display-p3 .584 .765 .008 / .15);
        --lime-a4: color(display-p3 .561 .757 .004 / .24);
        --lime-a5: color(display-p3 .514 .698 .004 / .322);
        --lime-a6: color(display-p3 .443 .627 0 / .4);
        --lime-a7: color(display-p3 .376 .561 .004 / .491);
        --lime-a8: color(display-p3 .333 .529 0 / .624);
        --lime-a9: color(display-p3 .588 .867 0 / .534);
        --lime-a10: color(display-p3 .561 .827 0 / .604);
        --lime-a11: color(display-p3 .386 .482 .227);
        --lime-a12: color(display-p3 .222 .25 .128);
        --mint-1: color(display-p3 .98 .995 .992);
        --mint-2: color(display-p3 .957 .985 .977);
        --mint-3: color(display-p3 .888 .972 .95);
        --mint-4: color(display-p3 .819 .951 .916);
        --mint-5: color(display-p3 .747 .918 .873);
        --mint-6: color(display-p3 .668 .87 .818);
        --mint-7: color(display-p3 .567 .805 .744);
        --mint-8: color(display-p3 .42 .724 .649);
        --mint-9: color(display-p3 .62 .908 .834);
        --mint-10: color(display-p3 .585 .871 .797);
        --mint-11: color(display-p3 .203 .463 .397);
        --mint-12: color(display-p3 .136 .259 .236);
        --mint-a1: color(display-p3 .02 .804 .608 / .02);
        --mint-a2: color(display-p3 .02 .647 .467 / .044);
        --mint-a3: color(display-p3 .004 .761 .553 / .114);
        --mint-a4: color(display-p3 .004 .741 .545 / .181);
        --mint-a5: color(display-p3 .004 .678 .51 / .255);
        --mint-a6: color(display-p3 .004 .616 .463 / .334);
        --mint-a7: color(display-p3 .004 .549 .412 / .432);
        --mint-a8: color(display-p3 0 .529 .392 / .581);
        --mint-a9: color(display-p3 .004 .765 .569 / .381);
        --mint-a10: color(display-p3 .004 .69 .51 / .416);
        --mint-a11: color(display-p3 .203 .463 .397);
        --mint-a12: color(display-p3 .136 .259 .236);
        --orange-1: color(display-p3 .995 .988 .985);
        --orange-2: color(display-p3 .994 .968 .934);
        --orange-3: color(display-p3 .989 .938 .85);
        --orange-4: color(display-p3 1 .874 .687);
        --orange-5: color(display-p3 1 .821 .583);
        --orange-6: color(display-p3 .975 .767 .545);
        --orange-7: color(display-p3 .919 .693 .486);
        --orange-8: color(display-p3 .877 .597 .379);
        --orange-9: color(display-p3 .9 .45 .2);
        --orange-10: color(display-p3 .87 .409 .164);
        --orange-11: color(display-p3 .76 .34 0);
        --orange-12: color(display-p3 .323 .185 .127);
        --orange-a1: color(display-p3 .757 .267 .024 / .016);
        --orange-a2: color(display-p3 .886 .533 .008 / .067);
        --orange-a3: color(display-p3 .922 .584 .008 / .15);
        --orange-a4: color(display-p3 1 .604 .004 / .314);
        --orange-a5: color(display-p3 1 .569 .004 / .416);
        --orange-a6: color(display-p3 .949 .494 .004 / .455);
        --orange-a7: color(display-p3 .839 .408 0 / .514);
        --orange-a8: color(display-p3 .804 .349 0 / .62);
        --orange-a9: color(display-p3 .878 .314 0 / .8);
        --orange-a10: color(display-p3 .843 .29 0 / .836);
        --orange-a11: color(display-p3 .76 .34 0);
        --orange-a12: color(display-p3 .323 .185 .127);
        --pink-1: color(display-p3 .998 .989 .996);
        --pink-2: color(display-p3 .992 .97 .985);
        --pink-3: color(display-p3 .981 .917 .96);
        --pink-4: color(display-p3 .963 .867 .932);
        --pink-5: color(display-p3 .939 .815 .899);
        --pink-6: color(display-p3 .907 .756 .859);
        --pink-7: color(display-p3 .869 .683 .81);
        --pink-8: color(display-p3 .825 .59 .751);
        --pink-9: color(display-p3 .775 .297 .61);
        --pink-10: color(display-p3 .748 .27 .581);
        --pink-11: color(display-p3 .698 .219 .528);
        --pink-12: color(display-p3 .363 .101 .279);
        --pink-a1: color(display-p3 .675 .024 .675 / .012);
        --pink-a2: color(display-p3 .757 .02 .51 / .032);
        --pink-a3: color(display-p3 .765 .008 .529 / .083);
        --pink-a4: color(display-p3 .737 .008 .506 / .134);
        --pink-a5: color(display-p3 .663 .004 .451 / .185);
        --pink-a6: color(display-p3 .616 .004 .424 / .244);
        --pink-a7: color(display-p3 .596 .004 .412 / .318);
        --pink-a8: color(display-p3 .573 .004 .404 / .412);
        --pink-a9: color(display-p3 .682 0 .447 / .702);
        --pink-a10: color(display-p3 .655 0 .424 / .73);
        --pink-a11: color(display-p3 .698 .219 .528);
        --pink-a12: color(display-p3 .363 .101 .279);
        --plum-1: color(display-p3 .995 .988 .999);
        --plum-2: color(display-p3 .988 .971 .99);
        --plum-3: color(display-p3 .973 .923 .98);
        --plum-4: color(display-p3 .953 .875 .966);
        --plum-5: color(display-p3 .926 .825 .945);
        --plum-6: color(display-p3 .89 .765 .916);
        --plum-7: color(display-p3 .84 .686 .877);
        --plum-8: color(display-p3 .775 .58 .832);
        --plum-9: color(display-p3 .624 .313 .708);
        --plum-10: color(display-p3 .587 .29 .667);
        --plum-11: color(display-p3 .543 .263 .619);
        --plum-12: color(display-p3 .299 .114 .352);
        --plum-a1: color(display-p3 .675 .024 1 / .012);
        --plum-a2: color(display-p3 .58 .024 .58 / .028);
        --plum-a3: color(display-p3 .655 .008 .753 / .079);
        --plum-a4: color(display-p3 .627 .008 .722 / .126);
        --plum-a5: color(display-p3 .58 .004 .69 / .177);
        --plum-a6: color(display-p3 .537 .004 .655 / .236);
        --plum-a7: color(display-p3 .49 .004 .616 / .314);
        --plum-a8: color(display-p3 .471 .004 .6 / .42);
        --plum-a9: color(display-p3 .451 0 .576 / .687);
        --plum-a10: color(display-p3 .42 0 .529 / .71);
        --plum-a11: color(display-p3 .543 .263 .619);
        --plum-a12: color(display-p3 .299 .114 .352);
        --purple-1: color(display-p3 .995 .988 .996);
        --purple-2: color(display-p3 .983 .971 .993);
        --purple-3: color(display-p3 .963 .931 .989);
        --purple-4: color(display-p3 .937 .888 .981);
        --purple-5: color(display-p3 .904 .837 .966);
        --purple-6: color(display-p3 .86 .774 .942);
        --purple-7: color(display-p3 .799 .69 .91);
        --purple-8: color(display-p3 .719 .583 .874);
        --purple-9: color(display-p3 .523 .318 .751);
        --purple-10: color(display-p3 .483 .289 .7);
        --purple-11: color(display-p3 .473 .281 .687);
        --purple-12: color(display-p3 .234 .132 .363);
        --purple-a1: color(display-p3 .675 .024 .675 / .012);
        --purple-a2: color(display-p3 .443 .024 .722 / .028);
        --purple-a3: color(display-p3 .506 .008 .835 / .071);
        --purple-a4: color(display-p3 .451 .004 .831 / .114);
        --purple-a5: color(display-p3 .431 .004 .788 / .165);
        --purple-a6: color(display-p3 .384 .004 .745 / .228);
        --purple-a7: color(display-p3 .357 .004 .71 / .31);
        --purple-a8: color(display-p3 .322 .004 .702 / .416);
        --purple-a9: color(display-p3 .298 0 .639 / .683);
        --purple-a10: color(display-p3 .271 0 .58 / .71);
        --purple-a11: color(display-p3 .473 .281 .687);
        --purple-a12: color(display-p3 .234 .132 .363);
        --red-1: color(display-p3 .998 .989 .988);
        --red-2: color(display-p3 .995 .971 .971);
        --red-3: color(display-p3 .985 .925 .925);
        --red-4: color(display-p3 .999 .866 .866);
        --red-5: color(display-p3 .984 .812 .811);
        --red-6: color(display-p3 .955 .751 .749);
        --red-7: color(display-p3 .915 .675 .672);
        --red-8: color(display-p3 .872 .575 .572);
        --red-9: color(display-p3 .83 .329 .324);
        --red-10: color(display-p3 .798 .294 .285);
        --red-11: color(display-p3 .744 .234 .222);
        --red-12: color(display-p3 .36 .115 .143);
        --red-a1: color(display-p3 .675 .024 .024 / .012);
        --red-a2: color(display-p3 .863 .024 .024 / .028);
        --red-a3: color(display-p3 .792 .008 .008 / .075);
        --red-a4: color(display-p3 1 .008 .008 / .134);
        --red-a5: color(display-p3 .918 .008 .008 / .189);
        --red-a6: color(display-p3 .831 .02 .004 / .251);
        --red-a7: color(display-p3 .741 .016 .004 / .33);
        --red-a8: color(display-p3 .698 .012 .004 / .428);
        --red-a9: color(display-p3 .749 .008 0 / .675);
        --red-a10: color(display-p3 .714 .012 0 / .714);
        --red-a11: color(display-p3 .744 .234 .222);
        --red-a12: color(display-p3 .36 .115 .143);
        --ruby-1: color(display-p3 .998 .989 .992);
        --ruby-2: color(display-p3 .995 .971 .974);
        --ruby-3: color(display-p3 .983 .92 .928);
        --ruby-4: color(display-p3 .987 .869 .885);
        --ruby-5: color(display-p3 .968 .817 .839);
        --ruby-6: color(display-p3 .937 .758 .786);
        --ruby-7: color(display-p3 .897 .685 .721);
        --ruby-8: color(display-p3 .851 .588 .639);
        --ruby-9: color(display-p3 .83 .323 .408);
        --ruby-10: color(display-p3 .795 .286 .375);
        --ruby-11: color(display-p3 .728 .211 .311);
        --ruby-12: color(display-p3 .36 .115 .171);
        --ruby-a1: color(display-p3 .675 .024 .349 / .012);
        --ruby-a2: color(display-p3 .863 .024 .024 / .028);
        --ruby-a3: color(display-p3 .804 .008 .11 / .079);
        --ruby-a4: color(display-p3 .91 .008 .125 / .13);
        --ruby-a5: color(display-p3 .831 .004 .133 / .185);
        --ruby-a6: color(display-p3 .745 .004 .118 / .244);
        --ruby-a7: color(display-p3 .678 .004 .114 / .314);
        --ruby-a8: color(display-p3 .639 .004 .125 / .412);
        --ruby-a9: color(display-p3 .753 0 .129 / .679);
        --ruby-a10: color(display-p3 .714 0 .125 / .714);
        --ruby-a11: color(display-p3 .728 .211 .311);
        --ruby-a12: color(display-p3 .36 .115 .171);
        --sky-1: color(display-p3 .98 .995 .999);
        --sky-2: color(display-p3 .953 .98 .99);
        --sky-3: color(display-p3 .899 .963 .989);
        --sky-4: color(display-p3 .842 .937 .977);
        --sky-5: color(display-p3 .777 .9 .954);
        --sky-6: color(display-p3 .701 .851 .921);
        --sky-7: color(display-p3 .604 .785 .879);
        --sky-8: color(display-p3 .457 .696 .829);
        --sky-9: color(display-p3 .585 .877 .983);
        --sky-10: color(display-p3 .555 .845 .959);
        --sky-11: color(display-p3 .193 .448 .605);
        --sky-12: color(display-p3 .145 .241 .329);
        --sky-a1: color(display-p3 .02 .804 1 / .02);
        --sky-a2: color(display-p3 .024 .592 .757 / .048);
        --sky-a3: color(display-p3 .004 .655 .886 / .102);
        --sky-a4: color(display-p3 .004 .604 .851 / .157);
        --sky-a5: color(display-p3 .004 .565 .792 / .224);
        --sky-a6: color(display-p3 .004 .502 .737 / .299);
        --sky-a7: color(display-p3 .004 .459 .694 / .397);
        --sky-a8: color(display-p3 0 .435 .682 / .542);
        --sky-a9: color(display-p3 .004 .71 .965 / .416);
        --sky-a10: color(display-p3 .004 .647 .914 / .444);
        --sky-a11: color(display-p3 .193 .448 .605);
        --sky-a12: color(display-p3 .145 .241 .329);
        --teal-1: color(display-p3 .983 .996 .992);
        --teal-2: color(display-p3 .958 .983 .976);
        --teal-3: color(display-p3 .895 .971 .952);
        --teal-4: color(display-p3 .831 .949 .92);
        --teal-5: color(display-p3 .761 .914 .878);
        --teal-6: color(display-p3 .682 .864 .825);
        --teal-7: color(display-p3 .581 .798 .756);
        --teal-8: color(display-p3 .433 .716 .671);
        --teal-9: color(display-p3 .297 .637 .581);
        --teal-10: color(display-p3 .275 .599 .542);
        --teal-11: color(display-p3 .08 .5 .43);
        --teal-12: color(display-p3 .11 .235 .219);
        --teal-a1: color(display-p3 .024 .757 .514 / .016);
        --teal-a2: color(display-p3 .02 .647 .467 / .044);
        --teal-a3: color(display-p3 .004 .741 .557 / .106);
        --teal-a4: color(display-p3 .004 .702 .537 / .169);
        --teal-a5: color(display-p3 .004 .643 .494 / .24);
        --teal-a6: color(display-p3 .004 .569 .447 / .318);
        --teal-a7: color(display-p3 .004 .518 .424 / .42);
        --teal-a8: color(display-p3 0 .506 .424 / .569);
        --teal-a9: color(display-p3 0 .482 .404 / .702);
        --teal-a10: color(display-p3 0 .451 .369 / .726);
        --teal-a11: color(display-p3 .08 .5 .43);
        --teal-a12: color(display-p3 .11 .235 .219);
        --tomato-1: color(display-p3 .998 .989 .988);
        --tomato-2: color(display-p3 .994 .974 .969);
        --tomato-3: color(display-p3 .985 .924 .909);
        --tomato-4: color(display-p3 .996 .868 .835);
        --tomato-5: color(display-p3 .98 .812 .77);
        --tomato-6: color(display-p3 .953 .75 .698);
        --tomato-7: color(display-p3 .917 .673 .611);
        --tomato-8: color(display-p3 .875 .575 .502);
        --tomato-9: color(display-p3 .831 .345 .231);
        --tomato-10: color(display-p3 .802 .313 .2);
        --tomato-11: color(display-p3 .755 .259 .152);
        --tomato-12: color(display-p3 .335 .165 .132);
        --tomato-a1: color(display-p3 .675 .024 .024 / .012);
        --tomato-a2: color(display-p3 .757 .145 .02 / .032);
        --tomato-a3: color(display-p3 .831 .184 .012 / .091);
        --tomato-a4: color(display-p3 .976 .192 .004 / .165);
        --tomato-a5: color(display-p3 .918 .192 .004 / .232);
        --tomato-a6: color(display-p3 .847 .173 .004 / .302);
        --tomato-a7: color(display-p3 .788 .165 .004 / .389);
        --tomato-a8: color(display-p3 .749 .153 .004 / .499);
        --tomato-a9: color(display-p3 .78 .149 0 / .769);
        --tomato-a10: color(display-p3 .757 .141 0 / .8);
        --tomato-a11: color(display-p3 .755 .259 .152);
        --tomato-a12: color(display-p3 .335 .165 .132);
        --violet-1: color(display-p3 .991 .988 .995);
        --violet-2: color(display-p3 .978 .974 .998);
        --violet-3: color(display-p3 .953 .943 .993);
        --violet-4: color(display-p3 .916 .897 1);
        --violet-5: color(display-p3 .876 .851 1);
        --violet-6: color(display-p3 .825 .793 .981);
        --violet-7: color(display-p3 .752 .712 .943);
        --violet-8: color(display-p3 .654 .602 .902);
        --violet-9: color(display-p3 .417 .341 .784);
        --violet-10: color(display-p3 .381 .306 .741);
        --violet-11: color(display-p3 .383 .317 .702);
        --violet-12: color(display-p3 .179 .15 .359);
        --violet-a1: color(display-p3 .349 .024 .675 / .012);
        --violet-a2: color(display-p3 .161 .024 .863 / .028);
        --violet-a3: color(display-p3 .204 .004 .871 / .059);
        --violet-a4: color(display-p3 .196 .004 1 / .102);
        --violet-a5: color(display-p3 .165 .008 1 / .15);
        --violet-a6: color(display-p3 .153 .004 .906 / .208);
        --violet-a7: color(display-p3 .141 .004 .796 / .287);
        --violet-a8: color(display-p3 .133 .004 .753 / .397);
        --violet-a9: color(display-p3 .114 0 .675 / .659);
        --violet-a10: color(display-p3 .11 0 .627 / .695);
        --violet-a11: color(display-p3 .383 .317 .702);
        --violet-a12: color(display-p3 .179 .15 .359);
        --yellow-1: color(display-p3 .992 .992 .978);
        --yellow-2: color(display-p3 .995 .99 .922);
        --yellow-3: color(display-p3 .997 .982 .749);
        --yellow-4: color(display-p3 .992 .953 .627);
        --yellow-5: color(display-p3 .984 .91 .51);
        --yellow-6: color(display-p3 .934 .847 .474);
        --yellow-7: color(display-p3 .876 .785 .46);
        --yellow-8: color(display-p3 .811 .689 .313);
        --yellow-9: color(display-p3 1 .92 .22);
        --yellow-10: color(display-p3 .977 .868 .291);
        --yellow-11: color(display-p3 .6 .44 0);
        --yellow-12: color(display-p3 .271 .233 .137);
        --yellow-a1: color(display-p3 .675 .675 .024 / .024);
        --yellow-a2: color(display-p3 .953 .855 .008 / .079);
        --yellow-a3: color(display-p3 .988 .925 .004 / .251);
        --yellow-a4: color(display-p3 .98 .875 .004 / .373);
        --yellow-a5: color(display-p3 .969 .816 .004 / .491);
        --yellow-a6: color(display-p3 .875 .71 0 / .526);
        --yellow-a7: color(display-p3 .769 .604 0 / .542);
        --yellow-a8: color(display-p3 .725 .549 0 / .687);
        --yellow-a9: color(display-p3 1 .898 0 / .781);
        --yellow-a10: color(display-p3 .969 .812 0 / .71);
        --yellow-a11: color(display-p3 .6 .44 0);
        --yellow-a12: color(display-p3 .271 .233 .137);
        --gray-surface: color(display-p3 1 1 1 / .8);
        --mauve-surface: color(display-p3 1 1 1 / .8);
        --slate-surface: color(display-p3 1 1 1 / .8);
        --sage-surface: color(display-p3 1 1 1 / .8);
        --olive-surface: color(display-p3 1 1 1 / .8);
        --sand-surface: color(display-p3 1 1 1 / .8);
        --amber-surface: color(display-p3 .9922 .9843 .902 / .8);
        --blue-surface: color(display-p3 .9529 .9765 .9961 / .8);
        --bronze-surface: color(display-p3 .9843 .9608 .9529 / .8);
        --brown-surface: color(display-p3 .9843 .9725 .9569 / .8);
        --crimson-surface: color(display-p3 .9922 .9608 .9725 / .8);
        --cyan-surface: color(display-p3 .9412 .9765 .9804 / .8);
        --gold-surface: color(display-p3 .9765 .9725 .9412 / .8);
        --grass-surface: color(display-p3 .9569 .9804 .9569 / .8);
        --green-surface: color(display-p3 .9569 .9804 .9608 / .8);
        --indigo-surface: color(display-p3 .9647 .9725 .9961 / .8);
        --iris-surface: color(display-p3 .9647 .9647 .9961 / .8);
        --jade-surface: color(display-p3 .9529 .9804 .9608 / .8);
        --lime-surface: color(display-p3 .9725 .9765 .9412 / .8);
        --mint-surface: color(display-p3 .9451 .9804 .9725 / .8);
        --orange-surface: color(display-p3 .9961 .9608 .9176 / .8);
        --pink-surface: color(display-p3 .9922 .9608 .9804 / .8);
        --plum-surface: color(display-p3 .9843 .9647 .9843 / .8);
        --purple-surface: color(display-p3 .9804 .9647 .9922 / .8);
        --red-surface: color(display-p3 .9961 .9647 .9647 / .8);
        --ruby-surface: color(display-p3 .9961 .9647 .9647 / .8);
        --sky-surface: color(display-p3 .9412 .9765 .9843 / .8);
        --teal-surface: color(display-p3 .9451 .9804 .9725 / .8);
        --tomato-surface: color(display-p3 .9922 .9647 .9608 / .8);
        --violet-surface: color(display-p3 .9725 .9647 .9961 / .8);
        --yellow-surface: color(display-p3 .9961 .9922 .902 / .8);
      }
    }
  }
}

.dark, .dark-theme {
  --gray-1: #111;
  --gray-2: #191919;
  --gray-3: #222;
  --gray-4: #2a2a2a;
  --gray-5: #313131;
  --gray-6: #3a3a3a;
  --gray-7: #484848;
  --gray-8: #606060;
  --gray-9: #6e6e6e;
  --gray-10: #7b7b7b;
  --gray-11: #b4b4b4;
  --gray-12: #eee;
  --gray-a1: rgba(0, 0, 0, 0);
  --gray-a2: rgba(255, 255, 255, .035);
  --gray-a3: rgba(255, 255, 255, .07);
  --gray-a4: rgba(255, 255, 255, .106);
  --gray-a5: rgba(255, 255, 255, .133);
  --gray-a6: rgba(255, 255, 255, .173);
  --gray-a7: rgba(255, 255, 255, .23);
  --gray-a8: rgba(255, 255, 255, .333);
  --gray-a9: rgba(255, 255, 255, .392);
  --gray-a10: rgba(255, 255, 255, .447);
  --gray-a11: rgba(255, 255, 255, .686);
  --gray-a12: rgba(255, 255, 255, .93);
  --mauve-1: #121113;
  --mauve-2: #1a191b;
  --mauve-3: #232225;
  --mauve-4: #2b292d;
  --mauve-5: #323035;
  --mauve-6: #3c393f;
  --mauve-7: #49474e;
  --mauve-8: #625f69;
  --mauve-9: #6f6d78;
  --mauve-10: #7c7a85;
  --mauve-11: #b5b2bc;
  --mauve-12: #eeeef0;
  --mauve-a1: rgba(0, 0, 0, 0);
  --mauve-a2: rgba(245, 244, 246, .035);
  --mauve-a3: rgba(235, 234, 248, .08);
  --mauve-a4: rgba(238, 229, 248, .114);
  --mauve-a5: rgba(239, 230, 254, .145);
  --mauve-a6: rgba(241, 230, 253, .19);
  --mauve-a7: rgba(238, 233, 255, .25);
  --mauve-a8: rgba(238, 231, 255, .365);
  --mauve-a9: rgba(234, 230, 253, .43);
  --mauve-a10: rgba(236, 233, 253, .486);
  --mauve-a11: rgba(245, 241, 255, .718);
  --mauve-a12: rgba(253, 253, 255, .937);
  --slate-1: #111113;
  --slate-2: #18191b;
  --slate-3: #212225;
  --slate-4: #272a2d;
  --slate-5: #2e3135;
  --slate-6: #363a3f;
  --slate-7: #43484e;
  --slate-8: #5a6169;
  --slate-9: #696e77;
  --slate-10: #777b84;
  --slate-11: #b0b4ba;
  --slate-12: #edeef0;
  --slate-a1: rgba(0, 0, 0, 0);
  --slate-a2: rgba(216, 244, 246, .035);
  --slate-a3: rgba(221, 234, 248, .08);
  --slate-a4: rgba(211, 237, 248, .114);
  --slate-a5: rgba(217, 237, 254, .145);
  --slate-a6: rgba(214, 235, 253, .19);
  --slate-a7: rgba(217, 237, 255, .25);
  --slate-a8: rgba(217, 237, 255, .365);
  --slate-a9: rgba(223, 235, 253, .427);
  --slate-a10: rgba(229, 237, 253, .482);
  --slate-a11: rgba(241, 247, 254, .71);
  --slate-a12: rgba(252, 253, 255, .937);
  --sage-1: #101211;
  --sage-2: #171918;
  --sage-3: #202221;
  --sage-4: #272a29;
  --sage-5: #2e3130;
  --sage-6: #373b39;
  --sage-7: #444947;
  --sage-8: #5b625f;
  --sage-9: #63706b;
  --sage-10: #717d79;
  --sage-11: #adb5b2;
  --sage-12: #eceeed;
  --sage-a1: rgba(0, 0, 0, 0);
  --sage-a2: rgba(240, 242, 241, .03);
  --sage-a3: rgba(243, 245, 244, .07);
  --sage-a4: rgba(242, 254, 253, .1);
  --sage-a5: rgba(241, 251, 250, .133);
  --sage-a6: rgba(237, 251, 244, .176);
  --sage-a7: rgba(237, 252, 247, .235);
  --sage-a8: rgba(235, 253, 246, .34);
  --sage-a9: rgba(223, 253, 242, .4);
  --sage-a10: rgba(229, 253, 246, .455);
  --sage-a11: rgba(244, 254, 251, .69);
  --sage-a12: rgba(253, 255, 254, .93);
  --olive-1: #111210;
  --olive-2: #181917;
  --olive-3: #212220;
  --olive-4: #282a27;
  --olive-5: #2f312e;
  --olive-6: #383a36;
  --olive-7: #454843;
  --olive-8: #5c625b;
  --olive-9: #687066;
  --olive-10: #767d74;
  --olive-11: #afb5ad;
  --olive-12: #eceeec;
  --olive-a1: rgba(0, 0, 0, 0);
  --olive-a2: rgba(241, 242, 240, .03);
  --olive-a3: rgba(244, 245, 243, .07);
  --olive-a4: rgba(243, 254, 242, .1);
  --olive-a5: rgba(242, 251, 241, .133);
  --olive-a6: rgba(244, 250, 237, .173);
  --olive-a7: rgba(242, 252, 237, .23);
  --olive-a8: rgba(237, 253, 235, .34);
  --olive-a9: rgba(235, 253, 231, .4);
  --olive-a10: rgba(240, 253, 236, .455);
  --olive-a11: rgba(246, 254, 244, .69);
  --olive-a12: rgba(253, 255, 253, .93);
  --sand-1: #111110;
  --sand-2: #191918;
  --sand-3: #222221;
  --sand-4: #2a2a28;
  --sand-5: #31312e;
  --sand-6: #3b3a37;
  --sand-7: #494844;
  --sand-8: #62605b;
  --sand-9: #6f6d66;
  --sand-10: #7c7b74;
  --sand-11: #b5b3ad;
  --sand-12: #eeeeec;
  --sand-a1: rgba(0, 0, 0, 0);
  --sand-a2: rgba(244, 244, 243, .035);
  --sand-a3: rgba(246, 246, 245, .075);
  --sand-a4: rgba(254, 254, 243, .106);
  --sand-a5: rgba(251, 251, 235, .137);
  --sand-a6: rgba(255, 250, 237, .176);
  --sand-a7: rgba(255, 251, 237, .235);
  --sand-a8: rgba(255, 249, 235, .34);
  --sand-a9: rgba(255, 250, 233, .396);
  --sand-a10: rgba(255, 253, 238, .45);
  --sand-a11: rgba(255, 252, 244, .69);
  --sand-a12: rgba(255, 255, 253, .93);
  --amber-1: #16120c;
  --amber-2: #1d180f;
  --amber-3: #302008;
  --amber-4: #3f2700;
  --amber-5: #4d3000;
  --amber-6: #5c3d05;
  --amber-7: #714f19;
  --amber-8: #8f6424;
  --amber-9: #ffc53d;
  --amber-10: #ffd60a;
  --amber-11: #ffca16;
  --amber-12: #ffe7b3;
  --amber-a1: rgba(230, 60, 0, .024);
  --amber-a2: rgba(253, 155, 0, .05);
  --amber-a3: rgba(250, 130, 0, .133);
  --amber-a4: rgba(252, 130, 0, .196);
  --amber-a5: rgba(253, 139, 0, .255);
  --amber-a6: rgba(253, 155, 0, .318);
  --amber-a7: rgba(255, 171, 37, .404);
  --amber-a8: rgba(255, 174, 53, .53);
  --amber-a9: #ffc53d;
  --amber-a10: #ffd60a;
  --amber-a11: #ffca16;
  --amber-a12: #ffe7b3;
  --blue-1: #0d1520;
  --blue-2: #111927;
  --blue-3: #0d2847;
  --blue-4: #003362;
  --blue-5: #004074;
  --blue-6: #104d87;
  --blue-7: #205d9e;
  --blue-8: #2870bd;
  --blue-9: #0090ff;
  --blue-10: #3b9eff;
  --blue-11: #70b8ff;
  --blue-12: #c2e6ff;
  --blue-a1: rgba(0, 77, 242, .067);
  --blue-a2: rgba(17, 102, 251, .094);
  --blue-a3: rgba(0, 119, 255, .227);
  --blue-a4: rgba(0, 117, 255, .34);
  --blue-a5: rgba(0, 129, 253, .42);
  --blue-a6: rgba(15, 137, 253, .498);
  --blue-a7: rgba(42, 145, 254, .596);
  --blue-a8: rgba(48, 148, 254, .725);
  --blue-a9: #0090ff;
  --blue-a10: #3b9eff;
  --blue-a11: #70b8ff;
  --blue-a12: #c2e6ff;
  --bronze-1: #141110;
  --bronze-2: #1c1917;
  --bronze-3: #262220;
  --bronze-4: #302a27;
  --bronze-5: #3b3330;
  --bronze-6: #493e3a;
  --bronze-7: #5a4c47;
  --bronze-8: #6f5f58;
  --bronze-9: #a18072;
  --bronze-10: #ae8c7e;
  --bronze-11: #d4b3a5;
  --bronze-12: #ede0d9;
  --bronze-a1: rgba(209, 17, 0, .016);
  --bronze-a2: rgba(251, 188, 145, .047);
  --bronze-a3: rgba(250, 206, 184, .09);
  --bronze-a4: rgba(250, 205, 182, .133);
  --bronze-a5: rgba(255, 210, 193, .176);
  --bronze-a6: rgba(255, 209, 192, .235);
  --bronze-a7: rgba(253, 208, 192, .31);
  --bronze-a8: rgba(255, 214, 197, .396);
  --bronze-a9: rgba(254, 199, 176, .608);
  --bronze-a10: rgba(254, 202, 181, .663);
  --bronze-a11: rgba(255, 215, 198, .82);
  --bronze-a12: rgba(255, 241, 233, .925);
  --brown-1: #12110f;
  --brown-2: #1c1816;
  --brown-3: #28211d;
  --brown-4: #322922;
  --brown-5: #3e3128;
  --brown-6: #4d3c2f;
  --brown-7: #614a39;
  --brown-8: #7c5f46;
  --brown-9: #ad7f58;
  --brown-10: #b88c67;
  --brown-11: #dbb594;
  --brown-12: #f2e1ca;
  --brown-a1: rgba(145, 17, 0, .008);
  --brown-a2: rgba(251, 166, 124, .047);
  --brown-a3: rgba(252, 181, 140, .098);
  --brown-a4: rgba(251, 187, 138, .14);
  --brown-a5: rgba(252, 184, 137, .192);
  --brown-a6: rgba(253, 186, 135, .255);
  --brown-a7: rgba(255, 187, 136, .337);
  --brown-a8: rgba(255, 190, 135, .45);
  --brown-a9: rgba(254, 184, 125, .66);
  --brown-a10: rgba(255, 193, 140, .7);
  --brown-a11: rgba(254, 209, 170, .85);
  --brown-a12: rgba(254, 236, 212, .95);
  --crimson-1: #191114;
  --crimson-2: #201318;
  --crimson-3: #381525;
  --crimson-4: #4d122f;
  --crimson-5: #5c1839;
  --crimson-6: #6d2545;
  --crimson-7: #873356;
  --crimson-8: #b0436e;
  --crimson-9: #e93d82;
  --crimson-10: #ee518a;
  --crimson-11: #ff92ad;
  --crimson-12: #fdd3e8;
  --crimson-a1: rgba(244, 18, 103, .035);
  --crimson-a2: rgba(242, 47, 122, .067);
  --crimson-a3: rgba(254, 42, 139, .165);
  --crimson-a4: rgba(253, 21, 135, .255);
  --crimson-a5: rgba(253, 39, 143, .318);
  --crimson-a6: rgba(254, 69, 151, .39);
  --crimson-a7: rgba(253, 85, 155, .498);
  --crimson-a8: rgba(254, 91, 155, .67);
  --crimson-a9: rgba(254, 65, 141, .91);
  --crimson-a10: rgba(255, 86, 147, .93);
  --crimson-a11: #ff92ad;
  --crimson-a12: rgba(255, 213, 234, .992);
  --cyan-1: #0b161a;
  --cyan-2: #101b20;
  --cyan-3: #082c36;
  --cyan-4: #003848;
  --cyan-5: #004558;
  --cyan-6: #045468;
  --cyan-7: #12677e;
  --cyan-8: #11809c;
  --cyan-9: #00a2c7;
  --cyan-10: #23afd0;
  --cyan-11: #4ccce6;
  --cyan-12: #b6ecf7;
  --cyan-a1: rgba(0, 145, 247, .04);
  --cyan-a2: rgba(2, 167, 242, .067);
  --cyan-a3: rgba(0, 190, 253, .157);
  --cyan-a4: rgba(0, 186, 255, .23);
  --cyan-a5: rgba(0, 190, 253, .3);
  --cyan-a6: rgba(0, 199, 253, .37);
  --cyan-a7: rgba(20, 205, 255, .46);
  --cyan-a8: rgba(17, 207, 255, .584);
  --cyan-a9: rgba(0, 207, 255, .765);
  --cyan-a10: rgba(40, 214, 255, .804);
  --cyan-a11: rgba(82, 225, 254, .898);
  --cyan-a12: rgba(187, 243, 254, .97);
  --gold-1: #121211;
  --gold-2: #1b1a17;
  --gold-3: #24231f;
  --gold-4: #2d2b26;
  --gold-5: #38352e;
  --gold-6: #444039;
  --gold-7: #544f46;
  --gold-8: #696256;
  --gold-9: #978365;
  --gold-10: #a39073;
  --gold-11: #cbb99f;
  --gold-12: #e8e2d9;
  --gold-a1: rgba(145, 145, 17, .008);
  --gold-a2: rgba(249, 226, 157, .043);
  --gold-a3: rgba(248, 236, 187, .082);
  --gold-a4: rgba(255, 238, 196, .118);
  --gold-a5: rgba(254, 236, 194, .165);
  --gold-a6: rgba(254, 235, 203, .216);
  --gold-a7: rgba(255, 237, 205, .282);
  --gold-a8: rgba(253, 234, 202, .373);
  --gold-a9: rgba(255, 219, 166, .565);
  --gold-a10: rgba(254, 223, 176, .616);
  --gold-a11: rgba(254, 231, 198, .784);
  --gold-a12: rgba(254, 247, 237, .906);
  --grass-1: #0e1511;
  --grass-2: #141a15;
  --grass-3: #1b2a1e;
  --grass-4: #1d3a24;
  --grass-5: #25482d;
  --grass-6: #2d5736;
  --grass-7: #366740;
  --grass-8: #3e7949;
  --grass-9: #46a758;
  --grass-10: #53b365;
  --grass-11: #71d083;
  --grass-12: #c2f0c2;
  --grass-a1: rgba(0, 222, 18, .02);
  --grass-a2: rgba(94, 247, 120, .04);
  --grass-a3: rgba(112, 254, 140, .106);
  --grass-a4: rgba(87, 255, 128, .173);
  --grass-a5: rgba(104, 255, 139, .23);
  --grass-a6: rgba(113, 255, 143, .294);
  --grass-a7: rgba(119, 253, 146, .365);
  --grass-a8: rgba(119, 253, 144, .44);
  --grass-a9: rgba(101, 255, 130, .63);
  --grass-a10: rgba(114, 255, 141, .682);
  --grass-a11: rgba(137, 255, 159, .804);
  --grass-a12: rgba(206, 255, 206, .937);
  --green-1: #0e1512;
  --green-2: #121b17;
  --green-3: #132d21;
  --green-4: #113b29;
  --green-5: #174933;
  --green-6: #20573e;
  --green-7: #28684a;
  --green-8: #2f7c57;
  --green-9: #30a46c;
  --green-10: #33b074;
  --green-11: #3dd68c;
  --green-12: #b1f1cb;
  --green-a1: rgba(0, 222, 69, .02);
  --green-a2: rgba(41, 249, 157, .043);
  --green-a3: rgba(34, 255, 153, .118);
  --green-a4: rgba(17, 255, 153, .176);
  --green-a5: rgba(43, 255, 162, .235);
  --green-a6: rgba(68, 255, 170, .294);
  --green-a7: rgba(80, 253, 172, .37);
  --green-a8: rgba(84, 255, 173, .45);
  --green-a9: rgba(68, 255, 164, .62);
  --green-a10: rgba(67, 254, 164, .67);
  --green-a11: rgba(70, 254, 165, .83);
  --green-a12: rgba(187, 255, 215, .94);
  --indigo-1: #11131f;
  --indigo-2: #141726;
  --indigo-3: #182449;
  --indigo-4: #1d2e62;
  --indigo-5: #253974;
  --indigo-6: #304384;
  --indigo-7: #3a4f97;
  --indigo-8: #435db1;
  --indigo-9: #3e63dd;
  --indigo-10: #5472e4;
  --indigo-11: #9eb1ff;
  --indigo-12: #d6e1ff;
  --indigo-a1: rgba(17, 51, 255, .06);
  --indigo-a2: rgba(51, 84, 250, .09);
  --indigo-a3: rgba(47, 98, 255, .235);
  --indigo-a4: rgba(53, 102, 255, .34);
  --indigo-a5: rgba(65, 113, 253, .42);
  --indigo-a6: rgba(81, 120, 253, .486);
  --indigo-a7: rgba(90, 127, 255, .565);
  --indigo-a8: rgba(91, 129, 254, .675);
  --indigo-a9: rgba(70, 113, 255, .86);
  --indigo-a10: rgba(92, 126, 254, .89);
  --indigo-a11: #9eb1ff;
  --indigo-a12: #d6e1ff;
  --iris-1: #13131e;
  --iris-2: #171625;
  --iris-3: #202248;
  --iris-4: #262a65;
  --iris-5: #303374;
  --iris-6: #3d3e82;
  --iris-7: #4a4a95;
  --iris-8: #5958b1;
  --iris-9: #5b5bd6;
  --iris-10: #6e6ade;
  --iris-11: #b1a9ff;
  --iris-12: #e0dffe;
  --iris-a1: rgba(54, 54, 254, .055);
  --iris-a2: rgba(86, 75, 249, .086);
  --iris-a3: rgba(82, 91, 255, .23);
  --iris-a4: rgba(77, 88, 255, .353);
  --iris-a5: rgba(91, 98, 253, .42);
  --iris-a6: rgba(109, 111, 253, .48);
  --iris-a7: rgba(119, 119, 254, .557);
  --iris-a8: rgba(123, 122, 254, .675);
  --iris-a9: rgba(106, 106, 254, .83);
  --iris-a10: rgba(125, 121, 255, .863);
  --iris-a11: #b1a9ff;
  --iris-a12: rgba(225, 224, 255, .996);
  --jade-1: #0d1512;
  --jade-2: #121c18;
  --jade-3: #0f2e22;
  --jade-4: #0b3b2c;
  --jade-5: #114837;
  --jade-6: #1b5745;
  --jade-7: #246854;
  --jade-8: #2a7e68;
  --jade-9: #29a383;
  --jade-10: #27b08b;
  --jade-11: #1fd8a4;
  --jade-12: #adf0d4;
  --jade-a1: rgba(0, 222, 69, .02);
  --jade-a2: rgba(39, 251, 166, .047);
  --jade-a3: rgba(2, 249, 153, .125);
  --jade-a4: rgba(0, 255, 170, .176);
  --jade-a5: rgba(17, 255, 182, .23);
  --jade-a6: rgba(52, 255, 194, .294);
  --jade-a7: rgba(69, 253, 199, .37);
  --jade-a8: rgba(72, 255, 207, .46);
  --jade-a9: rgba(56, 254, 202, .616);
  --jade-a10: rgba(49, 254, 199, .67);
  --jade-a11: rgba(33, 254, 192, .84);
  --jade-a12: rgba(184, 255, 225, .937);
  --lime-1: #11130c;
  --lime-2: #151a10;
  --lime-3: #1f2917;
  --lime-4: #29371d;
  --lime-5: #334423;
  --lime-6: #3d522a;
  --lime-7: #496231;
  --lime-8: #577538;
  --lime-9: #bdee63;
  --lime-10: #d4ff70;
  --lime-11: #bde56c;
  --lime-12: #e3f7ba;
  --lime-a1: rgba(17, 187, 0, .01);
  --lime-a2: rgba(120, 247, 0, .04);
  --lime-a3: rgba(155, 253, 76, .1);
  --lime-a4: rgba(167, 254, 92, .16);
  --lime-a5: rgba(175, 254, 101, .216);
  --lime-a6: rgba(178, 254, 109, .275);
  --lime-a7: rgba(182, 255, 111, .34);
  --lime-a8: rgba(182, 253, 109, .424);
  --lime-a9: rgba(202, 255, 105, .93);
  --lime-a10: #d4ff70;
  --lime-a11: rgba(209, 254, 119, .894);
  --lime-a12: rgba(233, 254, 191, .97);
  --mint-1: #0e1515;
  --mint-2: #0f1b1b;
  --mint-3: #092c2b;
  --mint-4: #003a38;
  --mint-5: #004744;
  --mint-6: #105650;
  --mint-7: #1e685f;
  --mint-8: #277f70;
  --mint-9: #86ead4;
  --mint-10: #a8f5e5;
  --mint-11: #58d5ba;
  --mint-12: #c4f5e1;
  --mint-a1: rgba(0, 222, 222, .02);
  --mint-a2: rgba(0, 249, 249, .043);
  --mint-a3: rgba(0, 255, 246, .114);
  --mint-a4: rgba(0, 255, 244, .173);
  --mint-a5: rgba(0, 255, 242, .227);
  --mint-a6: rgba(14, 255, 235, .29);
  --mint-a7: rgba(52, 253, 229, .37);
  --mint-a8: rgba(65, 255, 223, .463);
  --mint-a9: rgba(146, 255, 231, .914);
  --mint-a10: rgba(174, 254, 237, .96);
  --mint-a11: rgba(103, 255, 222, .824);
  --mint-a12: rgba(203, 254, 233, .96);
  --orange-1: #17120e;
  --orange-2: #1e160f;
  --orange-3: #331e0b;
  --orange-4: #462100;
  --orange-5: #562800;
  --orange-6: #66350c;
  --orange-7: #7e451d;
  --orange-8: #a35829;
  --orange-9: #f76b15;
  --orange-10: #ff801f;
  --orange-11: #ffa057;
  --orange-12: #ffe0c2;
  --orange-a1: rgba(236, 54, 0, .027);
  --orange-a2: rgba(254, 109, 0, .055);
  --orange-a3: rgba(251, 106, 0, .145);
  --orange-a4: rgba(255, 89, 0, .224);
  --orange-a5: rgba(255, 97, 0, .29);
  --orange-a6: rgba(253, 117, 4, .36);
  --orange-a7: rgba(255, 131, 44, .46);
  --orange-a8: rgba(254, 132, 56, .616);
  --orange-a9: rgba(254, 109, 21, .97);
  --orange-a10: #ff801f;
  --orange-a11: #ffa057;
  --orange-a12: #ffe0c2;
  --pink-1: #191117;
  --pink-2: #21121d;
  --pink-3: #37172f;
  --pink-4: #4b143d;
  --pink-5: #591c47;
  --pink-6: #692955;
  --pink-7: #833869;
  --pink-8: #a84885;
  --pink-9: #d6409f;
  --pink-10: #de51a8;
  --pink-11: #ff8dcc;
  --pink-12: #fdd1ea;
  --pink-a1: rgba(244, 18, 188, .035);
  --pink-a2: rgba(244, 32, 187, .07);
  --pink-a3: rgba(254, 55, 204, .16);
  --pink-a4: rgba(252, 30, 196, .247);
  --pink-a5: rgba(253, 53, 194, .306);
  --pink-a6: rgba(253, 81, 199, .373);
  --pink-a7: rgba(253, 98, 200, .482);
  --pink-a8: rgba(255, 104, 200, .635);
  --pink-a9: rgba(254, 73, 188, .83);
  --pink-a10: rgba(255, 92, 192, .863);
  --pink-a11: #ff8dcc;
  --pink-a12: rgba(255, 211, 236, .992);
  --plum-1: #181118;
  --plum-2: #201320;
  --plum-3: #351a35;
  --plum-4: #451d47;
  --plum-5: #512454;
  --plum-6: #5e3061;
  --plum-7: #734079;
  --plum-8: #92549c;
  --plum-9: #ab4aba;
  --plum-10: #b658c4;
  --plum-11: #e796f3;
  --plum-12: #f4d4f4;
  --plum-a1: rgba(241, 18, 241, .03);
  --plum-a2: rgba(242, 47, 242, .067);
  --plum-a3: rgba(253, 76, 253, .153);
  --plum-a4: rgba(246, 70, 255, .227);
  --plum-a5: rgba(244, 85, 255, .282);
  --plum-a6: rgba(246, 109, 255, .337);
  --plum-a7: rgba(240, 124, 253, .44);
  --plum-a8: rgba(238, 132, 255, .584);
  --plum-a9: rgba(233, 97, 254, .714);
  --plum-a10: rgba(237, 112, 255, .753);
  --plum-a11: rgba(241, 156, 254, .953);
  --plum-a12: rgba(254, 221, 254, .957);
  --purple-1: #18111b;
  --purple-2: #1e1523;
  --purple-3: #301c3b;
  --purple-4: #3d224e;
  --purple-5: #48295c;
  --purple-6: #54346b;
  --purple-7: #664282;
  --purple-8: #8457aa;
  --purple-9: #8e4ec6;
  --purple-10: #9a5cd0;
  --purple-11: #d19dff;
  --purple-12: #ecd9fa;
  --purple-a1: rgba(180, 18, 249, .043);
  --purple-a2: rgba(183, 68, 247, .08);
  --purple-a3: rgba(193, 80, 255, .176);
  --purple-a4: rgba(187, 83, 253, .26);
  --purple-a5: rgba(190, 92, 253, .318);
  --purple-a6: rgba(193, 109, 253, .38);
  --purple-a7: rgba(195, 120, 253, .48);
  --purple-a8: rgba(196, 126, 255, .643);
  --purple-a9: rgba(182, 97, 255, .76);
  --purple-a10: rgba(188, 111, 255, .804);
  --purple-a11: #d19dff;
  --purple-a12: rgba(241, 221, 255, .98);
  --red-1: #191111;
  --red-2: #201314;
  --red-3: #3b1219;
  --red-4: #500f1c;
  --red-5: #611623;
  --red-6: #72232d;
  --red-7: #8c333a;
  --red-8: #b54548;
  --red-9: #e5484d;
  --red-10: #ec5d5e;
  --red-11: #ff9592;
  --red-12: #ffd1d9;
  --red-a1: rgba(244, 18, 18, .035);
  --red-a2: rgba(242, 47, 62, .067);
  --red-a3: rgba(255, 23, 63, .176);
  --red-a4: rgba(254, 10, 59, .267);
  --red-a5: rgba(255, 32, 71, .337);
  --red-a6: rgba(255, 62, 86, .408);
  --red-a7: rgba(255, 83, 97, .518);
  --red-a8: rgba(255, 93, 97, .69);
  --red-a9: rgba(254, 78, 84, .894);
  --red-a10: rgba(255, 100, 101, .92);
  --red-a11: #ff9592;
  --red-a12: #ffd1d9;
  --ruby-1: #191113;
  --ruby-2: #1e1517;
  --ruby-3: #3a141e;
  --ruby-4: #4e1325;
  --ruby-5: #5e1a2e;
  --ruby-6: #6f2539;
  --ruby-7: #883447;
  --ruby-8: #b3445a;
  --ruby-9: #e54666;
  --ruby-10: #ec5a72;
  --ruby-11: #ff949d;
  --ruby-12: #fed2e1;
  --ruby-a1: rgba(244, 18, 74, .035);
  --ruby-a2: rgba(254, 90, 127, .055);
  --ruby-a3: rgba(255, 35, 93, .173);
  --ruby-a4: rgba(253, 25, 94, .26);
  --ruby-a5: rgba(254, 45, 107, .325);
  --ruby-a6: rgba(255, 68, 118, .396);
  --ruby-a7: rgba(255, 87, 125, .5);
  --ruby-a8: rgba(255, 92, 124, .682);
  --ruby-a9: rgba(254, 76, 112, .894);
  --ruby-a10: rgba(255, 97, 123, .92);
  --ruby-a11: #ff949d;
  --ruby-a12: rgba(255, 211, 226, .996);
  --sky-1: #0d141f;
  --sky-2: #111a27;
  --sky-3: #112840;
  --sky-4: #113555;
  --sky-5: #154467;
  --sky-6: #1b537b;
  --sky-7: #1f6692;
  --sky-8: #197cae;
  --sky-9: #7ce2fe;
  --sky-10: #a8eeff;
  --sky-11: #75c7f0;
  --sky-12: #c2f3ff;
  --sky-a1: rgba(0, 68, 255, .06);
  --sky-a2: rgba(17, 113, 251, .094);
  --sky-a3: rgba(17, 132, 252, .2);
  --sky-a4: rgba(18, 143, 255, .286);
  --sky-a5: rgba(28, 157, 253, .365);
  --sky-a6: rgba(40, 165, 255, .447);
  --sky-a7: rgba(43, 173, 254, .545);
  --sky-a8: rgba(29, 178, 254, .663);
  --sky-a9: rgba(124, 227, 255, .996);
  --sky-a10: #a8eeff;
  --sky-a11: rgba(124, 211, 255, .937);
  --sky-a12: #c2f3ff;
  --teal-1: #0d1514;
  --teal-2: #111c1b;
  --teal-3: #0d2d2a;
  --teal-4: #023b37;
  --teal-5: #084843;
  --teal-6: #145750;
  --teal-7: #1c6961;
  --teal-8: #207e73;
  --teal-9: #12a594;
  --teal-10: #0eb39e;
  --teal-11: #0bd8b6;
  --teal-12: #adf0dd;
  --teal-a1: rgba(0, 222, 171, .02);
  --teal-a2: rgba(18, 251, 230, .047);
  --teal-a3: rgba(0, 255, 230, .118);
  --teal-a4: rgba(0, 255, 233, .176);
  --teal-a5: rgba(0, 255, 234, .23);
  --teal-a6: rgba(28, 255, 232, .294);
  --teal-a7: rgba(46, 253, 232, .373);
  --teal-a8: rgba(50, 255, 231, .46);
  --teal-a9: rgba(19, 255, 228, .624);
  --teal-a10: rgba(13, 255, 224, .682);
  --teal-a11: rgba(10, 254, 213, .84);
  --teal-a12: rgba(184, 255, 235, .937);
  --tomato-1: #181111;
  --tomato-2: #1f1513;
  --tomato-3: #391714;
  --tomato-4: #4e1511;
  --tomato-5: #5e1c16;
  --tomato-6: #6e2920;
  --tomato-7: #853a2d;
  --tomato-8: #ac4d39;
  --tomato-9: #e54d2e;
  --tomato-10: #ec6142;
  --tomato-11: #ff977d;
  --tomato-12: #fbd3cb;
  --tomato-a1: rgba(241, 18, 18, .03);
  --tomato-a2: rgba(255, 85, 51, .06);
  --tomato-a3: rgba(255, 53, 35, .17);
  --tomato-a4: rgba(253, 32, 17, .26);
  --tomato-a5: rgba(254, 51, 33, .325);
  --tomato-a6: rgba(255, 79, 56, .392);
  --tomato-a7: rgba(253, 100, 74, .49);
  --tomato-a8: rgba(254, 109, 78, .655);
  --tomato-a9: rgba(254, 84, 49, .894);
  --tomato-a10: rgba(255, 104, 71, .92);
  --tomato-a11: #ff977d;
  --tomato-a12: rgba(255, 214, 206, .984);
  --violet-1: #14121f;
  --violet-2: #1b1525;
  --violet-3: #291f43;
  --violet-4: #33255b;
  --violet-5: #3c2e69;
  --violet-6: #473876;
  --violet-7: #56468b;
  --violet-8: #6958ad;
  --violet-9: #6e56cf;
  --violet-10: #7d66d9;
  --violet-11: #baa7ff;
  --violet-12: #e2ddfe;
  --violet-a1: rgba(68, 34, 255, .06);
  --violet-a2: rgba(133, 63, 249, .086);
  --violet-a3: rgba(131, 84, 254, .21);
  --violet-a4: rgba(125, 81, 253, .314);
  --violet-a5: rgba(132, 95, 253, .373);
  --violet-a6: rgba(143, 108, 253, .427);
  --violet-a7: rgba(152, 121, 255, .514);
  --violet-a8: rgba(151, 125, 254, .66);
  --violet-a9: rgba(134, 104, 255, .8);
  --violet-a10: rgba(145, 118, 254, .843);
  --violet-a11: #baa7ff;
  --violet-a12: rgba(227, 222, 255, .996);
  --yellow-1: #14120b;
  --yellow-2: #1b180f;
  --yellow-3: #2d2305;
  --yellow-4: #362b00;
  --yellow-5: #433500;
  --yellow-6: #524202;
  --yellow-7: #665417;
  --yellow-8: #836a21;
  --yellow-9: #ffe629;
  --yellow-10: #ffff57;
  --yellow-11: #f5e147;
  --yellow-12: #f6eeb4;
  --yellow-a1: rgba(209, 81, 0, .016);
  --yellow-a2: rgba(249, 180, 0, .043);
  --yellow-a3: rgba(255, 170, 0, .118);
  --yellow-a4: rgba(253, 183, 0, .157);
  --yellow-a5: rgba(254, 187, 0, .21);
  --yellow-a6: rgba(254, 196, 0, .275);
  --yellow-a7: rgba(253, 203, 34, .36);
  --yellow-a8: rgba(253, 202, 50, .482);
  --yellow-a9: #ffe629;
  --yellow-a10: #ffff57;
  --yellow-a11: rgba(254, 233, 73, .96);
  --yellow-a12: rgba(254, 246, 186, .965);
  --gray-surface: rgba(33, 33, 33, .5);
  --gray-indicator: var(--gray-9);
  --gray-track: var(--gray-9);
  --mauve-surface: rgba(34, 33, 35, .5);
  --mauve-indicator: var(--mauve-9);
  --mauve-track: var(--mauve-9);
  --slate-surface: rgba(31, 33, 35, .5);
  --slate-indicator: var(--slate-9);
  --slate-track: var(--slate-9);
  --sage-surface: rgba(30, 32, 31, .5);
  --sage-indicator: var(--sage-9);
  --sage-track: var(--sage-9);
  --olive-surface: rgba(31, 32, 30, .5);
  --olive-indicator: var(--olive-9);
  --olive-track: var(--olive-9);
  --sand-surface: rgba(33, 33, 32, .5);
  --sand-indicator: var(--sand-9);
  --sand-track: var(--sand-9);
  --amber-surface: rgba(39, 31, 19, .5);
  --amber-indicator: var(--amber-9);
  --amber-track: var(--amber-9);
  --blue-surface: rgba(17, 33, 61, .5);
  --blue-indicator: var(--blue-9);
  --blue-track: var(--blue-9);
  --bronze-surface: rgba(39, 33, 29, .5);
  --bronze-indicator: var(--bronze-9);
  --bronze-track: var(--bronze-9);
  --brown-surface: rgba(39, 31, 27, .5);
  --brown-indicator: var(--brown-9);
  --brown-track: var(--brown-9);
  --crimson-surface: rgba(47, 21, 31, .5);
  --crimson-indicator: var(--crimson-9);
  --crimson-track: var(--crimson-9);
  --cyan-surface: rgba(17, 37, 45, .5);
  --cyan-indicator: var(--cyan-9);
  --cyan-track: var(--cyan-9);
  --gold-surface: rgba(37, 35, 29, .5);
  --gold-indicator: var(--gold-9);
  --gold-track: var(--gold-9);
  --grass-surface: rgba(25, 35, 27, .5);
  --grass-indicator: var(--grass-9);
  --grass-track: var(--grass-9);
  --green-surface: rgba(21, 37, 29, .5);
  --green-indicator: var(--green-9);
  --green-track: var(--green-9);
  --indigo-surface: rgba(23, 29, 59, .5);
  --indigo-indicator: var(--indigo-9);
  --indigo-track: var(--indigo-9);
  --iris-surface: rgba(29, 27, 57, .5);
  --iris-indicator: var(--iris-9);
  --iris-track: var(--iris-9);
  --jade-surface: rgba(19, 39, 31, .5);
  --jade-indicator: var(--jade-9);
  --jade-track: var(--jade-9);
  --lime-surface: rgba(27, 33, 21, .5);
  --lime-indicator: var(--lime-9);
  --lime-track: var(--lime-9);
  --mint-surface: rgba(21, 39, 39, .5);
  --mint-indicator: var(--mint-9);
  --mint-track: var(--mint-9);
  --orange-surface: rgba(39, 29, 19, .5);
  --orange-indicator: var(--orange-9);
  --orange-track: var(--orange-9);
  --pink-surface: rgba(49, 19, 41, .5);
  --pink-indicator: var(--pink-9);
  --pink-track: var(--pink-9);
  --plum-surface: rgba(47, 21, 47, .5);
  --plum-indicator: var(--plum-9);
  --plum-track: var(--plum-9);
  --purple-surface: rgba(43, 23, 53, .5);
  --purple-indicator: var(--purple-9);
  --purple-track: var(--purple-9);
  --red-surface: rgba(47, 21, 23, .5);
  --red-indicator: var(--red-9);
  --red-track: var(--red-9);
  --ruby-surface: rgba(43, 25, 29, .5);
  --ruby-indicator: var(--ruby-9);
  --ruby-track: var(--ruby-9);
  --sky-surface: rgba(19, 35, 59, .5);
  --sky-indicator: var(--sky-9);
  --sky-track: var(--sky-9);
  --teal-surface: rgba(19, 39, 37, .5);
  --teal-indicator: var(--teal-9);
  --teal-track: var(--teal-9);
  --tomato-surface: rgba(45, 25, 21, .5);
  --tomato-indicator: var(--tomato-9);
  --tomato-track: var(--tomato-9);
  --violet-surface: rgba(37, 25, 57, .5);
  --violet-indicator: var(--violet-9);
  --violet-track: var(--violet-9);
  --yellow-surface: rgba(35, 31, 19, .5);
  --yellow-indicator: var(--yellow-9);
  --yellow-track: var(--yellow-9);
}

@supports (color: color(display-p3 1 1 1)) {
  @media (color-gamut: p3) {
    .dark, .dark-theme {
      --gray-1: #111;
      --gray-2: #191919;
      --gray-3: #222;
      --gray-4: #2a2a2a;
      --gray-5: #313131;
      --gray-6: #3a3a3a;
      --gray-7: #484848;
      --gray-8: #606060;
      --gray-9: #6e6e6e;
      --gray-10: #7b7b7b;
      --gray-11: #b4b4b4;
      --gray-12: #eee;
      --gray-a1: rgba(0, 0, 0, 0);
      --gray-a2: rgba(255, 255, 255, .035);
      --gray-a3: rgba(255, 255, 255, .07);
      --gray-a4: rgba(255, 255, 255, .106);
      --gray-a5: rgba(255, 255, 255, .133);
      --gray-a6: rgba(255, 255, 255, .173);
      --gray-a7: rgba(255, 255, 255, .23);
      --gray-a8: rgba(255, 255, 255, .333);
      --gray-a9: rgba(255, 255, 255, .392);
      --gray-a10: rgba(255, 255, 255, .443);
      --gray-a11: rgba(255, 255, 255, .686);
      --gray-a12: rgba(255, 255, 255, .93);
      --mauve-1: #121113;
      --mauve-2: #1a191b;
      --mauve-3: #232225;
      --mauve-4: #2b292d;
      --mauve-5: #323035;
      --mauve-6: #3c393f;
      --mauve-7: #49474e;
      --mauve-8: #625f69;
      --mauve-9: #6f6d78;
      --mauve-10: #7d7a85;
      --mauve-11: #b5b2bc;
      --mauve-12: #eeeef0;
      --mauve-a1: rgba(0, 0, 0, 0);
      --mauve-a2: rgba(254, 253, 255, .035);
      --mauve-a3: rgba(239, 238, 254, .08);
      --mauve-a4: rgba(246, 234, 255, .11);
      --mauve-a5: rgba(241, 231, 255, .145);
      --mauve-a6: rgba(244, 236, 255, .184);
      --mauve-a7: rgba(242, 237, 255, .247);
      --mauve-a8: rgba(240, 234, 255, .36);
      --mauve-a9: rgba(239, 234, 255, .424);
      --mauve-a10: rgba(241, 236, 255, .48);
      --mauve-a11: rgba(246, 245, 255, .714);
      --mauve-a12: rgba(253, 253, 255, .937);
      --slate-1: #111113;
      --slate-2: #18191b;
      --slate-3: #212225;
      --slate-4: #272a2d;
      --slate-5: #2e3135;
      --slate-6: #363a3f;
      --slate-7: #43484e;
      --slate-8: #5a6169;
      --slate-9: #696e77;
      --slate-10: #777b84;
      --slate-11: #b0b4ba;
      --slate-12: #edeef0;
      --slate-a1: rgba(0, 0, 0, 0);
      --slate-a2: rgba(218, 254, 255, .035);
      --slate-a3: rgba(222, 238, 255, .08);
      --slate-a4: rgba(221, 244, 255, .11);
      --slate-a5: rgba(221, 237, 255, .145);
      --slate-a6: rgba(221, 243, 255, .184);
      --slate-a7: rgba(223, 237, 255, .247);
      --slate-a8: rgba(219, 239, 255, .36);
      --slate-a9: rgba(227, 239, 255, .42);
      --slate-a10: rgba(233, 241, 255, .475);
      --slate-a11: rgba(241, 247, 255, .71);
      --slate-a12: rgba(252, 253, 255, .937);
      --sage-1: #101211;
      --sage-2: #171918;
      --sage-3: #202221;
      --sage-4: #272a28;
      --sage-5: #2e3130;
      --sage-6: #373b39;
      --sage-7: #444947;
      --sage-8: #5b625f;
      --sage-9: #63706b;
      --sage-10: #717d79;
      --sage-11: #adb5b2;
      --sage-12: #eceeed;
      --sage-a1: rgba(0, 0, 0, 0);
      --sage-a2: rgba(248, 252, 251, .03);
      --sage-a3: rgba(255, 241, 240, .07);
      --sage-a4: rgba(251, 254, 253, .1);
      --sage-a5: rgba(253, 255, 254, .13);
      --sage-a6: rgba(247, 255, 249, .173);
      --sage-a7: rgba(242, 255, 249, .23);
      --sage-a8: rgba(242, 255, 251, .333);
      --sage-a9: rgba(225, 255, 244, .396);
      --sage-a10: rgba(234, 255, 248, .45);
      --sage-a11: rgba(246, 255, 252, .686);
      --sage-a12: rgba(253, 255, 254, .93);
      --olive-1: #111210;
      --olive-2: #181917;
      --olive-3: #212220;
      --olive-4: #282a27;
      --olive-5: #2f312e;
      --olive-6: #383a37;
      --olive-7: #454943;
      --olive-8: #5c625b;
      --olive-9: #687066;
      --olive-10: #767d74;
      --olive-11: #afb5ad;
      --olive-12: #eceeec;
      --olive-a1: rgba(0, 0, 0, 0);
      --olive-a2: rgba(251, 252, 249, .03);
      --olive-a3: rgba(253, 254, 252, .067);
      --olive-a4: rgba(240, 254, 241, .1);
      --olive-a5: rgba(246, 255, 246, .13);
      --olive-a6: rgba(247, 255, 247, .17);
      --olive-a7: rgba(249, 255, 245, .227);
      --olive-a8: rgba(243, 255, 244, .333);
      --olive-a9: rgba(240, 255, 234, .396);
      --olive-a10: rgba(241, 255, 239, .45);
      --olive-a11: rgba(248, 255, 246, .686);
      --olive-a12: rgba(253, 255, 253, .93);
      --sand-1: #111110;
      --sand-2: #191918;
      --sand-3: #222221;
      --sand-4: #2a2a28;
      --sand-5: #31312e;
      --sand-6: #3b3a37;
      --sand-7: #494844;
      --sand-8: #62605a;
      --sand-9: #6f6d66;
      --sand-10: #7d7b74;
      --sand-11: #b5b3ad;
      --sand-12: #eeeeec;
      --sand-a1: rgba(0, 0, 0, 0);
      --sand-a2: rgba(253, 253, 252, .035);
      --sand-a3: rgba(254, 254, 253, .07);
      --sand-a4: rgba(253, 253, 242, .106);
      --sand-a5: rgba(255, 255, 245, .133);
      --sand-a6: rgba(255, 249, 237, .176);
      --sand-a7: rgba(255, 251, 237, .235);
      --sand-a8: rgba(255, 249, 236, .34);
      --sand-a9: rgba(255, 250, 236, .396);
      --sand-a10: rgba(255, 253, 238, .45);
      --sand-a11: rgba(255, 254, 245, .686);
      --sand-a12: rgba(255, 255, 253, .93);
      --amber-1: #16120c;
      --amber-2: #1d180f;
      --amber-3: #302008;
      --amber-4: #3e2800;
      --amber-5: #4c3100;
      --amber-6: #5c3d05;
      --amber-7: #714f1a;
      --amber-8: #8f6424;
      --amber-9: #ffc442;
      --amber-10: #ffdd20;
      --amber-11: #ffcc4a;
      --amber-12: #ffe7b3;
      --amber-a1: rgba(255, 78, 10, .016);
      --amber-a2: rgba(255, 166, 0, .047);
      --amber-a3: rgba(255, 153, 0, .118);
      --amber-a4: rgba(255, 142, 1, .184);
      --amber-a5: rgba(255, 151, 0, .24);
      --amber-a6: rgba(255, 168, 23, .298);
      --amber-a7: rgba(255, 182, 68, .384);
      --amber-a8: rgba(255, 186, 79, .5);
      --amber-a9: #ffc442;
      --amber-a10: #ffde20;
      --amber-a11: #ffcc4a;
      --amber-a12: #ffe7b3;
      --blue-1: #0d1520;
      --blue-2: #111927;
      --blue-3: #0d2847;
      --blue-4: #00345c;
      --blue-5: #004073;
      --blue-6: #104d87;
      --blue-7: #205d9e;
      --blue-8: #2870bd;
      --blue-9: #0190ff;
      --blue-10: #3c9eff;
      --blue-11: #70b9ff;
      --blue-12: #c2e6ff;
      --blue-a1: rgba(0, 95, 246, .06);
      --blue-a2: rgba(0, 117, 244, .086);
      --blue-a3: rgba(0, 124, 248, .22);
      --blue-a4: rgba(0, 125, 248, .325);
      --blue-a5: rgba(0, 135, 248, .4);
      --blue-a6: rgba(11, 145, 255, .475);
      --blue-a7: rgba(51, 152, 255, .573);
      --blue-a8: rgba(59, 154, 255, .7);
      --blue-a9: rgba(29, 149, 255, .97);
      --blue-a10: rgba(72, 163, 255, .973);
      --blue-a11: #70b9ff;
      --blue-a12: #c2e6ff;
      --bronze-1: #141110;
      --bronze-2: #1c1918;
      --bronze-3: #262120;
      --bronze-4: #302a27;
      --bronze-5: #3b3330;
      --bronze-6: #493e3a;
      --bronze-7: #5a4d47;
      --bronze-8: #6f5f57;
      --bronze-9: #a18072;
      --bronze-10: #ae8d7e;
      --bronze-11: #d4b3a5;
      --bronze-12: #ede0d9;
      --bronze-a1: rgba(255, 0, 0, .008);
      --bronze-a2: rgba(255, 203, 179, .043);
      --bronze-a3: rgba(255, 216, 193, .086);
      --bronze-a4: rgba(255, 214, 200, .125);
      --bronze-a5: rgba(255, 220, 198, .173);
      --bronze-a6: rgba(255, 220, 204, .227);
      --bronze-a7: rgba(255, 221, 205, .294);
      --bronze-a8: rgba(255, 219, 202, .39);
      --bronze-a9: rgba(255, 209, 188, .584);
      --bronze-a10: rgba(255, 214, 195, .635);
      --bronze-a11: #d4b3a5;
      --bronze-a12: #ede0d9;
      --brown-1: #12110f;
      --brown-2: #1c1816;
      --brown-3: #28211d;
      --brown-4: #322922;
      --brown-5: #3e3128;
      --brown-6: #4d3c2f;
      --brown-7: #614a39;
      --brown-8: #7c5f46;
      --brown-9: #ad7f58;
      --brown-10: #b98c67;
      --brown-11: #dbb594;
      --brown-12: #f2e1ca;
      --brown-a1: rgba(232, 0, 0, .004);
      --brown-a2: rgba(255, 180, 136, .043);
      --brown-a3: rgba(255, 190, 149, .094);
      --brown-a4: rgba(255, 195, 153, .133);
      --brown-a5: rgba(255, 194, 152, .18);
      --brown-a6: rgba(255, 197, 153, .24);
      --brown-a7: rgba(255, 198, 150, .32);
      --brown-a8: rgba(255, 199, 148, .43);
      --brown-a9: rgba(255, 196, 142, .627);
      --brown-a10: rgba(255, 202, 154, .68);
      --brown-a11: #dbb594;
      --brown-a12: #f2e1ca;
      --crimson-1: #191114;
      --crimson-2: #201318;
      --crimson-3: #381525;
      --crimson-4: #4d122f;
      --crimson-5: #5c1839;
      --crimson-6: #6d2445;
      --crimson-7: #873357;
      --crimson-8: #b0436e;
      --crimson-9: #e93d82;
      --crimson-10: #ee528a;
      --crimson-11: #ff8faa;
      --crimson-12: #fdd3e8;
      --crimson-a1: rgba(255, 26, 121, .03);
      --crimson-a2: rgba(255, 74, 147, .055);
      --crimson-a3: rgba(255, 61, 148, .15);
      --crimson-a4: rgba(255, 62, 148, .227);
      --crimson-a5: rgba(255, 62, 156, .286);
      --crimson-a6: rgba(255, 88, 166, .35);
      --crimson-a7: rgba(255, 107, 171, .455);
      --crimson-a8: rgba(255, 110, 168, .616);
      --crimson-a9: rgba(255, 90, 154, .83);
      --crimson-a10: rgba(255, 108, 160, .855);
      --crimson-a11: #ff8faa;
      --crimson-a12: #fdd3e8;
      --cyan-1: #0b1619;
      --cyan-2: #101b20;
      --cyan-3: #082c36;
      --cyan-4: #003847;
      --cyan-5: #004557;
      --cyan-6: #045468;
      --cyan-7: #12677e;
      --cyan-8: #11809c;
      --cyan-9: #01a2c7;
      --cyan-10: #23afd0;
      --cyan-11: #4ccce5;
      --cyan-12: #b6ecf7;
      --cyan-a1: rgba(0, 168, 248, .035);
      --cyan-a2: rgba(0, 189, 251, .06);
      --cyan-a3: rgba(0, 191, 250, .153);
      --cyan-a4: rgba(0, 187, 250, .227);
      --cyan-a5: rgba(0, 195, 251, .29);
      --cyan-a6: rgba(0, 203, 251, .357);
      --cyan-a7: rgba(8, 208, 255, .447);
      --cyan-a8: rgba(35, 210, 255, .573);
      --cyan-a9: rgba(33, 211, 255, .75);
      --cyan-a10: rgba(58, 216, 255, .784);
      --cyan-a11: #4ccce5;
      --cyan-a12: #b6ecf7;
      --gold-1: #121211;
      --gold-2: #1b1a17;
      --gold-3: #24231f;
      --gold-4: #2e2b26;
      --gold-5: #38352f;
      --gold-6: #444039;
      --gold-7: #544f46;
      --gold-8: #696256;
      --gold-9: #978365;
      --gold-10: #a39073;
      --gold-11: #cbb99f;
      --gold-12: #e8e2d9;
      --gold-a1: rgba(216, 218, 0, .004);
      --gold-a2: rgba(255, 226, 147, .043);
      --gold-a3: rgba(255, 242, 193, .08);
      --gold-a4: rgba(255, 238, 205, .118);
      --gold-a5: rgba(255, 242, 206, .16);
      --gold-a6: rgba(255, 236, 205, .216);
      --gold-a7: rgba(255, 241, 213, .28);
      --gold-a8: rgba(255, 239, 210, .365);
      --gold-a9: rgba(255, 224, 177, .553);
      --gold-a10: rgba(255, 228, 186, .6);
      --gold-a11: #cbb99f;
      --gold-a12: #e8e2d9;
      --grass-1: #0e1511;
      --grass-2: #141a15;
      --grass-3: #1b2a1e;
      --grass-4: #1d3a24;
      --grass-5: #25482d;
      --grass-6: #2d5736;
      --grass-7: #366740;
      --grass-8: #3e7949;
      --grass-9: #46a758;
      --grass-10: #53b365;
      --grass-11: #71d083;
      --grass-12: #c2f0c2;
      --grass-a1: rgba(0, 247, 69, .016);
      --grass-a2: rgba(69, 255, 142, .04);
      --grass-a3: rgba(97, 255, 141, .106);
      --grass-a4: rgba(84, 255, 135, .17);
      --grass-a5: rgba(104, 255, 143, .227);
      --grass-a6: rgba(117, 255, 149, .29);
      --grass-a7: rgba(125, 255, 151, .357);
      --grass-a8: rgba(127, 255, 152, .43);
      --grass-a9: rgba(112, 255, 138, .624);
      --grass-a10: rgba(124, 255, 147, .675);
      --grass-a11: #71d083;
      --grass-a12: #c2f0c2;
      --green-1: #0e1512;
      --green-2: #121b17;
      --green-3: #132d21;
      --green-4: #113b29;
      --green-5: #174933;
      --green-6: #1f573e;
      --green-7: #28684a;
      --green-8: #2f7c57;
      --green-9: #30a46c;
      --green-10: #33b074;
      --green-11: #3dd68c;
      --green-12: #b1f1cb;
      --green-a1: rgba(0, 247, 99, .016);
      --green-a2: rgba(0, 249, 156, .043);
      --green-a3: rgba(0, 255, 162, .114);
      --green-a4: rgba(0, 253, 161, .173);
      --green-a5: rgba(0, 255, 169, .23);
      --green-a6: rgba(64, 255, 176, .29);
      --green-a7: rgba(86, 255, 176, .36);
      --green-a8: rgba(93, 255, 180, .443);
      --green-a9: rgba(81, 255, 170, .61);
      --green-a10: rgba(82, 255, 170, .66);
      --green-a11: #3dd68c;
      --green-a12: #b1f1cb;
      --indigo-1: #11131f;
      --indigo-2: #141726;
      --indigo-3: #182449;
      --indigo-4: #1d2e61;
      --indigo-5: #253974;
      --indigo-6: #304384;
      --indigo-7: #3a4f97;
      --indigo-8: #435db2;
      --indigo-9: #3e63dd;
      --indigo-10: #5472e4;
      --indigo-11: #9eb1ff;
      --indigo-12: #d6e1ff;
      --indigo-a1: rgba(0, 60, 255, .055);
      --indigo-a2: rgba(58, 91, 255, .086);
      --indigo-a3: rgba(52, 107, 255, .224);
      --indigo-a4: rgba(58, 111, 255, .325);
      --indigo-a5: rgba(73, 119, 255, .4);
      --indigo-a6: rgba(86, 128, 255, .467);
      --indigo-a7: rgba(93, 133, 255, .545);
      --indigo-a8: rgba(98, 135, 255, .65);
      --indigo-a9: rgba(74, 119, 255, .824);
      --indigo-a10: rgba(99, 132, 255, .86);
      --indigo-a11: #9eb1ff;
      --indigo-a12: #d6e1ff;
      --iris-1: #13131e;
      --iris-2: #171625;
      --iris-3: #202248;
      --iris-4: #262a65;
      --iris-5: #303374;
      --iris-6: #3d3d82;
      --iris-7: #4a4a95;
      --iris-8: #5a58b1;
      --iris-9: #5b5bd6;
      --iris-10: #6e6ade;
      --iris-11: #b0aaff;
      --iris-12: #e0dffe;
      --iris-a1: rgba(57, 62, 255, .05);
      --iris-a2: rgba(95, 85, 255, .08);
      --iris-a3: rgba(91, 99, 255, .22);
      --iris-a4: rgba(82, 97, 255, .337);
      --iris-a5: rgba(97, 106, 255, .4);
      --iris-a6: rgba(115, 117, 255, .455);
      --iris-a7: rgba(125, 126, 255, .533);
      --iris-a8: rgba(129, 128, 255, .65);
      --iris-a9: rgba(111, 113, 255, .8);
      --iris-a10: rgba(129, 126, 255, .83);
      --iris-a11: #b0aaff;
      --iris-a12: #e0dffe;
      --jade-1: #0d1512;
      --jade-2: #121c18;
      --jade-3: #0f2e23;
      --jade-4: #0a3b2c;
      --jade-5: #104837;
      --jade-6: #1b5645;
      --jade-7: #246854;
      --jade-8: #2a7e68;
      --jade-9: #29a383;
      --jade-10: #27b08b;
      --jade-11: #1ed8a4;
      --jade-12: #adf0d4;
      --jade-a1: rgba(0, 247, 99, .016);
      --jade-a2: rgba(0, 251, 165, .047);
      --jade-a3: rgba(0, 254, 169, .118);
      --jade-a4: rgba(0, 254, 178, .173);
      --jade-a5: rgba(0, 255, 188, .227);
      --jade-a6: rgba(36, 255, 201, .286);
      --jade-a7: rgba(74, 255, 203, .36);
      --jade-a8: rgba(83, 255, 211, .45);
      --jade-a9: rgba(68, 255, 206, .608);
      --jade-a10: rgba(67, 255, 206, .655);
      --jade-a11: #1ed8a4;
      --jade-a12: #adf0d4;
      --lime-1: #11130c;
      --lime-2: #151a10;
      --lime-3: #1f2917;
      --lime-4: #29371d;
      --lime-5: #334423;
      --lime-6: #3d522a;
      --lime-7: #496231;
      --lime-8: #577538;
      --lime-9: #bdee63;
      --lime-10: #d4ff70;
      --lime-11: #bde56b;
      --lime-12: #e3f7ba;
      --lime-a1: rgba(0, 234, 62, .008);
      --lime-a2: rgba(126, 253, 0, .04);
      --lime-a3: rgba(156, 255, 79, .1);
      --lime-a4: rgba(169, 255, 97, .16);
      --lime-a5: rgba(175, 255, 108, .216);
      --lime-a6: rgba(183, 255, 112, .275);
      --lime-a7: rgba(183, 255, 119, .34);
      --lime-a8: rgba(188, 255, 120, .416);
      --lime-a9: rgba(205, 255, 118, .925);
      --lime-a10: rgba(215, 255, 123, .996);
      --lime-a11: #bde56b;
      --lime-a12: #e3f7ba;
      --mint-1: #0d1515;
      --mint-2: #0f1b1b;
      --mint-3: #092c2b;
      --mint-4: #003a38;
      --mint-5: #004744;
      --mint-6: #0f5650;
      --mint-7: #1e685f;
      --mint-8: #277f70;
      --mint-9: #86ead4;
      --mint-10: #a8f5e5;
      --mint-11: #58d5ba;
      --mint-12: #c4f5e1;
      --mint-a1: rgba(0, 249, 246, .016);
      --mint-a2: rgba(0, 248, 245, .043);
      --mint-a3: rgba(0, 252, 249, .11);
      --mint-a4: rgba(0, 250, 242, .17);
      --mint-a5: rgba(0, 255, 240, .224);
      --mint-a6: rgba(23, 255, 236, .286);
      --mint-a7: rgba(55, 255, 232, .36);
      --mint-a8: rgba(74, 255, 226, .455);
      --mint-a9: rgba(153, 255, 232, .906);
      --mint-a10: rgba(181, 255, 239, .95);
      --mint-a11: #58d5ba;
      --mint-a12: #c4f5e1;
      --orange-1: #17120e;
      --orange-2: #1e160f;
      --orange-3: #331e0b;
      --orange-4: #462100;
      --orange-5: #552a00;
      --orange-6: #66350c;
      --orange-7: #7e451d;
      --orange-8: #a35829;
      --orange-9: #f76a15;
      --orange-10: #ff823d;
      --orange-11: #ffa163;
      --orange-12: #ffe0c2;
      --orange-a1: rgba(255, 51, 0, .024);
      --orange-a2: rgba(255, 135, 2, .05);
      --orange-a3: rgba(255, 124, 4, .13);
      --orange-a4: rgba(255, 99, 8, .21);
      --orange-a5: rgba(255, 117, 6, .267);
      --orange-a6: rgba(255, 135, 35, .333);
      --orange-a7: rgba(255, 145, 66, .427);
      --orange-a8: rgba(255, 149, 79, .573);
      --orange-a9: rgba(255, 126, 58, .894);
      --orange-a10: rgba(255, 133, 63, .98);
      --orange-a11: #ffa163;
      --orange-a12: #ffe0c2;
      --pink-1: #191117;
      --pink-2: #21121d;
      --pink-3: #37172f;
      --pink-4: #4b143d;
      --pink-5: #591c47;
      --pink-6: #692955;
      --pink-7: #833869;
      --pink-8: #a84885;
      --pink-9: #d6409f;
      --pink-10: #de51a8;
      --pink-11: #ff89c8;
      --pink-12: #fdd1ea;
      --pink-a1: rgba(255, 35, 217, .03);
      --pink-a2: rgba(255, 73, 201, .06);
      --pink-a3: rgba(255, 91, 221, .137);
      --pink-a4: rgba(255, 72, 205, .22);
      --pink-a5: rgba(255, 88, 208, .275);
      --pink-a6: rgba(255, 104, 213, .337);
      --pink-a7: rgba(255, 119, 212, .443);
      --pink-a8: rgba(255, 124, 211, .584);
      --pink-a9: rgba(255, 98, 200, .76);
      --pink-a10: rgba(255, 113, 202, .796);
      --pink-a11: #ff89c8;
      --pink-a12: #fdd1ea;
      --plum-1: #181118;
      --plum-2: #201320;
      --plum-3: #351935;
      --plum-4: #451d47;
      --plum-5: #502454;
      --plum-6: #5e3062;
      --plum-7: #734079;
      --plum-8: #92549c;
      --plum-9: #ab4aba;
      --plum-10: #b658c4;
      --plum-11: #e796f3;
      --plum-12: #f4d4f4;
      --plum-a1: rgba(255, 40, 245, .027);
      --plum-a2: rgba(254, 63, 255, .06);
      --plum-a3: rgba(249, 81, 255, .15);
      --plum-a4: rgba(247, 77, 255, .22);
      --plum-a5: rgba(247, 96, 255, .27);
      --plum-a6: rgba(244, 116, 255, .33);
      --plum-a7: rgba(243, 133, 255, .424);
      --plum-a8: rgba(242, 140, 255, .57);
      --plum-a9: rgba(236, 107, 255, .69);
      --plum-a10: rgba(238, 118, 255, .733);
      --plum-a11: #e796f3;
      --plum-a12: #f4d4f4;
      --purple-1: #18111b;
      --purple-2: #1e1523;
      --purple-3: #2f1c3b;
      --purple-4: #3d224e;
      --purple-5: #48295c;
      --purple-6: #54346b;
      --purple-7: #664282;
      --purple-8: #8357aa;
      --purple-9: #8e4ec6;
      --purple-10: #9a5cd0;
      --purple-11: #d39eff;
      --purple-12: #ecd9fa;
      --purple-a1: rgba(188, 21, 255, .04);
      --purple-a2: rgba(196, 73, 255, .07);
      --purple-a3: rgba(194, 89, 255, .17);
      --purple-a4: rgba(190, 91, 255, .247);
      --purple-a5: rgba(193, 103, 255, .3);
      --purple-a6: rgba(196, 116, 255, .365);
      --purple-a7: rgba(201, 129, 255, .46);
      --purple-a8: rgba(199, 133, 255, .624);
      --purple-a9: rgba(184, 105, 255, .737);
      --purple-a10: rgba(190, 118, 255, .776);
      --purple-a11: #d39eff;
      --purple-a12: #ecd9fa;
      --red-1: #191111;
      --red-2: #201314;
      --red-3: #3b1219;
      --red-4: #500e1c;
      --red-5: #611623;
      --red-6: #73232d;
      --red-7: #8c333a;
      --red-8: #b54548;
      --red-9: #e5484d;
      --red-10: #ec5e5e;
      --red-11: #ff918f;
      --red-12: #ffd1d9;
      --red-a1: rgba(255, 26, 27, .03);
      --red-a2: rgba(255, 74, 76, .055);
      --red-a3: rgba(255, 47, 74, .157);
      --red-a4: rgba(255, 35, 73, .235);
      --red-a5: rgba(255, 57, 84, .3);
      --red-a6: rgba(255, 82, 100, .373);
      --red-a7: rgba(255, 103, 111, .475);
      --red-a8: rgba(255, 110, 113, .635);
      --red-a9: rgba(255, 100, 101, .82);
      --red-a10: rgba(255, 118, 117, .855);
      --red-a11: #ff918f;
      --red-a12: #ffd1d9;
      --ruby-1: #191113;
      --ruby-2: #1e1517;
      --ruby-3: #3a141e;
      --ruby-4: #4e1325;
      --ruby-5: #5e1a2e;
      --ruby-6: #6f2539;
      --ruby-7: #883447;
      --ruby-8: #b3445a;
      --ruby-9: #e54666;
      --ruby-10: #ec5a72;
      --ruby-11: #ff9299;
      --ruby-12: #fed2e1;
      --ruby-a1: rgba(255, 25, 88, .03);
      --ruby-a2: rgba(255, 97, 137, .05);
      --ruby-a3: rgba(255, 53, 107, .153);
      --ruby-a4: rgba(255, 48, 110, .227);
      --ruby-a5: rgba(255, 68, 120, .29);
      --ruby-a6: rgba(255, 88, 132, .357);
      --ruby-a7: rgba(255, 106, 140, .46);
      --ruby-a8: rgba(255, 111, 139, .627);
      --ruby-a9: rgba(255, 97, 126, .82);
      --ruby-a10: rgba(255, 115, 136, .847);
      --ruby-a11: #ff9299;
      --ruby-a12: #fed2e1;
      --sky-1: #0d141e;
      --sky-2: #111a27;
      --sky-3: #112840;
      --sky-4: #113655;
      --sky-5: #154467;
      --sky-6: #1b537b;
      --sky-7: #1f6692;
      --sky-8: #197cae;
      --sky-9: #7ce2fe;
      --sky-10: #a8eeff;
      --sky-11: #75c7f0;
      --sky-12: #c2f3ff;
      --sky-a1: rgba(0, 84, 244, .055);
      --sky-a2: rgba(0, 123, 254, .09);
      --sky-a3: rgba(0, 137, 255, .19);
      --sky-a4: rgba(0, 152, 255, .275);
      --sky-a5: rgba(15, 164, 255, .35);
      --sky-a6: rgba(42, 170, 255, .43);
      --sky-a7: rgba(50, 177, 255, .525);
      --sky-a8: rgba(43, 183, 255, .643);
      --sky-a9: rgba(132, 230, 255, .984);
      --sky-a10: rgba(173, 239, 255, .992);
      --sky-a11: #75c7f0;
      --sky-a12: #c2f3ff;
      --teal-1: #0d1514;
      --teal-2: #111c1b;
      --teal-3: #0d2d2a;
      --teal-4: #023b37;
      --teal-5: #084843;
      --teal-6: #145750;
      --teal-7: #1c6961;
      --teal-8: #1f7f74;
      --teal-9: #12a594;
      --teal-10: #0db39e;
      --teal-11: #0ad8b6;
      --teal-12: #adf0dd;
      --teal-a1: rgba(0, 248, 196, .016);
      --teal-a2: rgba(0, 252, 228, .047);
      --teal-a3: rgba(0, 255, 227, .118);
      --teal-a4: rgba(0, 252, 234, .173);
      --teal-a5: rgba(0, 255, 236, .227);
      --teal-a6: rgba(47, 255, 236, .286);
      --teal-a7: rgba(74, 255, 234, .365);
      --teal-a8: rgba(81, 255, 234, .455);
      --teal-a9: rgba(43, 255, 230, .61);
      --teal-a10: rgba(39, 255, 226, .67);
      --teal-a11: #0ad8b6;
      --teal-a12: #adf0dd;
      --tomato-1: #181111;
      --tomato-2: #1f1513;
      --tomato-3: #391714;
      --tomato-4: #4e1511;
      --tomato-5: #5e1c16;
      --tomato-6: #6e2920;
      --tomato-7: #863a2d;
      --tomato-8: #ac4d39;
      --tomato-9: #e54d2e;
      --tomato-10: #ec6142;
      --tomato-11: #ff9577;
      --tomato-12: #fbd3cb;
      --tomato-a1: rgba(255, 26, 26, .027);
      --tomato-a2: rgba(255, 97, 61, .05);
      --tomato-a3: rgba(255, 74, 50, .15);
      --tomato-a4: rgba(255, 55, 36, .23);
      --tomato-a5: rgba(255, 75, 54, .29);
      --tomato-a6: rgba(255, 101, 75, .353);
      --tomato-a7: rgba(255, 117, 92, .45);
      --tomato-a8: rgba(255, 125, 97, .6);
      --tomato-a9: rgba(255, 105, 72, .82);
      --tomato-a10: rgba(255, 122, 90, .855);
      --tomato-a11: #ff9577;
      --tomato-a12: #fbd3cb;
      --violet-1: #14121f;
      --violet-2: #1b1525;
      --violet-3: #291f44;
      --violet-4: #33255b;
      --violet-5: #3c2e69;
      --violet-6: #473876;
      --violet-7: #56468b;
      --violet-8: #6858ad;
      --violet-9: #6e56cf;
      --violet-10: #7d66d9;
      --violet-11: #bba6ff;
      --violet-12: #e2ddfe;
      --violet-a1: rgba(77, 42, 255, .055);
      --violet-a2: rgba(138, 71, 255, .08);
      --violet-a3: rgba(132, 89, 255, .204);
      --violet-a4: rgba(131, 91, 255, .298);
      --violet-a5: rgba(139, 103, 255, .353);
      --violet-a6: rgba(150, 118, 255, .408);
      --violet-a7: rgba(154, 128, 255, .494);
      --violet-a8: rgba(156, 132, 255, .63);
      --violet-a9: rgba(137, 111, 255, .77);
      --violet-a10: rgba(151, 125, 255, .81);
      --violet-a11: #bba6ff;
      --violet-a12: #e2ddfe;
      --yellow-1: #14110b;
      --yellow-2: #1b180f;
      --yellow-3: #2c2305;
      --yellow-4: #362b00;
      --yellow-5: #423500;
      --yellow-6: #524202;
      --yellow-7: #665417;
      --yellow-8: #836a20;
      --yellow-9: #ffea34;
      --yellow-10: #ffff57;
      --yellow-11: #f5e147;
      --yellow-12: #f6eeb4;
      --yellow-a1: rgba(255, 95, 8, .01);
      --yellow-a2: rgba(255, 202, 0, .04);
      --yellow-a3: rgba(255, 181, 0, .11);
      --yellow-a4: rgba(255, 189, 0, .153);
      --yellow-a5: rgba(255, 195, 0, .204);
      --yellow-a6: rgba(255, 208, 10, .263);
      --yellow-a7: rgba(255, 211, 66, .345);
      --yellow-a8: rgba(255, 211, 80, .463);
      --yellow-a9: #ffeb34;
      --yellow-a10: #ffff56;
      --yellow-a11: #f5e147;
      --yellow-a12: #f6eeb4;
      --gray-surface: rgba(32, 32, 32, .5);
      --mauve-surface: rgba(34, 32, 34, .5);
      --slate-surface: rgba(30, 32, 34, .5);
      --sage-surface: rgba(30, 32, 30, .5);
      --olive-surface: rgba(30, 32, 30, .5);
      --sand-surface: rgba(32, 32, 32, .5);
      --amber-surface: rgba(37, 30, 19, .5);
      --blue-surface: rgba(13, 32, 58, .5);
      --bronze-surface: rgba(37, 32, 30, .5);
      --brown-surface: rgba(37, 30, 25, .5);
      --crimson-surface: rgba(45, 21, 30, .5);
      --cyan-surface: rgba(15, 37, 45, .5);
      --gold-surface: rgba(36, 34, 27, .5);
      --grass-surface: rgba(24, 34, 25, .5);
      --green-surface: rgba(20, 36, 27, .5);
      --indigo-surface: rgba(23, 28, 58, .5);
      --iris-surface: rgba(28, 26, 56, .5);
      --jade-surface: rgba(17, 39, 29, .5);
      --lime-surface: rgba(27, 32, 19, .5);
      --mint-surface: rgba(20, 38, 36, .5);
      --orange-surface: rgba(38, 28, 16, .5);
      --pink-surface: rgba(48, 18, 39, .5);
      --plum-surface: rgba(45, 21, 45, .5);
      --purple-surface: rgba(40, 23, 52, .5);
      --red-surface: rgba(45, 21, 21, .5);
      --ruby-surface: rgba(43, 23, 28, .5);
      --sky-surface: rgba(18, 34, 58, .5);
      --teal-surface: rgba(17, 39, 36, .5);
      --tomato-surface: rgba(43, 23, 19, .5);
      --violet-surface: rgba(36, 26, 56, .5);
      --yellow-surface: rgba(35, 30, 16, .5);
    }

    @supports (color: color(display-p3 0 0 0)) {
      .dark, .dark-theme {
        --gray-1: color(display-p3 .067 .067 .067);
        --gray-2: color(display-p3 .098 .098 .098);
        --gray-3: color(display-p3 .135 .135 .135);
        --gray-4: color(display-p3 .163 .163 .163);
        --gray-5: color(display-p3 .192 .192 .192);
        --gray-6: color(display-p3 .228 .228 .228);
        --gray-7: color(display-p3 .283 .283 .283);
        --gray-8: color(display-p3 .375 .375 .375);
        --gray-9: color(display-p3 .431 .431 .431);
        --gray-10: color(display-p3 .484 .484 .484);
        --gray-11: color(display-p3 .706 .706 .706);
        --gray-12: color(display-p3 .933 .933 .933);
        --gray-a1: color(display-p3 0 0 0 / 0);
        --gray-a2: color(display-p3 1 1 1 / .034);
        --gray-a3: color(display-p3 1 1 1 / .071);
        --gray-a4: color(display-p3 1 1 1 / .105);
        --gray-a5: color(display-p3 1 1 1 / .134);
        --gray-a6: color(display-p3 1 1 1 / .172);
        --gray-a7: color(display-p3 1 1 1 / .231);
        --gray-a8: color(display-p3 1 1 1 / .332);
        --gray-a9: color(display-p3 1 1 1 / .391);
        --gray-a10: color(display-p3 1 1 1 / .445);
        --gray-a11: color(display-p3 1 1 1 / .685);
        --gray-a12: color(display-p3 1 1 1 / .929);
        --mauve-1: color(display-p3 .07 .067 .074);
        --mauve-2: color(display-p3 .101 .098 .105);
        --mauve-3: color(display-p3 .138 .134 .144);
        --mauve-4: color(display-p3 .167 .161 .175);
        --mauve-5: color(display-p3 .196 .189 .206);
        --mauve-6: color(display-p3 .232 .225 .245);
        --mauve-7: color(display-p3 .286 .277 .302);
        --mauve-8: color(display-p3 .383 .373 .408);
        --mauve-9: color(display-p3 .434 .428 .467);
        --mauve-10: color(display-p3 .487 .48 .519);
        --mauve-11: color(display-p3 .707 .7 .735);
        --mauve-12: color(display-p3 .933 .933 .94);
        --mauve-a1: color(display-p3 0 0 0 / 0);
        --mauve-a2: color(display-p3 .996 .992 1 / .034);
        --mauve-a3: color(display-p3 .937 .933 .992 / .077);
        --mauve-a4: color(display-p3 .957 .918 .996 / .111);
        --mauve-a5: color(display-p3 .937 .906 .996 / .145);
        --mauve-a6: color(display-p3 .953 .925 .996 / .183);
        --mauve-a7: color(display-p3 .945 .929 1 / .246);
        --mauve-a8: color(display-p3 .937 .918 1 / .361);
        --mauve-a9: color(display-p3 .933 .918 1 / .424);
        --mauve-a10: color(display-p3 .941 .925 1 / .479);
        --mauve-a11: color(display-p3 .965 .961 1 / .712);
        --mauve-a12: color(display-p3 .992 .992 1 / .937);
        --slate-1: color(display-p3 .067 .067 .074);
        --slate-2: color(display-p3 .095 .098 .105);
        --slate-3: color(display-p3 .13 .135 .145);
        --slate-4: color(display-p3 .156 .163 .176);
        --slate-5: color(display-p3 .183 .191 .206);
        --slate-6: color(display-p3 .215 .226 .244);
        --slate-7: color(display-p3 .265 .28 .302);
        --slate-8: color(display-p3 .357 .381 .409);
        --slate-9: color(display-p3 .415 .431 .463);
        --slate-10: color(display-p3 .469 .483 .514);
        --slate-11: color(display-p3 .692 .704 .728);
        --slate-12: color(display-p3 .93 .933 .94);
        --slate-a1: color(display-p3 0 0 0 / 0);
        --slate-a2: color(display-p3 .875 .992 1 / .034);
        --slate-a3: color(display-p3 .882 .933 .992 / .077);
        --slate-a4: color(display-p3 .882 .953 .996 / .111);
        --slate-a5: color(display-p3 .878 .929 .996 / .145);
        --slate-a6: color(display-p3 .882 .949 .996 / .183);
        --slate-a7: color(display-p3 .882 .929 1 / .246);
        --slate-a8: color(display-p3 .871 .937 1 / .361);
        --slate-a9: color(display-p3 .898 .937 1 / .42);
        --slate-a10: color(display-p3 .918 .945 1 / .475);
        --slate-a11: color(display-p3 .949 .969 .996 / .708);
        --slate-a12: color(display-p3 .988 .992 1 / .937);
        --sage-1: color(display-p3 .064 .07 .067);
        --sage-2: color(display-p3 .092 .098 .094);
        --sage-3: color(display-p3 .128 .135 .131);
        --sage-4: color(display-p3 .155 .164 .159);
        --sage-5: color(display-p3 .183 .193 .188);
        --sage-6: color(display-p3 .218 .23 .224);
        --sage-7: color(display-p3 .269 .285 .277);
        --sage-8: color(display-p3 .362 .382 .373);
        --sage-9: color(display-p3 .398 .438 .421);
        --sage-10: color(display-p3 .453 .49 .474);
        --sage-11: color(display-p3 .685 .709 .697);
        --sage-12: color(display-p3 .927 .933 .93);
        --sage-a1: color(display-p3 0 0 0 / 0);
        --sage-a2: color(display-p3 .976 .988 .984 / .03);
        --sage-a3: color(display-p3 .992 .945 .941 / .072);
        --sage-a4: color(display-p3 .988 .996 .992 / .102);
        --sage-a5: color(display-p3 .992 1 .996 / .131);
        --sage-a6: color(display-p3 .973 1 .976 / .173);
        --sage-a7: color(display-p3 .957 1 .976 / .233);
        --sage-a8: color(display-p3 .957 1 .984 / .334);
        --sage-a9: color(display-p3 .902 1 .957 / .397);
        --sage-a10: color(display-p3 .929 1 .973 / .452);
        --sage-a11: color(display-p3 .969 1 .988 / .688);
        --sage-a12: color(display-p3 .992 1 .996 / .929);
        --olive-1: color(display-p3 .067 .07 .063);
        --olive-2: color(display-p3 .095 .098 .091);
        --olive-3: color(display-p3 .131 .135 .126);
        --olive-4: color(display-p3 .158 .163 .153);
        --olive-5: color(display-p3 .186 .192 .18);
        --olive-6: color(display-p3 .221 .229 .215);
        --olive-7: color(display-p3 .273 .284 .266);
        --olive-8: color(display-p3 .365 .382 .359);
        --olive-9: color(display-p3 .414 .438 .404);
        --olive-10: color(display-p3 .467 .49 .458);
        --olive-11: color(display-p3 .69 .709 .682);
        --olive-12: color(display-p3 .927 .933 .926);
        --olive-a1: color(display-p3 0 0 0 / 0);
        --olive-a2: color(display-p3 .984 .988 .976 / .03);
        --olive-a3: color(display-p3 .992 .996 .988 / .068);
        --olive-a4: color(display-p3 .953 .996 .949 / .102);
        --olive-a5: color(display-p3 .969 1 .965 / .131);
        --olive-a6: color(display-p3 .973 1 .969 / .169);
        --olive-a7: color(display-p3 .98 1 .961 / .228);
        --olive-a8: color(display-p3 .961 1 .957 / .334);
        --olive-a9: color(display-p3 .949 1 .922 / .397);
        --olive-a10: color(display-p3 .953 1 .941 / .452);
        --olive-a11: color(display-p3 .976 1 .965 / .688);
        --olive-a12: color(display-p3 .992 1 .992 / .929);
        --sand-1: color(display-p3 .067 .067 .063);
        --sand-2: color(display-p3 .098 .098 .094);
        --sand-3: color(display-p3 .135 .135 .129);
        --sand-4: color(display-p3 .164 .163 .156);
        --sand-5: color(display-p3 .193 .192 .183);
        --sand-6: color(display-p3 .23 .229 .217);
        --sand-7: color(display-p3 .285 .282 .267);
        --sand-8: color(display-p3 .384 .378 .357);
        --sand-9: color(display-p3 .434 .428 .403);
        --sand-10: color(display-p3 .487 .481 .456);
        --sand-11: color(display-p3 .707 .703 .68);
        --sand-12: color(display-p3 .933 .933 .926);
        --sand-a1: color(display-p3 0 0 0 / 0);
        --sand-a2: color(display-p3 .992 .992 .988 / .034);
        --sand-a3: color(display-p3 .996 .996 .992 / .072);
        --sand-a4: color(display-p3 .992 .992 .953 / .106);
        --sand-a5: color(display-p3 1 1 .965 / .135);
        --sand-a6: color(display-p3 1 .976 .929 / .177);
        --sand-a7: color(display-p3 1 .984 .929 / .236);
        --sand-a8: color(display-p3 1 .976 .925 / .341);
        --sand-a9: color(display-p3 1 .98 .925 / .395);
        --sand-a10: color(display-p3 1 .992 .933 / .45);
        --sand-a11: color(display-p3 1 .996 .961 / .685);
        --sand-a12: color(display-p3 1 1 .992 / .929);
        --amber-1: color(display-p3 .082 .07 .05);
        --amber-2: color(display-p3 .111 .094 .064);
        --amber-3: color(display-p3 .178 .128 .049);
        --amber-4: color(display-p3 .239 .156 0);
        --amber-5: color(display-p3 .29 .193 0);
        --amber-6: color(display-p3 .344 .245 .076);
        --amber-7: color(display-p3 .422 .314 .141);
        --amber-8: color(display-p3 .535 .399 .189);
        --amber-9: color(display-p3 1 .77 .26);
        --amber-10: color(display-p3 1 .87 .15);
        --amber-11: color(display-p3 1 .8 .29);
        --amber-12: color(display-p3 .984 .909 .726);
        --amber-a1: color(display-p3 .992 .298 0 / .017);
        --amber-a2: color(display-p3 .988 .651 0 / .047);
        --amber-a3: color(display-p3 1 .6 0 / .118);
        --amber-a4: color(display-p3 1 .557 0 / .185);
        --amber-a5: color(display-p3 1 .592 0 / .24);
        --amber-a6: color(display-p3 1 .659 .094 / .299);
        --amber-a7: color(display-p3 1 .714 .263 / .383);
        --amber-a8: color(display-p3 .996 .729 .306 / .5);
        --amber-a9: color(display-p3 1 .769 .259);
        --amber-a10: color(display-p3 1 .871 .149);
        --amber-a11: color(display-p3 1 .8 .29);
        --amber-a12: color(display-p3 .984 .909 .726);
        --blue-1: color(display-p3 .057 .081 .122);
        --blue-2: color(display-p3 .072 .098 .147);
        --blue-3: color(display-p3 .078 .154 .27);
        --blue-4: color(display-p3 .033 .197 .37);
        --blue-5: color(display-p3 .08 .245 .441);
        --blue-6: color(display-p3 .14 .298 .511);
        --blue-7: color(display-p3 .195 .361 .6);
        --blue-8: color(display-p3 .239 .434 .72);
        --blue-9: color(display-p3 .247 .556 .969);
        --blue-10: color(display-p3 .344 .612 .973);
        --blue-11: color(display-p3 .49 .72 1);
        --blue-12: color(display-p3 .788 .898 .99);
        --blue-a1: color(display-p3 0 .333 1 / .059);
        --blue-a2: color(display-p3 .114 .435 .988 / .085);
        --blue-a3: color(display-p3 .122 .463 1 / .219);
        --blue-a4: color(display-p3 0 .467 1 / .324);
        --blue-a5: color(display-p3 .098 .51 1 / .4);
        --blue-a6: color(display-p3 .224 .557 1 / .475);
        --blue-a7: color(display-p3 .294 .584 1 / .572);
        --blue-a8: color(display-p3 .314 .592 1 / .702);
        --blue-a9: color(display-p3 .251 .573 .996 / .967);
        --blue-a10: color(display-p3 .357 .631 1 / .971);
        --blue-a11: color(display-p3 .49 .72 1);
        --blue-a12: color(display-p3 .788 .898 .99);
        --bronze-1: color(display-p3 .076 .067 .063);
        --bronze-2: color(display-p3 .106 .097 .093);
        --bronze-3: color(display-p3 .147 .132 .125);
        --bronze-4: color(display-p3 .185 .166 .156);
        --bronze-5: color(display-p3 .227 .202 .19);
        --bronze-6: color(display-p3 .278 .246 .23);
        --bronze-7: color(display-p3 .343 .302 .281);
        --bronze-8: color(display-p3 .426 .374 .347);
        --bronze-9: color(display-p3 .611 .507 .455);
        --bronze-10: color(display-p3 .66 .556 .504);
        --bronze-11: color(display-p3 .81 .707 .655);
        --bronze-12: color(display-p3 .921 .88 .854);
        --bronze-a1: color(display-p3 .941 .067 0 / .009);
        --bronze-a2: color(display-p3 .98 .8 .706 / .043);
        --bronze-a3: color(display-p3 .988 .851 .761 / .085);
        --bronze-a4: color(display-p3 .996 .839 .78 / .127);
        --bronze-a5: color(display-p3 .996 .863 .773 / .173);
        --bronze-a6: color(display-p3 1 .863 .796 / .227);
        --bronze-a7: color(display-p3 1 .867 .8 / .295);
        --bronze-a8: color(display-p3 1 .859 .788 / .387);
        --bronze-a9: color(display-p3 1 .82 .733 / .585);
        --bronze-a10: color(display-p3 1 .839 .761 / .635);
        --bronze-a11: color(display-p3 .81 .707 .655);
        --bronze-a12: color(display-p3 .921 .88 .854);
        --brown-1: color(display-p3 .071 .067 .059);
        --brown-2: color(display-p3 .107 .095 .087);
        --brown-3: color(display-p3 .151 .13 .115);
        --brown-4: color(display-p3 .191 .161 .138);
        --brown-5: color(display-p3 .235 .194 .162);
        --brown-6: color(display-p3 .291 .237 .192);
        --brown-7: color(display-p3 .365 .295 .232);
        --brown-8: color(display-p3 .469 .377 .287);
        --brown-9: color(display-p3 .651 .505 .368);
        --brown-10: color(display-p3 .697 .557 .423);
        --brown-11: color(display-p3 .835 .715 .597);
        --brown-12: color(display-p3 .938 .885 .802);
        --brown-a1: color(display-p3 .855 .071 0 / .005);
        --brown-a2: color(display-p3 .98 .706 .525 / .043);
        --brown-a3: color(display-p3 .996 .745 .576 / .093);
        --brown-a4: color(display-p3 1 .765 .592 / .135);
        --brown-a5: color(display-p3 1 .761 .588 / .181);
        --brown-a6: color(display-p3 1 .773 .592 / .24);
        --brown-a7: color(display-p3 .996 .776 .58 / .32);
        --brown-a8: color(display-p3 1 .78 .573 / .433);
        --brown-a9: color(display-p3 1 .769 .549 / .627);
        --brown-a10: color(display-p3 1 .792 .596 / .677);
        --brown-a11: color(display-p3 .835 .715 .597);
        --brown-a12: color(display-p3 .938 .885 .802);
        --crimson-1: color(display-p3 .093 .068 .078);
        --crimson-2: color(display-p3 .117 .078 .095);
        --crimson-3: color(display-p3 .203 .091 .143);
        --crimson-4: color(display-p3 .277 .087 .182);
        --crimson-5: color(display-p3 .332 .115 .22);
        --crimson-6: color(display-p3 .394 .162 .268);
        --crimson-7: color(display-p3 .489 .222 .336);
        --crimson-8: color(display-p3 .638 .289 .429);
        --crimson-9: color(display-p3 .843 .298 .507);
        --crimson-10: color(display-p3 .864 .364 .539);
        --crimson-11: color(display-p3 1 .56 .66);
        --crimson-12: color(display-p3 .966 .834 .906);
        --crimson-a1: color(display-p3 .984 .071 .463 / .03);
        --crimson-a2: color(display-p3 .996 .282 .569 / .055);
        --crimson-a3: color(display-p3 .996 .227 .573 / .148);
        --crimson-a4: color(display-p3 1 .157 .569 / .227);
        --crimson-a5: color(display-p3 1 .231 .604 / .286);
        --crimson-a6: color(display-p3 1 .337 .643 / .349);
        --crimson-a7: color(display-p3 1 .416 .663 / .454);
        --crimson-a8: color(display-p3 .996 .427 .651 / .614);
        --crimson-a9: color(display-p3 1 .345 .596 / .832);
        --crimson-a10: color(display-p3 1 .42 .62 / .853);
        --crimson-a11: color(display-p3 1 .56 .66);
        --crimson-a12: color(display-p3 .966 .834 .906);
        --cyan-1: color(display-p3 .053 .085 .098);
        --cyan-2: color(display-p3 .072 .105 .122);
        --cyan-3: color(display-p3 .073 .168 .209);
        --cyan-4: color(display-p3 .063 .216 .277);
        --cyan-5: color(display-p3 .091 .267 .336);
        --cyan-6: color(display-p3 .137 .324 .4);
        --cyan-7: color(display-p3 .186 .398 .484);
        --cyan-8: color(display-p3 .23 .496 .6);
        --cyan-9: color(display-p3 .282 .627 .765);
        --cyan-10: color(display-p3 .331 .675 .801);
        --cyan-11: color(display-p3 .446 .79 .887);
        --cyan-12: color(display-p3 .757 .919 .962);
        --cyan-a1: color(display-p3 0 .647 .992 / .034);
        --cyan-a2: color(display-p3 .133 .733 1 / .059);
        --cyan-a3: color(display-p3 .122 .741 .996 / .152);
        --cyan-a4: color(display-p3 .051 .725 1 / .227);
        --cyan-a5: color(display-p3 .149 .757 1 / .29);
        --cyan-a6: color(display-p3 .267 .792 1 / .358);
        --cyan-a7: color(display-p3 .333 .808 1 / .446);
        --cyan-a8: color(display-p3 .357 .816 1 / .572);
        --cyan-a9: color(display-p3 .357 .82 1 / .748);
        --cyan-a10: color(display-p3 .4 .839 1 / .786);
        --cyan-a11: color(display-p3 .446 .79 .887);
        --cyan-a12: color(display-p3 .757 .919 .962);
        --gold-1: color(display-p3 .071 .071 .067);
        --gold-2: color(display-p3 .104 .101 .09);
        --gold-3: color(display-p3 .141 .136 .122);
        --gold-4: color(display-p3 .177 .17 .152);
        --gold-5: color(display-p3 .217 .207 .185);
        --gold-6: color(display-p3 .265 .252 .225);
        --gold-7: color(display-p3 .327 .31 .277);
        --gold-8: color(display-p3 .407 .384 .342);
        --gold-9: color(display-p3 .579 .517 .41);
        --gold-10: color(display-p3 .628 .566 .463);
        --gold-11: color(display-p3 .784 .728 .635);
        --gold-12: color(display-p3 .906 .887 .855);
        --gold-a1: color(display-p3 .855 .855 .071 / .005);
        --gold-a2: color(display-p3 .98 .89 .616 / .043);
        --gold-a3: color(display-p3 1 .949 .753 / .08);
        --gold-a4: color(display-p3 1 .933 .8 / .118);
        --gold-a5: color(display-p3 1 .949 .804 / .16);
        --gold-a6: color(display-p3 1 .925 .8 / .215);
        --gold-a7: color(display-p3 1 .945 .831 / .278);
        --gold-a8: color(display-p3 1 .937 .82 / .366);
        --gold-a9: color(display-p3 .996 .882 .69 / .551);
        --gold-a10: color(display-p3 1 .894 .725 / .601);
        --gold-a11: color(display-p3 .784 .728 .635);
        --gold-a12: color(display-p3 .906 .887 .855);
        --grass-1: color(display-p3 .062 .083 .067);
        --grass-2: color(display-p3 .083 .103 .085);
        --grass-3: color(display-p3 .118 .163 .122);
        --grass-4: color(display-p3 .142 .225 .15);
        --grass-5: color(display-p3 .178 .279 .186);
        --grass-6: color(display-p3 .217 .337 .224);
        --grass-7: color(display-p3 .258 .4 .264);
        --grass-8: color(display-p3 .302 .47 .305);
        --grass-9: color(display-p3 .38 .647 .378);
        --grass-10: color(display-p3 .426 .694 .426);
        --grass-11: color(display-p3 .535 .807 .542);
        --grass-12: color(display-p3 .797 .936 .776);
        --grass-a1: color(display-p3 0 .992 .071 / .017);
        --grass-a2: color(display-p3 .482 .996 .584 / .038);
        --grass-a3: color(display-p3 .549 .992 .588 / .106);
        --grass-a4: color(display-p3 .51 .996 .557 / .169);
        --grass-a5: color(display-p3 .553 1 .588 / .227);
        --grass-a6: color(display-p3 .584 1 .608 / .29);
        --grass-a7: color(display-p3 .604 1 .616 / .358);
        --grass-a8: color(display-p3 .608 1 .62 / .433);
        --grass-a9: color(display-p3 .573 1 .569 / .622);
        --grass-a10: color(display-p3 .6 .996 .6 / .673);
        --grass-a11: color(display-p3 .535 .807 .542);
        --grass-a12: color(display-p3 .797 .936 .776);
        --green-1: color(display-p3 .062 .083 .071);
        --green-2: color(display-p3 .079 .106 .09);
        --green-3: color(display-p3 .1 .173 .133);
        --green-4: color(display-p3 .115 .229 .166);
        --green-5: color(display-p3 .147 .282 .206);
        --green-6: color(display-p3 .185 .338 .25);
        --green-7: color(display-p3 .227 .403 .298);
        --green-8: color(display-p3 .27 .479 .351);
        --green-9: color(display-p3 .332 .634 .442);
        --green-10: color(display-p3 .357 .682 .474);
        --green-11: color(display-p3 .434 .828 .573);
        --green-12: color(display-p3 .747 .938 .807);
        --green-a1: color(display-p3 0 .992 .298 / .017);
        --green-a2: color(display-p3 .341 .98 .616 / .043);
        --green-a3: color(display-p3 .376 .996 .655 / .114);
        --green-a4: color(display-p3 .341 .996 .635 / .173);
        --green-a5: color(display-p3 .408 1 .678 / .232);
        --green-a6: color(display-p3 .475 1 .706 / .29);
        --green-a7: color(display-p3 .514 1 .706 / .362);
        --green-a8: color(display-p3 .529 1 .718 / .442);
        --green-a9: color(display-p3 .502 .996 .682 / .61);
        --green-a10: color(display-p3 .506 1 .682 / .66);
        --green-a11: color(display-p3 .434 .828 .573);
        --green-a12: color(display-p3 .747 .938 .807);
        --indigo-1: color(display-p3 .068 .074 .118);
        --indigo-2: color(display-p3 .081 .089 .144);
        --indigo-3: color(display-p3 .105 .141 .275);
        --indigo-4: color(display-p3 .129 .18 .369);
        --indigo-5: color(display-p3 .163 .22 .439);
        --indigo-6: color(display-p3 .203 .262 .5);
        --indigo-7: color(display-p3 .245 .309 .575);
        --indigo-8: color(display-p3 .285 .362 .674);
        --indigo-9: color(display-p3 .276 .384 .837);
        --indigo-10: color(display-p3 .354 .445 .866);
        --indigo-11: color(display-p3 .63 .69 1);
        --indigo-12: color(display-p3 .848 .881 .99);
        --indigo-a1: color(display-p3 .071 .212 .996 / .055);
        --indigo-a2: color(display-p3 .251 .345 .988 / .085);
        --indigo-a3: color(display-p3 .243 .404 1 / .223);
        --indigo-a4: color(display-p3 .263 .42 1 / .324);
        --indigo-a5: color(display-p3 .314 .451 1 / .4);
        --indigo-a6: color(display-p3 .361 .49 1 / .467);
        --indigo-a7: color(display-p3 .388 .51 1 / .547);
        --indigo-a8: color(display-p3 .404 .518 1 / .652);
        --indigo-a9: color(display-p3 .318 .451 1 / .824);
        --indigo-a10: color(display-p3 .404 .506 1 / .858);
        --indigo-a11: color(display-p3 .63 .69 1);
        --indigo-a12: color(display-p3 .848 .881 .99);
        --iris-1: color(display-p3 .075 .075 .114);
        --iris-2: color(display-p3 .089 .086 .14);
        --iris-3: color(display-p3 .128 .134 .272);
        --iris-4: color(display-p3 .153 .165 .382);
        --iris-5: color(display-p3 .192 .201 .44);
        --iris-6: color(display-p3 .239 .241 .491);
        --iris-7: color(display-p3 .291 .289 .565);
        --iris-8: color(display-p3 .35 .345 .673);
        --iris-9: color(display-p3 .357 .357 .81);
        --iris-10: color(display-p3 .428 .416 .843);
        --iris-11: color(display-p3 .685 .662 1);
        --iris-12: color(display-p3 .878 .875 .986);
        --iris-a1: color(display-p3 .224 .224 .992 / .051);
        --iris-a2: color(display-p3 .361 .314 1 / .08);
        --iris-a3: color(display-p3 .357 .373 1 / .219);
        --iris-a4: color(display-p3 .325 .361 1 / .337);
        --iris-a5: color(display-p3 .38 .4 1 / .4);
        --iris-a6: color(display-p3 .447 .447 1 / .454);
        --iris-a7: color(display-p3 .486 .486 1 / .534);
        --iris-a8: color(display-p3 .502 .494 1 / .652);
        --iris-a9: color(display-p3 .431 .431 1 / .799);
        --iris-a10: color(display-p3 .502 .486 1 / .832);
        --iris-a11: color(display-p3 .685 .662 1);
        --iris-a12: color(display-p3 .878 .875 .986);
        --jade-1: color(display-p3 .059 .083 .071);
        --jade-2: color(display-p3 .078 .11 .094);
        --jade-3: color(display-p3 .091 .176 .138);
        --jade-4: color(display-p3 .102 .228 .177);
        --jade-5: color(display-p3 .133 .279 .221);
        --jade-6: color(display-p3 .174 .334 .273);
        --jade-7: color(display-p3 .219 .402 .335);
        --jade-8: color(display-p3 .263 .488 .411);
        --jade-9: color(display-p3 .319 .63 .521);
        --jade-10: color(display-p3 .338 .68 .555);
        --jade-11: color(display-p3 .4 .835 .656);
        --jade-12: color(display-p3 .734 .934 .838);
        --jade-a1: color(display-p3 0 .992 .298 / .017);
        --jade-a2: color(display-p3 .318 .988 .651 / .047);
        --jade-a3: color(display-p3 .267 1 .667 / .118);
        --jade-a4: color(display-p3 .275 .996 .702 / .173);
        --jade-a5: color(display-p3 .361 1 .741 / .227);
        --jade-a6: color(display-p3 .439 1 .796 / .286);
        --jade-a7: color(display-p3 .49 1 .804 / .362);
        --jade-a8: color(display-p3 .506 1 .835 / .45);
        --jade-a9: color(display-p3 .478 .996 .816 / .606);
        --jade-a10: color(display-p3 .478 1 .816 / .656);
        --jade-a11: color(display-p3 .4 .835 .656);
        --jade-a12: color(display-p3 .734 .934 .838);
        --lime-1: color(display-p3 .067 .073 .048);
        --lime-2: color(display-p3 .086 .1 .067);
        --lime-3: color(display-p3 .13 .16 .099);
        --lime-4: color(display-p3 .172 .214 .126);
        --lime-5: color(display-p3 .213 .266 .153);
        --lime-6: color(display-p3 .257 .321 .182);
        --lime-7: color(display-p3 .307 .383 .215);
        --lime-8: color(display-p3 .365 .456 .25);
        --lime-9: color(display-p3 .78 .928 .466);
        --lime-10: color(display-p3 .865 .995 .519);
        --lime-11: color(display-p3 .771 .893 .485);
        --lime-12: color(display-p3 .905 .966 .753);
        --lime-a1: color(display-p3 .067 .941 0 / .009);
        --lime-a2: color(display-p3 .584 .996 .071 / .038);
        --lime-a3: color(display-p3 .69 1 .38 / .101);
        --lime-a4: color(display-p3 .729 1 .435 / .16);
        --lime-a5: color(display-p3 .745 1 .471 / .215);
        --lime-a6: color(display-p3 .769 1 .482 / .274);
        --lime-a7: color(display-p3 .769 1 .506 / .341);
        --lime-a8: color(display-p3 .784 1 .51 / .416);
        --lime-a9: color(display-p3 .839 1 .502 / .925);
        --lime-a10: color(display-p3 .871 1 .522 / .996);
        --lime-a11: color(display-p3 .771 .893 .485);
        --lime-a12: color(display-p3 .905 .966 .753);
        --mint-1: color(display-p3 .059 .082 .081);
        --mint-2: color(display-p3 .068 .104 .105);
        --mint-3: color(display-p3 .077 .17 .168);
        --mint-4: color(display-p3 .068 .224 .22);
        --mint-5: color(display-p3 .104 .275 .264);
        --mint-6: color(display-p3 .154 .332 .313);
        --mint-7: color(display-p3 .207 .403 .373);
        --mint-8: color(display-p3 .258 .49 .441);
        --mint-9: color(display-p3 .62 .908 .834);
        --mint-10: color(display-p3 .725 .954 .898);
        --mint-11: color(display-p3 .482 .825 .733);
        --mint-12: color(display-p3 .807 .955 .887);
        --mint-a1: color(display-p3 0 .992 .992 / .017);
        --mint-a2: color(display-p3 .071 .98 .98 / .043);
        --mint-a3: color(display-p3 .176 .996 .996 / .11);
        --mint-a4: color(display-p3 .071 .996 .973 / .169);
        --mint-a5: color(display-p3 .243 1 .949 / .223);
        --mint-a6: color(display-p3 .369 1 .933 / .286);
        --mint-a7: color(display-p3 .459 1 .914 / .362);
        --mint-a8: color(display-p3 .49 1 .89 / .454);
        --mint-a9: color(display-p3 .678 .996 .914 / .904);
        --mint-a10: color(display-p3 .761 1 .941 / .95);
        --mint-a11: color(display-p3 .482 .825 .733);
        --mint-a12: color(display-p3 .807 .955 .887);
        --orange-1: color(display-p3 .088 .07 .057);
        --orange-2: color(display-p3 .113 .089 .061);
        --orange-3: color(display-p3 .189 .12 .056);
        --orange-4: color(display-p3 .262 .132 0);
        --orange-5: color(display-p3 .315 .168 .016);
        --orange-6: color(display-p3 .376 .219 .088);
        --orange-7: color(display-p3 .465 .283 .147);
        --orange-8: color(display-p3 .601 .359 .201);
        --orange-9: color(display-p3 .9 .45 .2);
        --orange-10: color(display-p3 .98 .51 .23);
        --orange-11: color(display-p3 1 .63 .38);
        --orange-12: color(display-p3 .98 .883 .775);
        --orange-a1: color(display-p3 .961 .247 0 / .022);
        --orange-a2: color(display-p3 .992 .529 0 / .051);
        --orange-a3: color(display-p3 .996 .486 0 / .131);
        --orange-a4: color(display-p3 .996 .384 0 / .211);
        --orange-a5: color(display-p3 1 .455 0 / .265);
        --orange-a6: color(display-p3 1 .529 .129 / .332);
        --orange-a7: color(display-p3 1 .569 .251 / .429);
        --orange-a8: color(display-p3 1 .584 .302 / .572);
        --orange-a9: color(display-p3 1 .494 .216 / .895);
        --orange-a10: color(display-p3 1 .522 .235 / .979);
        --orange-a11: color(display-p3 1 .63 .38);
        --orange-a12: color(display-p3 .98 .883 .775);
        --pink-1: color(display-p3 .093 .068 .089);
        --pink-2: color(display-p3 .121 .073 .11);
        --pink-3: color(display-p3 .198 .098 .179);
        --pink-4: color(display-p3 .271 .095 .231);
        --pink-5: color(display-p3 .32 .127 .273);
        --pink-6: color(display-p3 .382 .177 .326);
        --pink-7: color(display-p3 .477 .238 .405);
        --pink-8: color(display-p3 .612 .304 .51);
        --pink-9: color(display-p3 .775 .297 .61);
        --pink-10: color(display-p3 .808 .356 .645);
        --pink-11: color(display-p3 1 .535 .78);
        --pink-12: color(display-p3 .964 .826 .912);
        --pink-a1: color(display-p3 .984 .071 .855 / .03);
        --pink-a2: color(display-p3 1 .2 .8 / .059);
        --pink-a3: color(display-p3 1 .294 .886 / .139);
        --pink-a4: color(display-p3 1 .192 .82 / .219);
        --pink-a5: color(display-p3 1 .282 .827 / .274);
        --pink-a6: color(display-p3 1 .396 .835 / .337);
        --pink-a7: color(display-p3 1 .459 .831 / .442);
        --pink-a8: color(display-p3 1 .478 .827 / .585);
        --pink-a9: color(display-p3 1 .373 .784 / .761);
        --pink-a10: color(display-p3 1 .435 .792 / .795);
        --pink-a11: color(display-p3 1 .535 .78);
        --pink-a12: color(display-p3 .964 .826 .912);
        --plum-1: color(display-p3 .09 .068 .092);
        --plum-2: color(display-p3 .118 .077 .121);
        --plum-3: color(display-p3 .192 .105 .202);
        --plum-4: color(display-p3 .25 .121 .271);
        --plum-5: color(display-p3 .293 .152 .319);
        --plum-6: color(display-p3 .343 .198 .372);
        --plum-7: color(display-p3 .424 .262 .461);
        --plum-8: color(display-p3 .54 .341 .595);
        --plum-9: color(display-p3 .624 .313 .708);
        --plum-10: color(display-p3 .666 .365 .748);
        --plum-11: color(display-p3 .86 .602 .933);
        --plum-12: color(display-p3 .936 .836 .949);
        --plum-a1: color(display-p3 .973 .071 .973 / .026);
        --plum-a2: color(display-p3 .933 .267 1 / .059);
        --plum-a3: color(display-p3 .918 .333 .996 / .148);
        --plum-a4: color(display-p3 .91 .318 1 / .219);
        --plum-a5: color(display-p3 .914 .388 1 / .269);
        --plum-a6: color(display-p3 .906 .463 1 / .328);
        --plum-a7: color(display-p3 .906 .529 1 / .425);
        --plum-a8: color(display-p3 .906 .553 1 / .568);
        --plum-a9: color(display-p3 .875 .427 1 / .69);
        --plum-a10: color(display-p3 .886 .471 .996 / .732);
        --plum-a11: color(display-p3 .86 .602 .933);
        --plum-a12: color(display-p3 .936 .836 .949);
        --purple-1: color(display-p3 .09 .068 .103);
        --purple-2: color(display-p3 .113 .082 .134);
        --purple-3: color(display-p3 .175 .112 .224);
        --purple-4: color(display-p3 .224 .137 .297);
        --purple-5: color(display-p3 .264 .167 .349);
        --purple-6: color(display-p3 .311 .208 .406);
        --purple-7: color(display-p3 .381 .266 .496);
        --purple-8: color(display-p3 .49 .349 .649);
        --purple-9: color(display-p3 .523 .318 .751);
        --purple-10: color(display-p3 .57 .373 .791);
        --purple-11: color(display-p3 .8 .62 1);
        --purple-12: color(display-p3 .913 .854 .971);
        --purple-a1: color(display-p3 .686 .071 .996 / .038);
        --purple-a2: color(display-p3 .722 .286 .996 / .072);
        --purple-a3: color(display-p3 .718 .349 .996 / .169);
        --purple-a4: color(display-p3 .702 .353 1 / .248);
        --purple-a5: color(display-p3 .718 .404 1 / .303);
        --purple-a6: color(display-p3 .733 .455 1 / .366);
        --purple-a7: color(display-p3 .753 .506 1 / .458);
        --purple-a8: color(display-p3 .749 .522 1 / .622);
        --purple-a9: color(display-p3 .686 .408 1 / .736);
        --purple-a10: color(display-p3 .71 .459 1 / .778);
        --purple-a11: color(display-p3 .8 .62 1);
        --purple-a12: color(display-p3 .913 .854 .971);
        --red-1: color(display-p3 .093 .068 .067);
        --red-2: color(display-p3 .118 .077 .079);
        --red-3: color(display-p3 .211 .081 .099);
        --red-4: color(display-p3 .287 .079 .113);
        --red-5: color(display-p3 .348 .11 .142);
        --red-6: color(display-p3 .414 .16 .183);
        --red-7: color(display-p3 .508 .224 .236);
        --red-8: color(display-p3 .659 .298 .297);
        --red-9: color(display-p3 .83 .329 .324);
        --red-10: color(display-p3 .861 .403 .387);
        --red-11: color(display-p3 1 .57 .55);
        --red-12: color(display-p3 .971 .826 .852);
        --red-a1: color(display-p3 .984 .071 .071 / .03);
        --red-a2: color(display-p3 .996 .282 .282 / .055);
        --red-a3: color(display-p3 1 .169 .271 / .156);
        --red-a4: color(display-p3 1 .118 .267 / .236);
        --red-a5: color(display-p3 1 .212 .314 / .303);
        --red-a6: color(display-p3 1 .318 .38 / .374);
        --red-a7: color(display-p3 1 .4 .424 / .475);
        --red-a8: color(display-p3 1 .431 .431 / .635);
        --red-a9: color(display-p3 1 .388 .384 / .82);
        --red-a10: color(display-p3 1 .463 .447 / .853);
        --red-a11: color(display-p3 1 .57 .55);
        --red-a12: color(display-p3 .971 .826 .852);
        --ruby-1: color(display-p3 .093 .068 .074);
        --ruby-2: color(display-p3 .113 .083 .089);
        --ruby-3: color(display-p3 .208 .088 .117);
        --ruby-4: color(display-p3 .279 .092 .147);
        --ruby-5: color(display-p3 .337 .12 .18);
        --ruby-6: color(display-p3 .401 .166 .223);
        --ruby-7: color(display-p3 .495 .224 .281);
        --ruby-8: color(display-p3 .652 .295 .359);
        --ruby-9: color(display-p3 .83 .323 .408);
        --ruby-10: color(display-p3 .857 .392 .455);
        --ruby-11: color(display-p3 1 .57 .59);
        --ruby-12: color(display-p3 .968 .83 .88);
        --ruby-a1: color(display-p3 .984 .071 .329 / .03);
        --ruby-a2: color(display-p3 .992 .376 .529 / .051);
        --ruby-a3: color(display-p3 .996 .196 .404 / .152);
        --ruby-a4: color(display-p3 1 .173 .416 / .227);
        --ruby-a5: color(display-p3 1 .259 .459 / .29);
        --ruby-a6: color(display-p3 1 .341 .506 / .358);
        --ruby-a7: color(display-p3 1 .412 .541 / .458);
        --ruby-a8: color(display-p3 1 .431 .537 / .627);
        --ruby-a9: color(display-p3 1 .376 .482 / .82);
        --ruby-a10: color(display-p3 1 .447 .522 / .849);
        --ruby-a11: color(display-p3 1 .57 .59);
        --ruby-a12: color(display-p3 .968 .83 .88);
        --sky-1: color(display-p3 .056 .078 .116);
        --sky-2: color(display-p3 .075 .101 .149);
        --sky-3: color(display-p3 .089 .154 .244);
        --sky-4: color(display-p3 .106 .207 .323);
        --sky-5: color(display-p3 .135 .261 .394);
        --sky-6: color(display-p3 .17 .322 .469);
        --sky-7: color(display-p3 .205 .394 .557);
        --sky-8: color(display-p3 .232 .48 .665);
        --sky-9: color(display-p3 .585 .877 .983);
        --sky-10: color(display-p3 .718 .925 .991);
        --sky-11: color(display-p3 .536 .772 .924);
        --sky-12: color(display-p3 .799 .947 .993);
        --sky-a1: color(display-p3 0 .282 .996 / .055);
        --sky-a2: color(display-p3 .157 .467 .992 / .089);
        --sky-a3: color(display-p3 .192 .522 .996 / .19);
        --sky-a4: color(display-p3 .212 .584 1 / .274);
        --sky-a5: color(display-p3 .259 .631 1 / .349);
        --sky-a6: color(display-p3 .302 .655 1 / .433);
        --sky-a7: color(display-p3 .329 .686 1 / .526);
        --sky-a8: color(display-p3 .325 .71 1 / .643);
        --sky-a9: color(display-p3 .592 .894 1 / .984);
        --sky-a10: color(display-p3 .722 .933 1 / .992);
        --sky-a11: color(display-p3 .536 .772 .924);
        --sky-a12: color(display-p3 .799 .947 .993);
        --teal-1: color(display-p3 .059 .083 .079);
        --teal-2: color(display-p3 .075 .11 .107);
        --teal-3: color(display-p3 .087 .175 .165);
        --teal-4: color(display-p3 .087 .227 .214);
        --teal-5: color(display-p3 .12 .277 .261);
        --teal-6: color(display-p3 .162 .335 .314);
        --teal-7: color(display-p3 .205 .406 .379);
        --teal-8: color(display-p3 .245 .489 .453);
        --teal-9: color(display-p3 .297 .637 .581);
        --teal-10: color(display-p3 .319 .69 .62);
        --teal-11: color(display-p3 .388 .835 .719);
        --teal-12: color(display-p3 .734 .934 .87);
        --teal-a1: color(display-p3 0 .992 .761 / .017);
        --teal-a2: color(display-p3 .235 .988 .902 / .047);
        --teal-a3: color(display-p3 .235 1 .898 / .118);
        --teal-a4: color(display-p3 .18 .996 .929 / .173);
        --teal-a5: color(display-p3 .31 1 .933 / .227);
        --teal-a6: color(display-p3 .396 1 .933 / .286);
        --teal-a7: color(display-p3 .443 1 .925 / .366);
        --teal-a8: color(display-p3 .459 1 .925 / .454);
        --teal-a9: color(display-p3 .443 .996 .906 / .61);
        --teal-a10: color(display-p3 .439 .996 .89 / .669);
        --teal-a11: color(display-p3 .388 .835 .719);
        --teal-a12: color(display-p3 .734 .934 .87);
        --tomato-1: color(display-p3 .09 .068 .067);
        --tomato-2: color(display-p3 .115 .084 .076);
        --tomato-3: color(display-p3 .205 .097 .083);
        --tomato-4: color(display-p3 .282 .099 .077);
        --tomato-5: color(display-p3 .339 .129 .101);
        --tomato-6: color(display-p3 .398 .179 .141);
        --tomato-7: color(display-p3 .487 .245 .194);
        --tomato-8: color(display-p3 .629 .322 .248);
        --tomato-9: color(display-p3 .831 .345 .231);
        --tomato-10: color(display-p3 .862 .415 .298);
        --tomato-11: color(display-p3 1 .585 .455);
        --tomato-12: color(display-p3 .959 .833 .802);
        --tomato-a1: color(display-p3 .973 .071 .071 / .026);
        --tomato-a2: color(display-p3 .992 .376 .224 / .051);
        --tomato-a3: color(display-p3 .996 .282 .176 / .148);
        --tomato-a4: color(display-p3 1 .204 .118 / .232);
        --tomato-a5: color(display-p3 1 .286 .192 / .29);
        --tomato-a6: color(display-p3 1 .392 .278 / .353);
        --tomato-a7: color(display-p3 1 .459 .349 / .45);
        --tomato-a8: color(display-p3 1 .49 .369 / .601);
        --tomato-a9: color(display-p3 1 .408 .267 / .82);
        --tomato-a10: color(display-p3 1 .478 .341 / .853);
        --tomato-a11: color(display-p3 1 .585 .455);
        --tomato-a12: color(display-p3 .959 .833 .802);
        --violet-1: color(display-p3 .077 .071 .118);
        --violet-2: color(display-p3 .101 .084 .141);
        --violet-3: color(display-p3 .154 .123 .256);
        --violet-4: color(display-p3 .191 .148 .345);
        --violet-5: color(display-p3 .226 .182 .396);
        --violet-6: color(display-p3 .269 .223 .449);
        --violet-7: color(display-p3 .326 .277 .53);
        --violet-8: color(display-p3 .399 .346 .656);
        --violet-9: color(display-p3 .417 .341 .784);
        --violet-10: color(display-p3 .477 .402 .823);
        --violet-11: color(display-p3 .72 .65 1);
        --violet-12: color(display-p3 .883 .867 .986);
        --violet-a1: color(display-p3 .282 .141 .996 / .055);
        --violet-a2: color(display-p3 .51 .263 1 / .08);
        --violet-a3: color(display-p3 .494 .337 .996 / .202);
        --violet-a4: color(display-p3 .49 .345 1 / .299);
        --violet-a5: color(display-p3 .525 .392 1 / .353);
        --violet-a6: color(display-p3 .569 .455 1 / .408);
        --violet-a7: color(display-p3 .588 .494 1 / .496);
        --violet-a8: color(display-p3 .596 .51 1 / .631);
        --violet-a9: color(display-p3 .522 .424 1 / .769);
        --violet-a10: color(display-p3 .576 .482 1 / .811);
        --violet-a11: color(display-p3 .72 .65 1);
        --violet-a12: color(display-p3 .883 .867 .986);
        --yellow-1: color(display-p3 .078 .069 .047);
        --yellow-2: color(display-p3 .103 .094 .063);
        --yellow-3: color(display-p3 .168 .137 .039);
        --yellow-4: color(display-p3 .209 .169 0);
        --yellow-5: color(display-p3 .255 .209 0);
        --yellow-6: color(display-p3 .31 .261 .07);
        --yellow-7: color(display-p3 .389 .331 .135);
        --yellow-8: color(display-p3 .497 .42 .182);
        --yellow-9: color(display-p3 1 .92 .22);
        --yellow-10: color(display-p3 1 1 .456);
        --yellow-11: color(display-p3 .948 .885 .392);
        --yellow-12: color(display-p3 .959 .934 .731);
        --yellow-a1: color(display-p3 .973 .369 0 / .013);
        --yellow-a2: color(display-p3 .996 .792 0 / .038);
        --yellow-a3: color(display-p3 .996 .71 0 / .11);
        --yellow-a4: color(display-p3 .996 .741 0 / .152);
        --yellow-a5: color(display-p3 .996 .765 0 / .202);
        --yellow-a6: color(display-p3 .996 .816 .082 / .261);
        --yellow-a7: color(display-p3 1 .831 .263 / .345);
        --yellow-a8: color(display-p3 1 .831 .314 / .463);
        --yellow-a9: color(display-p3 1 .922 .22);
        --yellow-a10: color(display-p3 1 1 .455);
        --yellow-a11: color(display-p3 .948 .885 .392);
        --yellow-a12: color(display-p3 .959 .934 .731);
        --gray-surface: color(display-p3 .1255 .1255 .1255 / .5);
        --mauve-surface: color(display-p3 .1333 .1255 .1333 / .5);
        --slate-surface: color(display-p3 .1176 .1255 .1333 / .5);
        --sage-surface: color(display-p3 .1176 .1255 .1176 / .5);
        --olive-surface: color(display-p3 .1176 .1255 .1176 / .5);
        --sand-surface: color(display-p3 .1255 .1255 .1255 / .5);
        --amber-surface: color(display-p3 .1412 .1176 .0784 / .5);
        --blue-surface: color(display-p3 .0706 .1255 .2196 / .5);
        --bronze-surface: color(display-p3 .1412 .1255 .1176 / .5);
        --brown-surface: color(display-p3 .1412 .1176 .102 / .5);
        --crimson-surface: color(display-p3 .1647 .0863 .1176 / .5);
        --cyan-surface: color(display-p3 .0784 .1412 .1725 / .5);
        --gold-surface: color(display-p3 .1412 .1333 .1098 / .5);
        --grass-surface: color(display-p3 .102 .1333 .102 / .5);
        --green-surface: color(display-p3 .0941 .1412 .1098 / .5);
        --indigo-surface: color(display-p3 .0941 .1098 .2196 / .5);
        --iris-surface: color(display-p3 .1098 .102 .2118 / .5);
        --jade-surface: color(display-p3 .0863 .149 .1176 / .5);
        --lime-surface: color(display-p3 .1098 .1255 .0784 / .5);
        --mint-surface: color(display-p3 .0941 .149 .1412 / .5);
        --orange-surface: color(display-p3 .1412 .1098 .0706 / .5);
        --pink-surface: color(display-p3 .1725 .0784 .149 / .5);
        --plum-surface: color(display-p3 .1647 .0863 .1725 / .5);
        --purple-surface: color(display-p3 .149 .0941 .1961 / .5);
        --red-surface: color(display-p3 .1647 .0863 .0863 / .5);
        --ruby-surface: color(display-p3 .1569 .0941 .1098 / .5);
        --sky-surface: color(display-p3 .0863 .1333 .2196 / .5);
        --teal-surface: color(display-p3 .0863 .149 .1412 / .5);
        --tomato-surface: color(display-p3 .1569 .0941 .0784 / .5);
        --violet-surface: color(display-p3 .1333 .102 .2118 / .5);
        --yellow-surface: color(display-p3 .1333 .1176 .0706 / .5);
      }
    }
  }
}

:root {
  --gray-contrast: white;
  --mauve-contrast: white;
  --slate-contrast: white;
  --sage-contrast: white;
  --olive-contrast: white;
  --sand-contrast: white;
  --amber-contrast: #21201c;
  --blue-contrast: white;
  --bronze-contrast: white;
  --brown-contrast: white;
  --crimson-contrast: white;
  --cyan-contrast: white;
  --gold-contrast: white;
  --grass-contrast: white;
  --green-contrast: white;
  --indigo-contrast: white;
  --iris-contrast: white;
  --jade-contrast: white;
  --lime-contrast: #1d211c;
  --mint-contrast: #1a211e;
  --orange-contrast: white;
  --pink-contrast: white;
  --plum-contrast: white;
  --purple-contrast: white;
  --red-contrast: white;
  --ruby-contrast: white;
  --sky-contrast: #1c2024;
  --teal-contrast: white;
  --tomato-contrast: white;
  --violet-contrast: white;
  --yellow-contrast: #21201c;
  --black-a1: rgba(0, 0, 0, .05);
  --black-a2: rgba(0, 0, 0, .1);
  --black-a3: rgba(0, 0, 0, .15);
  --black-a4: rgba(0, 0, 0, .2);
  --black-a5: rgba(0, 0, 0, .3);
  --black-a6: rgba(0, 0, 0, .4);
  --black-a7: rgba(0, 0, 0, .5);
  --black-a8: rgba(0, 0, 0, .6);
  --black-a9: rgba(0, 0, 0, .7);
  --black-a10: rgba(0, 0, 0, .8);
  --black-a11: rgba(0, 0, 0, .9);
  --black-a12: rgba(0, 0, 0, .95);
  --white-a1: rgba(255, 255, 255, .05);
  --white-a2: rgba(255, 255, 255, .1);
  --white-a3: rgba(255, 255, 255, .15);
  --white-a4: rgba(255, 255, 255, .2);
  --white-a5: rgba(255, 255, 255, .3);
  --white-a6: rgba(255, 255, 255, .4);
  --white-a7: rgba(255, 255, 255, .5);
  --white-a8: rgba(255, 255, 255, .6);
  --white-a9: rgba(255, 255, 255, .7);
  --white-a10: rgba(255, 255, 255, .8);
  --white-a11: rgba(255, 255, 255, .9);
  --white-a12: rgba(255, 255, 255, .95);
}

@supports (color: color-mix(in oklab, white, black)) {
  .dark, .dark-theme {
    --amber-track: color-mix(in oklab, var(--amber-8), var(--amber-9) 75%);
    --lime-track: color-mix(in oklab, var(--lime-8), var(--lime-9) 65%);
    --mint-track: color-mix(in oklab, var(--mint-8), var(--mint-9) 65%);
    --sky-track: color-mix(in oklab, var(--sky-8), var(--sky-9) 65%);
    --yellow-track: color-mix(in oklab, var(--yellow-8), var(--yellow-9) 65%);
  }
}

@supports (color: color(display-p3 1 1 1)) {
  @media (color-gamut: p3) {
    :root {
      --black-a1: rgba(0, 0, 0, .05);
      --black-a2: rgba(0, 0, 0, .1);
      --black-a3: rgba(0, 0, 0, .15);
      --black-a4: rgba(0, 0, 0, .2);
      --black-a5: rgba(0, 0, 0, .3);
      --black-a6: rgba(0, 0, 0, .4);
      --black-a7: rgba(0, 0, 0, .5);
      --black-a8: rgba(0, 0, 0, .6);
      --black-a9: rgba(0, 0, 0, .7);
      --black-a10: rgba(0, 0, 0, .8);
      --black-a11: rgba(0, 0, 0, .9);
      --black-a12: rgba(0, 0, 0, .95);
      --white-a1: rgba(255, 255, 255, .05);
      --white-a2: rgba(255, 255, 255, .1);
      --white-a3: rgba(255, 255, 255, .15);
      --white-a4: rgba(255, 255, 255, .2);
      --white-a5: rgba(255, 255, 255, .3);
      --white-a6: rgba(255, 255, 255, .4);
      --white-a7: rgba(255, 255, 255, .5);
      --white-a8: rgba(255, 255, 255, .6);
      --white-a9: rgba(255, 255, 255, .7);
      --white-a10: rgba(255, 255, 255, .8);
      --white-a11: rgba(255, 255, 255, .9);
      --white-a12: rgba(255, 255, 255, .95);
    }

    @supports (color: color(display-p3 0 0 0)) {
      :root {
        --black-a1: color(display-p3 0 0 0 / .05);
        --black-a2: color(display-p3 0 0 0 / .1);
        --black-a3: color(display-p3 0 0 0 / .15);
        --black-a4: color(display-p3 0 0 0 / .2);
        --black-a5: color(display-p3 0 0 0 / .3);
        --black-a6: color(display-p3 0 0 0 / .4);
        --black-a7: color(display-p3 0 0 0 / .5);
        --black-a8: color(display-p3 0 0 0 / .6);
        --black-a9: color(display-p3 0 0 0 / .7);
        --black-a10: color(display-p3 0 0 0 / .8);
        --black-a11: color(display-p3 0 0 0 / .9);
        --black-a12: color(display-p3 0 0 0 / .95);
        --white-a1: color(display-p3 1 1 1 / .05);
        --white-a2: color(display-p3 1 1 1 / .1);
        --white-a3: color(display-p3 1 1 1 / .15);
        --white-a4: color(display-p3 1 1 1 / .2);
        --white-a5: color(display-p3 1 1 1 / .3);
        --white-a6: color(display-p3 1 1 1 / .4);
        --white-a7: color(display-p3 1 1 1 / .5);
        --white-a8: color(display-p3 1 1 1 / .6);
        --white-a9: color(display-p3 1 1 1 / .7);
        --white-a10: color(display-p3 1 1 1 / .8);
        --white-a11: color(display-p3 1 1 1 / .9);
        --white-a12: color(display-p3 1 1 1 / .95);
      }
    }
  }
}

:where(.radix-themes) {
  --color-background: white;
  --color-overlay: var(--black-a6);
  --color-panel-solid: white;
  --color-panel-translucent: rgba(255, 255, 255, .7);
  --color-surface: rgba(255, 255, 255, .85);
  --color-transparent: rgba(0, 0, 0, 0);
  --shadow-1: inset 0 0 0 1px var(--gray-a5), inset 0 1.5px 2px 0 var(--gray-a2), inset 0 1.5px 2px 0 var(--black-a2);
  --shadow-2: 0 0 0 1px var(--gray-a3), 0 0 0 .5px var(--black-a1), 0 1px 1px 0 var(--gray-a2), 0 2px 1px -1px var(--black-a1), 0 1px 3px 0 var(--black-a1);
  --shadow-3: 0 0 0 1px var(--gray-a3), 0 2px 3px -2px var(--gray-a3), 0 3px 12px -4px var(--black-a2), 0 4px 16px -8px var(--black-a2);
  --shadow-4: 0 0 0 1px var(--gray-a3), 0 8px 40px var(--black-a1), 0 12px 32px -16px var(--gray-a3);
  --shadow-5: 0 0 0 1px var(--gray-a3), 0 12px 60px var(--black-a3), 0 12px 32px -16px var(--gray-a5);
  --shadow-6: 0 0 0 1px var(--gray-a3), 0 12px 60px var(--black-a3), 0 16px 64px var(--gray-a2), 0 16px 36px -20px var(--gray-a7);
  --base-button-classic-after-inset: 2px;
  --base-button-classic-box-shadow-top: inset 0 0 0 1px var(--gray-a4), inset 0 -2px 1px var(--gray-a3);
  --base-button-classic-box-shadow-bottom: inset 0 4px 2px -2px var(--white-a9), inset 0 2px 1px -1px var(--white-a9);
  --base-button-classic-disabled-box-shadow: var(--base-button-classic-box-shadow-top), var(--base-button-classic-box-shadow-bottom);
  --base-button-classic-active-filter: brightness(.92) saturate(1.1);
  --base-button-classic-high-contrast-hover-filter: contrast(.88) saturate(1.1) brightness(1.1);
  --base-button-classic-high-contrast-active-filter: contrast(.82) saturate(1.2) brightness(1.16);
  --base-button-solid-active-filter: brightness(.92) saturate(1.1);
  --base-button-solid-high-contrast-hover-filter: contrast(.88) saturate(1.1) brightness(1.1);
  --base-button-solid-high-contrast-active-filter: contrast(.82) saturate(1.2) brightness(1.16);
  --kbd-box-shadow: inset 0 -.05em .5em var(--gray-a2), inset 0 .05em var(--white-a12), inset 0 .25em .5em var(--gray-a2), inset 0 -.05em var(--gray-a6), 0 0 0 .05em var(--gray-a5), 0 .08em .17em var(--gray-a7);
  --progress-indicator-after-linear-gradient: var(--white-a5), var(--white-a9), var(--white-a5);
  --segmented-control-indicator-background-color: var(--color-background);
  --select-trigger-classic-box-shadow: inset 0 0 0 1px var(--gray-a5), inset 0 2px 1px var(--white-a11), inset 0 -2px 1px var(--gray-a4);
  --slider-range-high-contrast-background-image: linear-gradient(var(--black-a8), var(--black-a8));
  --slider-disabled-blend-mode: multiply;
  --switch-disabled-blend-mode: multiply;
  --switch-high-contrast-checked-color-overlay: var(--black-a8);
  --switch-high-contrast-checked-active-before-filter: contrast(.82) saturate(1.2) brightness(1.16);
  --switch-surface-checked-active-filter: brightness(.92) saturate(1.1);
  --base-card-surface-box-shadow: 0 0 0 1px var(--gray-a5);
  --base-card-surface-hover-box-shadow: 0 0 0 1px var(--gray-a7);
  --base-card-surface-active-box-shadow: 0 0 0 1px var(--gray-a6);
  --base-card-classic-box-shadow-inner: 0 0 0 1px var(--base-card-classic-border-color), 0 0 0 1px var(--color-transparent), 0 0 0 .5px var(--black-a1), 0 1px 1px 0 var(--gray-a2), 0 2px 1px -1px var(--black-a1), 0 1px 3px 0 var(--black-a1);
  --base-card-classic-box-shadow-outer: 0 0 0 0 var(--base-card-classic-border-color), 0 0 0 0 var(--color-transparent), 0 0 0 0 var(--black-a1), 0 1px 1px -1px var(--gray-a2), 0 2px 1px -2px var(--black-a1), 0 1px 3px -1px var(--black-a1);
  --base-card-classic-hover-box-shadow-inner: 0 0 0 1px var(--base-card-classic-hover-border-color), 0 1px 1px 1px var(--black-a1), 0 2px 1px -1px var(--gray-a3), 0 2px 3px -2px var(--black-a1), 0 3px 12px -4px var(--gray-a3), 0 4px 16px -8px var(--black-a1);
  --base-card-classic-hover-box-shadow-outer: 0 0 0 0 var(--base-card-classic-hover-border-color), 0 1px 1px 0 var(--black-a1), 0 2px 1px -2px var(--gray-a3), 0 2px 3px -3px var(--black-a1), 0 3px 12px -5px var(--gray-a3), 0 4px 16px -9px var(--black-a1);
  --base-card-classic-active-box-shadow-inner: 0 0 0 1px var(--base-card-classic-active-border-color), 0 0 0 1px var(--color-transparent), 0 0 0 .5px var(--black-a1), 0 1px 1px 0 var(--gray-a4), 0 2px 1px -1px var(--black-a1), 0 1px 3px 0 var(--black-a1);
  --base-card-classic-active-box-shadow-outer: 0 0 0 0 var(--base-card-classic-active-border-color), 0 0 0 0 var(--color-transparent), 0 0 0 0 var(--black-a1), 0 1px 1px -1px var(--gray-a4), 0 2px 1px -2px var(--black-a1), 0 1px 3px -1px var(--black-a1);
  --base-card-classic-border-color: var(--gray-a3);
  --base-card-classic-hover-border-color: var(--gray-a3);
  --base-card-classic-active-border-color: var(--gray-a4);
}

:-webkit-any(.dark, .dark-theme) {
  --color-background: var(--gray-1);
  --color-overlay: var(--black-a8);
  --color-panel-solid: var(--gray-2);
  --color-panel-translucent: var(--gray-a2);
  --color-surface: rgba(0, 0, 0, .25);
  --shadow-1: inset 0 -1px 1px 0 var(--gray-a3), inset 0 0 0 1px var(--gray-a3), inset 0 3px 4px 0 var(--black-a5), inset 0 0 0 1px var(--gray-a4);
  --shadow-2: 0 0 0 1px var(--gray-a6), 0 0 0 .5px var(--black-a3), 0 1px 1px 0 var(--black-a6), 0 2px 1px -1px var(--black-a6), 0 1px 3px 0 var(--black-a5);
  --shadow-3: 0 0 0 1px var(--gray-a6), 0 2px 3px -2px var(--black-a3), 0 3px 8px -2px var(--black-a6), 0 4px 12px -4px var(--black-a7);
  --shadow-4: 0 0 0 1px var(--gray-a6), 0 8px 40px var(--black-a3), 0 12px 32px -16px var(--black-a5);
  --shadow-5: 0 0 0 1px var(--gray-a6), 0 12px 60px var(--black-a5), 0 12px 32px -16px var(--black-a7);
  --shadow-6: 0 0 0 1px var(--gray-a6), 0 12px 60px var(--black-a4), 0 16px 64px var(--black-a6), 0 16px 36px -20px var(--black-a11);
  --base-button-classic-after-inset: 1px;
  --base-button-classic-box-shadow-top: inset 0 0 0 1px var(--white-a2), inset 0 4px 2px -2px var(--white-a3), inset 0 1px 1px var(--white-a6), inset 0 -1px 1px var(--black-a6);
  --base-button-classic-box-shadow-bottom: 0 0 transparent;
  --base-button-classic-disabled-box-shadow: inset 0 0 0 1px var(--gray-a5), inset 0 4px 2px -2px var(--gray-a2), inset 0 1px 1px var(--gray-a5), inset 0 -1px 1px var(--black-a3), inset 0 0 0 1px var(--gray-a2);
  --base-button-classic-active-filter: brightness(1.08);
  --base-button-classic-high-contrast-hover-filter: contrast(.88) saturate(1.3) brightness(1.14);
  --base-button-classic-high-contrast-active-filter: brightness(.95) saturate(1.2);
  --base-button-solid-active-filter: brightness(1.08);
  --base-button-solid-high-contrast-hover-filter: contrast(.88) saturate(1.3) brightness(1.18);
  --base-button-solid-high-contrast-active-filter: brightness(.95) saturate(1.2);
  --kbd-box-shadow: inset 0 -.05em .5em var(--gray-a3), inset 0 .05em var(--gray-a11), inset 0 .25em .5em var(--gray-a2), inset 0 -.1em var(--black-a11), 0 0 0 .075em var(--gray-a7), 0 .08em .17em var(--black-a12);
  --progress-indicator-after-linear-gradient: var(--white-a3), var(--white-a6), var(--white-a3);
  --segmented-control-indicator-background-color: var(--gray-a3);
  --select-trigger-classic-box-shadow: inset 0 0 0 1px var(--white-a4), inset 0 1px 1px var(--white-a4), inset 0 -1px 1px var(--black-a9);
  --slider-range-high-contrast-background-image: none;
  --slider-disabled-blend-mode: screen;
  --switch-disabled-blend-mode: screen;
  --switch-high-contrast-checked-color-overlay: transparent;
  --switch-high-contrast-checked-active-before-filter: brightness(1.08);
  --switch-surface-checked-active-filter: brightness(1.08);
  --base-card-classic-box-shadow-inner: 0 0 0 1px var(--base-card-classic-border-color), 0 0 0 1px var(--color-transparent), 0 0 0 .5px var(--black-a3), 0 1px 1px 0 var(--black-a6), 0 2px 1px -1px var(--black-a6), 0 1px 3px 0 var(--black-a5);
  --base-card-classic-box-shadow-outer: 0 0 0 0 var(--base-card-classic-border-color), 0 0 0 0 var(--color-transparent), 0 0 0 0 var(--black-a3), 0 1px 1px -1px var(--black-a6), 0 2px 1px -2px var(--black-a6), 0 1px 3px -1px var(--black-a5);
  --base-card-classic-hover-box-shadow-inner: 0 0 0 1px var(--base-card-classic-hover-border-color), 0 0 1px 1px var(--gray-a4), 0 0 1px -1px var(--gray-a4), 0 0 3px -2px var(--gray-a3), 0 0 12px -2px var(--gray-a3), 0 0 16px -8px var(--gray-a7);
  --base-card-classic-hover-box-shadow-outer: 0 0 0 0 var(--base-card-classic-hover-border-color), 0 0 1px 0 var(--gray-a4), 0 0 1px -2px var(--gray-a4), 0 0 3px -3px var(--gray-a3), 0 0 12px -3px var(--gray-a3), 0 0 16px -9px var(--gray-a7);
  --base-card-classic-active-box-shadow-inner: 0 0 0 1px var(--base-card-classic-active-border-color), 0 0 0 1px var(--color-transparent), 0 0 0 .5px var(--black-a3), 0 1px 1px 0 var(--black-a6), 0 2px 1px -1px var(--black-a6), 0 1px 3px 0 var(--black-a5);
  --base-card-classic-active-box-shadow-outer: 0 0 0 0 var(--base-card-classic-active-border-color), 0 0 0 0 var(--color-transparent), 0 0 0 0 var(--black-a3), 0 1px 1px -1px var(--black-a6), 0 2px 1px -2px var(--black-a6), 0 1px 3px -1px var(--black-a5);
  --base-card-classic-border-color: var(--gray-a6);
  --base-card-classic-hover-border-color: var(--gray-a6);
  --base-card-classic-active-border-color: var(--gray-a6);
}

:-moz-any(.dark, .dark-theme) {
  --color-background: var(--gray-1);
  --color-overlay: var(--black-a8);
  --color-panel-solid: var(--gray-2);
  --color-panel-translucent: var(--gray-a2);
  --color-surface: rgba(0, 0, 0, .25);
  --shadow-1: inset 0 -1px 1px 0 var(--gray-a3), inset 0 0 0 1px var(--gray-a3), inset 0 3px 4px 0 var(--black-a5), inset 0 0 0 1px var(--gray-a4);
  --shadow-2: 0 0 0 1px var(--gray-a6), 0 0 0 .5px var(--black-a3), 0 1px 1px 0 var(--black-a6), 0 2px 1px -1px var(--black-a6), 0 1px 3px 0 var(--black-a5);
  --shadow-3: 0 0 0 1px var(--gray-a6), 0 2px 3px -2px var(--black-a3), 0 3px 8px -2px var(--black-a6), 0 4px 12px -4px var(--black-a7);
  --shadow-4: 0 0 0 1px var(--gray-a6), 0 8px 40px var(--black-a3), 0 12px 32px -16px var(--black-a5);
  --shadow-5: 0 0 0 1px var(--gray-a6), 0 12px 60px var(--black-a5), 0 12px 32px -16px var(--black-a7);
  --shadow-6: 0 0 0 1px var(--gray-a6), 0 12px 60px var(--black-a4), 0 16px 64px var(--black-a6), 0 16px 36px -20px var(--black-a11);
  --base-button-classic-after-inset: 1px;
  --base-button-classic-box-shadow-top: inset 0 0 0 1px var(--white-a2), inset 0 4px 2px -2px var(--white-a3), inset 0 1px 1px var(--white-a6), inset 0 -1px 1px var(--black-a6);
  --base-button-classic-box-shadow-bottom: 0 0 transparent;
  --base-button-classic-disabled-box-shadow: inset 0 0 0 1px var(--gray-a5), inset 0 4px 2px -2px var(--gray-a2), inset 0 1px 1px var(--gray-a5), inset 0 -1px 1px var(--black-a3), inset 0 0 0 1px var(--gray-a2);
  --base-button-classic-active-filter: brightness(1.08);
  --base-button-classic-high-contrast-hover-filter: contrast(.88) saturate(1.3) brightness(1.14);
  --base-button-classic-high-contrast-active-filter: brightness(.95) saturate(1.2);
  --base-button-solid-active-filter: brightness(1.08);
  --base-button-solid-high-contrast-hover-filter: contrast(.88) saturate(1.3) brightness(1.18);
  --base-button-solid-high-contrast-active-filter: brightness(.95) saturate(1.2);
  --kbd-box-shadow: inset 0 -.05em .5em var(--gray-a3), inset 0 .05em var(--gray-a11), inset 0 .25em .5em var(--gray-a2), inset 0 -.1em var(--black-a11), 0 0 0 .075em var(--gray-a7), 0 .08em .17em var(--black-a12);
  --progress-indicator-after-linear-gradient: var(--white-a3), var(--white-a6), var(--white-a3);
  --segmented-control-indicator-background-color: var(--gray-a3);
  --select-trigger-classic-box-shadow: inset 0 0 0 1px var(--white-a4), inset 0 1px 1px var(--white-a4), inset 0 -1px 1px var(--black-a9);
  --slider-range-high-contrast-background-image: none;
  --slider-disabled-blend-mode: screen;
  --switch-disabled-blend-mode: screen;
  --switch-high-contrast-checked-color-overlay: transparent;
  --switch-high-contrast-checked-active-before-filter: brightness(1.08);
  --switch-surface-checked-active-filter: brightness(1.08);
  --base-card-classic-box-shadow-inner: 0 0 0 1px var(--base-card-classic-border-color), 0 0 0 1px var(--color-transparent), 0 0 0 .5px var(--black-a3), 0 1px 1px 0 var(--black-a6), 0 2px 1px -1px var(--black-a6), 0 1px 3px 0 var(--black-a5);
  --base-card-classic-box-shadow-outer: 0 0 0 0 var(--base-card-classic-border-color), 0 0 0 0 var(--color-transparent), 0 0 0 0 var(--black-a3), 0 1px 1px -1px var(--black-a6), 0 2px 1px -2px var(--black-a6), 0 1px 3px -1px var(--black-a5);
  --base-card-classic-hover-box-shadow-inner: 0 0 0 1px var(--base-card-classic-hover-border-color), 0 0 1px 1px var(--gray-a4), 0 0 1px -1px var(--gray-a4), 0 0 3px -2px var(--gray-a3), 0 0 12px -2px var(--gray-a3), 0 0 16px -8px var(--gray-a7);
  --base-card-classic-hover-box-shadow-outer: 0 0 0 0 var(--base-card-classic-hover-border-color), 0 0 1px 0 var(--gray-a4), 0 0 1px -2px var(--gray-a4), 0 0 3px -3px var(--gray-a3), 0 0 12px -3px var(--gray-a3), 0 0 16px -9px var(--gray-a7);
  --base-card-classic-active-box-shadow-inner: 0 0 0 1px var(--base-card-classic-active-border-color), 0 0 0 1px var(--color-transparent), 0 0 0 .5px var(--black-a3), 0 1px 1px 0 var(--black-a6), 0 2px 1px -1px var(--black-a6), 0 1px 3px 0 var(--black-a5);
  --base-card-classic-active-box-shadow-outer: 0 0 0 0 var(--base-card-classic-active-border-color), 0 0 0 0 var(--color-transparent), 0 0 0 0 var(--black-a3), 0 1px 1px -1px var(--black-a6), 0 2px 1px -2px var(--black-a6), 0 1px 3px -1px var(--black-a5);
  --base-card-classic-border-color: var(--gray-a6);
  --base-card-classic-hover-border-color: var(--gray-a6);
  --base-card-classic-active-border-color: var(--gray-a6);
}

:is(.dark, .dark-theme) {
  --color-background: var(--gray-1);
  --color-overlay: var(--black-a8);
  --color-panel-solid: var(--gray-2);
  --color-panel-translucent: var(--gray-a2);
  --color-surface: rgba(0, 0, 0, .25);
  --shadow-1: inset 0 -1px 1px 0 var(--gray-a3), inset 0 0 0 1px var(--gray-a3), inset 0 3px 4px 0 var(--black-a5), inset 0 0 0 1px var(--gray-a4);
  --shadow-2: 0 0 0 1px var(--gray-a6), 0 0 0 .5px var(--black-a3), 0 1px 1px 0 var(--black-a6), 0 2px 1px -1px var(--black-a6), 0 1px 3px 0 var(--black-a5);
  --shadow-3: 0 0 0 1px var(--gray-a6), 0 2px 3px -2px var(--black-a3), 0 3px 8px -2px var(--black-a6), 0 4px 12px -4px var(--black-a7);
  --shadow-4: 0 0 0 1px var(--gray-a6), 0 8px 40px var(--black-a3), 0 12px 32px -16px var(--black-a5);
  --shadow-5: 0 0 0 1px var(--gray-a6), 0 12px 60px var(--black-a5), 0 12px 32px -16px var(--black-a7);
  --shadow-6: 0 0 0 1px var(--gray-a6), 0 12px 60px var(--black-a4), 0 16px 64px var(--black-a6), 0 16px 36px -20px var(--black-a11);
  --base-button-classic-after-inset: 1px;
  --base-button-classic-box-shadow-top: inset 0 0 0 1px var(--white-a2), inset 0 4px 2px -2px var(--white-a3), inset 0 1px 1px var(--white-a6), inset 0 -1px 1px var(--black-a6);
  --base-button-classic-box-shadow-bottom: 0 0 transparent;
  --base-button-classic-disabled-box-shadow: inset 0 0 0 1px var(--gray-a5), inset 0 4px 2px -2px var(--gray-a2), inset 0 1px 1px var(--gray-a5), inset 0 -1px 1px var(--black-a3), inset 0 0 0 1px var(--gray-a2);
  --base-button-classic-active-filter: brightness(1.08);
  --base-button-classic-high-contrast-hover-filter: contrast(.88) saturate(1.3) brightness(1.14);
  --base-button-classic-high-contrast-active-filter: brightness(.95) saturate(1.2);
  --base-button-solid-active-filter: brightness(1.08);
  --base-button-solid-high-contrast-hover-filter: contrast(.88) saturate(1.3) brightness(1.18);
  --base-button-solid-high-contrast-active-filter: brightness(.95) saturate(1.2);
  --kbd-box-shadow: inset 0 -.05em .5em var(--gray-a3), inset 0 .05em var(--gray-a11), inset 0 .25em .5em var(--gray-a2), inset 0 -.1em var(--black-a11), 0 0 0 .075em var(--gray-a7), 0 .08em .17em var(--black-a12);
  --progress-indicator-after-linear-gradient: var(--white-a3), var(--white-a6), var(--white-a3);
  --segmented-control-indicator-background-color: var(--gray-a3);
  --select-trigger-classic-box-shadow: inset 0 0 0 1px var(--white-a4), inset 0 1px 1px var(--white-a4), inset 0 -1px 1px var(--black-a9);
  --slider-range-high-contrast-background-image: none;
  --slider-disabled-blend-mode: screen;
  --switch-disabled-blend-mode: screen;
  --switch-high-contrast-checked-color-overlay: transparent;
  --switch-high-contrast-checked-active-before-filter: brightness(1.08);
  --switch-surface-checked-active-filter: brightness(1.08);
  --base-card-classic-box-shadow-inner: 0 0 0 1px var(--base-card-classic-border-color), 0 0 0 1px var(--color-transparent), 0 0 0 .5px var(--black-a3), 0 1px 1px 0 var(--black-a6), 0 2px 1px -1px var(--black-a6), 0 1px 3px 0 var(--black-a5);
  --base-card-classic-box-shadow-outer: 0 0 0 0 var(--base-card-classic-border-color), 0 0 0 0 var(--color-transparent), 0 0 0 0 var(--black-a3), 0 1px 1px -1px var(--black-a6), 0 2px 1px -2px var(--black-a6), 0 1px 3px -1px var(--black-a5);
  --base-card-classic-hover-box-shadow-inner: 0 0 0 1px var(--base-card-classic-hover-border-color), 0 0 1px 1px var(--gray-a4), 0 0 1px -1px var(--gray-a4), 0 0 3px -2px var(--gray-a3), 0 0 12px -2px var(--gray-a3), 0 0 16px -8px var(--gray-a7);
  --base-card-classic-hover-box-shadow-outer: 0 0 0 0 var(--base-card-classic-hover-border-color), 0 0 1px 0 var(--gray-a4), 0 0 1px -2px var(--gray-a4), 0 0 3px -3px var(--gray-a3), 0 0 12px -3px var(--gray-a3), 0 0 16px -9px var(--gray-a7);
  --base-card-classic-active-box-shadow-inner: 0 0 0 1px var(--base-card-classic-active-border-color), 0 0 0 1px var(--color-transparent), 0 0 0 .5px var(--black-a3), 0 1px 1px 0 var(--black-a6), 0 2px 1px -1px var(--black-a6), 0 1px 3px 0 var(--black-a5);
  --base-card-classic-active-box-shadow-outer: 0 0 0 0 var(--base-card-classic-active-border-color), 0 0 0 0 var(--color-transparent), 0 0 0 0 var(--black-a3), 0 1px 1px -1px var(--black-a6), 0 2px 1px -2px var(--black-a6), 0 1px 3px -1px var(--black-a5);
  --base-card-classic-border-color: var(--gray-a6);
  --base-card-classic-hover-border-color: var(--gray-a6);
  --base-card-classic-active-border-color: var(--gray-a6);
}

:-webkit-any(.dark, .dark-theme) :where(.radix-themes:not(:-webkit-any(.light, .light-theme))) {
  --color-background: var(--gray-1);
  --color-overlay: var(--black-a8);
  --color-panel-solid: var(--gray-2);
  --color-panel-translucent: var(--gray-a2);
  --color-surface: rgba(0, 0, 0, .25);
  --shadow-1: inset 0 -1px 1px 0 var(--gray-a3), inset 0 0 0 1px var(--gray-a3), inset 0 3px 4px 0 var(--black-a5), inset 0 0 0 1px var(--gray-a4);
  --shadow-2: 0 0 0 1px var(--gray-a6), 0 0 0 .5px var(--black-a3), 0 1px 1px 0 var(--black-a6), 0 2px 1px -1px var(--black-a6), 0 1px 3px 0 var(--black-a5);
  --shadow-3: 0 0 0 1px var(--gray-a6), 0 2px 3px -2px var(--black-a3), 0 3px 8px -2px var(--black-a6), 0 4px 12px -4px var(--black-a7);
  --shadow-4: 0 0 0 1px var(--gray-a6), 0 8px 40px var(--black-a3), 0 12px 32px -16px var(--black-a5);
  --shadow-5: 0 0 0 1px var(--gray-a6), 0 12px 60px var(--black-a5), 0 12px 32px -16px var(--black-a7);
  --shadow-6: 0 0 0 1px var(--gray-a6), 0 12px 60px var(--black-a4), 0 16px 64px var(--black-a6), 0 16px 36px -20px var(--black-a11);
  --base-button-classic-after-inset: 1px;
  --base-button-classic-box-shadow-top: inset 0 0 0 1px var(--white-a2), inset 0 4px 2px -2px var(--white-a3), inset 0 1px 1px var(--white-a6), inset 0 -1px 1px var(--black-a6);
  --base-button-classic-box-shadow-bottom: 0 0 transparent;
  --base-button-classic-disabled-box-shadow: inset 0 0 0 1px var(--gray-a5), inset 0 4px 2px -2px var(--gray-a2), inset 0 1px 1px var(--gray-a5), inset 0 -1px 1px var(--black-a3), inset 0 0 0 1px var(--gray-a2);
  --base-button-classic-active-filter: brightness(1.08);
  --base-button-classic-high-contrast-hover-filter: contrast(.88) saturate(1.3) brightness(1.14);
  --base-button-classic-high-contrast-active-filter: brightness(.95) saturate(1.2);
  --base-button-solid-active-filter: brightness(1.08);
  --base-button-solid-high-contrast-hover-filter: contrast(.88) saturate(1.3) brightness(1.18);
  --base-button-solid-high-contrast-active-filter: brightness(.95) saturate(1.2);
  --kbd-box-shadow: inset 0 -.05em .5em var(--gray-a3), inset 0 .05em var(--gray-a11), inset 0 .25em .5em var(--gray-a2), inset 0 -.1em var(--black-a11), 0 0 0 .075em var(--gray-a7), 0 .08em .17em var(--black-a12);
  --progress-indicator-after-linear-gradient: var(--white-a3), var(--white-a6), var(--white-a3);
  --segmented-control-indicator-background-color: var(--gray-a3);
  --select-trigger-classic-box-shadow: inset 0 0 0 1px var(--white-a4), inset 0 1px 1px var(--white-a4), inset 0 -1px 1px var(--black-a9);
  --slider-range-high-contrast-background-image: none;
  --slider-disabled-blend-mode: screen;
  --switch-disabled-blend-mode: screen;
  --switch-high-contrast-checked-color-overlay: transparent;
  --switch-high-contrast-checked-active-before-filter: brightness(1.08);
  --switch-surface-checked-active-filter: brightness(1.08);
  --base-card-classic-box-shadow-inner: 0 0 0 1px var(--base-card-classic-border-color), 0 0 0 1px var(--color-transparent), 0 0 0 .5px var(--black-a3), 0 1px 1px 0 var(--black-a6), 0 2px 1px -1px var(--black-a6), 0 1px 3px 0 var(--black-a5);
  --base-card-classic-box-shadow-outer: 0 0 0 0 var(--base-card-classic-border-color), 0 0 0 0 var(--color-transparent), 0 0 0 0 var(--black-a3), 0 1px 1px -1px var(--black-a6), 0 2px 1px -2px var(--black-a6), 0 1px 3px -1px var(--black-a5);
  --base-card-classic-hover-box-shadow-inner: 0 0 0 1px var(--base-card-classic-hover-border-color), 0 0 1px 1px var(--gray-a4), 0 0 1px -1px var(--gray-a4), 0 0 3px -2px var(--gray-a3), 0 0 12px -2px var(--gray-a3), 0 0 16px -8px var(--gray-a7);
  --base-card-classic-hover-box-shadow-outer: 0 0 0 0 var(--base-card-classic-hover-border-color), 0 0 1px 0 var(--gray-a4), 0 0 1px -2px var(--gray-a4), 0 0 3px -3px var(--gray-a3), 0 0 12px -3px var(--gray-a3), 0 0 16px -9px var(--gray-a7);
  --base-card-classic-active-box-shadow-inner: 0 0 0 1px var(--base-card-classic-active-border-color), 0 0 0 1px var(--color-transparent), 0 0 0 .5px var(--black-a3), 0 1px 1px 0 var(--black-a6), 0 2px 1px -1px var(--black-a6), 0 1px 3px 0 var(--black-a5);
  --base-card-classic-active-box-shadow-outer: 0 0 0 0 var(--base-card-classic-active-border-color), 0 0 0 0 var(--color-transparent), 0 0 0 0 var(--black-a3), 0 1px 1px -1px var(--black-a6), 0 2px 1px -2px var(--black-a6), 0 1px 3px -1px var(--black-a5);
  --base-card-classic-border-color: var(--gray-a6);
  --base-card-classic-hover-border-color: var(--gray-a6);
  --base-card-classic-active-border-color: var(--gray-a6);
}

:-moz-any(.dark, .dark-theme) :where(.radix-themes:not(:-moz-any(.light, .light-theme))) {
  --color-background: var(--gray-1);
  --color-overlay: var(--black-a8);
  --color-panel-solid: var(--gray-2);
  --color-panel-translucent: var(--gray-a2);
  --color-surface: rgba(0, 0, 0, .25);
  --shadow-1: inset 0 -1px 1px 0 var(--gray-a3), inset 0 0 0 1px var(--gray-a3), inset 0 3px 4px 0 var(--black-a5), inset 0 0 0 1px var(--gray-a4);
  --shadow-2: 0 0 0 1px var(--gray-a6), 0 0 0 .5px var(--black-a3), 0 1px 1px 0 var(--black-a6), 0 2px 1px -1px var(--black-a6), 0 1px 3px 0 var(--black-a5);
  --shadow-3: 0 0 0 1px var(--gray-a6), 0 2px 3px -2px var(--black-a3), 0 3px 8px -2px var(--black-a6), 0 4px 12px -4px var(--black-a7);
  --shadow-4: 0 0 0 1px var(--gray-a6), 0 8px 40px var(--black-a3), 0 12px 32px -16px var(--black-a5);
  --shadow-5: 0 0 0 1px var(--gray-a6), 0 12px 60px var(--black-a5), 0 12px 32px -16px var(--black-a7);
  --shadow-6: 0 0 0 1px var(--gray-a6), 0 12px 60px var(--black-a4), 0 16px 64px var(--black-a6), 0 16px 36px -20px var(--black-a11);
  --base-button-classic-after-inset: 1px;
  --base-button-classic-box-shadow-top: inset 0 0 0 1px var(--white-a2), inset 0 4px 2px -2px var(--white-a3), inset 0 1px 1px var(--white-a6), inset 0 -1px 1px var(--black-a6);
  --base-button-classic-box-shadow-bottom: 0 0 transparent;
  --base-button-classic-disabled-box-shadow: inset 0 0 0 1px var(--gray-a5), inset 0 4px 2px -2px var(--gray-a2), inset 0 1px 1px var(--gray-a5), inset 0 -1px 1px var(--black-a3), inset 0 0 0 1px var(--gray-a2);
  --base-button-classic-active-filter: brightness(1.08);
  --base-button-classic-high-contrast-hover-filter: contrast(.88) saturate(1.3) brightness(1.14);
  --base-button-classic-high-contrast-active-filter: brightness(.95) saturate(1.2);
  --base-button-solid-active-filter: brightness(1.08);
  --base-button-solid-high-contrast-hover-filter: contrast(.88) saturate(1.3) brightness(1.18);
  --base-button-solid-high-contrast-active-filter: brightness(.95) saturate(1.2);
  --kbd-box-shadow: inset 0 -.05em .5em var(--gray-a3), inset 0 .05em var(--gray-a11), inset 0 .25em .5em var(--gray-a2), inset 0 -.1em var(--black-a11), 0 0 0 .075em var(--gray-a7), 0 .08em .17em var(--black-a12);
  --progress-indicator-after-linear-gradient: var(--white-a3), var(--white-a6), var(--white-a3);
  --segmented-control-indicator-background-color: var(--gray-a3);
  --select-trigger-classic-box-shadow: inset 0 0 0 1px var(--white-a4), inset 0 1px 1px var(--white-a4), inset 0 -1px 1px var(--black-a9);
  --slider-range-high-contrast-background-image: none;
  --slider-disabled-blend-mode: screen;
  --switch-disabled-blend-mode: screen;
  --switch-high-contrast-checked-color-overlay: transparent;
  --switch-high-contrast-checked-active-before-filter: brightness(1.08);
  --switch-surface-checked-active-filter: brightness(1.08);
  --base-card-classic-box-shadow-inner: 0 0 0 1px var(--base-card-classic-border-color), 0 0 0 1px var(--color-transparent), 0 0 0 .5px var(--black-a3), 0 1px 1px 0 var(--black-a6), 0 2px 1px -1px var(--black-a6), 0 1px 3px 0 var(--black-a5);
  --base-card-classic-box-shadow-outer: 0 0 0 0 var(--base-card-classic-border-color), 0 0 0 0 var(--color-transparent), 0 0 0 0 var(--black-a3), 0 1px 1px -1px var(--black-a6), 0 2px 1px -2px var(--black-a6), 0 1px 3px -1px var(--black-a5);
  --base-card-classic-hover-box-shadow-inner: 0 0 0 1px var(--base-card-classic-hover-border-color), 0 0 1px 1px var(--gray-a4), 0 0 1px -1px var(--gray-a4), 0 0 3px -2px var(--gray-a3), 0 0 12px -2px var(--gray-a3), 0 0 16px -8px var(--gray-a7);
  --base-card-classic-hover-box-shadow-outer: 0 0 0 0 var(--base-card-classic-hover-border-color), 0 0 1px 0 var(--gray-a4), 0 0 1px -2px var(--gray-a4), 0 0 3px -3px var(--gray-a3), 0 0 12px -3px var(--gray-a3), 0 0 16px -9px var(--gray-a7);
  --base-card-classic-active-box-shadow-inner: 0 0 0 1px var(--base-card-classic-active-border-color), 0 0 0 1px var(--color-transparent), 0 0 0 .5px var(--black-a3), 0 1px 1px 0 var(--black-a6), 0 2px 1px -1px var(--black-a6), 0 1px 3px 0 var(--black-a5);
  --base-card-classic-active-box-shadow-outer: 0 0 0 0 var(--base-card-classic-active-border-color), 0 0 0 0 var(--color-transparent), 0 0 0 0 var(--black-a3), 0 1px 1px -1px var(--black-a6), 0 2px 1px -2px var(--black-a6), 0 1px 3px -1px var(--black-a5);
  --base-card-classic-border-color: var(--gray-a6);
  --base-card-classic-hover-border-color: var(--gray-a6);
  --base-card-classic-active-border-color: var(--gray-a6);
}

:is(.dark, .dark-theme) :where(.radix-themes:not(:is(.light, .light-theme))) {
  --color-background: var(--gray-1);
  --color-overlay: var(--black-a8);
  --color-panel-solid: var(--gray-2);
  --color-panel-translucent: var(--gray-a2);
  --color-surface: rgba(0, 0, 0, .25);
  --shadow-1: inset 0 -1px 1px 0 var(--gray-a3), inset 0 0 0 1px var(--gray-a3), inset 0 3px 4px 0 var(--black-a5), inset 0 0 0 1px var(--gray-a4);
  --shadow-2: 0 0 0 1px var(--gray-a6), 0 0 0 .5px var(--black-a3), 0 1px 1px 0 var(--black-a6), 0 2px 1px -1px var(--black-a6), 0 1px 3px 0 var(--black-a5);
  --shadow-3: 0 0 0 1px var(--gray-a6), 0 2px 3px -2px var(--black-a3), 0 3px 8px -2px var(--black-a6), 0 4px 12px -4px var(--black-a7);
  --shadow-4: 0 0 0 1px var(--gray-a6), 0 8px 40px var(--black-a3), 0 12px 32px -16px var(--black-a5);
  --shadow-5: 0 0 0 1px var(--gray-a6), 0 12px 60px var(--black-a5), 0 12px 32px -16px var(--black-a7);
  --shadow-6: 0 0 0 1px var(--gray-a6), 0 12px 60px var(--black-a4), 0 16px 64px var(--black-a6), 0 16px 36px -20px var(--black-a11);
  --base-button-classic-after-inset: 1px;
  --base-button-classic-box-shadow-top: inset 0 0 0 1px var(--white-a2), inset 0 4px 2px -2px var(--white-a3), inset 0 1px 1px var(--white-a6), inset 0 -1px 1px var(--black-a6);
  --base-button-classic-box-shadow-bottom: 0 0 transparent;
  --base-button-classic-disabled-box-shadow: inset 0 0 0 1px var(--gray-a5), inset 0 4px 2px -2px var(--gray-a2), inset 0 1px 1px var(--gray-a5), inset 0 -1px 1px var(--black-a3), inset 0 0 0 1px var(--gray-a2);
  --base-button-classic-active-filter: brightness(1.08);
  --base-button-classic-high-contrast-hover-filter: contrast(.88) saturate(1.3) brightness(1.14);
  --base-button-classic-high-contrast-active-filter: brightness(.95) saturate(1.2);
  --base-button-solid-active-filter: brightness(1.08);
  --base-button-solid-high-contrast-hover-filter: contrast(.88) saturate(1.3) brightness(1.18);
  --base-button-solid-high-contrast-active-filter: brightness(.95) saturate(1.2);
  --kbd-box-shadow: inset 0 -.05em .5em var(--gray-a3), inset 0 .05em var(--gray-a11), inset 0 .25em .5em var(--gray-a2), inset 0 -.1em var(--black-a11), 0 0 0 .075em var(--gray-a7), 0 .08em .17em var(--black-a12);
  --progress-indicator-after-linear-gradient: var(--white-a3), var(--white-a6), var(--white-a3);
  --segmented-control-indicator-background-color: var(--gray-a3);
  --select-trigger-classic-box-shadow: inset 0 0 0 1px var(--white-a4), inset 0 1px 1px var(--white-a4), inset 0 -1px 1px var(--black-a9);
  --slider-range-high-contrast-background-image: none;
  --slider-disabled-blend-mode: screen;
  --switch-disabled-blend-mode: screen;
  --switch-high-contrast-checked-color-overlay: transparent;
  --switch-high-contrast-checked-active-before-filter: brightness(1.08);
  --switch-surface-checked-active-filter: brightness(1.08);
  --base-card-classic-box-shadow-inner: 0 0 0 1px var(--base-card-classic-border-color), 0 0 0 1px var(--color-transparent), 0 0 0 .5px var(--black-a3), 0 1px 1px 0 var(--black-a6), 0 2px 1px -1px var(--black-a6), 0 1px 3px 0 var(--black-a5);
  --base-card-classic-box-shadow-outer: 0 0 0 0 var(--base-card-classic-border-color), 0 0 0 0 var(--color-transparent), 0 0 0 0 var(--black-a3), 0 1px 1px -1px var(--black-a6), 0 2px 1px -2px var(--black-a6), 0 1px 3px -1px var(--black-a5);
  --base-card-classic-hover-box-shadow-inner: 0 0 0 1px var(--base-card-classic-hover-border-color), 0 0 1px 1px var(--gray-a4), 0 0 1px -1px var(--gray-a4), 0 0 3px -2px var(--gray-a3), 0 0 12px -2px var(--gray-a3), 0 0 16px -8px var(--gray-a7);
  --base-card-classic-hover-box-shadow-outer: 0 0 0 0 var(--base-card-classic-hover-border-color), 0 0 1px 0 var(--gray-a4), 0 0 1px -2px var(--gray-a4), 0 0 3px -3px var(--gray-a3), 0 0 12px -3px var(--gray-a3), 0 0 16px -9px var(--gray-a7);
  --base-card-classic-active-box-shadow-inner: 0 0 0 1px var(--base-card-classic-active-border-color), 0 0 0 1px var(--color-transparent), 0 0 0 .5px var(--black-a3), 0 1px 1px 0 var(--black-a6), 0 2px 1px -1px var(--black-a6), 0 1px 3px 0 var(--black-a5);
  --base-card-classic-active-box-shadow-outer: 0 0 0 0 var(--base-card-classic-active-border-color), 0 0 0 0 var(--color-transparent), 0 0 0 0 var(--black-a3), 0 1px 1px -1px var(--black-a6), 0 2px 1px -2px var(--black-a6), 0 1px 3px -1px var(--black-a5);
  --base-card-classic-border-color: var(--gray-a6);
  --base-card-classic-hover-border-color: var(--gray-a6);
  --base-card-classic-active-border-color: var(--gray-a6);
}

@supports (color: color(display-p3 1 1 1)) {
  @media (color-gamut: p3) {
    .radix-themes {
      --color-transparent: rgba(0, 0, 0, 0);
    }

    @supports (color: color(display-p3 0 0 0)) {
      .radix-themes {
        --color-transparent: color(display-p3 0 0 0 / 0);
      }
    }
  }
}

.radix-themes:where(.light, .light-theme) {
  --lightningcss-light: initial;
  --lightningcss-dark: ;
  color-scheme: light;
}

:root:where(:has(.radix-themes[data-is-root-theme="true"]:where(.light, .light-theme))) {
  --lightningcss-light: initial;
  --lightningcss-dark: ;
  color-scheme: light;
}

.radix-themes:where(.dark, .dark-theme) {
  --lightningcss-light: ;
  --lightningcss-dark: initial;
  color-scheme: dark;
}

:root:where(:has(.radix-themes[data-is-root-theme="true"]:where(.dark, .dark-theme))) {
  --lightningcss-light: ;
  --lightningcss-dark: initial;
  color-scheme: dark;
}

.radix-themes {
  --focus-1: var(--accent-1);
  --focus-2: var(--accent-2);
  --focus-3: var(--accent-3);
  --focus-4: var(--accent-4);
  --focus-5: var(--accent-5);
  --focus-6: var(--accent-6);
  --focus-7: var(--accent-7);
  --focus-8: var(--accent-8);
  --focus-9: var(--accent-9);
  --focus-10: var(--accent-10);
  --focus-11: var(--accent-11);
  --focus-12: var(--accent-12);
  --focus-a1: var(--accent-a1);
  --focus-a2: var(--accent-a2);
  --focus-a3: var(--accent-a3);
  --focus-a4: var(--accent-a4);
  --focus-a5: var(--accent-a5);
  --focus-a6: var(--accent-a6);
  --focus-a7: var(--accent-a7);
  --focus-a8: var(--accent-a8);
  --focus-a9: var(--accent-a9);
  --focus-a10: var(--accent-a10);
  --focus-a11: var(--accent-a11);
  --focus-a12: var(--accent-a12);
}

[data-accent-color]:where(:not(:-webkit-any([data-accent-color=""], [data-accent-color="gray"]))) {
  --focus-1: var(--accent-1);
  --focus-2: var(--accent-2);
  --focus-3: var(--accent-3);
  --focus-4: var(--accent-4);
  --focus-5: var(--accent-5);
  --focus-6: var(--accent-6);
  --focus-7: var(--accent-7);
  --focus-8: var(--accent-8);
  --focus-9: var(--accent-9);
  --focus-10: var(--accent-10);
  --focus-11: var(--accent-11);
  --focus-12: var(--accent-12);
  --focus-a1: var(--accent-a1);
  --focus-a2: var(--accent-a2);
  --focus-a3: var(--accent-a3);
  --focus-a4: var(--accent-a4);
  --focus-a5: var(--accent-a5);
  --focus-a6: var(--accent-a6);
  --focus-a7: var(--accent-a7);
  --focus-a8: var(--accent-a8);
  --focus-a9: var(--accent-a9);
  --focus-a10: var(--accent-a10);
  --focus-a11: var(--accent-a11);
  --focus-a12: var(--accent-a12);
}

[data-accent-color]:where(:not(:-moz-any([data-accent-color=""], [data-accent-color="gray"]))) {
  --focus-1: var(--accent-1);
  --focus-2: var(--accent-2);
  --focus-3: var(--accent-3);
  --focus-4: var(--accent-4);
  --focus-5: var(--accent-5);
  --focus-6: var(--accent-6);
  --focus-7: var(--accent-7);
  --focus-8: var(--accent-8);
  --focus-9: var(--accent-9);
  --focus-10: var(--accent-10);
  --focus-11: var(--accent-11);
  --focus-12: var(--accent-12);
  --focus-a1: var(--accent-a1);
  --focus-a2: var(--accent-a2);
  --focus-a3: var(--accent-a3);
  --focus-a4: var(--accent-a4);
  --focus-a5: var(--accent-a5);
  --focus-a6: var(--accent-a6);
  --focus-a7: var(--accent-a7);
  --focus-a8: var(--accent-a8);
  --focus-a9: var(--accent-a9);
  --focus-a10: var(--accent-a10);
  --focus-a11: var(--accent-a11);
  --focus-a12: var(--accent-a12);
}

[data-accent-color]:where(:not(:is([data-accent-color=""], [data-accent-color="gray"]))) {
  --focus-1: var(--accent-1);
  --focus-2: var(--accent-2);
  --focus-3: var(--accent-3);
  --focus-4: var(--accent-4);
  --focus-5: var(--accent-5);
  --focus-6: var(--accent-6);
  --focus-7: var(--accent-7);
  --focus-8: var(--accent-8);
  --focus-9: var(--accent-9);
  --focus-10: var(--accent-10);
  --focus-11: var(--accent-11);
  --focus-12: var(--accent-12);
  --focus-a1: var(--accent-a1);
  --focus-a2: var(--accent-a2);
  --focus-a3: var(--accent-a3);
  --focus-a4: var(--accent-a4);
  --focus-a5: var(--accent-a5);
  --focus-a6: var(--accent-a6);
  --focus-a7: var(--accent-a7);
  --focus-a8: var(--accent-a8);
  --focus-a9: var(--accent-a9);
  --focus-a10: var(--accent-a10);
  --focus-a11: var(--accent-a11);
  --focus-a12: var(--accent-a12);
}

.radix-themes ::selection {
  background-color: var(--focus-a5);
}

.radix-themes:where([data-has-background="true"]) {
  background-color: var(--color-background);
}

.radix-themes:where([data-panel-background="solid"]) {
  --color-panel: var(--color-panel-solid);
  --backdrop-filter-panel: none;
}

.radix-themes:where([data-panel-background="translucent"]) {
  --color-panel: var(--color-panel-translucent);
  --backdrop-filter-panel: blur(64px);
}

[data-accent-color="amber"] {
  --accent-1: var(--amber-1);
  --accent-2: var(--amber-2);
  --accent-3: var(--amber-3);
  --accent-4: var(--amber-4);
  --accent-5: var(--amber-5);
  --accent-6: var(--amber-6);
  --accent-7: var(--amber-7);
  --accent-8: var(--amber-8);
  --accent-9: var(--amber-9);
  --accent-10: var(--amber-10);
  --accent-11: var(--amber-11);
  --accent-12: var(--amber-12);
  --accent-a1: var(--amber-a1);
  --accent-a2: var(--amber-a2);
  --accent-a3: var(--amber-a3);
  --accent-a4: var(--amber-a4);
  --accent-a5: var(--amber-a5);
  --accent-a6: var(--amber-a6);
  --accent-a7: var(--amber-a7);
  --accent-a8: var(--amber-a8);
  --accent-a9: var(--amber-a9);
  --accent-a10: var(--amber-a10);
  --accent-a11: var(--amber-a11);
  --accent-a12: var(--amber-a12);
  --accent-contrast: var(--amber-contrast);
  --accent-surface: var(--amber-surface);
  --accent-indicator: var(--amber-indicator);
  --accent-track: var(--amber-track);
}

[data-accent-color="blue"] {
  --accent-1: var(--blue-1);
  --accent-2: var(--blue-2);
  --accent-3: var(--blue-3);
  --accent-4: var(--blue-4);
  --accent-5: var(--blue-5);
  --accent-6: var(--blue-6);
  --accent-7: var(--blue-7);
  --accent-8: var(--blue-8);
  --accent-9: var(--blue-9);
  --accent-10: var(--blue-10);
  --accent-11: var(--blue-11);
  --accent-12: var(--blue-12);
  --accent-a1: var(--blue-a1);
  --accent-a2: var(--blue-a2);
  --accent-a3: var(--blue-a3);
  --accent-a4: var(--blue-a4);
  --accent-a5: var(--blue-a5);
  --accent-a6: var(--blue-a6);
  --accent-a7: var(--blue-a7);
  --accent-a8: var(--blue-a8);
  --accent-a9: var(--blue-a9);
  --accent-a10: var(--blue-a10);
  --accent-a11: var(--blue-a11);
  --accent-a12: var(--blue-a12);
  --accent-contrast: var(--blue-contrast);
  --accent-surface: var(--blue-surface);
  --accent-indicator: var(--blue-indicator);
  --accent-track: var(--blue-track);
}

[data-accent-color="bronze"] {
  --accent-1: var(--bronze-1);
  --accent-2: var(--bronze-2);
  --accent-3: var(--bronze-3);
  --accent-4: var(--bronze-4);
  --accent-5: var(--bronze-5);
  --accent-6: var(--bronze-6);
  --accent-7: var(--bronze-7);
  --accent-8: var(--bronze-8);
  --accent-9: var(--bronze-9);
  --accent-10: var(--bronze-10);
  --accent-11: var(--bronze-11);
  --accent-12: var(--bronze-12);
  --accent-a1: var(--bronze-a1);
  --accent-a2: var(--bronze-a2);
  --accent-a3: var(--bronze-a3);
  --accent-a4: var(--bronze-a4);
  --accent-a5: var(--bronze-a5);
  --accent-a6: var(--bronze-a6);
  --accent-a7: var(--bronze-a7);
  --accent-a8: var(--bronze-a8);
  --accent-a9: var(--bronze-a9);
  --accent-a10: var(--bronze-a10);
  --accent-a11: var(--bronze-a11);
  --accent-a12: var(--bronze-a12);
  --accent-contrast: var(--bronze-contrast);
  --accent-surface: var(--bronze-surface);
  --accent-indicator: var(--bronze-indicator);
  --accent-track: var(--bronze-track);
}

[data-accent-color="brown"] {
  --accent-1: var(--brown-1);
  --accent-2: var(--brown-2);
  --accent-3: var(--brown-3);
  --accent-4: var(--brown-4);
  --accent-5: var(--brown-5);
  --accent-6: var(--brown-6);
  --accent-7: var(--brown-7);
  --accent-8: var(--brown-8);
  --accent-9: var(--brown-9);
  --accent-10: var(--brown-10);
  --accent-11: var(--brown-11);
  --accent-12: var(--brown-12);
  --accent-a1: var(--brown-a1);
  --accent-a2: var(--brown-a2);
  --accent-a3: var(--brown-a3);
  --accent-a4: var(--brown-a4);
  --accent-a5: var(--brown-a5);
  --accent-a6: var(--brown-a6);
  --accent-a7: var(--brown-a7);
  --accent-a8: var(--brown-a8);
  --accent-a9: var(--brown-a9);
  --accent-a10: var(--brown-a10);
  --accent-a11: var(--brown-a11);
  --accent-a12: var(--brown-a12);
  --accent-contrast: var(--brown-contrast);
  --accent-surface: var(--brown-surface);
  --accent-indicator: var(--brown-indicator);
  --accent-track: var(--brown-track);
}

[data-accent-color="crimson"] {
  --accent-1: var(--crimson-1);
  --accent-2: var(--crimson-2);
  --accent-3: var(--crimson-3);
  --accent-4: var(--crimson-4);
  --accent-5: var(--crimson-5);
  --accent-6: var(--crimson-6);
  --accent-7: var(--crimson-7);
  --accent-8: var(--crimson-8);
  --accent-9: var(--crimson-9);
  --accent-10: var(--crimson-10);
  --accent-11: var(--crimson-11);
  --accent-12: var(--crimson-12);
  --accent-a1: var(--crimson-a1);
  --accent-a2: var(--crimson-a2);
  --accent-a3: var(--crimson-a3);
  --accent-a4: var(--crimson-a4);
  --accent-a5: var(--crimson-a5);
  --accent-a6: var(--crimson-a6);
  --accent-a7: var(--crimson-a7);
  --accent-a8: var(--crimson-a8);
  --accent-a9: var(--crimson-a9);
  --accent-a10: var(--crimson-a10);
  --accent-a11: var(--crimson-a11);
  --accent-a12: var(--crimson-a12);
  --accent-contrast: var(--crimson-contrast);
  --accent-surface: var(--crimson-surface);
  --accent-indicator: var(--crimson-indicator);
  --accent-track: var(--crimson-track);
}

[data-accent-color="cyan"] {
  --accent-1: var(--cyan-1);
  --accent-2: var(--cyan-2);
  --accent-3: var(--cyan-3);
  --accent-4: var(--cyan-4);
  --accent-5: var(--cyan-5);
  --accent-6: var(--cyan-6);
  --accent-7: var(--cyan-7);
  --accent-8: var(--cyan-8);
  --accent-9: var(--cyan-9);
  --accent-10: var(--cyan-10);
  --accent-11: var(--cyan-11);
  --accent-12: var(--cyan-12);
  --accent-a1: var(--cyan-a1);
  --accent-a2: var(--cyan-a2);
  --accent-a3: var(--cyan-a3);
  --accent-a4: var(--cyan-a4);
  --accent-a5: var(--cyan-a5);
  --accent-a6: var(--cyan-a6);
  --accent-a7: var(--cyan-a7);
  --accent-a8: var(--cyan-a8);
  --accent-a9: var(--cyan-a9);
  --accent-a10: var(--cyan-a10);
  --accent-a11: var(--cyan-a11);
  --accent-a12: var(--cyan-a12);
  --accent-contrast: var(--cyan-contrast);
  --accent-surface: var(--cyan-surface);
  --accent-indicator: var(--cyan-indicator);
  --accent-track: var(--cyan-track);
}

[data-accent-color="gold"] {
  --accent-1: var(--gold-1);
  --accent-2: var(--gold-2);
  --accent-3: var(--gold-3);
  --accent-4: var(--gold-4);
  --accent-5: var(--gold-5);
  --accent-6: var(--gold-6);
  --accent-7: var(--gold-7);
  --accent-8: var(--gold-8);
  --accent-9: var(--gold-9);
  --accent-10: var(--gold-10);
  --accent-11: var(--gold-11);
  --accent-12: var(--gold-12);
  --accent-a1: var(--gold-a1);
  --accent-a2: var(--gold-a2);
  --accent-a3: var(--gold-a3);
  --accent-a4: var(--gold-a4);
  --accent-a5: var(--gold-a5);
  --accent-a6: var(--gold-a6);
  --accent-a7: var(--gold-a7);
  --accent-a8: var(--gold-a8);
  --accent-a9: var(--gold-a9);
  --accent-a10: var(--gold-a10);
  --accent-a11: var(--gold-a11);
  --accent-a12: var(--gold-a12);
  --accent-contrast: var(--gold-contrast);
  --accent-surface: var(--gold-surface);
  --accent-indicator: var(--gold-indicator);
  --accent-track: var(--gold-track);
}

[data-accent-color="grass"] {
  --accent-1: var(--grass-1);
  --accent-2: var(--grass-2);
  --accent-3: var(--grass-3);
  --accent-4: var(--grass-4);
  --accent-5: var(--grass-5);
  --accent-6: var(--grass-6);
  --accent-7: var(--grass-7);
  --accent-8: var(--grass-8);
  --accent-9: var(--grass-9);
  --accent-10: var(--grass-10);
  --accent-11: var(--grass-11);
  --accent-12: var(--grass-12);
  --accent-a1: var(--grass-a1);
  --accent-a2: var(--grass-a2);
  --accent-a3: var(--grass-a3);
  --accent-a4: var(--grass-a4);
  --accent-a5: var(--grass-a5);
  --accent-a6: var(--grass-a6);
  --accent-a7: var(--grass-a7);
  --accent-a8: var(--grass-a8);
  --accent-a9: var(--grass-a9);
  --accent-a10: var(--grass-a10);
  --accent-a11: var(--grass-a11);
  --accent-a12: var(--grass-a12);
  --accent-contrast: var(--grass-contrast);
  --accent-surface: var(--grass-surface);
  --accent-indicator: var(--grass-indicator);
  --accent-track: var(--grass-track);
}

[data-accent-color="gray"] {
  --accent-1: var(--gray-1);
  --accent-2: var(--gray-2);
  --accent-3: var(--gray-3);
  --accent-4: var(--gray-4);
  --accent-5: var(--gray-5);
  --accent-6: var(--gray-6);
  --accent-7: var(--gray-7);
  --accent-8: var(--gray-8);
  --accent-9: var(--gray-9);
  --accent-10: var(--gray-10);
  --accent-11: var(--gray-11);
  --accent-12: var(--gray-12);
  --accent-a1: var(--gray-a1);
  --accent-a2: var(--gray-a2);
  --accent-a3: var(--gray-a3);
  --accent-a4: var(--gray-a4);
  --accent-a5: var(--gray-a5);
  --accent-a6: var(--gray-a6);
  --accent-a7: var(--gray-a7);
  --accent-a8: var(--gray-a8);
  --accent-a9: var(--gray-a9);
  --accent-a10: var(--gray-a10);
  --accent-a11: var(--gray-a11);
  --accent-a12: var(--gray-a12);
  --accent-contrast: var(--gray-contrast);
  --accent-surface: var(--gray-surface);
  --accent-indicator: var(--gray-indicator);
  --accent-track: var(--gray-track);
}

[data-accent-color="green"] {
  --accent-1: var(--green-1);
  --accent-2: var(--green-2);
  --accent-3: var(--green-3);
  --accent-4: var(--green-4);
  --accent-5: var(--green-5);
  --accent-6: var(--green-6);
  --accent-7: var(--green-7);
  --accent-8: var(--green-8);
  --accent-9: var(--green-9);
  --accent-10: var(--green-10);
  --accent-11: var(--green-11);
  --accent-12: var(--green-12);
  --accent-a1: var(--green-a1);
  --accent-a2: var(--green-a2);
  --accent-a3: var(--green-a3);
  --accent-a4: var(--green-a4);
  --accent-a5: var(--green-a5);
  --accent-a6: var(--green-a6);
  --accent-a7: var(--green-a7);
  --accent-a8: var(--green-a8);
  --accent-a9: var(--green-a9);
  --accent-a10: var(--green-a10);
  --accent-a11: var(--green-a11);
  --accent-a12: var(--green-a12);
  --accent-contrast: var(--green-contrast);
  --accent-surface: var(--green-surface);
  --accent-indicator: var(--green-indicator);
  --accent-track: var(--green-track);
}

[data-accent-color="indigo"] {
  --accent-1: var(--indigo-1);
  --accent-2: var(--indigo-2);
  --accent-3: var(--indigo-3);
  --accent-4: var(--indigo-4);
  --accent-5: var(--indigo-5);
  --accent-6: var(--indigo-6);
  --accent-7: var(--indigo-7);
  --accent-8: var(--indigo-8);
  --accent-9: var(--indigo-9);
  --accent-10: var(--indigo-10);
  --accent-11: var(--indigo-11);
  --accent-12: var(--indigo-12);
  --accent-a1: var(--indigo-a1);
  --accent-a2: var(--indigo-a2);
  --accent-a3: var(--indigo-a3);
  --accent-a4: var(--indigo-a4);
  --accent-a5: var(--indigo-a5);
  --accent-a6: var(--indigo-a6);
  --accent-a7: var(--indigo-a7);
  --accent-a8: var(--indigo-a8);
  --accent-a9: var(--indigo-a9);
  --accent-a10: var(--indigo-a10);
  --accent-a11: var(--indigo-a11);
  --accent-a12: var(--indigo-a12);
  --accent-contrast: var(--indigo-contrast);
  --accent-surface: var(--indigo-surface);
  --accent-indicator: var(--indigo-indicator);
  --accent-track: var(--indigo-track);
}

[data-accent-color="iris"] {
  --accent-1: var(--iris-1);
  --accent-2: var(--iris-2);
  --accent-3: var(--iris-3);
  --accent-4: var(--iris-4);
  --accent-5: var(--iris-5);
  --accent-6: var(--iris-6);
  --accent-7: var(--iris-7);
  --accent-8: var(--iris-8);
  --accent-9: var(--iris-9);
  --accent-10: var(--iris-10);
  --accent-11: var(--iris-11);
  --accent-12: var(--iris-12);
  --accent-a1: var(--iris-a1);
  --accent-a2: var(--iris-a2);
  --accent-a3: var(--iris-a3);
  --accent-a4: var(--iris-a4);
  --accent-a5: var(--iris-a5);
  --accent-a6: var(--iris-a6);
  --accent-a7: var(--iris-a7);
  --accent-a8: var(--iris-a8);
  --accent-a9: var(--iris-a9);
  --accent-a10: var(--iris-a10);
  --accent-a11: var(--iris-a11);
  --accent-a12: var(--iris-a12);
  --accent-contrast: var(--iris-contrast);
  --accent-surface: var(--iris-surface);
  --accent-indicator: var(--iris-indicator);
  --accent-track: var(--iris-track);
}

[data-accent-color="jade"] {
  --accent-1: var(--jade-1);
  --accent-2: var(--jade-2);
  --accent-3: var(--jade-3);
  --accent-4: var(--jade-4);
  --accent-5: var(--jade-5);
  --accent-6: var(--jade-6);
  --accent-7: var(--jade-7);
  --accent-8: var(--jade-8);
  --accent-9: var(--jade-9);
  --accent-10: var(--jade-10);
  --accent-11: var(--jade-11);
  --accent-12: var(--jade-12);
  --accent-a1: var(--jade-a1);
  --accent-a2: var(--jade-a2);
  --accent-a3: var(--jade-a3);
  --accent-a4: var(--jade-a4);
  --accent-a5: var(--jade-a5);
  --accent-a6: var(--jade-a6);
  --accent-a7: var(--jade-a7);
  --accent-a8: var(--jade-a8);
  --accent-a9: var(--jade-a9);
  --accent-a10: var(--jade-a10);
  --accent-a11: var(--jade-a11);
  --accent-a12: var(--jade-a12);
  --accent-contrast: var(--jade-contrast);
  --accent-surface: var(--jade-surface);
  --accent-indicator: var(--jade-indicator);
  --accent-track: var(--jade-track);
}

[data-accent-color="lime"] {
  --accent-1: var(--lime-1);
  --accent-2: var(--lime-2);
  --accent-3: var(--lime-3);
  --accent-4: var(--lime-4);
  --accent-5: var(--lime-5);
  --accent-6: var(--lime-6);
  --accent-7: var(--lime-7);
  --accent-8: var(--lime-8);
  --accent-9: var(--lime-9);
  --accent-10: var(--lime-10);
  --accent-11: var(--lime-11);
  --accent-12: var(--lime-12);
  --accent-a1: var(--lime-a1);
  --accent-a2: var(--lime-a2);
  --accent-a3: var(--lime-a3);
  --accent-a4: var(--lime-a4);
  --accent-a5: var(--lime-a5);
  --accent-a6: var(--lime-a6);
  --accent-a7: var(--lime-a7);
  --accent-a8: var(--lime-a8);
  --accent-a9: var(--lime-a9);
  --accent-a10: var(--lime-a10);
  --accent-a11: var(--lime-a11);
  --accent-a12: var(--lime-a12);
  --accent-contrast: var(--lime-contrast);
  --accent-surface: var(--lime-surface);
  --accent-indicator: var(--lime-indicator);
  --accent-track: var(--lime-track);
}

[data-accent-color="mint"] {
  --accent-1: var(--mint-1);
  --accent-2: var(--mint-2);
  --accent-3: var(--mint-3);
  --accent-4: var(--mint-4);
  --accent-5: var(--mint-5);
  --accent-6: var(--mint-6);
  --accent-7: var(--mint-7);
  --accent-8: var(--mint-8);
  --accent-9: var(--mint-9);
  --accent-10: var(--mint-10);
  --accent-11: var(--mint-11);
  --accent-12: var(--mint-12);
  --accent-a1: var(--mint-a1);
  --accent-a2: var(--mint-a2);
  --accent-a3: var(--mint-a3);
  --accent-a4: var(--mint-a4);
  --accent-a5: var(--mint-a5);
  --accent-a6: var(--mint-a6);
  --accent-a7: var(--mint-a7);
  --accent-a8: var(--mint-a8);
  --accent-a9: var(--mint-a9);
  --accent-a10: var(--mint-a10);
  --accent-a11: var(--mint-a11);
  --accent-a12: var(--mint-a12);
  --accent-contrast: var(--mint-contrast);
  --accent-surface: var(--mint-surface);
  --accent-indicator: var(--mint-indicator);
  --accent-track: var(--mint-track);
}

[data-accent-color="orange"] {
  --accent-1: var(--orange-1);
  --accent-2: var(--orange-2);
  --accent-3: var(--orange-3);
  --accent-4: var(--orange-4);
  --accent-5: var(--orange-5);
  --accent-6: var(--orange-6);
  --accent-7: var(--orange-7);
  --accent-8: var(--orange-8);
  --accent-9: var(--orange-9);
  --accent-10: var(--orange-10);
  --accent-11: var(--orange-11);
  --accent-12: var(--orange-12);
  --accent-a1: var(--orange-a1);
  --accent-a2: var(--orange-a2);
  --accent-a3: var(--orange-a3);
  --accent-a4: var(--orange-a4);
  --accent-a5: var(--orange-a5);
  --accent-a6: var(--orange-a6);
  --accent-a7: var(--orange-a7);
  --accent-a8: var(--orange-a8);
  --accent-a9: var(--orange-a9);
  --accent-a10: var(--orange-a10);
  --accent-a11: var(--orange-a11);
  --accent-a12: var(--orange-a12);
  --accent-contrast: var(--orange-contrast);
  --accent-surface: var(--orange-surface);
  --accent-indicator: var(--orange-indicator);
  --accent-track: var(--orange-track);
}

[data-accent-color="pink"] {
  --accent-1: var(--pink-1);
  --accent-2: var(--pink-2);
  --accent-3: var(--pink-3);
  --accent-4: var(--pink-4);
  --accent-5: var(--pink-5);
  --accent-6: var(--pink-6);
  --accent-7: var(--pink-7);
  --accent-8: var(--pink-8);
  --accent-9: var(--pink-9);
  --accent-10: var(--pink-10);
  --accent-11: var(--pink-11);
  --accent-12: var(--pink-12);
  --accent-a1: var(--pink-a1);
  --accent-a2: var(--pink-a2);
  --accent-a3: var(--pink-a3);
  --accent-a4: var(--pink-a4);
  --accent-a5: var(--pink-a5);
  --accent-a6: var(--pink-a6);
  --accent-a7: var(--pink-a7);
  --accent-a8: var(--pink-a8);
  --accent-a9: var(--pink-a9);
  --accent-a10: var(--pink-a10);
  --accent-a11: var(--pink-a11);
  --accent-a12: var(--pink-a12);
  --accent-contrast: var(--pink-contrast);
  --accent-surface: var(--pink-surface);
  --accent-indicator: var(--pink-indicator);
  --accent-track: var(--pink-track);
}

[data-accent-color="plum"] {
  --accent-1: var(--plum-1);
  --accent-2: var(--plum-2);
  --accent-3: var(--plum-3);
  --accent-4: var(--plum-4);
  --accent-5: var(--plum-5);
  --accent-6: var(--plum-6);
  --accent-7: var(--plum-7);
  --accent-8: var(--plum-8);
  --accent-9: var(--plum-9);
  --accent-10: var(--plum-10);
  --accent-11: var(--plum-11);
  --accent-12: var(--plum-12);
  --accent-a1: var(--plum-a1);
  --accent-a2: var(--plum-a2);
  --accent-a3: var(--plum-a3);
  --accent-a4: var(--plum-a4);
  --accent-a5: var(--plum-a5);
  --accent-a6: var(--plum-a6);
  --accent-a7: var(--plum-a7);
  --accent-a8: var(--plum-a8);
  --accent-a9: var(--plum-a9);
  --accent-a10: var(--plum-a10);
  --accent-a11: var(--plum-a11);
  --accent-a12: var(--plum-a12);
  --accent-contrast: var(--plum-contrast);
  --accent-surface: var(--plum-surface);
  --accent-indicator: var(--plum-indicator);
  --accent-track: var(--plum-track);
}

[data-accent-color="purple"] {
  --accent-1: var(--purple-1);
  --accent-2: var(--purple-2);
  --accent-3: var(--purple-3);
  --accent-4: var(--purple-4);
  --accent-5: var(--purple-5);
  --accent-6: var(--purple-6);
  --accent-7: var(--purple-7);
  --accent-8: var(--purple-8);
  --accent-9: var(--purple-9);
  --accent-10: var(--purple-10);
  --accent-11: var(--purple-11);
  --accent-12: var(--purple-12);
  --accent-a1: var(--purple-a1);
  --accent-a2: var(--purple-a2);
  --accent-a3: var(--purple-a3);
  --accent-a4: var(--purple-a4);
  --accent-a5: var(--purple-a5);
  --accent-a6: var(--purple-a6);
  --accent-a7: var(--purple-a7);
  --accent-a8: var(--purple-a8);
  --accent-a9: var(--purple-a9);
  --accent-a10: var(--purple-a10);
  --accent-a11: var(--purple-a11);
  --accent-a12: var(--purple-a12);
  --accent-contrast: var(--purple-contrast);
  --accent-surface: var(--purple-surface);
  --accent-indicator: var(--purple-indicator);
  --accent-track: var(--purple-track);
}

[data-accent-color="red"] {
  --accent-1: var(--red-1);
  --accent-2: var(--red-2);
  --accent-3: var(--red-3);
  --accent-4: var(--red-4);
  --accent-5: var(--red-5);
  --accent-6: var(--red-6);
  --accent-7: var(--red-7);
  --accent-8: var(--red-8);
  --accent-9: var(--red-9);
  --accent-10: var(--red-10);
  --accent-11: var(--red-11);
  --accent-12: var(--red-12);
  --accent-a1: var(--red-a1);
  --accent-a2: var(--red-a2);
  --accent-a3: var(--red-a3);
  --accent-a4: var(--red-a4);
  --accent-a5: var(--red-a5);
  --accent-a6: var(--red-a6);
  --accent-a7: var(--red-a7);
  --accent-a8: var(--red-a8);
  --accent-a9: var(--red-a9);
  --accent-a10: var(--red-a10);
  --accent-a11: var(--red-a11);
  --accent-a12: var(--red-a12);
  --accent-contrast: var(--red-contrast);
  --accent-surface: var(--red-surface);
  --accent-indicator: var(--red-indicator);
  --accent-track: var(--red-track);
}

[data-accent-color="ruby"] {
  --accent-1: var(--ruby-1);
  --accent-2: var(--ruby-2);
  --accent-3: var(--ruby-3);
  --accent-4: var(--ruby-4);
  --accent-5: var(--ruby-5);
  --accent-6: var(--ruby-6);
  --accent-7: var(--ruby-7);
  --accent-8: var(--ruby-8);
  --accent-9: var(--ruby-9);
  --accent-10: var(--ruby-10);
  --accent-11: var(--ruby-11);
  --accent-12: var(--ruby-12);
  --accent-a1: var(--ruby-a1);
  --accent-a2: var(--ruby-a2);
  --accent-a3: var(--ruby-a3);
  --accent-a4: var(--ruby-a4);
  --accent-a5: var(--ruby-a5);
  --accent-a6: var(--ruby-a6);
  --accent-a7: var(--ruby-a7);
  --accent-a8: var(--ruby-a8);
  --accent-a9: var(--ruby-a9);
  --accent-a10: var(--ruby-a10);
  --accent-a11: var(--ruby-a11);
  --accent-a12: var(--ruby-a12);
  --accent-contrast: var(--ruby-contrast);
  --accent-surface: var(--ruby-surface);
  --accent-indicator: var(--ruby-indicator);
  --accent-track: var(--ruby-track);
}

[data-accent-color="sky"] {
  --accent-1: var(--sky-1);
  --accent-2: var(--sky-2);
  --accent-3: var(--sky-3);
  --accent-4: var(--sky-4);
  --accent-5: var(--sky-5);
  --accent-6: var(--sky-6);
  --accent-7: var(--sky-7);
  --accent-8: var(--sky-8);
  --accent-9: var(--sky-9);
  --accent-10: var(--sky-10);
  --accent-11: var(--sky-11);
  --accent-12: var(--sky-12);
  --accent-a1: var(--sky-a1);
  --accent-a2: var(--sky-a2);
  --accent-a3: var(--sky-a3);
  --accent-a4: var(--sky-a4);
  --accent-a5: var(--sky-a5);
  --accent-a6: var(--sky-a6);
  --accent-a7: var(--sky-a7);
  --accent-a8: var(--sky-a8);
  --accent-a9: var(--sky-a9);
  --accent-a10: var(--sky-a10);
  --accent-a11: var(--sky-a11);
  --accent-a12: var(--sky-a12);
  --accent-contrast: var(--sky-contrast);
  --accent-surface: var(--sky-surface);
  --accent-indicator: var(--sky-indicator);
  --accent-track: var(--sky-track);
}

[data-accent-color="teal"] {
  --accent-1: var(--teal-1);
  --accent-2: var(--teal-2);
  --accent-3: var(--teal-3);
  --accent-4: var(--teal-4);
  --accent-5: var(--teal-5);
  --accent-6: var(--teal-6);
  --accent-7: var(--teal-7);
  --accent-8: var(--teal-8);
  --accent-9: var(--teal-9);
  --accent-10: var(--teal-10);
  --accent-11: var(--teal-11);
  --accent-12: var(--teal-12);
  --accent-a1: var(--teal-a1);
  --accent-a2: var(--teal-a2);
  --accent-a3: var(--teal-a3);
  --accent-a4: var(--teal-a4);
  --accent-a5: var(--teal-a5);
  --accent-a6: var(--teal-a6);
  --accent-a7: var(--teal-a7);
  --accent-a8: var(--teal-a8);
  --accent-a9: var(--teal-a9);
  --accent-a10: var(--teal-a10);
  --accent-a11: var(--teal-a11);
  --accent-a12: var(--teal-a12);
  --accent-contrast: var(--teal-contrast);
  --accent-surface: var(--teal-surface);
  --accent-indicator: var(--teal-indicator);
  --accent-track: var(--teal-track);
}

[data-accent-color="tomato"] {
  --accent-1: var(--tomato-1);
  --accent-2: var(--tomato-2);
  --accent-3: var(--tomato-3);
  --accent-4: var(--tomato-4);
  --accent-5: var(--tomato-5);
  --accent-6: var(--tomato-6);
  --accent-7: var(--tomato-7);
  --accent-8: var(--tomato-8);
  --accent-9: var(--tomato-9);
  --accent-10: var(--tomato-10);
  --accent-11: var(--tomato-11);
  --accent-12: var(--tomato-12);
  --accent-a1: var(--tomato-a1);
  --accent-a2: var(--tomato-a2);
  --accent-a3: var(--tomato-a3);
  --accent-a4: var(--tomato-a4);
  --accent-a5: var(--tomato-a5);
  --accent-a6: var(--tomato-a6);
  --accent-a7: var(--tomato-a7);
  --accent-a8: var(--tomato-a8);
  --accent-a9: var(--tomato-a9);
  --accent-a10: var(--tomato-a10);
  --accent-a11: var(--tomato-a11);
  --accent-a12: var(--tomato-a12);
  --accent-contrast: var(--tomato-contrast);
  --accent-surface: var(--tomato-surface);
  --accent-indicator: var(--tomato-indicator);
  --accent-track: var(--tomato-track);
}

[data-accent-color="violet"] {
  --accent-1: var(--violet-1);
  --accent-2: var(--violet-2);
  --accent-3: var(--violet-3);
  --accent-4: var(--violet-4);
  --accent-5: var(--violet-5);
  --accent-6: var(--violet-6);
  --accent-7: var(--violet-7);
  --accent-8: var(--violet-8);
  --accent-9: var(--violet-9);
  --accent-10: var(--violet-10);
  --accent-11: var(--violet-11);
  --accent-12: var(--violet-12);
  --accent-a1: var(--violet-a1);
  --accent-a2: var(--violet-a2);
  --accent-a3: var(--violet-a3);
  --accent-a4: var(--violet-a4);
  --accent-a5: var(--violet-a5);
  --accent-a6: var(--violet-a6);
  --accent-a7: var(--violet-a7);
  --accent-a8: var(--violet-a8);
  --accent-a9: var(--violet-a9);
  --accent-a10: var(--violet-a10);
  --accent-a11: var(--violet-a11);
  --accent-a12: var(--violet-a12);
  --accent-contrast: var(--violet-contrast);
  --accent-surface: var(--violet-surface);
  --accent-indicator: var(--violet-indicator);
  --accent-track: var(--violet-track);
}

[data-accent-color="yellow"] {
  --accent-1: var(--yellow-1);
  --accent-2: var(--yellow-2);
  --accent-3: var(--yellow-3);
  --accent-4: var(--yellow-4);
  --accent-5: var(--yellow-5);
  --accent-6: var(--yellow-6);
  --accent-7: var(--yellow-7);
  --accent-8: var(--yellow-8);
  --accent-9: var(--yellow-9);
  --accent-10: var(--yellow-10);
  --accent-11: var(--yellow-11);
  --accent-12: var(--yellow-12);
  --accent-a1: var(--yellow-a1);
  --accent-a2: var(--yellow-a2);
  --accent-a3: var(--yellow-a3);
  --accent-a4: var(--yellow-a4);
  --accent-a5: var(--yellow-a5);
  --accent-a6: var(--yellow-a6);
  --accent-a7: var(--yellow-a7);
  --accent-a8: var(--yellow-a8);
  --accent-a9: var(--yellow-a9);
  --accent-a10: var(--yellow-a10);
  --accent-a11: var(--yellow-a11);
  --accent-a12: var(--yellow-a12);
  --accent-contrast: var(--yellow-contrast);
  --accent-surface: var(--yellow-surface);
  --accent-indicator: var(--yellow-indicator);
  --accent-track: var(--yellow-track);
}

.radix-themes:where([data-gray-color="mauve"]) {
  --gray-1: var(--mauve-1);
  --gray-2: var(--mauve-2);
  --gray-3: var(--mauve-3);
  --gray-4: var(--mauve-4);
  --gray-5: var(--mauve-5);
  --gray-6: var(--mauve-6);
  --gray-7: var(--mauve-7);
  --gray-8: var(--mauve-8);
  --gray-9: var(--mauve-9);
  --gray-10: var(--mauve-10);
  --gray-11: var(--mauve-11);
  --gray-12: var(--mauve-12);
  --gray-a1: var(--mauve-a1);
  --gray-a2: var(--mauve-a2);
  --gray-a3: var(--mauve-a3);
  --gray-a4: var(--mauve-a4);
  --gray-a5: var(--mauve-a5);
  --gray-a6: var(--mauve-a6);
  --gray-a7: var(--mauve-a7);
  --gray-a8: var(--mauve-a8);
  --gray-a9: var(--mauve-a9);
  --gray-a10: var(--mauve-a10);
  --gray-a11: var(--mauve-a11);
  --gray-a12: var(--mauve-a12);
  --gray-contrast: var(--mauve-contrast);
  --gray-surface: var(--mauve-surface);
  --gray-indicator: var(--mauve-indicator);
  --gray-track: var(--mauve-track);
}

.radix-themes:where([data-gray-color="olive"]) {
  --gray-1: var(--olive-1);
  --gray-2: var(--olive-2);
  --gray-3: var(--olive-3);
  --gray-4: var(--olive-4);
  --gray-5: var(--olive-5);
  --gray-6: var(--olive-6);
  --gray-7: var(--olive-7);
  --gray-8: var(--olive-8);
  --gray-9: var(--olive-9);
  --gray-10: var(--olive-10);
  --gray-11: var(--olive-11);
  --gray-12: var(--olive-12);
  --gray-a1: var(--olive-a1);
  --gray-a2: var(--olive-a2);
  --gray-a3: var(--olive-a3);
  --gray-a4: var(--olive-a4);
  --gray-a5: var(--olive-a5);
  --gray-a6: var(--olive-a6);
  --gray-a7: var(--olive-a7);
  --gray-a8: var(--olive-a8);
  --gray-a9: var(--olive-a9);
  --gray-a10: var(--olive-a10);
  --gray-a11: var(--olive-a11);
  --gray-a12: var(--olive-a12);
  --gray-contrast: var(--olive-contrast);
  --gray-surface: var(--olive-surface);
  --gray-indicator: var(--olive-indicator);
  --gray-track: var(--olive-track);
}

.radix-themes:where([data-gray-color="sage"]) {
  --gray-1: var(--sage-1);
  --gray-2: var(--sage-2);
  --gray-3: var(--sage-3);
  --gray-4: var(--sage-4);
  --gray-5: var(--sage-5);
  --gray-6: var(--sage-6);
  --gray-7: var(--sage-7);
  --gray-8: var(--sage-8);
  --gray-9: var(--sage-9);
  --gray-10: var(--sage-10);
  --gray-11: var(--sage-11);
  --gray-12: var(--sage-12);
  --gray-a1: var(--sage-a1);
  --gray-a2: var(--sage-a2);
  --gray-a3: var(--sage-a3);
  --gray-a4: var(--sage-a4);
  --gray-a5: var(--sage-a5);
  --gray-a6: var(--sage-a6);
  --gray-a7: var(--sage-a7);
  --gray-a8: var(--sage-a8);
  --gray-a9: var(--sage-a9);
  --gray-a10: var(--sage-a10);
  --gray-a11: var(--sage-a11);
  --gray-a12: var(--sage-a12);
  --gray-contrast: var(--sage-contrast);
  --gray-surface: var(--sage-surface);
  --gray-indicator: var(--sage-indicator);
  --gray-track: var(--sage-track);
}

.radix-themes:where([data-gray-color="sand"]) {
  --gray-1: var(--sand-1);
  --gray-2: var(--sand-2);
  --gray-3: var(--sand-3);
  --gray-4: var(--sand-4);
  --gray-5: var(--sand-5);
  --gray-6: var(--sand-6);
  --gray-7: var(--sand-7);
  --gray-8: var(--sand-8);
  --gray-9: var(--sand-9);
  --gray-10: var(--sand-10);
  --gray-11: var(--sand-11);
  --gray-12: var(--sand-12);
  --gray-a1: var(--sand-a1);
  --gray-a2: var(--sand-a2);
  --gray-a3: var(--sand-a3);
  --gray-a4: var(--sand-a4);
  --gray-a5: var(--sand-a5);
  --gray-a6: var(--sand-a6);
  --gray-a7: var(--sand-a7);
  --gray-a8: var(--sand-a8);
  --gray-a9: var(--sand-a9);
  --gray-a10: var(--sand-a10);
  --gray-a11: var(--sand-a11);
  --gray-a12: var(--sand-a12);
  --gray-contrast: var(--sand-contrast);
  --gray-surface: var(--sand-surface);
  --gray-indicator: var(--sand-indicator);
  --gray-track: var(--sand-track);
}

.radix-themes:where([data-gray-color="slate"]) {
  --gray-1: var(--slate-1);
  --gray-2: var(--slate-2);
  --gray-3: var(--slate-3);
  --gray-4: var(--slate-4);
  --gray-5: var(--slate-5);
  --gray-6: var(--slate-6);
  --gray-7: var(--slate-7);
  --gray-8: var(--slate-8);
  --gray-9: var(--slate-9);
  --gray-10: var(--slate-10);
  --gray-11: var(--slate-11);
  --gray-12: var(--slate-12);
  --gray-a1: var(--slate-a1);
  --gray-a2: var(--slate-a2);
  --gray-a3: var(--slate-a3);
  --gray-a4: var(--slate-a4);
  --gray-a5: var(--slate-a5);
  --gray-a6: var(--slate-a6);
  --gray-a7: var(--slate-a7);
  --gray-a8: var(--slate-a8);
  --gray-a9: var(--slate-a9);
  --gray-a10: var(--slate-a10);
  --gray-a11: var(--slate-a11);
  --gray-a12: var(--slate-a12);
  --gray-contrast: var(--slate-contrast);
  --gray-surface: var(--slate-surface);
  --gray-indicator: var(--slate-indicator);
  --gray-track: var(--slate-track);
}

.radix-themes {
  --cursor-button: default;
  --cursor-checkbox: default;
  --cursor-disabled: not-allowed;
  --cursor-link: pointer;
  --cursor-menu-item: default;
  --cursor-radio: default;
  --cursor-slider-thumb: default;
  --cursor-slider-thumb-active: default;
  --cursor-switch: default;
  --space-1: calc(4px * var(--scaling));
  --space-2: calc(8px * var(--scaling));
  --space-3: calc(12px * var(--scaling));
  --space-4: calc(16px * var(--scaling));
  --space-5: calc(24px * var(--scaling));
  --space-6: calc(32px * var(--scaling));
  --space-7: calc(40px * var(--scaling));
  --space-8: calc(48px * var(--scaling));
  --space-9: calc(64px * var(--scaling));
  --font-size-1: calc(12px * var(--scaling));
  --font-size-2: calc(14px * var(--scaling));
  --font-size-3: calc(16px * var(--scaling));
  --font-size-4: calc(18px * var(--scaling));
  --font-size-5: calc(20px * var(--scaling));
  --font-size-6: calc(24px * var(--scaling));
  --font-size-7: calc(28px * var(--scaling));
  --font-size-8: calc(35px * var(--scaling));
  --font-size-9: calc(60px * var(--scaling));
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --line-height-1: calc(16px * var(--scaling));
  --line-height-2: calc(20px * var(--scaling));
  --line-height-3: calc(24px * var(--scaling));
  --line-height-4: calc(26px * var(--scaling));
  --line-height-5: calc(28px * var(--scaling));
  --line-height-6: calc(30px * var(--scaling));
  --line-height-7: calc(36px * var(--scaling));
  --line-height-8: calc(40px * var(--scaling));
  --line-height-9: calc(60px * var(--scaling));
  --letter-spacing-1: .0025em;
  --letter-spacing-2: 0em;
  --letter-spacing-3: 0em;
  --letter-spacing-4: -.0025em;
  --letter-spacing-5: -.005em;
  --letter-spacing-6: -.00625em;
  --letter-spacing-7: -.0075em;
  --letter-spacing-8: -.01em;
  --letter-spacing-9: -.025em;
  --default-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI (Custom)", Roboto, "Helvetica Neue", "Open Sans (Custom)", system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  --default-font-size: var(--font-size-3);
  --default-font-style: normal;
  --default-font-weight: var(--font-weight-regular);
  --default-line-height: 1.5;
  --default-letter-spacing: 0em;
  --default-leading-trim-start: .42em;
  --default-leading-trim-end: .36em;
  --heading-font-family: var(--default-font-family);
  --heading-font-size-adjust: 1;
  --heading-font-style: normal;
  --heading-leading-trim-start: var(--default-leading-trim-start);
  --heading-leading-trim-end: var(--default-leading-trim-end);
  --heading-letter-spacing: 0em;
  --heading-line-height-1: calc(16px * var(--scaling));
  --heading-line-height-2: calc(18px * var(--scaling));
  --heading-line-height-3: calc(22px * var(--scaling));
  --heading-line-height-4: calc(24px * var(--scaling));
  --heading-line-height-5: calc(26px * var(--scaling));
  --heading-line-height-6: calc(30px * var(--scaling));
  --heading-line-height-7: calc(36px * var(--scaling));
  --heading-line-height-8: calc(40px * var(--scaling));
  --heading-line-height-9: calc(60px * var(--scaling));
  --code-font-family: "Menlo", "Consolas (Custom)", "Bitstream Vera Sans Mono", monospace, "Apple Color Emoji", "Segoe UI Emoji";
  --code-font-size-adjust: .95;
  --code-font-style: normal;
  --code-font-weight: inherit;
  --code-letter-spacing: -.007em;
  --code-padding-top: .1em;
  --code-padding-bottom: .1em;
  --code-padding-left: .25em;
  --code-padding-right: .25em;
  --strong-font-family: var(--default-font-family);
  --strong-font-size-adjust: 1;
  --strong-font-style: inherit;
  --strong-font-weight: var(--font-weight-bold);
  --strong-letter-spacing: 0em;
  --em-font-family: "Times New Roman", "Times", serif;
  --em-font-size-adjust: 1.18;
  --em-font-style: italic;
  --em-font-weight: inherit;
  --em-letter-spacing: -.025em;
  --quote-font-family: "Times New Roman", "Times", serif;
  --quote-font-size-adjust: 1.18;
  --quote-font-style: italic;
  --quote-font-weight: inherit;
  --quote-letter-spacing: -.025em;
  --tab-active-letter-spacing: -.01em;
  --tab-active-word-spacing: 0em;
  --tab-inactive-letter-spacing: 0em;
  --tab-inactive-word-spacing: 0em;
  overflow-wrap: break-word;
  font-family: var(--default-font-family);
  font-size: var(--default-font-size);
  font-weight: var(--default-font-weight);
  font-style: var(--default-font-style);
  line-height: var(--default-line-height);
  letter-spacing: var(--default-letter-spacing);
  -moz-text-size-adjust: none;
  text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --container-1: 448px;
  --container-2: 688px;
  --container-3: 880px;
  --container-4: 1136px;
  --scrollarea-scrollbar-horizontal-margin-top: var(--space-1);
  --scrollarea-scrollbar-horizontal-margin-bottom: var(--space-1);
  --scrollarea-scrollbar-horizontal-margin-left: var(--space-1);
  --scrollarea-scrollbar-horizontal-margin-right: var(--space-1);
  --scrollarea-scrollbar-vertical-margin-top: var(--space-1);
  --scrollarea-scrollbar-vertical-margin-bottom: var(--space-1);
  --scrollarea-scrollbar-vertical-margin-left: var(--space-1);
  --scrollarea-scrollbar-vertical-margin-right: var(--space-1);
  --segmented-control-transition-duration: .1s;
  --spinner-animation-duration: .8s;
  --spinner-opacity: .65;
  color: var(--gray-12);
}

.radix-themes:where([data-scaling="90%"]) {
  --scaling: .9;
}

.radix-themes:where([data-scaling="95%"]) {
  --scaling: .95;
}

.radix-themes:where([data-scaling="100%"]) {
  --scaling: 1;
}

.radix-themes:where([data-scaling="105%"]) {
  --scaling: 1.05;
}

.radix-themes:where([data-scaling="110%"]) {
  --scaling: 1.1;
}

[data-radius] {
  --radius-1: calc(3px * var(--scaling) * var(--radius-factor));
  --radius-2: calc(4px * var(--scaling) * var(--radius-factor));
  --radius-3: calc(6px * var(--scaling) * var(--radius-factor));
  --radius-4: calc(8px * var(--scaling) * var(--radius-factor));
  --radius-5: calc(12px * var(--scaling) * var(--radius-factor));
  --radius-6: calc(16px * var(--scaling) * var(--radius-factor));
}

[data-radius="none"] {
  --radius-factor: 0;
  --radius-full: 0px;
  --radius-thumb: .5px;
}

[data-radius="small"] {
  --radius-factor: .75;
  --radius-full: 0px;
  --radius-thumb: .5px;
}

[data-radius="medium"] {
  --radius-factor: 1;
  --radius-full: 0px;
  --radius-thumb: 9999px;
}

[data-radius="large"] {
  --radius-factor: 1.5;
  --radius-full: 0px;
  --radius-thumb: 9999px;
}

[data-radius="full"] {
  --radius-factor: 1.5;
  --radius-full: 9999px;
  --radius-thumb: 9999px;
}

@supports (color: color-mix(in oklab, white, black)) {
  :where(.radix-themes) {
    --shadow-1: inset 0 0 0 1px var(--gray-a5), inset 0 1.5px 2px 0 var(--gray-a2), inset 0 1.5px 2px 0 var(--black-a2);
    --shadow-2: 0 0 0 1px color-mix(in oklab, var(--gray-a3), var(--gray-3) 25%), 0 0 0 .5px var(--black-a1), 0 1px 1px 0 var(--gray-a2), 0 2px 1px -1px var(--black-a1), 0 1px 3px 0 var(--black-a1);
    --shadow-3: 0 0 0 1px color-mix(in oklab, var(--gray-a3), var(--gray-3) 25%), 0 2px 3px -2px var(--gray-a3), 0 3px 12px -4px var(--black-a2), 0 4px 16px -8px var(--black-a2);
    --shadow-4: 0 0 0 1px color-mix(in oklab, var(--gray-a3), var(--gray-3) 25%), 0 8px 40px var(--black-a1), 0 12px 32px -16px var(--gray-a3);
    --shadow-5: 0 0 0 1px color-mix(in oklab, var(--gray-a3), var(--gray-3) 25%), 0 12px 60px var(--black-a3), 0 12px 32px -16px var(--gray-a5);
    --shadow-6: 0 0 0 1px color-mix(in oklab, var(--gray-a3), var(--gray-3) 25%), 0 12px 60px var(--black-a3), 0 16px 64px var(--gray-a2), 0 16px 36px -20px var(--gray-a7);
    --base-card-surface-box-shadow: 0 0 0 1px color-mix(in oklab, var(--gray-a5), var(--gray-5) 25%);
    --base-card-surface-hover-box-shadow: 0 0 0 1px color-mix(in oklab, var(--gray-a7), var(--gray-7) 25%);
    --base-card-surface-active-box-shadow: 0 0 0 1px color-mix(in oklab, var(--gray-a6), var(--gray-6) 25%);
    --base-card-classic-border-color: color-mix(in oklab, var(--gray-a3), var(--gray-3) 25%);
    --base-card-classic-hover-border-color: color-mix(in oklab, var(--gray-a4), var(--gray-4) 25%);
    --base-card-classic-active-border-color: color-mix(in oklab, var(--gray-a3), var(--gray-3) 25%);
  }

  :-webkit-any(.dark, .dark-theme) {
    --shadow-1: inset 0 -1px 1px 0 var(--gray-a3), inset 0 0 0 1px var(--gray-a3), inset 0 3px 4px 0 var(--black-a5), inset 0 0 0 1px var(--gray-a4);
    --shadow-2: 0 0 0 1px color-mix(in oklab, var(--gray-a6), var(--gray-6) 25%), 0 0 0 .5px var(--black-a3), 0 1px 1px 0 var(--black-a6), 0 2px 1px -1px var(--black-a6), 0 1px 3px 0 var(--black-a5);
    --shadow-3: 0 0 0 1px color-mix(in oklab, var(--gray-a6), var(--gray-6) 25%), 0 2px 3px -2px var(--black-a3), 0 3px 8px -2px var(--black-a6), 0 4px 12px -4px var(--black-a7);
    --shadow-4: 0 0 0 1px color-mix(in oklab, var(--gray-a6), var(--gray-6) 25%), 0 8px 40px var(--black-a3), 0 12px 32px -16px var(--black-a5);
    --shadow-5: 0 0 0 1px color-mix(in oklab, var(--gray-a6), var(--gray-6) 25%), 0 12px 60px var(--black-a5), 0 12px 32px -16px var(--black-a7);
    --shadow-6: 0 0 0 1px color-mix(in oklab, var(--gray-a6), var(--gray-6) 25%), 0 12px 60px var(--black-a4), 0 16px 64px var(--black-a6), 0 16px 36px -20px var(--black-a11);
    --base-card-classic-border-color: color-mix(in oklab, var(--gray-a6), var(--gray-6) 25%);
    --base-card-classic-hover-border-color: color-mix(in oklab, var(--gray-a6), var(--gray-6) 25%);
    --base-card-classic-active-border-color: color-mix(in oklab, var(--gray-a6), var(--gray-6) 25%);
  }

  :-moz-any(.dark, .dark-theme) {
    --shadow-1: inset 0 -1px 1px 0 var(--gray-a3), inset 0 0 0 1px var(--gray-a3), inset 0 3px 4px 0 var(--black-a5), inset 0 0 0 1px var(--gray-a4);
    --shadow-2: 0 0 0 1px color-mix(in oklab, var(--gray-a6), var(--gray-6) 25%), 0 0 0 .5px var(--black-a3), 0 1px 1px 0 var(--black-a6), 0 2px 1px -1px var(--black-a6), 0 1px 3px 0 var(--black-a5);
    --shadow-3: 0 0 0 1px color-mix(in oklab, var(--gray-a6), var(--gray-6) 25%), 0 2px 3px -2px var(--black-a3), 0 3px 8px -2px var(--black-a6), 0 4px 12px -4px var(--black-a7);
    --shadow-4: 0 0 0 1px color-mix(in oklab, var(--gray-a6), var(--gray-6) 25%), 0 8px 40px var(--black-a3), 0 12px 32px -16px var(--black-a5);
    --shadow-5: 0 0 0 1px color-mix(in oklab, var(--gray-a6), var(--gray-6) 25%), 0 12px 60px var(--black-a5), 0 12px 32px -16px var(--black-a7);
    --shadow-6: 0 0 0 1px color-mix(in oklab, var(--gray-a6), var(--gray-6) 25%), 0 12px 60px var(--black-a4), 0 16px 64px var(--black-a6), 0 16px 36px -20px var(--black-a11);
    --base-card-classic-border-color: color-mix(in oklab, var(--gray-a6), var(--gray-6) 25%);
    --base-card-classic-hover-border-color: color-mix(in oklab, var(--gray-a6), var(--gray-6) 25%);
    --base-card-classic-active-border-color: color-mix(in oklab, var(--gray-a6), var(--gray-6) 25%);
  }

  :is(.dark, .dark-theme) {
    --shadow-1: inset 0 -1px 1px 0 var(--gray-a3), inset 0 0 0 1px var(--gray-a3), inset 0 3px 4px 0 var(--black-a5), inset 0 0 0 1px var(--gray-a4);
    --shadow-2: 0 0 0 1px color-mix(in oklab, var(--gray-a6), var(--gray-6) 25%), 0 0 0 .5px var(--black-a3), 0 1px 1px 0 var(--black-a6), 0 2px 1px -1px var(--black-a6), 0 1px 3px 0 var(--black-a5);
    --shadow-3: 0 0 0 1px color-mix(in oklab, var(--gray-a6), var(--gray-6) 25%), 0 2px 3px -2px var(--black-a3), 0 3px 8px -2px var(--black-a6), 0 4px 12px -4px var(--black-a7);
    --shadow-4: 0 0 0 1px color-mix(in oklab, var(--gray-a6), var(--gray-6) 25%), 0 8px 40px var(--black-a3), 0 12px 32px -16px var(--black-a5);
    --shadow-5: 0 0 0 1px color-mix(in oklab, var(--gray-a6), var(--gray-6) 25%), 0 12px 60px var(--black-a5), 0 12px 32px -16px var(--black-a7);
    --shadow-6: 0 0 0 1px color-mix(in oklab, var(--gray-a6), var(--gray-6) 25%), 0 12px 60px var(--black-a4), 0 16px 64px var(--black-a6), 0 16px 36px -20px var(--black-a11);
    --base-card-classic-border-color: color-mix(in oklab, var(--gray-a6), var(--gray-6) 25%);
    --base-card-classic-hover-border-color: color-mix(in oklab, var(--gray-a6), var(--gray-6) 25%);
    --base-card-classic-active-border-color: color-mix(in oklab, var(--gray-a6), var(--gray-6) 25%);
  }

  :-webkit-any(.dark, .dark-theme) :where(.radix-themes:not(:-webkit-any(.light, .light-theme))) {
    --shadow-1: inset 0 -1px 1px 0 var(--gray-a3), inset 0 0 0 1px var(--gray-a3), inset 0 3px 4px 0 var(--black-a5), inset 0 0 0 1px var(--gray-a4);
    --shadow-2: 0 0 0 1px color-mix(in oklab, var(--gray-a6), var(--gray-6) 25%), 0 0 0 .5px var(--black-a3), 0 1px 1px 0 var(--black-a6), 0 2px 1px -1px var(--black-a6), 0 1px 3px 0 var(--black-a5);
    --shadow-3: 0 0 0 1px color-mix(in oklab, var(--gray-a6), var(--gray-6) 25%), 0 2px 3px -2px var(--black-a3), 0 3px 8px -2px var(--black-a6), 0 4px 12px -4px var(--black-a7);
    --shadow-4: 0 0 0 1px color-mix(in oklab, var(--gray-a6), var(--gray-6) 25%), 0 8px 40px var(--black-a3), 0 12px 32px -16px var(--black-a5);
    --shadow-5: 0 0 0 1px color-mix(in oklab, var(--gray-a6), var(--gray-6) 25%), 0 12px 60px var(--black-a5), 0 12px 32px -16px var(--black-a7);
    --shadow-6: 0 0 0 1px color-mix(in oklab, var(--gray-a6), var(--gray-6) 25%), 0 12px 60px var(--black-a4), 0 16px 64px var(--black-a6), 0 16px 36px -20px var(--black-a11);
    --base-card-classic-border-color: color-mix(in oklab, var(--gray-a6), var(--gray-6) 25%);
    --base-card-classic-hover-border-color: color-mix(in oklab, var(--gray-a6), var(--gray-6) 25%);
    --base-card-classic-active-border-color: color-mix(in oklab, var(--gray-a6), var(--gray-6) 25%);
  }

  :-moz-any(.dark, .dark-theme) :where(.radix-themes:not(:-moz-any(.light, .light-theme))) {
    --shadow-1: inset 0 -1px 1px 0 var(--gray-a3), inset 0 0 0 1px var(--gray-a3), inset 0 3px 4px 0 var(--black-a5), inset 0 0 0 1px var(--gray-a4);
    --shadow-2: 0 0 0 1px color-mix(in oklab, var(--gray-a6), var(--gray-6) 25%), 0 0 0 .5px var(--black-a3), 0 1px 1px 0 var(--black-a6), 0 2px 1px -1px var(--black-a6), 0 1px 3px 0 var(--black-a5);
    --shadow-3: 0 0 0 1px color-mix(in oklab, var(--gray-a6), var(--gray-6) 25%), 0 2px 3px -2px var(--black-a3), 0 3px 8px -2px var(--black-a6), 0 4px 12px -4px var(--black-a7);
    --shadow-4: 0 0 0 1px color-mix(in oklab, var(--gray-a6), var(--gray-6) 25%), 0 8px 40px var(--black-a3), 0 12px 32px -16px var(--black-a5);
    --shadow-5: 0 0 0 1px color-mix(in oklab, var(--gray-a6), var(--gray-6) 25%), 0 12px 60px var(--black-a5), 0 12px 32px -16px var(--black-a7);
    --shadow-6: 0 0 0 1px color-mix(in oklab, var(--gray-a6), var(--gray-6) 25%), 0 12px 60px var(--black-a4), 0 16px 64px var(--black-a6), 0 16px 36px -20px var(--black-a11);
    --base-card-classic-border-color: color-mix(in oklab, var(--gray-a6), var(--gray-6) 25%);
    --base-card-classic-hover-border-color: color-mix(in oklab, var(--gray-a6), var(--gray-6) 25%);
    --base-card-classic-active-border-color: color-mix(in oklab, var(--gray-a6), var(--gray-6) 25%);
  }

  :is(.dark, .dark-theme) :where(.radix-themes:not(:is(.light, .light-theme))) {
    --shadow-1: inset 0 -1px 1px 0 var(--gray-a3), inset 0 0 0 1px var(--gray-a3), inset 0 3px 4px 0 var(--black-a5), inset 0 0 0 1px var(--gray-a4);
    --shadow-2: 0 0 0 1px color-mix(in oklab, var(--gray-a6), var(--gray-6) 25%), 0 0 0 .5px var(--black-a3), 0 1px 1px 0 var(--black-a6), 0 2px 1px -1px var(--black-a6), 0 1px 3px 0 var(--black-a5);
    --shadow-3: 0 0 0 1px color-mix(in oklab, var(--gray-a6), var(--gray-6) 25%), 0 2px 3px -2px var(--black-a3), 0 3px 8px -2px var(--black-a6), 0 4px 12px -4px var(--black-a7);
    --shadow-4: 0 0 0 1px color-mix(in oklab, var(--gray-a6), var(--gray-6) 25%), 0 8px 40px var(--black-a3), 0 12px 32px -16px var(--black-a5);
    --shadow-5: 0 0 0 1px color-mix(in oklab, var(--gray-a6), var(--gray-6) 25%), 0 12px 60px var(--black-a5), 0 12px 32px -16px var(--black-a7);
    --shadow-6: 0 0 0 1px color-mix(in oklab, var(--gray-a6), var(--gray-6) 25%), 0 12px 60px var(--black-a4), 0 16px 64px var(--black-a6), 0 16px 36px -20px var(--black-a11);
    --base-card-classic-border-color: color-mix(in oklab, var(--gray-a6), var(--gray-6) 25%);
    --base-card-classic-hover-border-color: color-mix(in oklab, var(--gray-a6), var(--gray-6) 25%);
    --base-card-classic-active-border-color: color-mix(in oklab, var(--gray-a6), var(--gray-6) 25%);
  }
}

@font-face {
  font-family: "Segoe UI (Custom)";
  font-weight: 300;
  size-adjust: 103%;
  descent-override: 35%;
  ascent-override: 105%;
  src: local(Segoe UI Semilight), local(Segoe UI);
}

@font-face {
  font-family: "Segoe UI (Custom)";
  font-weight: 300;
  font-style: italic;
  size-adjust: 103%;
  descent-override: 35%;
  ascent-override: 105%;
  src: local(Segoe UI Semilight Italic), local(Segoe UI Italic);
}

@font-face {
  font-family: "Segoe UI (Custom)";
  font-weight: 400;
  size-adjust: 103%;
  descent-override: 35%;
  ascent-override: 105%;
  src: local(Segoe UI);
}

@font-face {
  font-family: "Segoe UI (Custom)";
  font-weight: 400;
  font-style: italic;
  size-adjust: 103%;
  descent-override: 35%;
  ascent-override: 105%;
  src: local(Segoe UI Italic);
}

@font-face {
  font-family: "Segoe UI (Custom)";
  font-weight: 500;
  size-adjust: 103%;
  descent-override: 35%;
  ascent-override: 105%;
  src: local(Segoe UI Semibold), local(Segoe UI);
}

@font-face {
  font-family: "Segoe UI (Custom)";
  font-weight: 500;
  font-style: italic;
  size-adjust: 103%;
  descent-override: 35%;
  ascent-override: 105%;
  src: local(Segoe UI Semibold Italic), local(Segoe UI Italic);
}

@font-face {
  font-family: "Segoe UI (Custom)";
  font-weight: 700;
  size-adjust: 103%;
  descent-override: 35%;
  ascent-override: 105%;
  src: local(Segoe UI Bold);
}

@font-face {
  font-family: "Segoe UI (Custom)";
  font-weight: 700;
  font-style: italic;
  size-adjust: 103%;
  descent-override: 35%;
  ascent-override: 105%;
  src: local(Segoe UI Bold Italic);
}

@font-face {
  font-family: "Open Sans (Custom)";
  font-weight: 300;
  descent-override: 35%;
  src: local(Open Sans Light), local(Open Sans Regular);
}

@font-face {
  font-family: "Open Sans (Custom)";
  font-weight: 300;
  font-style: italic;
  descent-override: 35%;
  src: local(Open Sans Light Italic), local(Open Sans Italic);
}

@font-face {
  font-family: "Open Sans (Custom)";
  font-weight: 400;
  descent-override: 35%;
  src: local(Open Sans Regular);
}

@font-face {
  font-family: "Open Sans (Custom)";
  font-weight: 400;
  font-style: italic;
  descent-override: 35%;
  src: local(Open Sans Italic);
}

@font-face {
  font-family: "Open Sans (Custom)";
  font-weight: 500;
  descent-override: 35%;
  src: local(Open Sans Medium), local(Open Sans Regular);
}

@font-face {
  font-family: "Open Sans (Custom)";
  font-weight: 500;
  font-style: italic;
  descent-override: 35%;
  src: local(Open Sans Medium Italic), local(Open Sans Italic);
}

@font-face {
  font-family: "Open Sans (Custom)";
  font-weight: 700;
  descent-override: 35%;
  src: local(Open Sans Bold);
}

@font-face {
  font-family: "Open Sans (Custom)";
  font-weight: 700;
  font-style: italic;
  descent-override: 35%;
  src: local(Open Sans Bold Italic);
}

@font-face {
  font-family: "Consolas (Custom)";
  font-weight: 400;
  size-adjust: 110%;
  ascent-override: 85%;
  descent-override: 22%;
  src: local(Consolas);
}

@font-face {
  font-family: "Consolas (Custom)";
  font-weight: 400;
  font-style: italic;
  size-adjust: 110%;
  ascent-override: 85%;
  descent-override: 22%;
  src: local(Consolas Italic);
}

@font-face {
  font-family: "Consolas (Custom)";
  font-weight: 700;
  size-adjust: 110%;
  ascent-override: 85%;
  descent-override: 22%;
  src: local(Consolas Bold);
}

@font-face {
  font-family: "Consolas (Custom)";
  font-weight: 700;
  font-style: italic;
  size-adjust: 110%;
  ascent-override: 85%;
  descent-override: 22%;
  src: local(Consolas Bold Italic);
}

.rt-reset:where(body, blockquote, dl, dd, figure, p) {
  margin: 0;
}

.rt-reset:where(address, b, cite, code, dfn, em, i, kbd, q, samp, small, strong, var) {
  font: unset;
}

.rt-reset:where(h1, h2, h3, h4, h5, h6) {
  font: unset;
  margin: 0;
}

.rt-reset:where(a) {
  all: unset;
  -webkit-tap-highlight-color: transparent;
}

.rt-reset:where(button, select, [type="button"], [type="image"], [type="reset"], [type="submit"], [type="checkbox"], [type="color"], [type="radio"], [type="range"]) {
  all: unset;
  text-indent: initial;
  -webkit-tap-highlight-color: transparent;
  font-style: normal;
  font-weight: normal;
  display: inline-block;
}

.rt-reset:where(label) {
  -webkit-tap-highlight-color: transparent;
}

.rt-reset:where(select) {
  text-align: start;
  font-style: normal;
  font-weight: normal;
}

.rt-reset:where(textarea, input:not(:-webkit-any([type="button"], [type="image"], [type="reset"], [type="submit"], [type="checkbox"], [type="color"], [type="radio"], [type="range"]))) {
  all: unset;
  width: -webkit-fill-available;
  width: -moz-available;
  text-align: start;
  width: stretch;
  text-indent: initial;
  -webkit-tap-highlight-color: transparent;
  cursor: text;
  white-space: pre-wrap;
  font-style: normal;
  font-weight: normal;
  display: block;
}

.rt-reset:where(textarea, input:not(:-moz-any([type="button"], [type="image"], [type="reset"], [type="submit"], [type="checkbox"], [type="color"], [type="radio"], [type="range"]))) {
  all: unset;
  width: -webkit-fill-available;
  width: -moz-available;
  text-align: start;
  width: stretch;
  text-indent: initial;
  -webkit-tap-highlight-color: transparent;
  cursor: text;
  white-space: pre-wrap;
  font-style: normal;
  font-weight: normal;
  display: block;
}

.rt-reset:where(textarea, input:not(:is([type="button"], [type="image"], [type="reset"], [type="submit"], [type="checkbox"], [type="color"], [type="radio"], [type="range"]))) {
  all: unset;
  width: -webkit-fill-available;
  width: -moz-available;
  text-align: start;
  width: stretch;
  text-indent: initial;
  -webkit-tap-highlight-color: transparent;
  cursor: text;
  white-space: pre-wrap;
  font-style: normal;
  font-weight: normal;
  display: block;
}

.rt-reset:where(:focus) {
  outline: none;
}

.rt-reset::placeholder {
  color: unset;
  opacity: unset;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.rt-reset:where(table) {
  all: unset;
  text-indent: initial;
  display: table;
}

.rt-reset:where(caption) {
  text-align: inherit;
}

.rt-reset:where(td) {
  padding: 0;
}

.rt-reset:where(th) {
  font-weight: unset;
  text-align: inherit;
  padding: 0;
}

.rt-reset:where(abbr, acronym) {
  text-decoration: none;
}

.rt-reset:where(canvas, object, picture, summary) {
  display: block;
}

.rt-reset:where(del, s) {
  -webkit-text-decoration: unset;
  text-decoration: unset;
}

.rt-reset:where(fieldset, hr) {
  all: unset;
  display: block;
}

.rt-reset:where(legend) {
  cursor: default;
  border: none;
  padding: 0;
}

.rt-reset:where(li) {
  text-align: unset;
  display: block;
}

.rt-reset:where(ol, ul) {
  margin: 0;
  padding: 0;
  list-style: none;
}

.rt-reset:where(iframe) {
  width: -webkit-fill-available;
  width: -moz-available;
  border: none;
  width: stretch;
  display: block;
}

.rt-reset:where(ins, u) {
  text-decoration: none;
}

.rt-reset:where(img) {
  max-width: 100%;
  display: block;
}

.rt-reset:where(svg) {
  flex-shrink: 0;
  max-width: 100%;
  display: block;
}

.rt-reset:where(mark) {
  all: unset;
}

.rt-reset:where(pre) {
  font: unset;
  margin: unset;
}

.rt-reset:where(q):before {
  content: "";
}

.rt-reset:where(q):after {
  content: "";
}

.rt-reset:where(sub, sup) {
  font: unset;
  vertical-align: unset;
}

.rt-reset:where(details) ::marker {
  content: none;
}

.rt-reset:where(summary)::marker {
  content: none;
}

.rt-reset:where(video) {
  width: -webkit-fill-available;
  width: -moz-available;
  width: stretch;
  display: block;
}

.rt-reset:where(:-webkit-any-link) {
  cursor: var(--cursor-link);
}

.rt-reset:where(:any-link) {
  cursor: var(--cursor-link);
}

.rt-reset:where(button) {
  cursor: var(--cursor-button);
}

.rt-reset:where(:disabled, [data-disabled]) {
  cursor: var(--cursor-disabled);
}

.rt-reset:where(input[type="checkbox"]) {
  cursor: var(--cursor-checkbox);
}

.rt-reset:where(input[type="radio"]) {
  cursor: var(--cursor-radio);
}

.rt-reset, .rt-reset:before, .rt-reset:after {
  box-sizing: border-box;
}

@keyframes rt-fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes rt-fade-out {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes rt-slide-from-top {
  from {
    transform: translateY(4px)scale(.97);
  }

  to {
    transform: translateY(0)scale(1);
  }
}

@keyframes rt-slide-to-top {
  from {
    transform: translateY(0)scale(1);
  }

  to {
    transform: translateY(4px)scale(.97);
  }
}

@keyframes rt-slide-from-bottom {
  from {
    transform: translateY(-4px)scale(.97);
  }

  to {
    transform: translateY(0)scale(1);
  }
}

@keyframes rt-slide-to-bottom {
  from {
    transform: translateY(0)scale(1);
  }

  to {
    transform: translateY(-4px)scale(.97);
  }
}

@keyframes rt-slide-from-left {
  from {
    transform: translateX(4px)scale(.97);
  }

  to {
    transform: translateX(0)scale(1);
  }
}

@keyframes rt-slide-to-left {
  from {
    transform: translateX(0)scale(1);
  }

  to {
    transform: translateX(4px)scale(.97);
  }
}

@keyframes rt-slide-from-right {
  from {
    transform: translateX(-4px)scale(.97);
  }

  to {
    transform: translateX(0)scale(1);
  }
}

@keyframes rt-slide-to-right {
  from {
    transform: translateX(0)scale(1);
  }

  to {
    transform: translateX(-4px)scale(.97);
  }
}

@media (prefers-reduced-motion: no-preference) {
  .rt-PopperContent {
    animation-timing-function: cubic-bezier(.16, 1, .3, 1);
  }

  .rt-PopperContent:where([data-state="open"]) {
    animation-duration: .16s;
  }

  .rt-PopperContent:where([data-state="open"]):where([data-side="top"]) {
    animation-name: rt-slide-from-top, rt-fade-in;
  }

  .rt-PopperContent:where([data-state="open"]):where([data-side="bottom"]) {
    animation-name: rt-slide-from-bottom, rt-fade-in;
  }

  .rt-PopperContent:where([data-state="open"]):where([data-side="left"]) {
    animation-name: rt-slide-from-left, rt-fade-in;
  }

  .rt-PopperContent:where([data-state="open"]):where([data-side="right"]) {
    animation-name: rt-slide-from-right, rt-fade-in;
  }

  .rt-PopperContent:where([data-state="closed"]) {
    animation-duration: .1s;
  }

  .rt-PopperContent:where([data-state="closed"]):where([data-side="top"]) {
    animation-name: rt-slide-to-top, rt-fade-out;
  }

  .rt-PopperContent:where([data-state="closed"]):where([data-side="bottom"]) {
    animation-name: rt-slide-to-bottom, rt-fade-out;
  }

  .rt-PopperContent:where([data-state="closed"]):where([data-side="left"]) {
    animation-name: rt-slide-to-left, rt-fade-out;
  }

  .rt-PopperContent:where([data-state="closed"]):where([data-side="right"]) {
    animation-name: rt-slide-to-right, rt-fade-out;
  }
}

.rt-Box {
  box-sizing: border-box;
  display: block;
}

.rt-Flex {
  box-sizing: border-box;
  justify-content: flex-start;
  display: flex;
}

.rt-Grid {
  box-sizing: border-box;
  grid-template-rows: none;
  grid-template-columns: minmax(0, 1fr);
  justify-content: flex-start;
  align-items: stretch;
  display: grid;
}

.rt-Section {
  box-sizing: border-box;
  flex-shrink: 0;
}

.rt-Section:where(.rt-r-size-1) {
  padding-top: var(--space-5);
  padding-bottom: var(--space-5);
}

.rt-Section:where(.rt-r-size-2) {
  padding-top: var(--space-7);
  padding-bottom: var(--space-7);
}

.rt-Section:where(.rt-r-size-3) {
  padding-top: var(--space-9);
  padding-bottom: var(--space-9);
}

.rt-Section:where(.rt-r-size-4) {
  padding-top: calc(80px * var(--scaling));
  padding-bottom: calc(80px * var(--scaling));
}

@media (min-width: 520px) {
  .rt-Section:where(.xs\:rt-r-size-1) {
    padding-top: var(--space-5);
    padding-bottom: var(--space-5);
  }

  .rt-Section:where(.xs\:rt-r-size-2) {
    padding-top: var(--space-7);
    padding-bottom: var(--space-7);
  }

  .rt-Section:where(.xs\:rt-r-size-3) {
    padding-top: var(--space-9);
    padding-bottom: var(--space-9);
  }

  .rt-Section:where(.xs\:rt-r-size-4) {
    padding-top: calc(80px * var(--scaling));
    padding-bottom: calc(80px * var(--scaling));
  }
}

@media (min-width: 768px) {
  .rt-Section:where(.sm\:rt-r-size-1) {
    padding-top: var(--space-5);
    padding-bottom: var(--space-5);
  }

  .rt-Section:where(.sm\:rt-r-size-2) {
    padding-top: var(--space-7);
    padding-bottom: var(--space-7);
  }

  .rt-Section:where(.sm\:rt-r-size-3) {
    padding-top: var(--space-9);
    padding-bottom: var(--space-9);
  }

  .rt-Section:where(.sm\:rt-r-size-4) {
    padding-top: calc(80px * var(--scaling));
    padding-bottom: calc(80px * var(--scaling));
  }
}

@media (min-width: 1024px) {
  .rt-Section:where(.md\:rt-r-size-1) {
    padding-top: var(--space-5);
    padding-bottom: var(--space-5);
  }

  .rt-Section:where(.md\:rt-r-size-2) {
    padding-top: var(--space-7);
    padding-bottom: var(--space-7);
  }

  .rt-Section:where(.md\:rt-r-size-3) {
    padding-top: var(--space-9);
    padding-bottom: var(--space-9);
  }

  .rt-Section:where(.md\:rt-r-size-4) {
    padding-top: calc(80px * var(--scaling));
    padding-bottom: calc(80px * var(--scaling));
  }
}

@media (min-width: 1280px) {
  .rt-Section:where(.lg\:rt-r-size-1) {
    padding-top: var(--space-5);
    padding-bottom: var(--space-5);
  }

  .rt-Section:where(.lg\:rt-r-size-2) {
    padding-top: var(--space-7);
    padding-bottom: var(--space-7);
  }

  .rt-Section:where(.lg\:rt-r-size-3) {
    padding-top: var(--space-9);
    padding-bottom: var(--space-9);
  }

  .rt-Section:where(.lg\:rt-r-size-4) {
    padding-top: calc(80px * var(--scaling));
    padding-bottom: calc(80px * var(--scaling));
  }
}

@media (min-width: 1640px) {
  .rt-Section:where(.xl\:rt-r-size-1) {
    padding-top: var(--space-5);
    padding-bottom: var(--space-5);
  }

  .rt-Section:where(.xl\:rt-r-size-2) {
    padding-top: var(--space-7);
    padding-bottom: var(--space-7);
  }

  .rt-Section:where(.xl\:rt-r-size-3) {
    padding-top: var(--space-9);
    padding-bottom: var(--space-9);
  }

  .rt-Section:where(.xl\:rt-r-size-4) {
    padding-top: calc(80px * var(--scaling));
    padding-bottom: calc(80px * var(--scaling));
  }
}

.rt-Container {
  box-sizing: border-box;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  align-items: center;
  display: flex;
}

.rt-ContainerInner {
  width: 100%;
}

:where(.rt-Container.rt-r-size-1) .rt-ContainerInner {
  max-width: var(--container-1);
}

:where(.rt-Container.rt-r-size-2) .rt-ContainerInner {
  max-width: var(--container-2);
}

:where(.rt-Container.rt-r-size-3) .rt-ContainerInner {
  max-width: var(--container-3);
}

:where(.rt-Container.rt-r-size-4) .rt-ContainerInner {
  max-width: var(--container-4);
}

@media (min-width: 520px) {
  :where(.rt-Container.xs\:rt-r-size-1) .rt-ContainerInner {
    max-width: var(--container-1);
  }

  :where(.rt-Container.xs\:rt-r-size-2) .rt-ContainerInner {
    max-width: var(--container-2);
  }

  :where(.rt-Container.xs\:rt-r-size-3) .rt-ContainerInner {
    max-width: var(--container-3);
  }

  :where(.rt-Container.xs\:rt-r-size-4) .rt-ContainerInner {
    max-width: var(--container-4);
  }
}

@media (min-width: 768px) {
  :where(.rt-Container.sm\:rt-r-size-1) .rt-ContainerInner {
    max-width: var(--container-1);
  }

  :where(.rt-Container.sm\:rt-r-size-2) .rt-ContainerInner {
    max-width: var(--container-2);
  }

  :where(.rt-Container.sm\:rt-r-size-3) .rt-ContainerInner {
    max-width: var(--container-3);
  }

  :where(.rt-Container.sm\:rt-r-size-4) .rt-ContainerInner {
    max-width: var(--container-4);
  }
}

@media (min-width: 1024px) {
  :where(.rt-Container.md\:rt-r-size-1) .rt-ContainerInner {
    max-width: var(--container-1);
  }

  :where(.rt-Container.md\:rt-r-size-2) .rt-ContainerInner {
    max-width: var(--container-2);
  }

  :where(.rt-Container.md\:rt-r-size-3) .rt-ContainerInner {
    max-width: var(--container-3);
  }

  :where(.rt-Container.md\:rt-r-size-4) .rt-ContainerInner {
    max-width: var(--container-4);
  }
}

@media (min-width: 1280px) {
  :where(.rt-Container.lg\:rt-r-size-1) .rt-ContainerInner {
    max-width: var(--container-1);
  }

  :where(.rt-Container.lg\:rt-r-size-2) .rt-ContainerInner {
    max-width: var(--container-2);
  }

  :where(.rt-Container.lg\:rt-r-size-3) .rt-ContainerInner {
    max-width: var(--container-3);
  }

  :where(.rt-Container.lg\:rt-r-size-4) .rt-ContainerInner {
    max-width: var(--container-4);
  }
}

@media (min-width: 1640px) {
  :where(.rt-Container.xl\:rt-r-size-1) .rt-ContainerInner {
    max-width: var(--container-1);
  }

  :where(.rt-Container.xl\:rt-r-size-2) .rt-ContainerInner {
    max-width: var(--container-2);
  }

  :where(.rt-Container.xl\:rt-r-size-3) .rt-ContainerInner {
    max-width: var(--container-3);
  }

  :where(.rt-Container.xl\:rt-r-size-4) .rt-ContainerInner {
    max-width: var(--container-4);
  }
}

.rt-Skeleton {
  --skeleton-radius: var(--skeleton-radius-override);
  --skeleton-height: var(--skeleton-height-override);
  border-radius: var(--radius-1);
  box-shadow: none !important;
  -webkit-box-decoration-break: clone !important;
  box-decoration-break: clone !important;
  color: rgba(0, 0, 0, 0) !important;
  pointer-events: none !important;
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  user-select: none !important;
  cursor: default !important;
  background-image: none !important;
  background-clip: border-box !important;
  border: none !important;
  outline: none !important;
  animation: 1s infinite alternate-reverse rt-skeleton-pulse !important;
}

.rt-Skeleton:where([data-inline-skeleton]) {
  line-height: 0;
  font-family: Arial, sans-serif !important;
}

:where(.rt-Skeleton:empty) {
  height: var(--space-3);
  display: block;
}

.rt-Skeleton > *, .rt-Skeleton:after, .rt-Skeleton:before {
  visibility: hidden !important;
}

@keyframes rt-skeleton-pulse {
  from {
    background-color: var(--gray-a3);
  }

  to {
    background-color: var(--gray-a4);
  }
}

.rt-Text {
  line-height: var(--line-height, var(--default-line-height));
  letter-spacing: var(--letter-spacing, inherit);
}

:where(.rt-Text) {
  margin: 0;
}

.rt-Text:where([data-accent-color]) {
  color: var(--accent-a11);
}

.rt-Text:where([data-accent-color].rt-high-contrast) {
  color: var(--accent-12);
}

:where([data-accent-color]:not(.radix-themes)) .rt-Text:where(.rt-high-contrast) {
  color: var(--accent-12);
}

@media (pointer: coarse) {
  .rt-Text:where(label) {
    -webkit-tap-highlight-color: transparent;
  }

  .rt-Text:where(label):where(:active) {
    outline: .75em solid var(--gray-a4);
    outline-offset: -.6em;
  }
}

.rt-Text:where(.rt-r-size-1) {
  font-size: var(--font-size-1);
  --line-height: var(--line-height-1);
  --letter-spacing: var(--letter-spacing-1);
}

.rt-Text:where(.rt-r-size-2) {
  font-size: var(--font-size-2);
  --line-height: var(--line-height-2);
  --letter-spacing: var(--letter-spacing-2);
}

.rt-Text:where(.rt-r-size-3) {
  font-size: var(--font-size-3);
  --line-height: var(--line-height-3);
  --letter-spacing: var(--letter-spacing-3);
}

.rt-Text:where(.rt-r-size-4) {
  font-size: var(--font-size-4);
  --line-height: var(--line-height-4);
  --letter-spacing: var(--letter-spacing-4);
}

.rt-Text:where(.rt-r-size-5) {
  font-size: var(--font-size-5);
  --line-height: var(--line-height-5);
  --letter-spacing: var(--letter-spacing-5);
}

.rt-Text:where(.rt-r-size-6) {
  font-size: var(--font-size-6);
  --line-height: var(--line-height-6);
  --letter-spacing: var(--letter-spacing-6);
}

.rt-Text:where(.rt-r-size-7) {
  font-size: var(--font-size-7);
  --line-height: var(--line-height-7);
  --letter-spacing: var(--letter-spacing-7);
}

.rt-Text:where(.rt-r-size-8) {
  font-size: var(--font-size-8);
  --line-height: var(--line-height-8);
  --letter-spacing: var(--letter-spacing-8);
}

.rt-Text:where(.rt-r-size-9) {
  font-size: var(--font-size-9);
  --line-height: var(--line-height-9);
  --letter-spacing: var(--letter-spacing-9);
}

@media (min-width: 520px) {
  .rt-Text:where(.xs\:rt-r-size-1) {
    font-size: var(--font-size-1);
    --line-height: var(--line-height-1);
    --letter-spacing: var(--letter-spacing-1);
  }

  .rt-Text:where(.xs\:rt-r-size-2) {
    font-size: var(--font-size-2);
    --line-height: var(--line-height-2);
    --letter-spacing: var(--letter-spacing-2);
  }

  .rt-Text:where(.xs\:rt-r-size-3) {
    font-size: var(--font-size-3);
    --line-height: var(--line-height-3);
    --letter-spacing: var(--letter-spacing-3);
  }

  .rt-Text:where(.xs\:rt-r-size-4) {
    font-size: var(--font-size-4);
    --line-height: var(--line-height-4);
    --letter-spacing: var(--letter-spacing-4);
  }

  .rt-Text:where(.xs\:rt-r-size-5) {
    font-size: var(--font-size-5);
    --line-height: var(--line-height-5);
    --letter-spacing: var(--letter-spacing-5);
  }

  .rt-Text:where(.xs\:rt-r-size-6) {
    font-size: var(--font-size-6);
    --line-height: var(--line-height-6);
    --letter-spacing: var(--letter-spacing-6);
  }

  .rt-Text:where(.xs\:rt-r-size-7) {
    font-size: var(--font-size-7);
    --line-height: var(--line-height-7);
    --letter-spacing: var(--letter-spacing-7);
  }

  .rt-Text:where(.xs\:rt-r-size-8) {
    font-size: var(--font-size-8);
    --line-height: var(--line-height-8);
    --letter-spacing: var(--letter-spacing-8);
  }

  .rt-Text:where(.xs\:rt-r-size-9) {
    font-size: var(--font-size-9);
    --line-height: var(--line-height-9);
    --letter-spacing: var(--letter-spacing-9);
  }
}

@media (min-width: 768px) {
  .rt-Text:where(.sm\:rt-r-size-1) {
    font-size: var(--font-size-1);
    --line-height: var(--line-height-1);
    --letter-spacing: var(--letter-spacing-1);
  }

  .rt-Text:where(.sm\:rt-r-size-2) {
    font-size: var(--font-size-2);
    --line-height: var(--line-height-2);
    --letter-spacing: var(--letter-spacing-2);
  }

  .rt-Text:where(.sm\:rt-r-size-3) {
    font-size: var(--font-size-3);
    --line-height: var(--line-height-3);
    --letter-spacing: var(--letter-spacing-3);
  }

  .rt-Text:where(.sm\:rt-r-size-4) {
    font-size: var(--font-size-4);
    --line-height: var(--line-height-4);
    --letter-spacing: var(--letter-spacing-4);
  }

  .rt-Text:where(.sm\:rt-r-size-5) {
    font-size: var(--font-size-5);
    --line-height: var(--line-height-5);
    --letter-spacing: var(--letter-spacing-5);
  }

  .rt-Text:where(.sm\:rt-r-size-6) {
    font-size: var(--font-size-6);
    --line-height: var(--line-height-6);
    --letter-spacing: var(--letter-spacing-6);
  }

  .rt-Text:where(.sm\:rt-r-size-7) {
    font-size: var(--font-size-7);
    --line-height: var(--line-height-7);
    --letter-spacing: var(--letter-spacing-7);
  }

  .rt-Text:where(.sm\:rt-r-size-8) {
    font-size: var(--font-size-8);
    --line-height: var(--line-height-8);
    --letter-spacing: var(--letter-spacing-8);
  }

  .rt-Text:where(.sm\:rt-r-size-9) {
    font-size: var(--font-size-9);
    --line-height: var(--line-height-9);
    --letter-spacing: var(--letter-spacing-9);
  }
}

@media (min-width: 1024px) {
  .rt-Text:where(.md\:rt-r-size-1) {
    font-size: var(--font-size-1);
    --line-height: var(--line-height-1);
    --letter-spacing: var(--letter-spacing-1);
  }

  .rt-Text:where(.md\:rt-r-size-2) {
    font-size: var(--font-size-2);
    --line-height: var(--line-height-2);
    --letter-spacing: var(--letter-spacing-2);
  }

  .rt-Text:where(.md\:rt-r-size-3) {
    font-size: var(--font-size-3);
    --line-height: var(--line-height-3);
    --letter-spacing: var(--letter-spacing-3);
  }

  .rt-Text:where(.md\:rt-r-size-4) {
    font-size: var(--font-size-4);
    --line-height: var(--line-height-4);
    --letter-spacing: var(--letter-spacing-4);
  }

  .rt-Text:where(.md\:rt-r-size-5) {
    font-size: var(--font-size-5);
    --line-height: var(--line-height-5);
    --letter-spacing: var(--letter-spacing-5);
  }

  .rt-Text:where(.md\:rt-r-size-6) {
    font-size: var(--font-size-6);
    --line-height: var(--line-height-6);
    --letter-spacing: var(--letter-spacing-6);
  }

  .rt-Text:where(.md\:rt-r-size-7) {
    font-size: var(--font-size-7);
    --line-height: var(--line-height-7);
    --letter-spacing: var(--letter-spacing-7);
  }

  .rt-Text:where(.md\:rt-r-size-8) {
    font-size: var(--font-size-8);
    --line-height: var(--line-height-8);
    --letter-spacing: var(--letter-spacing-8);
  }

  .rt-Text:where(.md\:rt-r-size-9) {
    font-size: var(--font-size-9);
    --line-height: var(--line-height-9);
    --letter-spacing: var(--letter-spacing-9);
  }
}

@media (min-width: 1280px) {
  .rt-Text:where(.lg\:rt-r-size-1) {
    font-size: var(--font-size-1);
    --line-height: var(--line-height-1);
    --letter-spacing: var(--letter-spacing-1);
  }

  .rt-Text:where(.lg\:rt-r-size-2) {
    font-size: var(--font-size-2);
    --line-height: var(--line-height-2);
    --letter-spacing: var(--letter-spacing-2);
  }

  .rt-Text:where(.lg\:rt-r-size-3) {
    font-size: var(--font-size-3);
    --line-height: var(--line-height-3);
    --letter-spacing: var(--letter-spacing-3);
  }

  .rt-Text:where(.lg\:rt-r-size-4) {
    font-size: var(--font-size-4);
    --line-height: var(--line-height-4);
    --letter-spacing: var(--letter-spacing-4);
  }

  .rt-Text:where(.lg\:rt-r-size-5) {
    font-size: var(--font-size-5);
    --line-height: var(--line-height-5);
    --letter-spacing: var(--letter-spacing-5);
  }

  .rt-Text:where(.lg\:rt-r-size-6) {
    font-size: var(--font-size-6);
    --line-height: var(--line-height-6);
    --letter-spacing: var(--letter-spacing-6);
  }

  .rt-Text:where(.lg\:rt-r-size-7) {
    font-size: var(--font-size-7);
    --line-height: var(--line-height-7);
    --letter-spacing: var(--letter-spacing-7);
  }

  .rt-Text:where(.lg\:rt-r-size-8) {
    font-size: var(--font-size-8);
    --line-height: var(--line-height-8);
    --letter-spacing: var(--letter-spacing-8);
  }

  .rt-Text:where(.lg\:rt-r-size-9) {
    font-size: var(--font-size-9);
    --line-height: var(--line-height-9);
    --letter-spacing: var(--letter-spacing-9);
  }
}

@media (min-width: 1640px) {
  .rt-Text:where(.xl\:rt-r-size-1) {
    font-size: var(--font-size-1);
    --line-height: var(--line-height-1);
    --letter-spacing: var(--letter-spacing-1);
  }

  .rt-Text:where(.xl\:rt-r-size-2) {
    font-size: var(--font-size-2);
    --line-height: var(--line-height-2);
    --letter-spacing: var(--letter-spacing-2);
  }

  .rt-Text:where(.xl\:rt-r-size-3) {
    font-size: var(--font-size-3);
    --line-height: var(--line-height-3);
    --letter-spacing: var(--letter-spacing-3);
  }

  .rt-Text:where(.xl\:rt-r-size-4) {
    font-size: var(--font-size-4);
    --line-height: var(--line-height-4);
    --letter-spacing: var(--letter-spacing-4);
  }

  .rt-Text:where(.xl\:rt-r-size-5) {
    font-size: var(--font-size-5);
    --line-height: var(--line-height-5);
    --letter-spacing: var(--letter-spacing-5);
  }

  .rt-Text:where(.xl\:rt-r-size-6) {
    font-size: var(--font-size-6);
    --line-height: var(--line-height-6);
    --letter-spacing: var(--letter-spacing-6);
  }

  .rt-Text:where(.xl\:rt-r-size-7) {
    font-size: var(--font-size-7);
    --line-height: var(--line-height-7);
    --letter-spacing: var(--letter-spacing-7);
  }

  .rt-Text:where(.xl\:rt-r-size-8) {
    font-size: var(--font-size-8);
    --line-height: var(--line-height-8);
    --letter-spacing: var(--letter-spacing-8);
  }

  .rt-Text:where(.xl\:rt-r-size-9) {
    font-size: var(--font-size-9);
    --line-height: var(--line-height-9);
    --letter-spacing: var(--letter-spacing-9);
  }
}

.rt-BaseDialogOverlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.rt-BaseDialogOverlay:before {
  content: "";
  background-color: var(--color-overlay);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.rt-BaseDialogScroll {
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
}

.rt-BaseDialogScrollPadding {
  padding-top: var(--space-6);
  padding-bottom: max(var(--space-6), 6vh);
  padding-left: var(--space-4);
  padding-right: var(--space-4);
  flex-grow: 1;
  margin: auto;
}

.rt-BaseDialogScrollPadding:where(.rt-r-align-start) {
  margin-top: 0;
}

.rt-BaseDialogScrollPadding:where(.rt-r-align-center) {
  margin-top: auto;
}

@media (min-width: 520px) {
  .rt-BaseDialogScrollPadding:where(.xs\:rt-r-align-start) {
    margin-top: 0;
  }

  .rt-BaseDialogScrollPadding:where(.xs\:rt-r-align-center) {
    margin-top: auto;
  }
}

@media (min-width: 768px) {
  .rt-BaseDialogScrollPadding:where(.sm\:rt-r-align-start) {
    margin-top: 0;
  }

  .rt-BaseDialogScrollPadding:where(.sm\:rt-r-align-center) {
    margin-top: auto;
  }
}

@media (min-width: 1024px) {
  .rt-BaseDialogScrollPadding:where(.md\:rt-r-align-start) {
    margin-top: 0;
  }

  .rt-BaseDialogScrollPadding:where(.md\:rt-r-align-center) {
    margin-top: auto;
  }
}

@media (min-width: 1280px) {
  .rt-BaseDialogScrollPadding:where(.lg\:rt-r-align-start) {
    margin-top: 0;
  }

  .rt-BaseDialogScrollPadding:where(.lg\:rt-r-align-center) {
    margin-top: auto;
  }
}

@media (min-width: 1640px) {
  .rt-BaseDialogScrollPadding:where(.xl\:rt-r-align-start) {
    margin-top: 0;
  }

  .rt-BaseDialogScrollPadding:where(.xl\:rt-r-align-center) {
    margin-top: auto;
  }
}

.rt-BaseDialogContent {
  z-index: 1;
  box-sizing: border-box;
  --inset-padding-top: var(--dialog-content-padding);
  --inset-padding-right: var(--dialog-content-padding);
  --inset-padding-bottom: var(--dialog-content-padding);
  --inset-padding-left: var(--dialog-content-padding);
  width: 100%;
  padding: var(--dialog-content-padding);
  background-color: var(--color-panel-solid);
  box-shadow: var(--shadow-6);
  outline: none;
  margin: auto;
  position: relative;
  overflow: auto;
}

.rt-BaseDialogContent:where(.rt-r-size-1) {
  --dialog-content-padding: var(--space-3);
  border-radius: var(--radius-4);
}

.rt-BaseDialogContent:where(.rt-r-size-2) {
  --dialog-content-padding: var(--space-4);
  border-radius: var(--radius-4);
}

.rt-BaseDialogContent:where(.rt-r-size-3) {
  --dialog-content-padding: var(--space-5);
  border-radius: var(--radius-5);
}

.rt-BaseDialogContent:where(.rt-r-size-4) {
  --dialog-content-padding: var(--space-6);
  border-radius: var(--radius-5);
}

@media (min-width: 520px) {
  .rt-BaseDialogContent:where(.xs\:rt-r-size-1) {
    --dialog-content-padding: var(--space-3);
    border-radius: var(--radius-4);
  }

  .rt-BaseDialogContent:where(.xs\:rt-r-size-2) {
    --dialog-content-padding: var(--space-4);
    border-radius: var(--radius-4);
  }

  .rt-BaseDialogContent:where(.xs\:rt-r-size-3) {
    --dialog-content-padding: var(--space-5);
    border-radius: var(--radius-5);
  }

  .rt-BaseDialogContent:where(.xs\:rt-r-size-4) {
    --dialog-content-padding: var(--space-6);
    border-radius: var(--radius-5);
  }
}

@media (min-width: 768px) {
  .rt-BaseDialogContent:where(.sm\:rt-r-size-1) {
    --dialog-content-padding: var(--space-3);
    border-radius: var(--radius-4);
  }

  .rt-BaseDialogContent:where(.sm\:rt-r-size-2) {
    --dialog-content-padding: var(--space-4);
    border-radius: var(--radius-4);
  }

  .rt-BaseDialogContent:where(.sm\:rt-r-size-3) {
    --dialog-content-padding: var(--space-5);
    border-radius: var(--radius-5);
  }

  .rt-BaseDialogContent:where(.sm\:rt-r-size-4) {
    --dialog-content-padding: var(--space-6);
    border-radius: var(--radius-5);
  }
}

@media (min-width: 1024px) {
  .rt-BaseDialogContent:where(.md\:rt-r-size-1) {
    --dialog-content-padding: var(--space-3);
    border-radius: var(--radius-4);
  }

  .rt-BaseDialogContent:where(.md\:rt-r-size-2) {
    --dialog-content-padding: var(--space-4);
    border-radius: var(--radius-4);
  }

  .rt-BaseDialogContent:where(.md\:rt-r-size-3) {
    --dialog-content-padding: var(--space-5);
    border-radius: var(--radius-5);
  }

  .rt-BaseDialogContent:where(.md\:rt-r-size-4) {
    --dialog-content-padding: var(--space-6);
    border-radius: var(--radius-5);
  }
}

@media (min-width: 1280px) {
  .rt-BaseDialogContent:where(.lg\:rt-r-size-1) {
    --dialog-content-padding: var(--space-3);
    border-radius: var(--radius-4);
  }

  .rt-BaseDialogContent:where(.lg\:rt-r-size-2) {
    --dialog-content-padding: var(--space-4);
    border-radius: var(--radius-4);
  }

  .rt-BaseDialogContent:where(.lg\:rt-r-size-3) {
    --dialog-content-padding: var(--space-5);
    border-radius: var(--radius-5);
  }

  .rt-BaseDialogContent:where(.lg\:rt-r-size-4) {
    --dialog-content-padding: var(--space-6);
    border-radius: var(--radius-5);
  }
}

@media (min-width: 1640px) {
  .rt-BaseDialogContent:where(.xl\:rt-r-size-1) {
    --dialog-content-padding: var(--space-3);
    border-radius: var(--radius-4);
  }

  .rt-BaseDialogContent:where(.xl\:rt-r-size-2) {
    --dialog-content-padding: var(--space-4);
    border-radius: var(--radius-4);
  }

  .rt-BaseDialogContent:where(.xl\:rt-r-size-3) {
    --dialog-content-padding: var(--space-5);
    border-radius: var(--radius-5);
  }

  .rt-BaseDialogContent:where(.xl\:rt-r-size-4) {
    --dialog-content-padding: var(--space-6);
    border-radius: var(--radius-5);
  }
}

@media (prefers-reduced-motion: no-preference) {
  @keyframes rt-dialog-overlay-no-op {
    from {
      opacity: 1;
    }

    to {
      opacity: 1;
    }
  }

  @keyframes rt-dialog-content-show {
    from {
      opacity: 0;
      transform: translateY(5px)scale(.97);
    }

    to {
      opacity: 1;
      transform: translateY(0)scale(1);
    }
  }

  @keyframes rt-dialog-content-hide {
    from {
      opacity: 1;
      transform: translateY(0)scale(1);
    }

    to {
      opacity: 0;
      transform: translateY(5px)scale(.99);
    }
  }

  .rt-BaseDialogOverlay:where([data-state="closed"]) {
    animation: .16s cubic-bezier(.16, 1, .3, 1) rt-dialog-overlay-no-op;
  }

  .rt-BaseDialogOverlay:where([data-state="open"]):before {
    animation: .2s cubic-bezier(.16, 1, .3, 1) rt-fade-in;
  }

  .rt-BaseDialogOverlay:where([data-state="closed"]):before {
    opacity: 0;
    animation: .16s cubic-bezier(.16, 1, .3, 1) rt-fade-out;
  }

  .rt-BaseDialogContent:where([data-state="open"]) {
    animation: .2s cubic-bezier(.16, 1, .3, 1) rt-dialog-content-show;
  }

  .rt-BaseDialogContent:where([data-state="closed"]) {
    opacity: 0;
    animation: .1s cubic-bezier(.16, 1, .3, 1) rt-dialog-content-hide;
  }
}

.rt-AvatarRoot {
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  width: var(--avatar-size);
  height: var(--avatar-size);
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  display: inline-flex;
}

.rt-AvatarImage {
  object-fit: cover;
  border-radius: inherit;
  width: 100%;
  height: 100%;
}

.rt-AvatarFallback {
  font-family: var(--default-font-family);
  font-weight: var(--font-weight-medium);
  z-index: 0;
  border-radius: inherit;
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-style: normal;
  line-height: 1;
  display: flex;
}

.rt-AvatarFallback:where(.rt-one-letter) {
  font-size: var(--avatar-fallback-one-letter-font-size);
}

.rt-AvatarFallback:where(.rt-two-letters) {
  font-size: var(--avatar-fallback-two-letters-font-size, var(--avatar-fallback-one-letter-font-size));
}

.rt-AvatarRoot:where(.rt-r-size-1) {
  --avatar-size: var(--space-5);
  --avatar-fallback-one-letter-font-size: var(--font-size-2);
  --avatar-fallback-two-letters-font-size: var(--font-size-1);
  border-radius: max(var(--radius-2), var(--radius-full));
  letter-spacing: var(--letter-spacing-1);
}

.rt-AvatarRoot:where(.rt-r-size-2) {
  --avatar-size: var(--space-6);
  --avatar-fallback-one-letter-font-size: var(--font-size-3);
  --avatar-fallback-two-letters-font-size: var(--font-size-2);
  border-radius: max(var(--radius-2), var(--radius-full));
  letter-spacing: var(--letter-spacing-2);
}

.rt-AvatarRoot:where(.rt-r-size-3) {
  --avatar-size: var(--space-7);
  --avatar-fallback-one-letter-font-size: var(--font-size-4);
  --avatar-fallback-two-letters-font-size: var(--font-size-3);
  border-radius: max(var(--radius-3), var(--radius-full));
  letter-spacing: var(--letter-spacing-3);
}

.rt-AvatarRoot:where(.rt-r-size-4) {
  --avatar-size: var(--space-8);
  --avatar-fallback-one-letter-font-size: var(--font-size-5);
  --avatar-fallback-two-letters-font-size: var(--font-size-4);
  border-radius: max(var(--radius-3), var(--radius-full));
  letter-spacing: var(--letter-spacing-4);
}

.rt-AvatarRoot:where(.rt-r-size-5) {
  --avatar-size: var(--space-9);
  --avatar-fallback-one-letter-font-size: var(--font-size-6);
  border-radius: max(var(--radius-4), var(--radius-full));
  letter-spacing: var(--letter-spacing-6);
}

.rt-AvatarRoot:where(.rt-r-size-6) {
  --avatar-size: 80px;
  --avatar-fallback-one-letter-font-size: var(--font-size-7);
  border-radius: max(var(--radius-5), var(--radius-full));
  letter-spacing: var(--letter-spacing-7);
}

.rt-AvatarRoot:where(.rt-r-size-7) {
  --avatar-size: 96px;
  --avatar-fallback-one-letter-font-size: var(--font-size-7);
  border-radius: max(var(--radius-5), var(--radius-full));
  letter-spacing: var(--letter-spacing-7);
}

.rt-AvatarRoot:where(.rt-r-size-8) {
  --avatar-size: 128px;
  --avatar-fallback-one-letter-font-size: var(--font-size-8);
  border-radius: max(var(--radius-6), var(--radius-full));
  letter-spacing: var(--letter-spacing-8);
}

.rt-AvatarRoot:where(.rt-r-size-9) {
  --avatar-size: 160px;
  --avatar-fallback-one-letter-font-size: var(--font-size-9);
  border-radius: max(var(--radius-6), var(--radius-full));
  letter-spacing: var(--letter-spacing-9);
}

@media (min-width: 520px) {
  .rt-AvatarRoot:where(.xs\:rt-r-size-1) {
    --avatar-size: var(--space-5);
    --avatar-fallback-one-letter-font-size: var(--font-size-2);
    --avatar-fallback-two-letters-font-size: var(--font-size-1);
    border-radius: max(var(--radius-2), var(--radius-full));
    letter-spacing: var(--letter-spacing-1);
  }

  .rt-AvatarRoot:where(.xs\:rt-r-size-2) {
    --avatar-size: var(--space-6);
    --avatar-fallback-one-letter-font-size: var(--font-size-3);
    --avatar-fallback-two-letters-font-size: var(--font-size-2);
    border-radius: max(var(--radius-2), var(--radius-full));
    letter-spacing: var(--letter-spacing-2);
  }

  .rt-AvatarRoot:where(.xs\:rt-r-size-3) {
    --avatar-size: var(--space-7);
    --avatar-fallback-one-letter-font-size: var(--font-size-4);
    --avatar-fallback-two-letters-font-size: var(--font-size-3);
    border-radius: max(var(--radius-3), var(--radius-full));
    letter-spacing: var(--letter-spacing-3);
  }

  .rt-AvatarRoot:where(.xs\:rt-r-size-4) {
    --avatar-size: var(--space-8);
    --avatar-fallback-one-letter-font-size: var(--font-size-5);
    --avatar-fallback-two-letters-font-size: var(--font-size-4);
    border-radius: max(var(--radius-3), var(--radius-full));
    letter-spacing: var(--letter-spacing-4);
  }

  .rt-AvatarRoot:where(.xs\:rt-r-size-5) {
    --avatar-size: var(--space-9);
    --avatar-fallback-one-letter-font-size: var(--font-size-6);
    border-radius: max(var(--radius-4), var(--radius-full));
    letter-spacing: var(--letter-spacing-6);
  }

  .rt-AvatarRoot:where(.xs\:rt-r-size-6) {
    --avatar-size: 80px;
    --avatar-fallback-one-letter-font-size: var(--font-size-7);
    border-radius: max(var(--radius-5), var(--radius-full));
    letter-spacing: var(--letter-spacing-7);
  }

  .rt-AvatarRoot:where(.xs\:rt-r-size-7) {
    --avatar-size: 96px;
    --avatar-fallback-one-letter-font-size: var(--font-size-7);
    border-radius: max(var(--radius-5), var(--radius-full));
    letter-spacing: var(--letter-spacing-7);
  }

  .rt-AvatarRoot:where(.xs\:rt-r-size-8) {
    --avatar-size: 128px;
    --avatar-fallback-one-letter-font-size: var(--font-size-8);
    border-radius: max(var(--radius-6), var(--radius-full));
    letter-spacing: var(--letter-spacing-8);
  }

  .rt-AvatarRoot:where(.xs\:rt-r-size-9) {
    --avatar-size: 160px;
    --avatar-fallback-one-letter-font-size: var(--font-size-9);
    border-radius: max(var(--radius-6), var(--radius-full));
    letter-spacing: var(--letter-spacing-9);
  }
}

@media (min-width: 768px) {
  .rt-AvatarRoot:where(.sm\:rt-r-size-1) {
    --avatar-size: var(--space-5);
    --avatar-fallback-one-letter-font-size: var(--font-size-2);
    --avatar-fallback-two-letters-font-size: var(--font-size-1);
    border-radius: max(var(--radius-2), var(--radius-full));
    letter-spacing: var(--letter-spacing-1);
  }

  .rt-AvatarRoot:where(.sm\:rt-r-size-2) {
    --avatar-size: var(--space-6);
    --avatar-fallback-one-letter-font-size: var(--font-size-3);
    --avatar-fallback-two-letters-font-size: var(--font-size-2);
    border-radius: max(var(--radius-2), var(--radius-full));
    letter-spacing: var(--letter-spacing-2);
  }

  .rt-AvatarRoot:where(.sm\:rt-r-size-3) {
    --avatar-size: var(--space-7);
    --avatar-fallback-one-letter-font-size: var(--font-size-4);
    --avatar-fallback-two-letters-font-size: var(--font-size-3);
    border-radius: max(var(--radius-3), var(--radius-full));
    letter-spacing: var(--letter-spacing-3);
  }

  .rt-AvatarRoot:where(.sm\:rt-r-size-4) {
    --avatar-size: var(--space-8);
    --avatar-fallback-one-letter-font-size: var(--font-size-5);
    --avatar-fallback-two-letters-font-size: var(--font-size-4);
    border-radius: max(var(--radius-3), var(--radius-full));
    letter-spacing: var(--letter-spacing-4);
  }

  .rt-AvatarRoot:where(.sm\:rt-r-size-5) {
    --avatar-size: var(--space-9);
    --avatar-fallback-one-letter-font-size: var(--font-size-6);
    border-radius: max(var(--radius-4), var(--radius-full));
    letter-spacing: var(--letter-spacing-6);
  }

  .rt-AvatarRoot:where(.sm\:rt-r-size-6) {
    --avatar-size: 80px;
    --avatar-fallback-one-letter-font-size: var(--font-size-7);
    border-radius: max(var(--radius-5), var(--radius-full));
    letter-spacing: var(--letter-spacing-7);
  }

  .rt-AvatarRoot:where(.sm\:rt-r-size-7) {
    --avatar-size: 96px;
    --avatar-fallback-one-letter-font-size: var(--font-size-7);
    border-radius: max(var(--radius-5), var(--radius-full));
    letter-spacing: var(--letter-spacing-7);
  }

  .rt-AvatarRoot:where(.sm\:rt-r-size-8) {
    --avatar-size: 128px;
    --avatar-fallback-one-letter-font-size: var(--font-size-8);
    border-radius: max(var(--radius-6), var(--radius-full));
    letter-spacing: var(--letter-spacing-8);
  }

  .rt-AvatarRoot:where(.sm\:rt-r-size-9) {
    --avatar-size: 160px;
    --avatar-fallback-one-letter-font-size: var(--font-size-9);
    border-radius: max(var(--radius-6), var(--radius-full));
    letter-spacing: var(--letter-spacing-9);
  }
}

@media (min-width: 1024px) {
  .rt-AvatarRoot:where(.md\:rt-r-size-1) {
    --avatar-size: var(--space-5);
    --avatar-fallback-one-letter-font-size: var(--font-size-2);
    --avatar-fallback-two-letters-font-size: var(--font-size-1);
    border-radius: max(var(--radius-2), var(--radius-full));
    letter-spacing: var(--letter-spacing-1);
  }

  .rt-AvatarRoot:where(.md\:rt-r-size-2) {
    --avatar-size: var(--space-6);
    --avatar-fallback-one-letter-font-size: var(--font-size-3);
    --avatar-fallback-two-letters-font-size: var(--font-size-2);
    border-radius: max(var(--radius-2), var(--radius-full));
    letter-spacing: var(--letter-spacing-2);
  }

  .rt-AvatarRoot:where(.md\:rt-r-size-3) {
    --avatar-size: var(--space-7);
    --avatar-fallback-one-letter-font-size: var(--font-size-4);
    --avatar-fallback-two-letters-font-size: var(--font-size-3);
    border-radius: max(var(--radius-3), var(--radius-full));
    letter-spacing: var(--letter-spacing-3);
  }

  .rt-AvatarRoot:where(.md\:rt-r-size-4) {
    --avatar-size: var(--space-8);
    --avatar-fallback-one-letter-font-size: var(--font-size-5);
    --avatar-fallback-two-letters-font-size: var(--font-size-4);
    border-radius: max(var(--radius-3), var(--radius-full));
    letter-spacing: var(--letter-spacing-4);
  }

  .rt-AvatarRoot:where(.md\:rt-r-size-5) {
    --avatar-size: var(--space-9);
    --avatar-fallback-one-letter-font-size: var(--font-size-6);
    border-radius: max(var(--radius-4), var(--radius-full));
    letter-spacing: var(--letter-spacing-6);
  }

  .rt-AvatarRoot:where(.md\:rt-r-size-6) {
    --avatar-size: 80px;
    --avatar-fallback-one-letter-font-size: var(--font-size-7);
    border-radius: max(var(--radius-5), var(--radius-full));
    letter-spacing: var(--letter-spacing-7);
  }

  .rt-AvatarRoot:where(.md\:rt-r-size-7) {
    --avatar-size: 96px;
    --avatar-fallback-one-letter-font-size: var(--font-size-7);
    border-radius: max(var(--radius-5), var(--radius-full));
    letter-spacing: var(--letter-spacing-7);
  }

  .rt-AvatarRoot:where(.md\:rt-r-size-8) {
    --avatar-size: 128px;
    --avatar-fallback-one-letter-font-size: var(--font-size-8);
    border-radius: max(var(--radius-6), var(--radius-full));
    letter-spacing: var(--letter-spacing-8);
  }

  .rt-AvatarRoot:where(.md\:rt-r-size-9) {
    --avatar-size: 160px;
    --avatar-fallback-one-letter-font-size: var(--font-size-9);
    border-radius: max(var(--radius-6), var(--radius-full));
    letter-spacing: var(--letter-spacing-9);
  }
}

@media (min-width: 1280px) {
  .rt-AvatarRoot:where(.lg\:rt-r-size-1) {
    --avatar-size: var(--space-5);
    --avatar-fallback-one-letter-font-size: var(--font-size-2);
    --avatar-fallback-two-letters-font-size: var(--font-size-1);
    border-radius: max(var(--radius-2), var(--radius-full));
    letter-spacing: var(--letter-spacing-1);
  }

  .rt-AvatarRoot:where(.lg\:rt-r-size-2) {
    --avatar-size: var(--space-6);
    --avatar-fallback-one-letter-font-size: var(--font-size-3);
    --avatar-fallback-two-letters-font-size: var(--font-size-2);
    border-radius: max(var(--radius-2), var(--radius-full));
    letter-spacing: var(--letter-spacing-2);
  }

  .rt-AvatarRoot:where(.lg\:rt-r-size-3) {
    --avatar-size: var(--space-7);
    --avatar-fallback-one-letter-font-size: var(--font-size-4);
    --avatar-fallback-two-letters-font-size: var(--font-size-3);
    border-radius: max(var(--radius-3), var(--radius-full));
    letter-spacing: var(--letter-spacing-3);
  }

  .rt-AvatarRoot:where(.lg\:rt-r-size-4) {
    --avatar-size: var(--space-8);
    --avatar-fallback-one-letter-font-size: var(--font-size-5);
    --avatar-fallback-two-letters-font-size: var(--font-size-4);
    border-radius: max(var(--radius-3), var(--radius-full));
    letter-spacing: var(--letter-spacing-4);
  }

  .rt-AvatarRoot:where(.lg\:rt-r-size-5) {
    --avatar-size: var(--space-9);
    --avatar-fallback-one-letter-font-size: var(--font-size-6);
    border-radius: max(var(--radius-4), var(--radius-full));
    letter-spacing: var(--letter-spacing-6);
  }

  .rt-AvatarRoot:where(.lg\:rt-r-size-6) {
    --avatar-size: 80px;
    --avatar-fallback-one-letter-font-size: var(--font-size-7);
    border-radius: max(var(--radius-5), var(--radius-full));
    letter-spacing: var(--letter-spacing-7);
  }

  .rt-AvatarRoot:where(.lg\:rt-r-size-7) {
    --avatar-size: 96px;
    --avatar-fallback-one-letter-font-size: var(--font-size-7);
    border-radius: max(var(--radius-5), var(--radius-full));
    letter-spacing: var(--letter-spacing-7);
  }

  .rt-AvatarRoot:where(.lg\:rt-r-size-8) {
    --avatar-size: 128px;
    --avatar-fallback-one-letter-font-size: var(--font-size-8);
    border-radius: max(var(--radius-6), var(--radius-full));
    letter-spacing: var(--letter-spacing-8);
  }

  .rt-AvatarRoot:where(.lg\:rt-r-size-9) {
    --avatar-size: 160px;
    --avatar-fallback-one-letter-font-size: var(--font-size-9);
    border-radius: max(var(--radius-6), var(--radius-full));
    letter-spacing: var(--letter-spacing-9);
  }
}

@media (min-width: 1640px) {
  .rt-AvatarRoot:where(.xl\:rt-r-size-1) {
    --avatar-size: var(--space-5);
    --avatar-fallback-one-letter-font-size: var(--font-size-2);
    --avatar-fallback-two-letters-font-size: var(--font-size-1);
    border-radius: max(var(--radius-2), var(--radius-full));
    letter-spacing: var(--letter-spacing-1);
  }

  .rt-AvatarRoot:where(.xl\:rt-r-size-2) {
    --avatar-size: var(--space-6);
    --avatar-fallback-one-letter-font-size: var(--font-size-3);
    --avatar-fallback-two-letters-font-size: var(--font-size-2);
    border-radius: max(var(--radius-2), var(--radius-full));
    letter-spacing: var(--letter-spacing-2);
  }

  .rt-AvatarRoot:where(.xl\:rt-r-size-3) {
    --avatar-size: var(--space-7);
    --avatar-fallback-one-letter-font-size: var(--font-size-4);
    --avatar-fallback-two-letters-font-size: var(--font-size-3);
    border-radius: max(var(--radius-3), var(--radius-full));
    letter-spacing: var(--letter-spacing-3);
  }

  .rt-AvatarRoot:where(.xl\:rt-r-size-4) {
    --avatar-size: var(--space-8);
    --avatar-fallback-one-letter-font-size: var(--font-size-5);
    --avatar-fallback-two-letters-font-size: var(--font-size-4);
    border-radius: max(var(--radius-3), var(--radius-full));
    letter-spacing: var(--letter-spacing-4);
  }

  .rt-AvatarRoot:where(.xl\:rt-r-size-5) {
    --avatar-size: var(--space-9);
    --avatar-fallback-one-letter-font-size: var(--font-size-6);
    border-radius: max(var(--radius-4), var(--radius-full));
    letter-spacing: var(--letter-spacing-6);
  }

  .rt-AvatarRoot:where(.xl\:rt-r-size-6) {
    --avatar-size: 80px;
    --avatar-fallback-one-letter-font-size: var(--font-size-7);
    border-radius: max(var(--radius-5), var(--radius-full));
    letter-spacing: var(--letter-spacing-7);
  }

  .rt-AvatarRoot:where(.xl\:rt-r-size-7) {
    --avatar-size: 96px;
    --avatar-fallback-one-letter-font-size: var(--font-size-7);
    border-radius: max(var(--radius-5), var(--radius-full));
    letter-spacing: var(--letter-spacing-7);
  }

  .rt-AvatarRoot:where(.xl\:rt-r-size-8) {
    --avatar-size: 128px;
    --avatar-fallback-one-letter-font-size: var(--font-size-8);
    border-radius: max(var(--radius-6), var(--radius-full));
    letter-spacing: var(--letter-spacing-8);
  }

  .rt-AvatarRoot:where(.xl\:rt-r-size-9) {
    --avatar-size: 160px;
    --avatar-fallback-one-letter-font-size: var(--font-size-9);
    border-radius: max(var(--radius-6), var(--radius-full));
    letter-spacing: var(--letter-spacing-9);
  }
}

.rt-AvatarRoot:where(.rt-variant-solid) :where(.rt-AvatarFallback) {
  background-color: var(--accent-9);
  color: var(--accent-contrast);
}

.rt-AvatarRoot:where(.rt-variant-solid):where(.rt-high-contrast) :where(.rt-AvatarFallback) {
  background-color: var(--accent-12);
  color: var(--accent-1);
}

.rt-AvatarRoot:where(.rt-variant-soft) :where(.rt-AvatarFallback) {
  background-color: var(--accent-a3);
  color: var(--accent-a11);
}

.rt-AvatarRoot:where(.rt-variant-soft):where(.rt-high-contrast) :where(.rt-AvatarFallback) {
  color: var(--accent-12);
}

.rt-Badge {
  white-space: nowrap;
  font-family: var(--default-font-family);
  font-weight: var(--font-weight-medium);
  flex-shrink: 0;
  align-items: center;
  height: -moz-fit-content;
  height: fit-content;
  font-style: normal;
  line-height: 1;
  display: inline-flex;
}

.rt-Badge:where(.rt-r-size-1) {
  font-size: var(--font-size-1);
  line-height: var(--line-height-1);
  letter-spacing: var(--letter-spacing-1);
  padding: calc(var(--space-1) * .5) calc(var(--space-1) * 1.5);
  gap: calc(var(--space-1) * 1.5);
  border-radius: max(var(--radius-1), var(--radius-full));
}

.rt-Badge:where(.rt-r-size-2) {
  font-size: var(--font-size-1);
  line-height: var(--line-height-1);
  letter-spacing: var(--letter-spacing-1);
  padding: var(--space-1) var(--space-2);
  gap: calc(var(--space-1) * 1.5);
  border-radius: max(var(--radius-2), var(--radius-full));
}

.rt-Badge:where(.rt-r-size-3) {
  font-size: var(--font-size-2);
  line-height: var(--line-height-2);
  letter-spacing: var(--letter-spacing-2);
  padding: var(--space-1) calc(var(--space-2) * 1.25);
  gap: var(--space-2);
  border-radius: max(var(--radius-2), var(--radius-full));
}

@media (min-width: 520px) {
  .rt-Badge:where(.xs\:rt-r-size-1) {
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
    padding: calc(var(--space-1) * .5) calc(var(--space-1) * 1.5);
    gap: calc(var(--space-1) * 1.5);
    border-radius: max(var(--radius-1), var(--radius-full));
  }

  .rt-Badge:where(.xs\:rt-r-size-2) {
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
    padding: var(--space-1) var(--space-2);
    gap: calc(var(--space-1) * 1.5);
    border-radius: max(var(--radius-2), var(--radius-full));
  }

  .rt-Badge:where(.xs\:rt-r-size-3) {
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    letter-spacing: var(--letter-spacing-2);
    padding: var(--space-1) calc(var(--space-2) * 1.25);
    gap: var(--space-2);
    border-radius: max(var(--radius-2), var(--radius-full));
  }
}

@media (min-width: 768px) {
  .rt-Badge:where(.sm\:rt-r-size-1) {
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
    padding: calc(var(--space-1) * .5) calc(var(--space-1) * 1.5);
    gap: calc(var(--space-1) * 1.5);
    border-radius: max(var(--radius-1), var(--radius-full));
  }

  .rt-Badge:where(.sm\:rt-r-size-2) {
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
    padding: var(--space-1) var(--space-2);
    gap: calc(var(--space-1) * 1.5);
    border-radius: max(var(--radius-2), var(--radius-full));
  }

  .rt-Badge:where(.sm\:rt-r-size-3) {
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    letter-spacing: var(--letter-spacing-2);
    padding: var(--space-1) calc(var(--space-2) * 1.25);
    gap: var(--space-2);
    border-radius: max(var(--radius-2), var(--radius-full));
  }
}

@media (min-width: 1024px) {
  .rt-Badge:where(.md\:rt-r-size-1) {
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
    padding: calc(var(--space-1) * .5) calc(var(--space-1) * 1.5);
    gap: calc(var(--space-1) * 1.5);
    border-radius: max(var(--radius-1), var(--radius-full));
  }

  .rt-Badge:where(.md\:rt-r-size-2) {
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
    padding: var(--space-1) var(--space-2);
    gap: calc(var(--space-1) * 1.5);
    border-radius: max(var(--radius-2), var(--radius-full));
  }

  .rt-Badge:where(.md\:rt-r-size-3) {
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    letter-spacing: var(--letter-spacing-2);
    padding: var(--space-1) calc(var(--space-2) * 1.25);
    gap: var(--space-2);
    border-radius: max(var(--radius-2), var(--radius-full));
  }
}

@media (min-width: 1280px) {
  .rt-Badge:where(.lg\:rt-r-size-1) {
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
    padding: calc(var(--space-1) * .5) calc(var(--space-1) * 1.5);
    gap: calc(var(--space-1) * 1.5);
    border-radius: max(var(--radius-1), var(--radius-full));
  }

  .rt-Badge:where(.lg\:rt-r-size-2) {
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
    padding: var(--space-1) var(--space-2);
    gap: calc(var(--space-1) * 1.5);
    border-radius: max(var(--radius-2), var(--radius-full));
  }

  .rt-Badge:where(.lg\:rt-r-size-3) {
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    letter-spacing: var(--letter-spacing-2);
    padding: var(--space-1) calc(var(--space-2) * 1.25);
    gap: var(--space-2);
    border-radius: max(var(--radius-2), var(--radius-full));
  }
}

@media (min-width: 1640px) {
  .rt-Badge:where(.xl\:rt-r-size-1) {
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
    padding: calc(var(--space-1) * .5) calc(var(--space-1) * 1.5);
    gap: calc(var(--space-1) * 1.5);
    border-radius: max(var(--radius-1), var(--radius-full));
  }

  .rt-Badge:where(.xl\:rt-r-size-2) {
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
    padding: var(--space-1) var(--space-2);
    gap: calc(var(--space-1) * 1.5);
    border-radius: max(var(--radius-2), var(--radius-full));
  }

  .rt-Badge:where(.xl\:rt-r-size-3) {
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    letter-spacing: var(--letter-spacing-2);
    padding: var(--space-1) calc(var(--space-2) * 1.25);
    gap: var(--space-2);
    border-radius: max(var(--radius-2), var(--radius-full));
  }
}

.rt-Badge:where(.rt-variant-solid) {
  background-color: var(--accent-9);
  color: var(--accent-contrast);
}

.rt-Badge:where(.rt-variant-solid)::selection {
  background-color: var(--accent-7);
  color: var(--accent-12);
}

.rt-Badge:where(.rt-variant-solid):where(.rt-high-contrast) {
  background-color: var(--accent-12);
  color: var(--accent-1);
}

.rt-Badge:where(.rt-variant-solid):where(.rt-high-contrast)::selection {
  background-color: var(--accent-a11);
  color: var(--accent-1);
}

.rt-Badge:where(.rt-variant-surface) {
  background-color: var(--accent-surface);
  box-shadow: inset 0 0 0 1px var(--accent-a6);
  color: var(--accent-a11);
}

.rt-Badge:where(.rt-variant-surface):where(.rt-high-contrast) {
  color: var(--accent-12);
}

.rt-Badge:where(.rt-variant-soft) {
  background-color: var(--accent-a3);
  color: var(--accent-a11);
}

.rt-Badge:where(.rt-variant-soft):where(.rt-high-contrast) {
  color: var(--accent-12);
}

.rt-Badge:where(.rt-variant-outline) {
  box-shadow: inset 0 0 0 1px var(--accent-a8);
  color: var(--accent-a11);
}

.rt-Badge:where(.rt-variant-outline):where(.rt-high-contrast) {
  box-shadow: inset 0 0 0 1px var(--accent-a7), inset 0 0 0 1px var(--gray-a11);
  color: var(--accent-12);
}

.rt-Blockquote {
  box-sizing: border-box;
  border-left: max(var(--space-1), .25em) solid var(--accent-a6);
  padding-left: min(var(--space-5), max(var(--space-3), .5em));
}

.rt-BaseButton {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  vertical-align: top;
  font-family: var(--default-font-family);
  text-align: center;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  font-style: normal;
  display: inline-flex;
}

.rt-BaseButton:where([data-disabled]) {
  --spinner-opacity: 1;
}

.rt-BaseButton:where(.rt-loading) {
  position: relative;
}

.rt-BaseButton:where(:not(.rt-variant-ghost)) {
  height: var(--base-button-height);
}

.rt-BaseButton:where(.rt-variant-ghost) {
  box-sizing: content-box;
  height: -moz-fit-content;
  height: fit-content;
}

.rt-BaseButton:where(.rt-r-size-1) {
  --base-button-classic-active-padding-top: 1px;
  --base-button-height: var(--space-5);
  border-radius: max(var(--radius-1), var(--radius-full));
}

.rt-BaseButton:where(.rt-r-size-2) {
  --base-button-classic-active-padding-top: 2px;
  --base-button-height: var(--space-6);
  border-radius: max(var(--radius-2), var(--radius-full));
}

.rt-BaseButton:where(.rt-r-size-3) {
  --base-button-classic-active-padding-top: 2px;
  --base-button-height: var(--space-7);
  border-radius: max(var(--radius-3), var(--radius-full));
}

.rt-BaseButton:where(.rt-r-size-4) {
  --base-button-classic-active-padding-top: 2px;
  --base-button-height: var(--space-8);
  border-radius: max(var(--radius-4), var(--radius-full));
}

@media (min-width: 520px) {
  .rt-BaseButton:where(.xs\:rt-r-size-1) {
    --base-button-classic-active-padding-top: 1px;
    --base-button-height: var(--space-5);
    border-radius: max(var(--radius-1), var(--radius-full));
  }

  .rt-BaseButton:where(.xs\:rt-r-size-2) {
    --base-button-classic-active-padding-top: 2px;
    --base-button-height: var(--space-6);
    border-radius: max(var(--radius-2), var(--radius-full));
  }

  .rt-BaseButton:where(.xs\:rt-r-size-3) {
    --base-button-classic-active-padding-top: 2px;
    --base-button-height: var(--space-7);
    border-radius: max(var(--radius-3), var(--radius-full));
  }

  .rt-BaseButton:where(.xs\:rt-r-size-4) {
    --base-button-classic-active-padding-top: 2px;
    --base-button-height: var(--space-8);
    border-radius: max(var(--radius-4), var(--radius-full));
  }
}

@media (min-width: 768px) {
  .rt-BaseButton:where(.sm\:rt-r-size-1) {
    --base-button-classic-active-padding-top: 1px;
    --base-button-height: var(--space-5);
    border-radius: max(var(--radius-1), var(--radius-full));
  }

  .rt-BaseButton:where(.sm\:rt-r-size-2) {
    --base-button-classic-active-padding-top: 2px;
    --base-button-height: var(--space-6);
    border-radius: max(var(--radius-2), var(--radius-full));
  }

  .rt-BaseButton:where(.sm\:rt-r-size-3) {
    --base-button-classic-active-padding-top: 2px;
    --base-button-height: var(--space-7);
    border-radius: max(var(--radius-3), var(--radius-full));
  }

  .rt-BaseButton:where(.sm\:rt-r-size-4) {
    --base-button-classic-active-padding-top: 2px;
    --base-button-height: var(--space-8);
    border-radius: max(var(--radius-4), var(--radius-full));
  }
}

@media (min-width: 1024px) {
  .rt-BaseButton:where(.md\:rt-r-size-1) {
    --base-button-classic-active-padding-top: 1px;
    --base-button-height: var(--space-5);
    border-radius: max(var(--radius-1), var(--radius-full));
  }

  .rt-BaseButton:where(.md\:rt-r-size-2) {
    --base-button-classic-active-padding-top: 2px;
    --base-button-height: var(--space-6);
    border-radius: max(var(--radius-2), var(--radius-full));
  }

  .rt-BaseButton:where(.md\:rt-r-size-3) {
    --base-button-classic-active-padding-top: 2px;
    --base-button-height: var(--space-7);
    border-radius: max(var(--radius-3), var(--radius-full));
  }

  .rt-BaseButton:where(.md\:rt-r-size-4) {
    --base-button-classic-active-padding-top: 2px;
    --base-button-height: var(--space-8);
    border-radius: max(var(--radius-4), var(--radius-full));
  }
}

@media (min-width: 1280px) {
  .rt-BaseButton:where(.lg\:rt-r-size-1) {
    --base-button-classic-active-padding-top: 1px;
    --base-button-height: var(--space-5);
    border-radius: max(var(--radius-1), var(--radius-full));
  }

  .rt-BaseButton:where(.lg\:rt-r-size-2) {
    --base-button-classic-active-padding-top: 2px;
    --base-button-height: var(--space-6);
    border-radius: max(var(--radius-2), var(--radius-full));
  }

  .rt-BaseButton:where(.lg\:rt-r-size-3) {
    --base-button-classic-active-padding-top: 2px;
    --base-button-height: var(--space-7);
    border-radius: max(var(--radius-3), var(--radius-full));
  }

  .rt-BaseButton:where(.lg\:rt-r-size-4) {
    --base-button-classic-active-padding-top: 2px;
    --base-button-height: var(--space-8);
    border-radius: max(var(--radius-4), var(--radius-full));
  }
}

@media (min-width: 1640px) {
  .rt-BaseButton:where(.xl\:rt-r-size-1) {
    --base-button-classic-active-padding-top: 1px;
    --base-button-height: var(--space-5);
    border-radius: max(var(--radius-1), var(--radius-full));
  }

  .rt-BaseButton:where(.xl\:rt-r-size-2) {
    --base-button-classic-active-padding-top: 2px;
    --base-button-height: var(--space-6);
    border-radius: max(var(--radius-2), var(--radius-full));
  }

  .rt-BaseButton:where(.xl\:rt-r-size-3) {
    --base-button-classic-active-padding-top: 2px;
    --base-button-height: var(--space-7);
    border-radius: max(var(--radius-3), var(--radius-full));
  }

  .rt-BaseButton:where(.xl\:rt-r-size-4) {
    --base-button-classic-active-padding-top: 2px;
    --base-button-height: var(--space-8);
    border-radius: max(var(--radius-4), var(--radius-full));
  }
}

.rt-BaseButton:where(.rt-variant-classic) {
  background-color: var(--accent-9);
  color: var(--accent-contrast);
  z-index: 0;
  background-image: linear-gradient(to bottom, transparent 50%, var(--gray-a4)), linear-gradient(to bottom, transparent 50%, var(--accent-9) 80%);
  box-shadow: var(--base-button-classic-box-shadow-top), inset 0 0 0 1px var(--accent-9), var(--base-button-classic-box-shadow-bottom);
  position: relative;
}

.rt-BaseButton:where(.rt-variant-classic):after {
  content: "";
  border-radius: inherit;
  pointer-events: none;
  z-index: -1;
  border: var(--base-button-classic-after-inset) solid transparent;
  background-clip: content-box;
  background-color: inherit;
  background-image: linear-gradient(var(--black-a1), transparent, var(--white-a2));
  box-shadow: inset 0 2px 3px -1px var(--white-a4);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.rt-BaseButton:where(.rt-variant-classic):where(.rt-high-contrast) {
  background-color: var(--accent-12);
  color: var(--gray-1);
  background-image: linear-gradient(to bottom, transparent 50%, var(--gray-a4)), linear-gradient(to bottom, transparent 50%, var(--accent-12) 80%);
  box-shadow: var(--base-button-classic-box-shadow-top), inset 0 0 0 1px var(--accent-12), var(--base-button-classic-box-shadow-bottom);
}

.rt-BaseButton:where(.rt-variant-classic):where(.rt-high-contrast):after {
  background-image: linear-gradient(var(--black-a3), transparent, var(--white-a2));
}

@media (pointer: coarse) {
  .rt-BaseButton:where(.rt-variant-classic):where(:active:not([data-state="open"])) {
    outline: .5em solid var(--accent-a4);
    outline-offset: 0;
  }
}

.rt-BaseButton:where(.rt-variant-classic):where(:focus-visible) {
  outline: 2px solid var(--focus-8);
  outline-offset: 2px;
}

@media (hover: hover) {
  .rt-BaseButton:where(.rt-variant-classic):where(:hover):after {
    background-color: var(--accent-10);
    background-image: linear-gradient(var(--black-a2) -15%, transparent, var(--white-a3));
  }

  .rt-BaseButton:where(.rt-variant-classic):where(:hover):where(.rt-high-contrast) {
    filter: var(--base-button-classic-high-contrast-hover-filter);
  }

  .rt-BaseButton:where(.rt-variant-classic):where(:hover):where(.rt-high-contrast):after {
    background-color: var(--accent-12);
    background-image: linear-gradient(var(--black-a5), transparent, var(--white-a2));
  }
}

.rt-BaseButton:where(.rt-variant-classic):where([data-state="open"]):after {
  background-color: var(--accent-10);
  background-image: linear-gradient(var(--black-a2) -15%, transparent, var(--white-a3));
}

.rt-BaseButton:where(.rt-variant-classic):where([data-state="open"]):where(.rt-high-contrast) {
  filter: var(--base-button-classic-high-contrast-hover-filter);
}

.rt-BaseButton:where(.rt-variant-classic):where([data-state="open"]):where(.rt-high-contrast):after {
  background-color: var(--accent-12);
  background-image: linear-gradient(var(--black-a5), transparent, var(--white-a2));
}

.rt-BaseButton:where(.rt-variant-classic):where(:active:not(:-webkit-any([data-state="open"], [data-disabled]))) {
  background-color: var(--accent-9);
  background-image: linear-gradient(var(--black-a1), transparent);
  padding-top: var(--base-button-classic-active-padding-top);
  box-shadow: inset 0 4px 2px -2px var(--gray-a4), inset 0 1px 1px var(--gray-a7), inset 0 0 0 1px var(--gray-a5), inset 0 0 0 1px var(--accent-9), inset 0 3px 2px var(--gray-a3), inset 0 0 0 1px var(--white-a7), inset 0 -2px 1px var(--white-a5);
}

.rt-BaseButton:where(.rt-variant-classic):where(:active:not(:-moz-any([data-state="open"], [data-disabled]))) {
  background-color: var(--accent-9);
  background-image: linear-gradient(var(--black-a1), transparent);
  padding-top: var(--base-button-classic-active-padding-top);
  box-shadow: inset 0 4px 2px -2px var(--gray-a4), inset 0 1px 1px var(--gray-a7), inset 0 0 0 1px var(--gray-a5), inset 0 0 0 1px var(--accent-9), inset 0 3px 2px var(--gray-a3), inset 0 0 0 1px var(--white-a7), inset 0 -2px 1px var(--white-a5);
}

.rt-BaseButton:where(.rt-variant-classic):where(:active:not(:is([data-state="open"], [data-disabled]))) {
  background-color: var(--accent-9);
  background-image: linear-gradient(var(--black-a1), transparent);
  padding-top: var(--base-button-classic-active-padding-top);
  box-shadow: inset 0 4px 2px -2px var(--gray-a4), inset 0 1px 1px var(--gray-a7), inset 0 0 0 1px var(--gray-a5), inset 0 0 0 1px var(--accent-9), inset 0 3px 2px var(--gray-a3), inset 0 0 0 1px var(--white-a7), inset 0 -2px 1px var(--white-a5);
}

.rt-BaseButton:where(.rt-variant-classic):where(:active:not(:-webkit-any([data-state="open"], [data-disabled]))):after {
  box-shadow: none;
  background-color: inherit;
  background-image: linear-gradient(var(--black-a2), transparent, var(--white-a3));
}

.rt-BaseButton:where(.rt-variant-classic):where(:active:not(:-moz-any([data-state="open"], [data-disabled]))):after {
  box-shadow: none;
  background-color: inherit;
  background-image: linear-gradient(var(--black-a2), transparent, var(--white-a3));
}

.rt-BaseButton:where(.rt-variant-classic):where(:active:not(:is([data-state="open"], [data-disabled]))):after {
  box-shadow: none;
  background-color: inherit;
  background-image: linear-gradient(var(--black-a2), transparent, var(--white-a3));
}

.rt-BaseButton:where(.rt-variant-classic):where(:active:not(:-webkit-any([data-state="open"], [data-disabled]))):where(.rt-high-contrast) {
  background-color: var(--accent-12);
  filter: var(--base-button-classic-high-contrast-active-filter);
  box-shadow: var(--base-button__classic-active__shadow-front-layer), inset 0 0 0 1px var(--accent-12), var(--base-button__classic-active__shadow-bottom-layer);
}

.rt-BaseButton:where(.rt-variant-classic):where(:active:not(:-moz-any([data-state="open"], [data-disabled]))):where(.rt-high-contrast) {
  background-color: var(--accent-12);
  filter: var(--base-button-classic-high-contrast-active-filter);
  box-shadow: var(--base-button__classic-active__shadow-front-layer), inset 0 0 0 1px var(--accent-12), var(--base-button__classic-active__shadow-bottom-layer);
}

.rt-BaseButton:where(.rt-variant-classic):where(:active:not(:is([data-state="open"], [data-disabled]))):where(.rt-high-contrast) {
  background-color: var(--accent-12);
  filter: var(--base-button-classic-high-contrast-active-filter);
  box-shadow: var(--base-button__classic-active__shadow-front-layer), inset 0 0 0 1px var(--accent-12), var(--base-button__classic-active__shadow-bottom-layer);
}

.rt-BaseButton:where(.rt-variant-classic):where(:active:not(:-webkit-any([data-state="open"], [data-disabled]))):where(.rt-high-contrast):after {
  background-image: linear-gradient(var(--black-a5), transparent, var(--white-a3));
}

.rt-BaseButton:where(.rt-variant-classic):where(:active:not(:-moz-any([data-state="open"], [data-disabled]))):where(.rt-high-contrast):after {
  background-image: linear-gradient(var(--black-a5), transparent, var(--white-a3));
}

.rt-BaseButton:where(.rt-variant-classic):where(:active:not(:is([data-state="open"], [data-disabled]))):where(.rt-high-contrast):after {
  background-image: linear-gradient(var(--black-a5), transparent, var(--white-a3));
}

.rt-BaseButton:where(.rt-variant-classic):where([data-disabled]) {
  color: var(--gray-a8);
  background-color: var(--gray-2);
  box-shadow: var(--base-button-classic-disabled-box-shadow);
  filter: none;
  background-image: none;
  outline: none;
}

.rt-BaseButton:where(.rt-variant-classic):where([data-disabled]):after {
  box-shadow: none;
  background-color: var(--gray-a2);
  background-image: linear-gradient(var(--black-a1) -20%, transparent, var(--white-a1));
}

.rt-BaseButton:where(.rt-variant-solid) {
  background-color: var(--accent-9);
  color: var(--accent-contrast);
}

@media (hover: hover) {
  .rt-BaseButton:where(.rt-variant-solid):where(:hover) {
    background-color: var(--accent-10);
  }
}

.rt-BaseButton:where(.rt-variant-solid):where([data-state="open"]) {
  background-color: var(--accent-10);
}

.rt-BaseButton:where(.rt-variant-solid):where(:active:not([data-state="open"])) {
  background-color: var(--accent-10);
  filter: var(--base-button-solid-active-filter);
}

@media (pointer: coarse) {
  .rt-BaseButton:where(.rt-variant-solid):where(:active:not([data-state="open"])) {
    outline: .5em solid var(--accent-a4);
    outline-offset: 0;
  }
}

.rt-BaseButton:where(.rt-variant-solid):where(:focus-visible) {
  outline: 2px solid var(--focus-8);
  outline-offset: 2px;
}

.rt-BaseButton:where(.rt-variant-solid):where(.rt-high-contrast) {
  background-color: var(--accent-12);
  color: var(--gray-1);
}

@media (hover: hover) {
  .rt-BaseButton:where(.rt-variant-solid):where(.rt-high-contrast):where(:hover) {
    background-color: var(--accent-12);
    filter: var(--base-button-solid-high-contrast-hover-filter);
  }
}

.rt-BaseButton:where(.rt-variant-solid):where(.rt-high-contrast):where([data-state="open"]) {
  background-color: var(--accent-12);
  filter: var(--base-button-solid-high-contrast-hover-filter);
}

.rt-BaseButton:where(.rt-variant-solid):where(.rt-high-contrast):where(:active:not([data-state="open"])) {
  background-color: var(--accent-12);
  filter: var(--base-button-solid-high-contrast-active-filter);
}

.rt-BaseButton:where(.rt-variant-solid):where([data-disabled]) {
  color: var(--gray-a8);
  background-color: var(--gray-a3);
  filter: none;
  outline: none;
}

.rt-BaseButton:where(.rt-variant-soft, .rt-variant-ghost) {
  color: var(--accent-a11);
}

.rt-BaseButton:where(.rt-variant-soft, .rt-variant-ghost):where(.rt-high-contrast) {
  color: var(--accent-12);
}

.rt-BaseButton:where(.rt-variant-soft, .rt-variant-ghost):where([data-disabled]) {
  color: var(--gray-a8);
  background-color: var(--gray-a3);
}

.rt-BaseButton:where(.rt-variant-soft) {
  background-color: var(--accent-a3);
}

.rt-BaseButton:where(.rt-variant-soft):where(:focus-visible) {
  outline: 2px solid var(--accent-8);
  outline-offset: -1px;
}

@media (hover: hover) {
  .rt-BaseButton:where(.rt-variant-soft):where(:hover) {
    background-color: var(--accent-a4);
  }
}

.rt-BaseButton:where(.rt-variant-soft):where([data-state="open"]) {
  background-color: var(--accent-a4);
}

.rt-BaseButton:where(.rt-variant-soft):where(:active:not([data-state="open"])) {
  background-color: var(--accent-a5);
}

.rt-BaseButton:where(.rt-variant-soft):where([data-disabled]) {
  color: var(--gray-a8);
  background-color: var(--gray-a3);
}

@media (hover: hover) {
  .rt-BaseButton:where(.rt-variant-ghost):where(:hover) {
    background-color: var(--accent-a3);
  }
}

.rt-BaseButton:where(.rt-variant-ghost):where(:focus-visible) {
  outline: 2px solid var(--focus-8);
  outline-offset: -1px;
}

.rt-BaseButton:where(.rt-variant-ghost):where([data-state="open"]) {
  background-color: var(--accent-a3);
}

.rt-BaseButton:where(.rt-variant-ghost):where(:active:not([data-state="open"])) {
  background-color: var(--accent-a4);
}

.rt-BaseButton:where(.rt-variant-ghost):where([data-disabled]) {
  color: var(--gray-a8);
  background-color: rgba(0, 0, 0, 0);
}

.rt-BaseButton:where(.rt-variant-outline) {
  box-shadow: inset 0 0 0 1px var(--accent-a8);
  color: var(--accent-a11);
}

@media (hover: hover) {
  .rt-BaseButton:where(.rt-variant-outline):where(:hover) {
    background-color: var(--accent-a2);
  }
}

.rt-BaseButton:where(.rt-variant-outline):where([data-state="open"]) {
  background-color: var(--accent-a2);
}

.rt-BaseButton:where(.rt-variant-outline):where(:active:not([data-state="open"])) {
  background-color: var(--accent-a3);
}

.rt-BaseButton:where(.rt-variant-outline):where(:focus-visible) {
  outline: 2px solid var(--focus-8);
  outline-offset: -1px;
}

.rt-BaseButton:where(.rt-variant-outline):where(.rt-high-contrast) {
  box-shadow: inset 0 0 0 1px var(--accent-a7), inset 0 0 0 1px var(--gray-a11);
  color: var(--accent-12);
}

.rt-BaseButton:where(.rt-variant-outline):where([data-disabled]) {
  color: var(--gray-a8);
  box-shadow: inset 0 0 0 1px var(--gray-a7);
  background-color: rgba(0, 0, 0, 0);
}

.rt-BaseButton:where(.rt-variant-surface) {
  background-color: var(--accent-surface);
  box-shadow: inset 0 0 0 1px var(--accent-a7);
  color: var(--accent-a11);
}

@media (hover: hover) {
  .rt-BaseButton:where(.rt-variant-surface):where(:hover) {
    box-shadow: inset 0 0 0 1px var(--accent-a8);
  }
}

.rt-BaseButton:where(.rt-variant-surface):where([data-state="open"]) {
  box-shadow: inset 0 0 0 1px var(--accent-a8);
}

.rt-BaseButton:where(.rt-variant-surface):where(:active:not([data-state="open"])) {
  background-color: var(--accent-a3);
  box-shadow: inset 0 0 0 1px var(--accent-a8);
}

.rt-BaseButton:where(.rt-variant-surface):where(:focus-visible) {
  outline: 2px solid var(--focus-8);
  outline-offset: -1px;
}

.rt-BaseButton:where(.rt-variant-surface):where(.rt-high-contrast) {
  color: var(--accent-12);
}

.rt-BaseButton:where(.rt-variant-surface):where([data-disabled]) {
  color: var(--gray-a8);
  box-shadow: inset 0 0 0 1px var(--gray-a6);
  background-color: var(--gray-a2);
}

.rt-Button:where(:not(.rt-variant-ghost)) :where(svg) {
  opacity: .9;
}

.rt-Button:where(.rt-variant-ghost) {
  padding: var(--button-ghost-padding-y) var(--button-ghost-padding-x);
  --margin-top: 0px;
  --margin-right: 0px;
  --margin-bottom: 0px;
  --margin-left: 0px;
  --margin-top-override: calc(var(--margin-top)  - var(--button-ghost-padding-y));
  --margin-right-override: calc(var(--margin-right)  - var(--button-ghost-padding-x));
  --margin-bottom-override: calc(var(--margin-bottom)  - var(--button-ghost-padding-y));
  --margin-left-override: calc(var(--margin-left)  - var(--button-ghost-padding-x));
  margin: var(--margin-top-override) var(--margin-right-override) var(--margin-bottom-override) var(--margin-left-override);
}

:where(.rt-Button:where(.rt-variant-ghost)) > * {
  --margin-top-override: initial;
  --margin-right-override: initial;
  --margin-bottom-override: initial;
  --margin-left-override: initial;
}

.rt-Button:where(.rt-r-size-1) {
  gap: var(--space-1);
  font-size: var(--font-size-1);
  line-height: var(--line-height-1);
  letter-spacing: var(--letter-spacing-1);
}

.rt-Button:where(.rt-r-size-1):where(:not(.rt-variant-ghost)) {
  padding-left: var(--space-2);
  padding-right: var(--space-2);
}

.rt-Button:where(.rt-r-size-1):where(.rt-variant-ghost) {
  gap: var(--space-1);
  --button-ghost-padding-x: var(--space-2);
  --button-ghost-padding-y: var(--space-1);
}

.rt-Button:where(.rt-r-size-2) {
  gap: var(--space-2);
  font-size: var(--font-size-2);
  line-height: var(--line-height-2);
  letter-spacing: var(--letter-spacing-2);
}

.rt-Button:where(.rt-r-size-2):where(:not(.rt-variant-ghost)) {
  padding-left: var(--space-3);
  padding-right: var(--space-3);
}

.rt-Button:where(.rt-r-size-2):where(.rt-variant-ghost) {
  gap: var(--space-1);
  --button-ghost-padding-x: var(--space-2);
  --button-ghost-padding-y: var(--space-1);
}

.rt-Button:where(.rt-r-size-3) {
  gap: var(--space-3);
  font-size: var(--font-size-3);
  line-height: var(--line-height-3);
  letter-spacing: var(--letter-spacing-3);
}

.rt-Button:where(.rt-r-size-3):where(:not(.rt-variant-ghost)) {
  padding-left: var(--space-4);
  padding-right: var(--space-4);
}

.rt-Button:where(.rt-r-size-3):where(.rt-variant-ghost) {
  gap: var(--space-2);
  --button-ghost-padding-x: var(--space-3);
  --button-ghost-padding-y: calc(var(--space-1) * 1.5);
}

.rt-Button:where(.rt-r-size-4) {
  gap: var(--space-3);
  font-size: var(--font-size-4);
  line-height: var(--line-height-4);
  letter-spacing: var(--letter-spacing-4);
}

.rt-Button:where(.rt-r-size-4):where(:not(.rt-variant-ghost)) {
  padding-left: var(--space-5);
  padding-right: var(--space-5);
}

.rt-Button:where(.rt-r-size-4):where(.rt-variant-ghost) {
  gap: var(--space-2);
  --button-ghost-padding-x: var(--space-4);
  --button-ghost-padding-y: var(--space-2);
}

@media (min-width: 520px) {
  .rt-Button:where(.xs\:rt-r-size-1) {
    gap: var(--space-1);
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
  }

  .rt-Button:where(.xs\:rt-r-size-1):where(:not(.rt-variant-ghost)) {
    padding-left: var(--space-2);
    padding-right: var(--space-2);
  }

  .rt-Button:where(.xs\:rt-r-size-1):where(.rt-variant-ghost) {
    gap: var(--space-1);
    --button-ghost-padding-x: var(--space-2);
    --button-ghost-padding-y: var(--space-1);
  }

  .rt-Button:where(.xs\:rt-r-size-2) {
    gap: var(--space-2);
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    letter-spacing: var(--letter-spacing-2);
  }

  .rt-Button:where(.xs\:rt-r-size-2):where(:not(.rt-variant-ghost)) {
    padding-left: var(--space-3);
    padding-right: var(--space-3);
  }

  .rt-Button:where(.xs\:rt-r-size-2):where(.rt-variant-ghost) {
    gap: var(--space-1);
    --button-ghost-padding-x: var(--space-2);
    --button-ghost-padding-y: var(--space-1);
  }

  .rt-Button:where(.xs\:rt-r-size-3) {
    gap: var(--space-3);
    font-size: var(--font-size-3);
    line-height: var(--line-height-3);
    letter-spacing: var(--letter-spacing-3);
  }

  .rt-Button:where(.xs\:rt-r-size-3):where(:not(.rt-variant-ghost)) {
    padding-left: var(--space-4);
    padding-right: var(--space-4);
  }

  .rt-Button:where(.xs\:rt-r-size-3):where(.rt-variant-ghost) {
    gap: var(--space-2);
    --button-ghost-padding-x: var(--space-3);
    --button-ghost-padding-y: calc(var(--space-1) * 1.5);
  }

  .rt-Button:where(.xs\:rt-r-size-4) {
    gap: var(--space-3);
    font-size: var(--font-size-4);
    line-height: var(--line-height-4);
    letter-spacing: var(--letter-spacing-4);
  }

  .rt-Button:where(.xs\:rt-r-size-4):where(:not(.rt-variant-ghost)) {
    padding-left: var(--space-5);
    padding-right: var(--space-5);
  }

  .rt-Button:where(.xs\:rt-r-size-4):where(.rt-variant-ghost) {
    gap: var(--space-2);
    --button-ghost-padding-x: var(--space-4);
    --button-ghost-padding-y: var(--space-2);
  }
}

@media (min-width: 768px) {
  .rt-Button:where(.sm\:rt-r-size-1) {
    gap: var(--space-1);
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
  }

  .rt-Button:where(.sm\:rt-r-size-1):where(:not(.rt-variant-ghost)) {
    padding-left: var(--space-2);
    padding-right: var(--space-2);
  }

  .rt-Button:where(.sm\:rt-r-size-1):where(.rt-variant-ghost) {
    gap: var(--space-1);
    --button-ghost-padding-x: var(--space-2);
    --button-ghost-padding-y: var(--space-1);
  }

  .rt-Button:where(.sm\:rt-r-size-2) {
    gap: var(--space-2);
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    letter-spacing: var(--letter-spacing-2);
  }

  .rt-Button:where(.sm\:rt-r-size-2):where(:not(.rt-variant-ghost)) {
    padding-left: var(--space-3);
    padding-right: var(--space-3);
  }

  .rt-Button:where(.sm\:rt-r-size-2):where(.rt-variant-ghost) {
    gap: var(--space-1);
    --button-ghost-padding-x: var(--space-2);
    --button-ghost-padding-y: var(--space-1);
  }

  .rt-Button:where(.sm\:rt-r-size-3) {
    gap: var(--space-3);
    font-size: var(--font-size-3);
    line-height: var(--line-height-3);
    letter-spacing: var(--letter-spacing-3);
  }

  .rt-Button:where(.sm\:rt-r-size-3):where(:not(.rt-variant-ghost)) {
    padding-left: var(--space-4);
    padding-right: var(--space-4);
  }

  .rt-Button:where(.sm\:rt-r-size-3):where(.rt-variant-ghost) {
    gap: var(--space-2);
    --button-ghost-padding-x: var(--space-3);
    --button-ghost-padding-y: calc(var(--space-1) * 1.5);
  }

  .rt-Button:where(.sm\:rt-r-size-4) {
    gap: var(--space-3);
    font-size: var(--font-size-4);
    line-height: var(--line-height-4);
    letter-spacing: var(--letter-spacing-4);
  }

  .rt-Button:where(.sm\:rt-r-size-4):where(:not(.rt-variant-ghost)) {
    padding-left: var(--space-5);
    padding-right: var(--space-5);
  }

  .rt-Button:where(.sm\:rt-r-size-4):where(.rt-variant-ghost) {
    gap: var(--space-2);
    --button-ghost-padding-x: var(--space-4);
    --button-ghost-padding-y: var(--space-2);
  }
}

@media (min-width: 1024px) {
  .rt-Button:where(.md\:rt-r-size-1) {
    gap: var(--space-1);
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
  }

  .rt-Button:where(.md\:rt-r-size-1):where(:not(.rt-variant-ghost)) {
    padding-left: var(--space-2);
    padding-right: var(--space-2);
  }

  .rt-Button:where(.md\:rt-r-size-1):where(.rt-variant-ghost) {
    gap: var(--space-1);
    --button-ghost-padding-x: var(--space-2);
    --button-ghost-padding-y: var(--space-1);
  }

  .rt-Button:where(.md\:rt-r-size-2) {
    gap: var(--space-2);
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    letter-spacing: var(--letter-spacing-2);
  }

  .rt-Button:where(.md\:rt-r-size-2):where(:not(.rt-variant-ghost)) {
    padding-left: var(--space-3);
    padding-right: var(--space-3);
  }

  .rt-Button:where(.md\:rt-r-size-2):where(.rt-variant-ghost) {
    gap: var(--space-1);
    --button-ghost-padding-x: var(--space-2);
    --button-ghost-padding-y: var(--space-1);
  }

  .rt-Button:where(.md\:rt-r-size-3) {
    gap: var(--space-3);
    font-size: var(--font-size-3);
    line-height: var(--line-height-3);
    letter-spacing: var(--letter-spacing-3);
  }

  .rt-Button:where(.md\:rt-r-size-3):where(:not(.rt-variant-ghost)) {
    padding-left: var(--space-4);
    padding-right: var(--space-4);
  }

  .rt-Button:where(.md\:rt-r-size-3):where(.rt-variant-ghost) {
    gap: var(--space-2);
    --button-ghost-padding-x: var(--space-3);
    --button-ghost-padding-y: calc(var(--space-1) * 1.5);
  }

  .rt-Button:where(.md\:rt-r-size-4) {
    gap: var(--space-3);
    font-size: var(--font-size-4);
    line-height: var(--line-height-4);
    letter-spacing: var(--letter-spacing-4);
  }

  .rt-Button:where(.md\:rt-r-size-4):where(:not(.rt-variant-ghost)) {
    padding-left: var(--space-5);
    padding-right: var(--space-5);
  }

  .rt-Button:where(.md\:rt-r-size-4):where(.rt-variant-ghost) {
    gap: var(--space-2);
    --button-ghost-padding-x: var(--space-4);
    --button-ghost-padding-y: var(--space-2);
  }
}

@media (min-width: 1280px) {
  .rt-Button:where(.lg\:rt-r-size-1) {
    gap: var(--space-1);
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
  }

  .rt-Button:where(.lg\:rt-r-size-1):where(:not(.rt-variant-ghost)) {
    padding-left: var(--space-2);
    padding-right: var(--space-2);
  }

  .rt-Button:where(.lg\:rt-r-size-1):where(.rt-variant-ghost) {
    gap: var(--space-1);
    --button-ghost-padding-x: var(--space-2);
    --button-ghost-padding-y: var(--space-1);
  }

  .rt-Button:where(.lg\:rt-r-size-2) {
    gap: var(--space-2);
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    letter-spacing: var(--letter-spacing-2);
  }

  .rt-Button:where(.lg\:rt-r-size-2):where(:not(.rt-variant-ghost)) {
    padding-left: var(--space-3);
    padding-right: var(--space-3);
  }

  .rt-Button:where(.lg\:rt-r-size-2):where(.rt-variant-ghost) {
    gap: var(--space-1);
    --button-ghost-padding-x: var(--space-2);
    --button-ghost-padding-y: var(--space-1);
  }

  .rt-Button:where(.lg\:rt-r-size-3) {
    gap: var(--space-3);
    font-size: var(--font-size-3);
    line-height: var(--line-height-3);
    letter-spacing: var(--letter-spacing-3);
  }

  .rt-Button:where(.lg\:rt-r-size-3):where(:not(.rt-variant-ghost)) {
    padding-left: var(--space-4);
    padding-right: var(--space-4);
  }

  .rt-Button:where(.lg\:rt-r-size-3):where(.rt-variant-ghost) {
    gap: var(--space-2);
    --button-ghost-padding-x: var(--space-3);
    --button-ghost-padding-y: calc(var(--space-1) * 1.5);
  }

  .rt-Button:where(.lg\:rt-r-size-4) {
    gap: var(--space-3);
    font-size: var(--font-size-4);
    line-height: var(--line-height-4);
    letter-spacing: var(--letter-spacing-4);
  }

  .rt-Button:where(.lg\:rt-r-size-4):where(:not(.rt-variant-ghost)) {
    padding-left: var(--space-5);
    padding-right: var(--space-5);
  }

  .rt-Button:where(.lg\:rt-r-size-4):where(.rt-variant-ghost) {
    gap: var(--space-2);
    --button-ghost-padding-x: var(--space-4);
    --button-ghost-padding-y: var(--space-2);
  }
}

@media (min-width: 1640px) {
  .rt-Button:where(.xl\:rt-r-size-1) {
    gap: var(--space-1);
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
  }

  .rt-Button:where(.xl\:rt-r-size-1):where(:not(.rt-variant-ghost)) {
    padding-left: var(--space-2);
    padding-right: var(--space-2);
  }

  .rt-Button:where(.xl\:rt-r-size-1):where(.rt-variant-ghost) {
    gap: var(--space-1);
    --button-ghost-padding-x: var(--space-2);
    --button-ghost-padding-y: var(--space-1);
  }

  .rt-Button:where(.xl\:rt-r-size-2) {
    gap: var(--space-2);
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    letter-spacing: var(--letter-spacing-2);
  }

  .rt-Button:where(.xl\:rt-r-size-2):where(:not(.rt-variant-ghost)) {
    padding-left: var(--space-3);
    padding-right: var(--space-3);
  }

  .rt-Button:where(.xl\:rt-r-size-2):where(.rt-variant-ghost) {
    gap: var(--space-1);
    --button-ghost-padding-x: var(--space-2);
    --button-ghost-padding-y: var(--space-1);
  }

  .rt-Button:where(.xl\:rt-r-size-3) {
    gap: var(--space-3);
    font-size: var(--font-size-3);
    line-height: var(--line-height-3);
    letter-spacing: var(--letter-spacing-3);
  }

  .rt-Button:where(.xl\:rt-r-size-3):where(:not(.rt-variant-ghost)) {
    padding-left: var(--space-4);
    padding-right: var(--space-4);
  }

  .rt-Button:where(.xl\:rt-r-size-3):where(.rt-variant-ghost) {
    gap: var(--space-2);
    --button-ghost-padding-x: var(--space-3);
    --button-ghost-padding-y: calc(var(--space-1) * 1.5);
  }

  .rt-Button:where(.xl\:rt-r-size-4) {
    gap: var(--space-3);
    font-size: var(--font-size-4);
    line-height: var(--line-height-4);
    letter-spacing: var(--letter-spacing-4);
  }

  .rt-Button:where(.xl\:rt-r-size-4):where(:not(.rt-variant-ghost)) {
    padding-left: var(--space-5);
    padding-right: var(--space-5);
  }

  .rt-Button:where(.xl\:rt-r-size-4):where(.rt-variant-ghost) {
    gap: var(--space-2);
    --button-ghost-padding-x: var(--space-4);
    --button-ghost-padding-y: var(--space-2);
  }
}

.rt-Button:where(:not(.rt-variant-ghost)) {
  font-weight: var(--font-weight-medium);
}

.rt-CalloutRoot {
  box-sizing: border-box;
  text-align: left;
  color: var(--accent-a11);
  justify-content: flex-start;
  align-items: flex-start;
  display: grid;
}

.rt-CalloutRoot:where(.rt-high-contrast) {
  color: var(--accent-12);
}

.rt-CalloutIcon {
  height: var(--callout-icon-height);
  grid-column-start: -2;
  align-items: center;
  display: flex;
}

.rt-CalloutRoot > :where(:not(.rt-CalloutIcon)) {
  grid-column-start: -1;
}

.rt-CalloutRoot:where(.rt-r-size-1) {
  row-gap: var(--space-2);
  column-gap: var(--space-2);
  padding: var(--space-3);
  border-radius: var(--radius-3);
  --callout-icon-height: var(--line-height-2);
}

.rt-CalloutRoot:where(.rt-r-size-2) {
  row-gap: var(--space-2);
  column-gap: var(--space-3);
  padding: var(--space-4);
  border-radius: var(--radius-4);
  --callout-icon-height: var(--line-height-2);
}

.rt-CalloutRoot:where(.rt-r-size-3) {
  row-gap: var(--space-3);
  column-gap: var(--space-4);
  padding: var(--space-5);
  border-radius: var(--radius-5);
  --callout-icon-height: var(--line-height-3);
}

@media (min-width: 520px) {
  .rt-CalloutRoot:where(.xs\:rt-r-size-1) {
    row-gap: var(--space-2);
    column-gap: var(--space-2);
    padding: var(--space-3);
    border-radius: var(--radius-3);
    --callout-icon-height: var(--line-height-2);
  }

  .rt-CalloutRoot:where(.xs\:rt-r-size-2) {
    row-gap: var(--space-2);
    column-gap: var(--space-3);
    padding: var(--space-4);
    border-radius: var(--radius-4);
    --callout-icon-height: var(--line-height-2);
  }

  .rt-CalloutRoot:where(.xs\:rt-r-size-3) {
    row-gap: var(--space-3);
    column-gap: var(--space-4);
    padding: var(--space-5);
    border-radius: var(--radius-5);
    --callout-icon-height: var(--line-height-3);
  }
}

@media (min-width: 768px) {
  .rt-CalloutRoot:where(.sm\:rt-r-size-1) {
    row-gap: var(--space-2);
    column-gap: var(--space-2);
    padding: var(--space-3);
    border-radius: var(--radius-3);
    --callout-icon-height: var(--line-height-2);
  }

  .rt-CalloutRoot:where(.sm\:rt-r-size-2) {
    row-gap: var(--space-2);
    column-gap: var(--space-3);
    padding: var(--space-4);
    border-radius: var(--radius-4);
    --callout-icon-height: var(--line-height-2);
  }

  .rt-CalloutRoot:where(.sm\:rt-r-size-3) {
    row-gap: var(--space-3);
    column-gap: var(--space-4);
    padding: var(--space-5);
    border-radius: var(--radius-5);
    --callout-icon-height: var(--line-height-3);
  }
}

@media (min-width: 1024px) {
  .rt-CalloutRoot:where(.md\:rt-r-size-1) {
    row-gap: var(--space-2);
    column-gap: var(--space-2);
    padding: var(--space-3);
    border-radius: var(--radius-3);
    --callout-icon-height: var(--line-height-2);
  }

  .rt-CalloutRoot:where(.md\:rt-r-size-2) {
    row-gap: var(--space-2);
    column-gap: var(--space-3);
    padding: var(--space-4);
    border-radius: var(--radius-4);
    --callout-icon-height: var(--line-height-2);
  }

  .rt-CalloutRoot:where(.md\:rt-r-size-3) {
    row-gap: var(--space-3);
    column-gap: var(--space-4);
    padding: var(--space-5);
    border-radius: var(--radius-5);
    --callout-icon-height: var(--line-height-3);
  }
}

@media (min-width: 1280px) {
  .rt-CalloutRoot:where(.lg\:rt-r-size-1) {
    row-gap: var(--space-2);
    column-gap: var(--space-2);
    padding: var(--space-3);
    border-radius: var(--radius-3);
    --callout-icon-height: var(--line-height-2);
  }

  .rt-CalloutRoot:where(.lg\:rt-r-size-2) {
    row-gap: var(--space-2);
    column-gap: var(--space-3);
    padding: var(--space-4);
    border-radius: var(--radius-4);
    --callout-icon-height: var(--line-height-2);
  }

  .rt-CalloutRoot:where(.lg\:rt-r-size-3) {
    row-gap: var(--space-3);
    column-gap: var(--space-4);
    padding: var(--space-5);
    border-radius: var(--radius-5);
    --callout-icon-height: var(--line-height-3);
  }
}

@media (min-width: 1640px) {
  .rt-CalloutRoot:where(.xl\:rt-r-size-1) {
    row-gap: var(--space-2);
    column-gap: var(--space-2);
    padding: var(--space-3);
    border-radius: var(--radius-3);
    --callout-icon-height: var(--line-height-2);
  }

  .rt-CalloutRoot:where(.xl\:rt-r-size-2) {
    row-gap: var(--space-2);
    column-gap: var(--space-3);
    padding: var(--space-4);
    border-radius: var(--radius-4);
    --callout-icon-height: var(--line-height-2);
  }

  .rt-CalloutRoot:where(.xl\:rt-r-size-3) {
    row-gap: var(--space-3);
    column-gap: var(--space-4);
    padding: var(--space-5);
    border-radius: var(--radius-5);
    --callout-icon-height: var(--line-height-3);
  }
}

.rt-CalloutRoot:where(.rt-variant-soft) {
  background-color: var(--accent-a3);
}

.rt-CalloutRoot:where(.rt-variant-surface) {
  box-shadow: inset 0 0 0 1px var(--accent-a6);
  background-color: var(--accent-a2);
}

.rt-CalloutRoot:where(.rt-variant-outline) {
  box-shadow: inset 0 0 0 1px var(--accent-a7);
}

.rt-BaseCard {
  border-radius: var(--base-card-border-radius);
  font-family: var(--default-font-family);
  font-weight: var(--font-weight-normal);
  text-align: start;
  --inset-border-width: var(--base-card-border-width);
  --inset-border-radius: var(--base-card-border-radius);
  padding-top: var(--base-card-padding-top);
  padding-right: var(--base-card-padding-right);
  padding-bottom: var(--base-card-padding-bottom);
  padding-left: var(--base-card-padding-left);
  box-sizing: border-box;
  --inset-padding-top: calc(var(--base-card-padding-top)  - var(--base-card-border-width));
  --inset-padding-right: calc(var(--base-card-padding-right)  - var(--base-card-border-width));
  --inset-padding-bottom: calc(var(--base-card-padding-bottom)  - var(--base-card-border-width));
  --inset-padding-left: calc(var(--base-card-padding-left)  - var(--base-card-border-width));
  contain: paint;
  font-style: normal;
  display: block;
  position: relative;
  overflow: hidden;
}

.rt-BaseCard:before, .rt-BaseCard:after {
  content: "";
  pointer-events: none;
  border-radius: calc(var(--base-card-border-radius)  - var(--base-card-border-width));
  inset: var(--base-card-border-width);
  transition: inherit;
  position: absolute;
}

.rt-BaseCard:before {
  z-index: -1;
}

.rt-Card {
  --base-card-padding-top: var(--card-padding);
  --base-card-padding-right: var(--card-padding);
  --base-card-padding-bottom: var(--card-padding);
  --base-card-padding-left: var(--card-padding);
  --base-card-border-radius: var(--card-border-radius);
  --base-card-border-width: var(--card-border-width);
}

.rt-Card:where(:focus-visible) {
  outline: 2px solid var(--focus-8);
  outline-offset: -1px;
}

.rt-Card:where(:focus-visible):after {
  outline: inherit;
}

.rt-Card:where(:focus-visible):where(:active:not([data-state="open"])):before {
  background-image: linear-gradient(var(--focus-a2), var(--focus-a2));
}

.rt-Card:where(.rt-r-size-1) {
  --card-padding: var(--space-3);
  --card-border-radius: var(--radius-4);
}

.rt-Card:where(.rt-r-size-2) {
  --card-padding: var(--space-4);
  --card-border-radius: var(--radius-4);
}

.rt-Card:where(.rt-r-size-3) {
  --card-padding: var(--space-5);
  --card-border-radius: var(--radius-5);
}

.rt-Card:where(.rt-r-size-4) {
  --card-padding: var(--space-6);
  --card-border-radius: var(--radius-5);
}

.rt-Card:where(.rt-r-size-5) {
  --card-padding: var(--space-8);
  --card-border-radius: var(--radius-6);
}

@media (min-width: 520px) {
  .rt-Card:where(.xs\:rt-r-size-1) {
    --card-padding: var(--space-3);
    --card-border-radius: var(--radius-4);
  }

  .rt-Card:where(.xs\:rt-r-size-2) {
    --card-padding: var(--space-4);
    --card-border-radius: var(--radius-4);
  }

  .rt-Card:where(.xs\:rt-r-size-3) {
    --card-padding: var(--space-5);
    --card-border-radius: var(--radius-5);
  }

  .rt-Card:where(.xs\:rt-r-size-4) {
    --card-padding: var(--space-6);
    --card-border-radius: var(--radius-5);
  }

  .rt-Card:where(.xs\:rt-r-size-5) {
    --card-padding: var(--space-8);
    --card-border-radius: var(--radius-6);
  }
}

@media (min-width: 768px) {
  .rt-Card:where(.sm\:rt-r-size-1) {
    --card-padding: var(--space-3);
    --card-border-radius: var(--radius-4);
  }

  .rt-Card:where(.sm\:rt-r-size-2) {
    --card-padding: var(--space-4);
    --card-border-radius: var(--radius-4);
  }

  .rt-Card:where(.sm\:rt-r-size-3) {
    --card-padding: var(--space-5);
    --card-border-radius: var(--radius-5);
  }

  .rt-Card:where(.sm\:rt-r-size-4) {
    --card-padding: var(--space-6);
    --card-border-radius: var(--radius-5);
  }

  .rt-Card:where(.sm\:rt-r-size-5) {
    --card-padding: var(--space-8);
    --card-border-radius: var(--radius-6);
  }
}

@media (min-width: 1024px) {
  .rt-Card:where(.md\:rt-r-size-1) {
    --card-padding: var(--space-3);
    --card-border-radius: var(--radius-4);
  }

  .rt-Card:where(.md\:rt-r-size-2) {
    --card-padding: var(--space-4);
    --card-border-radius: var(--radius-4);
  }

  .rt-Card:where(.md\:rt-r-size-3) {
    --card-padding: var(--space-5);
    --card-border-radius: var(--radius-5);
  }

  .rt-Card:where(.md\:rt-r-size-4) {
    --card-padding: var(--space-6);
    --card-border-radius: var(--radius-5);
  }

  .rt-Card:where(.md\:rt-r-size-5) {
    --card-padding: var(--space-8);
    --card-border-radius: var(--radius-6);
  }
}

@media (min-width: 1280px) {
  .rt-Card:where(.lg\:rt-r-size-1) {
    --card-padding: var(--space-3);
    --card-border-radius: var(--radius-4);
  }

  .rt-Card:where(.lg\:rt-r-size-2) {
    --card-padding: var(--space-4);
    --card-border-radius: var(--radius-4);
  }

  .rt-Card:where(.lg\:rt-r-size-3) {
    --card-padding: var(--space-5);
    --card-border-radius: var(--radius-5);
  }

  .rt-Card:where(.lg\:rt-r-size-4) {
    --card-padding: var(--space-6);
    --card-border-radius: var(--radius-5);
  }

  .rt-Card:where(.lg\:rt-r-size-5) {
    --card-padding: var(--space-8);
    --card-border-radius: var(--radius-6);
  }
}

@media (min-width: 1640px) {
  .rt-Card:where(.xl\:rt-r-size-1) {
    --card-padding: var(--space-3);
    --card-border-radius: var(--radius-4);
  }

  .rt-Card:where(.xl\:rt-r-size-2) {
    --card-padding: var(--space-4);
    --card-border-radius: var(--radius-4);
  }

  .rt-Card:where(.xl\:rt-r-size-3) {
    --card-padding: var(--space-5);
    --card-border-radius: var(--radius-5);
  }

  .rt-Card:where(.xl\:rt-r-size-4) {
    --card-padding: var(--space-6);
    --card-border-radius: var(--radius-5);
  }

  .rt-Card:where(.xl\:rt-r-size-5) {
    --card-padding: var(--space-8);
    --card-border-radius: var(--radius-6);
  }
}

.rt-Card:where(.rt-variant-surface) {
  --card-border-width: 1px;
  --card-background-color: var(--color-panel);
}

.rt-Card:where(.rt-variant-surface):before {
  background-color: var(--card-background-color);
  -webkit-backdrop-filter: var(--backdrop-filter-panel);
  backdrop-filter: var(--backdrop-filter-panel);
}

.rt-Card:where(.rt-variant-surface):after {
  box-shadow: var(--base-card-surface-box-shadow);
}

@media (hover: hover) {
  .rt-Card:where(.rt-variant-surface):where(:-webkit-any-link, button, label):where(:hover):after {
    box-shadow: var(--base-card-surface-hover-box-shadow);
  }

  .rt-Card:where(.rt-variant-surface):where(:any-link, button, label):where(:hover):after {
    box-shadow: var(--base-card-surface-hover-box-shadow);
  }
}

.rt-Card:where(.rt-variant-surface):where(:-webkit-any-link, button, label):where([data-state="open"]):after {
  box-shadow: var(--base-card-surface-hover-box-shadow);
}

.rt-Card:where(.rt-variant-surface):where(:any-link, button, label):where([data-state="open"]):after {
  box-shadow: var(--base-card-surface-hover-box-shadow);
}

.rt-Card:where(.rt-variant-surface):where(:-webkit-any-link, button, label):where(:active:not([data-state="open"])):after {
  box-shadow: var(--base-card-surface-active-box-shadow);
}

.rt-Card:where(.rt-variant-surface):where(:any-link, button, label):where(:active:not([data-state="open"])):after {
  box-shadow: var(--base-card-surface-active-box-shadow);
}

.rt-Card:where(.rt-variant-classic) {
  --card-border-width: 1px;
  --card-background-color: var(--color-panel);
  box-shadow: var(--base-card-classic-box-shadow-outer);
  transition: box-shadow .12s;
}

.rt-Card:where(.rt-variant-classic):before {
  background-color: var(--card-background-color);
  -webkit-backdrop-filter: var(--backdrop-filter-panel);
  backdrop-filter: var(--backdrop-filter-panel);
}

.rt-Card:where(.rt-variant-classic):after {
  box-shadow: var(--base-card-classic-box-shadow-inner);
}

@media (hover: hover) {
  .rt-Card:where(.rt-variant-classic):where(:-webkit-any-link, button, label):where(:hover) {
    box-shadow: var(--base-card-classic-hover-box-shadow-outer);
    transition-duration: 40ms;
  }

  .rt-Card:where(.rt-variant-classic):where(:any-link, button, label):where(:hover) {
    box-shadow: var(--base-card-classic-hover-box-shadow-outer);
    transition-duration: 40ms;
  }

  .rt-Card:where(.rt-variant-classic):where(:-webkit-any-link, button, label):where(:hover):after {
    box-shadow: var(--base-card-classic-hover-box-shadow-inner);
  }

  .rt-Card:where(.rt-variant-classic):where(:any-link, button, label):where(:hover):after {
    box-shadow: var(--base-card-classic-hover-box-shadow-inner);
  }
}

.rt-Card:where(.rt-variant-classic):where(:-webkit-any-link, button, label):where([data-state="open"]) {
  box-shadow: var(--base-card-classic-hover-box-shadow-outer);
  transition-duration: 40ms;
}

.rt-Card:where(.rt-variant-classic):where(:any-link, button, label):where([data-state="open"]) {
  box-shadow: var(--base-card-classic-hover-box-shadow-outer);
  transition-duration: 40ms;
}

.rt-Card:where(.rt-variant-classic):where(:-webkit-any-link, button, label):where([data-state="open"]):after {
  box-shadow: var(--base-card-classic-hover-box-shadow-inner);
}

.rt-Card:where(.rt-variant-classic):where(:any-link, button, label):where([data-state="open"]):after {
  box-shadow: var(--base-card-classic-hover-box-shadow-inner);
}

.rt-Card:where(.rt-variant-classic):where(:-webkit-any-link, button, label):where(:active:not([data-state="open"])) {
  box-shadow: var(--base-card-classic-active-box-shadow-outer);
  transition-duration: 40ms;
}

.rt-Card:where(.rt-variant-classic):where(:any-link, button, label):where(:active:not([data-state="open"])) {
  box-shadow: var(--base-card-classic-active-box-shadow-outer);
  transition-duration: 40ms;
}

.rt-Card:where(.rt-variant-classic):where(:-webkit-any-link, button, label):where(:active:not([data-state="open"])):after {
  box-shadow: var(--base-card-classic-active-box-shadow-inner);
}

.rt-Card:where(.rt-variant-classic):where(:any-link, button, label):where(:active:not([data-state="open"])):after {
  box-shadow: var(--base-card-classic-active-box-shadow-inner);
}

.rt-Card:where(.rt-variant-ghost) {
  --card-border-width: 0px;
  --margin-top: 0px;
  --margin-right: 0px;
  --margin-bottom: 0px;
  --margin-left: 0px;
  --margin-top-override: calc(var(--margin-top)  - var(--card-padding));
  --margin-right-override: calc(var(--margin-right)  - var(--card-padding));
  --margin-bottom-override: calc(var(--margin-bottom)  - var(--card-padding));
  --margin-left-override: calc(var(--margin-left)  - var(--card-padding));
  margin-top: var(--margin-top-override);
  margin-right: var(--margin-right-override);
  margin-bottom: var(--margin-bottom-override);
  margin-left: var(--margin-left-override);
}

:where(.rt-Card:where(.rt-variant-ghost)) > * {
  --margin-top-override: initial;
  --margin-right-override: initial;
  --margin-bottom-override: initial;
  --margin-left-override: initial;
}

@media (hover: hover) {
  .rt-Card:where(.rt-variant-ghost):where(:-webkit-any-link, button, label):where(:hover) {
    background-color: var(--gray-a3);
  }

  .rt-Card:where(.rt-variant-ghost):where(:any-link, button, label):where(:hover) {
    background-color: var(--gray-a3);
  }

  .rt-Card:where(.rt-variant-ghost):where(:-webkit-any-link, button, label):where(:hover):where(:focus-visible) {
    background-color: var(--focus-a2);
  }

  .rt-Card:where(.rt-variant-ghost):where(:any-link, button, label):where(:hover):where(:focus-visible) {
    background-color: var(--focus-a2);
  }
}

.rt-Card:where(.rt-variant-ghost):where(:-webkit-any-link, button, label):where([data-state="open"]) {
  background-color: var(--gray-a3);
}

.rt-Card:where(.rt-variant-ghost):where(:any-link, button, label):where([data-state="open"]) {
  background-color: var(--gray-a3);
}

.rt-Card:where(.rt-variant-ghost):where(:-webkit-any-link, button, label):where([data-state="open"]):where(:focus-visible) {
  background-color: var(--focus-a2);
}

.rt-Card:where(.rt-variant-ghost):where(:any-link, button, label):where([data-state="open"]):where(:focus-visible) {
  background-color: var(--focus-a2);
}

.rt-Card:where(.rt-variant-ghost):where(:-webkit-any-link, button, label):where(:active:not([data-state="open"])) {
  background-color: var(--gray-a4);
}

.rt-Card:where(.rt-variant-ghost):where(:any-link, button, label):where(:active:not([data-state="open"])) {
  background-color: var(--gray-a4);
}

.rt-Card:where(.rt-variant-ghost):where(:-webkit-any-link, button, label):where(:active:not([data-state="open"])):where(:focus-visible) {
  background-color: var(--focus-a2);
}

.rt-Card:where(.rt-variant-ghost):where(:any-link, button, label):where(:active:not([data-state="open"])):where(:focus-visible) {
  background-color: var(--focus-a2);
}

@media (pointer: coarse) {
  .rt-Card:where(:-webkit-any-link, button, label):where(:active:not(:-webkit-any(:focus-visible, [data-state="open"]))):before {
    background-image: linear-gradient(var(--gray-a4), var(--gray-a4));
  }

  .rt-Card:where(:any-link, button, label):where(:active:not(:-moz-any(:focus-visible, [data-state="open"]))):before {
    background-image: linear-gradient(var(--gray-a4), var(--gray-a4));
  }

  .rt-Card:where(:any-link, button, label):where(:active:not(:is(:focus-visible, [data-state="open"]))):before {
    background-image: linear-gradient(var(--gray-a4), var(--gray-a4));
  }
}

.rt-BaseCheckboxRoot {
  vertical-align: top;
  cursor: var(--cursor-checkbox);
  height: var(--skeleton-height, var(--line-height, var(--checkbox-size)));
  --skeleton-height-override: var(--checkbox-size);
  border-radius: var(--skeleton-radius);
  --skeleton-radius-override: var(--checkbox-border-radius);
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  position: relative;
}

.rt-BaseCheckboxRoot:before {
  content: "";
  height: var(--checkbox-size);
  width: var(--checkbox-size);
  border-radius: var(--checkbox-border-radius);
  display: block;
}

.rt-BaseCheckboxIndicator {
  width: var(--checkbox-indicator-size);
  height: var(--checkbox-indicator-size);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.rt-BaseCheckboxRoot:where(.rt-r-size-1) {
  --checkbox-size: calc(var(--space-4) * .875);
  --checkbox-indicator-size: calc(9px * var(--scaling));
  --checkbox-border-radius: calc(var(--radius-1) * .875);
}

.rt-BaseCheckboxRoot:where(.rt-r-size-2) {
  --checkbox-size: var(--space-4);
  --checkbox-indicator-size: calc(10px * var(--scaling));
  --checkbox-border-radius: var(--radius-1);
}

.rt-BaseCheckboxRoot:where(.rt-r-size-3) {
  --checkbox-size: calc(var(--space-4) * 1.25);
  --checkbox-indicator-size: calc(12px * var(--scaling));
  --checkbox-border-radius: calc(var(--radius-1) * 1.25);
}

@media (min-width: 520px) {
  .rt-BaseCheckboxRoot:where(.xs\:rt-r-size-1) {
    --checkbox-size: calc(var(--space-4) * .875);
    --checkbox-indicator-size: calc(9px * var(--scaling));
    --checkbox-border-radius: calc(var(--radius-1) * .875);
  }

  .rt-BaseCheckboxRoot:where(.xs\:rt-r-size-2) {
    --checkbox-size: var(--space-4);
    --checkbox-indicator-size: calc(10px * var(--scaling));
    --checkbox-border-radius: var(--radius-1);
  }

  .rt-BaseCheckboxRoot:where(.xs\:rt-r-size-3) {
    --checkbox-size: calc(var(--space-4) * 1.25);
    --checkbox-indicator-size: calc(12px * var(--scaling));
    --checkbox-border-radius: calc(var(--radius-1) * 1.25);
  }
}

@media (min-width: 768px) {
  .rt-BaseCheckboxRoot:where(.sm\:rt-r-size-1) {
    --checkbox-size: calc(var(--space-4) * .875);
    --checkbox-indicator-size: calc(9px * var(--scaling));
    --checkbox-border-radius: calc(var(--radius-1) * .875);
  }

  .rt-BaseCheckboxRoot:where(.sm\:rt-r-size-2) {
    --checkbox-size: var(--space-4);
    --checkbox-indicator-size: calc(10px * var(--scaling));
    --checkbox-border-radius: var(--radius-1);
  }

  .rt-BaseCheckboxRoot:where(.sm\:rt-r-size-3) {
    --checkbox-size: calc(var(--space-4) * 1.25);
    --checkbox-indicator-size: calc(12px * var(--scaling));
    --checkbox-border-radius: calc(var(--radius-1) * 1.25);
  }
}

@media (min-width: 1024px) {
  .rt-BaseCheckboxRoot:where(.md\:rt-r-size-1) {
    --checkbox-size: calc(var(--space-4) * .875);
    --checkbox-indicator-size: calc(9px * var(--scaling));
    --checkbox-border-radius: calc(var(--radius-1) * .875);
  }

  .rt-BaseCheckboxRoot:where(.md\:rt-r-size-2) {
    --checkbox-size: var(--space-4);
    --checkbox-indicator-size: calc(10px * var(--scaling));
    --checkbox-border-radius: var(--radius-1);
  }

  .rt-BaseCheckboxRoot:where(.md\:rt-r-size-3) {
    --checkbox-size: calc(var(--space-4) * 1.25);
    --checkbox-indicator-size: calc(12px * var(--scaling));
    --checkbox-border-radius: calc(var(--radius-1) * 1.25);
  }
}

@media (min-width: 1280px) {
  .rt-BaseCheckboxRoot:where(.lg\:rt-r-size-1) {
    --checkbox-size: calc(var(--space-4) * .875);
    --checkbox-indicator-size: calc(9px * var(--scaling));
    --checkbox-border-radius: calc(var(--radius-1) * .875);
  }

  .rt-BaseCheckboxRoot:where(.lg\:rt-r-size-2) {
    --checkbox-size: var(--space-4);
    --checkbox-indicator-size: calc(10px * var(--scaling));
    --checkbox-border-radius: var(--radius-1);
  }

  .rt-BaseCheckboxRoot:where(.lg\:rt-r-size-3) {
    --checkbox-size: calc(var(--space-4) * 1.25);
    --checkbox-indicator-size: calc(12px * var(--scaling));
    --checkbox-border-radius: calc(var(--radius-1) * 1.25);
  }
}

@media (min-width: 1640px) {
  .rt-BaseCheckboxRoot:where(.xl\:rt-r-size-1) {
    --checkbox-size: calc(var(--space-4) * .875);
    --checkbox-indicator-size: calc(9px * var(--scaling));
    --checkbox-border-radius: calc(var(--radius-1) * .875);
  }

  .rt-BaseCheckboxRoot:where(.xl\:rt-r-size-2) {
    --checkbox-size: var(--space-4);
    --checkbox-indicator-size: calc(10px * var(--scaling));
    --checkbox-border-radius: var(--radius-1);
  }

  .rt-BaseCheckboxRoot:where(.xl\:rt-r-size-3) {
    --checkbox-size: calc(var(--space-4) * 1.25);
    --checkbox-indicator-size: calc(12px * var(--scaling));
    --checkbox-border-radius: calc(var(--radius-1) * 1.25);
  }
}

.rt-BaseCheckboxRoot:where(.rt-variant-surface):where([data-state="unchecked"]):before {
  background-color: var(--color-surface);
  box-shadow: inset 0 0 0 1px var(--gray-a7);
}

.rt-BaseCheckboxRoot:where(.rt-variant-surface):where([data-state="checked"], [data-state="indeterminate"]):before {
  background-color: var(--accent-indicator);
}

.rt-BaseCheckboxRoot:where(.rt-variant-surface):where([data-state="checked"], [data-state="indeterminate"]) :where(.rt-BaseCheckboxIndicator) {
  color: var(--accent-contrast);
}

.rt-BaseCheckboxRoot:where(.rt-variant-surface):where([data-state="checked"], [data-state="indeterminate"]):where(.rt-high-contrast):before {
  background-color: var(--accent-12);
}

.rt-BaseCheckboxRoot:where(.rt-variant-surface):where([data-state="checked"], [data-state="indeterminate"]):where(.rt-high-contrast) :where(.rt-BaseCheckboxIndicator) {
  color: var(--accent-1);
}

.rt-BaseCheckboxRoot:where(.rt-variant-surface):where(:disabled):before {
  box-shadow: inset 0 0 0 1px var(--gray-a6);
  background-color: rgba(0, 0, 0, 0);
}

.rt-BaseCheckboxRoot:where(.rt-variant-surface):where(:disabled) :where(.rt-BaseCheckboxIndicator) {
  color: var(--gray-a8);
}

.rt-BaseCheckboxRoot:where(.rt-variant-classic):where([data-state="unchecked"]):before {
  background-color: var(--color-surface);
  box-shadow: inset 0 0 0 1px var(--gray-a3), var(--shadow-1);
}

.rt-BaseCheckboxRoot:where(.rt-variant-classic):where([data-state="checked"], [data-state="indeterminate"]):before {
  background-color: var(--accent-indicator);
  background-image: linear-gradient(to bottom, var(--white-a3), transparent, var(--black-a1));
  box-shadow: inset 0 .5px .5px var(--white-a4), inset 0 -.5px .5px var(--black-a4);
}

.rt-BaseCheckboxRoot:where(.rt-variant-classic):where([data-state="checked"], [data-state="indeterminate"]) :where(.rt-BaseCheckboxIndicator) {
  color: var(--accent-contrast);
}

.rt-BaseCheckboxRoot:where(.rt-variant-classic):where([data-state="checked"], [data-state="indeterminate"]):where(.rt-high-contrast):before {
  background-color: var(--accent-12);
}

.rt-BaseCheckboxRoot:where(.rt-variant-classic):where([data-state="checked"], [data-state="indeterminate"]):where(.rt-high-contrast) :where(.rt-BaseCheckboxIndicator) {
  color: var(--accent-1);
}

.rt-BaseCheckboxRoot:where(.rt-variant-classic):where(:disabled):before {
  box-shadow: var(--shadow-1);
  background-color: rgba(0, 0, 0, 0);
  background-image: none;
}

.rt-BaseCheckboxRoot:where(.rt-variant-classic):where(:disabled) :where(.rt-BaseCheckboxIndicator) {
  color: var(--gray-a8);
}

.rt-BaseCheckboxRoot:where(.rt-variant-soft):before {
  background-color: var(--accent-a5);
}

.rt-BaseCheckboxRoot:where(.rt-variant-soft):where([data-state="checked"], [data-state="indeterminate"]) :where(.rt-BaseCheckboxIndicator) {
  color: var(--accent-a11);
}

.rt-BaseCheckboxRoot:where(.rt-variant-soft):where([data-state="checked"], [data-state="indeterminate"]):where(.rt-high-contrast) :where(.rt-BaseCheckboxIndicator) {
  color: var(--accent-12);
}

.rt-BaseCheckboxRoot:where(.rt-variant-soft):where(:disabled):before {
  background-color: rgba(0, 0, 0, 0);
}

.rt-BaseCheckboxRoot:where(.rt-variant-soft):where(:disabled) :where(.rt-BaseCheckboxIndicator) {
  color: var(--gray-a8);
}

.rt-CheckboxCardsRoot {
  line-height: var(--line-height);
  letter-spacing: var(--letter-spacing);
  cursor: default;
}

.rt-CheckboxCardsItem:where(:has(:focus-visible)) {
  outline: 2px solid var(--focus-8);
  outline-offset: -1px;
}

.rt-CheckboxCardsItem:where(:has(:focus-visible)):after {
  outline: inherit;
}

.rt-CheckboxCardsItem > * {
  pointer-events: none;
}

.rt-CheckboxCardsItem > :where(svg) {
  flex-shrink: 0;
}

.rt-CheckboxCardCheckbox {
  right: var(--checkbox-cards-item-padding-left);
  position: absolute;
}

.rt-CheckboxCardsItem {
  --checkbox-cards-item-padding-right: calc(var(--checkbox-cards-item-padding-left) * 2 + var(--checkbox-cards-item-checkbox-size));
  --base-card-padding-top: var(--checkbox-cards-item-padding-top);
  --base-card-padding-right: var(--checkbox-cards-item-padding-right);
  --base-card-padding-bottom: var(--checkbox-cards-item-padding-bottom);
  --base-card-padding-left: var(--checkbox-cards-item-padding-left);
  --base-card-border-radius: var(--checkbox-cards-item-border-radius);
  --base-card-border-width: var(--checkbox-cards-item-border-width);
  align-items: center;
  gap: var(--space-2);
  cursor: var(--cursor-button);
  -webkit-tap-highlight-color: transparent;
  display: flex;
}

.rt-CheckboxCardsRoot:where(.rt-r-size-1) {
  font-size: var(--font-size-2);
  --line-height: var(--line-height-2);
  --letter-spacing: var(--letter-spacing-2);
  --checkbox-cards-item-padding-top: calc(var(--space-3) / 1.2);
  --checkbox-cards-item-padding-bottom: calc(var(--space-3) / 1.2);
  --checkbox-cards-item-padding-left: var(--space-3);
  --checkbox-cards-item-border-radius: var(--radius-3);
  --checkbox-cards-item-checkbox-size: calc(var(--space-4) * .875);
}

.rt-CheckboxCardsRoot:where(.rt-r-size-2) {
  font-size: var(--font-size-2);
  --line-height: var(--line-height-2);
  --letter-spacing: var(--letter-spacing-2);
  --checkbox-cards-item-padding-top: calc(var(--space-4) * .875);
  --checkbox-cards-item-padding-bottom: calc(var(--space-4) * .875);
  --checkbox-cards-item-padding-left: var(--space-4);
  --checkbox-cards-item-border-radius: var(--radius-3);
  --checkbox-cards-item-checkbox-size: var(--space-4);
}

.rt-CheckboxCardsRoot:where(.rt-r-size-3) {
  font-size: var(--font-size-3);
  --line-height: var(--line-height-3);
  --letter-spacing: var(--letter-spacing-3);
  --checkbox-cards-item-padding-top: calc(var(--space-5) / 1.2);
  --checkbox-cards-item-padding-bottom: calc(var(--space-5) / 1.2);
  --checkbox-cards-item-padding-left: var(--space-5);
  --checkbox-cards-item-border-radius: var(--radius-4);
  --checkbox-cards-item-checkbox-size: calc(var(--space-4) * 1.25);
}

@media (min-width: 520px) {
  .rt-CheckboxCardsRoot:where(.xs\:rt-r-size-1) {
    font-size: var(--font-size-2);
    --line-height: var(--line-height-2);
    --letter-spacing: var(--letter-spacing-2);
    --checkbox-cards-item-padding-top: calc(var(--space-3) / 1.2);
    --checkbox-cards-item-padding-bottom: calc(var(--space-3) / 1.2);
    --checkbox-cards-item-padding-left: var(--space-3);
    --checkbox-cards-item-border-radius: var(--radius-3);
    --checkbox-cards-item-checkbox-size: calc(var(--space-4) * .875);
  }

  .rt-CheckboxCardsRoot:where(.xs\:rt-r-size-2) {
    font-size: var(--font-size-2);
    --line-height: var(--line-height-2);
    --letter-spacing: var(--letter-spacing-2);
    --checkbox-cards-item-padding-top: calc(var(--space-4) * .875);
    --checkbox-cards-item-padding-bottom: calc(var(--space-4) * .875);
    --checkbox-cards-item-padding-left: var(--space-4);
    --checkbox-cards-item-border-radius: var(--radius-3);
    --checkbox-cards-item-checkbox-size: var(--space-4);
  }

  .rt-CheckboxCardsRoot:where(.xs\:rt-r-size-3) {
    font-size: var(--font-size-3);
    --line-height: var(--line-height-3);
    --letter-spacing: var(--letter-spacing-3);
    --checkbox-cards-item-padding-top: calc(var(--space-5) / 1.2);
    --checkbox-cards-item-padding-bottom: calc(var(--space-5) / 1.2);
    --checkbox-cards-item-padding-left: var(--space-5);
    --checkbox-cards-item-border-radius: var(--radius-4);
    --checkbox-cards-item-checkbox-size: calc(var(--space-4) * 1.25);
  }
}

@media (min-width: 768px) {
  .rt-CheckboxCardsRoot:where(.sm\:rt-r-size-1) {
    font-size: var(--font-size-2);
    --line-height: var(--line-height-2);
    --letter-spacing: var(--letter-spacing-2);
    --checkbox-cards-item-padding-top: calc(var(--space-3) / 1.2);
    --checkbox-cards-item-padding-bottom: calc(var(--space-3) / 1.2);
    --checkbox-cards-item-padding-left: var(--space-3);
    --checkbox-cards-item-border-radius: var(--radius-3);
    --checkbox-cards-item-checkbox-size: calc(var(--space-4) * .875);
  }

  .rt-CheckboxCardsRoot:where(.sm\:rt-r-size-2) {
    font-size: var(--font-size-2);
    --line-height: var(--line-height-2);
    --letter-spacing: var(--letter-spacing-2);
    --checkbox-cards-item-padding-top: calc(var(--space-4) * .875);
    --checkbox-cards-item-padding-bottom: calc(var(--space-4) * .875);
    --checkbox-cards-item-padding-left: var(--space-4);
    --checkbox-cards-item-border-radius: var(--radius-3);
    --checkbox-cards-item-checkbox-size: var(--space-4);
  }

  .rt-CheckboxCardsRoot:where(.sm\:rt-r-size-3) {
    font-size: var(--font-size-3);
    --line-height: var(--line-height-3);
    --letter-spacing: var(--letter-spacing-3);
    --checkbox-cards-item-padding-top: calc(var(--space-5) / 1.2);
    --checkbox-cards-item-padding-bottom: calc(var(--space-5) / 1.2);
    --checkbox-cards-item-padding-left: var(--space-5);
    --checkbox-cards-item-border-radius: var(--radius-4);
    --checkbox-cards-item-checkbox-size: calc(var(--space-4) * 1.25);
  }
}

@media (min-width: 1024px) {
  .rt-CheckboxCardsRoot:where(.md\:rt-r-size-1) {
    font-size: var(--font-size-2);
    --line-height: var(--line-height-2);
    --letter-spacing: var(--letter-spacing-2);
    --checkbox-cards-item-padding-top: calc(var(--space-3) / 1.2);
    --checkbox-cards-item-padding-bottom: calc(var(--space-3) / 1.2);
    --checkbox-cards-item-padding-left: var(--space-3);
    --checkbox-cards-item-border-radius: var(--radius-3);
    --checkbox-cards-item-checkbox-size: calc(var(--space-4) * .875);
  }

  .rt-CheckboxCardsRoot:where(.md\:rt-r-size-2) {
    font-size: var(--font-size-2);
    --line-height: var(--line-height-2);
    --letter-spacing: var(--letter-spacing-2);
    --checkbox-cards-item-padding-top: calc(var(--space-4) * .875);
    --checkbox-cards-item-padding-bottom: calc(var(--space-4) * .875);
    --checkbox-cards-item-padding-left: var(--space-4);
    --checkbox-cards-item-border-radius: var(--radius-3);
    --checkbox-cards-item-checkbox-size: var(--space-4);
  }

  .rt-CheckboxCardsRoot:where(.md\:rt-r-size-3) {
    font-size: var(--font-size-3);
    --line-height: var(--line-height-3);
    --letter-spacing: var(--letter-spacing-3);
    --checkbox-cards-item-padding-top: calc(var(--space-5) / 1.2);
    --checkbox-cards-item-padding-bottom: calc(var(--space-5) / 1.2);
    --checkbox-cards-item-padding-left: var(--space-5);
    --checkbox-cards-item-border-radius: var(--radius-4);
    --checkbox-cards-item-checkbox-size: calc(var(--space-4) * 1.25);
  }
}

@media (min-width: 1280px) {
  .rt-CheckboxCardsRoot:where(.lg\:rt-r-size-1) {
    font-size: var(--font-size-2);
    --line-height: var(--line-height-2);
    --letter-spacing: var(--letter-spacing-2);
    --checkbox-cards-item-padding-top: calc(var(--space-3) / 1.2);
    --checkbox-cards-item-padding-bottom: calc(var(--space-3) / 1.2);
    --checkbox-cards-item-padding-left: var(--space-3);
    --checkbox-cards-item-border-radius: var(--radius-3);
    --checkbox-cards-item-checkbox-size: calc(var(--space-4) * .875);
  }

  .rt-CheckboxCardsRoot:where(.lg\:rt-r-size-2) {
    font-size: var(--font-size-2);
    --line-height: var(--line-height-2);
    --letter-spacing: var(--letter-spacing-2);
    --checkbox-cards-item-padding-top: calc(var(--space-4) * .875);
    --checkbox-cards-item-padding-bottom: calc(var(--space-4) * .875);
    --checkbox-cards-item-padding-left: var(--space-4);
    --checkbox-cards-item-border-radius: var(--radius-3);
    --checkbox-cards-item-checkbox-size: var(--space-4);
  }

  .rt-CheckboxCardsRoot:where(.lg\:rt-r-size-3) {
    font-size: var(--font-size-3);
    --line-height: var(--line-height-3);
    --letter-spacing: var(--letter-spacing-3);
    --checkbox-cards-item-padding-top: calc(var(--space-5) / 1.2);
    --checkbox-cards-item-padding-bottom: calc(var(--space-5) / 1.2);
    --checkbox-cards-item-padding-left: var(--space-5);
    --checkbox-cards-item-border-radius: var(--radius-4);
    --checkbox-cards-item-checkbox-size: calc(var(--space-4) * 1.25);
  }
}

@media (min-width: 1640px) {
  .rt-CheckboxCardsRoot:where(.xl\:rt-r-size-1) {
    font-size: var(--font-size-2);
    --line-height: var(--line-height-2);
    --letter-spacing: var(--letter-spacing-2);
    --checkbox-cards-item-padding-top: calc(var(--space-3) / 1.2);
    --checkbox-cards-item-padding-bottom: calc(var(--space-3) / 1.2);
    --checkbox-cards-item-padding-left: var(--space-3);
    --checkbox-cards-item-border-radius: var(--radius-3);
    --checkbox-cards-item-checkbox-size: calc(var(--space-4) * .875);
  }

  .rt-CheckboxCardsRoot:where(.xl\:rt-r-size-2) {
    font-size: var(--font-size-2);
    --line-height: var(--line-height-2);
    --letter-spacing: var(--letter-spacing-2);
    --checkbox-cards-item-padding-top: calc(var(--space-4) * .875);
    --checkbox-cards-item-padding-bottom: calc(var(--space-4) * .875);
    --checkbox-cards-item-padding-left: var(--space-4);
    --checkbox-cards-item-border-radius: var(--radius-3);
    --checkbox-cards-item-checkbox-size: var(--space-4);
  }

  .rt-CheckboxCardsRoot:where(.xl\:rt-r-size-3) {
    font-size: var(--font-size-3);
    --line-height: var(--line-height-3);
    --letter-spacing: var(--letter-spacing-3);
    --checkbox-cards-item-padding-top: calc(var(--space-5) / 1.2);
    --checkbox-cards-item-padding-bottom: calc(var(--space-5) / 1.2);
    --checkbox-cards-item-padding-left: var(--space-5);
    --checkbox-cards-item-border-radius: var(--radius-4);
    --checkbox-cards-item-checkbox-size: calc(var(--space-4) * 1.25);
  }
}

:where(.rt-CheckboxCardsRoot.rt-variant-surface) .rt-CheckboxCardsItem {
  --checkbox-cards-item-border-width: 1px;
  --checkbox-cards-item-background-color: var(--color-surface);
}

:where(.rt-CheckboxCardsRoot.rt-variant-surface) .rt-CheckboxCardsItem:before {
  background-color: var(--checkbox-cards-item-background-color);
}

:where(.rt-CheckboxCardsRoot.rt-variant-surface) .rt-CheckboxCardsItem:after {
  box-shadow: var(--base-card-surface-box-shadow);
}

@media (hover: hover) {
  :where(.rt-CheckboxCardsRoot.rt-variant-surface) .rt-CheckboxCardsItem:where(:not(:has(:disabled)):hover):after {
    box-shadow: var(--base-card-surface-hover-box-shadow);
  }
}

:where(.rt-CheckboxCardsRoot.rt-variant-classic) .rt-CheckboxCardsItem {
  --checkbox-cards-item-border-width: 1px;
  --checkbox-cards-item-background-color: var(--color-surface);
  box-shadow: var(--base-card-classic-box-shadow-outer);
  transition: box-shadow .12s;
}

:where(.rt-CheckboxCardsRoot.rt-variant-classic) .rt-CheckboxCardsItem:before {
  background-color: var(--checkbox-cards-item-background-color);
}

:where(.rt-CheckboxCardsRoot.rt-variant-classic) .rt-CheckboxCardsItem:after {
  box-shadow: var(--base-card-classic-box-shadow-inner);
}

@media (hover: hover) {
  :where(.rt-CheckboxCardsRoot.rt-variant-classic) .rt-CheckboxCardsItem:where(:not(:has(:disabled)):hover) {
    box-shadow: var(--base-card-classic-hover-box-shadow-outer);
    transition-duration: 40ms;
  }

  :where(.rt-CheckboxCardsRoot.rt-variant-classic) .rt-CheckboxCardsItem:where(:not(:has(:disabled)):hover):after {
    box-shadow: var(--base-card-classic-hover-box-shadow-inner);
  }
}

@media (pointer: coarse) {
  .rt-CheckboxCardsItem:where(:active:not(:focus-visible)):before {
    background-image: linear-gradient(var(--gray-a4), var(--gray-a4));
  }
}

.rt-CheckboxCardsItem:where(:has(:disabled)) {
  cursor: var(--cursor-disabled);
  color: var(--gray-a9);
}

.rt-CheckboxCardsItem:where(:has(:disabled)):before {
  background-image: linear-gradient(var(--gray-a2), var(--gray-a2));
}

.rt-CheckboxCardsItem:where(:has(:disabled))::selection {
  background-color: var(--gray-a5);
}

.rt-CheckboxGroupRoot {
  gap: var(--space-1);
  flex-direction: column;
  display: flex;
}

.rt-CheckboxGroupItem {
  gap: .5em;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
}

.rt-CheckboxGroupItemCheckbox:where(:focus-visible):before {
  outline: 2px solid var(--focus-8);
  outline-offset: 2px;
}

.rt-CheckboxGroupItemCheckbox:where(:disabled) {
  cursor: var(--cursor-disabled);
}

.rt-CheckboxGroupItemCheckbox:where(:disabled):before {
  background-color: var(--gray-a3);
}

.rt-CheckboxGroupItemInner {
  min-width: 0;
}

.rt-CheckboxRoot:where(:focus-visible):before {
  outline: 2px solid var(--focus-8);
  outline-offset: 2px;
}

.rt-CheckboxRoot:where(:disabled) {
  cursor: var(--cursor-disabled);
}

.rt-CheckboxRoot:where(:disabled):before {
  background-color: var(--gray-a3);
}

.rt-Code {
  --code-variant-font-size-adjust: calc(var(--code-font-size-adjust) * .95);
  font-family: var(--code-font-family);
  font-size: calc(var(--code-variant-font-size-adjust) * 1em);
  font-style: var(--code-font-style);
  font-weight: var(--code-font-weight);
  letter-spacing: calc(var(--code-letter-spacing)  + var(--letter-spacing, var(--default-letter-spacing)));
  border-radius: calc((.5px + .2em) * var(--radius-factor));
  box-sizing: border-box;
  padding-top: var(--code-padding-top);
  padding-left: var(--code-padding-left);
  padding-bottom: var(--code-padding-bottom);
  padding-right: var(--code-padding-right);
  height: -moz-fit-content;
  height: fit-content;
  line-height: 1.25;
}

.rt-Code :where(.rt-Code) {
  font-size: inherit;
}

.rt-Code:where(.rt-r-size-1) {
  font-size: calc(var(--font-size-1) * var(--code-variant-font-size-adjust));
  line-height: var(--line-height-1);
  --letter-spacing: var(--letter-spacing-1);
}

.rt-Code:where(.rt-r-size-2) {
  font-size: calc(var(--font-size-2) * var(--code-variant-font-size-adjust));
  line-height: var(--line-height-2);
  --letter-spacing: var(--letter-spacing-2);
}

.rt-Code:where(.rt-r-size-3) {
  font-size: calc(var(--font-size-3) * var(--code-variant-font-size-adjust));
  line-height: var(--line-height-3);
  --letter-spacing: var(--letter-spacing-3);
}

.rt-Code:where(.rt-r-size-4) {
  font-size: calc(var(--font-size-4) * var(--code-variant-font-size-adjust));
  line-height: var(--line-height-4);
  --letter-spacing: var(--letter-spacing-4);
}

.rt-Code:where(.rt-r-size-5) {
  font-size: calc(var(--font-size-5) * var(--code-variant-font-size-adjust));
  line-height: var(--line-height-5);
  --letter-spacing: var(--letter-spacing-5);
}

.rt-Code:where(.rt-r-size-6) {
  font-size: calc(var(--font-size-6) * var(--code-variant-font-size-adjust));
  line-height: var(--line-height-6);
  --letter-spacing: var(--letter-spacing-6);
}

.rt-Code:where(.rt-r-size-7) {
  font-size: calc(var(--font-size-7) * var(--code-variant-font-size-adjust));
  line-height: var(--line-height-7);
  --letter-spacing: var(--letter-spacing-7);
}

.rt-Code:where(.rt-r-size-8) {
  font-size: calc(var(--font-size-8) * var(--code-variant-font-size-adjust));
  line-height: var(--line-height-8);
  --letter-spacing: var(--letter-spacing-8);
}

.rt-Code:where(.rt-r-size-9) {
  font-size: calc(var(--font-size-9) * var(--code-variant-font-size-adjust));
  line-height: var(--line-height-9);
  --letter-spacing: var(--letter-spacing-9);
}

@media (min-width: 520px) {
  .rt-Code:where(.xs\:rt-r-size-1) {
    font-size: calc(var(--font-size-1) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-1);
    --letter-spacing: var(--letter-spacing-1);
  }

  .rt-Code:where(.xs\:rt-r-size-2) {
    font-size: calc(var(--font-size-2) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-2);
    --letter-spacing: var(--letter-spacing-2);
  }

  .rt-Code:where(.xs\:rt-r-size-3) {
    font-size: calc(var(--font-size-3) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-3);
    --letter-spacing: var(--letter-spacing-3);
  }

  .rt-Code:where(.xs\:rt-r-size-4) {
    font-size: calc(var(--font-size-4) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-4);
    --letter-spacing: var(--letter-spacing-4);
  }

  .rt-Code:where(.xs\:rt-r-size-5) {
    font-size: calc(var(--font-size-5) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-5);
    --letter-spacing: var(--letter-spacing-5);
  }

  .rt-Code:where(.xs\:rt-r-size-6) {
    font-size: calc(var(--font-size-6) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-6);
    --letter-spacing: var(--letter-spacing-6);
  }

  .rt-Code:where(.xs\:rt-r-size-7) {
    font-size: calc(var(--font-size-7) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-7);
    --letter-spacing: var(--letter-spacing-7);
  }

  .rt-Code:where(.xs\:rt-r-size-8) {
    font-size: calc(var(--font-size-8) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-8);
    --letter-spacing: var(--letter-spacing-8);
  }

  .rt-Code:where(.xs\:rt-r-size-9) {
    font-size: calc(var(--font-size-9) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-9);
    --letter-spacing: var(--letter-spacing-9);
  }
}

@media (min-width: 768px) {
  .rt-Code:where(.sm\:rt-r-size-1) {
    font-size: calc(var(--font-size-1) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-1);
    --letter-spacing: var(--letter-spacing-1);
  }

  .rt-Code:where(.sm\:rt-r-size-2) {
    font-size: calc(var(--font-size-2) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-2);
    --letter-spacing: var(--letter-spacing-2);
  }

  .rt-Code:where(.sm\:rt-r-size-3) {
    font-size: calc(var(--font-size-3) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-3);
    --letter-spacing: var(--letter-spacing-3);
  }

  .rt-Code:where(.sm\:rt-r-size-4) {
    font-size: calc(var(--font-size-4) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-4);
    --letter-spacing: var(--letter-spacing-4);
  }

  .rt-Code:where(.sm\:rt-r-size-5) {
    font-size: calc(var(--font-size-5) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-5);
    --letter-spacing: var(--letter-spacing-5);
  }

  .rt-Code:where(.sm\:rt-r-size-6) {
    font-size: calc(var(--font-size-6) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-6);
    --letter-spacing: var(--letter-spacing-6);
  }

  .rt-Code:where(.sm\:rt-r-size-7) {
    font-size: calc(var(--font-size-7) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-7);
    --letter-spacing: var(--letter-spacing-7);
  }

  .rt-Code:where(.sm\:rt-r-size-8) {
    font-size: calc(var(--font-size-8) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-8);
    --letter-spacing: var(--letter-spacing-8);
  }

  .rt-Code:where(.sm\:rt-r-size-9) {
    font-size: calc(var(--font-size-9) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-9);
    --letter-spacing: var(--letter-spacing-9);
  }
}

@media (min-width: 1024px) {
  .rt-Code:where(.md\:rt-r-size-1) {
    font-size: calc(var(--font-size-1) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-1);
    --letter-spacing: var(--letter-spacing-1);
  }

  .rt-Code:where(.md\:rt-r-size-2) {
    font-size: calc(var(--font-size-2) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-2);
    --letter-spacing: var(--letter-spacing-2);
  }

  .rt-Code:where(.md\:rt-r-size-3) {
    font-size: calc(var(--font-size-3) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-3);
    --letter-spacing: var(--letter-spacing-3);
  }

  .rt-Code:where(.md\:rt-r-size-4) {
    font-size: calc(var(--font-size-4) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-4);
    --letter-spacing: var(--letter-spacing-4);
  }

  .rt-Code:where(.md\:rt-r-size-5) {
    font-size: calc(var(--font-size-5) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-5);
    --letter-spacing: var(--letter-spacing-5);
  }

  .rt-Code:where(.md\:rt-r-size-6) {
    font-size: calc(var(--font-size-6) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-6);
    --letter-spacing: var(--letter-spacing-6);
  }

  .rt-Code:where(.md\:rt-r-size-7) {
    font-size: calc(var(--font-size-7) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-7);
    --letter-spacing: var(--letter-spacing-7);
  }

  .rt-Code:where(.md\:rt-r-size-8) {
    font-size: calc(var(--font-size-8) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-8);
    --letter-spacing: var(--letter-spacing-8);
  }

  .rt-Code:where(.md\:rt-r-size-9) {
    font-size: calc(var(--font-size-9) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-9);
    --letter-spacing: var(--letter-spacing-9);
  }
}

@media (min-width: 1280px) {
  .rt-Code:where(.lg\:rt-r-size-1) {
    font-size: calc(var(--font-size-1) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-1);
    --letter-spacing: var(--letter-spacing-1);
  }

  .rt-Code:where(.lg\:rt-r-size-2) {
    font-size: calc(var(--font-size-2) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-2);
    --letter-spacing: var(--letter-spacing-2);
  }

  .rt-Code:where(.lg\:rt-r-size-3) {
    font-size: calc(var(--font-size-3) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-3);
    --letter-spacing: var(--letter-spacing-3);
  }

  .rt-Code:where(.lg\:rt-r-size-4) {
    font-size: calc(var(--font-size-4) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-4);
    --letter-spacing: var(--letter-spacing-4);
  }

  .rt-Code:where(.lg\:rt-r-size-5) {
    font-size: calc(var(--font-size-5) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-5);
    --letter-spacing: var(--letter-spacing-5);
  }

  .rt-Code:where(.lg\:rt-r-size-6) {
    font-size: calc(var(--font-size-6) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-6);
    --letter-spacing: var(--letter-spacing-6);
  }

  .rt-Code:where(.lg\:rt-r-size-7) {
    font-size: calc(var(--font-size-7) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-7);
    --letter-spacing: var(--letter-spacing-7);
  }

  .rt-Code:where(.lg\:rt-r-size-8) {
    font-size: calc(var(--font-size-8) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-8);
    --letter-spacing: var(--letter-spacing-8);
  }

  .rt-Code:where(.lg\:rt-r-size-9) {
    font-size: calc(var(--font-size-9) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-9);
    --letter-spacing: var(--letter-spacing-9);
  }
}

@media (min-width: 1640px) {
  .rt-Code:where(.xl\:rt-r-size-1) {
    font-size: calc(var(--font-size-1) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-1);
    --letter-spacing: var(--letter-spacing-1);
  }

  .rt-Code:where(.xl\:rt-r-size-2) {
    font-size: calc(var(--font-size-2) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-2);
    --letter-spacing: var(--letter-spacing-2);
  }

  .rt-Code:where(.xl\:rt-r-size-3) {
    font-size: calc(var(--font-size-3) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-3);
    --letter-spacing: var(--letter-spacing-3);
  }

  .rt-Code:where(.xl\:rt-r-size-4) {
    font-size: calc(var(--font-size-4) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-4);
    --letter-spacing: var(--letter-spacing-4);
  }

  .rt-Code:where(.xl\:rt-r-size-5) {
    font-size: calc(var(--font-size-5) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-5);
    --letter-spacing: var(--letter-spacing-5);
  }

  .rt-Code:where(.xl\:rt-r-size-6) {
    font-size: calc(var(--font-size-6) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-6);
    --letter-spacing: var(--letter-spacing-6);
  }

  .rt-Code:where(.xl\:rt-r-size-7) {
    font-size: calc(var(--font-size-7) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-7);
    --letter-spacing: var(--letter-spacing-7);
  }

  .rt-Code:where(.xl\:rt-r-size-8) {
    font-size: calc(var(--font-size-8) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-8);
    --letter-spacing: var(--letter-spacing-8);
  }

  .rt-Code:where(.xl\:rt-r-size-9) {
    font-size: calc(var(--font-size-9) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-9);
    --letter-spacing: var(--letter-spacing-9);
  }
}

.rt-Code:where(.rt-variant-ghost) {
  --code-variant-font-size-adjust: var(--code-font-size-adjust);
  padding: 0;
}

.rt-Code:where(.rt-variant-ghost):where([data-accent-color]) {
  color: var(--accent-a11);
}

.rt-Code:where(.rt-variant-ghost):where([data-accent-color].rt-high-contrast) {
  color: var(--accent-12);
}

:where([data-accent-color]:not(.radix-themes)) .rt-Code:where(.rt-variant-ghost):where(.rt-high-contrast) {
  color: var(--accent-12);
}

.rt-Code:where(.rt-variant-solid) {
  background-color: var(--accent-a9);
  color: var(--accent-contrast);
}

.rt-Code:where(.rt-variant-solid)::selection {
  background-color: var(--accent-7);
  color: var(--accent-12);
}

.rt-Code:where(.rt-variant-solid):where(.rt-high-contrast) {
  background-color: var(--accent-12);
  color: var(--accent-1);
}

.rt-Code:where(.rt-variant-solid):where(.rt-high-contrast)::selection {
  background-color: var(--accent-a11);
  color: var(--accent-1);
}

:where(.rt-Link) .rt-Code:where(.rt-variant-solid) {
  isolation: isolate;
}

.rt-Code:where(.rt-variant-solid):where(:-webkit-any-link, button) {
  isolation: isolate;
}

.rt-Code:where(.rt-variant-solid):where(:any-link, button) {
  isolation: isolate;
}

@media (hover: hover) {
  :where(.rt-Link) .rt-Code:where(.rt-variant-solid):where(:hover) {
    background-color: var(--accent-10);
  }

  .rt-Code:where(.rt-variant-solid):where(:-webkit-any-link, button):where(:hover) {
    background-color: var(--accent-10);
  }

  .rt-Code:where(.rt-variant-solid):where(:any-link, button):where(:hover) {
    background-color: var(--accent-10);
  }

  :where(.rt-Link) .rt-Code:where(.rt-variant-solid):where(.rt-high-contrast:hover) {
    background-color: var(--accent-12);
    filter: var(--base-button-solid-high-contrast-hover-filter);
  }

  .rt-Code:where(.rt-variant-solid):where(:-webkit-any-link, button):where(.rt-high-contrast:hover) {
    background-color: var(--accent-12);
    filter: var(--base-button-solid-high-contrast-hover-filter);
  }

  .rt-Code:where(.rt-variant-solid):where(:any-link, button):where(.rt-high-contrast:hover) {
    background-color: var(--accent-12);
    filter: var(--base-button-solid-high-contrast-hover-filter);
  }
}

.rt-Code:where(.rt-variant-soft) {
  background-color: var(--accent-a3);
  color: var(--accent-a11);
}

.rt-Code:where(.rt-variant-soft):where(.rt-high-contrast) {
  color: var(--accent-12);
}

:where(.rt-Link) .rt-Code:where(.rt-variant-soft) {
  isolation: isolate;
}

.rt-Code:where(.rt-variant-soft):where(:-webkit-any-link, button) {
  isolation: isolate;
}

.rt-Code:where(.rt-variant-soft):where(:any-link, button) {
  isolation: isolate;
}

@media (hover: hover) {
  :where(.rt-Link) .rt-Code:where(.rt-variant-soft):where(:hover) {
    background-color: var(--accent-a4);
  }

  .rt-Code:where(.rt-variant-soft):where(:-webkit-any-link, button):where(:hover) {
    background-color: var(--accent-a4);
  }

  .rt-Code:where(.rt-variant-soft):where(:any-link, button):where(:hover) {
    background-color: var(--accent-a4);
  }
}

.rt-Code:where(.rt-variant-outline) {
  box-shadow: inset 0 0 0 max(1px, .033em) var(--accent-a8);
  color: var(--accent-a11);
}

.rt-Code:where(.rt-variant-outline):where(.rt-high-contrast) {
  box-shadow: inset 0 0 0 max(1px, .033em) var(--accent-a7), inset 0 0 0 max(1px, .033em) var(--gray-a11);
  color: var(--accent-12);
}

:where(.rt-Link) .rt-Code:where(.rt-variant-outline) {
  isolation: isolate;
}

.rt-Code:where(.rt-variant-outline):where(:-webkit-any-link, button) {
  isolation: isolate;
}

.rt-Code:where(.rt-variant-outline):where(:any-link, button) {
  isolation: isolate;
}

@media (hover: hover) {
  :where(.rt-Link) .rt-Code:where(.rt-variant-outline):where(:hover) {
    background-color: var(--accent-a2);
  }

  .rt-Code:where(.rt-variant-outline):where(:-webkit-any-link, button):where(:hover) {
    background-color: var(--accent-a2);
  }

  .rt-Code:where(.rt-variant-outline):where(:any-link, button):where(:hover) {
    background-color: var(--accent-a2);
  }
}

.rt-BaseMenuContent {
  --scrollarea-scrollbar-vertical-margin-top: var(--base-menu-content-padding);
  --scrollarea-scrollbar-vertical-margin-bottom: var(--base-menu-content-padding);
  --scrollarea-scrollbar-horizontal-margin-left: var(--base-menu-content-padding);
  --scrollarea-scrollbar-horizontal-margin-right: var(--base-menu-content-padding);
  box-sizing: border-box;
  background-color: var(--base-menu-bg);
  --base-menu-bg: var(--color-panel-solid);
  box-shadow: var(--shadow-5);
  flex-direction: column;
  display: flex;
  overflow: hidden;
}

.rt-BaseMenuViewport {
  padding: var(--base-menu-content-padding);
  box-sizing: border-box;
  flex-direction: column;
  flex: 1;
  display: flex;
  overflow: auto;
}

:where(.rt-BaseMenuContent:has(.rt-ScrollAreaScrollbar[data-orientation="vertical"])) .rt-BaseMenuViewport {
  padding-right: var(--space-3);
}

.rt-BaseMenuItem {
  align-items: center;
  gap: var(--space-2);
  height: var(--base-menu-item-height);
  padding-left: var(--base-menu-item-padding-left);
  padding-right: var(--base-menu-item-padding-right);
  box-sizing: border-box;
  scroll-margin: var(--base-menu-content-padding) 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  cursor: var(--cursor-menu-item);
  outline: none;
  display: flex;
  position: relative;
}

.rt-BaseMenuShortcut {
  padding-left: var(--space-4);
  color: var(--gray-a11);
  align-items: center;
  margin-left: auto;
  display: flex;
}

.rt-BaseMenuSubTriggerIcon {
  color: var(--gray-12);
  margin-right: calc(-2px * var(--scaling));
}

.rt-BaseMenuItemIndicator {
  width: var(--base-menu-item-padding-left);
  justify-content: center;
  align-items: center;
  display: inline-flex;
  position: absolute;
  left: 0;
}

.rt-BaseMenuSeparator {
  height: 1px;
  margin-top: var(--space-2);
  margin-bottom: var(--space-2);
  margin-left: var(--base-menu-item-padding-left);
  margin-right: var(--base-menu-item-padding-right);
  background-color: var(--gray-a6);
}

.rt-BaseMenuLabel {
  height: var(--base-menu-item-height);
  padding-left: var(--base-menu-item-padding-left);
  padding-right: var(--base-menu-item-padding-right);
  box-sizing: border-box;
  color: var(--gray-a10);
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  cursor: default;
  align-items: center;
  display: flex;
}

:where(.rt-BaseMenuItem) + .rt-BaseMenuLabel {
  margin-top: var(--space-2);
}

.rt-BaseMenuArrow {
  fill: var(--base-menu-bg);
}

.rt-BaseMenuContent:where(.rt-r-size-1) {
  --base-menu-content-padding: var(--space-1);
  --base-menu-item-padding-left: calc(var(--space-5) / 1.2);
  --base-menu-item-padding-right: var(--space-2);
  --base-menu-item-height: var(--space-5);
  border-radius: var(--radius-3);
}

.rt-BaseMenuContent:where(.rt-r-size-1) :where(.rt-BaseMenuItem) {
  font-size: var(--font-size-1);
  line-height: var(--line-height-1);
  letter-spacing: var(--letter-spacing-1);
  border-radius: var(--radius-1);
}

.rt-BaseMenuContent:where(.rt-r-size-1) :where(.rt-BaseMenuLabel) {
  font-size: var(--font-size-1);
  line-height: var(--line-height-1);
  letter-spacing: var(--letter-spacing-1);
}

.rt-BaseMenuContent:where(.rt-r-size-1) :where(.rt-BaseMenuItemIndicatorIcon, .rt-BaseMenuSubTriggerIcon) {
  width: calc(8px * var(--scaling));
  height: calc(8px * var(--scaling));
}

.rt-BaseMenuContent:where(.rt-r-size-1):where(:not(:has(.rt-BaseMenuCheckboxItem, .rt-BaseMenuRadioItem))) {
  --base-menu-item-padding-left: var(--space-2);
}

.rt-BaseMenuContent:where(.rt-r-size-1):where(:has(.rt-BaseMenuCheckboxItem, .rt-BaseMenuRadioItem)) {
  --base-menu-item-padding-left: calc(var(--space-5) / 1.2);
}

.rt-BaseMenuContent:where(.rt-r-size-2) {
  --base-menu-content-padding: var(--space-2);
  --base-menu-item-padding-left: var(--space-3);
  --base-menu-item-padding-right: var(--space-3);
  --base-menu-item-height: var(--space-6);
  border-radius: var(--radius-4);
}

.rt-BaseMenuContent:where(.rt-r-size-2) :where(.rt-BaseMenuItem) {
  font-size: var(--font-size-2);
  line-height: var(--line-height-2);
  letter-spacing: var(--letter-spacing-2);
  border-radius: var(--radius-2);
}

.rt-BaseMenuContent:where(.rt-r-size-2) :where(.rt-BaseMenuLabel) {
  font-size: var(--font-size-2);
  line-height: var(--line-height-2);
  letter-spacing: var(--letter-spacing-2);
}

.rt-BaseMenuContent:where(.rt-r-size-2) :where(.rt-BaseMenuItemIndicatorIcon, .rt-BaseMenuSubTriggerIcon) {
  width: calc(10px * var(--scaling));
  height: calc(10px * var(--scaling));
}

.rt-BaseMenuContent:where(.rt-r-size-2):where(:not(:has(.rt-BaseMenuCheckboxItem, .rt-BaseMenuRadioItem))) {
  --base-menu-item-padding-left: var(--space-3);
}

.rt-BaseMenuContent:where(.rt-r-size-2):where(:has(.rt-BaseMenuCheckboxItem, .rt-BaseMenuRadioItem)) {
  --base-menu-item-padding-left: var(--space-5);
}

@media (min-width: 520px) {
  .rt-BaseMenuContent:where(.xs\:rt-r-size-1) {
    --base-menu-content-padding: var(--space-1);
    --base-menu-item-padding-left: calc(var(--space-5) / 1.2);
    --base-menu-item-padding-right: var(--space-2);
    --base-menu-item-height: var(--space-5);
    border-radius: var(--radius-3);
  }

  .rt-BaseMenuContent:where(.xs\:rt-r-size-1) :where(.rt-BaseMenuItem) {
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
    border-radius: var(--radius-1);
  }

  .rt-BaseMenuContent:where(.xs\:rt-r-size-1) :where(.rt-BaseMenuLabel) {
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
  }

  .rt-BaseMenuContent:where(.xs\:rt-r-size-1) :where(.rt-BaseMenuItemIndicatorIcon, .rt-BaseMenuSubTriggerIcon) {
    width: calc(8px * var(--scaling));
    height: calc(8px * var(--scaling));
  }

  .rt-BaseMenuContent:where(.xs\:rt-r-size-1):where(:not(:has(.rt-BaseMenuCheckboxItem, .rt-BaseMenuRadioItem))) {
    --base-menu-item-padding-left: var(--space-2);
  }

  .rt-BaseMenuContent:where(.xs\:rt-r-size-1):where(:has(.rt-BaseMenuCheckboxItem, .rt-BaseMenuRadioItem)) {
    --base-menu-item-padding-left: calc(var(--space-5) / 1.2);
  }

  .rt-BaseMenuContent:where(.xs\:rt-r-size-2) {
    --base-menu-content-padding: var(--space-2);
    --base-menu-item-padding-left: var(--space-3);
    --base-menu-item-padding-right: var(--space-3);
    --base-menu-item-height: var(--space-6);
    border-radius: var(--radius-4);
  }

  .rt-BaseMenuContent:where(.xs\:rt-r-size-2) :where(.rt-BaseMenuItem) {
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    letter-spacing: var(--letter-spacing-2);
    border-radius: var(--radius-2);
  }

  .rt-BaseMenuContent:where(.xs\:rt-r-size-2) :where(.rt-BaseMenuLabel) {
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    letter-spacing: var(--letter-spacing-2);
  }

  .rt-BaseMenuContent:where(.xs\:rt-r-size-2) :where(.rt-BaseMenuItemIndicatorIcon, .rt-BaseMenuSubTriggerIcon) {
    width: calc(10px * var(--scaling));
    height: calc(10px * var(--scaling));
  }

  .rt-BaseMenuContent:where(.xs\:rt-r-size-2):where(:not(:has(.rt-BaseMenuCheckboxItem, .rt-BaseMenuRadioItem))) {
    --base-menu-item-padding-left: var(--space-3);
  }

  .rt-BaseMenuContent:where(.xs\:rt-r-size-2):where(:has(.rt-BaseMenuCheckboxItem, .rt-BaseMenuRadioItem)) {
    --base-menu-item-padding-left: var(--space-5);
  }
}

@media (min-width: 768px) {
  .rt-BaseMenuContent:where(.sm\:rt-r-size-1) {
    --base-menu-content-padding: var(--space-1);
    --base-menu-item-padding-left: calc(var(--space-5) / 1.2);
    --base-menu-item-padding-right: var(--space-2);
    --base-menu-item-height: var(--space-5);
    border-radius: var(--radius-3);
  }

  .rt-BaseMenuContent:where(.sm\:rt-r-size-1) :where(.rt-BaseMenuItem) {
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
    border-radius: var(--radius-1);
  }

  .rt-BaseMenuContent:where(.sm\:rt-r-size-1) :where(.rt-BaseMenuLabel) {
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
  }

  .rt-BaseMenuContent:where(.sm\:rt-r-size-1) :where(.rt-BaseMenuItemIndicatorIcon, .rt-BaseMenuSubTriggerIcon) {
    width: calc(8px * var(--scaling));
    height: calc(8px * var(--scaling));
  }

  .rt-BaseMenuContent:where(.sm\:rt-r-size-1):where(:not(:has(.rt-BaseMenuCheckboxItem, .rt-BaseMenuRadioItem))) {
    --base-menu-item-padding-left: var(--space-2);
  }

  .rt-BaseMenuContent:where(.sm\:rt-r-size-1):where(:has(.rt-BaseMenuCheckboxItem, .rt-BaseMenuRadioItem)) {
    --base-menu-item-padding-left: calc(var(--space-5) / 1.2);
  }

  .rt-BaseMenuContent:where(.sm\:rt-r-size-2) {
    --base-menu-content-padding: var(--space-2);
    --base-menu-item-padding-left: var(--space-3);
    --base-menu-item-padding-right: var(--space-3);
    --base-menu-item-height: var(--space-6);
    border-radius: var(--radius-4);
  }

  .rt-BaseMenuContent:where(.sm\:rt-r-size-2) :where(.rt-BaseMenuItem) {
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    letter-spacing: var(--letter-spacing-2);
    border-radius: var(--radius-2);
  }

  .rt-BaseMenuContent:where(.sm\:rt-r-size-2) :where(.rt-BaseMenuLabel) {
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    letter-spacing: var(--letter-spacing-2);
  }

  .rt-BaseMenuContent:where(.sm\:rt-r-size-2) :where(.rt-BaseMenuItemIndicatorIcon, .rt-BaseMenuSubTriggerIcon) {
    width: calc(10px * var(--scaling));
    height: calc(10px * var(--scaling));
  }

  .rt-BaseMenuContent:where(.sm\:rt-r-size-2):where(:not(:has(.rt-BaseMenuCheckboxItem, .rt-BaseMenuRadioItem))) {
    --base-menu-item-padding-left: var(--space-3);
  }

  .rt-BaseMenuContent:where(.sm\:rt-r-size-2):where(:has(.rt-BaseMenuCheckboxItem, .rt-BaseMenuRadioItem)) {
    --base-menu-item-padding-left: var(--space-5);
  }
}

@media (min-width: 1024px) {
  .rt-BaseMenuContent:where(.md\:rt-r-size-1) {
    --base-menu-content-padding: var(--space-1);
    --base-menu-item-padding-left: calc(var(--space-5) / 1.2);
    --base-menu-item-padding-right: var(--space-2);
    --base-menu-item-height: var(--space-5);
    border-radius: var(--radius-3);
  }

  .rt-BaseMenuContent:where(.md\:rt-r-size-1) :where(.rt-BaseMenuItem) {
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
    border-radius: var(--radius-1);
  }

  .rt-BaseMenuContent:where(.md\:rt-r-size-1) :where(.rt-BaseMenuLabel) {
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
  }

  .rt-BaseMenuContent:where(.md\:rt-r-size-1) :where(.rt-BaseMenuItemIndicatorIcon, .rt-BaseMenuSubTriggerIcon) {
    width: calc(8px * var(--scaling));
    height: calc(8px * var(--scaling));
  }

  .rt-BaseMenuContent:where(.md\:rt-r-size-1):where(:not(:has(.rt-BaseMenuCheckboxItem, .rt-BaseMenuRadioItem))) {
    --base-menu-item-padding-left: var(--space-2);
  }

  .rt-BaseMenuContent:where(.md\:rt-r-size-1):where(:has(.rt-BaseMenuCheckboxItem, .rt-BaseMenuRadioItem)) {
    --base-menu-item-padding-left: calc(var(--space-5) / 1.2);
  }

  .rt-BaseMenuContent:where(.md\:rt-r-size-2) {
    --base-menu-content-padding: var(--space-2);
    --base-menu-item-padding-left: var(--space-3);
    --base-menu-item-padding-right: var(--space-3);
    --base-menu-item-height: var(--space-6);
    border-radius: var(--radius-4);
  }

  .rt-BaseMenuContent:where(.md\:rt-r-size-2) :where(.rt-BaseMenuItem) {
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    letter-spacing: var(--letter-spacing-2);
    border-radius: var(--radius-2);
  }

  .rt-BaseMenuContent:where(.md\:rt-r-size-2) :where(.rt-BaseMenuLabel) {
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    letter-spacing: var(--letter-spacing-2);
  }

  .rt-BaseMenuContent:where(.md\:rt-r-size-2) :where(.rt-BaseMenuItemIndicatorIcon, .rt-BaseMenuSubTriggerIcon) {
    width: calc(10px * var(--scaling));
    height: calc(10px * var(--scaling));
  }

  .rt-BaseMenuContent:where(.md\:rt-r-size-2):where(:not(:has(.rt-BaseMenuCheckboxItem, .rt-BaseMenuRadioItem))) {
    --base-menu-item-padding-left: var(--space-3);
  }

  .rt-BaseMenuContent:where(.md\:rt-r-size-2):where(:has(.rt-BaseMenuCheckboxItem, .rt-BaseMenuRadioItem)) {
    --base-menu-item-padding-left: var(--space-5);
  }
}

@media (min-width: 1280px) {
  .rt-BaseMenuContent:where(.lg\:rt-r-size-1) {
    --base-menu-content-padding: var(--space-1);
    --base-menu-item-padding-left: calc(var(--space-5) / 1.2);
    --base-menu-item-padding-right: var(--space-2);
    --base-menu-item-height: var(--space-5);
    border-radius: var(--radius-3);
  }

  .rt-BaseMenuContent:where(.lg\:rt-r-size-1) :where(.rt-BaseMenuItem) {
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
    border-radius: var(--radius-1);
  }

  .rt-BaseMenuContent:where(.lg\:rt-r-size-1) :where(.rt-BaseMenuLabel) {
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
  }

  .rt-BaseMenuContent:where(.lg\:rt-r-size-1) :where(.rt-BaseMenuItemIndicatorIcon, .rt-BaseMenuSubTriggerIcon) {
    width: calc(8px * var(--scaling));
    height: calc(8px * var(--scaling));
  }

  .rt-BaseMenuContent:where(.lg\:rt-r-size-1):where(:not(:has(.rt-BaseMenuCheckboxItem, .rt-BaseMenuRadioItem))) {
    --base-menu-item-padding-left: var(--space-2);
  }

  .rt-BaseMenuContent:where(.lg\:rt-r-size-1):where(:has(.rt-BaseMenuCheckboxItem, .rt-BaseMenuRadioItem)) {
    --base-menu-item-padding-left: calc(var(--space-5) / 1.2);
  }

  .rt-BaseMenuContent:where(.lg\:rt-r-size-2) {
    --base-menu-content-padding: var(--space-2);
    --base-menu-item-padding-left: var(--space-3);
    --base-menu-item-padding-right: var(--space-3);
    --base-menu-item-height: var(--space-6);
    border-radius: var(--radius-4);
  }

  .rt-BaseMenuContent:where(.lg\:rt-r-size-2) :where(.rt-BaseMenuItem) {
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    letter-spacing: var(--letter-spacing-2);
    border-radius: var(--radius-2);
  }

  .rt-BaseMenuContent:where(.lg\:rt-r-size-2) :where(.rt-BaseMenuLabel) {
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    letter-spacing: var(--letter-spacing-2);
  }

  .rt-BaseMenuContent:where(.lg\:rt-r-size-2) :where(.rt-BaseMenuItemIndicatorIcon, .rt-BaseMenuSubTriggerIcon) {
    width: calc(10px * var(--scaling));
    height: calc(10px * var(--scaling));
  }

  .rt-BaseMenuContent:where(.lg\:rt-r-size-2):where(:not(:has(.rt-BaseMenuCheckboxItem, .rt-BaseMenuRadioItem))) {
    --base-menu-item-padding-left: var(--space-3);
  }

  .rt-BaseMenuContent:where(.lg\:rt-r-size-2):where(:has(.rt-BaseMenuCheckboxItem, .rt-BaseMenuRadioItem)) {
    --base-menu-item-padding-left: var(--space-5);
  }
}

@media (min-width: 1640px) {
  .rt-BaseMenuContent:where(.xl\:rt-r-size-1) {
    --base-menu-content-padding: var(--space-1);
    --base-menu-item-padding-left: calc(var(--space-5) / 1.2);
    --base-menu-item-padding-right: var(--space-2);
    --base-menu-item-height: var(--space-5);
    border-radius: var(--radius-3);
  }

  .rt-BaseMenuContent:where(.xl\:rt-r-size-1) :where(.rt-BaseMenuItem) {
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
    border-radius: var(--radius-1);
  }

  .rt-BaseMenuContent:where(.xl\:rt-r-size-1) :where(.rt-BaseMenuLabel) {
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
  }

  .rt-BaseMenuContent:where(.xl\:rt-r-size-1) :where(.rt-BaseMenuItemIndicatorIcon, .rt-BaseMenuSubTriggerIcon) {
    width: calc(8px * var(--scaling));
    height: calc(8px * var(--scaling));
  }

  .rt-BaseMenuContent:where(.xl\:rt-r-size-1):where(:not(:has(.rt-BaseMenuCheckboxItem, .rt-BaseMenuRadioItem))) {
    --base-menu-item-padding-left: var(--space-2);
  }

  .rt-BaseMenuContent:where(.xl\:rt-r-size-1):where(:has(.rt-BaseMenuCheckboxItem, .rt-BaseMenuRadioItem)) {
    --base-menu-item-padding-left: calc(var(--space-5) / 1.2);
  }

  .rt-BaseMenuContent:where(.xl\:rt-r-size-2) {
    --base-menu-content-padding: var(--space-2);
    --base-menu-item-padding-left: var(--space-3);
    --base-menu-item-padding-right: var(--space-3);
    --base-menu-item-height: var(--space-6);
    border-radius: var(--radius-4);
  }

  .rt-BaseMenuContent:where(.xl\:rt-r-size-2) :where(.rt-BaseMenuItem) {
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    letter-spacing: var(--letter-spacing-2);
    border-radius: var(--radius-2);
  }

  .rt-BaseMenuContent:where(.xl\:rt-r-size-2) :where(.rt-BaseMenuLabel) {
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    letter-spacing: var(--letter-spacing-2);
  }

  .rt-BaseMenuContent:where(.xl\:rt-r-size-2) :where(.rt-BaseMenuItemIndicatorIcon, .rt-BaseMenuSubTriggerIcon) {
    width: calc(10px * var(--scaling));
    height: calc(10px * var(--scaling));
  }

  .rt-BaseMenuContent:where(.xl\:rt-r-size-2):where(:not(:has(.rt-BaseMenuCheckboxItem, .rt-BaseMenuRadioItem))) {
    --base-menu-item-padding-left: var(--space-3);
  }

  .rt-BaseMenuContent:where(.xl\:rt-r-size-2):where(:has(.rt-BaseMenuCheckboxItem, .rt-BaseMenuRadioItem)) {
    --base-menu-item-padding-left: var(--space-5);
  }
}

.rt-BaseMenuItem:where([data-accent-color]) {
  color: var(--accent-a11);
}

.rt-BaseMenuItem:where([data-disabled]) {
  color: var(--gray-a8);
  cursor: default;
}

.rt-BaseMenuItem:where([data-disabled], [data-highlighted]) :where(.rt-BaseMenuShortcut) {
  color: inherit;
}

.rt-BaseMenuSubTrigger:where([data-state="open"]) :where(.rt-BaseMenuShortcut) {
  color: inherit;
}

.rt-BaseMenuContent:where(.rt-variant-solid) :where(.rt-BaseMenuSubTrigger[data-state="open"]) {
  background-color: var(--gray-a3);
}

.rt-BaseMenuContent:where(.rt-variant-solid) :where(.rt-BaseMenuItem[data-highlighted]) {
  background-color: var(--accent-9);
  color: var(--accent-contrast);
}

.rt-BaseMenuContent:where(.rt-variant-solid) :where(.rt-BaseMenuItem[data-highlighted]) :where(.rt-BaseMenuSubTriggerIcon) {
  color: var(--accent-contrast);
}

.rt-BaseMenuContent:where(.rt-variant-solid):where(.rt-high-contrast) :where(.rt-BaseMenuItem[data-highlighted]) {
  background-color: var(--accent-12);
  color: var(--accent-1);
}

.rt-BaseMenuContent:where(.rt-variant-solid):where(.rt-high-contrast) :where(.rt-BaseMenuItem[data-highlighted]) :where(.rt-BaseMenuSubTriggerIcon) {
  color: var(--accent-1);
}

.rt-BaseMenuContent:where(.rt-variant-solid):where(.rt-high-contrast) :where(.rt-BaseMenuItem[data-highlighted]):where([data-accent-color]) {
  background-color: var(--accent-9);
  color: var(--accent-contrast);
}

.rt-BaseMenuContent:where(.rt-variant-soft) :where(.rt-BaseMenuSubTrigger[data-state="open"]) {
  background-color: var(--accent-a3);
}

.rt-BaseMenuContent:where(.rt-variant-soft) :where(.rt-BaseMenuItem[data-highlighted]) {
  background-color: var(--accent-a4);
}

.rt-ContextMenuContent {
  max-height: var(--radix-context-menu-content-available-height);
  transform-origin: var(--radix-context-menu-content-transform-origin);
}

.rt-DataListRoot {
  overflow-wrap: anywhere;
  font-family: var(--default-font-family);
  font-weight: var(--font-weight-normal);
  text-align: start;
  --data-list-leading-trim-start: calc(var(--default-leading-trim-start)  - var(--line-height) / 2);
  --data-list-leading-trim-end: calc(var(--default-leading-trim-end)  - var(--line-height) / 2);
  font-style: normal;
}

.rt-DataListLabel {
  color: var(--gray-a11);
  display: flex;
}

.rt-DataListLabel:where(.rt-high-contrast) {
  color: var(--gray-12);
}

.rt-DataListLabel:where([data-accent-color]) {
  color: var(--accent-a11);
}

.rt-DataListLabel:where([data-accent-color]):where(.rt-high-contrast) {
  color: var(--accent-12);
}

.rt-DataListValue {
  min-width: 0;
  margin: 0;
  margin-top: var(--data-list-value-margin-top);
  margin-bottom: var(--data-list-value-margin-bottom);
  display: flex;
}

.rt-DataListItem {
  --data-list-value-margin-top: 0px;
  --data-list-value-margin-bottom: 0px;
  --data-list-first-item-value-margin-top: 0px;
  --data-list-last-item-value-margin-bottom: 0px;
  --data-list-value-trim-start: -.25em;
  --data-list-value-trim-end: -.25em;
  --data-list-first-item-value-trim-start: 0px;
  --data-list-last-item-value-trim-end: 0px;
}

:where(.rt-DataListItem:first-child) .rt-DataListValue {
  margin-top: var(--data-list-first-item-value-margin-top);
}

:where(.rt-DataListItem:last-child) .rt-DataListValue {
  margin-bottom: var(--data-list-last-item-value-margin-bottom);
}

.rt-DataListRoot:where(.rt-r-size-1) {
  gap: var(--space-3);
}

.rt-DataListRoot:where(.rt-r-size-2) {
  gap: var(--space-4);
}

.rt-DataListRoot:where(.rt-r-size-3) {
  gap: calc(var(--space-4) * 1.25);
}

@media (min-width: 520px) {
  .rt-DataListRoot:where(.xs\:rt-r-size-1) {
    gap: var(--space-3);
  }

  .rt-DataListRoot:where(.xs\:rt-r-size-2) {
    gap: var(--space-4);
  }

  .rt-DataListRoot:where(.xs\:rt-r-size-3) {
    gap: calc(var(--space-4) * 1.25);
  }
}

@media (min-width: 768px) {
  .rt-DataListRoot:where(.sm\:rt-r-size-1) {
    gap: var(--space-3);
  }

  .rt-DataListRoot:where(.sm\:rt-r-size-2) {
    gap: var(--space-4);
  }

  .rt-DataListRoot:where(.sm\:rt-r-size-3) {
    gap: calc(var(--space-4) * 1.25);
  }
}

@media (min-width: 1024px) {
  .rt-DataListRoot:where(.md\:rt-r-size-1) {
    gap: var(--space-3);
  }

  .rt-DataListRoot:where(.md\:rt-r-size-2) {
    gap: var(--space-4);
  }

  .rt-DataListRoot:where(.md\:rt-r-size-3) {
    gap: calc(var(--space-4) * 1.25);
  }
}

@media (min-width: 1280px) {
  .rt-DataListRoot:where(.lg\:rt-r-size-1) {
    gap: var(--space-3);
  }

  .rt-DataListRoot:where(.lg\:rt-r-size-2) {
    gap: var(--space-4);
  }

  .rt-DataListRoot:where(.lg\:rt-r-size-3) {
    gap: calc(var(--space-4) * 1.25);
  }
}

@media (min-width: 1640px) {
  .rt-DataListRoot:where(.xl\:rt-r-size-1) {
    gap: var(--space-3);
  }

  .rt-DataListRoot:where(.xl\:rt-r-size-2) {
    gap: var(--space-4);
  }

  .rt-DataListRoot:where(.xl\:rt-r-size-3) {
    gap: calc(var(--space-4) * 1.25);
  }
}

.rt-DataListRoot:where(.rt-r-orientation-vertical) {
  flex-direction: column;
  display: flex;
}

.rt-DataListRoot:where(.rt-r-orientation-vertical) :where(.rt-DataListItem) {
  --data-list-value-margin-top: 0px;
  --data-list-value-margin-bottom: 0px;
  --data-list-first-item-value-margin-top: 0px;
  --data-list-last-item-value-margin-bottom: 0px;
  gap: var(--space-1);
  flex-direction: column;
  display: flex;
}

.rt-DataListRoot:where(.rt-r-orientation-vertical) :where(.rt-DataListLabel) {
  min-width: 0;
}

.rt-DataListRoot:where(.rt-r-orientation-horizontal) {
  grid-template-columns: auto 1fr;
  display: grid;
}

.rt-DataListRoot:where(.rt-r-orientation-horizontal) :where(.rt-DataListItem) {
  --data-list-value-margin-top: var(--data-list-value-trim-start);
  --data-list-value-margin-bottom: var(--data-list-value-trim-end);
  --data-list-first-item-value-margin-top: var(--data-list-first-item-value-trim-start);
  --data-list-last-item-value-margin-bottom: var(--data-list-last-item-value-trim-end);
  grid-template-columns: inherit;
  grid-template-columns: subgrid;
  gap: inherit;
  grid-column: span 2;
  align-items: baseline;
  display: grid;
}

.rt-DataListRoot:where(.rt-r-orientation-horizontal) :where(.rt-DataListLabel) {
  min-width: 120px;
}

@media (min-width: 520px) {
  .rt-DataListRoot:where(.xs\:rt-r-orientation-vertical) {
    flex-direction: column;
    display: flex;
  }

  .rt-DataListRoot:where(.xs\:rt-r-orientation-vertical) :where(.rt-DataListItem) {
    --data-list-value-margin-top: 0px;
    --data-list-value-margin-bottom: 0px;
    --data-list-first-item-value-margin-top: 0px;
    --data-list-last-item-value-margin-bottom: 0px;
    gap: var(--space-1);
    flex-direction: column;
    display: flex;
  }

  .rt-DataListRoot:where(.xs\:rt-r-orientation-vertical) :where(.rt-DataListLabel) {
    min-width: 0;
  }

  .rt-DataListRoot:where(.xs\:rt-r-orientation-horizontal) {
    grid-template-columns: auto 1fr;
    display: grid;
  }

  .rt-DataListRoot:where(.xs\:rt-r-orientation-horizontal) :where(.rt-DataListItem) {
    --data-list-value-margin-top: var(--data-list-value-trim-start);
    --data-list-value-margin-bottom: var(--data-list-value-trim-end);
    --data-list-first-item-value-margin-top: var(--data-list-first-item-value-trim-start);
    --data-list-last-item-value-margin-bottom: var(--data-list-last-item-value-trim-end);
    grid-template-columns: inherit;
    grid-template-columns: subgrid;
    gap: inherit;
    grid-column: span 2;
    align-items: baseline;
    display: grid;
  }

  .rt-DataListRoot:where(.xs\:rt-r-orientation-horizontal) :where(.rt-DataListLabel) {
    min-width: 120px;
  }
}

@media (min-width: 768px) {
  .rt-DataListRoot:where(.sm\:rt-r-orientation-vertical) {
    flex-direction: column;
    display: flex;
  }

  .rt-DataListRoot:where(.sm\:rt-r-orientation-vertical) :where(.rt-DataListItem) {
    --data-list-value-margin-top: 0px;
    --data-list-value-margin-bottom: 0px;
    --data-list-first-item-value-margin-top: 0px;
    --data-list-last-item-value-margin-bottom: 0px;
    gap: var(--space-1);
    flex-direction: column;
    display: flex;
  }

  .rt-DataListRoot:where(.sm\:rt-r-orientation-vertical) :where(.rt-DataListLabel) {
    min-width: 0;
  }

  .rt-DataListRoot:where(.sm\:rt-r-orientation-horizontal) {
    grid-template-columns: auto 1fr;
    display: grid;
  }

  .rt-DataListRoot:where(.sm\:rt-r-orientation-horizontal) :where(.rt-DataListItem) {
    --data-list-value-margin-top: var(--data-list-value-trim-start);
    --data-list-value-margin-bottom: var(--data-list-value-trim-end);
    --data-list-first-item-value-margin-top: var(--data-list-first-item-value-trim-start);
    --data-list-last-item-value-margin-bottom: var(--data-list-last-item-value-trim-end);
    grid-template-columns: inherit;
    grid-template-columns: subgrid;
    gap: inherit;
    grid-column: span 2;
    align-items: baseline;
    display: grid;
  }

  .rt-DataListRoot:where(.sm\:rt-r-orientation-horizontal) :where(.rt-DataListLabel) {
    min-width: 120px;
  }
}

@media (min-width: 1024px) {
  .rt-DataListRoot:where(.md\:rt-r-orientation-vertical) {
    flex-direction: column;
    display: flex;
  }

  .rt-DataListRoot:where(.md\:rt-r-orientation-vertical) :where(.rt-DataListItem) {
    --data-list-value-margin-top: 0px;
    --data-list-value-margin-bottom: 0px;
    --data-list-first-item-value-margin-top: 0px;
    --data-list-last-item-value-margin-bottom: 0px;
    gap: var(--space-1);
    flex-direction: column;
    display: flex;
  }

  .rt-DataListRoot:where(.md\:rt-r-orientation-vertical) :where(.rt-DataListLabel) {
    min-width: 0;
  }

  .rt-DataListRoot:where(.md\:rt-r-orientation-horizontal) {
    grid-template-columns: auto 1fr;
    display: grid;
  }

  .rt-DataListRoot:where(.md\:rt-r-orientation-horizontal) :where(.rt-DataListItem) {
    --data-list-value-margin-top: var(--data-list-value-trim-start);
    --data-list-value-margin-bottom: var(--data-list-value-trim-end);
    --data-list-first-item-value-margin-top: var(--data-list-first-item-value-trim-start);
    --data-list-last-item-value-margin-bottom: var(--data-list-last-item-value-trim-end);
    grid-template-columns: inherit;
    grid-template-columns: subgrid;
    gap: inherit;
    grid-column: span 2;
    align-items: baseline;
    display: grid;
  }

  .rt-DataListRoot:where(.md\:rt-r-orientation-horizontal) :where(.rt-DataListLabel) {
    min-width: 120px;
  }
}

@media (min-width: 1280px) {
  .rt-DataListRoot:where(.lg\:rt-r-orientation-vertical) {
    flex-direction: column;
    display: flex;
  }

  .rt-DataListRoot:where(.lg\:rt-r-orientation-vertical) :where(.rt-DataListItem) {
    --data-list-value-margin-top: 0px;
    --data-list-value-margin-bottom: 0px;
    --data-list-first-item-value-margin-top: 0px;
    --data-list-last-item-value-margin-bottom: 0px;
    gap: var(--space-1);
    flex-direction: column;
    display: flex;
  }

  .rt-DataListRoot:where(.lg\:rt-r-orientation-vertical) :where(.rt-DataListLabel) {
    min-width: 0;
  }

  .rt-DataListRoot:where(.lg\:rt-r-orientation-horizontal) {
    grid-template-columns: auto 1fr;
    display: grid;
  }

  .rt-DataListRoot:where(.lg\:rt-r-orientation-horizontal) :where(.rt-DataListItem) {
    --data-list-value-margin-top: var(--data-list-value-trim-start);
    --data-list-value-margin-bottom: var(--data-list-value-trim-end);
    --data-list-first-item-value-margin-top: var(--data-list-first-item-value-trim-start);
    --data-list-last-item-value-margin-bottom: var(--data-list-last-item-value-trim-end);
    grid-template-columns: inherit;
    grid-template-columns: subgrid;
    gap: inherit;
    grid-column: span 2;
    align-items: baseline;
    display: grid;
  }

  .rt-DataListRoot:where(.lg\:rt-r-orientation-horizontal) :where(.rt-DataListLabel) {
    min-width: 120px;
  }
}

@media (min-width: 1640px) {
  .rt-DataListRoot:where(.xl\:rt-r-orientation-vertical) {
    flex-direction: column;
    display: flex;
  }

  .rt-DataListRoot:where(.xl\:rt-r-orientation-vertical) :where(.rt-DataListItem) {
    --data-list-value-margin-top: 0px;
    --data-list-value-margin-bottom: 0px;
    --data-list-first-item-value-margin-top: 0px;
    --data-list-last-item-value-margin-bottom: 0px;
    gap: var(--space-1);
    flex-direction: column;
    display: flex;
  }

  .rt-DataListRoot:where(.xl\:rt-r-orientation-vertical) :where(.rt-DataListLabel) {
    min-width: 0;
  }

  .rt-DataListRoot:where(.xl\:rt-r-orientation-horizontal) {
    grid-template-columns: auto 1fr;
    display: grid;
  }

  .rt-DataListRoot:where(.xl\:rt-r-orientation-horizontal) :where(.rt-DataListItem) {
    --data-list-value-margin-top: var(--data-list-value-trim-start);
    --data-list-value-margin-bottom: var(--data-list-value-trim-end);
    --data-list-first-item-value-margin-top: var(--data-list-first-item-value-trim-start);
    --data-list-last-item-value-margin-bottom: var(--data-list-last-item-value-trim-end);
    grid-template-columns: inherit;
    grid-template-columns: subgrid;
    gap: inherit;
    grid-column: span 2;
    align-items: baseline;
    display: grid;
  }

  .rt-DataListRoot:where(.xl\:rt-r-orientation-horizontal) :where(.rt-DataListLabel) {
    min-width: 120px;
  }
}

.rt-DataListLabel:before, .rt-DataListValue:before {
  content: "‍";
}

.rt-DataListItem:where(.rt-r-ai-baseline) {
  --data-list-value-trim-start: -.25em;
  --data-list-value-trim-end: -.25em;
  --data-list-first-item-value-trim-start: 0px;
  --data-list-last-item-value-trim-end: 0px;
}

.rt-DataListItem:where(.rt-r-ai-start) {
  --data-list-value-trim-start: 0px;
  --data-list-value-trim-end: -.25em;
  --data-list-first-item-value-trim-start: 0px;
  --data-list-last-item-value-trim-end: 0px;
}

.rt-DataListItem:where(.rt-r-ai-center) {
  --data-list-value-trim-start: -.25em;
  --data-list-value-trim-end: -.25em;
  --data-list-first-item-value-trim-start: -.25em;
  --data-list-last-item-value-trim-end: -.25em;
}

.rt-DataListItem:where(.rt-r-ai-end) {
  --data-list-value-trim-start: -.25em;
  --data-list-value-trim-end: 0px;
  --data-list-first-item-value-trim-start: 0px;
  --data-list-last-item-value-trim-end: 0px;
}

.rt-DataListItem:where(.rt-r-ai-stretch) {
  --data-list-value-trim-start: 0px;
  --data-list-value-trim-end: 0px;
  --data-list-first-item-value-trim-start: 0px;
  --data-list-last-item-value-trim-end: 0px;
}

@media (min-width: 520px) {
  .rt-DataListItem:where(.xs\:rt-r-ai-baseline) {
    --data-list-value-trim-start: -.25em;
    --data-list-value-trim-end: -.25em;
    --data-list-first-item-value-trim-start: 0px;
    --data-list-last-item-value-trim-end: 0px;
  }

  .rt-DataListItem:where(.xs\:rt-r-ai-start) {
    --data-list-value-trim-start: 0px;
    --data-list-value-trim-end: -.25em;
    --data-list-first-item-value-trim-start: 0px;
    --data-list-last-item-value-trim-end: 0px;
  }

  .rt-DataListItem:where(.xs\:rt-r-ai-center) {
    --data-list-value-trim-start: -.25em;
    --data-list-value-trim-end: -.25em;
    --data-list-first-item-value-trim-start: -.25em;
    --data-list-last-item-value-trim-end: -.25em;
  }

  .rt-DataListItem:where(.xs\:rt-r-ai-end) {
    --data-list-value-trim-start: -.25em;
    --data-list-value-trim-end: 0px;
    --data-list-first-item-value-trim-start: 0px;
    --data-list-last-item-value-trim-end: 0px;
  }

  .rt-DataListItem:where(.xs\:rt-r-ai-stretch) {
    --data-list-value-trim-start: 0px;
    --data-list-value-trim-end: 0px;
    --data-list-first-item-value-trim-start: 0px;
    --data-list-last-item-value-trim-end: 0px;
  }
}

@media (min-width: 768px) {
  .rt-DataListItem:where(.sm\:rt-r-ai-baseline) {
    --data-list-value-trim-start: -.25em;
    --data-list-value-trim-end: -.25em;
    --data-list-first-item-value-trim-start: 0px;
    --data-list-last-item-value-trim-end: 0px;
  }

  .rt-DataListItem:where(.sm\:rt-r-ai-start) {
    --data-list-value-trim-start: 0px;
    --data-list-value-trim-end: -.25em;
    --data-list-first-item-value-trim-start: 0px;
    --data-list-last-item-value-trim-end: 0px;
  }

  .rt-DataListItem:where(.sm\:rt-r-ai-center) {
    --data-list-value-trim-start: -.25em;
    --data-list-value-trim-end: -.25em;
    --data-list-first-item-value-trim-start: -.25em;
    --data-list-last-item-value-trim-end: -.25em;
  }

  .rt-DataListItem:where(.sm\:rt-r-ai-end) {
    --data-list-value-trim-start: -.25em;
    --data-list-value-trim-end: 0px;
    --data-list-first-item-value-trim-start: 0px;
    --data-list-last-item-value-trim-end: 0px;
  }

  .rt-DataListItem:where(.sm\:rt-r-ai-stretch) {
    --data-list-value-trim-start: 0px;
    --data-list-value-trim-end: 0px;
    --data-list-first-item-value-trim-start: 0px;
    --data-list-last-item-value-trim-end: 0px;
  }
}

@media (min-width: 1024px) {
  .rt-DataListItem:where(.md\:rt-r-ai-baseline) {
    --data-list-value-trim-start: -.25em;
    --data-list-value-trim-end: -.25em;
    --data-list-first-item-value-trim-start: 0px;
    --data-list-last-item-value-trim-end: 0px;
  }

  .rt-DataListItem:where(.md\:rt-r-ai-start) {
    --data-list-value-trim-start: 0px;
    --data-list-value-trim-end: -.25em;
    --data-list-first-item-value-trim-start: 0px;
    --data-list-last-item-value-trim-end: 0px;
  }

  .rt-DataListItem:where(.md\:rt-r-ai-center) {
    --data-list-value-trim-start: -.25em;
    --data-list-value-trim-end: -.25em;
    --data-list-first-item-value-trim-start: -.25em;
    --data-list-last-item-value-trim-end: -.25em;
  }

  .rt-DataListItem:where(.md\:rt-r-ai-end) {
    --data-list-value-trim-start: -.25em;
    --data-list-value-trim-end: 0px;
    --data-list-first-item-value-trim-start: 0px;
    --data-list-last-item-value-trim-end: 0px;
  }

  .rt-DataListItem:where(.md\:rt-r-ai-stretch) {
    --data-list-value-trim-start: 0px;
    --data-list-value-trim-end: 0px;
    --data-list-first-item-value-trim-start: 0px;
    --data-list-last-item-value-trim-end: 0px;
  }
}

@media (min-width: 1280px) {
  .rt-DataListItem:where(.lg\:rt-r-ai-baseline) {
    --data-list-value-trim-start: -.25em;
    --data-list-value-trim-end: -.25em;
    --data-list-first-item-value-trim-start: 0px;
    --data-list-last-item-value-trim-end: 0px;
  }

  .rt-DataListItem:where(.lg\:rt-r-ai-start) {
    --data-list-value-trim-start: 0px;
    --data-list-value-trim-end: -.25em;
    --data-list-first-item-value-trim-start: 0px;
    --data-list-last-item-value-trim-end: 0px;
  }

  .rt-DataListItem:where(.lg\:rt-r-ai-center) {
    --data-list-value-trim-start: -.25em;
    --data-list-value-trim-end: -.25em;
    --data-list-first-item-value-trim-start: -.25em;
    --data-list-last-item-value-trim-end: -.25em;
  }

  .rt-DataListItem:where(.lg\:rt-r-ai-end) {
    --data-list-value-trim-start: -.25em;
    --data-list-value-trim-end: 0px;
    --data-list-first-item-value-trim-start: 0px;
    --data-list-last-item-value-trim-end: 0px;
  }

  .rt-DataListItem:where(.lg\:rt-r-ai-stretch) {
    --data-list-value-trim-start: 0px;
    --data-list-value-trim-end: 0px;
    --data-list-first-item-value-trim-start: 0px;
    --data-list-last-item-value-trim-end: 0px;
  }
}

@media (min-width: 1640px) {
  .rt-DataListItem:where(.xl\:rt-r-ai-baseline) {
    --data-list-value-trim-start: -.25em;
    --data-list-value-trim-end: -.25em;
    --data-list-first-item-value-trim-start: 0px;
    --data-list-last-item-value-trim-end: 0px;
  }

  .rt-DataListItem:where(.xl\:rt-r-ai-start) {
    --data-list-value-trim-start: 0px;
    --data-list-value-trim-end: -.25em;
    --data-list-first-item-value-trim-start: 0px;
    --data-list-last-item-value-trim-end: 0px;
  }

  .rt-DataListItem:where(.xl\:rt-r-ai-center) {
    --data-list-value-trim-start: -.25em;
    --data-list-value-trim-end: -.25em;
    --data-list-first-item-value-trim-start: -.25em;
    --data-list-last-item-value-trim-end: -.25em;
  }

  .rt-DataListItem:where(.xl\:rt-r-ai-end) {
    --data-list-value-trim-start: -.25em;
    --data-list-value-trim-end: 0px;
    --data-list-first-item-value-trim-start: 0px;
    --data-list-last-item-value-trim-end: 0px;
  }

  .rt-DataListItem:where(.xl\:rt-r-ai-stretch) {
    --data-list-value-trim-start: 0px;
    --data-list-value-trim-end: 0px;
    --data-list-first-item-value-trim-start: 0px;
    --data-list-last-item-value-trim-end: 0px;
  }
}

.rt-DataListItem:where(:first-child) {
  margin-top: var(--leading-trim-start);
}

.rt-DataListItem:where(:last-child) {
  margin-bottom: var(--leading-trim-end);
}

.rt-DataListRoot:where(.rt-r-trim-normal) {
  --leading-trim-start: initial;
  --leading-trim-end: initial;
}

.rt-DataListRoot:where(.rt-r-trim-start) {
  --leading-trim-start: var(--data-list-leading-trim-start);
  --leading-trim-end: initial;
}

.rt-DataListRoot:where(.rt-r-trim-end) {
  --leading-trim-start: initial;
  --leading-trim-end: var(--data-list-leading-trim-end);
}

.rt-DataListRoot:where(.rt-r-trim-both) {
  --leading-trim-start: var(--data-list-leading-trim-start);
  --leading-trim-end: var(--data-list-leading-trim-end);
}

@media (min-width: 520px) {
  .rt-DataListRoot:where(.xs\:rt-r-trim-normal) {
    --leading-trim-start: initial;
    --leading-trim-end: initial;
  }

  .rt-DataListRoot:where(.xs\:rt-r-trim-start) {
    --leading-trim-start: var(--data-list-leading-trim-start);
    --leading-trim-end: initial;
  }

  .rt-DataListRoot:where(.xs\:rt-r-trim-end) {
    --leading-trim-start: initial;
    --leading-trim-end: var(--data-list-leading-trim-end);
  }

  .rt-DataListRoot:where(.xs\:rt-r-trim-both) {
    --leading-trim-start: var(--data-list-leading-trim-start);
    --leading-trim-end: var(--data-list-leading-trim-end);
  }
}

@media (min-width: 768px) {
  .rt-DataListRoot:where(.sm\:rt-r-trim-normal) {
    --leading-trim-start: initial;
    --leading-trim-end: initial;
  }

  .rt-DataListRoot:where(.sm\:rt-r-trim-start) {
    --leading-trim-start: var(--data-list-leading-trim-start);
    --leading-trim-end: initial;
  }

  .rt-DataListRoot:where(.sm\:rt-r-trim-end) {
    --leading-trim-start: initial;
    --leading-trim-end: var(--data-list-leading-trim-end);
  }

  .rt-DataListRoot:where(.sm\:rt-r-trim-both) {
    --leading-trim-start: var(--data-list-leading-trim-start);
    --leading-trim-end: var(--data-list-leading-trim-end);
  }
}

@media (min-width: 1024px) {
  .rt-DataListRoot:where(.md\:rt-r-trim-normal) {
    --leading-trim-start: initial;
    --leading-trim-end: initial;
  }

  .rt-DataListRoot:where(.md\:rt-r-trim-start) {
    --leading-trim-start: var(--data-list-leading-trim-start);
    --leading-trim-end: initial;
  }

  .rt-DataListRoot:where(.md\:rt-r-trim-end) {
    --leading-trim-start: initial;
    --leading-trim-end: var(--data-list-leading-trim-end);
  }

  .rt-DataListRoot:where(.md\:rt-r-trim-both) {
    --leading-trim-start: var(--data-list-leading-trim-start);
    --leading-trim-end: var(--data-list-leading-trim-end);
  }
}

@media (min-width: 1280px) {
  .rt-DataListRoot:where(.lg\:rt-r-trim-normal) {
    --leading-trim-start: initial;
    --leading-trim-end: initial;
  }

  .rt-DataListRoot:where(.lg\:rt-r-trim-start) {
    --leading-trim-start: var(--data-list-leading-trim-start);
    --leading-trim-end: initial;
  }

  .rt-DataListRoot:where(.lg\:rt-r-trim-end) {
    --leading-trim-start: initial;
    --leading-trim-end: var(--data-list-leading-trim-end);
  }

  .rt-DataListRoot:where(.lg\:rt-r-trim-both) {
    --leading-trim-start: var(--data-list-leading-trim-start);
    --leading-trim-end: var(--data-list-leading-trim-end);
  }
}

@media (min-width: 1640px) {
  .rt-DataListRoot:where(.xl\:rt-r-trim-normal) {
    --leading-trim-start: initial;
    --leading-trim-end: initial;
  }

  .rt-DataListRoot:where(.xl\:rt-r-trim-start) {
    --leading-trim-start: var(--data-list-leading-trim-start);
    --leading-trim-end: initial;
  }

  .rt-DataListRoot:where(.xl\:rt-r-trim-end) {
    --leading-trim-start: initial;
    --leading-trim-end: var(--data-list-leading-trim-end);
  }

  .rt-DataListRoot:where(.xl\:rt-r-trim-both) {
    --leading-trim-start: var(--data-list-leading-trim-start);
    --leading-trim-end: var(--data-list-leading-trim-end);
  }
}

.rt-DropdownMenuContent {
  max-height: var(--radix-dropdown-menu-content-available-height);
  transform-origin: var(--radix-dropdown-menu-content-transform-origin);
}

.rt-Em {
  box-sizing: border-box;
  font-family: var(--em-font-family);
  font-size: calc(var(--em-font-size-adjust) * 1em);
  font-style: var(--em-font-style);
  font-weight: var(--em-font-weight);
  letter-spacing: calc(var(--em-letter-spacing)  + var(--letter-spacing, var(--default-letter-spacing)));
  color: inherit;
  line-height: 1.25;
}

.rt-Em :where(.rt-Em) {
  font-size: inherit;
}

.rt-Heading {
  --leading-trim-start: var(--heading-leading-trim-start);
  --leading-trim-end: var(--heading-leading-trim-end);
  font-family: var(--heading-font-family);
  font-style: var(--heading-font-style);
  font-weight: var(--font-weight-bold);
  line-height: var(--line-height);
}

:where(.rt-Heading) {
  margin: 0;
}

.rt-Heading:where([data-accent-color]) {
  color: var(--accent-a11);
}

.rt-Heading:where([data-accent-color].rt-high-contrast) {
  color: var(--accent-12);
}

:where([data-accent-color]:not(.radix-themes)) .rt-Heading:where(.rt-high-contrast) {
  color: var(--accent-12);
}

.rt-Heading:where(.rt-r-size-1) {
  font-size: calc(var(--font-size-1) * var(--heading-font-size-adjust));
  --line-height: var(--heading-line-height-1);
  letter-spacing: calc(var(--letter-spacing-1)  + var(--heading-letter-spacing));
}

.rt-Heading:where(.rt-r-size-2) {
  font-size: calc(var(--font-size-2) * var(--heading-font-size-adjust));
  --line-height: var(--heading-line-height-2);
  letter-spacing: calc(var(--letter-spacing-2)  + var(--heading-letter-spacing));
}

.rt-Heading:where(.rt-r-size-3) {
  font-size: calc(var(--font-size-3) * var(--heading-font-size-adjust));
  --line-height: var(--heading-line-height-3);
  letter-spacing: calc(var(--letter-spacing-3)  + var(--heading-letter-spacing));
}

.rt-Heading:where(.rt-r-size-4) {
  font-size: calc(var(--font-size-4) * var(--heading-font-size-adjust));
  --line-height: var(--heading-line-height-4);
  letter-spacing: calc(var(--letter-spacing-4)  + var(--heading-letter-spacing));
}

.rt-Heading:where(.rt-r-size-5) {
  font-size: calc(var(--font-size-5) * var(--heading-font-size-adjust));
  --line-height: var(--heading-line-height-5);
  letter-spacing: calc(var(--letter-spacing-5)  + var(--heading-letter-spacing));
}

.rt-Heading:where(.rt-r-size-6) {
  font-size: calc(var(--font-size-6) * var(--heading-font-size-adjust));
  --line-height: var(--heading-line-height-6);
  letter-spacing: calc(var(--letter-spacing-6)  + var(--heading-letter-spacing));
}

.rt-Heading:where(.rt-r-size-7) {
  font-size: calc(var(--font-size-7) * var(--heading-font-size-adjust));
  --line-height: var(--heading-line-height-7);
  letter-spacing: calc(var(--letter-spacing-7)  + var(--heading-letter-spacing));
}

.rt-Heading:where(.rt-r-size-8) {
  font-size: calc(var(--font-size-8) * var(--heading-font-size-adjust));
  --line-height: var(--heading-line-height-8);
  letter-spacing: calc(var(--letter-spacing-8)  + var(--heading-letter-spacing));
}

.rt-Heading:where(.rt-r-size-9) {
  font-size: calc(var(--font-size-9) * var(--heading-font-size-adjust));
  --line-height: var(--heading-line-height-9);
  letter-spacing: calc(var(--letter-spacing-9)  + var(--heading-letter-spacing));
}

@media (min-width: 520px) {
  .rt-Heading:where(.xs\:rt-r-size-1) {
    font-size: calc(var(--font-size-1) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-1);
    letter-spacing: calc(var(--letter-spacing-1)  + var(--heading-letter-spacing));
  }

  .rt-Heading:where(.xs\:rt-r-size-2) {
    font-size: calc(var(--font-size-2) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-2);
    letter-spacing: calc(var(--letter-spacing-2)  + var(--heading-letter-spacing));
  }

  .rt-Heading:where(.xs\:rt-r-size-3) {
    font-size: calc(var(--font-size-3) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-3);
    letter-spacing: calc(var(--letter-spacing-3)  + var(--heading-letter-spacing));
  }

  .rt-Heading:where(.xs\:rt-r-size-4) {
    font-size: calc(var(--font-size-4) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-4);
    letter-spacing: calc(var(--letter-spacing-4)  + var(--heading-letter-spacing));
  }

  .rt-Heading:where(.xs\:rt-r-size-5) {
    font-size: calc(var(--font-size-5) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-5);
    letter-spacing: calc(var(--letter-spacing-5)  + var(--heading-letter-spacing));
  }

  .rt-Heading:where(.xs\:rt-r-size-6) {
    font-size: calc(var(--font-size-6) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-6);
    letter-spacing: calc(var(--letter-spacing-6)  + var(--heading-letter-spacing));
  }

  .rt-Heading:where(.xs\:rt-r-size-7) {
    font-size: calc(var(--font-size-7) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-7);
    letter-spacing: calc(var(--letter-spacing-7)  + var(--heading-letter-spacing));
  }

  .rt-Heading:where(.xs\:rt-r-size-8) {
    font-size: calc(var(--font-size-8) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-8);
    letter-spacing: calc(var(--letter-spacing-8)  + var(--heading-letter-spacing));
  }

  .rt-Heading:where(.xs\:rt-r-size-9) {
    font-size: calc(var(--font-size-9) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-9);
    letter-spacing: calc(var(--letter-spacing-9)  + var(--heading-letter-spacing));
  }
}

@media (min-width: 768px) {
  .rt-Heading:where(.sm\:rt-r-size-1) {
    font-size: calc(var(--font-size-1) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-1);
    letter-spacing: calc(var(--letter-spacing-1)  + var(--heading-letter-spacing));
  }

  .rt-Heading:where(.sm\:rt-r-size-2) {
    font-size: calc(var(--font-size-2) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-2);
    letter-spacing: calc(var(--letter-spacing-2)  + var(--heading-letter-spacing));
  }

  .rt-Heading:where(.sm\:rt-r-size-3) {
    font-size: calc(var(--font-size-3) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-3);
    letter-spacing: calc(var(--letter-spacing-3)  + var(--heading-letter-spacing));
  }

  .rt-Heading:where(.sm\:rt-r-size-4) {
    font-size: calc(var(--font-size-4) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-4);
    letter-spacing: calc(var(--letter-spacing-4)  + var(--heading-letter-spacing));
  }

  .rt-Heading:where(.sm\:rt-r-size-5) {
    font-size: calc(var(--font-size-5) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-5);
    letter-spacing: calc(var(--letter-spacing-5)  + var(--heading-letter-spacing));
  }

  .rt-Heading:where(.sm\:rt-r-size-6) {
    font-size: calc(var(--font-size-6) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-6);
    letter-spacing: calc(var(--letter-spacing-6)  + var(--heading-letter-spacing));
  }

  .rt-Heading:where(.sm\:rt-r-size-7) {
    font-size: calc(var(--font-size-7) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-7);
    letter-spacing: calc(var(--letter-spacing-7)  + var(--heading-letter-spacing));
  }

  .rt-Heading:where(.sm\:rt-r-size-8) {
    font-size: calc(var(--font-size-8) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-8);
    letter-spacing: calc(var(--letter-spacing-8)  + var(--heading-letter-spacing));
  }

  .rt-Heading:where(.sm\:rt-r-size-9) {
    font-size: calc(var(--font-size-9) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-9);
    letter-spacing: calc(var(--letter-spacing-9)  + var(--heading-letter-spacing));
  }
}

@media (min-width: 1024px) {
  .rt-Heading:where(.md\:rt-r-size-1) {
    font-size: calc(var(--font-size-1) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-1);
    letter-spacing: calc(var(--letter-spacing-1)  + var(--heading-letter-spacing));
  }

  .rt-Heading:where(.md\:rt-r-size-2) {
    font-size: calc(var(--font-size-2) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-2);
    letter-spacing: calc(var(--letter-spacing-2)  + var(--heading-letter-spacing));
  }

  .rt-Heading:where(.md\:rt-r-size-3) {
    font-size: calc(var(--font-size-3) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-3);
    letter-spacing: calc(var(--letter-spacing-3)  + var(--heading-letter-spacing));
  }

  .rt-Heading:where(.md\:rt-r-size-4) {
    font-size: calc(var(--font-size-4) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-4);
    letter-spacing: calc(var(--letter-spacing-4)  + var(--heading-letter-spacing));
  }

  .rt-Heading:where(.md\:rt-r-size-5) {
    font-size: calc(var(--font-size-5) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-5);
    letter-spacing: calc(var(--letter-spacing-5)  + var(--heading-letter-spacing));
  }

  .rt-Heading:where(.md\:rt-r-size-6) {
    font-size: calc(var(--font-size-6) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-6);
    letter-spacing: calc(var(--letter-spacing-6)  + var(--heading-letter-spacing));
  }

  .rt-Heading:where(.md\:rt-r-size-7) {
    font-size: calc(var(--font-size-7) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-7);
    letter-spacing: calc(var(--letter-spacing-7)  + var(--heading-letter-spacing));
  }

  .rt-Heading:where(.md\:rt-r-size-8) {
    font-size: calc(var(--font-size-8) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-8);
    letter-spacing: calc(var(--letter-spacing-8)  + var(--heading-letter-spacing));
  }

  .rt-Heading:where(.md\:rt-r-size-9) {
    font-size: calc(var(--font-size-9) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-9);
    letter-spacing: calc(var(--letter-spacing-9)  + var(--heading-letter-spacing));
  }
}

@media (min-width: 1280px) {
  .rt-Heading:where(.lg\:rt-r-size-1) {
    font-size: calc(var(--font-size-1) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-1);
    letter-spacing: calc(var(--letter-spacing-1)  + var(--heading-letter-spacing));
  }

  .rt-Heading:where(.lg\:rt-r-size-2) {
    font-size: calc(var(--font-size-2) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-2);
    letter-spacing: calc(var(--letter-spacing-2)  + var(--heading-letter-spacing));
  }

  .rt-Heading:where(.lg\:rt-r-size-3) {
    font-size: calc(var(--font-size-3) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-3);
    letter-spacing: calc(var(--letter-spacing-3)  + var(--heading-letter-spacing));
  }

  .rt-Heading:where(.lg\:rt-r-size-4) {
    font-size: calc(var(--font-size-4) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-4);
    letter-spacing: calc(var(--letter-spacing-4)  + var(--heading-letter-spacing));
  }

  .rt-Heading:where(.lg\:rt-r-size-5) {
    font-size: calc(var(--font-size-5) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-5);
    letter-spacing: calc(var(--letter-spacing-5)  + var(--heading-letter-spacing));
  }

  .rt-Heading:where(.lg\:rt-r-size-6) {
    font-size: calc(var(--font-size-6) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-6);
    letter-spacing: calc(var(--letter-spacing-6)  + var(--heading-letter-spacing));
  }

  .rt-Heading:where(.lg\:rt-r-size-7) {
    font-size: calc(var(--font-size-7) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-7);
    letter-spacing: calc(var(--letter-spacing-7)  + var(--heading-letter-spacing));
  }

  .rt-Heading:where(.lg\:rt-r-size-8) {
    font-size: calc(var(--font-size-8) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-8);
    letter-spacing: calc(var(--letter-spacing-8)  + var(--heading-letter-spacing));
  }

  .rt-Heading:where(.lg\:rt-r-size-9) {
    font-size: calc(var(--font-size-9) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-9);
    letter-spacing: calc(var(--letter-spacing-9)  + var(--heading-letter-spacing));
  }
}

@media (min-width: 1640px) {
  .rt-Heading:where(.xl\:rt-r-size-1) {
    font-size: calc(var(--font-size-1) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-1);
    letter-spacing: calc(var(--letter-spacing-1)  + var(--heading-letter-spacing));
  }

  .rt-Heading:where(.xl\:rt-r-size-2) {
    font-size: calc(var(--font-size-2) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-2);
    letter-spacing: calc(var(--letter-spacing-2)  + var(--heading-letter-spacing));
  }

  .rt-Heading:where(.xl\:rt-r-size-3) {
    font-size: calc(var(--font-size-3) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-3);
    letter-spacing: calc(var(--letter-spacing-3)  + var(--heading-letter-spacing));
  }

  .rt-Heading:where(.xl\:rt-r-size-4) {
    font-size: calc(var(--font-size-4) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-4);
    letter-spacing: calc(var(--letter-spacing-4)  + var(--heading-letter-spacing));
  }

  .rt-Heading:where(.xl\:rt-r-size-5) {
    font-size: calc(var(--font-size-5) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-5);
    letter-spacing: calc(var(--letter-spacing-5)  + var(--heading-letter-spacing));
  }

  .rt-Heading:where(.xl\:rt-r-size-6) {
    font-size: calc(var(--font-size-6) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-6);
    letter-spacing: calc(var(--letter-spacing-6)  + var(--heading-letter-spacing));
  }

  .rt-Heading:where(.xl\:rt-r-size-7) {
    font-size: calc(var(--font-size-7) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-7);
    letter-spacing: calc(var(--letter-spacing-7)  + var(--heading-letter-spacing));
  }

  .rt-Heading:where(.xl\:rt-r-size-8) {
    font-size: calc(var(--font-size-8) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-8);
    letter-spacing: calc(var(--letter-spacing-8)  + var(--heading-letter-spacing));
  }

  .rt-Heading:where(.xl\:rt-r-size-9) {
    font-size: calc(var(--font-size-9) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-9);
    letter-spacing: calc(var(--letter-spacing-9)  + var(--heading-letter-spacing));
  }
}

.rt-HoverCardContent {
  background-color: var(--color-panel-solid);
  box-shadow: var(--shadow-4);
  --inset-padding-top: var(--hover-card-content-padding);
  --inset-padding-right: var(--hover-card-content-padding);
  --inset-padding-bottom: var(--hover-card-content-padding);
  --inset-padding-left: var(--hover-card-content-padding);
  padding: var(--hover-card-content-padding);
  box-sizing: border-box;
  transform-origin: var(--radix-hover-card-content-transform-origin);
  position: relative;
  overflow: auto;
}

.rt-HoverCardContent:where(.rt-r-size-1) {
  --hover-card-content-padding: var(--space-3);
  border-radius: var(--radius-4);
}

.rt-HoverCardContent:where(.rt-r-size-2) {
  --hover-card-content-padding: var(--space-4);
  border-radius: var(--radius-4);
}

.rt-HoverCardContent:where(.rt-r-size-3) {
  --hover-card-content-padding: var(--space-5);
  border-radius: var(--radius-5);
}

@media (min-width: 520px) {
  .rt-HoverCardContent:where(.xs\:rt-r-size-1) {
    --hover-card-content-padding: var(--space-3);
    border-radius: var(--radius-4);
  }

  .rt-HoverCardContent:where(.xs\:rt-r-size-2) {
    --hover-card-content-padding: var(--space-4);
    border-radius: var(--radius-4);
  }

  .rt-HoverCardContent:where(.xs\:rt-r-size-3) {
    --hover-card-content-padding: var(--space-5);
    border-radius: var(--radius-5);
  }
}

@media (min-width: 768px) {
  .rt-HoverCardContent:where(.sm\:rt-r-size-1) {
    --hover-card-content-padding: var(--space-3);
    border-radius: var(--radius-4);
  }

  .rt-HoverCardContent:where(.sm\:rt-r-size-2) {
    --hover-card-content-padding: var(--space-4);
    border-radius: var(--radius-4);
  }

  .rt-HoverCardContent:where(.sm\:rt-r-size-3) {
    --hover-card-content-padding: var(--space-5);
    border-radius: var(--radius-5);
  }
}

@media (min-width: 1024px) {
  .rt-HoverCardContent:where(.md\:rt-r-size-1) {
    --hover-card-content-padding: var(--space-3);
    border-radius: var(--radius-4);
  }

  .rt-HoverCardContent:where(.md\:rt-r-size-2) {
    --hover-card-content-padding: var(--space-4);
    border-radius: var(--radius-4);
  }

  .rt-HoverCardContent:where(.md\:rt-r-size-3) {
    --hover-card-content-padding: var(--space-5);
    border-radius: var(--radius-5);
  }
}

@media (min-width: 1280px) {
  .rt-HoverCardContent:where(.lg\:rt-r-size-1) {
    --hover-card-content-padding: var(--space-3);
    border-radius: var(--radius-4);
  }

  .rt-HoverCardContent:where(.lg\:rt-r-size-2) {
    --hover-card-content-padding: var(--space-4);
    border-radius: var(--radius-4);
  }

  .rt-HoverCardContent:where(.lg\:rt-r-size-3) {
    --hover-card-content-padding: var(--space-5);
    border-radius: var(--radius-5);
  }
}

@media (min-width: 1640px) {
  .rt-HoverCardContent:where(.xl\:rt-r-size-1) {
    --hover-card-content-padding: var(--space-3);
    border-radius: var(--radius-4);
  }

  .rt-HoverCardContent:where(.xl\:rt-r-size-2) {
    --hover-card-content-padding: var(--space-4);
    border-radius: var(--radius-4);
  }

  .rt-HoverCardContent:where(.xl\:rt-r-size-3) {
    --hover-card-content-padding: var(--space-5);
    border-radius: var(--radius-5);
  }
}

.rt-IconButton:where(:not(.rt-variant-ghost)) {
  height: var(--base-button-height);
  width: var(--base-button-height);
}

.rt-IconButton:where(.rt-variant-ghost) {
  padding: var(--icon-button-ghost-padding);
  --margin-top: 0px;
  --margin-right: 0px;
  --margin-bottom: 0px;
  --margin-left: 0px;
  --margin-top-override: calc(var(--margin-top)  - var(--icon-button-ghost-padding));
  --margin-right-override: calc(var(--margin-right)  - var(--icon-button-ghost-padding));
  --margin-bottom-override: calc(var(--margin-bottom)  - var(--icon-button-ghost-padding));
  --margin-left-override: calc(var(--margin-left)  - var(--icon-button-ghost-padding));
  margin: var(--margin-top-override) var(--margin-right-override) var(--margin-bottom-override) var(--margin-left-override);
}

:where(.rt-IconButton:where(.rt-variant-ghost)) > * {
  --margin-top-override: initial;
  --margin-right-override: initial;
  --margin-bottom-override: initial;
  --margin-left-override: initial;
}

.rt-IconButton:where(.rt-variant-ghost):where(.rt-r-size-1) {
  --icon-button-ghost-padding: var(--space-1);
}

.rt-IconButton:where(.rt-variant-ghost):where(.rt-r-size-2) {
  --icon-button-ghost-padding: calc(var(--space-1) * 1.5);
}

.rt-IconButton:where(.rt-variant-ghost):where(.rt-r-size-3) {
  --icon-button-ghost-padding: var(--space-2);
}

.rt-IconButton:where(.rt-variant-ghost):where(.rt-r-size-4) {
  --icon-button-ghost-padding: var(--space-3);
}

@media (min-width: 520px) {
  .rt-IconButton:where(.rt-variant-ghost):where(.xs\:rt-r-size-1) {
    --icon-button-ghost-padding: var(--space-1);
  }

  .rt-IconButton:where(.rt-variant-ghost):where(.xs\:rt-r-size-2) {
    --icon-button-ghost-padding: calc(var(--space-1) * 1.5);
  }

  .rt-IconButton:where(.rt-variant-ghost):where(.xs\:rt-r-size-3) {
    --icon-button-ghost-padding: var(--space-2);
  }

  .rt-IconButton:where(.rt-variant-ghost):where(.xs\:rt-r-size-4) {
    --icon-button-ghost-padding: var(--space-3);
  }
}

@media (min-width: 768px) {
  .rt-IconButton:where(.rt-variant-ghost):where(.sm\:rt-r-size-1) {
    --icon-button-ghost-padding: var(--space-1);
  }

  .rt-IconButton:where(.rt-variant-ghost):where(.sm\:rt-r-size-2) {
    --icon-button-ghost-padding: calc(var(--space-1) * 1.5);
  }

  .rt-IconButton:where(.rt-variant-ghost):where(.sm\:rt-r-size-3) {
    --icon-button-ghost-padding: var(--space-2);
  }

  .rt-IconButton:where(.rt-variant-ghost):where(.sm\:rt-r-size-4) {
    --icon-button-ghost-padding: var(--space-3);
  }
}

@media (min-width: 1024px) {
  .rt-IconButton:where(.rt-variant-ghost):where(.md\:rt-r-size-1) {
    --icon-button-ghost-padding: var(--space-1);
  }

  .rt-IconButton:where(.rt-variant-ghost):where(.md\:rt-r-size-2) {
    --icon-button-ghost-padding: calc(var(--space-1) * 1.5);
  }

  .rt-IconButton:where(.rt-variant-ghost):where(.md\:rt-r-size-3) {
    --icon-button-ghost-padding: var(--space-2);
  }

  .rt-IconButton:where(.rt-variant-ghost):where(.md\:rt-r-size-4) {
    --icon-button-ghost-padding: var(--space-3);
  }
}

@media (min-width: 1280px) {
  .rt-IconButton:where(.rt-variant-ghost):where(.lg\:rt-r-size-1) {
    --icon-button-ghost-padding: var(--space-1);
  }

  .rt-IconButton:where(.rt-variant-ghost):where(.lg\:rt-r-size-2) {
    --icon-button-ghost-padding: calc(var(--space-1) * 1.5);
  }

  .rt-IconButton:where(.rt-variant-ghost):where(.lg\:rt-r-size-3) {
    --icon-button-ghost-padding: var(--space-2);
  }

  .rt-IconButton:where(.rt-variant-ghost):where(.lg\:rt-r-size-4) {
    --icon-button-ghost-padding: var(--space-3);
  }
}

@media (min-width: 1640px) {
  .rt-IconButton:where(.rt-variant-ghost):where(.xl\:rt-r-size-1) {
    --icon-button-ghost-padding: var(--space-1);
  }

  .rt-IconButton:where(.rt-variant-ghost):where(.xl\:rt-r-size-2) {
    --icon-button-ghost-padding: calc(var(--space-1) * 1.5);
  }

  .rt-IconButton:where(.rt-variant-ghost):where(.xl\:rt-r-size-3) {
    --icon-button-ghost-padding: var(--space-2);
  }

  .rt-IconButton:where(.rt-variant-ghost):where(.xl\:rt-r-size-4) {
    --icon-button-ghost-padding: var(--space-3);
  }
}

.rt-Inset {
  box-sizing: border-box;
  --margin-top: 0px;
  --margin-right: 0px;
  --margin-bottom: 0px;
  --margin-left: 0px;
  margin-top: var(--margin-top-override);
  margin-right: var(--margin-right-override);
  margin-bottom: var(--margin-bottom-override);
  margin-left: var(--margin-left-override);
  overflow: hidden;
}

:where(.rt-Inset) > * {
  --margin-top-override: initial;
  --margin-right-override: initial;
  --margin-bottom-override: initial;
  --margin-left-override: initial;
}

.rt-Inset:where(.rt-r-clip-border-box) {
  --inset-border-radius-calc: calc(var(--inset-border-radius, 0px)  - var(--inset-border-width, 0px));
  --inset-padding-top-calc: var(--inset-padding-top, 0px);
  --inset-padding-right-calc: var(--inset-padding-right, 0px);
  --inset-padding-bottom-calc: var(--inset-padding-bottom, 0px);
  --inset-padding-left-calc: var(--inset-padding-left, 0px);
}

.rt-Inset:where(.rt-r-clip-padding-box) {
  --inset-border-radius-calc: var(--inset-border-radius, 0px);
  --inset-padding-top-calc: calc(var(--inset-padding-top, 0px)  + var(--inset-border-width, 0px));
  --inset-padding-right-calc: calc(var(--inset-padding-right, 0px)  + var(--inset-border-width, 0px));
  --inset-padding-bottom-calc: calc(var(--inset-padding-bottom, 0px)  + var(--inset-border-width, 0px));
  --inset-padding-left-calc: calc(var(--inset-padding-left, 0px)  + var(--inset-border-width, 0px));
}

.rt-Inset:where(.rt-r-side-top) {
  --margin-top-override: calc(var(--margin-top)  - var(--inset-padding-top-calc));
  --margin-right-override: calc(var(--margin-right)  - var(--inset-padding-right-calc));
  --margin-bottom-override: var(--margin-bottom);
  --margin-left-override: calc(var(--margin-left)  - var(--inset-padding-left-calc));
  border-top-left-radius: var(--inset-border-radius-calc);
  border-top-right-radius: var(--inset-border-radius-calc);
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.rt-Inset:where(.rt-r-side-bottom) {
  --margin-top-override: var(--margin-top);
  --margin-right-override: calc(var(--margin-right)  - var(--inset-padding-right-calc));
  --margin-bottom-override: calc(var(--margin-bottom)  - var(--inset-padding-bottom-calc));
  --margin-left-override: calc(var(--margin-left)  - var(--inset-padding-left-calc));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: var(--inset-border-radius-calc);
  border-bottom-right-radius: var(--inset-border-radius-calc);
}

.rt-Inset:where(.rt-r-side-left) {
  --margin-top-override: calc(var(--margin-top)  - var(--inset-padding-top-calc));
  --margin-bottom-override: calc(var(--margin-bottom)  - var(--inset-padding-bottom-calc));
  --margin-left-override: calc(var(--margin-left)  - var(--inset-padding-left-calc));
  --margin-right-override: var(--margin-right);
  border-top-left-radius: var(--inset-border-radius-calc);
  border-top-right-radius: 0;
  border-bottom-left-radius: var(--inset-border-radius-calc);
  border-bottom-right-radius: 0;
}

.rt-Inset:where(.rt-r-side-right) {
  --margin-top-override: calc(var(--margin-top)  - var(--inset-padding-top-calc));
  --margin-right-override: calc(var(--margin-right)  - var(--inset-padding-right-calc));
  --margin-bottom-override: calc(var(--margin-bottom)  - var(--inset-padding-bottom-calc));
  --margin-left-override: var(--margin-left);
  border-top-left-radius: 0;
  border-top-right-radius: var(--inset-border-radius-calc);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: var(--inset-border-radius-calc);
}

.rt-Inset:where(.rt-r-side-x) {
  --margin-top-override: var(--margin-top);
  --margin-right-override: calc(var(--margin-right)  - var(--inset-padding-right-calc));
  --margin-bottom-override: var(--margin-bottom);
  --margin-left-override: calc(var(--margin-left)  - var(--inset-padding-left-calc));
  border-radius: 0;
}

.rt-Inset:where(.rt-r-side-y) {
  --margin-top-override: calc(var(--margin-top)  - var(--inset-padding-top-calc));
  --margin-right-override: var(--margin-right);
  --margin-bottom-override: calc(var(--margin-bottom)  - var(--inset-padding-bottom-calc));
  --margin-left-override: var(--margin-left);
  border-radius: 0;
}

.rt-Inset:where(.rt-r-side-all) {
  --margin-top-override: calc(var(--margin-top)  - var(--inset-padding-top-calc));
  --margin-right-override: calc(var(--margin-right)  - var(--inset-padding-right-calc));
  --margin-bottom-override: calc(var(--margin-bottom)  - var(--inset-padding-bottom-calc));
  --margin-left-override: calc(var(--margin-left)  - var(--inset-padding-left-calc));
  border-radius: var(--inset-border-radius-calc);
}

@media (min-width: 520px) {
  .rt-Inset:where(.xs\:rt-r-clip-border-box) {
    --inset-border-radius-calc: calc(var(--inset-border-radius, 0px)  - var(--inset-border-width, 0px));
    --inset-padding-top-calc: var(--inset-padding-top, 0px);
    --inset-padding-right-calc: var(--inset-padding-right, 0px);
    --inset-padding-bottom-calc: var(--inset-padding-bottom, 0px);
    --inset-padding-left-calc: var(--inset-padding-left, 0px);
  }

  .rt-Inset:where(.xs\:rt-r-clip-padding-box) {
    --inset-border-radius-calc: var(--inset-border-radius, 0px);
    --inset-padding-top-calc: calc(var(--inset-padding-top, 0px)  + var(--inset-border-width, 0px));
    --inset-padding-right-calc: calc(var(--inset-padding-right, 0px)  + var(--inset-border-width, 0px));
    --inset-padding-bottom-calc: calc(var(--inset-padding-bottom, 0px)  + var(--inset-border-width, 0px));
    --inset-padding-left-calc: calc(var(--inset-padding-left, 0px)  + var(--inset-border-width, 0px));
  }

  .rt-Inset:where(.xs\:rt-r-side-top) {
    --margin-top-override: calc(var(--margin-top)  - var(--inset-padding-top-calc));
    --margin-right-override: calc(var(--margin-right)  - var(--inset-padding-right-calc));
    --margin-bottom-override: var(--margin-bottom);
    --margin-left-override: calc(var(--margin-left)  - var(--inset-padding-left-calc));
    border-top-left-radius: var(--inset-border-radius-calc);
    border-top-right-radius: var(--inset-border-radius-calc);
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .rt-Inset:where(.xs\:rt-r-side-bottom) {
    --margin-top-override: var(--margin-top);
    --margin-right-override: calc(var(--margin-right)  - var(--inset-padding-right-calc));
    --margin-bottom-override: calc(var(--margin-bottom)  - var(--inset-padding-bottom-calc));
    --margin-left-override: calc(var(--margin-left)  - var(--inset-padding-left-calc));
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: var(--inset-border-radius-calc);
    border-bottom-right-radius: var(--inset-border-radius-calc);
  }

  .rt-Inset:where(.xs\:rt-r-side-left) {
    --margin-top-override: calc(var(--margin-top)  - var(--inset-padding-top-calc));
    --margin-bottom-override: calc(var(--margin-bottom)  - var(--inset-padding-bottom-calc));
    --margin-left-override: calc(var(--margin-left)  - var(--inset-padding-left-calc));
    --margin-right-override: var(--margin-right);
    border-top-left-radius: var(--inset-border-radius-calc);
    border-top-right-radius: 0;
    border-bottom-left-radius: var(--inset-border-radius-calc);
    border-bottom-right-radius: 0;
  }

  .rt-Inset:where(.xs\:rt-r-side-right) {
    --margin-top-override: calc(var(--margin-top)  - var(--inset-padding-top-calc));
    --margin-right-override: calc(var(--margin-right)  - var(--inset-padding-right-calc));
    --margin-bottom-override: calc(var(--margin-bottom)  - var(--inset-padding-bottom-calc));
    --margin-left-override: var(--margin-left);
    border-top-left-radius: 0;
    border-top-right-radius: var(--inset-border-radius-calc);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: var(--inset-border-radius-calc);
  }

  .rt-Inset:where(.xs\:rt-r-side-x) {
    --margin-top-override: var(--margin-top);
    --margin-right-override: calc(var(--margin-right)  - var(--inset-padding-right-calc));
    --margin-bottom-override: var(--margin-bottom);
    --margin-left-override: calc(var(--margin-left)  - var(--inset-padding-left-calc));
    border-radius: 0;
  }

  .rt-Inset:where(.xs\:rt-r-side-y) {
    --margin-top-override: calc(var(--margin-top)  - var(--inset-padding-top-calc));
    --margin-right-override: var(--margin-right);
    --margin-bottom-override: calc(var(--margin-bottom)  - var(--inset-padding-bottom-calc));
    --margin-left-override: var(--margin-left);
    border-radius: 0;
  }

  .rt-Inset:where(.xs\:rt-r-side-all) {
    --margin-top-override: calc(var(--margin-top)  - var(--inset-padding-top-calc));
    --margin-right-override: calc(var(--margin-right)  - var(--inset-padding-right-calc));
    --margin-bottom-override: calc(var(--margin-bottom)  - var(--inset-padding-bottom-calc));
    --margin-left-override: calc(var(--margin-left)  - var(--inset-padding-left-calc));
    border-radius: var(--inset-border-radius-calc);
  }
}

@media (min-width: 768px) {
  .rt-Inset:where(.sm\:rt-r-clip-border-box) {
    --inset-border-radius-calc: calc(var(--inset-border-radius, 0px)  - var(--inset-border-width, 0px));
    --inset-padding-top-calc: var(--inset-padding-top, 0px);
    --inset-padding-right-calc: var(--inset-padding-right, 0px);
    --inset-padding-bottom-calc: var(--inset-padding-bottom, 0px);
    --inset-padding-left-calc: var(--inset-padding-left, 0px);
  }

  .rt-Inset:where(.sm\:rt-r-clip-padding-box) {
    --inset-border-radius-calc: var(--inset-border-radius, 0px);
    --inset-padding-top-calc: calc(var(--inset-padding-top, 0px)  + var(--inset-border-width, 0px));
    --inset-padding-right-calc: calc(var(--inset-padding-right, 0px)  + var(--inset-border-width, 0px));
    --inset-padding-bottom-calc: calc(var(--inset-padding-bottom, 0px)  + var(--inset-border-width, 0px));
    --inset-padding-left-calc: calc(var(--inset-padding-left, 0px)  + var(--inset-border-width, 0px));
  }

  .rt-Inset:where(.sm\:rt-r-side-top) {
    --margin-top-override: calc(var(--margin-top)  - var(--inset-padding-top-calc));
    --margin-right-override: calc(var(--margin-right)  - var(--inset-padding-right-calc));
    --margin-bottom-override: var(--margin-bottom);
    --margin-left-override: calc(var(--margin-left)  - var(--inset-padding-left-calc));
    border-top-left-radius: var(--inset-border-radius-calc);
    border-top-right-radius: var(--inset-border-radius-calc);
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .rt-Inset:where(.sm\:rt-r-side-bottom) {
    --margin-top-override: var(--margin-top);
    --margin-right-override: calc(var(--margin-right)  - var(--inset-padding-right-calc));
    --margin-bottom-override: calc(var(--margin-bottom)  - var(--inset-padding-bottom-calc));
    --margin-left-override: calc(var(--margin-left)  - var(--inset-padding-left-calc));
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: var(--inset-border-radius-calc);
    border-bottom-right-radius: var(--inset-border-radius-calc);
  }

  .rt-Inset:where(.sm\:rt-r-side-left) {
    --margin-top-override: calc(var(--margin-top)  - var(--inset-padding-top-calc));
    --margin-bottom-override: calc(var(--margin-bottom)  - var(--inset-padding-bottom-calc));
    --margin-left-override: calc(var(--margin-left)  - var(--inset-padding-left-calc));
    --margin-right-override: var(--margin-right);
    border-top-left-radius: var(--inset-border-radius-calc);
    border-top-right-radius: 0;
    border-bottom-left-radius: var(--inset-border-radius-calc);
    border-bottom-right-radius: 0;
  }

  .rt-Inset:where(.sm\:rt-r-side-right) {
    --margin-top-override: calc(var(--margin-top)  - var(--inset-padding-top-calc));
    --margin-right-override: calc(var(--margin-right)  - var(--inset-padding-right-calc));
    --margin-bottom-override: calc(var(--margin-bottom)  - var(--inset-padding-bottom-calc));
    --margin-left-override: var(--margin-left);
    border-top-left-radius: 0;
    border-top-right-radius: var(--inset-border-radius-calc);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: var(--inset-border-radius-calc);
  }

  .rt-Inset:where(.sm\:rt-r-side-x) {
    --margin-top-override: var(--margin-top);
    --margin-right-override: calc(var(--margin-right)  - var(--inset-padding-right-calc));
    --margin-bottom-override: var(--margin-bottom);
    --margin-left-override: calc(var(--margin-left)  - var(--inset-padding-left-calc));
    border-radius: 0;
  }

  .rt-Inset:where(.sm\:rt-r-side-y) {
    --margin-top-override: calc(var(--margin-top)  - var(--inset-padding-top-calc));
    --margin-right-override: var(--margin-right);
    --margin-bottom-override: calc(var(--margin-bottom)  - var(--inset-padding-bottom-calc));
    --margin-left-override: var(--margin-left);
    border-radius: 0;
  }

  .rt-Inset:where(.sm\:rt-r-side-all) {
    --margin-top-override: calc(var(--margin-top)  - var(--inset-padding-top-calc));
    --margin-right-override: calc(var(--margin-right)  - var(--inset-padding-right-calc));
    --margin-bottom-override: calc(var(--margin-bottom)  - var(--inset-padding-bottom-calc));
    --margin-left-override: calc(var(--margin-left)  - var(--inset-padding-left-calc));
    border-radius: var(--inset-border-radius-calc);
  }
}

@media (min-width: 1024px) {
  .rt-Inset:where(.md\:rt-r-clip-border-box) {
    --inset-border-radius-calc: calc(var(--inset-border-radius, 0px)  - var(--inset-border-width, 0px));
    --inset-padding-top-calc: var(--inset-padding-top, 0px);
    --inset-padding-right-calc: var(--inset-padding-right, 0px);
    --inset-padding-bottom-calc: var(--inset-padding-bottom, 0px);
    --inset-padding-left-calc: var(--inset-padding-left, 0px);
  }

  .rt-Inset:where(.md\:rt-r-clip-padding-box) {
    --inset-border-radius-calc: var(--inset-border-radius, 0px);
    --inset-padding-top-calc: calc(var(--inset-padding-top, 0px)  + var(--inset-border-width, 0px));
    --inset-padding-right-calc: calc(var(--inset-padding-right, 0px)  + var(--inset-border-width, 0px));
    --inset-padding-bottom-calc: calc(var(--inset-padding-bottom, 0px)  + var(--inset-border-width, 0px));
    --inset-padding-left-calc: calc(var(--inset-padding-left, 0px)  + var(--inset-border-width, 0px));
  }

  .rt-Inset:where(.md\:rt-r-side-top) {
    --margin-top-override: calc(var(--margin-top)  - var(--inset-padding-top-calc));
    --margin-right-override: calc(var(--margin-right)  - var(--inset-padding-right-calc));
    --margin-bottom-override: var(--margin-bottom);
    --margin-left-override: calc(var(--margin-left)  - var(--inset-padding-left-calc));
    border-top-left-radius: var(--inset-border-radius-calc);
    border-top-right-radius: var(--inset-border-radius-calc);
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .rt-Inset:where(.md\:rt-r-side-bottom) {
    --margin-top-override: var(--margin-top);
    --margin-right-override: calc(var(--margin-right)  - var(--inset-padding-right-calc));
    --margin-bottom-override: calc(var(--margin-bottom)  - var(--inset-padding-bottom-calc));
    --margin-left-override: calc(var(--margin-left)  - var(--inset-padding-left-calc));
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: var(--inset-border-radius-calc);
    border-bottom-right-radius: var(--inset-border-radius-calc);
  }

  .rt-Inset:where(.md\:rt-r-side-left) {
    --margin-top-override: calc(var(--margin-top)  - var(--inset-padding-top-calc));
    --margin-bottom-override: calc(var(--margin-bottom)  - var(--inset-padding-bottom-calc));
    --margin-left-override: calc(var(--margin-left)  - var(--inset-padding-left-calc));
    --margin-right-override: var(--margin-right);
    border-top-left-radius: var(--inset-border-radius-calc);
    border-top-right-radius: 0;
    border-bottom-left-radius: var(--inset-border-radius-calc);
    border-bottom-right-radius: 0;
  }

  .rt-Inset:where(.md\:rt-r-side-right) {
    --margin-top-override: calc(var(--margin-top)  - var(--inset-padding-top-calc));
    --margin-right-override: calc(var(--margin-right)  - var(--inset-padding-right-calc));
    --margin-bottom-override: calc(var(--margin-bottom)  - var(--inset-padding-bottom-calc));
    --margin-left-override: var(--margin-left);
    border-top-left-radius: 0;
    border-top-right-radius: var(--inset-border-radius-calc);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: var(--inset-border-radius-calc);
  }

  .rt-Inset:where(.md\:rt-r-side-x) {
    --margin-top-override: var(--margin-top);
    --margin-right-override: calc(var(--margin-right)  - var(--inset-padding-right-calc));
    --margin-bottom-override: var(--margin-bottom);
    --margin-left-override: calc(var(--margin-left)  - var(--inset-padding-left-calc));
    border-radius: 0;
  }

  .rt-Inset:where(.md\:rt-r-side-y) {
    --margin-top-override: calc(var(--margin-top)  - var(--inset-padding-top-calc));
    --margin-right-override: var(--margin-right);
    --margin-bottom-override: calc(var(--margin-bottom)  - var(--inset-padding-bottom-calc));
    --margin-left-override: var(--margin-left);
    border-radius: 0;
  }

  .rt-Inset:where(.md\:rt-r-side-all) {
    --margin-top-override: calc(var(--margin-top)  - var(--inset-padding-top-calc));
    --margin-right-override: calc(var(--margin-right)  - var(--inset-padding-right-calc));
    --margin-bottom-override: calc(var(--margin-bottom)  - var(--inset-padding-bottom-calc));
    --margin-left-override: calc(var(--margin-left)  - var(--inset-padding-left-calc));
    border-radius: var(--inset-border-radius-calc);
  }
}

@media (min-width: 1280px) {
  .rt-Inset:where(.lg\:rt-r-clip-border-box) {
    --inset-border-radius-calc: calc(var(--inset-border-radius, 0px)  - var(--inset-border-width, 0px));
    --inset-padding-top-calc: var(--inset-padding-top, 0px);
    --inset-padding-right-calc: var(--inset-padding-right, 0px);
    --inset-padding-bottom-calc: var(--inset-padding-bottom, 0px);
    --inset-padding-left-calc: var(--inset-padding-left, 0px);
  }

  .rt-Inset:where(.lg\:rt-r-clip-padding-box) {
    --inset-border-radius-calc: var(--inset-border-radius, 0px);
    --inset-padding-top-calc: calc(var(--inset-padding-top, 0px)  + var(--inset-border-width, 0px));
    --inset-padding-right-calc: calc(var(--inset-padding-right, 0px)  + var(--inset-border-width, 0px));
    --inset-padding-bottom-calc: calc(var(--inset-padding-bottom, 0px)  + var(--inset-border-width, 0px));
    --inset-padding-left-calc: calc(var(--inset-padding-left, 0px)  + var(--inset-border-width, 0px));
  }

  .rt-Inset:where(.lg\:rt-r-side-top) {
    --margin-top-override: calc(var(--margin-top)  - var(--inset-padding-top-calc));
    --margin-right-override: calc(var(--margin-right)  - var(--inset-padding-right-calc));
    --margin-bottom-override: var(--margin-bottom);
    --margin-left-override: calc(var(--margin-left)  - var(--inset-padding-left-calc));
    border-top-left-radius: var(--inset-border-radius-calc);
    border-top-right-radius: var(--inset-border-radius-calc);
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .rt-Inset:where(.lg\:rt-r-side-bottom) {
    --margin-top-override: var(--margin-top);
    --margin-right-override: calc(var(--margin-right)  - var(--inset-padding-right-calc));
    --margin-bottom-override: calc(var(--margin-bottom)  - var(--inset-padding-bottom-calc));
    --margin-left-override: calc(var(--margin-left)  - var(--inset-padding-left-calc));
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: var(--inset-border-radius-calc);
    border-bottom-right-radius: var(--inset-border-radius-calc);
  }

  .rt-Inset:where(.lg\:rt-r-side-left) {
    --margin-top-override: calc(var(--margin-top)  - var(--inset-padding-top-calc));
    --margin-bottom-override: calc(var(--margin-bottom)  - var(--inset-padding-bottom-calc));
    --margin-left-override: calc(var(--margin-left)  - var(--inset-padding-left-calc));
    --margin-right-override: var(--margin-right);
    border-top-left-radius: var(--inset-border-radius-calc);
    border-top-right-radius: 0;
    border-bottom-left-radius: var(--inset-border-radius-calc);
    border-bottom-right-radius: 0;
  }

  .rt-Inset:where(.lg\:rt-r-side-right) {
    --margin-top-override: calc(var(--margin-top)  - var(--inset-padding-top-calc));
    --margin-right-override: calc(var(--margin-right)  - var(--inset-padding-right-calc));
    --margin-bottom-override: calc(var(--margin-bottom)  - var(--inset-padding-bottom-calc));
    --margin-left-override: var(--margin-left);
    border-top-left-radius: 0;
    border-top-right-radius: var(--inset-border-radius-calc);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: var(--inset-border-radius-calc);
  }

  .rt-Inset:where(.lg\:rt-r-side-x) {
    --margin-top-override: var(--margin-top);
    --margin-right-override: calc(var(--margin-right)  - var(--inset-padding-right-calc));
    --margin-bottom-override: var(--margin-bottom);
    --margin-left-override: calc(var(--margin-left)  - var(--inset-padding-left-calc));
    border-radius: 0;
  }

  .rt-Inset:where(.lg\:rt-r-side-y) {
    --margin-top-override: calc(var(--margin-top)  - var(--inset-padding-top-calc));
    --margin-right-override: var(--margin-right);
    --margin-bottom-override: calc(var(--margin-bottom)  - var(--inset-padding-bottom-calc));
    --margin-left-override: var(--margin-left);
    border-radius: 0;
  }

  .rt-Inset:where(.lg\:rt-r-side-all) {
    --margin-top-override: calc(var(--margin-top)  - var(--inset-padding-top-calc));
    --margin-right-override: calc(var(--margin-right)  - var(--inset-padding-right-calc));
    --margin-bottom-override: calc(var(--margin-bottom)  - var(--inset-padding-bottom-calc));
    --margin-left-override: calc(var(--margin-left)  - var(--inset-padding-left-calc));
    border-radius: var(--inset-border-radius-calc);
  }
}

@media (min-width: 1640px) {
  .rt-Inset:where(.xl\:rt-r-clip-border-box) {
    --inset-border-radius-calc: calc(var(--inset-border-radius, 0px)  - var(--inset-border-width, 0px));
    --inset-padding-top-calc: var(--inset-padding-top, 0px);
    --inset-padding-right-calc: var(--inset-padding-right, 0px);
    --inset-padding-bottom-calc: var(--inset-padding-bottom, 0px);
    --inset-padding-left-calc: var(--inset-padding-left, 0px);
  }

  .rt-Inset:where(.xl\:rt-r-clip-padding-box) {
    --inset-border-radius-calc: var(--inset-border-radius, 0px);
    --inset-padding-top-calc: calc(var(--inset-padding-top, 0px)  + var(--inset-border-width, 0px));
    --inset-padding-right-calc: calc(var(--inset-padding-right, 0px)  + var(--inset-border-width, 0px));
    --inset-padding-bottom-calc: calc(var(--inset-padding-bottom, 0px)  + var(--inset-border-width, 0px));
    --inset-padding-left-calc: calc(var(--inset-padding-left, 0px)  + var(--inset-border-width, 0px));
  }

  .rt-Inset:where(.xl\:rt-r-side-top) {
    --margin-top-override: calc(var(--margin-top)  - var(--inset-padding-top-calc));
    --margin-right-override: calc(var(--margin-right)  - var(--inset-padding-right-calc));
    --margin-bottom-override: var(--margin-bottom);
    --margin-left-override: calc(var(--margin-left)  - var(--inset-padding-left-calc));
    border-top-left-radius: var(--inset-border-radius-calc);
    border-top-right-radius: var(--inset-border-radius-calc);
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .rt-Inset:where(.xl\:rt-r-side-bottom) {
    --margin-top-override: var(--margin-top);
    --margin-right-override: calc(var(--margin-right)  - var(--inset-padding-right-calc));
    --margin-bottom-override: calc(var(--margin-bottom)  - var(--inset-padding-bottom-calc));
    --margin-left-override: calc(var(--margin-left)  - var(--inset-padding-left-calc));
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: var(--inset-border-radius-calc);
    border-bottom-right-radius: var(--inset-border-radius-calc);
  }

  .rt-Inset:where(.xl\:rt-r-side-left) {
    --margin-top-override: calc(var(--margin-top)  - var(--inset-padding-top-calc));
    --margin-bottom-override: calc(var(--margin-bottom)  - var(--inset-padding-bottom-calc));
    --margin-left-override: calc(var(--margin-left)  - var(--inset-padding-left-calc));
    --margin-right-override: var(--margin-right);
    border-top-left-radius: var(--inset-border-radius-calc);
    border-top-right-radius: 0;
    border-bottom-left-radius: var(--inset-border-radius-calc);
    border-bottom-right-radius: 0;
  }

  .rt-Inset:where(.xl\:rt-r-side-right) {
    --margin-top-override: calc(var(--margin-top)  - var(--inset-padding-top-calc));
    --margin-right-override: calc(var(--margin-right)  - var(--inset-padding-right-calc));
    --margin-bottom-override: calc(var(--margin-bottom)  - var(--inset-padding-bottom-calc));
    --margin-left-override: var(--margin-left);
    border-top-left-radius: 0;
    border-top-right-radius: var(--inset-border-radius-calc);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: var(--inset-border-radius-calc);
  }

  .rt-Inset:where(.xl\:rt-r-side-x) {
    --margin-top-override: var(--margin-top);
    --margin-right-override: calc(var(--margin-right)  - var(--inset-padding-right-calc));
    --margin-bottom-override: var(--margin-bottom);
    --margin-left-override: calc(var(--margin-left)  - var(--inset-padding-left-calc));
    border-radius: 0;
  }

  .rt-Inset:where(.xl\:rt-r-side-y) {
    --margin-top-override: calc(var(--margin-top)  - var(--inset-padding-top-calc));
    --margin-right-override: var(--margin-right);
    --margin-bottom-override: calc(var(--margin-bottom)  - var(--inset-padding-bottom-calc));
    --margin-left-override: var(--margin-left);
    border-radius: 0;
  }

  .rt-Inset:where(.xl\:rt-r-side-all) {
    --margin-top-override: calc(var(--margin-top)  - var(--inset-padding-top-calc));
    --margin-right-override: calc(var(--margin-right)  - var(--inset-padding-right-calc));
    --margin-bottom-override: calc(var(--margin-bottom)  - var(--inset-padding-bottom-calc));
    --margin-left-override: calc(var(--margin-left)  - var(--inset-padding-left-calc));
    border-radius: var(--inset-border-radius-calc);
  }
}

.rt-Kbd {
  font-family: var(--default-font-family);
  vertical-align: text-top;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  box-sizing: border-box;
  word-spacing: -.1em;
  border-radius: calc(var(--radius-factor) * .35em);
  min-width: 1.75em;
  letter-spacing: var(--letter-spacing, var(--default-letter-spacing));
  height: -moz-fit-content;
  height: fit-content;
  color: var(--gray-12);
  background-color: var(--gray-1);
  box-shadow: var(--kbd-box-shadow);
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  padding-bottom: .05em;
  padding-left: .5em;
  padding-right: .5em;
  font-size: .75em;
  font-weight: normal;
  line-height: 1.7em;
  transition: box-shadow .12s, background-color .12s;
  display: inline-flex;
  position: relative;
  top: -.03em;
}

@media (hover: hover) {
  .rt-Kbd:where(:-webkit-any-link, button):where(:hover) {
    background-color: var(--color-background);
    box-shadow: var(--kbd-box-shadow), 0 0 0 .05em var(--gray-a5);
    transition-duration: 40ms, 40ms;
  }

  .rt-Kbd:where(:any-link, button):where(:hover) {
    background-color: var(--color-background);
    box-shadow: var(--kbd-box-shadow), 0 0 0 .05em var(--gray-a5);
    transition-duration: 40ms, 40ms;
  }
}

.rt-Kbd:where(:-webkit-any-link, button):where([data-state="open"]) {
  background-color: var(--color-background);
  box-shadow: var(--kbd-box-shadow), 0 0 0 .05em var(--gray-a5);
  transition-duration: 40ms, 40ms;
}

.rt-Kbd:where(:any-link, button):where([data-state="open"]) {
  background-color: var(--color-background);
  box-shadow: var(--kbd-box-shadow), 0 0 0 .05em var(--gray-a5);
  transition-duration: 40ms, 40ms;
}

.rt-Kbd:where(:-webkit-any-link, button):where(:active:not([data-state="open"])) {
  background-color: var(--gray-2);
  box-shadow: inset 0 .05em var(--black-a3), 0 0 0 .05em var(--gray-a7);
  padding-top: .05em;
  padding-bottom: 0;
  transition-duration: 40ms, 40ms;
}

.rt-Kbd:where(:any-link, button):where(:active:not([data-state="open"])) {
  background-color: var(--gray-2);
  box-shadow: inset 0 .05em var(--black-a3), 0 0 0 .05em var(--gray-a7);
  padding-top: .05em;
  padding-bottom: 0;
  transition-duration: 40ms, 40ms;
}

.rt-Kbd:where(:-webkit-any-link, button):where(:focus-visible) {
  outline: 2px solid var(--focus-8);
}

.rt-Kbd:where(:any-link, button):where(:focus-visible) {
  outline: 2px solid var(--focus-8);
}

.rt-Kbd:where(.rt-r-size-1) {
  font-size: calc(var(--font-size-1) * .8);
  --letter-spacing: var(--letter-spacing-1);
}

.rt-Kbd:where(.rt-r-size-2) {
  font-size: calc(var(--font-size-2) * .8);
  --letter-spacing: var(--letter-spacing-2);
}

.rt-Kbd:where(.rt-r-size-3) {
  font-size: calc(var(--font-size-3) * .8);
  --letter-spacing: var(--letter-spacing-3);
}

.rt-Kbd:where(.rt-r-size-4) {
  font-size: calc(var(--font-size-4) * .8);
  --letter-spacing: var(--letter-spacing-4);
}

.rt-Kbd:where(.rt-r-size-5) {
  font-size: calc(var(--font-size-5) * .8);
  --letter-spacing: var(--letter-spacing-5);
}

.rt-Kbd:where(.rt-r-size-6) {
  font-size: calc(var(--font-size-6) * .8);
  --letter-spacing: var(--letter-spacing-6);
}

.rt-Kbd:where(.rt-r-size-7) {
  font-size: calc(var(--font-size-7) * .8);
  --letter-spacing: var(--letter-spacing-7);
}

.rt-Kbd:where(.rt-r-size-8) {
  font-size: calc(var(--font-size-8) * .8);
  --letter-spacing: var(--letter-spacing-8);
}

.rt-Kbd:where(.rt-r-size-9) {
  font-size: calc(var(--font-size-9) * .8);
  --letter-spacing: var(--letter-spacing-9);
}

@media (min-width: 520px) {
  .rt-Kbd:where(.xs\:rt-r-size-1) {
    font-size: calc(var(--font-size-1) * .8);
    --letter-spacing: var(--letter-spacing-1);
  }

  .rt-Kbd:where(.xs\:rt-r-size-2) {
    font-size: calc(var(--font-size-2) * .8);
    --letter-spacing: var(--letter-spacing-2);
  }

  .rt-Kbd:where(.xs\:rt-r-size-3) {
    font-size: calc(var(--font-size-3) * .8);
    --letter-spacing: var(--letter-spacing-3);
  }

  .rt-Kbd:where(.xs\:rt-r-size-4) {
    font-size: calc(var(--font-size-4) * .8);
    --letter-spacing: var(--letter-spacing-4);
  }

  .rt-Kbd:where(.xs\:rt-r-size-5) {
    font-size: calc(var(--font-size-5) * .8);
    --letter-spacing: var(--letter-spacing-5);
  }

  .rt-Kbd:where(.xs\:rt-r-size-6) {
    font-size: calc(var(--font-size-6) * .8);
    --letter-spacing: var(--letter-spacing-6);
  }

  .rt-Kbd:where(.xs\:rt-r-size-7) {
    font-size: calc(var(--font-size-7) * .8);
    --letter-spacing: var(--letter-spacing-7);
  }

  .rt-Kbd:where(.xs\:rt-r-size-8) {
    font-size: calc(var(--font-size-8) * .8);
    --letter-spacing: var(--letter-spacing-8);
  }

  .rt-Kbd:where(.xs\:rt-r-size-9) {
    font-size: calc(var(--font-size-9) * .8);
    --letter-spacing: var(--letter-spacing-9);
  }
}

@media (min-width: 768px) {
  .rt-Kbd:where(.sm\:rt-r-size-1) {
    font-size: calc(var(--font-size-1) * .8);
    --letter-spacing: var(--letter-spacing-1);
  }

  .rt-Kbd:where(.sm\:rt-r-size-2) {
    font-size: calc(var(--font-size-2) * .8);
    --letter-spacing: var(--letter-spacing-2);
  }

  .rt-Kbd:where(.sm\:rt-r-size-3) {
    font-size: calc(var(--font-size-3) * .8);
    --letter-spacing: var(--letter-spacing-3);
  }

  .rt-Kbd:where(.sm\:rt-r-size-4) {
    font-size: calc(var(--font-size-4) * .8);
    --letter-spacing: var(--letter-spacing-4);
  }

  .rt-Kbd:where(.sm\:rt-r-size-5) {
    font-size: calc(var(--font-size-5) * .8);
    --letter-spacing: var(--letter-spacing-5);
  }

  .rt-Kbd:where(.sm\:rt-r-size-6) {
    font-size: calc(var(--font-size-6) * .8);
    --letter-spacing: var(--letter-spacing-6);
  }

  .rt-Kbd:where(.sm\:rt-r-size-7) {
    font-size: calc(var(--font-size-7) * .8);
    --letter-spacing: var(--letter-spacing-7);
  }

  .rt-Kbd:where(.sm\:rt-r-size-8) {
    font-size: calc(var(--font-size-8) * .8);
    --letter-spacing: var(--letter-spacing-8);
  }

  .rt-Kbd:where(.sm\:rt-r-size-9) {
    font-size: calc(var(--font-size-9) * .8);
    --letter-spacing: var(--letter-spacing-9);
  }
}

@media (min-width: 1024px) {
  .rt-Kbd:where(.md\:rt-r-size-1) {
    font-size: calc(var(--font-size-1) * .8);
    --letter-spacing: var(--letter-spacing-1);
  }

  .rt-Kbd:where(.md\:rt-r-size-2) {
    font-size: calc(var(--font-size-2) * .8);
    --letter-spacing: var(--letter-spacing-2);
  }

  .rt-Kbd:where(.md\:rt-r-size-3) {
    font-size: calc(var(--font-size-3) * .8);
    --letter-spacing: var(--letter-spacing-3);
  }

  .rt-Kbd:where(.md\:rt-r-size-4) {
    font-size: calc(var(--font-size-4) * .8);
    --letter-spacing: var(--letter-spacing-4);
  }

  .rt-Kbd:where(.md\:rt-r-size-5) {
    font-size: calc(var(--font-size-5) * .8);
    --letter-spacing: var(--letter-spacing-5);
  }

  .rt-Kbd:where(.md\:rt-r-size-6) {
    font-size: calc(var(--font-size-6) * .8);
    --letter-spacing: var(--letter-spacing-6);
  }

  .rt-Kbd:where(.md\:rt-r-size-7) {
    font-size: calc(var(--font-size-7) * .8);
    --letter-spacing: var(--letter-spacing-7);
  }

  .rt-Kbd:where(.md\:rt-r-size-8) {
    font-size: calc(var(--font-size-8) * .8);
    --letter-spacing: var(--letter-spacing-8);
  }

  .rt-Kbd:where(.md\:rt-r-size-9) {
    font-size: calc(var(--font-size-9) * .8);
    --letter-spacing: var(--letter-spacing-9);
  }
}

@media (min-width: 1280px) {
  .rt-Kbd:where(.lg\:rt-r-size-1) {
    font-size: calc(var(--font-size-1) * .8);
    --letter-spacing: var(--letter-spacing-1);
  }

  .rt-Kbd:where(.lg\:rt-r-size-2) {
    font-size: calc(var(--font-size-2) * .8);
    --letter-spacing: var(--letter-spacing-2);
  }

  .rt-Kbd:where(.lg\:rt-r-size-3) {
    font-size: calc(var(--font-size-3) * .8);
    --letter-spacing: var(--letter-spacing-3);
  }

  .rt-Kbd:where(.lg\:rt-r-size-4) {
    font-size: calc(var(--font-size-4) * .8);
    --letter-spacing: var(--letter-spacing-4);
  }

  .rt-Kbd:where(.lg\:rt-r-size-5) {
    font-size: calc(var(--font-size-5) * .8);
    --letter-spacing: var(--letter-spacing-5);
  }

  .rt-Kbd:where(.lg\:rt-r-size-6) {
    font-size: calc(var(--font-size-6) * .8);
    --letter-spacing: var(--letter-spacing-6);
  }

  .rt-Kbd:where(.lg\:rt-r-size-7) {
    font-size: calc(var(--font-size-7) * .8);
    --letter-spacing: var(--letter-spacing-7);
  }

  .rt-Kbd:where(.lg\:rt-r-size-8) {
    font-size: calc(var(--font-size-8) * .8);
    --letter-spacing: var(--letter-spacing-8);
  }

  .rt-Kbd:where(.lg\:rt-r-size-9) {
    font-size: calc(var(--font-size-9) * .8);
    --letter-spacing: var(--letter-spacing-9);
  }
}

@media (min-width: 1640px) {
  .rt-Kbd:where(.xl\:rt-r-size-1) {
    font-size: calc(var(--font-size-1) * .8);
    --letter-spacing: var(--letter-spacing-1);
  }

  .rt-Kbd:where(.xl\:rt-r-size-2) {
    font-size: calc(var(--font-size-2) * .8);
    --letter-spacing: var(--letter-spacing-2);
  }

  .rt-Kbd:where(.xl\:rt-r-size-3) {
    font-size: calc(var(--font-size-3) * .8);
    --letter-spacing: var(--letter-spacing-3);
  }

  .rt-Kbd:where(.xl\:rt-r-size-4) {
    font-size: calc(var(--font-size-4) * .8);
    --letter-spacing: var(--letter-spacing-4);
  }

  .rt-Kbd:where(.xl\:rt-r-size-5) {
    font-size: calc(var(--font-size-5) * .8);
    --letter-spacing: var(--letter-spacing-5);
  }

  .rt-Kbd:where(.xl\:rt-r-size-6) {
    font-size: calc(var(--font-size-6) * .8);
    --letter-spacing: var(--letter-spacing-6);
  }

  .rt-Kbd:where(.xl\:rt-r-size-7) {
    font-size: calc(var(--font-size-7) * .8);
    --letter-spacing: var(--letter-spacing-7);
  }

  .rt-Kbd:where(.xl\:rt-r-size-8) {
    font-size: calc(var(--font-size-8) * .8);
    --letter-spacing: var(--letter-spacing-8);
  }

  .rt-Kbd:where(.xl\:rt-r-size-9) {
    font-size: calc(var(--font-size-9) * .8);
    --letter-spacing: var(--letter-spacing-9);
  }
}

.rt-Link:where(:-webkit-any-link, button) {
  cursor: var(--cursor-link);
  text-underline-offset: calc(.025em + 2px);
  -webkit-text-decoration-line: none;
  text-decoration-line: none;
  -webkit-text-decoration-style: solid;
  text-decoration-style: solid;
  text-decoration-thickness: min(2px, max(1px, .05em));
  -webkit-text-decoration-color: var(--accent-a5);
  text-decoration-color: var(--accent-a5);
}

.rt-Link:where(:any-link, button) {
  cursor: var(--cursor-link);
  text-underline-offset: calc(.025em + 2px);
  -webkit-text-decoration-line: none;
  text-decoration-line: none;
  -webkit-text-decoration-style: solid;
  text-decoration-style: solid;
  text-decoration-thickness: min(2px, max(1px, .05em));
  -webkit-text-decoration-color: var(--accent-a5);
  text-decoration-color: var(--accent-a5);
}

.rt-Link:where(:disabled, [data-disabled]) {
  cursor: var(--cursor-disabled);
}

:where([data-accent-color]:not(:-webkit-any(.radix-themes, .rt-high-contrast))) .rt-Link:where([data-accent-color=""]) {
  color: var(--accent-12);
}

:where([data-accent-color]:not(:-moz-any(.radix-themes, .rt-high-contrast))) .rt-Link:where([data-accent-color=""]) {
  color: var(--accent-12);
}

:where([data-accent-color]:not(:is(.radix-themes, .rt-high-contrast))) .rt-Link:where([data-accent-color=""]) {
  color: var(--accent-12);
}

@supports (color: color-mix(in oklab, white, black)) {
  .rt-Link:where(:-webkit-any-link, button) {
    -webkit-text-decoration-color: color-mix(in oklab, var(--accent-a5), var(--gray-a6));
    text-decoration-color: color-mix(in oklab, var(--accent-a5), var(--gray-a6));
  }

  .rt-Link:where(:any-link, button) {
    -webkit-text-decoration-color: color-mix(in oklab, var(--accent-a5), var(--gray-a6));
    text-decoration-color: color-mix(in oklab, var(--accent-a5), var(--gray-a6));
  }
}

@media (pointer: coarse) {
  .rt-Link:where(:-webkit-any-link, button):where(:active:not(:-webkit-any(:focus-visible, [data-state="open"]))) {
    outline: .75em solid var(--accent-a4);
    outline-offset: -.6em;
  }

  .rt-Link:where(:any-link, button):where(:active:not(:-moz-any(:focus-visible, [data-state="open"]))) {
    outline: .75em solid var(--accent-a4);
    outline-offset: -.6em;
  }

  .rt-Link:where(:any-link, button):where(:active:not(:is(:focus-visible, [data-state="open"]))) {
    outline: .75em solid var(--accent-a4);
    outline-offset: -.6em;
  }
}

@media (hover: hover) {
  .rt-Link:where(:-webkit-any-link, button):where(.rt-underline-auto):where(:hover) {
    -webkit-text-decoration-line: underline;
    text-decoration-line: underline;
  }

  .rt-Link:where(:any-link, button):where(.rt-underline-auto):where(:hover) {
    -webkit-text-decoration-line: underline;
    text-decoration-line: underline;
  }
}

.rt-Link:where(:-webkit-any-link, button):where(.rt-underline-auto):where(.rt-high-contrast) {
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
  -webkit-text-decoration-color: var(--accent-a6);
  text-decoration-color: var(--accent-a6);
}

.rt-Link:where(:any-link, button):where(.rt-underline-auto):where(.rt-high-contrast) {
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
  -webkit-text-decoration-color: var(--accent-a6);
  text-decoration-color: var(--accent-a6);
}

:where([data-accent-color]:not(:-webkit-any(.radix-themes, .rt-high-contrast))) .rt-Link:where(:-webkit-any-link, button):where(.rt-underline-auto):where([data-accent-color=""]) {
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
  -webkit-text-decoration-color: var(--accent-a6);
  text-decoration-color: var(--accent-a6);
}

:where([data-accent-color]:not(:-moz-any(.radix-themes, .rt-high-contrast))) .rt-Link:where(:any-link, button):where(.rt-underline-auto):where([data-accent-color=""]) {
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
  -webkit-text-decoration-color: var(--accent-a6);
  text-decoration-color: var(--accent-a6);
}

:where([data-accent-color]:not(:is(.radix-themes, .rt-high-contrast))) .rt-Link:where(:any-link, button):where(.rt-underline-auto):where([data-accent-color=""]) {
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
  -webkit-text-decoration-color: var(--accent-a6);
  text-decoration-color: var(--accent-a6);
}

@supports (color: color-mix(in oklab, white, black)) {
  .rt-Link:where(:-webkit-any-link, button):where(.rt-underline-auto):where(.rt-high-contrast) {
    -webkit-text-decoration-color: color-mix(in oklab, var(--accent-a6), var(--gray-a6));
    text-decoration-color: color-mix(in oklab, var(--accent-a6), var(--gray-a6));
  }

  .rt-Link:where(:any-link, button):where(.rt-underline-auto):where(.rt-high-contrast) {
    -webkit-text-decoration-color: color-mix(in oklab, var(--accent-a6), var(--gray-a6));
    text-decoration-color: color-mix(in oklab, var(--accent-a6), var(--gray-a6));
  }

  :where([data-accent-color]:not(:-webkit-any(.radix-themes, .rt-high-contrast))) .rt-Link:where(:-webkit-any-link, button):where(.rt-underline-auto):where([data-accent-color=""]) {
    -webkit-text-decoration-color: color-mix(in oklab, var(--accent-a6), var(--gray-a6));
    text-decoration-color: color-mix(in oklab, var(--accent-a6), var(--gray-a6));
  }

  :where([data-accent-color]:not(:-moz-any(.radix-themes, .rt-high-contrast))) .rt-Link:where(:any-link, button):where(.rt-underline-auto):where([data-accent-color=""]) {
    -webkit-text-decoration-color: color-mix(in oklab, var(--accent-a6), var(--gray-a6));
    text-decoration-color: color-mix(in oklab, var(--accent-a6), var(--gray-a6));
  }

  :where([data-accent-color]:not(:is(.radix-themes, .rt-high-contrast))) .rt-Link:where(:any-link, button):where(.rt-underline-auto):where([data-accent-color=""]) {
    -webkit-text-decoration-color: color-mix(in oklab, var(--accent-a6), var(--gray-a6));
    text-decoration-color: color-mix(in oklab, var(--accent-a6), var(--gray-a6));
  }
}

@media (hover: hover) {
  .rt-Link:where(:-webkit-any-link, button):where(.rt-underline-hover):where(:hover) {
    -webkit-text-decoration-line: underline;
    text-decoration-line: underline;
  }

  .rt-Link:where(:any-link, button):where(.rt-underline-hover):where(:hover) {
    -webkit-text-decoration-line: underline;
    text-decoration-line: underline;
  }
}

.rt-Link:where(:-webkit-any-link, button):where(.rt-underline-always) {
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}

.rt-Link:where(:any-link, button):where(.rt-underline-always) {
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}

.rt-Link:where(:focus-visible) {
  border-radius: calc(.07em * var(--radius-factor));
  outline-color: var(--focus-8);
  outline-offset: 2px;
  outline-width: 2px;
  outline-style: solid;
  -webkit-text-decoration-line: none;
  text-decoration-line: none;
}

.rt-Link:where(:has(.rt-Code:not(.rt-variant-ghost):only-child)) {
  -webkit-text-decoration-color: rgba(0, 0, 0, 0);
  text-decoration-color: rgba(0, 0, 0, 0);
}

.rt-PopoverContent {
  background-color: var(--color-panel-solid);
  box-shadow: var(--shadow-5);
  min-width: var(--radix-popover-trigger-width);
  --inset-padding-top: var(--popover-content-padding);
  --inset-padding-right: var(--popover-content-padding);
  --inset-padding-bottom: var(--popover-content-padding);
  --inset-padding-left: var(--popover-content-padding);
  padding: var(--popover-content-padding);
  box-sizing: border-box;
  transform-origin: var(--radix-popover-content-transform-origin);
  outline: 0;
  position: relative;
  overflow: auto;
}

.rt-PopoverContent:where(.rt-r-size-1) {
  --popover-content-padding: var(--space-3);
  border-radius: var(--radius-4);
}

.rt-PopoverContent:where(.rt-r-size-2) {
  --popover-content-padding: var(--space-4);
  border-radius: var(--radius-4);
}

.rt-PopoverContent:where(.rt-r-size-3) {
  --popover-content-padding: var(--space-5);
  border-radius: var(--radius-5);
}

.rt-PopoverContent:where(.rt-r-size-4) {
  --popover-content-padding: var(--space-6);
  border-radius: var(--radius-5);
}

@media (min-width: 520px) {
  .rt-PopoverContent:where(.xs\:rt-r-size-1) {
    --popover-content-padding: var(--space-3);
    border-radius: var(--radius-4);
  }

  .rt-PopoverContent:where(.xs\:rt-r-size-2) {
    --popover-content-padding: var(--space-4);
    border-radius: var(--radius-4);
  }

  .rt-PopoverContent:where(.xs\:rt-r-size-3) {
    --popover-content-padding: var(--space-5);
    border-radius: var(--radius-5);
  }

  .rt-PopoverContent:where(.xs\:rt-r-size-4) {
    --popover-content-padding: var(--space-6);
    border-radius: var(--radius-5);
  }
}

@media (min-width: 768px) {
  .rt-PopoverContent:where(.sm\:rt-r-size-1) {
    --popover-content-padding: var(--space-3);
    border-radius: var(--radius-4);
  }

  .rt-PopoverContent:where(.sm\:rt-r-size-2) {
    --popover-content-padding: var(--space-4);
    border-radius: var(--radius-4);
  }

  .rt-PopoverContent:where(.sm\:rt-r-size-3) {
    --popover-content-padding: var(--space-5);
    border-radius: var(--radius-5);
  }

  .rt-PopoverContent:where(.sm\:rt-r-size-4) {
    --popover-content-padding: var(--space-6);
    border-radius: var(--radius-5);
  }
}

@media (min-width: 1024px) {
  .rt-PopoverContent:where(.md\:rt-r-size-1) {
    --popover-content-padding: var(--space-3);
    border-radius: var(--radius-4);
  }

  .rt-PopoverContent:where(.md\:rt-r-size-2) {
    --popover-content-padding: var(--space-4);
    border-radius: var(--radius-4);
  }

  .rt-PopoverContent:where(.md\:rt-r-size-3) {
    --popover-content-padding: var(--space-5);
    border-radius: var(--radius-5);
  }

  .rt-PopoverContent:where(.md\:rt-r-size-4) {
    --popover-content-padding: var(--space-6);
    border-radius: var(--radius-5);
  }
}

@media (min-width: 1280px) {
  .rt-PopoverContent:where(.lg\:rt-r-size-1) {
    --popover-content-padding: var(--space-3);
    border-radius: var(--radius-4);
  }

  .rt-PopoverContent:where(.lg\:rt-r-size-2) {
    --popover-content-padding: var(--space-4);
    border-radius: var(--radius-4);
  }

  .rt-PopoverContent:where(.lg\:rt-r-size-3) {
    --popover-content-padding: var(--space-5);
    border-radius: var(--radius-5);
  }

  .rt-PopoverContent:where(.lg\:rt-r-size-4) {
    --popover-content-padding: var(--space-6);
    border-radius: var(--radius-5);
  }
}

@media (min-width: 1640px) {
  .rt-PopoverContent:where(.xl\:rt-r-size-1) {
    --popover-content-padding: var(--space-3);
    border-radius: var(--radius-4);
  }

  .rt-PopoverContent:where(.xl\:rt-r-size-2) {
    --popover-content-padding: var(--space-4);
    border-radius: var(--radius-4);
  }

  .rt-PopoverContent:where(.xl\:rt-r-size-3) {
    --popover-content-padding: var(--space-5);
    border-radius: var(--radius-5);
  }

  .rt-PopoverContent:where(.xl\:rt-r-size-4) {
    --popover-content-padding: var(--space-6);
    border-radius: var(--radius-5);
  }
}

.rt-ProgressRoot {
  --progress-value: 0;
  --progress-max: 100;
  --progress-duration: 5s;
  pointer-events: none;
  height: var(--progress-height);
  border-radius: max(calc(var(--radius-factor) * var(--progress-height) / 3), calc(var(--radius-factor) * var(--radius-thumb)));
  flex-grow: 1;
  position: relative;
  overflow: hidden;
}

.rt-ProgressRoot:after {
  content: "";
  border-radius: inherit;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.rt-ProgressIndicator {
  width: 100%;
  height: 100%;
  transform: scaleX(calc(var(--progress-value) / var(--progress-max)));
  transform-origin: 0;
  transition: transform .12s;
  display: block;
}

.rt-ProgressIndicator:where([data-state="indeterminate"]) {
  animation-name: rt-progress-indicator-indeterminate-grow, var(--progress-indicator-indeterminate-animation-start), var(--progress-indicator-indeterminate-animation-repeat);
  animation-delay: 0s, calc(var(--progress-duration)  + 5s), calc(var(--progress-duration)  + 7.5s);
  animation-duration: var(--progress-duration), 2.5s, 5s;
  animation-iteration-count: 1, 1, infinite;
  animation-direction: normal, normal, alternate;
  animation-fill-mode: both, none, none;
}

.rt-ProgressIndicator:where([data-state="indeterminate"]):after {
  content: "";
  width: 400%;
  animation-name: rt-progress-indicator-indeterminate-shine-from-left;
  animation-delay: calc(var(--progress-duration)  + 5s);
  background-image: linear-gradient(to right, transparent 25%, var(--progress-indicator-after-linear-gradient), transparent 75%);
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-fill-mode: backwards;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

@keyframes rt-progress-indicator-indeterminate-grow {
  0% {
    transform: scaleX(.01);
  }

  20% {
    transform: scaleX(.1);
  }

  30% {
    transform: scaleX(.6);
  }

  40%, 50% {
    transform: scaleX(.9);
  }

  100% {
    transform: scaleX(1);
  }
}

@keyframes rt-progress-indicator-indeterminate-shine-from-left {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0%);
  }
}

.rt-ProgressRoot:where(.rt-r-size-1) {
  --progress-height: var(--space-1);
}

.rt-ProgressRoot:where(.rt-r-size-2) {
  --progress-height: calc(var(--space-2) * .75);
}

.rt-ProgressRoot:where(.rt-r-size-3) {
  --progress-height: var(--space-2);
}

@media (min-width: 520px) {
  .rt-ProgressRoot:where(.xs\:rt-r-size-1) {
    --progress-height: var(--space-1);
  }

  .rt-ProgressRoot:where(.xs\:rt-r-size-2) {
    --progress-height: calc(var(--space-2) * .75);
  }

  .rt-ProgressRoot:where(.xs\:rt-r-size-3) {
    --progress-height: var(--space-2);
  }
}

@media (min-width: 768px) {
  .rt-ProgressRoot:where(.sm\:rt-r-size-1) {
    --progress-height: var(--space-1);
  }

  .rt-ProgressRoot:where(.sm\:rt-r-size-2) {
    --progress-height: calc(var(--space-2) * .75);
  }

  .rt-ProgressRoot:where(.sm\:rt-r-size-3) {
    --progress-height: var(--space-2);
  }
}

@media (min-width: 1024px) {
  .rt-ProgressRoot:where(.md\:rt-r-size-1) {
    --progress-height: var(--space-1);
  }

  .rt-ProgressRoot:where(.md\:rt-r-size-2) {
    --progress-height: calc(var(--space-2) * .75);
  }

  .rt-ProgressRoot:where(.md\:rt-r-size-3) {
    --progress-height: var(--space-2);
  }
}

@media (min-width: 1280px) {
  .rt-ProgressRoot:where(.lg\:rt-r-size-1) {
    --progress-height: var(--space-1);
  }

  .rt-ProgressRoot:where(.lg\:rt-r-size-2) {
    --progress-height: calc(var(--space-2) * .75);
  }

  .rt-ProgressRoot:where(.lg\:rt-r-size-3) {
    --progress-height: var(--space-2);
  }
}

@media (min-width: 1640px) {
  .rt-ProgressRoot:where(.xl\:rt-r-size-1) {
    --progress-height: var(--space-1);
  }

  .rt-ProgressRoot:where(.xl\:rt-r-size-2) {
    --progress-height: calc(var(--space-2) * .75);
  }

  .rt-ProgressRoot:where(.xl\:rt-r-size-3) {
    --progress-height: var(--space-2);
  }
}

.rt-ProgressRoot:where(.rt-variant-surface) {
  --progress-indicator-indeterminate-animation-start: rt-progress-indicator-surface-indeterminate-fade;
  --progress-indicator-indeterminate-animation-repeat: rt-progress-indicator-surface-indeterminate-pulse;
  background-color: var(--gray-a3);
}

.rt-ProgressRoot:where(.rt-variant-surface):after {
  box-shadow: inset 0 0 0 1px var(--gray-a4);
}

.rt-ProgressRoot:where(.rt-variant-surface) :where(.rt-ProgressIndicator) {
  background-color: var(--accent-track);
}

@keyframes rt-progress-indicator-surface-indeterminate-fade {
  100% {
    background-color: var(--accent-7);
  }
}

@keyframes rt-progress-indicator-surface-indeterminate-pulse {
  0% {
    background-color: var(--accent-7);
  }

  100% {
    background-color: var(--accent-track);
  }
}

.rt-ProgressRoot:where(.rt-variant-classic) {
  --progress-indicator-indeterminate-animation-start: rt-progress-indicator-classic-indeterminate-fade;
  --progress-indicator-indeterminate-animation-repeat: rt-progress-indicator-classic-indeterminate-pulse;
  background-color: var(--gray-a3);
}

.rt-ProgressRoot:where(.rt-variant-classic):after {
  box-shadow: var(--shadow-1);
}

.rt-ProgressRoot:where(.rt-variant-classic) :where(.rt-ProgressIndicator) {
  background-color: var(--accent-track);
}

@keyframes rt-progress-indicator-classic-indeterminate-fade {
  100% {
    background-color: var(--accent-7);
  }
}

@keyframes rt-progress-indicator-classic-indeterminate-pulse {
  0% {
    background-color: var(--accent-7);
  }

  100% {
    background-color: var(--accent-track);
  }
}

.rt-ProgressRoot:where(.rt-variant-soft) {
  --progress-indicator-indeterminate-animation-start: rt-progress-indicator-soft-indeterminate-fade;
  --progress-indicator-indeterminate-animation-repeat: rt-progress-indicator-soft-indeterminate-pulse;
  background-color: var(--gray-a4);
  background-image: linear-gradient(var(--white-a1), var(--white-a1));
}

.rt-ProgressRoot:where(.rt-variant-soft) :where(.rt-ProgressIndicator) {
  background-image: linear-gradient(var(--accent-a5), var(--accent-a5));
  background-color: var(--accent-8);
}

.rt-ProgressRoot:where(.rt-variant-soft) :where(.rt-ProgressIndicator):after {
  opacity: .75;
}

@keyframes rt-progress-indicator-soft-indeterminate-fade {
  100% {
    background-color: var(--accent-5);
  }
}

@keyframes rt-progress-indicator-soft-indeterminate-pulse {
  0% {
    background-color: var(--accent-5);
  }

  100% {
    background-color: var(--accent-7);
  }
}

.rt-ProgressRoot:where(.rt-high-contrast) {
  --progress-indicator-indeterminate-animation-start: rt-progress-indicator-high-contrast-indeterminate-fade;
  --progress-indicator-indeterminate-animation-repeat: rt-progress-indicator-high-contrast-indeterminate-pulse;
}

.rt-ProgressRoot:where(.rt-high-contrast) :where(.rt-ProgressIndicator) {
  background-color: var(--accent-12);
}

.rt-ProgressRoot:where(.rt-high-contrast) :where(.rt-ProgressIndicator):after {
  opacity: .75;
}

@keyframes rt-progress-indicator-high-contrast-indeterminate-fade {
  100% {
    opacity: .8;
  }
}

@keyframes rt-progress-indicator-high-contrast-indeterminate-pulse {
  0% {
    opacity: .8;
  }

  100% {
    opacity: 1;
  }
}

.rt-Quote {
  box-sizing: border-box;
  font-family: var(--quote-font-family);
  font-size: calc(var(--quote-font-size-adjust) * 1em);
  font-style: var(--quote-font-style);
  font-weight: var(--quote-font-weight);
  letter-spacing: calc(var(--quote-letter-spacing)  + var(--letter-spacing, var(--default-letter-spacing)));
  color: inherit;
  line-height: 1.25;
}

.rt-Quote :where(.rt-Quote) {
  font-size: inherit;
}

.rt-RadioCardsRoot {
  line-height: var(--line-height);
  letter-spacing: var(--letter-spacing);
  cursor: default;
}

.rt-RadioCardsItem {
  --base-card-padding-top: var(--radio-cards-item-padding-y);
  --base-card-padding-right: var(--radio-cards-item-padding-x);
  --base-card-padding-bottom: var(--radio-cards-item-padding-y);
  --base-card-padding-left: var(--radio-cards-item-padding-x);
  --base-card-border-radius: var(--radio-cards-item-border-radius);
  --base-card-border-width: var(--radio-cards-item-border-width);
  justify-content: center;
  align-items: center;
  gap: var(--space-2);
  display: flex;
}

.rt-RadioCardsItem > * {
  pointer-events: none;
}

.rt-RadioCardsItem > :where(svg) {
  flex-shrink: 0;
}

.rt-RadioCardsItem:after {
  outline-offset: -1px;
}

.rt-RadioCardsRoot:where(.rt-r-size-1) {
  font-size: var(--font-size-2);
  --line-height: var(--line-height-2);
  --letter-spacing: var(--letter-spacing-2);
  --radio-cards-item-padding-x: var(--space-3);
  --radio-cards-item-padding-y: calc(var(--space-3) / 1.2);
  --radio-cards-item-border-radius: var(--radius-3);
}

.rt-RadioCardsRoot:where(.rt-r-size-2) {
  font-size: var(--font-size-2);
  --line-height: var(--line-height-2);
  --letter-spacing: var(--letter-spacing-2);
  --radio-cards-item-padding-x: var(--space-4);
  --radio-cards-item-padding-y: calc(var(--space-4) * .875);
  --radio-cards-item-border-radius: var(--radius-3);
}

.rt-RadioCardsRoot:where(.rt-r-size-3) {
  font-size: var(--font-size-3);
  --line-height: var(--line-height-3);
  --letter-spacing: var(--letter-spacing-3);
  --radio-cards-item-padding-x: var(--space-5);
  --radio-cards-item-padding-y: calc(var(--space-5) / 1.2);
  --radio-cards-item-border-radius: var(--radius-4);
}

@media (min-width: 520px) {
  .rt-RadioCardsRoot:where(.xs\:rt-r-size-1) {
    font-size: var(--font-size-2);
    --line-height: var(--line-height-2);
    --letter-spacing: var(--letter-spacing-2);
    --radio-cards-item-padding-x: var(--space-3);
    --radio-cards-item-padding-y: calc(var(--space-3) / 1.2);
    --radio-cards-item-border-radius: var(--radius-3);
  }

  .rt-RadioCardsRoot:where(.xs\:rt-r-size-2) {
    font-size: var(--font-size-2);
    --line-height: var(--line-height-2);
    --letter-spacing: var(--letter-spacing-2);
    --radio-cards-item-padding-x: var(--space-4);
    --radio-cards-item-padding-y: calc(var(--space-4) * .875);
    --radio-cards-item-border-radius: var(--radius-3);
  }

  .rt-RadioCardsRoot:where(.xs\:rt-r-size-3) {
    font-size: var(--font-size-3);
    --line-height: var(--line-height-3);
    --letter-spacing: var(--letter-spacing-3);
    --radio-cards-item-padding-x: var(--space-5);
    --radio-cards-item-padding-y: calc(var(--space-5) / 1.2);
    --radio-cards-item-border-radius: var(--radius-4);
  }
}

@media (min-width: 768px) {
  .rt-RadioCardsRoot:where(.sm\:rt-r-size-1) {
    font-size: var(--font-size-2);
    --line-height: var(--line-height-2);
    --letter-spacing: var(--letter-spacing-2);
    --radio-cards-item-padding-x: var(--space-3);
    --radio-cards-item-padding-y: calc(var(--space-3) / 1.2);
    --radio-cards-item-border-radius: var(--radius-3);
  }

  .rt-RadioCardsRoot:where(.sm\:rt-r-size-2) {
    font-size: var(--font-size-2);
    --line-height: var(--line-height-2);
    --letter-spacing: var(--letter-spacing-2);
    --radio-cards-item-padding-x: var(--space-4);
    --radio-cards-item-padding-y: calc(var(--space-4) * .875);
    --radio-cards-item-border-radius: var(--radius-3);
  }

  .rt-RadioCardsRoot:where(.sm\:rt-r-size-3) {
    font-size: var(--font-size-3);
    --line-height: var(--line-height-3);
    --letter-spacing: var(--letter-spacing-3);
    --radio-cards-item-padding-x: var(--space-5);
    --radio-cards-item-padding-y: calc(var(--space-5) / 1.2);
    --radio-cards-item-border-radius: var(--radius-4);
  }
}

@media (min-width: 1024px) {
  .rt-RadioCardsRoot:where(.md\:rt-r-size-1) {
    font-size: var(--font-size-2);
    --line-height: var(--line-height-2);
    --letter-spacing: var(--letter-spacing-2);
    --radio-cards-item-padding-x: var(--space-3);
    --radio-cards-item-padding-y: calc(var(--space-3) / 1.2);
    --radio-cards-item-border-radius: var(--radius-3);
  }

  .rt-RadioCardsRoot:where(.md\:rt-r-size-2) {
    font-size: var(--font-size-2);
    --line-height: var(--line-height-2);
    --letter-spacing: var(--letter-spacing-2);
    --radio-cards-item-padding-x: var(--space-4);
    --radio-cards-item-padding-y: calc(var(--space-4) * .875);
    --radio-cards-item-border-radius: var(--radius-3);
  }

  .rt-RadioCardsRoot:where(.md\:rt-r-size-3) {
    font-size: var(--font-size-3);
    --line-height: var(--line-height-3);
    --letter-spacing: var(--letter-spacing-3);
    --radio-cards-item-padding-x: var(--space-5);
    --radio-cards-item-padding-y: calc(var(--space-5) / 1.2);
    --radio-cards-item-border-radius: var(--radius-4);
  }
}

@media (min-width: 1280px) {
  .rt-RadioCardsRoot:where(.lg\:rt-r-size-1) {
    font-size: var(--font-size-2);
    --line-height: var(--line-height-2);
    --letter-spacing: var(--letter-spacing-2);
    --radio-cards-item-padding-x: var(--space-3);
    --radio-cards-item-padding-y: calc(var(--space-3) / 1.2);
    --radio-cards-item-border-radius: var(--radius-3);
  }

  .rt-RadioCardsRoot:where(.lg\:rt-r-size-2) {
    font-size: var(--font-size-2);
    --line-height: var(--line-height-2);
    --letter-spacing: var(--letter-spacing-2);
    --radio-cards-item-padding-x: var(--space-4);
    --radio-cards-item-padding-y: calc(var(--space-4) * .875);
    --radio-cards-item-border-radius: var(--radius-3);
  }

  .rt-RadioCardsRoot:where(.lg\:rt-r-size-3) {
    font-size: var(--font-size-3);
    --line-height: var(--line-height-3);
    --letter-spacing: var(--letter-spacing-3);
    --radio-cards-item-padding-x: var(--space-5);
    --radio-cards-item-padding-y: calc(var(--space-5) / 1.2);
    --radio-cards-item-border-radius: var(--radius-4);
  }
}

@media (min-width: 1640px) {
  .rt-RadioCardsRoot:where(.xl\:rt-r-size-1) {
    font-size: var(--font-size-2);
    --line-height: var(--line-height-2);
    --letter-spacing: var(--letter-spacing-2);
    --radio-cards-item-padding-x: var(--space-3);
    --radio-cards-item-padding-y: calc(var(--space-3) / 1.2);
    --radio-cards-item-border-radius: var(--radius-3);
  }

  .rt-RadioCardsRoot:where(.xl\:rt-r-size-2) {
    font-size: var(--font-size-2);
    --line-height: var(--line-height-2);
    --letter-spacing: var(--letter-spacing-2);
    --radio-cards-item-padding-x: var(--space-4);
    --radio-cards-item-padding-y: calc(var(--space-4) * .875);
    --radio-cards-item-border-radius: var(--radius-3);
  }

  .rt-RadioCardsRoot:where(.xl\:rt-r-size-3) {
    font-size: var(--font-size-3);
    --line-height: var(--line-height-3);
    --letter-spacing: var(--letter-spacing-3);
    --radio-cards-item-padding-x: var(--space-5);
    --radio-cards-item-padding-y: calc(var(--space-5) / 1.2);
    --radio-cards-item-border-radius: var(--radius-4);
  }
}

:where(.rt-RadioCardsRoot.rt-variant-surface) .rt-RadioCardsItem {
  --radio-cards-item-border-width: 1px;
  --radio-cards-item-background-color: var(--color-surface);
}

:where(.rt-RadioCardsRoot.rt-variant-surface) .rt-RadioCardsItem:before {
  background-color: var(--radio-cards-item-background-color);
}

:where(.rt-RadioCardsRoot.rt-variant-surface) .rt-RadioCardsItem:after {
  box-shadow: var(--base-card-surface-box-shadow);
}

@media (hover: hover) {
  :where(.rt-RadioCardsRoot.rt-variant-surface) .rt-RadioCardsItem:where(:not(:disabled):not([data-state="checked"]):hover):after {
    box-shadow: var(--base-card-surface-hover-box-shadow);
  }
}

:where(.rt-RadioCardsRoot.rt-variant-classic) .rt-RadioCardsItem {
  --radio-cards-item-border-width: 1px;
  --radio-cards-item-background-color: var(--color-surface);
  box-shadow: var(--base-card-classic-box-shadow-outer);
  transition: box-shadow .12s;
}

:where(.rt-RadioCardsRoot.rt-variant-classic) .rt-RadioCardsItem:before {
  background-color: var(--radio-cards-item-background-color);
}

:where(.rt-RadioCardsRoot.rt-variant-classic) .rt-RadioCardsItem:after {
  box-shadow: var(--base-card-classic-box-shadow-inner);
}

@media (hover: hover) {
  :where(.rt-RadioCardsRoot.rt-variant-classic) .rt-RadioCardsItem:where(:not(:disabled):not([data-state="checked"]):hover) {
    box-shadow: var(--base-card-classic-hover-box-shadow-outer);
    transition-duration: 40ms;
  }

  :where(.rt-RadioCardsRoot.rt-variant-classic) .rt-RadioCardsItem:where(:not(:disabled):not([data-state="checked"]):hover):after {
    box-shadow: var(--base-card-classic-hover-box-shadow-inner);
  }
}

.rt-RadioCardsItem:where([data-state="checked"]):after {
  outline: 2px solid var(--accent-indicator);
}

:where(.rt-RadioCardsRoot.rt-high-contrast) .rt-RadioCardsItem:where([data-state="checked"]):after {
  outline-color: var(--accent-12);
}

.rt-RadioCardsItem:where(:focus-visible):after {
  outline: 2px solid var(--focus-8);
}

.rt-RadioCardsItem:where(:focus-visible):where([data-state="checked"]):before {
  background-image: linear-gradient(var(--focus-a3), var(--focus-a3));
}

.rt-RadioCardsItem:where(:focus-visible):where([data-state="checked"]):after {
  outline-color: var(--focus-10);
}

.rt-RadioCardsItem:where(:disabled) {
  cursor: var(--cursor-disabled);
  color: var(--gray-a9);
}

.rt-RadioCardsItem:where(:disabled)::selection {
  background-color: var(--gray-a5);
}

.rt-RadioCardsItem:where(:disabled):before {
  background-image: linear-gradient(var(--gray-a2), var(--gray-a2));
}

.rt-RadioCardsItem:where(:disabled):after {
  outline-color: var(--gray-8);
}

.rt-RadioGroupRoot {
  gap: var(--space-1);
  flex-direction: column;
  display: flex;
}

.rt-RadioGroupItem {
  gap: .5em;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
}

.rt-RadioGroupItemInner {
  min-width: 0;
}

.rt-BaseRadioRoot {
  vertical-align: top;
  cursor: var(--cursor-radio);
  height: var(--skeleton-height, var(--line-height, var(--radio-size)));
  --skeleton-height-override: var(--radio-size);
  border-radius: var(--skeleton-radius);
  --skeleton-radius-override: 100%;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  position: relative;
}

.rt-BaseRadioRoot:where(:disabled, [data-disabled]) {
  cursor: var(--cursor-disabled);
}

.rt-BaseRadioRoot:before {
  content: "";
  height: var(--radio-size);
  width: var(--radio-size);
  border-radius: 100%;
  display: block;
}

.rt-BaseRadioRoot:after {
  pointer-events: none;
  height: var(--radio-size);
  width: var(--radio-size);
  border-radius: 100%;
  position: absolute;
  transform: scale(.4);
}

.rt-BaseRadioRoot:where(:checked, [data-state="checked"]):after {
  content: "";
}

.rt-BaseRadioRoot:where(:focus-visible):before {
  outline: 2px solid var(--focus-8);
  outline-offset: 2px;
}

.rt-BaseRadioRoot:where(.rt-r-size-1) {
  --radio-size: calc(var(--space-4) * .875);
}

.rt-BaseRadioRoot:where(.rt-r-size-2) {
  --radio-size: var(--space-4);
}

.rt-BaseRadioRoot:where(.rt-r-size-3) {
  --radio-size: calc(var(--space-4) * 1.25);
}

@media (min-width: 520px) {
  .rt-BaseRadioRoot:where(.xs\:rt-r-size-1) {
    --radio-size: calc(var(--space-4) * .875);
  }

  .rt-BaseRadioRoot:where(.xs\:rt-r-size-2) {
    --radio-size: var(--space-4);
  }

  .rt-BaseRadioRoot:where(.xs\:rt-r-size-3) {
    --radio-size: calc(var(--space-4) * 1.25);
  }
}

@media (min-width: 768px) {
  .rt-BaseRadioRoot:where(.sm\:rt-r-size-1) {
    --radio-size: calc(var(--space-4) * .875);
  }

  .rt-BaseRadioRoot:where(.sm\:rt-r-size-2) {
    --radio-size: var(--space-4);
  }

  .rt-BaseRadioRoot:where(.sm\:rt-r-size-3) {
    --radio-size: calc(var(--space-4) * 1.25);
  }
}

@media (min-width: 1024px) {
  .rt-BaseRadioRoot:where(.md\:rt-r-size-1) {
    --radio-size: calc(var(--space-4) * .875);
  }

  .rt-BaseRadioRoot:where(.md\:rt-r-size-2) {
    --radio-size: var(--space-4);
  }

  .rt-BaseRadioRoot:where(.md\:rt-r-size-3) {
    --radio-size: calc(var(--space-4) * 1.25);
  }
}

@media (min-width: 1280px) {
  .rt-BaseRadioRoot:where(.lg\:rt-r-size-1) {
    --radio-size: calc(var(--space-4) * .875);
  }

  .rt-BaseRadioRoot:where(.lg\:rt-r-size-2) {
    --radio-size: var(--space-4);
  }

  .rt-BaseRadioRoot:where(.lg\:rt-r-size-3) {
    --radio-size: calc(var(--space-4) * 1.25);
  }
}

@media (min-width: 1640px) {
  .rt-BaseRadioRoot:where(.xl\:rt-r-size-1) {
    --radio-size: calc(var(--space-4) * .875);
  }

  .rt-BaseRadioRoot:where(.xl\:rt-r-size-2) {
    --radio-size: var(--space-4);
  }

  .rt-BaseRadioRoot:where(.xl\:rt-r-size-3) {
    --radio-size: calc(var(--space-4) * 1.25);
  }
}

.rt-BaseRadioRoot:where(.rt-variant-surface):where(:not(:checked), [data-state="unchecked"]):before {
  background-color: var(--color-surface);
  box-shadow: inset 0 0 0 1px var(--gray-a7);
}

.rt-BaseRadioRoot:where(.rt-variant-surface):where(:checked, [data-state="checked"]):before {
  background-color: var(--accent-indicator);
}

.rt-BaseRadioRoot:where(.rt-variant-surface):after {
  background-color: var(--accent-contrast);
}

.rt-BaseRadioRoot:where(.rt-variant-surface):where(.rt-high-contrast):where(:checked, [data-state="checked"]):before {
  background-color: var(--accent-12);
}

.rt-BaseRadioRoot:where(.rt-variant-surface):where(.rt-high-contrast):after {
  background-color: var(--accent-1);
}

.rt-BaseRadioRoot:where(.rt-variant-surface):where(:disabled, [data-disabled]):before {
  box-shadow: inset 0 0 0 1px var(--gray-a6);
  background-color: var(--gray-a3);
}

.rt-BaseRadioRoot:where(.rt-variant-surface):where(:disabled, [data-disabled]):after {
  background-color: var(--gray-a8);
}

.rt-BaseRadioRoot:where(.rt-variant-classic):where(:not(:checked), [data-state="unchecked"]):before {
  background-color: var(--color-surface);
  box-shadow: inset 0 0 0 1px var(--gray-7), var(--shadow-1);
}

.rt-BaseRadioRoot:where(.rt-variant-classic):where(:checked, [data-state="checked"]):before {
  background-color: var(--accent-indicator);
  background-image: linear-gradient(to bottom, var(--white-a3), transparent, var(--black-a3));
  box-shadow: inset 0 .5px .5px var(--white-a4), inset 0 -.5px .5px var(--black-a4);
}

.rt-BaseRadioRoot:where(.rt-variant-classic):after {
  background-color: var(--accent-contrast);
}

.rt-BaseRadioRoot:where(.rt-variant-classic):where(.rt-high-contrast):where(:checked, [data-state="checked"]):before {
  background-color: var(--accent-12);
}

.rt-BaseRadioRoot:where(.rt-variant-classic):where(.rt-high-contrast):after {
  background-color: var(--accent-1);
}

.rt-BaseRadioRoot:where(.rt-variant-classic):where(:disabled, [data-disabled]):before {
  box-shadow: var(--shadow-1);
  background-color: var(--gray-a3);
  background-image: none;
}

.rt-BaseRadioRoot:where(.rt-variant-classic):where(:disabled, [data-disabled]):after {
  background-color: var(--gray-a8);
}

.rt-BaseRadioRoot:where(.rt-variant-soft):before {
  background-color: var(--accent-a4);
}

.rt-BaseRadioRoot:where(.rt-variant-soft):after {
  background-color: var(--accent-a11);
}

.rt-BaseRadioRoot:where(.rt-variant-soft):where(.rt-high-contrast):after {
  background-color: var(--accent-12);
}

.rt-BaseRadioRoot:where(.rt-variant-soft):where(:focus-visible):before {
  outline-color: var(--accent-a8);
}

.rt-BaseRadioRoot:where(.rt-variant-soft):where(:disabled, [data-disabled]):before {
  background-color: var(--gray-a3);
}

.rt-BaseRadioRoot:where(.rt-variant-soft):where(:disabled, [data-disabled]):after {
  background-color: var(--gray-a8);
}

.rt-ScrollAreaRoot {
  flex-direction: column;
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
}

.rt-ScrollAreaViewport {
  flex-direction: column;
  width: 100%;
  height: 100%;
  display: flex;
}

.rt-ScrollAreaViewport:where(:focus-visible) + :where(.rt-ScrollAreaViewportFocusRing) {
  pointer-events: none;
  outline: 2px solid var(--focus-8);
  outline-offset: -2px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.rt-ScrollAreaViewport:where(:has(.rt-ScrollAreaScrollbar[data-orientation="horizontal"])) {
  overscroll-behavior-x: contain;
}

.rt-ScrollAreaViewport > * {
  flex-grow: 1;
  width: -moz-fit-content;
  width: fit-content;
  display: block !important;
}

.rt-ScrollAreaScrollbar {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  touch-action: none;
  background-color: var(--gray-a3);
  border-radius: var(--scrollarea-scrollbar-border-radius);
  animation-duration: .12s;
  animation-timing-function: ease-out;
  display: flex;
}

.rt-ScrollAreaScrollbar:where([data-orientation="vertical"]) {
  width: var(--scrollarea-scrollbar-size);
  margin-top: var(--scrollarea-scrollbar-vertical-margin-top);
  margin-bottom: var(--scrollarea-scrollbar-vertical-margin-bottom);
  margin-left: var(--scrollarea-scrollbar-vertical-margin-left);
  margin-right: var(--scrollarea-scrollbar-vertical-margin-right);
  flex-direction: column;
}

.rt-ScrollAreaScrollbar:where([data-orientation="horizontal"]) {
  height: var(--scrollarea-scrollbar-size);
  margin-top: var(--scrollarea-scrollbar-horizontal-margin-top);
  margin-bottom: var(--scrollarea-scrollbar-horizontal-margin-bottom);
  margin-left: var(--scrollarea-scrollbar-horizontal-margin-left);
  margin-right: var(--scrollarea-scrollbar-horizontal-margin-right);
  flex-direction: row;
}

.rt-ScrollAreaThumb {
  background-color: var(--gray-a8);
  border-radius: inherit;
  transition: background-color .1s;
  position: relative;
}

.rt-ScrollAreaThumb:before {
  content: "";
  width: 100%;
  height: 100%;
  min-width: var(--space-4);
  min-height: var(--space-4);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.rt-ScrollAreaScrollbar:where(.rt-r-size-1) {
  --scrollarea-scrollbar-size: var(--space-1);
  --scrollarea-scrollbar-border-radius: max(var(--radius-1), var(--radius-full));
}

.rt-ScrollAreaScrollbar:where(.rt-r-size-2) {
  --scrollarea-scrollbar-size: var(--space-2);
  --scrollarea-scrollbar-border-radius: max(var(--radius-1), var(--radius-full));
}

.rt-ScrollAreaScrollbar:where(.rt-r-size-3) {
  --scrollarea-scrollbar-size: var(--space-3);
  --scrollarea-scrollbar-border-radius: max(var(--radius-1), var(--radius-full));
}

@media (min-width: 520px) {
  .rt-ScrollAreaScrollbar:where(.xs\:rt-r-size-1) {
    --scrollarea-scrollbar-size: var(--space-1);
    --scrollarea-scrollbar-border-radius: max(var(--radius-1), var(--radius-full));
  }

  .rt-ScrollAreaScrollbar:where(.xs\:rt-r-size-2) {
    --scrollarea-scrollbar-size: var(--space-2);
    --scrollarea-scrollbar-border-radius: max(var(--radius-1), var(--radius-full));
  }

  .rt-ScrollAreaScrollbar:where(.xs\:rt-r-size-3) {
    --scrollarea-scrollbar-size: var(--space-3);
    --scrollarea-scrollbar-border-radius: max(var(--radius-1), var(--radius-full));
  }
}

@media (min-width: 768px) {
  .rt-ScrollAreaScrollbar:where(.sm\:rt-r-size-1) {
    --scrollarea-scrollbar-size: var(--space-1);
    --scrollarea-scrollbar-border-radius: max(var(--radius-1), var(--radius-full));
  }

  .rt-ScrollAreaScrollbar:where(.sm\:rt-r-size-2) {
    --scrollarea-scrollbar-size: var(--space-2);
    --scrollarea-scrollbar-border-radius: max(var(--radius-1), var(--radius-full));
  }

  .rt-ScrollAreaScrollbar:where(.sm\:rt-r-size-3) {
    --scrollarea-scrollbar-size: var(--space-3);
    --scrollarea-scrollbar-border-radius: max(var(--radius-1), var(--radius-full));
  }
}

@media (min-width: 1024px) {
  .rt-ScrollAreaScrollbar:where(.md\:rt-r-size-1) {
    --scrollarea-scrollbar-size: var(--space-1);
    --scrollarea-scrollbar-border-radius: max(var(--radius-1), var(--radius-full));
  }

  .rt-ScrollAreaScrollbar:where(.md\:rt-r-size-2) {
    --scrollarea-scrollbar-size: var(--space-2);
    --scrollarea-scrollbar-border-radius: max(var(--radius-1), var(--radius-full));
  }

  .rt-ScrollAreaScrollbar:where(.md\:rt-r-size-3) {
    --scrollarea-scrollbar-size: var(--space-3);
    --scrollarea-scrollbar-border-radius: max(var(--radius-1), var(--radius-full));
  }
}

@media (min-width: 1280px) {
  .rt-ScrollAreaScrollbar:where(.lg\:rt-r-size-1) {
    --scrollarea-scrollbar-size: var(--space-1);
    --scrollarea-scrollbar-border-radius: max(var(--radius-1), var(--radius-full));
  }

  .rt-ScrollAreaScrollbar:where(.lg\:rt-r-size-2) {
    --scrollarea-scrollbar-size: var(--space-2);
    --scrollarea-scrollbar-border-radius: max(var(--radius-1), var(--radius-full));
  }

  .rt-ScrollAreaScrollbar:where(.lg\:rt-r-size-3) {
    --scrollarea-scrollbar-size: var(--space-3);
    --scrollarea-scrollbar-border-radius: max(var(--radius-1), var(--radius-full));
  }
}

@media (min-width: 1640px) {
  .rt-ScrollAreaScrollbar:where(.xl\:rt-r-size-1) {
    --scrollarea-scrollbar-size: var(--space-1);
    --scrollarea-scrollbar-border-radius: max(var(--radius-1), var(--radius-full));
  }

  .rt-ScrollAreaScrollbar:where(.xl\:rt-r-size-2) {
    --scrollarea-scrollbar-size: var(--space-2);
    --scrollarea-scrollbar-border-radius: max(var(--radius-1), var(--radius-full));
  }

  .rt-ScrollAreaScrollbar:where(.xl\:rt-r-size-3) {
    --scrollarea-scrollbar-size: var(--space-3);
    --scrollarea-scrollbar-border-radius: max(var(--radius-1), var(--radius-full));
  }
}

.rt-ScrollAreaScrollbar:where([data-state="visible"]) {
  animation-name: rt-fade-in;
}

.rt-ScrollAreaScrollbar:where([data-state="hidden"]) {
  animation-name: rt-fade-out;
}

@media (hover: hover) {
  .rt-ScrollAreaThumb:where(:hover) {
    background-color: var(--gray-a9);
  }
}

.rt-SegmentedControlRoot {
  vertical-align: top;
  color: var(--gray-12);
  background-color: var(--color-surface);
  background-image: linear-gradient(var(--gray-a3), var(--gray-a3));
  min-width: max-content;
  font-family: var(--default-font-family);
  text-align: center;
  isolation: isolate;
  border-radius: var(--segmented-control-border-radius);
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  align-items: stretch;
  font-style: normal;
  display: inline-grid;
  position: relative;
}

.rt-SegmentedControlRoot:where([data-disabled]) {
  color: var(--gray-a8);
  background-color: var(--gray-3);
}

.rt-SegmentedControlItem {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  align-items: stretch;
  display: flex;
}

.rt-SegmentedControlItem:where(:first-child) {
  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit;
}

.rt-SegmentedControlItem:where(:nth-last-child(2)) {
  border-top-right-radius: inherit;
  border-bottom-right-radius: inherit;
}

.rt-SegmentedControlItem:where(:focus-visible) {
  border-radius: inherit;
  outline: 2px solid var(--focus-8);
  outline-offset: -1px;
}

.rt-SegmentedControlItemLabel :where(svg) {
  flex-shrink: 0;
}

@media (hover: hover) {
  :where(.rt-SegmentedControlItem[data-state="off"]:not([disabled]):hover) .rt-SegmentedControlItemLabel {
    background-color: var(--gray-a2);
  }
}

.rt-SegmentedControlItemLabelInactive {
  transition: opacity calc(.8 * var(--segmented-control-transition-duration));
  font-weight: var(--font-weight-regular);
  letter-spacing: var(--tab-inactive-letter-spacing);
  word-spacing: var(--tab-inactive-word-spacing);
  opacity: 1;
  transition-timing-function: ease-out;
  position: absolute;
}

:where(.rt-SegmentedControlItem[data-state="on"]) .rt-SegmentedControlItemLabelInactive {
  opacity: 0;
  transition-timing-function: ease-in;
}

.rt-SegmentedControlItemLabelActive {
  transition: opacity calc(.8 * var(--segmented-control-transition-duration));
  font-weight: var(--font-weight-medium);
  letter-spacing: var(--tab-active-letter-spacing);
  word-spacing: var(--tab-active-word-spacing);
  opacity: 0;
  transition-timing-function: ease-in;
}

:where(.rt-SegmentedControlItem[data-state="on"]) .rt-SegmentedControlItemLabelActive {
  opacity: 1;
  transition-timing-function: ease-out;
}

.rt-SegmentedControlItemSeparator {
  z-index: -1;
  background-color: var(--gray-a4);
  width: 1px;
  transition: opacity calc(.8 * var(--segmented-control-transition-duration));
  margin: 3px -.5px;
  transition-timing-function: ease-out;
}

:where(.rt-SegmentedControlItem:first-child) .rt-SegmentedControlItemSeparator {
  opacity: 0;
  transition-timing-function: ease-in;
}

:where(.rt-SegmentedControlItem:where([data-state="on"], :focus-visible)) .rt-SegmentedControlItemSeparator {
  opacity: 0;
  transition-timing-function: ease-in;
}

:where(.rt-SegmentedControlItem:where([data-state="on"], :focus-visible)) + * .rt-SegmentedControlItemSeparator {
  opacity: 0;
  transition-timing-function: ease-in;
}

:where(.rt-SegmentedControlRoot:has(:focus-visible)) .rt-SegmentedControlItemSeparator {
  transition-duration: 0s;
}

.rt-SegmentedControlIndicator {
  z-index: -1;
  pointer-events: none;
  height: 100%;
  transition-property: transform;
  transition-timing-function: cubic-bezier(.445, .05, .55, .95);
  transition-duration: var(--segmented-control-transition-duration);
  display: none;
  position: absolute;
  top: 0;
  left: 0;
}

.rt-SegmentedControlIndicator:before {
  border-radius: max(.5px, calc(var(--segmented-control-border-radius)  - 1px));
  background-color: var(--segmented-control-indicator-background-color);
  content: "";
  position: absolute;
  top: 1px;
  bottom: 1px;
  left: 1px;
  right: 1px;
}

:where(.rt-SegmentedControlItem[data-state="on"]) ~ .rt-SegmentedControlIndicator {
  display: block;
}

:where(.rt-SegmentedControlItem[disabled]) ~ .rt-SegmentedControlIndicator {
  --segmented-control-indicator-background-color: var(--gray-a3);
}

:where(.rt-SegmentedControlItem[disabled]) ~ .rt-SegmentedControlIndicator:before {
  box-shadow: none;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.rt-SegmentedControlIndicator:where(:nth-child(2)) {
  width: 100%;
}

.rt-SegmentedControlIndicator:where(:nth-child(3)) {
  width: 50%;
}

.rt-SegmentedControlIndicator:where(:nth-child(4)) {
  width: 33.3333%;
}

.rt-SegmentedControlIndicator:where(:nth-child(5)) {
  width: 25%;
}

.rt-SegmentedControlIndicator:where(:nth-child(6)) {
  width: 20%;
}

.rt-SegmentedControlIndicator:where(:nth-child(7)) {
  width: 16.6667%;
}

.rt-SegmentedControlIndicator:where(:nth-child(8)) {
  width: 14.2857%;
}

.rt-SegmentedControlIndicator:where(:nth-child(9)) {
  width: 12.5%;
}

.rt-SegmentedControlIndicator:where(:nth-child(10)) {
  width: 11.1111%;
}

.rt-SegmentedControlIndicator:where(:nth-child(11)) {
  width: 10%;
}

:where(.rt-SegmentedControlItem[data-state="on"]:first-child) ~ .rt-SegmentedControlIndicator {
  transform: translateX(0%);
}

:where(.rt-SegmentedControlItem[data-state="on"]:nth-child(2)) ~ .rt-SegmentedControlIndicator {
  transform: translateX(100%);
}

:where(.rt-SegmentedControlItem[data-state="on"]:nth-child(3)) ~ .rt-SegmentedControlIndicator {
  transform: translateX(200%);
}

:where(.rt-SegmentedControlItem[data-state="on"]:nth-child(4)) ~ .rt-SegmentedControlIndicator {
  transform: translateX(300%);
}

:where(.rt-SegmentedControlItem[data-state="on"]:nth-child(5)) ~ .rt-SegmentedControlIndicator {
  transform: translateX(400%);
}

:where(.rt-SegmentedControlItem[data-state="on"]:nth-child(6)) ~ .rt-SegmentedControlIndicator {
  transform: translateX(500%);
}

:where(.rt-SegmentedControlItem[data-state="on"]:nth-child(7)) ~ .rt-SegmentedControlIndicator {
  transform: translateX(600%);
}

:where(.rt-SegmentedControlItem[data-state="on"]:nth-child(8)) ~ .rt-SegmentedControlIndicator {
  transform: translateX(700%);
}

:where(.rt-SegmentedControlItem[data-state="on"]:nth-child(9)) ~ .rt-SegmentedControlIndicator {
  transform: translateX(800%);
}

:where(.rt-SegmentedControlItem[data-state="on"]:nth-child(10)) ~ .rt-SegmentedControlIndicator {
  transform: translateX(900%);
}

.rt-SegmentedControlItemLabel {
  box-sizing: border-box;
  border-radius: inherit;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  display: flex;
}

.rt-SegmentedControlRoot:where(.rt-r-size-1) {
  --segmented-control-border-radius: max(var(--radius-2), var(--radius-full));
  height: var(--space-5);
}

.rt-SegmentedControlRoot:where(.rt-r-size-1) :where(.rt-SegmentedControlItemLabel) {
  font-size: var(--font-size-1);
  letter-spacing: var(--letter-spacing-1);
  padding-left: var(--space-3);
  padding-right: var(--space-3);
  gap: var(--space-1);
}

.rt-SegmentedControlRoot:where(.rt-r-size-2) {
  --segmented-control-border-radius: max(var(--radius-2), var(--radius-full));
  height: var(--space-6);
}

.rt-SegmentedControlRoot:where(.rt-r-size-2) :where(.rt-SegmentedControlItemLabel) {
  font-size: var(--font-size-2);
  letter-spacing: var(--letter-spacing-2);
  padding-left: var(--space-4);
  padding-right: var(--space-4);
  gap: var(--space-2);
}

.rt-SegmentedControlRoot:where(.rt-r-size-3) {
  --segmented-control-border-radius: max(var(--radius-3), var(--radius-full));
  height: var(--space-7);
}

.rt-SegmentedControlRoot:where(.rt-r-size-3) :where(.rt-SegmentedControlItemLabel) {
  font-size: var(--font-size-3);
  letter-spacing: var(--letter-spacing-3);
  padding-left: var(--space-4);
  padding-right: var(--space-4);
  gap: var(--space-3);
}

@media (min-width: 520px) {
  .rt-SegmentedControlRoot:where(.xs\:rt-r-size-1) {
    --segmented-control-border-radius: max(var(--radius-2), var(--radius-full));
    height: var(--space-5);
  }

  .rt-SegmentedControlRoot:where(.xs\:rt-r-size-1) :where(.rt-SegmentedControlItemLabel) {
    font-size: var(--font-size-1);
    letter-spacing: var(--letter-spacing-1);
    padding-left: var(--space-3);
    padding-right: var(--space-3);
    gap: var(--space-1);
  }

  .rt-SegmentedControlRoot:where(.xs\:rt-r-size-2) {
    --segmented-control-border-radius: max(var(--radius-2), var(--radius-full));
    height: var(--space-6);
  }

  .rt-SegmentedControlRoot:where(.xs\:rt-r-size-2) :where(.rt-SegmentedControlItemLabel) {
    font-size: var(--font-size-2);
    letter-spacing: var(--letter-spacing-2);
    padding-left: var(--space-4);
    padding-right: var(--space-4);
    gap: var(--space-2);
  }

  .rt-SegmentedControlRoot:where(.xs\:rt-r-size-3) {
    --segmented-control-border-radius: max(var(--radius-3), var(--radius-full));
    height: var(--space-7);
  }

  .rt-SegmentedControlRoot:where(.xs\:rt-r-size-3) :where(.rt-SegmentedControlItemLabel) {
    font-size: var(--font-size-3);
    letter-spacing: var(--letter-spacing-3);
    padding-left: var(--space-4);
    padding-right: var(--space-4);
    gap: var(--space-3);
  }
}

@media (min-width: 768px) {
  .rt-SegmentedControlRoot:where(.sm\:rt-r-size-1) {
    --segmented-control-border-radius: max(var(--radius-2), var(--radius-full));
    height: var(--space-5);
  }

  .rt-SegmentedControlRoot:where(.sm\:rt-r-size-1) :where(.rt-SegmentedControlItemLabel) {
    font-size: var(--font-size-1);
    letter-spacing: var(--letter-spacing-1);
    padding-left: var(--space-3);
    padding-right: var(--space-3);
    gap: var(--space-1);
  }

  .rt-SegmentedControlRoot:where(.sm\:rt-r-size-2) {
    --segmented-control-border-radius: max(var(--radius-2), var(--radius-full));
    height: var(--space-6);
  }

  .rt-SegmentedControlRoot:where(.sm\:rt-r-size-2) :where(.rt-SegmentedControlItemLabel) {
    font-size: var(--font-size-2);
    letter-spacing: var(--letter-spacing-2);
    padding-left: var(--space-4);
    padding-right: var(--space-4);
    gap: var(--space-2);
  }

  .rt-SegmentedControlRoot:where(.sm\:rt-r-size-3) {
    --segmented-control-border-radius: max(var(--radius-3), var(--radius-full));
    height: var(--space-7);
  }

  .rt-SegmentedControlRoot:where(.sm\:rt-r-size-3) :where(.rt-SegmentedControlItemLabel) {
    font-size: var(--font-size-3);
    letter-spacing: var(--letter-spacing-3);
    padding-left: var(--space-4);
    padding-right: var(--space-4);
    gap: var(--space-3);
  }
}

@media (min-width: 1024px) {
  .rt-SegmentedControlRoot:where(.md\:rt-r-size-1) {
    --segmented-control-border-radius: max(var(--radius-2), var(--radius-full));
    height: var(--space-5);
  }

  .rt-SegmentedControlRoot:where(.md\:rt-r-size-1) :where(.rt-SegmentedControlItemLabel) {
    font-size: var(--font-size-1);
    letter-spacing: var(--letter-spacing-1);
    padding-left: var(--space-3);
    padding-right: var(--space-3);
    gap: var(--space-1);
  }

  .rt-SegmentedControlRoot:where(.md\:rt-r-size-2) {
    --segmented-control-border-radius: max(var(--radius-2), var(--radius-full));
    height: var(--space-6);
  }

  .rt-SegmentedControlRoot:where(.md\:rt-r-size-2) :where(.rt-SegmentedControlItemLabel) {
    font-size: var(--font-size-2);
    letter-spacing: var(--letter-spacing-2);
    padding-left: var(--space-4);
    padding-right: var(--space-4);
    gap: var(--space-2);
  }

  .rt-SegmentedControlRoot:where(.md\:rt-r-size-3) {
    --segmented-control-border-radius: max(var(--radius-3), var(--radius-full));
    height: var(--space-7);
  }

  .rt-SegmentedControlRoot:where(.md\:rt-r-size-3) :where(.rt-SegmentedControlItemLabel) {
    font-size: var(--font-size-3);
    letter-spacing: var(--letter-spacing-3);
    padding-left: var(--space-4);
    padding-right: var(--space-4);
    gap: var(--space-3);
  }
}

@media (min-width: 1280px) {
  .rt-SegmentedControlRoot:where(.lg\:rt-r-size-1) {
    --segmented-control-border-radius: max(var(--radius-2), var(--radius-full));
    height: var(--space-5);
  }

  .rt-SegmentedControlRoot:where(.lg\:rt-r-size-1) :where(.rt-SegmentedControlItemLabel) {
    font-size: var(--font-size-1);
    letter-spacing: var(--letter-spacing-1);
    padding-left: var(--space-3);
    padding-right: var(--space-3);
    gap: var(--space-1);
  }

  .rt-SegmentedControlRoot:where(.lg\:rt-r-size-2) {
    --segmented-control-border-radius: max(var(--radius-2), var(--radius-full));
    height: var(--space-6);
  }

  .rt-SegmentedControlRoot:where(.lg\:rt-r-size-2) :where(.rt-SegmentedControlItemLabel) {
    font-size: var(--font-size-2);
    letter-spacing: var(--letter-spacing-2);
    padding-left: var(--space-4);
    padding-right: var(--space-4);
    gap: var(--space-2);
  }

  .rt-SegmentedControlRoot:where(.lg\:rt-r-size-3) {
    --segmented-control-border-radius: max(var(--radius-3), var(--radius-full));
    height: var(--space-7);
  }

  .rt-SegmentedControlRoot:where(.lg\:rt-r-size-3) :where(.rt-SegmentedControlItemLabel) {
    font-size: var(--font-size-3);
    letter-spacing: var(--letter-spacing-3);
    padding-left: var(--space-4);
    padding-right: var(--space-4);
    gap: var(--space-3);
  }
}

@media (min-width: 1640px) {
  .rt-SegmentedControlRoot:where(.xl\:rt-r-size-1) {
    --segmented-control-border-radius: max(var(--radius-2), var(--radius-full));
    height: var(--space-5);
  }

  .rt-SegmentedControlRoot:where(.xl\:rt-r-size-1) :where(.rt-SegmentedControlItemLabel) {
    font-size: var(--font-size-1);
    letter-spacing: var(--letter-spacing-1);
    padding-left: var(--space-3);
    padding-right: var(--space-3);
    gap: var(--space-1);
  }

  .rt-SegmentedControlRoot:where(.xl\:rt-r-size-2) {
    --segmented-control-border-radius: max(var(--radius-2), var(--radius-full));
    height: var(--space-6);
  }

  .rt-SegmentedControlRoot:where(.xl\:rt-r-size-2) :where(.rt-SegmentedControlItemLabel) {
    font-size: var(--font-size-2);
    letter-spacing: var(--letter-spacing-2);
    padding-left: var(--space-4);
    padding-right: var(--space-4);
    gap: var(--space-2);
  }

  .rt-SegmentedControlRoot:where(.xl\:rt-r-size-3) {
    --segmented-control-border-radius: max(var(--radius-3), var(--radius-full));
    height: var(--space-7);
  }

  .rt-SegmentedControlRoot:where(.xl\:rt-r-size-3) :where(.rt-SegmentedControlItemLabel) {
    font-size: var(--font-size-3);
    letter-spacing: var(--letter-spacing-3);
    padding-left: var(--space-4);
    padding-right: var(--space-4);
    gap: var(--space-3);
  }
}

.rt-SegmentedControlRoot:where(.rt-variant-surface) :where(.rt-SegmentedControlItem:not([disabled])) ~ :where(.rt-SegmentedControlIndicator):before {
  box-shadow: 0 0 0 1px var(--gray-a4);
}

.rt-SegmentedControlRoot:where(.rt-variant-classic) :where(.rt-SegmentedControlItem:not([disabled])) ~ :where(.rt-SegmentedControlIndicator):before {
  box-shadow: var(--shadow-2);
}

.rt-SelectTrigger {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  vertical-align: top;
  line-height: var(--height);
  font-family: var(--default-font-family);
  font-weight: var(--font-weight-regular);
  text-align: start;
  color: var(--gray-12);
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
  font-style: normal;
  display: inline-flex;
}

.rt-SelectTrigger:where(:focus-visible) {
  outline: 2px solid var(--focus-8);
  outline-offset: -1px;
}

.rt-SelectTriggerInner {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.rt-SelectIcon {
  flex-shrink: 0;
}

:where(.rt-SelectTrigger:not(.rt-variant-ghost)) .rt-SelectIcon {
  opacity: .9;
}

.rt-SelectContent:where([data-side]) {
  min-width: var(--radix-select-trigger-width);
  max-height: var(--radix-select-content-available-height);
  transform-origin: var(--radix-select-content-transform-origin);
}

.rt-SelectViewport {
  box-sizing: border-box;
  padding: var(--select-content-padding);
}

:where(.rt-SelectContent:has(.rt-ScrollAreaScrollbar[data-orientation="vertical"])) .rt-SelectViewport {
  padding-right: var(--space-3);
}

.rt-SelectItem {
  height: var(--select-item-height);
  padding-left: var(--select-item-indicator-width);
  padding-right: var(--select-item-indicator-width);
  box-sizing: border-box;
  scroll-margin: var(--select-content-padding) 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  cursor: var(--cursor-menu-item);
  outline: none;
  align-items: center;
  display: flex;
  position: relative;
}

.rt-SelectItemIndicator {
  width: var(--select-item-indicator-width);
  justify-content: center;
  align-items: center;
  display: inline-flex;
  position: absolute;
  left: 0;
}

.rt-SelectSeparator {
  height: 1px;
  margin-top: var(--space-2);
  margin-bottom: var(--space-2);
  margin-left: var(--select-item-indicator-width);
  margin-right: var(--select-separator-margin-right);
  background-color: var(--gray-a6);
}

.rt-SelectLabel {
  height: var(--select-item-height);
  padding-left: var(--select-item-indicator-width);
  padding-right: var(--select-item-indicator-width);
  color: var(--gray-a10);
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  cursor: default;
  align-items: center;
  display: flex;
}

:where(.rt-SelectItem) + .rt-SelectLabel {
  margin-top: var(--space-2);
}

.rt-SelectTrigger:where(:not(.rt-variant-ghost)) {
  box-sizing: border-box;
  height: var(--select-trigger-height);
}

.rt-SelectTrigger:where(.rt-variant-ghost) {
  box-sizing: content-box;
  height: -moz-fit-content;
  height: fit-content;
  padding: var(--select-trigger-ghost-padding-y) var(--select-trigger-ghost-padding-x);
  --margin-top: 0px;
  --margin-right: 0px;
  --margin-bottom: 0px;
  --margin-left: 0px;
  --margin-top-override: calc(var(--margin-top)  - var(--select-trigger-ghost-padding-y));
  --margin-right-override: calc(var(--margin-right)  - var(--select-trigger-ghost-padding-x));
  --margin-bottom-override: calc(var(--margin-bottom)  - var(--select-trigger-ghost-padding-y));
  --margin-left-override: calc(var(--margin-left)  - var(--select-trigger-ghost-padding-x));
  margin: var(--margin-top-override) var(--margin-right-override) var(--margin-bottom-override) var(--margin-left-override);
}

:where(.rt-SelectTrigger:where(.rt-variant-ghost)) > * {
  --margin-top-override: initial;
  --margin-right-override: initial;
  --margin-bottom-override: initial;
  --margin-left-override: initial;
}

.rt-SelectTrigger:where(.rt-r-size-1) {
  --select-trigger-height: var(--space-5);
  gap: var(--space-1);
  font-size: var(--font-size-1);
  line-height: var(--line-height-1);
  letter-spacing: var(--letter-spacing-1);
  border-radius: max(var(--radius-1), var(--radius-full));
}

.rt-SelectTrigger:where(.rt-r-size-1):where(:not(.rt-variant-ghost)) {
  padding-left: var(--space-2);
  padding-right: var(--space-2);
}

.rt-SelectTrigger:where(.rt-r-size-1):where(.rt-variant-ghost) {
  --select-trigger-ghost-padding-x: var(--space-2);
  --select-trigger-ghost-padding-y: var(--space-1);
}

.rt-SelectTrigger:where(.rt-r-size-2) {
  --select-trigger-height: var(--space-6);
  gap: calc(var(--space-1) * 1.5);
  font-size: var(--font-size-2);
  line-height: var(--line-height-2);
  letter-spacing: var(--letter-spacing-2);
  border-radius: max(var(--radius-2), var(--radius-full));
}

.rt-SelectTrigger:where(.rt-r-size-2):where(:not(.rt-variant-ghost)) {
  padding-left: var(--space-3);
  padding-right: var(--space-3);
}

.rt-SelectTrigger:where(.rt-r-size-2):where(.rt-variant-ghost) {
  --select-trigger-ghost-padding-x: var(--space-2);
  --select-trigger-ghost-padding-y: var(--space-1);
}

.rt-SelectTrigger:where(.rt-r-size-3) {
  --select-trigger-height: var(--space-7);
  gap: var(--space-2);
  font-size: var(--font-size-3);
  line-height: var(--line-height-3);
  letter-spacing: var(--letter-spacing-3);
  border-radius: max(var(--radius-3), var(--radius-full));
}

.rt-SelectTrigger:where(.rt-r-size-3):where(:not(.rt-variant-ghost)) {
  padding-left: var(--space-4);
  padding-right: var(--space-4);
}

.rt-SelectTrigger:where(.rt-r-size-3):where(.rt-variant-ghost) {
  --select-trigger-ghost-padding-x: var(--space-3);
  --select-trigger-ghost-padding-y: calc(var(--space-1) * 1.5);
}

.rt-SelectTrigger:where(.rt-r-size-3) :where(.rt-SelectIcon) {
  width: 11px;
  height: 11px;
}

@media (min-width: 520px) {
  .rt-SelectTrigger:where(.xs\:rt-r-size-1) {
    --select-trigger-height: var(--space-5);
    gap: var(--space-1);
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
    border-radius: max(var(--radius-1), var(--radius-full));
  }

  .rt-SelectTrigger:where(.xs\:rt-r-size-1):where(:not(.rt-variant-ghost)) {
    padding-left: var(--space-2);
    padding-right: var(--space-2);
  }

  .rt-SelectTrigger:where(.xs\:rt-r-size-1):where(.rt-variant-ghost) {
    --select-trigger-ghost-padding-x: var(--space-2);
    --select-trigger-ghost-padding-y: var(--space-1);
  }

  .rt-SelectTrigger:where(.xs\:rt-r-size-2) {
    --select-trigger-height: var(--space-6);
    gap: calc(var(--space-1) * 1.5);
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    letter-spacing: var(--letter-spacing-2);
    border-radius: max(var(--radius-2), var(--radius-full));
  }

  .rt-SelectTrigger:where(.xs\:rt-r-size-2):where(:not(.rt-variant-ghost)) {
    padding-left: var(--space-3);
    padding-right: var(--space-3);
  }

  .rt-SelectTrigger:where(.xs\:rt-r-size-2):where(.rt-variant-ghost) {
    --select-trigger-ghost-padding-x: var(--space-2);
    --select-trigger-ghost-padding-y: var(--space-1);
  }

  .rt-SelectTrigger:where(.xs\:rt-r-size-3) {
    --select-trigger-height: var(--space-7);
    gap: var(--space-2);
    font-size: var(--font-size-3);
    line-height: var(--line-height-3);
    letter-spacing: var(--letter-spacing-3);
    border-radius: max(var(--radius-3), var(--radius-full));
  }

  .rt-SelectTrigger:where(.xs\:rt-r-size-3):where(:not(.rt-variant-ghost)) {
    padding-left: var(--space-4);
    padding-right: var(--space-4);
  }

  .rt-SelectTrigger:where(.xs\:rt-r-size-3):where(.rt-variant-ghost) {
    --select-trigger-ghost-padding-x: var(--space-3);
    --select-trigger-ghost-padding-y: calc(var(--space-1) * 1.5);
  }

  .rt-SelectTrigger:where(.xs\:rt-r-size-3) :where(.rt-SelectIcon) {
    width: 11px;
    height: 11px;
  }
}

@media (min-width: 768px) {
  .rt-SelectTrigger:where(.sm\:rt-r-size-1) {
    --select-trigger-height: var(--space-5);
    gap: var(--space-1);
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
    border-radius: max(var(--radius-1), var(--radius-full));
  }

  .rt-SelectTrigger:where(.sm\:rt-r-size-1):where(:not(.rt-variant-ghost)) {
    padding-left: var(--space-2);
    padding-right: var(--space-2);
  }

  .rt-SelectTrigger:where(.sm\:rt-r-size-1):where(.rt-variant-ghost) {
    --select-trigger-ghost-padding-x: var(--space-2);
    --select-trigger-ghost-padding-y: var(--space-1);
  }

  .rt-SelectTrigger:where(.sm\:rt-r-size-2) {
    --select-trigger-height: var(--space-6);
    gap: calc(var(--space-1) * 1.5);
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    letter-spacing: var(--letter-spacing-2);
    border-radius: max(var(--radius-2), var(--radius-full));
  }

  .rt-SelectTrigger:where(.sm\:rt-r-size-2):where(:not(.rt-variant-ghost)) {
    padding-left: var(--space-3);
    padding-right: var(--space-3);
  }

  .rt-SelectTrigger:where(.sm\:rt-r-size-2):where(.rt-variant-ghost) {
    --select-trigger-ghost-padding-x: var(--space-2);
    --select-trigger-ghost-padding-y: var(--space-1);
  }

  .rt-SelectTrigger:where(.sm\:rt-r-size-3) {
    --select-trigger-height: var(--space-7);
    gap: var(--space-2);
    font-size: var(--font-size-3);
    line-height: var(--line-height-3);
    letter-spacing: var(--letter-spacing-3);
    border-radius: max(var(--radius-3), var(--radius-full));
  }

  .rt-SelectTrigger:where(.sm\:rt-r-size-3):where(:not(.rt-variant-ghost)) {
    padding-left: var(--space-4);
    padding-right: var(--space-4);
  }

  .rt-SelectTrigger:where(.sm\:rt-r-size-3):where(.rt-variant-ghost) {
    --select-trigger-ghost-padding-x: var(--space-3);
    --select-trigger-ghost-padding-y: calc(var(--space-1) * 1.5);
  }

  .rt-SelectTrigger:where(.sm\:rt-r-size-3) :where(.rt-SelectIcon) {
    width: 11px;
    height: 11px;
  }
}

@media (min-width: 1024px) {
  .rt-SelectTrigger:where(.md\:rt-r-size-1) {
    --select-trigger-height: var(--space-5);
    gap: var(--space-1);
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
    border-radius: max(var(--radius-1), var(--radius-full));
  }

  .rt-SelectTrigger:where(.md\:rt-r-size-1):where(:not(.rt-variant-ghost)) {
    padding-left: var(--space-2);
    padding-right: var(--space-2);
  }

  .rt-SelectTrigger:where(.md\:rt-r-size-1):where(.rt-variant-ghost) {
    --select-trigger-ghost-padding-x: var(--space-2);
    --select-trigger-ghost-padding-y: var(--space-1);
  }

  .rt-SelectTrigger:where(.md\:rt-r-size-2) {
    --select-trigger-height: var(--space-6);
    gap: calc(var(--space-1) * 1.5);
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    letter-spacing: var(--letter-spacing-2);
    border-radius: max(var(--radius-2), var(--radius-full));
  }

  .rt-SelectTrigger:where(.md\:rt-r-size-2):where(:not(.rt-variant-ghost)) {
    padding-left: var(--space-3);
    padding-right: var(--space-3);
  }

  .rt-SelectTrigger:where(.md\:rt-r-size-2):where(.rt-variant-ghost) {
    --select-trigger-ghost-padding-x: var(--space-2);
    --select-trigger-ghost-padding-y: var(--space-1);
  }

  .rt-SelectTrigger:where(.md\:rt-r-size-3) {
    --select-trigger-height: var(--space-7);
    gap: var(--space-2);
    font-size: var(--font-size-3);
    line-height: var(--line-height-3);
    letter-spacing: var(--letter-spacing-3);
    border-radius: max(var(--radius-3), var(--radius-full));
  }

  .rt-SelectTrigger:where(.md\:rt-r-size-3):where(:not(.rt-variant-ghost)) {
    padding-left: var(--space-4);
    padding-right: var(--space-4);
  }

  .rt-SelectTrigger:where(.md\:rt-r-size-3):where(.rt-variant-ghost) {
    --select-trigger-ghost-padding-x: var(--space-3);
    --select-trigger-ghost-padding-y: calc(var(--space-1) * 1.5);
  }

  .rt-SelectTrigger:where(.md\:rt-r-size-3) :where(.rt-SelectIcon) {
    width: 11px;
    height: 11px;
  }
}

@media (min-width: 1280px) {
  .rt-SelectTrigger:where(.lg\:rt-r-size-1) {
    --select-trigger-height: var(--space-5);
    gap: var(--space-1);
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
    border-radius: max(var(--radius-1), var(--radius-full));
  }

  .rt-SelectTrigger:where(.lg\:rt-r-size-1):where(:not(.rt-variant-ghost)) {
    padding-left: var(--space-2);
    padding-right: var(--space-2);
  }

  .rt-SelectTrigger:where(.lg\:rt-r-size-1):where(.rt-variant-ghost) {
    --select-trigger-ghost-padding-x: var(--space-2);
    --select-trigger-ghost-padding-y: var(--space-1);
  }

  .rt-SelectTrigger:where(.lg\:rt-r-size-2) {
    --select-trigger-height: var(--space-6);
    gap: calc(var(--space-1) * 1.5);
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    letter-spacing: var(--letter-spacing-2);
    border-radius: max(var(--radius-2), var(--radius-full));
  }

  .rt-SelectTrigger:where(.lg\:rt-r-size-2):where(:not(.rt-variant-ghost)) {
    padding-left: var(--space-3);
    padding-right: var(--space-3);
  }

  .rt-SelectTrigger:where(.lg\:rt-r-size-2):where(.rt-variant-ghost) {
    --select-trigger-ghost-padding-x: var(--space-2);
    --select-trigger-ghost-padding-y: var(--space-1);
  }

  .rt-SelectTrigger:where(.lg\:rt-r-size-3) {
    --select-trigger-height: var(--space-7);
    gap: var(--space-2);
    font-size: var(--font-size-3);
    line-height: var(--line-height-3);
    letter-spacing: var(--letter-spacing-3);
    border-radius: max(var(--radius-3), var(--radius-full));
  }

  .rt-SelectTrigger:where(.lg\:rt-r-size-3):where(:not(.rt-variant-ghost)) {
    padding-left: var(--space-4);
    padding-right: var(--space-4);
  }

  .rt-SelectTrigger:where(.lg\:rt-r-size-3):where(.rt-variant-ghost) {
    --select-trigger-ghost-padding-x: var(--space-3);
    --select-trigger-ghost-padding-y: calc(var(--space-1) * 1.5);
  }

  .rt-SelectTrigger:where(.lg\:rt-r-size-3) :where(.rt-SelectIcon) {
    width: 11px;
    height: 11px;
  }
}

@media (min-width: 1640px) {
  .rt-SelectTrigger:where(.xl\:rt-r-size-1) {
    --select-trigger-height: var(--space-5);
    gap: var(--space-1);
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
    border-radius: max(var(--radius-1), var(--radius-full));
  }

  .rt-SelectTrigger:where(.xl\:rt-r-size-1):where(:not(.rt-variant-ghost)) {
    padding-left: var(--space-2);
    padding-right: var(--space-2);
  }

  .rt-SelectTrigger:where(.xl\:rt-r-size-1):where(.rt-variant-ghost) {
    --select-trigger-ghost-padding-x: var(--space-2);
    --select-trigger-ghost-padding-y: var(--space-1);
  }

  .rt-SelectTrigger:where(.xl\:rt-r-size-2) {
    --select-trigger-height: var(--space-6);
    gap: calc(var(--space-1) * 1.5);
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    letter-spacing: var(--letter-spacing-2);
    border-radius: max(var(--radius-2), var(--radius-full));
  }

  .rt-SelectTrigger:where(.xl\:rt-r-size-2):where(:not(.rt-variant-ghost)) {
    padding-left: var(--space-3);
    padding-right: var(--space-3);
  }

  .rt-SelectTrigger:where(.xl\:rt-r-size-2):where(.rt-variant-ghost) {
    --select-trigger-ghost-padding-x: var(--space-2);
    --select-trigger-ghost-padding-y: var(--space-1);
  }

  .rt-SelectTrigger:where(.xl\:rt-r-size-3) {
    --select-trigger-height: var(--space-7);
    gap: var(--space-2);
    font-size: var(--font-size-3);
    line-height: var(--line-height-3);
    letter-spacing: var(--letter-spacing-3);
    border-radius: max(var(--radius-3), var(--radius-full));
  }

  .rt-SelectTrigger:where(.xl\:rt-r-size-3):where(:not(.rt-variant-ghost)) {
    padding-left: var(--space-4);
    padding-right: var(--space-4);
  }

  .rt-SelectTrigger:where(.xl\:rt-r-size-3):where(.rt-variant-ghost) {
    --select-trigger-ghost-padding-x: var(--space-3);
    --select-trigger-ghost-padding-y: calc(var(--space-1) * 1.5);
  }

  .rt-SelectTrigger:where(.xl\:rt-r-size-3) :where(.rt-SelectIcon) {
    width: 11px;
    height: 11px;
  }
}

.rt-SelectContent:where(.rt-r-size-1) {
  --select-content-padding: var(--space-1);
  --select-item-height: var(--space-5);
  --select-item-indicator-width: calc(var(--space-5) / 1.2);
  --select-separator-margin-right: var(--space-2);
  border-radius: var(--radius-3);
}

.rt-SelectContent:where(.rt-r-size-1) :where(.rt-SelectLabel) {
  font-size: var(--font-size-1);
  letter-spacing: var(--letter-spacing-1);
  line-height: var(--line-height-1);
}

.rt-SelectContent:where(.rt-r-size-1) :where(.rt-SelectItem) {
  font-size: var(--font-size-1);
  line-height: var(--line-height-1);
  letter-spacing: var(--letter-spacing-1);
  border-radius: var(--radius-1);
}

.rt-SelectContent:where(.rt-r-size-1) :where(.rt-SelectItemIndicatorIcon) {
  width: calc(8px * var(--scaling));
  height: calc(8px * var(--scaling));
}

.rt-SelectContent:where(.rt-r-size-2, .rt-r-size-3) {
  --select-content-padding: var(--space-2);
  --select-item-height: var(--space-6);
  --select-item-indicator-width: var(--space-5);
  --select-separator-margin-right: var(--space-3);
  border-radius: var(--radius-4);
}

.rt-SelectContent:where(.rt-r-size-2, .rt-r-size-3) :where(.rt-SelectLabel) {
  font-size: var(--font-size-2);
  letter-spacing: var(--letter-spacing-2);
  line-height: var(--line-height-2);
}

.rt-SelectContent:where(.rt-r-size-2, .rt-r-size-3) :where(.rt-SelectItem) {
  line-height: var(--line-height-2);
  border-radius: var(--radius-2);
}

.rt-SelectContent:where(.rt-r-size-2) :where(.rt-SelectItem) {
  font-size: var(--font-size-2);
  letter-spacing: var(--letter-spacing-2);
}

.rt-SelectContent:where(.rt-r-size-2) :where(.rt-SelectItemIndicatorIcon) {
  width: calc(10px * var(--scaling));
  height: calc(10px * var(--scaling));
}

.rt-SelectContent:where(.rt-r-size-3) :where(.rt-SelectItem) {
  font-size: var(--font-size-3);
  letter-spacing: var(--letter-spacing-3);
}

.rt-SelectContent:where(.rt-r-size-3) :where(.rt-SelectItemIndicatorIcon) {
  width: calc(10px * var(--scaling));
  height: calc(10px * var(--scaling));
}

@media (min-width: 520px) {
  .rt-SelectContent:where(.xs\:rt-r-size-1) {
    --select-content-padding: var(--space-1);
    --select-item-height: var(--space-5);
    --select-item-indicator-width: calc(var(--space-5) / 1.2);
    --select-separator-margin-right: var(--space-2);
    border-radius: var(--radius-3);
  }

  .rt-SelectContent:where(.xs\:rt-r-size-1) :where(.rt-SelectLabel) {
    font-size: var(--font-size-1);
    letter-spacing: var(--letter-spacing-1);
    line-height: var(--line-height-1);
  }

  .rt-SelectContent:where(.xs\:rt-r-size-1) :where(.rt-SelectItem) {
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
    border-radius: var(--radius-1);
  }

  .rt-SelectContent:where(.xs\:rt-r-size-1) :where(.rt-SelectItemIndicatorIcon) {
    width: calc(8px * var(--scaling));
    height: calc(8px * var(--scaling));
  }

  .rt-SelectContent:where(.xs\:rt-r-size-2, .xs\:rt-r-size-3) {
    --select-content-padding: var(--space-2);
    --select-item-height: var(--space-6);
    --select-item-indicator-width: var(--space-5);
    --select-separator-margin-right: var(--space-3);
    border-radius: var(--radius-4);
  }

  .rt-SelectContent:where(.xs\:rt-r-size-2, .xs\:rt-r-size-3) :where(.rt-SelectLabel) {
    font-size: var(--font-size-2);
    letter-spacing: var(--letter-spacing-2);
    line-height: var(--line-height-2);
  }

  .rt-SelectContent:where(.xs\:rt-r-size-2, .xs\:rt-r-size-3) :where(.rt-SelectItem) {
    line-height: var(--line-height-2);
    border-radius: var(--radius-2);
  }

  .rt-SelectContent:where(.xs\:rt-r-size-2) :where(.rt-SelectItem) {
    font-size: var(--font-size-2);
    letter-spacing: var(--letter-spacing-2);
  }

  .rt-SelectContent:where(.xs\:rt-r-size-2) :where(.rt-SelectItemIndicatorIcon) {
    width: calc(10px * var(--scaling));
    height: calc(10px * var(--scaling));
  }

  .rt-SelectContent:where(.xs\:rt-r-size-3) :where(.rt-SelectItem) {
    font-size: var(--font-size-3);
    letter-spacing: var(--letter-spacing-3);
  }

  .rt-SelectContent:where(.xs\:rt-r-size-3) :where(.rt-SelectItemIndicatorIcon) {
    width: calc(10px * var(--scaling));
    height: calc(10px * var(--scaling));
  }
}

@media (min-width: 768px) {
  .rt-SelectContent:where(.sm\:rt-r-size-1) {
    --select-content-padding: var(--space-1);
    --select-item-height: var(--space-5);
    --select-item-indicator-width: calc(var(--space-5) / 1.2);
    --select-separator-margin-right: var(--space-2);
    border-radius: var(--radius-3);
  }

  .rt-SelectContent:where(.sm\:rt-r-size-1) :where(.rt-SelectLabel) {
    font-size: var(--font-size-1);
    letter-spacing: var(--letter-spacing-1);
    line-height: var(--line-height-1);
  }

  .rt-SelectContent:where(.sm\:rt-r-size-1) :where(.rt-SelectItem) {
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
    border-radius: var(--radius-1);
  }

  .rt-SelectContent:where(.sm\:rt-r-size-1) :where(.rt-SelectItemIndicatorIcon) {
    width: calc(8px * var(--scaling));
    height: calc(8px * var(--scaling));
  }

  .rt-SelectContent:where(.sm\:rt-r-size-2, .sm\:rt-r-size-3) {
    --select-content-padding: var(--space-2);
    --select-item-height: var(--space-6);
    --select-item-indicator-width: var(--space-5);
    --select-separator-margin-right: var(--space-3);
    border-radius: var(--radius-4);
  }

  .rt-SelectContent:where(.sm\:rt-r-size-2, .sm\:rt-r-size-3) :where(.rt-SelectLabel) {
    font-size: var(--font-size-2);
    letter-spacing: var(--letter-spacing-2);
    line-height: var(--line-height-2);
  }

  .rt-SelectContent:where(.sm\:rt-r-size-2, .sm\:rt-r-size-3) :where(.rt-SelectItem) {
    line-height: var(--line-height-2);
    border-radius: var(--radius-2);
  }

  .rt-SelectContent:where(.sm\:rt-r-size-2) :where(.rt-SelectItem) {
    font-size: var(--font-size-2);
    letter-spacing: var(--letter-spacing-2);
  }

  .rt-SelectContent:where(.sm\:rt-r-size-2) :where(.rt-SelectItemIndicatorIcon) {
    width: calc(10px * var(--scaling));
    height: calc(10px * var(--scaling));
  }

  .rt-SelectContent:where(.sm\:rt-r-size-3) :where(.rt-SelectItem) {
    font-size: var(--font-size-3);
    letter-spacing: var(--letter-spacing-3);
  }

  .rt-SelectContent:where(.sm\:rt-r-size-3) :where(.rt-SelectItemIndicatorIcon) {
    width: calc(10px * var(--scaling));
    height: calc(10px * var(--scaling));
  }
}

@media (min-width: 1024px) {
  .rt-SelectContent:where(.md\:rt-r-size-1) {
    --select-content-padding: var(--space-1);
    --select-item-height: var(--space-5);
    --select-item-indicator-width: calc(var(--space-5) / 1.2);
    --select-separator-margin-right: var(--space-2);
    border-radius: var(--radius-3);
  }

  .rt-SelectContent:where(.md\:rt-r-size-1) :where(.rt-SelectLabel) {
    font-size: var(--font-size-1);
    letter-spacing: var(--letter-spacing-1);
    line-height: var(--line-height-1);
  }

  .rt-SelectContent:where(.md\:rt-r-size-1) :where(.rt-SelectItem) {
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
    border-radius: var(--radius-1);
  }

  .rt-SelectContent:where(.md\:rt-r-size-1) :where(.rt-SelectItemIndicatorIcon) {
    width: calc(8px * var(--scaling));
    height: calc(8px * var(--scaling));
  }

  .rt-SelectContent:where(.md\:rt-r-size-2, .md\:rt-r-size-3) {
    --select-content-padding: var(--space-2);
    --select-item-height: var(--space-6);
    --select-item-indicator-width: var(--space-5);
    --select-separator-margin-right: var(--space-3);
    border-radius: var(--radius-4);
  }

  .rt-SelectContent:where(.md\:rt-r-size-2, .md\:rt-r-size-3) :where(.rt-SelectLabel) {
    font-size: var(--font-size-2);
    letter-spacing: var(--letter-spacing-2);
    line-height: var(--line-height-2);
  }

  .rt-SelectContent:where(.md\:rt-r-size-2, .md\:rt-r-size-3) :where(.rt-SelectItem) {
    line-height: var(--line-height-2);
    border-radius: var(--radius-2);
  }

  .rt-SelectContent:where(.md\:rt-r-size-2) :where(.rt-SelectItem) {
    font-size: var(--font-size-2);
    letter-spacing: var(--letter-spacing-2);
  }

  .rt-SelectContent:where(.md\:rt-r-size-2) :where(.rt-SelectItemIndicatorIcon) {
    width: calc(10px * var(--scaling));
    height: calc(10px * var(--scaling));
  }

  .rt-SelectContent:where(.md\:rt-r-size-3) :where(.rt-SelectItem) {
    font-size: var(--font-size-3);
    letter-spacing: var(--letter-spacing-3);
  }

  .rt-SelectContent:where(.md\:rt-r-size-3) :where(.rt-SelectItemIndicatorIcon) {
    width: calc(10px * var(--scaling));
    height: calc(10px * var(--scaling));
  }
}

@media (min-width: 1280px) {
  .rt-SelectContent:where(.lg\:rt-r-size-1) {
    --select-content-padding: var(--space-1);
    --select-item-height: var(--space-5);
    --select-item-indicator-width: calc(var(--space-5) / 1.2);
    --select-separator-margin-right: var(--space-2);
    border-radius: var(--radius-3);
  }

  .rt-SelectContent:where(.lg\:rt-r-size-1) :where(.rt-SelectLabel) {
    font-size: var(--font-size-1);
    letter-spacing: var(--letter-spacing-1);
    line-height: var(--line-height-1);
  }

  .rt-SelectContent:where(.lg\:rt-r-size-1) :where(.rt-SelectItem) {
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
    border-radius: var(--radius-1);
  }

  .rt-SelectContent:where(.lg\:rt-r-size-1) :where(.rt-SelectItemIndicatorIcon) {
    width: calc(8px * var(--scaling));
    height: calc(8px * var(--scaling));
  }

  .rt-SelectContent:where(.lg\:rt-r-size-2, .lg\:rt-r-size-3) {
    --select-content-padding: var(--space-2);
    --select-item-height: var(--space-6);
    --select-item-indicator-width: var(--space-5);
    --select-separator-margin-right: var(--space-3);
    border-radius: var(--radius-4);
  }

  .rt-SelectContent:where(.lg\:rt-r-size-2, .lg\:rt-r-size-3) :where(.rt-SelectLabel) {
    font-size: var(--font-size-2);
    letter-spacing: var(--letter-spacing-2);
    line-height: var(--line-height-2);
  }

  .rt-SelectContent:where(.lg\:rt-r-size-2, .lg\:rt-r-size-3) :where(.rt-SelectItem) {
    line-height: var(--line-height-2);
    border-radius: var(--radius-2);
  }

  .rt-SelectContent:where(.lg\:rt-r-size-2) :where(.rt-SelectItem) {
    font-size: var(--font-size-2);
    letter-spacing: var(--letter-spacing-2);
  }

  .rt-SelectContent:where(.lg\:rt-r-size-2) :where(.rt-SelectItemIndicatorIcon) {
    width: calc(10px * var(--scaling));
    height: calc(10px * var(--scaling));
  }

  .rt-SelectContent:where(.lg\:rt-r-size-3) :where(.rt-SelectItem) {
    font-size: var(--font-size-3);
    letter-spacing: var(--letter-spacing-3);
  }

  .rt-SelectContent:where(.lg\:rt-r-size-3) :where(.rt-SelectItemIndicatorIcon) {
    width: calc(10px * var(--scaling));
    height: calc(10px * var(--scaling));
  }
}

@media (min-width: 1640px) {
  .rt-SelectContent:where(.xl\:rt-r-size-1) {
    --select-content-padding: var(--space-1);
    --select-item-height: var(--space-5);
    --select-item-indicator-width: calc(var(--space-5) / 1.2);
    --select-separator-margin-right: var(--space-2);
    border-radius: var(--radius-3);
  }

  .rt-SelectContent:where(.xl\:rt-r-size-1) :where(.rt-SelectLabel) {
    font-size: var(--font-size-1);
    letter-spacing: var(--letter-spacing-1);
    line-height: var(--line-height-1);
  }

  .rt-SelectContent:where(.xl\:rt-r-size-1) :where(.rt-SelectItem) {
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
    border-radius: var(--radius-1);
  }

  .rt-SelectContent:where(.xl\:rt-r-size-1) :where(.rt-SelectItemIndicatorIcon) {
    width: calc(8px * var(--scaling));
    height: calc(8px * var(--scaling));
  }

  .rt-SelectContent:where(.xl\:rt-r-size-2, .xl\:rt-r-size-3) {
    --select-content-padding: var(--space-2);
    --select-item-height: var(--space-6);
    --select-item-indicator-width: var(--space-5);
    --select-separator-margin-right: var(--space-3);
    border-radius: var(--radius-4);
  }

  .rt-SelectContent:where(.xl\:rt-r-size-2, .xl\:rt-r-size-3) :where(.rt-SelectLabel) {
    font-size: var(--font-size-2);
    letter-spacing: var(--letter-spacing-2);
    line-height: var(--line-height-2);
  }

  .rt-SelectContent:where(.xl\:rt-r-size-2, .xl\:rt-r-size-3) :where(.rt-SelectItem) {
    line-height: var(--line-height-2);
    border-radius: var(--radius-2);
  }

  .rt-SelectContent:where(.xl\:rt-r-size-2) :where(.rt-SelectItem) {
    font-size: var(--font-size-2);
    letter-spacing: var(--letter-spacing-2);
  }

  .rt-SelectContent:where(.xl\:rt-r-size-2) :where(.rt-SelectItemIndicatorIcon) {
    width: calc(10px * var(--scaling));
    height: calc(10px * var(--scaling));
  }

  .rt-SelectContent:where(.xl\:rt-r-size-3) :where(.rt-SelectItem) {
    font-size: var(--font-size-3);
    letter-spacing: var(--letter-spacing-3);
  }

  .rt-SelectContent:where(.xl\:rt-r-size-3) :where(.rt-SelectItemIndicatorIcon) {
    width: calc(10px * var(--scaling));
    height: calc(10px * var(--scaling));
  }
}

.rt-SelectTrigger:where(.rt-variant-surface) {
  color: var(--gray-12);
  background-color: var(--color-surface);
  box-shadow: inset 0 0 0 1px var(--gray-a7);
}

@media (hover: hover) {
  .rt-SelectTrigger:where(.rt-variant-surface):where(:hover) {
    box-shadow: inset 0 0 0 1px var(--gray-a8);
  }
}

.rt-SelectTrigger:where(.rt-variant-surface):where([data-state="open"]) {
  box-shadow: inset 0 0 0 1px var(--gray-a8);
}

.rt-SelectTrigger:where(.rt-variant-surface):where(:disabled) {
  color: var(--gray-a11);
  background-color: var(--gray-a2);
  box-shadow: inset 0 0 0 1px var(--gray-a6);
}

.rt-SelectTrigger:where(.rt-variant-surface):where([data-placeholder]) :where(.rt-SelectTriggerInner) {
  color: var(--gray-a10);
}

.rt-SelectTrigger:where(.rt-variant-classic) {
  color: var(--gray-12);
  background-image: linear-gradient(var(--gray-2), var(--gray-1));
  box-shadow: var(--select-trigger-classic-box-shadow);
  z-index: 0;
  position: relative;
}

.rt-SelectTrigger:where(.rt-variant-classic):before {
  content: "";
  z-index: -1;
  border-radius: inherit;
  pointer-events: none;
  background-clip: content-box;
  background-image: linear-gradient(var(--black-a1) -20%, transparent, var(--white-a1) 130%), linear-gradient(var(--color-surface), transparent);
  border: 2px solid rgba(0, 0, 0, 0);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

@media (hover: hover) {
  .rt-SelectTrigger:where(.rt-variant-classic):where(:hover) {
    box-shadow: inset 0 0 0 1px var(--gray-a3), var(--select-trigger-classic-box-shadow);
  }

  .rt-SelectTrigger:where(.rt-variant-classic):where(:hover):before {
    background-image: linear-gradient(var(--black-a1) -15%, transparent, var(--white-a1) 120%), linear-gradient(var(--gray-2), var(--gray-1));
  }
}

.rt-SelectTrigger:where(.rt-variant-classic):where([data-state="open"]) {
  box-shadow: inset 0 0 0 1px var(--gray-a3), var(--select-trigger-classic-box-shadow);
}

.rt-SelectTrigger:where(.rt-variant-classic):where([data-state="open"]):before {
  background-image: linear-gradient(var(--black-a1) -15%, transparent, var(--white-a1) 120%), linear-gradient(var(--gray-2), var(--gray-1));
}

.rt-SelectTrigger:where(.rt-variant-classic):where(:disabled) {
  color: var(--gray-a11);
  background-color: var(--gray-2);
  box-shadow: var(--base-button-classic-disabled-box-shadow);
  background-image: none;
}

.rt-SelectTrigger:where(.rt-variant-classic):where(:disabled):before {
  background-color: var(--gray-a2);
  background-image: linear-gradient(var(--black-a1) -20%, transparent, var(--white-a1));
}

.rt-SelectTrigger:where(.rt-variant-classic):where([data-placeholder]) :where(.rt-SelectTriggerInner) {
  color: var(--gray-a10);
}

.rt-SelectTrigger:where(.rt-variant-soft) {
  color: var(--accent-12);
}

.rt-SelectTrigger:where(.rt-variant-ghost) {
  color: var(--accent-12);
}

.rt-SelectTrigger:where(.rt-variant-soft):where([data-placeholder]) :where(.rt-SelectTriggerInner) {
  color: var(--accent-12);
  opacity: .6;
}

.rt-SelectTrigger:where(.rt-variant-ghost):where([data-placeholder]) :where(.rt-SelectTriggerInner) {
  color: var(--accent-12);
  opacity: .6;
}

.rt-SelectTrigger:where(.rt-variant-soft) {
  background-color: var(--accent-a3);
}

@media (hover: hover) {
  .rt-SelectTrigger:where(.rt-variant-soft):where(:hover) {
    background-color: var(--accent-a4);
  }
}

.rt-SelectTrigger:where(.rt-variant-soft):where([data-state="open"]) {
  background-color: var(--accent-a4);
}

.rt-SelectTrigger:where(.rt-variant-soft):where(:focus-visible) {
  outline-color: var(--accent-8);
}

.rt-SelectTrigger:where(.rt-variant-soft):where(:disabled) {
  color: var(--gray-a11);
  background-color: var(--gray-a3);
}

@media (hover: hover) {
  .rt-SelectTrigger:where(.rt-variant-ghost):where(:hover) {
    background-color: var(--accent-a3);
  }
}

.rt-SelectTrigger:where(.rt-variant-ghost):where([data-state="open"]) {
  background-color: var(--accent-a3);
}

.rt-SelectTrigger:where(.rt-variant-ghost):where(:disabled) {
  color: var(--gray-a11);
  background-color: rgba(0, 0, 0, 0);
}

.rt-SelectTrigger:where(:disabled) :where(.rt-SelectIcon) {
  color: var(--gray-a9);
}

.rt-SelectContent {
  box-shadow: var(--shadow-5);
  --scrollarea-scrollbar-vertical-margin-top: var(--select-content-padding);
  --scrollarea-scrollbar-vertical-margin-bottom: var(--select-content-padding);
  --scrollarea-scrollbar-horizontal-margin-left: var(--select-content-padding);
  --scrollarea-scrollbar-horizontal-margin-right: var(--select-content-padding);
  background-color: var(--color-panel-solid);
  overflow: hidden;
}

.rt-SelectItem:where([data-disabled]) {
  color: var(--gray-a8);
  cursor: default;
}

.rt-SelectContent:where(.rt-variant-solid) :where(.rt-SelectItem[data-highlighted]) {
  background-color: var(--accent-9);
  color: var(--accent-contrast);
}

.rt-SelectContent:where(.rt-variant-solid):where(.rt-high-contrast) :where(.rt-SelectItem[data-highlighted]) {
  background-color: var(--accent-12);
  color: var(--accent-1);
}

.rt-SelectContent:where(.rt-variant-soft) :where(.rt-SelectItem[data-highlighted]) {
  background-color: var(--accent-a4);
}

.rt-Separator {
  background-color: var(--accent-a6);
  display: block;
}

.rt-Separator:where(.rt-r-orientation-horizontal) {
  width: var(--separator-size);
  height: 1px;
}

.rt-Separator:where(.rt-r-orientation-vertical) {
  width: 1px;
  height: var(--separator-size);
}

@media (min-width: 520px) {
  .rt-Separator:where(.xs\:rt-r-orientation-horizontal) {
    width: var(--separator-size);
    height: 1px;
  }

  .rt-Separator:where(.xs\:rt-r-orientation-vertical) {
    width: 1px;
    height: var(--separator-size);
  }
}

@media (min-width: 768px) {
  .rt-Separator:where(.sm\:rt-r-orientation-horizontal) {
    width: var(--separator-size);
    height: 1px;
  }

  .rt-Separator:where(.sm\:rt-r-orientation-vertical) {
    width: 1px;
    height: var(--separator-size);
  }
}

@media (min-width: 1024px) {
  .rt-Separator:where(.md\:rt-r-orientation-horizontal) {
    width: var(--separator-size);
    height: 1px;
  }

  .rt-Separator:where(.md\:rt-r-orientation-vertical) {
    width: 1px;
    height: var(--separator-size);
  }
}

@media (min-width: 1280px) {
  .rt-Separator:where(.lg\:rt-r-orientation-horizontal) {
    width: var(--separator-size);
    height: 1px;
  }

  .rt-Separator:where(.lg\:rt-r-orientation-vertical) {
    width: 1px;
    height: var(--separator-size);
  }
}

@media (min-width: 1640px) {
  .rt-Separator:where(.xl\:rt-r-orientation-horizontal) {
    width: var(--separator-size);
    height: 1px;
  }

  .rt-Separator:where(.xl\:rt-r-orientation-vertical) {
    width: 1px;
    height: var(--separator-size);
  }
}

.rt-Separator:where(.rt-r-size-1) {
  --separator-size: var(--space-4);
}

.rt-Separator:where(.rt-r-size-2) {
  --separator-size: var(--space-6);
}

.rt-Separator:where(.rt-r-size-3) {
  --separator-size: var(--space-9);
}

.rt-Separator:where(.rt-r-size-4) {
  --separator-size: 100%;
}

@media (min-width: 520px) {
  .rt-Separator:where(.xs\:rt-r-size-1) {
    --separator-size: var(--space-4);
  }

  .rt-Separator:where(.xs\:rt-r-size-2) {
    --separator-size: var(--space-6);
  }

  .rt-Separator:where(.xs\:rt-r-size-3) {
    --separator-size: var(--space-9);
  }

  .rt-Separator:where(.xs\:rt-r-size-4) {
    --separator-size: 100%;
  }
}

@media (min-width: 768px) {
  .rt-Separator:where(.sm\:rt-r-size-1) {
    --separator-size: var(--space-4);
  }

  .rt-Separator:where(.sm\:rt-r-size-2) {
    --separator-size: var(--space-6);
  }

  .rt-Separator:where(.sm\:rt-r-size-3) {
    --separator-size: var(--space-9);
  }

  .rt-Separator:where(.sm\:rt-r-size-4) {
    --separator-size: 100%;
  }
}

@media (min-width: 1024px) {
  .rt-Separator:where(.md\:rt-r-size-1) {
    --separator-size: var(--space-4);
  }

  .rt-Separator:where(.md\:rt-r-size-2) {
    --separator-size: var(--space-6);
  }

  .rt-Separator:where(.md\:rt-r-size-3) {
    --separator-size: var(--space-9);
  }

  .rt-Separator:where(.md\:rt-r-size-4) {
    --separator-size: 100%;
  }
}

@media (min-width: 1280px) {
  .rt-Separator:where(.lg\:rt-r-size-1) {
    --separator-size: var(--space-4);
  }

  .rt-Separator:where(.lg\:rt-r-size-2) {
    --separator-size: var(--space-6);
  }

  .rt-Separator:where(.lg\:rt-r-size-3) {
    --separator-size: var(--space-9);
  }

  .rt-Separator:where(.lg\:rt-r-size-4) {
    --separator-size: 100%;
  }
}

@media (min-width: 1640px) {
  .rt-Separator:where(.xl\:rt-r-size-1) {
    --separator-size: var(--space-4);
  }

  .rt-Separator:where(.xl\:rt-r-size-2) {
    --separator-size: var(--space-6);
  }

  .rt-Separator:where(.xl\:rt-r-size-3) {
    --separator-size: var(--space-9);
  }

  .rt-Separator:where(.xl\:rt-r-size-4) {
    --separator-size: 100%;
  }
}

.rt-SliderRoot {
  --slider-thumb-size: calc(var(--slider-track-size)  + var(--space-1));
  border-radius: max(calc(var(--radius-factor) * var(--slider-track-size) / 3), calc(var(--radius-factor) * var(--radius-thumb)));
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  touch-action: none;
  flex-grow: 1;
  align-items: center;
  display: flex;
  position: relative;
}

.rt-SliderRoot:where([data-orientation="horizontal"]) {
  width: -webkit-fill-available;
  width: -moz-available;
  width: stretch;
  height: var(--slider-track-size);
}

.rt-SliderRoot:where([data-orientation="vertical"]) {
  height: -webkit-fill-available;
  height: -moz-available;
  height: stretch;
  width: var(--slider-track-size);
  flex-direction: column;
}

.rt-SliderTrack {
  border-radius: inherit;
  flex-grow: 1;
  position: relative;
  overflow: hidden;
}

.rt-SliderTrack:where([data-orientation="horizontal"]) {
  height: var(--slider-track-size);
}

.rt-SliderTrack:where([data-orientation="vertical"]) {
  width: var(--slider-track-size);
}

.rt-SliderRange {
  border-radius: inherit;
  position: absolute;
}

.rt-SliderRange:where([data-orientation="horizontal"]) {
  height: 100%;
}

.rt-SliderRange:where([data-orientation="vertical"]) {
  width: 100%;
}

.rt-SliderThumb {
  width: var(--slider-thumb-size);
  height: var(--slider-thumb-size);
  outline: 0;
  display: block;
}

.rt-SliderThumb:before {
  content: "";
  z-index: -1;
  width: calc(var(--slider-thumb-size) * 3);
  height: calc(var(--slider-thumb-size) * 3);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.rt-SliderThumb:after {
  content: "";
  inset: calc(-.25 * var(--slider-track-size));
  border-radius: max(var(--radius-1), var(--radius-thumb));
  box-shadow: var(--slider-thumb-box-shadow);
  cursor: var(--cursor-slider-thumb);
  background-color: #fff;
  position: absolute;
}

.rt-SliderThumb:where(:focus-visible):after {
  box-shadow: var(--slider-thumb-box-shadow), 0 0 0 3px var(--accent-3), 0 0 0 5px var(--focus-8);
}

.rt-SliderThumb:where(:active) {
  cursor: var(--cursor-slider-thumb-active);
}

.rt-SliderRoot:where(.rt-r-size-1) {
  --slider-track-size: calc(var(--space-2) * .75);
}

.rt-SliderRoot:where(.rt-r-size-2) {
  --slider-track-size: var(--space-2);
}

.rt-SliderRoot:where(.rt-r-size-3) {
  --slider-track-size: calc(var(--space-2) * 1.25);
}

@media (min-width: 520px) {
  .rt-SliderRoot:where(.xs\:rt-r-size-1) {
    --slider-track-size: calc(var(--space-2) * .75);
  }

  .rt-SliderRoot:where(.xs\:rt-r-size-2) {
    --slider-track-size: var(--space-2);
  }

  .rt-SliderRoot:where(.xs\:rt-r-size-3) {
    --slider-track-size: calc(var(--space-2) * 1.25);
  }
}

@media (min-width: 768px) {
  .rt-SliderRoot:where(.sm\:rt-r-size-1) {
    --slider-track-size: calc(var(--space-2) * .75);
  }

  .rt-SliderRoot:where(.sm\:rt-r-size-2) {
    --slider-track-size: var(--space-2);
  }

  .rt-SliderRoot:where(.sm\:rt-r-size-3) {
    --slider-track-size: calc(var(--space-2) * 1.25);
  }
}

@media (min-width: 1024px) {
  .rt-SliderRoot:where(.md\:rt-r-size-1) {
    --slider-track-size: calc(var(--space-2) * .75);
  }

  .rt-SliderRoot:where(.md\:rt-r-size-2) {
    --slider-track-size: var(--space-2);
  }

  .rt-SliderRoot:where(.md\:rt-r-size-3) {
    --slider-track-size: calc(var(--space-2) * 1.25);
  }
}

@media (min-width: 1280px) {
  .rt-SliderRoot:where(.lg\:rt-r-size-1) {
    --slider-track-size: calc(var(--space-2) * .75);
  }

  .rt-SliderRoot:where(.lg\:rt-r-size-2) {
    --slider-track-size: var(--space-2);
  }

  .rt-SliderRoot:where(.lg\:rt-r-size-3) {
    --slider-track-size: calc(var(--space-2) * 1.25);
  }
}

@media (min-width: 1640px) {
  .rt-SliderRoot:where(.xl\:rt-r-size-1) {
    --slider-track-size: calc(var(--space-2) * .75);
  }

  .rt-SliderRoot:where(.xl\:rt-r-size-2) {
    --slider-track-size: var(--space-2);
  }

  .rt-SliderRoot:where(.xl\:rt-r-size-3) {
    --slider-track-size: calc(var(--space-2) * 1.25);
  }
}

.rt-SliderRoot:where(.rt-variant-surface) :where(.rt-SliderTrack) {
  background-color: var(--gray-a3);
  box-shadow: inset 0 0 0 1px var(--gray-a5);
}

.rt-SliderRoot:where(.rt-variant-surface) :where(.rt-SliderTrack):where([data-disabled]) {
  box-shadow: inset 0 0 0 1px var(--gray-a4);
}

.rt-SliderRoot:where(.rt-variant-surface) :where(.rt-SliderRange) {
  background-color: var(--accent-track);
  background-image: var(--slider-range-high-contrast-background-image);
  box-shadow: inset 0 0 0 1px var(--gray-a5);
}

.rt-SliderRoot:where(.rt-variant-surface) :where(.rt-SliderThumb) {
  --slider-thumb-box-shadow: 0 0 0 1px var(--black-a4);
}

.rt-SliderRoot:where(.rt-variant-surface) :where(.rt-SliderThumb):where([data-disabled]):after {
  background-color: var(--gray-1);
  box-shadow: 0 0 0 1px var(--gray-6);
}

.rt-SliderRoot:where(.rt-variant-classic) :where(.rt-SliderTrack) {
  background-color: var(--gray-a3);
  position: relative;
}

.rt-SliderRoot:where(.rt-variant-classic) :where(.rt-SliderTrack):before {
  content: "";
  border-radius: inherit;
  box-shadow: var(--shadow-1);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.rt-SliderRoot:where(.rt-variant-classic) :where(.rt-SliderTrack):where([data-disabled]):before {
  opacity: .5;
}

.rt-SliderRoot:where(.rt-variant-classic) :where(.rt-SliderRange) {
  background-color: var(--accent-track);
  background-image: var(--slider-range-high-contrast-background-image);
  box-shadow: inset 0 0 0 1px var(--gray-a3), inset 0 0 0 1px var(--accent-a4), inset 0 0 0 1px var(--black-a1), inset 0 1.5px 2px 0 var(--black-a2);
}

.rt-SliderRoot:where(.rt-variant-classic) :where(.rt-SliderRange):where(.rt-high-contrast) {
  box-shadow: inset 0 0 0 1px var(--gray-a3), inset 0 0 0 1px var(--black-a2), inset 0 1.5px 2px 0 var(--black-a2);
}

.rt-SliderRoot:where(.rt-variant-classic) :where(.rt-SliderThumb) {
  --slider-thumb-box-shadow: 0 0 0 1px var(--black-a3), 0 1px 3px var(--black-a1), 0 2px 4px -1px var(--black-a1);
}

.rt-SliderRoot:where(.rt-variant-classic) :where(.rt-SliderThumb):where([data-disabled]):after {
  background-color: var(--gray-1);
  box-shadow: 0 0 0 1px var(--gray-6);
}

.rt-SliderRoot:where(.rt-variant-soft) :where(.rt-SliderTrack) {
  background-color: var(--gray-a4);
  background-image: linear-gradient(var(--white-a1), var(--white-a1));
}

.rt-SliderRoot:where(.rt-variant-soft) :where(.rt-SliderTrack):where([data-disabled]) {
  background-color: var(--gray-a4);
  background-image: none;
}

.rt-SliderRoot:where(.rt-variant-soft) :where(.rt-SliderRange) {
  background-image: linear-gradient(var(--accent-a5), var(--accent-a5)), var(--slider-range-high-contrast-background-image);
  background-color: var(--accent-6);
}

.rt-SliderRoot:where(.rt-variant-soft) :where(.rt-SliderThumb) {
  --slider-thumb-box-shadow: 0 0 0 1px var(--black-a3), 0 0 0 1px var(--gray-a2), 0 0 0 1px var(--accent-a2), 0 1px 2px var(--gray-a4), 0 1px 3px -.5px var(--gray-a3);
}

.rt-SliderRoot:where(.rt-variant-soft) :where(.rt-SliderThumb):where([data-disabled]):after {
  background-color: var(--gray-1);
  box-shadow: 0 0 0 1px var(--gray-5);
}

.rt-SliderRoot:where(:not(.rt-high-contrast)) {
  --slider-range-high-contrast-background-image: none;
}

.rt-SliderRoot:where([data-disabled]) {
  cursor: var(--cursor-disabled);
  mix-blend-mode: var(--slider-disabled-blend-mode);
}

.rt-SliderRange:where([data-disabled]) {
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0);
  background-image: none;
}

.rt-SliderThumb:where([data-disabled]) {
  cursor: var(--cursor-disabled);
}

.rt-SliderThumb:where([data-disabled]):after {
  cursor: var(--cursor-disabled);
}

.rt-Spinner {
  opacity: var(--spinner-opacity);
  display: block;
  position: relative;
}

.rt-SpinnerLeaf {
  width: 12.5%;
  height: 100%;
  animation: rt-spinner-leaf-fade var(--spinner-animation-duration) linear infinite;
  position: absolute;
  top: 0;
  left: 43.75%;
}

.rt-SpinnerLeaf:before {
  content: "";
  border-radius: var(--radius-1);
  background-color: currentColor;
  width: 100%;
  height: 30%;
  display: block;
}

.rt-SpinnerLeaf:where(:first-child) {
  animation-delay: calc(-8 / 8 * var(--spinner-animation-duration));
  transform: rotate(0);
}

.rt-SpinnerLeaf:where(:nth-child(2)) {
  animation-delay: calc(-7 / 8 * var(--spinner-animation-duration));
  transform: rotate(45deg);
}

.rt-SpinnerLeaf:where(:nth-child(3)) {
  animation-delay: calc(-6 / 8 * var(--spinner-animation-duration));
  transform: rotate(90deg);
}

.rt-SpinnerLeaf:where(:nth-child(4)) {
  animation-delay: calc(-5 / 8 * var(--spinner-animation-duration));
  transform: rotate(135deg);
}

.rt-SpinnerLeaf:where(:nth-child(5)) {
  animation-delay: calc(-4 / 8 * var(--spinner-animation-duration));
  transform: rotate(180deg);
}

.rt-SpinnerLeaf:where(:nth-child(6)) {
  animation-delay: calc(-3 / 8 * var(--spinner-animation-duration));
  transform: rotate(225deg);
}

.rt-SpinnerLeaf:where(:nth-child(7)) {
  animation-delay: calc(-2 / 8 * var(--spinner-animation-duration));
  transform: rotate(270deg);
}

.rt-SpinnerLeaf:where(:nth-child(8)) {
  animation-delay: calc(-1 / 8 * var(--spinner-animation-duration));
  transform: rotate(315deg);
}

@keyframes rt-spinner-leaf-fade {
  from {
    opacity: 1;
  }

  to {
    opacity: .25;
  }
}

.rt-Spinner:where(.rt-r-size-1) {
  width: var(--space-3);
  height: var(--space-3);
}

.rt-Spinner:where(.rt-r-size-2) {
  width: var(--space-4);
  height: var(--space-4);
}

.rt-Spinner:where(.rt-r-size-3) {
  width: calc(1.25 * var(--space-4));
  height: calc(1.25 * var(--space-4));
}

@media (min-width: 520px) {
  .rt-Spinner:where(.xs\:rt-r-size-1) {
    width: var(--space-3);
    height: var(--space-3);
  }

  .rt-Spinner:where(.xs\:rt-r-size-2) {
    width: var(--space-4);
    height: var(--space-4);
  }

  .rt-Spinner:where(.xs\:rt-r-size-3) {
    width: calc(1.25 * var(--space-4));
    height: calc(1.25 * var(--space-4));
  }
}

@media (min-width: 768px) {
  .rt-Spinner:where(.sm\:rt-r-size-1) {
    width: var(--space-3);
    height: var(--space-3);
  }

  .rt-Spinner:where(.sm\:rt-r-size-2) {
    width: var(--space-4);
    height: var(--space-4);
  }

  .rt-Spinner:where(.sm\:rt-r-size-3) {
    width: calc(1.25 * var(--space-4));
    height: calc(1.25 * var(--space-4));
  }
}

@media (min-width: 1024px) {
  .rt-Spinner:where(.md\:rt-r-size-1) {
    width: var(--space-3);
    height: var(--space-3);
  }

  .rt-Spinner:where(.md\:rt-r-size-2) {
    width: var(--space-4);
    height: var(--space-4);
  }

  .rt-Spinner:where(.md\:rt-r-size-3) {
    width: calc(1.25 * var(--space-4));
    height: calc(1.25 * var(--space-4));
  }
}

@media (min-width: 1280px) {
  .rt-Spinner:where(.lg\:rt-r-size-1) {
    width: var(--space-3);
    height: var(--space-3);
  }

  .rt-Spinner:where(.lg\:rt-r-size-2) {
    width: var(--space-4);
    height: var(--space-4);
  }

  .rt-Spinner:where(.lg\:rt-r-size-3) {
    width: calc(1.25 * var(--space-4));
    height: calc(1.25 * var(--space-4));
  }
}

@media (min-width: 1640px) {
  .rt-Spinner:where(.xl\:rt-r-size-1) {
    width: var(--space-3);
    height: var(--space-3);
  }

  .rt-Spinner:where(.xl\:rt-r-size-2) {
    width: var(--space-4);
    height: var(--space-4);
  }

  .rt-Spinner:where(.xl\:rt-r-size-3) {
    width: calc(1.25 * var(--space-4));
    height: calc(1.25 * var(--space-4));
  }
}

.rt-Strong {
  font-family: var(--strong-font-family);
  font-size: calc(var(--strong-font-size-adjust) * 1em);
  font-style: var(--strong-font-style);
  font-weight: var(--strong-font-weight);
  letter-spacing: calc(var(--strong-letter-spacing)  + var(--letter-spacing, var(--default-letter-spacing)));
}

.rt-Strong :where(.rt-Strong) {
  font-size: inherit;
}

.rt-SwitchRoot {
  vertical-align: top;
  height: var(--skeleton-height, var(--line-height, var(--switch-height)));
  --skeleton-height-override: var(--switch-height);
  border-radius: var(--skeleton-radius);
  --skeleton-radius-override: var(--switch-border-radius);
  --switch-width: calc(var(--switch-height) * 1.75);
  --switch-thumb-inset: 1px;
  --switch-thumb-size: calc(var(--switch-height)  - var(--switch-thumb-inset) * 2);
  --switch-thumb-translate-x: calc(var(--switch-width)  - var(--switch-height));
  flex-shrink: 0;
  align-items: center;
  display: inline-flex;
  position: relative;
}

.rt-SwitchRoot:before {
  content: "";
  width: var(--switch-width);
  height: var(--switch-height);
  border-radius: var(--switch-border-radius);
  background-repeat: no-repeat;
  background-size: calc(var(--switch-width) * 2 + var(--switch-height)) 100%;
  cursor: var(--cursor-switch);
  transition: background-position linear, background-color ease-in-out, box-shadow ease-in-out, filter ease-in-out;
  display: block;
}

.rt-SwitchRoot:where([data-state="unchecked"]):before {
  background-position-x: 100%;
  transition-duration: .12s, .14s, .14s, .14s;
}

.rt-SwitchRoot:where([data-state="checked"]):before {
  background-position: 0%;
  transition-duration: .16s, .14s, .14s, .14s;
}

.rt-SwitchRoot:where(:active):before {
  transition-duration: 30ms;
}

.rt-SwitchRoot:where(:focus-visible):before {
  outline: 2px solid var(--focus-8);
  outline-offset: 2px;
}

.rt-SwitchRoot:where([data-disabled]):before {
  cursor: var(--cursor-disabled);
}

.rt-SwitchThumb {
  left: var(--switch-thumb-inset);
  width: var(--switch-thumb-size);
  height: var(--switch-thumb-size);
  border-radius: calc(var(--switch-border-radius)  - var(--switch-thumb-inset));
  background-color: #fff;
  transition: transform .14s cubic-bezier(.45, .05, .55, .95), box-shadow .14s ease-in-out;
  position: absolute;
}

.rt-SwitchThumb:where([data-state="checked"]) {
  transform: translateX(var(--switch-thumb-translate-x));
}

.rt-SwitchRoot:where(.rt-r-size-1) {
  --switch-height: var(--space-4);
  --switch-border-radius: max(var(--radius-1), var(--radius-thumb));
}

.rt-SwitchRoot:where(.rt-r-size-2) {
  --switch-height: calc(var(--space-5) * 5 / 6);
  --switch-border-radius: max(var(--radius-2), var(--radius-thumb));
}

.rt-SwitchRoot:where(.rt-r-size-3) {
  --switch-height: var(--space-5);
  --switch-border-radius: max(var(--radius-2), var(--radius-thumb));
}

@media (min-width: 520px) {
  .rt-SwitchRoot:where(.xs\:rt-r-size-1) {
    --switch-height: var(--space-4);
    --switch-border-radius: max(var(--radius-1), var(--radius-thumb));
  }

  .rt-SwitchRoot:where(.xs\:rt-r-size-2) {
    --switch-height: calc(var(--space-5) * 5 / 6);
    --switch-border-radius: max(var(--radius-2), var(--radius-thumb));
  }

  .rt-SwitchRoot:where(.xs\:rt-r-size-3) {
    --switch-height: var(--space-5);
    --switch-border-radius: max(var(--radius-2), var(--radius-thumb));
  }
}

@media (min-width: 768px) {
  .rt-SwitchRoot:where(.sm\:rt-r-size-1) {
    --switch-height: var(--space-4);
    --switch-border-radius: max(var(--radius-1), var(--radius-thumb));
  }

  .rt-SwitchRoot:where(.sm\:rt-r-size-2) {
    --switch-height: calc(var(--space-5) * 5 / 6);
    --switch-border-radius: max(var(--radius-2), var(--radius-thumb));
  }

  .rt-SwitchRoot:where(.sm\:rt-r-size-3) {
    --switch-height: var(--space-5);
    --switch-border-radius: max(var(--radius-2), var(--radius-thumb));
  }
}

@media (min-width: 1024px) {
  .rt-SwitchRoot:where(.md\:rt-r-size-1) {
    --switch-height: var(--space-4);
    --switch-border-radius: max(var(--radius-1), var(--radius-thumb));
  }

  .rt-SwitchRoot:where(.md\:rt-r-size-2) {
    --switch-height: calc(var(--space-5) * 5 / 6);
    --switch-border-radius: max(var(--radius-2), var(--radius-thumb));
  }

  .rt-SwitchRoot:where(.md\:rt-r-size-3) {
    --switch-height: var(--space-5);
    --switch-border-radius: max(var(--radius-2), var(--radius-thumb));
  }
}

@media (min-width: 1280px) {
  .rt-SwitchRoot:where(.lg\:rt-r-size-1) {
    --switch-height: var(--space-4);
    --switch-border-radius: max(var(--radius-1), var(--radius-thumb));
  }

  .rt-SwitchRoot:where(.lg\:rt-r-size-2) {
    --switch-height: calc(var(--space-5) * 5 / 6);
    --switch-border-radius: max(var(--radius-2), var(--radius-thumb));
  }

  .rt-SwitchRoot:where(.lg\:rt-r-size-3) {
    --switch-height: var(--space-5);
    --switch-border-radius: max(var(--radius-2), var(--radius-thumb));
  }
}

@media (min-width: 1640px) {
  .rt-SwitchRoot:where(.xl\:rt-r-size-1) {
    --switch-height: var(--space-4);
    --switch-border-radius: max(var(--radius-1), var(--radius-thumb));
  }

  .rt-SwitchRoot:where(.xl\:rt-r-size-2) {
    --switch-height: calc(var(--space-5) * 5 / 6);
    --switch-border-radius: max(var(--radius-2), var(--radius-thumb));
  }

  .rt-SwitchRoot:where(.xl\:rt-r-size-3) {
    --switch-height: var(--space-5);
    --switch-border-radius: max(var(--radius-2), var(--radius-thumb));
  }
}

.rt-SwitchRoot:where(.rt-variant-surface):before {
  background-color: var(--gray-a3);
  background-image: linear-gradient(to right, var(--accent-track) 40%, transparent 60%);
  box-shadow: inset 0 0 0 1px var(--gray-a5);
}

.rt-SwitchRoot:where(.rt-variant-surface):where(:active):before {
  background-color: var(--gray-a4);
}

.rt-SwitchRoot:where(.rt-variant-surface):where([data-state="checked"]:active):before {
  filter: var(--switch-surface-checked-active-filter);
}

.rt-SwitchRoot:where(.rt-variant-surface):where(.rt-high-contrast):before {
  background-image: linear-gradient(to right, var(--switch-high-contrast-checked-color-overlay) 40%, transparent 60%), linear-gradient(to right, var(--accent-track) 40%, transparent 60%);
}

.rt-SwitchRoot:where(.rt-variant-surface):where(.rt-high-contrast):where([data-state="checked"]:active):before {
  filter: var(--switch-high-contrast-checked-active-before-filter);
}

.rt-SwitchRoot:where(.rt-variant-surface):where([data-disabled]) {
  mix-blend-mode: var(--switch-disabled-blend-mode);
}

.rt-SwitchRoot:where(.rt-variant-surface):where([data-disabled]):before {
  filter: none;
  background-image: none;
  background-color: var(--gray-a3);
  box-shadow: inset 0 0 0 1px var(--gray-a3);
}

.rt-SwitchRoot:where(.rt-variant-surface) :where(.rt-SwitchThumb):where([data-state="unchecked"]) {
  box-shadow: 0 0 1px 1px var(--black-a2), 0 1px 1px var(--black-a1), 0 2px 4px -1px var(--black-a1);
}

.rt-SwitchRoot:where(.rt-variant-surface) :where(.rt-SwitchThumb):where([data-state="checked"]) {
  box-shadow: 0 1px 3px var(--black-a2), 0 2px 4px -1px var(--black-a1), 0 0 0 1px var(--black-a1), 0 0 0 1px var(--accent-a4), -1px 0 1px var(--black-a2);
}

.rt-SwitchRoot:where(.rt-variant-surface) :where(.rt-SwitchThumb):where([data-state="checked"]):where(.rt-high-contrast) {
  box-shadow: 0 1px 3px var(--black-a2), 0 2px 4px -1px var(--black-a1), 0 0 0 1px var(--black-a2), -1px 0 1px var(--black-a2);
}

.rt-SwitchRoot:where(.rt-variant-surface) :where(.rt-SwitchThumb):where([data-disabled]) {
  background-color: var(--gray-2);
  box-shadow: 0 0 0 1px var(--gray-a2), 0 1px 3px var(--black-a1);
  transition: none;
}

.rt-SwitchRoot:where(.rt-variant-classic):before {
  background-image: linear-gradient(to right, var(--accent-track) 40%, transparent 60%);
  background-color: var(--gray-a4);
  box-shadow: var(--shadow-1);
}

.rt-SwitchRoot:where(.rt-variant-classic):where([data-state="unchecked"]:active):before {
  background-color: var(--gray-a5);
}

.rt-SwitchRoot:where(.rt-variant-classic):where([data-state="checked"]):before {
  box-shadow: inset 0 0 0 1px var(--gray-a3), inset 0 0 0 1px var(--accent-a4), inset 0 0 0 1px var(--black-a1), inset 0 1.5px 2px 0 var(--black-a2);
}

.rt-SwitchRoot:where(.rt-variant-classic):where([data-state="checked"]:active):before {
  filter: var(--switch-surface-checked-active-filter);
}

.rt-SwitchRoot:where(.rt-variant-classic):where(.rt-high-contrast):before {
  box-shadow: inset 0 0 0 1px var(--gray-a3), inset 0 0 0 1px var(--black-a2), inset 0 1.5px 2px 0 var(--black-a2);
  background-image: linear-gradient(to right, var(--switch-high-contrast-checked-color-overlay) 40%, transparent 60%), linear-gradient(to right, var(--accent-track) 40%, transparent 60%);
}

.rt-SwitchRoot:where(.rt-variant-classic):where(.rt-high-contrast):where([data-state="checked"]:active):before {
  filter: var(--switch-high-contrast-checked-active-before-filter);
}

.rt-SwitchRoot:where(.rt-variant-classic):where([data-disabled]) {
  mix-blend-mode: var(--switch-disabled-blend-mode);
}

.rt-SwitchRoot:where(.rt-variant-classic):where([data-disabled]):before {
  filter: none;
  background-image: none;
  background-color: var(--gray-a5);
  box-shadow: var(--shadow-1);
  opacity: .5;
}

.rt-SwitchRoot:where(.rt-variant-classic) :where(.rt-SwitchThumb):where([data-state="unchecked"]) {
  box-shadow: 0 1px 3px var(--black-a3), 0 2px 4px -1px var(--black-a1), 0 0 0 1px var(--black-a2);
}

.rt-SwitchRoot:where(.rt-variant-classic) :where(.rt-SwitchThumb):where([data-state="checked"]) {
  box-shadow: 0 1px 3px var(--black-a2), 0 2px 4px -1px var(--black-a1), 0 0 0 1px var(--black-a1), 0 0 0 1px var(--accent-a4), -1px 0 1px var(--black-a2);
}

.rt-SwitchRoot:where(.rt-variant-classic) :where(.rt-SwitchThumb):where([data-state="checked"]):where(.rt-high-contrast) {
  box-shadow: 0 1px 3px var(--black-a2), 0 2px 4px -1px var(--black-a1), 0 0 0 1px var(--black-a2), -1px 0 1px var(--black-a2);
}

.rt-SwitchRoot:where(.rt-variant-classic) :where(.rt-SwitchThumb):where([data-disabled]) {
  background-color: var(--gray-2);
  box-shadow: 0 0 0 1px var(--gray-a2), 0 1px 3px var(--black-a1);
  transition: none;
}

.rt-SwitchRoot:where(.rt-variant-soft):before {
  background-image: linear-gradient(to right, var(--accent-a4) 40%, transparent 60%), linear-gradient(to right, var(--accent-a4) 40%, transparent 60%), linear-gradient(to right, var(--accent-a4) 40%, var(--white-a1) 60%), linear-gradient(to right, var(--gray-a2) 40%, var(--gray-a3) 60%);
}

.rt-SwitchRoot:where(.rt-variant-soft):where([data-state="unchecked"]):before {
  background-color: var(--gray-a3);
}

.rt-SwitchRoot:where(.rt-variant-soft):where(:active):before {
  background-color: var(--gray-a4);
}

.rt-SwitchRoot:where(.rt-variant-soft):where(.rt-high-contrast):before {
  background-image: linear-gradient(to right, var(--switch-high-contrast-checked-color-overlay) 40%, transparent 60%), linear-gradient(to right, var(--accent-a6) 40%, transparent 60%), linear-gradient(to right, var(--accent-a6) 40%, transparent 60%), linear-gradient(to right, var(--accent-a6) 40%, var(--white-a1) 60%), linear-gradient(to right, var(--accent-a3) 40%, var(--gray-a3) 60%);
}

.rt-SwitchRoot:where(.rt-variant-soft):where(.rt-high-contrast):where([data-state="checked"]:active):before {
  filter: var(--switch-high-contrast-checked-active-before-filter);
}

.rt-SwitchRoot:where(.rt-variant-soft):where([data-disabled]) {
  mix-blend-mode: var(--switch-disabled-blend-mode);
}

.rt-SwitchRoot:where(.rt-variant-soft):where([data-disabled]):before {
  filter: none;
  background-image: none;
  background-color: var(--gray-a4);
}

.rt-SwitchRoot:where(.rt-variant-soft) :where(.rt-SwitchThumb) {
  filter: saturate(.45);
}

.rt-SwitchRoot:where(.rt-variant-soft) :where(.rt-SwitchThumb):where([data-state="unchecked"]) {
  box-shadow: 0 0 0 1px var(--black-a1), 0 1px 3px var(--black-a1), 0 1px 3px var(--black-a1), 0 2px 4px -1px var(--black-a1);
}

.rt-SwitchRoot:where(.rt-variant-soft) :where(.rt-SwitchThumb):where([data-state="checked"]) {
  box-shadow: 0 0 0 1px var(--black-a1), 0 1px 3px var(--black-a2), 0 1px 3px var(--accent-a3), 0 2px 4px -1px var(--accent-a3);
}

.rt-SwitchRoot:where(.rt-variant-soft) :where(.rt-SwitchThumb):where([data-disabled]) {
  filter: none;
  background-color: var(--gray-2);
  box-shadow: 0 0 0 1px var(--gray-a2), 0 1px 3px var(--black-a1);
  transition: none;
}

.rt-BaseTabList::-webkit-scrollbar {
  display: none;
}

.rt-BaseTabListTrigger {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  box-sizing: border-box;
  height: var(--tab-height);
  padding-left: var(--tab-padding-x);
  padding-right: var(--tab-padding-x);
  color: var(--gray-a11);
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.rt-BaseTabListTriggerInner, .rt-BaseTabListTriggerInnerHidden {
  box-sizing: border-box;
  padding: var(--tab-inner-padding-y) var(--tab-inner-padding-x);
  border-radius: var(--tab-inner-border-radius);
  justify-content: center;
  align-items: center;
  display: flex;
}

.rt-BaseTabListTriggerInner {
  position: absolute;
}

:where(.rt-BaseTabListTrigger[data-state="inactive"], .rt-TabNavLink:not([data-active])) .rt-BaseTabListTriggerInner {
  letter-spacing: var(--tab-inactive-letter-spacing);
  word-spacing: var(--tab-inactive-word-spacing);
}

:where(.rt-BaseTabListTrigger[data-state="active"], .rt-TabNavLink[data-active]) .rt-BaseTabListTriggerInner {
  font-weight: var(--font-weight-medium);
  letter-spacing: var(--tab-active-letter-spacing);
  word-spacing: var(--tab-active-word-spacing);
}

.rt-BaseTabListTriggerInnerHidden {
  visibility: hidden;
  font-weight: var(--font-weight-medium);
  letter-spacing: var(--tab-active-letter-spacing);
  word-spacing: var(--tab-active-word-spacing);
}

.rt-BaseTabList:where(.rt-r-size-1) {
  font-size: var(--font-size-1);
  line-height: var(--line-height-1);
  letter-spacing: var(--letter-spacing-1);
  --tab-height: var(--space-6);
  --tab-padding-x: var(--space-1);
  --tab-inner-padding-x: var(--space-1);
  --tab-inner-padding-y: calc(var(--space-1) * .5);
  --tab-inner-border-radius: var(--radius-1);
}

.rt-BaseTabList:where(.rt-r-size-2) {
  font-size: var(--font-size-2);
  line-height: var(--line-height-2);
  letter-spacing: var(--letter-spacing-2);
  --tab-height: var(--space-7);
  --tab-padding-x: var(--space-2);
  --tab-inner-padding-x: var(--space-2);
  --tab-inner-padding-y: var(--space-1);
  --tab-inner-border-radius: var(--radius-2);
}

@media (min-width: 520px) {
  .rt-BaseTabList:where(.xs\:rt-r-size-1) {
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
    --tab-height: var(--space-6);
    --tab-padding-x: var(--space-1);
    --tab-inner-padding-x: var(--space-1);
    --tab-inner-padding-y: calc(var(--space-1) * .5);
    --tab-inner-border-radius: var(--radius-1);
  }

  .rt-BaseTabList:where(.xs\:rt-r-size-2) {
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    letter-spacing: var(--letter-spacing-2);
    --tab-height: var(--space-7);
    --tab-padding-x: var(--space-2);
    --tab-inner-padding-x: var(--space-2);
    --tab-inner-padding-y: var(--space-1);
    --tab-inner-border-radius: var(--radius-2);
  }
}

@media (min-width: 768px) {
  .rt-BaseTabList:where(.sm\:rt-r-size-1) {
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
    --tab-height: var(--space-6);
    --tab-padding-x: var(--space-1);
    --tab-inner-padding-x: var(--space-1);
    --tab-inner-padding-y: calc(var(--space-1) * .5);
    --tab-inner-border-radius: var(--radius-1);
  }

  .rt-BaseTabList:where(.sm\:rt-r-size-2) {
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    letter-spacing: var(--letter-spacing-2);
    --tab-height: var(--space-7);
    --tab-padding-x: var(--space-2);
    --tab-inner-padding-x: var(--space-2);
    --tab-inner-padding-y: var(--space-1);
    --tab-inner-border-radius: var(--radius-2);
  }
}

@media (min-width: 1024px) {
  .rt-BaseTabList:where(.md\:rt-r-size-1) {
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
    --tab-height: var(--space-6);
    --tab-padding-x: var(--space-1);
    --tab-inner-padding-x: var(--space-1);
    --tab-inner-padding-y: calc(var(--space-1) * .5);
    --tab-inner-border-radius: var(--radius-1);
  }

  .rt-BaseTabList:where(.md\:rt-r-size-2) {
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    letter-spacing: var(--letter-spacing-2);
    --tab-height: var(--space-7);
    --tab-padding-x: var(--space-2);
    --tab-inner-padding-x: var(--space-2);
    --tab-inner-padding-y: var(--space-1);
    --tab-inner-border-radius: var(--radius-2);
  }
}

@media (min-width: 1280px) {
  .rt-BaseTabList:where(.lg\:rt-r-size-1) {
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
    --tab-height: var(--space-6);
    --tab-padding-x: var(--space-1);
    --tab-inner-padding-x: var(--space-1);
    --tab-inner-padding-y: calc(var(--space-1) * .5);
    --tab-inner-border-radius: var(--radius-1);
  }

  .rt-BaseTabList:where(.lg\:rt-r-size-2) {
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    letter-spacing: var(--letter-spacing-2);
    --tab-height: var(--space-7);
    --tab-padding-x: var(--space-2);
    --tab-inner-padding-x: var(--space-2);
    --tab-inner-padding-y: var(--space-1);
    --tab-inner-border-radius: var(--radius-2);
  }
}

@media (min-width: 1640px) {
  .rt-BaseTabList:where(.xl\:rt-r-size-1) {
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
    --tab-height: var(--space-6);
    --tab-padding-x: var(--space-1);
    --tab-inner-padding-x: var(--space-1);
    --tab-inner-padding-y: calc(var(--space-1) * .5);
    --tab-inner-border-radius: var(--radius-1);
  }

  .rt-BaseTabList:where(.xl\:rt-r-size-2) {
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    letter-spacing: var(--letter-spacing-2);
    --tab-height: var(--space-7);
    --tab-padding-x: var(--space-2);
    --tab-inner-padding-x: var(--space-2);
    --tab-inner-padding-y: var(--space-1);
    --tab-inner-border-radius: var(--radius-2);
  }
}

.rt-BaseTabList {
  box-shadow: inset 0 -1px 0 0 var(--gray-a5);
  white-space: nowrap;
  font-family: var(--default-font-family);
  scrollbar-width: none;
  justify-content: flex-start;
  font-style: normal;
  display: flex;
  overflow-x: auto;
}

@media (hover: hover) {
  .rt-BaseTabListTrigger:where(:hover) {
    color: var(--gray-12);
  }

  .rt-BaseTabListTrigger:where(:hover) :where(.rt-BaseTabListTriggerInner) {
    background-color: var(--gray-a3);
  }

  .rt-BaseTabListTrigger:where(:focus-visible:hover) :where(.rt-BaseTabListTriggerInner) {
    background-color: var(--accent-a3);
  }
}

.rt-BaseTabListTrigger:where([data-state="active"], [data-active]) {
  color: var(--gray-12);
}

.rt-BaseTabListTrigger:where(:focus-visible) :where(.rt-BaseTabListTriggerInner) {
  outline: 2px solid var(--focus-8);
  outline-offset: -2px;
}

.rt-BaseTabListTrigger:where([data-state="active"], [data-active]):before {
  box-sizing: border-box;
  content: "";
  background-color: var(--accent-indicator);
  height: 2px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

:where(.rt-BaseTabList.rt-high-contrast) .rt-BaseTabListTrigger:where([data-state="active"], [data-active]):before {
  background-color: var(--accent-12);
}

.rt-TabNavItem {
  display: flex;
}

.rt-TableRootTable {
  --table-row-background-color: transparent;
  --table-row-box-shadow: inset 0 -1px var(--gray-a5);
  text-align: left;
  vertical-align: top;
  border-collapse: collapse;
  border-radius: calc(var(--table-border-radius)  - 1px);
  border-spacing: 0;
  box-sizing: border-box;
  width: 100%;
  height: 0;
}

.rt-TableHeader, .rt-TableBody {
  vertical-align: inherit;
}

.rt-TableRow {
  vertical-align: inherit;
  color: var(--gray-12);
}

.rt-TableCell {
  background-color: var(--table-row-background-color);
  box-shadow: var(--table-row-box-shadow);
  box-sizing: border-box;
  vertical-align: inherit;
  padding: var(--table-cell-padding);
  height: var(--table-cell-min-height);
}

.rt-Inset :where(.rt-TableCell:first-child) {
  padding-left: var(--inset-padding-left, var(--table-cell-padding));
}

.rt-Inset :where(.rt-TableCell:last-child) {
  padding-right: var(--inset-padding-right, var(--table-cell-padding));
}

.rt-TableColumnHeaderCell {
  font-weight: bold;
}

.rt-TableRowHeaderCell {
  font-weight: normal;
}

.rt-TableRoot:where(.rt-r-size-1) {
  --table-border-radius: var(--radius-3);
  --table-cell-padding: var(--space-2);
  --table-cell-min-height: calc(36px * var(--scaling));
}

.rt-TableRoot:where(.rt-r-size-1) :where(.rt-TableRootTable) {
  font-size: var(--font-size-2);
  line-height: var(--line-height-2);
}

.rt-TableRoot:where(.rt-r-size-2) {
  --table-border-radius: var(--radius-4);
  --table-cell-padding: var(--space-3);
  --table-cell-min-height: calc(44px * var(--scaling));
}

.rt-TableRoot:where(.rt-r-size-2) :where(.rt-TableRootTable) {
  font-size: var(--font-size-2);
  line-height: var(--line-height-2);
}

.rt-TableRoot:where(.rt-r-size-3) {
  --table-border-radius: var(--radius-4);
  --table-cell-padding: var(--space-3) var(--space-4);
  --table-cell-min-height: var(--space-8);
}

.rt-TableRoot:where(.rt-r-size-3) :where(.rt-TableRootTable) {
  font-size: var(--font-size-3);
  line-height: var(--line-height-3);
}

@media (min-width: 520px) {
  .rt-TableRoot:where(.xs\:rt-r-size-1) {
    --table-border-radius: var(--radius-3);
    --table-cell-padding: var(--space-2);
    --table-cell-min-height: calc(36px * var(--scaling));
  }

  .rt-TableRoot:where(.xs\:rt-r-size-1) :where(.rt-TableRootTable) {
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
  }

  .rt-TableRoot:where(.xs\:rt-r-size-2) {
    --table-border-radius: var(--radius-4);
    --table-cell-padding: var(--space-3);
    --table-cell-min-height: calc(44px * var(--scaling));
  }

  .rt-TableRoot:where(.xs\:rt-r-size-2) :where(.rt-TableRootTable) {
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
  }

  .rt-TableRoot:where(.xs\:rt-r-size-3) {
    --table-border-radius: var(--radius-4);
    --table-cell-padding: var(--space-3) var(--space-4);
    --table-cell-min-height: var(--space-8);
  }

  .rt-TableRoot:where(.xs\:rt-r-size-3) :where(.rt-TableRootTable) {
    font-size: var(--font-size-3);
    line-height: var(--line-height-3);
  }
}

@media (min-width: 768px) {
  .rt-TableRoot:where(.sm\:rt-r-size-1) {
    --table-border-radius: var(--radius-3);
    --table-cell-padding: var(--space-2);
    --table-cell-min-height: calc(36px * var(--scaling));
  }

  .rt-TableRoot:where(.sm\:rt-r-size-1) :where(.rt-TableRootTable) {
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
  }

  .rt-TableRoot:where(.sm\:rt-r-size-2) {
    --table-border-radius: var(--radius-4);
    --table-cell-padding: var(--space-3);
    --table-cell-min-height: calc(44px * var(--scaling));
  }

  .rt-TableRoot:where(.sm\:rt-r-size-2) :where(.rt-TableRootTable) {
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
  }

  .rt-TableRoot:where(.sm\:rt-r-size-3) {
    --table-border-radius: var(--radius-4);
    --table-cell-padding: var(--space-3) var(--space-4);
    --table-cell-min-height: var(--space-8);
  }

  .rt-TableRoot:where(.sm\:rt-r-size-3) :where(.rt-TableRootTable) {
    font-size: var(--font-size-3);
    line-height: var(--line-height-3);
  }
}

@media (min-width: 1024px) {
  .rt-TableRoot:where(.md\:rt-r-size-1) {
    --table-border-radius: var(--radius-3);
    --table-cell-padding: var(--space-2);
    --table-cell-min-height: calc(36px * var(--scaling));
  }

  .rt-TableRoot:where(.md\:rt-r-size-1) :where(.rt-TableRootTable) {
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
  }

  .rt-TableRoot:where(.md\:rt-r-size-2) {
    --table-border-radius: var(--radius-4);
    --table-cell-padding: var(--space-3);
    --table-cell-min-height: calc(44px * var(--scaling));
  }

  .rt-TableRoot:where(.md\:rt-r-size-2) :where(.rt-TableRootTable) {
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
  }

  .rt-TableRoot:where(.md\:rt-r-size-3) {
    --table-border-radius: var(--radius-4);
    --table-cell-padding: var(--space-3) var(--space-4);
    --table-cell-min-height: var(--space-8);
  }

  .rt-TableRoot:where(.md\:rt-r-size-3) :where(.rt-TableRootTable) {
    font-size: var(--font-size-3);
    line-height: var(--line-height-3);
  }
}

@media (min-width: 1280px) {
  .rt-TableRoot:where(.lg\:rt-r-size-1) {
    --table-border-radius: var(--radius-3);
    --table-cell-padding: var(--space-2);
    --table-cell-min-height: calc(36px * var(--scaling));
  }

  .rt-TableRoot:where(.lg\:rt-r-size-1) :where(.rt-TableRootTable) {
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
  }

  .rt-TableRoot:where(.lg\:rt-r-size-2) {
    --table-border-radius: var(--radius-4);
    --table-cell-padding: var(--space-3);
    --table-cell-min-height: calc(44px * var(--scaling));
  }

  .rt-TableRoot:where(.lg\:rt-r-size-2) :where(.rt-TableRootTable) {
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
  }

  .rt-TableRoot:where(.lg\:rt-r-size-3) {
    --table-border-radius: var(--radius-4);
    --table-cell-padding: var(--space-3) var(--space-4);
    --table-cell-min-height: var(--space-8);
  }

  .rt-TableRoot:where(.lg\:rt-r-size-3) :where(.rt-TableRootTable) {
    font-size: var(--font-size-3);
    line-height: var(--line-height-3);
  }
}

@media (min-width: 1640px) {
  .rt-TableRoot:where(.xl\:rt-r-size-1) {
    --table-border-radius: var(--radius-3);
    --table-cell-padding: var(--space-2);
    --table-cell-min-height: calc(36px * var(--scaling));
  }

  .rt-TableRoot:where(.xl\:rt-r-size-1) :where(.rt-TableRootTable) {
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
  }

  .rt-TableRoot:where(.xl\:rt-r-size-2) {
    --table-border-radius: var(--radius-4);
    --table-cell-padding: var(--space-3);
    --table-cell-min-height: calc(44px * var(--scaling));
  }

  .rt-TableRoot:where(.xl\:rt-r-size-2) :where(.rt-TableRootTable) {
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
  }

  .rt-TableRoot:where(.xl\:rt-r-size-3) {
    --table-border-radius: var(--radius-4);
    --table-cell-padding: var(--space-3) var(--space-4);
    --table-cell-min-height: var(--space-8);
  }

  .rt-TableRoot:where(.xl\:rt-r-size-3) :where(.rt-TableRootTable) {
    font-size: var(--font-size-3);
    line-height: var(--line-height-3);
  }
}

.rt-TableRoot:where(.rt-variant-surface) {
  box-sizing: border-box;
  border: 1px solid var(--gray-a5);
  border-radius: var(--table-border-radius);
  background-color: var(--color-panel);
  -webkit-backdrop-filter: var(--backdrop-filter-panel);
  backdrop-filter: var(--backdrop-filter-panel);
  background-clip: padding-box;
  position: relative;
}

@supports (box-shadow: 0 0 0 1px color-mix(in oklab, white, black)) {
  .rt-TableRoot:where(.rt-variant-surface) {
    border-color: color-mix(in oklab, var(--gray-a5), var(--gray-6));
  }
}

.rt-TableRoot:where(.rt-variant-surface) :where(.rt-TableRootTable) {
  overflow: hidden;
}

.rt-TableRoot:where(.rt-variant-surface) :where(.rt-TableRootTable) :where(.rt-TableHeader) {
  --table-row-background-color: var(--gray-a2);
}

.rt-TableRoot:where(.rt-variant-surface) :where(.rt-TableRootTable) :where(.rt-TableBody) :where(.rt-TableRow:last-child) {
  --table-row-box-shadow: none;
}

.rt-TableRoot:where(.rt-variant-ghost) {
  --scrollarea-scrollbar-horizontal-margin-left: 0;
  --scrollarea-scrollbar-horizontal-margin-right: 0;
}

.rt-TabsContent {
  outline: 0;
  position: relative;
}

.rt-TabsContent:where(:focus-visible) {
  outline: 2px solid var(--focus-8);
}

.rt-TextAreaRoot:where(:focus-within) {
  outline: 2px solid var(--focus-8);
  outline-offset: -1px;
}

.rt-TextAreaInput::-webkit-scrollbar {
  width: var(--space-3);
  height: var(--space-3);
}

.rt-TextAreaInput::-webkit-scrollbar-track {
  border: var(--space-1) solid transparent;
  border-radius: var(--space-3);
  background-clip: content-box;
}

.rt-TextAreaInput::-webkit-scrollbar-thumb {
  border: var(--space-1) solid transparent;
  border-radius: var(--space-3);
  background-clip: content-box;
}

.rt-TextAreaInput::-webkit-scrollbar-track {
  background-color: var(--gray-a3);
}

.rt-TextAreaInput::-webkit-scrollbar-thumb {
  background-color: var(--gray-a8);
}

@media (hover: hover) {
  :where(.rt-TextAreaInput:not(:disabled))::-webkit-scrollbar-thumb:hover {
    background-color: var(--gray-a9);
  }
}

.rt-TextAreaInput:where(:-webkit-autofill, [data-com-onepassword-filled]) {
  -webkit-text-fill-color: var(--gray-12);
  -webkit-background-clip: text;
  background-clip: text;
}

.rt-TextAreaInput:where(:autofill, [data-com-onepassword-filled]) {
  -webkit-text-fill-color: var(--gray-12);
  -webkit-background-clip: text;
  background-clip: text;
}

.rt-TextAreaRoot {
  box-sizing: border-box;
  padding: var(--text-area-border-width);
  font-family: var(--default-font-family);
  font-weight: var(--font-weight-regular);
  text-align: start;
  flex-direction: column;
  font-style: normal;
  display: flex;
  overflow: hidden;
}

.rt-TextAreaInput {
  padding: var(--text-area-padding-y) var(--text-area-padding-x);
  border-radius: inherit;
  resize: none;
  cursor: auto;
  flex-grow: 1;
  width: 100%;
  display: block;
}

.rt-TextAreaRoot:where(.rt-r-size-1) {
  min-height: var(--space-8);
  border-radius: var(--radius-2);
}

.rt-TextAreaRoot:where(.rt-r-size-1) :where(.rt-TextAreaInput) {
  --text-area-padding-y: calc(var(--space-1)  - var(--text-area-border-width));
  --text-area-padding-x: calc(var(--space-1) * 1.5 - var(--text-area-border-width));
  font-size: var(--font-size-1);
  line-height: var(--line-height-1);
  letter-spacing: var(--letter-spacing-1);
}

.rt-TextAreaRoot:where(.rt-r-size-2) {
  min-height: var(--space-9);
  border-radius: var(--radius-2);
}

.rt-TextAreaRoot:where(.rt-r-size-2) :where(.rt-TextAreaInput) {
  --text-area-padding-y: calc(var(--space-1) * 1.5 - var(--text-area-border-width));
  --text-area-padding-x: calc(var(--space-2)  - var(--text-area-border-width));
  font-size: var(--font-size-2);
  line-height: var(--line-height-2);
  letter-spacing: var(--letter-spacing-2);
}

.rt-TextAreaRoot:where(.rt-r-size-3) {
  border-radius: var(--radius-3);
  min-height: 80px;
}

.rt-TextAreaRoot:where(.rt-r-size-3) :where(.rt-TextAreaInput) {
  --text-area-padding-y: calc(var(--space-2)  - var(--text-area-border-width));
  --text-area-padding-x: calc(var(--space-3)  - var(--text-area-border-width));
  font-size: var(--font-size-3);
  line-height: var(--line-height-3);
  letter-spacing: var(--letter-spacing-3);
}

@media (min-width: 520px) {
  .rt-TextAreaRoot:where(.xs\:rt-r-size-1) {
    min-height: var(--space-8);
    border-radius: var(--radius-2);
  }

  .rt-TextAreaRoot:where(.xs\:rt-r-size-1) :where(.rt-TextAreaInput) {
    --text-area-padding-y: calc(var(--space-1)  - var(--text-area-border-width));
    --text-area-padding-x: calc(var(--space-1) * 1.5 - var(--text-area-border-width));
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
  }

  .rt-TextAreaRoot:where(.xs\:rt-r-size-2) {
    min-height: var(--space-9);
    border-radius: var(--radius-2);
  }

  .rt-TextAreaRoot:where(.xs\:rt-r-size-2) :where(.rt-TextAreaInput) {
    --text-area-padding-y: calc(var(--space-1) * 1.5 - var(--text-area-border-width));
    --text-area-padding-x: calc(var(--space-2)  - var(--text-area-border-width));
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    letter-spacing: var(--letter-spacing-2);
  }

  .rt-TextAreaRoot:where(.xs\:rt-r-size-3) {
    border-radius: var(--radius-3);
    min-height: 80px;
  }

  .rt-TextAreaRoot:where(.xs\:rt-r-size-3) :where(.rt-TextAreaInput) {
    --text-area-padding-y: calc(var(--space-2)  - var(--text-area-border-width));
    --text-area-padding-x: calc(var(--space-3)  - var(--text-area-border-width));
    font-size: var(--font-size-3);
    line-height: var(--line-height-3);
    letter-spacing: var(--letter-spacing-3);
  }
}

@media (min-width: 768px) {
  .rt-TextAreaRoot:where(.sm\:rt-r-size-1) {
    min-height: var(--space-8);
    border-radius: var(--radius-2);
  }

  .rt-TextAreaRoot:where(.sm\:rt-r-size-1) :where(.rt-TextAreaInput) {
    --text-area-padding-y: calc(var(--space-1)  - var(--text-area-border-width));
    --text-area-padding-x: calc(var(--space-1) * 1.5 - var(--text-area-border-width));
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
  }

  .rt-TextAreaRoot:where(.sm\:rt-r-size-2) {
    min-height: var(--space-9);
    border-radius: var(--radius-2);
  }

  .rt-TextAreaRoot:where(.sm\:rt-r-size-2) :where(.rt-TextAreaInput) {
    --text-area-padding-y: calc(var(--space-1) * 1.5 - var(--text-area-border-width));
    --text-area-padding-x: calc(var(--space-2)  - var(--text-area-border-width));
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    letter-spacing: var(--letter-spacing-2);
  }

  .rt-TextAreaRoot:where(.sm\:rt-r-size-3) {
    border-radius: var(--radius-3);
    min-height: 80px;
  }

  .rt-TextAreaRoot:where(.sm\:rt-r-size-3) :where(.rt-TextAreaInput) {
    --text-area-padding-y: calc(var(--space-2)  - var(--text-area-border-width));
    --text-area-padding-x: calc(var(--space-3)  - var(--text-area-border-width));
    font-size: var(--font-size-3);
    line-height: var(--line-height-3);
    letter-spacing: var(--letter-spacing-3);
  }
}

@media (min-width: 1024px) {
  .rt-TextAreaRoot:where(.md\:rt-r-size-1) {
    min-height: var(--space-8);
    border-radius: var(--radius-2);
  }

  .rt-TextAreaRoot:where(.md\:rt-r-size-1) :where(.rt-TextAreaInput) {
    --text-area-padding-y: calc(var(--space-1)  - var(--text-area-border-width));
    --text-area-padding-x: calc(var(--space-1) * 1.5 - var(--text-area-border-width));
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
  }

  .rt-TextAreaRoot:where(.md\:rt-r-size-2) {
    min-height: var(--space-9);
    border-radius: var(--radius-2);
  }

  .rt-TextAreaRoot:where(.md\:rt-r-size-2) :where(.rt-TextAreaInput) {
    --text-area-padding-y: calc(var(--space-1) * 1.5 - var(--text-area-border-width));
    --text-area-padding-x: calc(var(--space-2)  - var(--text-area-border-width));
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    letter-spacing: var(--letter-spacing-2);
  }

  .rt-TextAreaRoot:where(.md\:rt-r-size-3) {
    border-radius: var(--radius-3);
    min-height: 80px;
  }

  .rt-TextAreaRoot:where(.md\:rt-r-size-3) :where(.rt-TextAreaInput) {
    --text-area-padding-y: calc(var(--space-2)  - var(--text-area-border-width));
    --text-area-padding-x: calc(var(--space-3)  - var(--text-area-border-width));
    font-size: var(--font-size-3);
    line-height: var(--line-height-3);
    letter-spacing: var(--letter-spacing-3);
  }
}

@media (min-width: 1280px) {
  .rt-TextAreaRoot:where(.lg\:rt-r-size-1) {
    min-height: var(--space-8);
    border-radius: var(--radius-2);
  }

  .rt-TextAreaRoot:where(.lg\:rt-r-size-1) :where(.rt-TextAreaInput) {
    --text-area-padding-y: calc(var(--space-1)  - var(--text-area-border-width));
    --text-area-padding-x: calc(var(--space-1) * 1.5 - var(--text-area-border-width));
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
  }

  .rt-TextAreaRoot:where(.lg\:rt-r-size-2) {
    min-height: var(--space-9);
    border-radius: var(--radius-2);
  }

  .rt-TextAreaRoot:where(.lg\:rt-r-size-2) :where(.rt-TextAreaInput) {
    --text-area-padding-y: calc(var(--space-1) * 1.5 - var(--text-area-border-width));
    --text-area-padding-x: calc(var(--space-2)  - var(--text-area-border-width));
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    letter-spacing: var(--letter-spacing-2);
  }

  .rt-TextAreaRoot:where(.lg\:rt-r-size-3) {
    border-radius: var(--radius-3);
    min-height: 80px;
  }

  .rt-TextAreaRoot:where(.lg\:rt-r-size-3) :where(.rt-TextAreaInput) {
    --text-area-padding-y: calc(var(--space-2)  - var(--text-area-border-width));
    --text-area-padding-x: calc(var(--space-3)  - var(--text-area-border-width));
    font-size: var(--font-size-3);
    line-height: var(--line-height-3);
    letter-spacing: var(--letter-spacing-3);
  }
}

@media (min-width: 1640px) {
  .rt-TextAreaRoot:where(.xl\:rt-r-size-1) {
    min-height: var(--space-8);
    border-radius: var(--radius-2);
  }

  .rt-TextAreaRoot:where(.xl\:rt-r-size-1) :where(.rt-TextAreaInput) {
    --text-area-padding-y: calc(var(--space-1)  - var(--text-area-border-width));
    --text-area-padding-x: calc(var(--space-1) * 1.5 - var(--text-area-border-width));
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
  }

  .rt-TextAreaRoot:where(.xl\:rt-r-size-2) {
    min-height: var(--space-9);
    border-radius: var(--radius-2);
  }

  .rt-TextAreaRoot:where(.xl\:rt-r-size-2) :where(.rt-TextAreaInput) {
    --text-area-padding-y: calc(var(--space-1) * 1.5 - var(--text-area-border-width));
    --text-area-padding-x: calc(var(--space-2)  - var(--text-area-border-width));
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    letter-spacing: var(--letter-spacing-2);
  }

  .rt-TextAreaRoot:where(.xl\:rt-r-size-3) {
    border-radius: var(--radius-3);
    min-height: 80px;
  }

  .rt-TextAreaRoot:where(.xl\:rt-r-size-3) :where(.rt-TextAreaInput) {
    --text-area-padding-y: calc(var(--space-2)  - var(--text-area-border-width));
    --text-area-padding-x: calc(var(--space-3)  - var(--text-area-border-width));
    font-size: var(--font-size-3);
    line-height: var(--line-height-3);
    letter-spacing: var(--letter-spacing-3);
  }
}

.rt-TextAreaRoot:where(.rt-variant-surface) {
  --text-area-border-width: 1px;
  background-clip: content-box;
  background-color: var(--color-surface);
  box-shadow: inset 0 0 0 var(--text-area-border-width) var(--gray-a7);
  color: var(--gray-12);
}

.rt-TextAreaRoot:where(.rt-variant-surface) :where(.rt-TextAreaInput)::placeholder {
  color: var(--gray-a10);
}

.rt-TextAreaRoot:where(.rt-variant-surface):where(:has(.rt-TextAreaInput:where(:-webkit-autofill, [data-com-onepassword-filled]):not(:-webkit-any(:disabled, :read-only)))) {
  background-image: linear-gradient(var(--focus-a2), var(--focus-a2));
  box-shadow: inset 0 0 0 1px var(--focus-a5), inset 0 0 0 1px var(--gray-a5);
}

.rt-TextAreaRoot:where(.rt-variant-surface):where(:has(.rt-TextAreaInput:where(:autofill, [data-com-onepassword-filled]):not(:-moz-any(:disabled, :-moz-read-only)))) {
  background-image: linear-gradient(var(--focus-a2), var(--focus-a2));
  box-shadow: inset 0 0 0 1px var(--focus-a5), inset 0 0 0 1px var(--gray-a5);
}

.rt-TextAreaRoot:where(.rt-variant-surface):where(:has(.rt-TextAreaInput:where(:autofill, [data-com-onepassword-filled]):not(:is(:disabled, :read-only)))) {
  background-image: linear-gradient(var(--focus-a2), var(--focus-a2));
  box-shadow: inset 0 0 0 1px var(--focus-a5), inset 0 0 0 1px var(--gray-a5);
}

.rt-TextAreaRoot:where(.rt-variant-surface):where(:has(.rt-TextAreaInput:where(:disabled, :-moz-read-only))) {
  background-image: linear-gradient(var(--gray-a2), var(--gray-a2));
  box-shadow: inset 0 0 0 var(--text-area-border-width) var(--gray-a6);
}

.rt-TextAreaRoot:where(.rt-variant-surface):where(:has(.rt-TextAreaInput:where(:disabled, :read-only))) {
  background-image: linear-gradient(var(--gray-a2), var(--gray-a2));
  box-shadow: inset 0 0 0 var(--text-area-border-width) var(--gray-a6);
}

.rt-TextAreaRoot:where(.rt-variant-classic) {
  --text-area-border-width: 1px;
  background-clip: content-box;
  background-color: var(--color-surface);
  box-shadow: var(--shadow-1);
  color: var(--gray-12);
}

.rt-TextAreaRoot:where(.rt-variant-classic) :where(.rt-TextAreaInput)::placeholder {
  color: var(--gray-a10);
}

.rt-TextAreaRoot:where(.rt-variant-classic):where(:has(.rt-TextAreaInput:where(:-webkit-autofill, [data-com-onepassword-filled]):not(:-webkit-any(:disabled, :read-only)))) {
  background-image: linear-gradient(var(--focus-a2), var(--focus-a2));
  box-shadow: inset 0 0 0 1px var(--focus-a5), inset 0 0 0 1px var(--gray-a5);
}

.rt-TextAreaRoot:where(.rt-variant-classic):where(:has(.rt-TextAreaInput:where(:autofill, [data-com-onepassword-filled]):not(:-moz-any(:disabled, :-moz-read-only)))) {
  background-image: linear-gradient(var(--focus-a2), var(--focus-a2));
  box-shadow: inset 0 0 0 1px var(--focus-a5), inset 0 0 0 1px var(--gray-a5);
}

.rt-TextAreaRoot:where(.rt-variant-classic):where(:has(.rt-TextAreaInput:where(:autofill, [data-com-onepassword-filled]):not(:is(:disabled, :read-only)))) {
  background-image: linear-gradient(var(--focus-a2), var(--focus-a2));
  box-shadow: inset 0 0 0 1px var(--focus-a5), inset 0 0 0 1px var(--gray-a5);
}

.rt-TextAreaRoot:where(.rt-variant-classic):where(:has(.rt-TextAreaInput:where(:disabled, :-moz-read-only))) {
  background-image: linear-gradient(var(--gray-a2), var(--gray-a2));
}

.rt-TextAreaRoot:where(.rt-variant-classic):where(:has(.rt-TextAreaInput:where(:disabled, :read-only))) {
  background-image: linear-gradient(var(--gray-a2), var(--gray-a2));
}

.rt-TextAreaRoot:where(.rt-variant-soft) {
  --text-area-border-width: 0px;
  background-color: var(--accent-a3);
  color: var(--accent-12);
}

.rt-TextAreaRoot:where(.rt-variant-soft) :where(.rt-TextAreaInput)::selection {
  background-color: var(--accent-a5);
}

.rt-TextAreaRoot:where(.rt-variant-soft) :where(.rt-TextAreaInput)::placeholder {
  color: var(--accent-12);
  opacity: .65;
}

.rt-TextAreaRoot:where(.rt-variant-soft):where(:focus-within) {
  outline-color: var(--accent-8);
}

.rt-TextAreaRoot:where(.rt-variant-soft):where(:has(.rt-TextAreaInput:where(:-webkit-autofill, [data-com-onepassword-filled]):not(:-webkit-any(:disabled, :read-only)))) {
  box-shadow: inset 0 0 0 1px var(--accent-a5), inset 0 0 0 1px var(--gray-a4);
}

.rt-TextAreaRoot:where(.rt-variant-soft):where(:has(.rt-TextAreaInput:where(:autofill, [data-com-onepassword-filled]):not(:-moz-any(:disabled, :-moz-read-only)))) {
  box-shadow: inset 0 0 0 1px var(--accent-a5), inset 0 0 0 1px var(--gray-a4);
}

.rt-TextAreaRoot:where(.rt-variant-soft):where(:has(.rt-TextAreaInput:where(:autofill, [data-com-onepassword-filled]):not(:is(:disabled, :read-only)))) {
  box-shadow: inset 0 0 0 1px var(--accent-a5), inset 0 0 0 1px var(--gray-a4);
}

.rt-TextAreaRoot:where(.rt-variant-soft):where(:has(.rt-TextAreaInput:where(:disabled, :-moz-read-only))) {
  background-color: var(--gray-a3);
}

.rt-TextAreaRoot:where(.rt-variant-soft):where(:has(.rt-TextAreaInput:where(:disabled, :read-only))) {
  background-color: var(--gray-a3);
}

.rt-TextAreaInput:where(:disabled, :-moz-read-only) {
  cursor: text;
  color: var(--gray-a11);
  -webkit-text-fill-color: var(--gray-a11);
}

.rt-TextAreaInput:where(:disabled, :read-only) {
  cursor: text;
  color: var(--gray-a11);
  -webkit-text-fill-color: var(--gray-a11);
}

.rt-TextAreaInput:where(:disabled, :-moz-read-only)::placeholder {
  opacity: .5;
}

.rt-TextAreaInput:where(:disabled, :read-only)::placeholder {
  opacity: .5;
}

.rt-TextAreaInput:where(:disabled, :-moz-read-only):where(:placeholder-shown) {
  cursor: var(--cursor-disabled);
}

.rt-TextAreaInput:where(:disabled, :read-only):where(:placeholder-shown) {
  cursor: var(--cursor-disabled);
}

.rt-TextAreaInput:where(:disabled, :-moz-read-only)::selection {
  background-color: var(--gray-a5);
}

.rt-TextAreaInput:where(:disabled, :read-only)::selection {
  background-color: var(--gray-a5);
}

.rt-TextAreaRoot:where(:focus-within:has(.rt-TextAreaInput:where(:disabled, :-moz-read-only))) {
  outline-color: var(--gray-8);
}

.rt-TextAreaRoot:where(:focus-within:has(.rt-TextAreaInput:where(:disabled, :read-only))) {
  outline-color: var(--gray-8);
}

@supports selector(:has(*)) {
  .rt-TextFieldRoot:where(:has(.rt-TextFieldInput:focus)) {
    outline: 2px solid var(--text-field-focus-color);
    outline-offset: -1px;
  }
}

@supports not selector(:has(*)) {
  .rt-TextFieldRoot:where(:focus-within) {
    outline: 2px solid var(--text-field-focus-color);
    outline-offset: -1px;
  }
}

.rt-TextFieldRoot::selection {
  background-color: var(--text-field-selection-color);
}

.rt-TextFieldInput {
  width: 100%;
  text-align: inherit;
  border-radius: calc(var(--text-field-border-radius)  - var(--text-field-border-width));
  text-indent: var(--text-field-padding);
  align-items: center;
  display: flex;
}

.rt-TextFieldInput:where([type="number"]) {
  -moz-appearance: textfield;
}

.rt-TextFieldInput::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.rt-TextFieldInput::-webkit-search-cancel-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.rt-TextFieldInput::selection {
  background-color: var(--text-field-selection-color);
}

.rt-TextFieldInput::-webkit-calendar-picker-indicator {
  box-sizing: content-box;
  width: var(--text-field-native-icon-size);
  height: var(--text-field-native-icon-size);
  padding: var(--space-1);
  margin-left: 0;
  margin-right: calc(var(--space-1) * -1);
  border-radius: calc(var(--text-field-border-radius)  - 2px);
}

.rt-TextFieldInput:where(:not([type="time"]))::-webkit-calendar-picker-indicator {
  margin-left: var(--space-1);
}

.rt-TextFieldInput::-webkit-calendar-picker-indicator:where(:hover) {
  background-color: var(--gray-a3);
}

.rt-TextFieldInput::-webkit-calendar-picker-indicator:where(:focus-visible) {
  outline: 2px solid var(--text-field-focus-color);
}

.rt-TextFieldInput::-webkit-datetime-edit-ampm-field:where(:focus) {
  background-color: var(--text-field-selection-color);
  color: inherit;
  outline: none;
}

.rt-TextFieldInput::-webkit-datetime-edit-day-field:where(:focus) {
  background-color: var(--text-field-selection-color);
  color: inherit;
  outline: none;
}

.rt-TextFieldInput::-webkit-datetime-edit-hour-field:where(:focus) {
  background-color: var(--text-field-selection-color);
  color: inherit;
  outline: none;
}

.rt-TextFieldInput::-webkit-datetime-edit-millisecond-field:where(:focus) {
  background-color: var(--text-field-selection-color);
  color: inherit;
  outline: none;
}

.rt-TextFieldInput::-webkit-datetime-edit-minute-field:where(:focus) {
  background-color: var(--text-field-selection-color);
  color: inherit;
  outline: none;
}

.rt-TextFieldInput::-webkit-datetime-edit-month-field:where(:focus) {
  background-color: var(--text-field-selection-color);
  color: inherit;
  outline: none;
}

.rt-TextFieldInput::-webkit-datetime-edit-second-field:where(:focus) {
  background-color: var(--text-field-selection-color);
  color: inherit;
  outline: none;
}

.rt-TextFieldInput::-webkit-datetime-edit-week-field:where(:focus) {
  background-color: var(--text-field-selection-color);
  color: inherit;
  outline: none;
}

.rt-TextFieldInput::-webkit-datetime-edit-year-field:where(:focus) {
  background-color: var(--text-field-selection-color);
  color: inherit;
  outline: none;
}

@supports selector(:has(*)) {
  .rt-TextFieldInput:where(:-webkit-autofill, [data-com-onepassword-filled]) {
    -webkit-text-fill-color: var(--gray-12);
    -webkit-background-clip: text;
    background-clip: text;
  }

  .rt-TextFieldInput:where(:autofill, [data-com-onepassword-filled]) {
    -webkit-text-fill-color: var(--gray-12);
    -webkit-background-clip: text;
    background-clip: text;
  }
}

.rt-TextFieldSlot {
  box-sizing: border-box;
  cursor: text;
  flex-shrink: 0;
  align-items: center;
  display: flex;
}

.rt-TextFieldSlot:where(:not([data-side="right"])) {
  margin-left: calc(var(--text-field-border-width) * -1);
  order: -1;
  margin-right: 0;
}

.rt-TextFieldSlot:where([data-side="right"]) {
  margin-left: 0;
  margin-right: calc(var(--text-field-border-width) * -1);
  order: 0;
}

:where(.rt-TextFieldSlot:not([data-side="right"])) ~ .rt-TextFieldSlot:where(:not([data-side="left"])) {
  margin-left: 0;
  margin-right: calc(var(--text-field-border-width) * -1);
  order: 0;
}

.rt-TextFieldRoot {
  box-sizing: border-box;
  height: var(--text-field-height);
  padding: var(--text-field-border-width);
  border-radius: var(--text-field-border-radius);
  font-family: var(--default-font-family);
  font-weight: var(--font-weight-regular);
  text-align: start;
  align-items: stretch;
  font-style: normal;
  display: flex;
}

.rt-TextFieldInput:where([type="date"], [type="datetime-local"], [type="time"], [type="week"], [type="month"]) {
  text-indent: 0;
  padding-left: var(--text-field-padding);
  padding-right: var(--text-field-padding);
}

.rt-TextFieldInput:where(:has( ~ .rt-TextFieldSlot:not([data-side="right"]))) {
  text-indent: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding-left: 0;
}

.rt-TextFieldInput:where(:has( ~ .rt-TextFieldSlot[data-side="right"],  ~ .rt-TextFieldSlot:not([data-side="right"]) ~ .rt-TextFieldSlot:not([data-side="left"]))) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  padding-right: 0;
}

.rt-TextFieldRoot:where(.rt-r-size-1) {
  --text-field-height: var(--space-5);
  --text-field-padding: calc(var(--space-1) * 1.5 - var(--text-field-border-width));
  --text-field-border-radius: max(var(--radius-2), var(--radius-full));
  --text-field-native-icon-size: var(--space-3);
  font-size: var(--font-size-1);
  letter-spacing: var(--letter-spacing-1);
}

.rt-TextFieldRoot:where(.rt-r-size-1) :where(.rt-TextFieldSlot) {
  gap: var(--space-2);
  padding-left: var(--space-1);
  padding-right: var(--space-1);
}

.rt-TextFieldRoot:where(.rt-r-size-1) :where(.rt-TextFieldInput) {
  padding-bottom: 0;
}

.rt-TextFieldRoot:where(.rt-r-size-1) :where(.rt-TextFieldInput)::-webkit-textfield-decoration-container {
  margin-right: -2px;
  padding-right: 0;
}

.rt-TextFieldRoot:where(.rt-r-size-2) {
  --text-field-height: var(--space-6);
  --text-field-padding: calc(var(--space-2)  - var(--text-field-border-width));
  --text-field-border-radius: max(var(--radius-2), var(--radius-full));
  --text-field-native-icon-size: var(--space-4);
  font-size: var(--font-size-2);
  letter-spacing: var(--letter-spacing-2);
}

.rt-TextFieldRoot:where(.rt-r-size-2) :where(.rt-TextFieldInput) {
  padding-bottom: .5px;
}

.rt-TextFieldRoot:where(.rt-r-size-2) :where(.rt-TextFieldInput)::-webkit-textfield-decoration-container {
  margin-right: 0;
  padding-right: 2px;
}

.rt-TextFieldRoot:where(.rt-r-size-2) :where(.rt-TextFieldSlot) {
  gap: var(--space-2);
  padding-left: var(--space-2);
  padding-right: var(--space-2);
}

.rt-TextFieldRoot:where(.rt-r-size-3) {
  --text-field-height: var(--space-7);
  --text-field-padding: calc(var(--space-3)  - var(--text-field-border-width));
  --text-field-border-radius: max(var(--radius-3), var(--radius-full));
  --text-field-native-icon-size: var(--space-4);
  font-size: var(--font-size-3);
  letter-spacing: var(--letter-spacing-3);
}

.rt-TextFieldRoot:where(.rt-r-size-3) :where(.rt-TextFieldInput) {
  padding-bottom: 0;
}

.rt-TextFieldRoot:where(.rt-r-size-3) :where(.rt-TextFieldInput)::-webkit-textfield-decoration-container {
  margin-right: 0;
  padding-right: 5px;
}

.rt-TextFieldRoot:where(.rt-r-size-3) :where(.rt-TextFieldSlot) {
  gap: var(--space-3);
  padding-left: var(--space-3);
  padding-right: var(--space-3);
}

@media (min-width: 520px) {
  .rt-TextFieldRoot:where(.xs\:rt-r-size-1) {
    --text-field-height: var(--space-5);
    --text-field-padding: calc(var(--space-1) * 1.5 - var(--text-field-border-width));
    --text-field-border-radius: max(var(--radius-2), var(--radius-full));
    --text-field-native-icon-size: var(--space-3);
    font-size: var(--font-size-1);
    letter-spacing: var(--letter-spacing-1);
  }

  .rt-TextFieldRoot:where(.xs\:rt-r-size-1) :where(.rt-TextFieldSlot) {
    gap: var(--space-2);
    padding-left: var(--space-1);
    padding-right: var(--space-1);
  }

  .rt-TextFieldRoot:where(.xs\:rt-r-size-1) :where(.rt-TextFieldInput) {
    padding-bottom: 0;
  }

  .rt-TextFieldRoot:where(.xs\:rt-r-size-1) :where(.rt-TextFieldInput)::-webkit-textfield-decoration-container {
    margin-right: -2px;
    padding-right: 0;
  }

  .rt-TextFieldRoot:where(.xs\:rt-r-size-2) {
    --text-field-height: var(--space-6);
    --text-field-padding: calc(var(--space-2)  - var(--text-field-border-width));
    --text-field-border-radius: max(var(--radius-2), var(--radius-full));
    --text-field-native-icon-size: var(--space-4);
    font-size: var(--font-size-2);
    letter-spacing: var(--letter-spacing-2);
  }

  .rt-TextFieldRoot:where(.xs\:rt-r-size-2) :where(.rt-TextFieldInput) {
    padding-bottom: .5px;
  }

  .rt-TextFieldRoot:where(.xs\:rt-r-size-2) :where(.rt-TextFieldInput)::-webkit-textfield-decoration-container {
    margin-right: 0;
    padding-right: 2px;
  }

  .rt-TextFieldRoot:where(.xs\:rt-r-size-2) :where(.rt-TextFieldSlot) {
    gap: var(--space-2);
    padding-left: var(--space-2);
    padding-right: var(--space-2);
  }

  .rt-TextFieldRoot:where(.xs\:rt-r-size-3) {
    --text-field-height: var(--space-7);
    --text-field-padding: calc(var(--space-3)  - var(--text-field-border-width));
    --text-field-border-radius: max(var(--radius-3), var(--radius-full));
    --text-field-native-icon-size: var(--space-4);
    font-size: var(--font-size-3);
    letter-spacing: var(--letter-spacing-3);
  }

  .rt-TextFieldRoot:where(.xs\:rt-r-size-3) :where(.rt-TextFieldInput) {
    padding-bottom: 0;
  }

  .rt-TextFieldRoot:where(.xs\:rt-r-size-3) :where(.rt-TextFieldInput)::-webkit-textfield-decoration-container {
    margin-right: 0;
    padding-right: 5px;
  }

  .rt-TextFieldRoot:where(.xs\:rt-r-size-3) :where(.rt-TextFieldSlot) {
    gap: var(--space-3);
    padding-left: var(--space-3);
    padding-right: var(--space-3);
  }
}

@media (min-width: 768px) {
  .rt-TextFieldRoot:where(.sm\:rt-r-size-1) {
    --text-field-height: var(--space-5);
    --text-field-padding: calc(var(--space-1) * 1.5 - var(--text-field-border-width));
    --text-field-border-radius: max(var(--radius-2), var(--radius-full));
    --text-field-native-icon-size: var(--space-3);
    font-size: var(--font-size-1);
    letter-spacing: var(--letter-spacing-1);
  }

  .rt-TextFieldRoot:where(.sm\:rt-r-size-1) :where(.rt-TextFieldSlot) {
    gap: var(--space-2);
    padding-left: var(--space-1);
    padding-right: var(--space-1);
  }

  .rt-TextFieldRoot:where(.sm\:rt-r-size-1) :where(.rt-TextFieldInput) {
    padding-bottom: 0;
  }

  .rt-TextFieldRoot:where(.sm\:rt-r-size-1) :where(.rt-TextFieldInput)::-webkit-textfield-decoration-container {
    margin-right: -2px;
    padding-right: 0;
  }

  .rt-TextFieldRoot:where(.sm\:rt-r-size-2) {
    --text-field-height: var(--space-6);
    --text-field-padding: calc(var(--space-2)  - var(--text-field-border-width));
    --text-field-border-radius: max(var(--radius-2), var(--radius-full));
    --text-field-native-icon-size: var(--space-4);
    font-size: var(--font-size-2);
    letter-spacing: var(--letter-spacing-2);
  }

  .rt-TextFieldRoot:where(.sm\:rt-r-size-2) :where(.rt-TextFieldInput) {
    padding-bottom: .5px;
  }

  .rt-TextFieldRoot:where(.sm\:rt-r-size-2) :where(.rt-TextFieldInput)::-webkit-textfield-decoration-container {
    margin-right: 0;
    padding-right: 2px;
  }

  .rt-TextFieldRoot:where(.sm\:rt-r-size-2) :where(.rt-TextFieldSlot) {
    gap: var(--space-2);
    padding-left: var(--space-2);
    padding-right: var(--space-2);
  }

  .rt-TextFieldRoot:where(.sm\:rt-r-size-3) {
    --text-field-height: var(--space-7);
    --text-field-padding: calc(var(--space-3)  - var(--text-field-border-width));
    --text-field-border-radius: max(var(--radius-3), var(--radius-full));
    --text-field-native-icon-size: var(--space-4);
    font-size: var(--font-size-3);
    letter-spacing: var(--letter-spacing-3);
  }

  .rt-TextFieldRoot:where(.sm\:rt-r-size-3) :where(.rt-TextFieldInput) {
    padding-bottom: 0;
  }

  .rt-TextFieldRoot:where(.sm\:rt-r-size-3) :where(.rt-TextFieldInput)::-webkit-textfield-decoration-container {
    margin-right: 0;
    padding-right: 5px;
  }

  .rt-TextFieldRoot:where(.sm\:rt-r-size-3) :where(.rt-TextFieldSlot) {
    gap: var(--space-3);
    padding-left: var(--space-3);
    padding-right: var(--space-3);
  }
}

@media (min-width: 1024px) {
  .rt-TextFieldRoot:where(.md\:rt-r-size-1) {
    --text-field-height: var(--space-5);
    --text-field-padding: calc(var(--space-1) * 1.5 - var(--text-field-border-width));
    --text-field-border-radius: max(var(--radius-2), var(--radius-full));
    --text-field-native-icon-size: var(--space-3);
    font-size: var(--font-size-1);
    letter-spacing: var(--letter-spacing-1);
  }

  .rt-TextFieldRoot:where(.md\:rt-r-size-1) :where(.rt-TextFieldSlot) {
    gap: var(--space-2);
    padding-left: var(--space-1);
    padding-right: var(--space-1);
  }

  .rt-TextFieldRoot:where(.md\:rt-r-size-1) :where(.rt-TextFieldInput) {
    padding-bottom: 0;
  }

  .rt-TextFieldRoot:where(.md\:rt-r-size-1) :where(.rt-TextFieldInput)::-webkit-textfield-decoration-container {
    margin-right: -2px;
    padding-right: 0;
  }

  .rt-TextFieldRoot:where(.md\:rt-r-size-2) {
    --text-field-height: var(--space-6);
    --text-field-padding: calc(var(--space-2)  - var(--text-field-border-width));
    --text-field-border-radius: max(var(--radius-2), var(--radius-full));
    --text-field-native-icon-size: var(--space-4);
    font-size: var(--font-size-2);
    letter-spacing: var(--letter-spacing-2);
  }

  .rt-TextFieldRoot:where(.md\:rt-r-size-2) :where(.rt-TextFieldInput) {
    padding-bottom: .5px;
  }

  .rt-TextFieldRoot:where(.md\:rt-r-size-2) :where(.rt-TextFieldInput)::-webkit-textfield-decoration-container {
    margin-right: 0;
    padding-right: 2px;
  }

  .rt-TextFieldRoot:where(.md\:rt-r-size-2) :where(.rt-TextFieldSlot) {
    gap: var(--space-2);
    padding-left: var(--space-2);
    padding-right: var(--space-2);
  }

  .rt-TextFieldRoot:where(.md\:rt-r-size-3) {
    --text-field-height: var(--space-7);
    --text-field-padding: calc(var(--space-3)  - var(--text-field-border-width));
    --text-field-border-radius: max(var(--radius-3), var(--radius-full));
    --text-field-native-icon-size: var(--space-4);
    font-size: var(--font-size-3);
    letter-spacing: var(--letter-spacing-3);
  }

  .rt-TextFieldRoot:where(.md\:rt-r-size-3) :where(.rt-TextFieldInput) {
    padding-bottom: 0;
  }

  .rt-TextFieldRoot:where(.md\:rt-r-size-3) :where(.rt-TextFieldInput)::-webkit-textfield-decoration-container {
    margin-right: 0;
    padding-right: 5px;
  }

  .rt-TextFieldRoot:where(.md\:rt-r-size-3) :where(.rt-TextFieldSlot) {
    gap: var(--space-3);
    padding-left: var(--space-3);
    padding-right: var(--space-3);
  }
}

@media (min-width: 1280px) {
  .rt-TextFieldRoot:where(.lg\:rt-r-size-1) {
    --text-field-height: var(--space-5);
    --text-field-padding: calc(var(--space-1) * 1.5 - var(--text-field-border-width));
    --text-field-border-radius: max(var(--radius-2), var(--radius-full));
    --text-field-native-icon-size: var(--space-3);
    font-size: var(--font-size-1);
    letter-spacing: var(--letter-spacing-1);
  }

  .rt-TextFieldRoot:where(.lg\:rt-r-size-1) :where(.rt-TextFieldSlot) {
    gap: var(--space-2);
    padding-left: var(--space-1);
    padding-right: var(--space-1);
  }

  .rt-TextFieldRoot:where(.lg\:rt-r-size-1) :where(.rt-TextFieldInput) {
    padding-bottom: 0;
  }

  .rt-TextFieldRoot:where(.lg\:rt-r-size-1) :where(.rt-TextFieldInput)::-webkit-textfield-decoration-container {
    margin-right: -2px;
    padding-right: 0;
  }

  .rt-TextFieldRoot:where(.lg\:rt-r-size-2) {
    --text-field-height: var(--space-6);
    --text-field-padding: calc(var(--space-2)  - var(--text-field-border-width));
    --text-field-border-radius: max(var(--radius-2), var(--radius-full));
    --text-field-native-icon-size: var(--space-4);
    font-size: var(--font-size-2);
    letter-spacing: var(--letter-spacing-2);
  }

  .rt-TextFieldRoot:where(.lg\:rt-r-size-2) :where(.rt-TextFieldInput) {
    padding-bottom: .5px;
  }

  .rt-TextFieldRoot:where(.lg\:rt-r-size-2) :where(.rt-TextFieldInput)::-webkit-textfield-decoration-container {
    margin-right: 0;
    padding-right: 2px;
  }

  .rt-TextFieldRoot:where(.lg\:rt-r-size-2) :where(.rt-TextFieldSlot) {
    gap: var(--space-2);
    padding-left: var(--space-2);
    padding-right: var(--space-2);
  }

  .rt-TextFieldRoot:where(.lg\:rt-r-size-3) {
    --text-field-height: var(--space-7);
    --text-field-padding: calc(var(--space-3)  - var(--text-field-border-width));
    --text-field-border-radius: max(var(--radius-3), var(--radius-full));
    --text-field-native-icon-size: var(--space-4);
    font-size: var(--font-size-3);
    letter-spacing: var(--letter-spacing-3);
  }

  .rt-TextFieldRoot:where(.lg\:rt-r-size-3) :where(.rt-TextFieldInput) {
    padding-bottom: 0;
  }

  .rt-TextFieldRoot:where(.lg\:rt-r-size-3) :where(.rt-TextFieldInput)::-webkit-textfield-decoration-container {
    margin-right: 0;
    padding-right: 5px;
  }

  .rt-TextFieldRoot:where(.lg\:rt-r-size-3) :where(.rt-TextFieldSlot) {
    gap: var(--space-3);
    padding-left: var(--space-3);
    padding-right: var(--space-3);
  }
}

@media (min-width: 1640px) {
  .rt-TextFieldRoot:where(.xl\:rt-r-size-1) {
    --text-field-height: var(--space-5);
    --text-field-padding: calc(var(--space-1) * 1.5 - var(--text-field-border-width));
    --text-field-border-radius: max(var(--radius-2), var(--radius-full));
    --text-field-native-icon-size: var(--space-3);
    font-size: var(--font-size-1);
    letter-spacing: var(--letter-spacing-1);
  }

  .rt-TextFieldRoot:where(.xl\:rt-r-size-1) :where(.rt-TextFieldSlot) {
    gap: var(--space-2);
    padding-left: var(--space-1);
    padding-right: var(--space-1);
  }

  .rt-TextFieldRoot:where(.xl\:rt-r-size-1) :where(.rt-TextFieldInput) {
    padding-bottom: 0;
  }

  .rt-TextFieldRoot:where(.xl\:rt-r-size-1) :where(.rt-TextFieldInput)::-webkit-textfield-decoration-container {
    margin-right: -2px;
    padding-right: 0;
  }

  .rt-TextFieldRoot:where(.xl\:rt-r-size-2) {
    --text-field-height: var(--space-6);
    --text-field-padding: calc(var(--space-2)  - var(--text-field-border-width));
    --text-field-border-radius: max(var(--radius-2), var(--radius-full));
    --text-field-native-icon-size: var(--space-4);
    font-size: var(--font-size-2);
    letter-spacing: var(--letter-spacing-2);
  }

  .rt-TextFieldRoot:where(.xl\:rt-r-size-2) :where(.rt-TextFieldInput) {
    padding-bottom: .5px;
  }

  .rt-TextFieldRoot:where(.xl\:rt-r-size-2) :where(.rt-TextFieldInput)::-webkit-textfield-decoration-container {
    margin-right: 0;
    padding-right: 2px;
  }

  .rt-TextFieldRoot:where(.xl\:rt-r-size-2) :where(.rt-TextFieldSlot) {
    gap: var(--space-2);
    padding-left: var(--space-2);
    padding-right: var(--space-2);
  }

  .rt-TextFieldRoot:where(.xl\:rt-r-size-3) {
    --text-field-height: var(--space-7);
    --text-field-padding: calc(var(--space-3)  - var(--text-field-border-width));
    --text-field-border-radius: max(var(--radius-3), var(--radius-full));
    --text-field-native-icon-size: var(--space-4);
    font-size: var(--font-size-3);
    letter-spacing: var(--letter-spacing-3);
  }

  .rt-TextFieldRoot:where(.xl\:rt-r-size-3) :where(.rt-TextFieldInput) {
    padding-bottom: 0;
  }

  .rt-TextFieldRoot:where(.xl\:rt-r-size-3) :where(.rt-TextFieldInput)::-webkit-textfield-decoration-container {
    margin-right: 0;
    padding-right: 5px;
  }

  .rt-TextFieldRoot:where(.xl\:rt-r-size-3) :where(.rt-TextFieldSlot) {
    gap: var(--space-3);
    padding-left: var(--space-3);
    padding-right: var(--space-3);
  }
}

.rt-TextFieldRoot:where(.rt-variant-surface) {
  --text-field-selection-color: var(--focus-a5);
  --text-field-focus-color: var(--focus-8);
  --text-field-border-width: 1px;
  background-clip: content-box;
  background-color: var(--color-surface);
  box-shadow: inset 0 0 0 var(--text-field-border-width) var(--gray-a7);
  color: var(--gray-12);
}

.rt-TextFieldRoot:where(.rt-variant-surface) :where(.rt-TextFieldInput)::placeholder {
  color: var(--gray-a10);
}

.rt-TextFieldRoot:where(.rt-variant-surface) :where(.rt-TextFieldSlot) {
  color: var(--gray-a11);
}

.rt-TextFieldRoot:where(.rt-variant-surface) :where(.rt-TextFieldSlot):where([data-accent-color]) {
  color: var(--accent-a11);
}

.rt-TextFieldRoot:where(.rt-variant-surface):where(:has(.rt-TextFieldInput:where(:-webkit-autofill, [data-com-onepassword-filled]):not(:-webkit-any(:disabled, :read-only)))) {
  background-image: linear-gradient(var(--focus-a2), var(--focus-a2));
  box-shadow: inset 0 0 0 1px var(--focus-a5), inset 0 0 0 1px var(--gray-a5);
}

.rt-TextFieldRoot:where(.rt-variant-surface):where(:has(.rt-TextFieldInput:where(:autofill, [data-com-onepassword-filled]):not(:-moz-any(:disabled, :-moz-read-only)))) {
  background-image: linear-gradient(var(--focus-a2), var(--focus-a2));
  box-shadow: inset 0 0 0 1px var(--focus-a5), inset 0 0 0 1px var(--gray-a5);
}

.rt-TextFieldRoot:where(.rt-variant-surface):where(:has(.rt-TextFieldInput:where(:autofill, [data-com-onepassword-filled]):not(:is(:disabled, :read-only)))) {
  background-image: linear-gradient(var(--focus-a2), var(--focus-a2));
  box-shadow: inset 0 0 0 1px var(--focus-a5), inset 0 0 0 1px var(--gray-a5);
}

.rt-TextFieldRoot:where(.rt-variant-surface):where(:has(.rt-TextFieldInput:where(:disabled, :-moz-read-only))) {
  background-image: linear-gradient(var(--gray-a2), var(--gray-a2));
  box-shadow: inset 0 0 0 var(--text-field-border-width) var(--gray-a6);
}

.rt-TextFieldRoot:where(.rt-variant-surface):where(:has(.rt-TextFieldInput:where(:disabled, :read-only))) {
  background-image: linear-gradient(var(--gray-a2), var(--gray-a2));
  box-shadow: inset 0 0 0 var(--text-field-border-width) var(--gray-a6);
}

.rt-TextFieldRoot:where(.rt-variant-classic) {
  --text-field-selection-color: var(--focus-a5);
  --text-field-focus-color: var(--focus-8);
  --text-field-border-width: 1px;
  background-clip: content-box;
  background-color: var(--color-surface);
  box-shadow: var(--shadow-1);
  color: var(--gray-12);
}

.rt-TextFieldRoot:where(.rt-variant-classic) :where(.rt-TextFieldInput)::placeholder {
  color: var(--gray-a10);
}

.rt-TextFieldRoot:where(.rt-variant-classic) :where(.rt-TextFieldSlot) {
  color: var(--gray-a11);
}

.rt-TextFieldRoot:where(.rt-variant-classic) :where(.rt-TextFieldSlot):where([data-accent-color]) {
  color: var(--accent-a11);
}

.rt-TextFieldRoot:where(.rt-variant-classic):where(:has(.rt-TextFieldInput:where(:-webkit-autofill, [data-com-onepassword-filled]):not(:-webkit-any(:disabled, :read-only)))) {
  background-image: linear-gradient(var(--focus-a2), var(--focus-a2));
  box-shadow: inset 0 0 0 1px var(--focus-a5), inset 0 0 0 1px var(--gray-a5);
}

.rt-TextFieldRoot:where(.rt-variant-classic):where(:has(.rt-TextFieldInput:where(:autofill, [data-com-onepassword-filled]):not(:-moz-any(:disabled, :-moz-read-only)))) {
  background-image: linear-gradient(var(--focus-a2), var(--focus-a2));
  box-shadow: inset 0 0 0 1px var(--focus-a5), inset 0 0 0 1px var(--gray-a5);
}

.rt-TextFieldRoot:where(.rt-variant-classic):where(:has(.rt-TextFieldInput:where(:autofill, [data-com-onepassword-filled]):not(:is(:disabled, :read-only)))) {
  background-image: linear-gradient(var(--focus-a2), var(--focus-a2));
  box-shadow: inset 0 0 0 1px var(--focus-a5), inset 0 0 0 1px var(--gray-a5);
}

.rt-TextFieldRoot:where(.rt-variant-classic):where(:has(.rt-TextFieldInput:where(:disabled, :-moz-read-only))) {
  background-image: linear-gradient(var(--gray-a2), var(--gray-a2));
}

.rt-TextFieldRoot:where(.rt-variant-classic):where(:has(.rt-TextFieldInput:where(:disabled, :read-only))) {
  background-image: linear-gradient(var(--gray-a2), var(--gray-a2));
}

.rt-TextFieldRoot:where(.rt-variant-soft) {
  --text-field-selection-color: var(--accent-a5);
  --text-field-focus-color: var(--accent-8);
  --text-field-border-width: 0px;
  background-color: var(--accent-a3);
  color: var(--accent-12);
}

.rt-TextFieldRoot:where(.rt-variant-soft) :where(.rt-TextFieldInput)::placeholder {
  color: var(--accent-12);
  opacity: .6;
}

.rt-TextFieldRoot:where(.rt-variant-soft) :where(.rt-TextFieldSlot) {
  color: var(--accent-12);
}

.rt-TextFieldRoot:where(.rt-variant-soft) :where(.rt-TextFieldSlot):where([data-accent-color]) {
  color: var(--accent-a11);
}

.rt-TextFieldRoot:where(.rt-variant-soft):where(:has(.rt-TextFieldInput:where(:-webkit-autofill, [data-com-onepassword-filled]):not(:-webkit-any(:disabled, :read-only)))) {
  box-shadow: inset 0 0 0 1px var(--accent-a5), inset 0 0 0 1px var(--gray-a4);
}

.rt-TextFieldRoot:where(.rt-variant-soft):where(:has(.rt-TextFieldInput:where(:autofill, [data-com-onepassword-filled]):not(:-moz-any(:disabled, :-moz-read-only)))) {
  box-shadow: inset 0 0 0 1px var(--accent-a5), inset 0 0 0 1px var(--gray-a4);
}

.rt-TextFieldRoot:where(.rt-variant-soft):where(:has(.rt-TextFieldInput:where(:autofill, [data-com-onepassword-filled]):not(:is(:disabled, :read-only)))) {
  box-shadow: inset 0 0 0 1px var(--accent-a5), inset 0 0 0 1px var(--gray-a4);
}

.rt-TextFieldRoot:where(.rt-variant-soft):where(:has(.rt-TextFieldInput:where(:disabled, :-moz-read-only))) {
  background-color: var(--gray-a3);
}

.rt-TextFieldRoot:where(.rt-variant-soft):where(:has(.rt-TextFieldInput:where(:disabled, :read-only))) {
  background-color: var(--gray-a3);
}

.rt-TextFieldInput:where(:disabled, :-moz-read-only) {
  cursor: text;
  color: var(--gray-a11);
  -webkit-text-fill-color: var(--gray-a11);
}

.rt-TextFieldInput:where(:disabled, :read-only) {
  cursor: text;
  color: var(--gray-a11);
  -webkit-text-fill-color: var(--gray-a11);
}

.rt-TextFieldInput:where(:disabled, :-moz-read-only)::placeholder {
  opacity: .5;
}

.rt-TextFieldInput:where(:disabled, :read-only)::placeholder {
  opacity: .5;
}

.rt-TextFieldInput:where(:disabled, :-moz-read-only):where(:placeholder-shown) {
  cursor: var(--cursor-disabled);
}

.rt-TextFieldInput:where(:disabled, :read-only):where(:placeholder-shown) {
  cursor: var(--cursor-disabled);
}

.rt-TextFieldInput:where(:disabled, :-moz-read-only):where(:placeholder-shown) ~ :where(.rt-TextFieldSlot) {
  cursor: var(--cursor-disabled);
}

.rt-TextFieldInput:where(:disabled, :read-only):where(:placeholder-shown) ~ :where(.rt-TextFieldSlot) {
  cursor: var(--cursor-disabled);
}

.rt-TextFieldRoot:where(:has(.rt-TextFieldInput:where(:disabled, :-moz-read-only))) {
  --text-field-selection-color: var(--gray-a5);
  --text-field-focus-color: var(--gray-8);
}

.rt-TextFieldRoot:where(:has(.rt-TextFieldInput:where(:disabled, :read-only))) {
  --text-field-selection-color: var(--gray-a5);
  --text-field-focus-color: var(--gray-8);
}

.rt-ThemePanelShortcut:where(:focus-visible) {
  outline-offset: 2px;
  outline-width: 2px;
  outline-style: solid;
  outline-color: var(--accent-9);
}

.rt-ThemePanelSwatch, .rt-ThemePanelRadioCard {
  position: relative;
}

.rt-ThemePanelSwatchInput, .rt-ThemePanelRadioCardInput {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: inherit;
  outline: 2px;
  width: 100%;
  height: 100%;
  margin: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.rt-ThemePanelSwatch {
  width: var(--space-5);
  height: var(--space-5);
  border-radius: 100%;
}

.rt-ThemePanelSwatchInput {
  outline-offset: 2px;
}

.rt-ThemePanelSwatchInput:where(:checked) {
  outline-style: solid;
  outline-color: var(--gray-12);
}

.rt-ThemePanelSwatchInput:where(:focus-visible) {
  outline-style: solid;
  outline-color: var(--accent-9);
}

.rt-ThemePanelRadioCard {
  border-radius: var(--radius-1);
  box-shadow: 0 0 0 1px var(--gray-7);
}

.rt-ThemePanelRadioCardInput {
  outline-offset: -1px;
}

.rt-ThemePanelRadioCardInput:where(:checked) {
  outline-style: solid;
  outline-color: var(--gray-12);
}

.rt-ThemePanelRadioCardInput:where(:focus-visible) {
  background-color: var(--accent-a3);
  outline-style: solid;
  outline-color: var(--accent-9);
}

.rt-TooltipContent {
  box-sizing: border-box;
  padding: var(--space-1) var(--space-2);
  background-color: var(--gray-12);
  border-radius: var(--radius-2);
  transform-origin: var(--radix-tooltip-content-transform-origin);
  animation-duration: .14s;
  animation-timing-function: cubic-bezier(.16, 1, .3, 1);
}

@media (prefers-reduced-motion: no-preference) {
  .rt-TooltipContent:where([data-state="delayed-open"]):where([data-side="top"]) {
    animation-name: rt-slide-from-top, rt-fade-in;
  }

  .rt-TooltipContent:where([data-state="delayed-open"]):where([data-side="bottom"]) {
    animation-name: rt-slide-from-bottom, rt-fade-in;
  }

  .rt-TooltipContent:where([data-state="delayed-open"]):where([data-side="left"]) {
    animation-name: rt-slide-from-left, rt-fade-in;
  }

  .rt-TooltipContent:where([data-state="delayed-open"]):where([data-side="right"]) {
    animation-name: rt-slide-from-right, rt-fade-in;
  }
}

.rt-TooltipText {
  color: var(--gray-1);
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  cursor: default;
}

.rt-TooltipArrow {
  fill: var(--gray-12);
}

.radix-themes:where([data-is-root-theme="true"]) {
  z-index: 0;
  min-height: 100vh;
  position: relative;
}

@supports (min-height: 100dvh) {
  .radix-themes:where([data-is-root-theme="true"]) {
    min-height: 100dvh;
  }
}

.rt-r-ai-start {
  align-items: flex-start;
}

.rt-r-ai-center {
  align-items: center;
}

.rt-r-ai-end {
  align-items: flex-end;
}

.rt-r-ai-baseline {
  align-items: baseline;
}

.rt-r-ai-stretch {
  align-items: stretch;
}

@media (min-width: 520px) {
  .xs\:rt-r-ai-start {
    align-items: flex-start;
  }

  .xs\:rt-r-ai-center {
    align-items: center;
  }

  .xs\:rt-r-ai-end {
    align-items: flex-end;
  }

  .xs\:rt-r-ai-baseline {
    align-items: baseline;
  }

  .xs\:rt-r-ai-stretch {
    align-items: stretch;
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-ai-start {
    align-items: flex-start;
  }

  .sm\:rt-r-ai-center {
    align-items: center;
  }

  .sm\:rt-r-ai-end {
    align-items: flex-end;
  }

  .sm\:rt-r-ai-baseline {
    align-items: baseline;
  }

  .sm\:rt-r-ai-stretch {
    align-items: stretch;
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-ai-start {
    align-items: flex-start;
  }

  .md\:rt-r-ai-center {
    align-items: center;
  }

  .md\:rt-r-ai-end {
    align-items: flex-end;
  }

  .md\:rt-r-ai-baseline {
    align-items: baseline;
  }

  .md\:rt-r-ai-stretch {
    align-items: stretch;
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-ai-start {
    align-items: flex-start;
  }

  .lg\:rt-r-ai-center {
    align-items: center;
  }

  .lg\:rt-r-ai-end {
    align-items: flex-end;
  }

  .lg\:rt-r-ai-baseline {
    align-items: baseline;
  }

  .lg\:rt-r-ai-stretch {
    align-items: stretch;
  }
}

@media (min-width: 1640px) {
  .xl\:rt-r-ai-start {
    align-items: flex-start;
  }

  .xl\:rt-r-ai-center {
    align-items: center;
  }

  .xl\:rt-r-ai-end {
    align-items: flex-end;
  }

  .xl\:rt-r-ai-baseline {
    align-items: baseline;
  }

  .xl\:rt-r-ai-stretch {
    align-items: stretch;
  }
}

.rt-r-as-start {
  align-self: flex-start;
}

.rt-r-as-center {
  align-self: center;
}

.rt-r-as-end {
  align-self: flex-end;
}

.rt-r-as-baseline {
  align-self: baseline;
}

.rt-r-as-stretch {
  align-self: stretch;
}

@media (min-width: 520px) {
  .xs\:rt-r-as-start {
    align-self: flex-start;
  }

  .xs\:rt-r-as-center {
    align-self: center;
  }

  .xs\:rt-r-as-end {
    align-self: flex-end;
  }

  .xs\:rt-r-as-baseline {
    align-self: baseline;
  }

  .xs\:rt-r-as-stretch {
    align-self: stretch;
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-as-start {
    align-self: flex-start;
  }

  .sm\:rt-r-as-center {
    align-self: center;
  }

  .sm\:rt-r-as-end {
    align-self: flex-end;
  }

  .sm\:rt-r-as-baseline {
    align-self: baseline;
  }

  .sm\:rt-r-as-stretch {
    align-self: stretch;
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-as-start {
    align-self: flex-start;
  }

  .md\:rt-r-as-center {
    align-self: center;
  }

  .md\:rt-r-as-end {
    align-self: flex-end;
  }

  .md\:rt-r-as-baseline {
    align-self: baseline;
  }

  .md\:rt-r-as-stretch {
    align-self: stretch;
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-as-start {
    align-self: flex-start;
  }

  .lg\:rt-r-as-center {
    align-self: center;
  }

  .lg\:rt-r-as-end {
    align-self: flex-end;
  }

  .lg\:rt-r-as-baseline {
    align-self: baseline;
  }

  .lg\:rt-r-as-stretch {
    align-self: stretch;
  }
}

@media (min-width: 1640px) {
  .xl\:rt-r-as-start {
    align-self: flex-start;
  }

  .xl\:rt-r-as-center {
    align-self: center;
  }

  .xl\:rt-r-as-end {
    align-self: flex-end;
  }

  .xl\:rt-r-as-baseline {
    align-self: baseline;
  }

  .xl\:rt-r-as-stretch {
    align-self: stretch;
  }
}

.rt-r-display-block {
  display: block;
}

.rt-r-display-inline {
  display: inline;
}

.rt-r-display-inline-block {
  display: inline-block;
}

.rt-r-display-flex {
  display: flex;
}

.rt-r-display-inline-flex {
  display: inline-flex;
}

.rt-r-display-grid {
  display: grid;
}

.rt-r-display-inline-grid {
  display: inline-grid;
}

.rt-r-display-none {
  display: none;
}

.rt-r-display-contents {
  display: contents;
}

@media (min-width: 520px) {
  .xs\:rt-r-display-block {
    display: block;
  }

  .xs\:rt-r-display-inline {
    display: inline;
  }

  .xs\:rt-r-display-inline-block {
    display: inline-block;
  }

  .xs\:rt-r-display-flex {
    display: flex;
  }

  .xs\:rt-r-display-inline-flex {
    display: inline-flex;
  }

  .xs\:rt-r-display-grid {
    display: grid;
  }

  .xs\:rt-r-display-inline-grid {
    display: inline-grid;
  }

  .xs\:rt-r-display-none {
    display: none;
  }

  .xs\:rt-r-display-contents {
    display: contents;
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-display-block {
    display: block;
  }

  .sm\:rt-r-display-inline {
    display: inline;
  }

  .sm\:rt-r-display-inline-block {
    display: inline-block;
  }

  .sm\:rt-r-display-flex {
    display: flex;
  }

  .sm\:rt-r-display-inline-flex {
    display: inline-flex;
  }

  .sm\:rt-r-display-grid {
    display: grid;
  }

  .sm\:rt-r-display-inline-grid {
    display: inline-grid;
  }

  .sm\:rt-r-display-none {
    display: none;
  }

  .sm\:rt-r-display-contents {
    display: contents;
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-display-block {
    display: block;
  }

  .md\:rt-r-display-inline {
    display: inline;
  }

  .md\:rt-r-display-inline-block {
    display: inline-block;
  }

  .md\:rt-r-display-flex {
    display: flex;
  }

  .md\:rt-r-display-inline-flex {
    display: inline-flex;
  }

  .md\:rt-r-display-grid {
    display: grid;
  }

  .md\:rt-r-display-inline-grid {
    display: inline-grid;
  }

  .md\:rt-r-display-none {
    display: none;
  }

  .md\:rt-r-display-contents {
    display: contents;
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-display-block {
    display: block;
  }

  .lg\:rt-r-display-inline {
    display: inline;
  }

  .lg\:rt-r-display-inline-block {
    display: inline-block;
  }

  .lg\:rt-r-display-flex {
    display: flex;
  }

  .lg\:rt-r-display-inline-flex {
    display: inline-flex;
  }

  .lg\:rt-r-display-grid {
    display: grid;
  }

  .lg\:rt-r-display-inline-grid {
    display: inline-grid;
  }

  .lg\:rt-r-display-none {
    display: none;
  }

  .lg\:rt-r-display-contents {
    display: contents;
  }
}

@media (min-width: 1640px) {
  .xl\:rt-r-display-block {
    display: block;
  }

  .xl\:rt-r-display-inline {
    display: inline;
  }

  .xl\:rt-r-display-inline-block {
    display: inline-block;
  }

  .xl\:rt-r-display-flex {
    display: flex;
  }

  .xl\:rt-r-display-inline-flex {
    display: inline-flex;
  }

  .xl\:rt-r-display-grid {
    display: grid;
  }

  .xl\:rt-r-display-inline-grid {
    display: inline-grid;
  }

  .xl\:rt-r-display-none {
    display: none;
  }

  .xl\:rt-r-display-contents {
    display: contents;
  }
}

.rt-r-fb {
  flex-basis: var(--flex-basis);
}

@media (min-width: 520px) {
  .xs\:rt-r-fb {
    flex-basis: var(--flex-basis-xs);
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-fb {
    flex-basis: var(--flex-basis-sm);
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-fb {
    flex-basis: var(--flex-basis-md);
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-fb {
    flex-basis: var(--flex-basis-lg);
  }
}

@media (min-width: 1640px) {
  .xl\:rt-r-fb {
    flex-basis: var(--flex-basis-xl);
  }
}

.rt-r-fd-row {
  flex-direction: row;
}

.rt-r-fd-column {
  flex-direction: column;
}

.rt-r-fd-row-reverse {
  flex-direction: row-reverse;
}

.rt-r-fd-column-reverse {
  flex-direction: column-reverse;
}

@media (min-width: 520px) {
  .xs\:rt-r-fd-row {
    flex-direction: row;
  }

  .xs\:rt-r-fd-column {
    flex-direction: column;
  }

  .xs\:rt-r-fd-row-reverse {
    flex-direction: row-reverse;
  }

  .xs\:rt-r-fd-column-reverse {
    flex-direction: column-reverse;
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-fd-row {
    flex-direction: row;
  }

  .sm\:rt-r-fd-column {
    flex-direction: column;
  }

  .sm\:rt-r-fd-row-reverse {
    flex-direction: row-reverse;
  }

  .sm\:rt-r-fd-column-reverse {
    flex-direction: column-reverse;
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-fd-row {
    flex-direction: row;
  }

  .md\:rt-r-fd-column {
    flex-direction: column;
  }

  .md\:rt-r-fd-row-reverse {
    flex-direction: row-reverse;
  }

  .md\:rt-r-fd-column-reverse {
    flex-direction: column-reverse;
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-fd-row {
    flex-direction: row;
  }

  .lg\:rt-r-fd-column {
    flex-direction: column;
  }

  .lg\:rt-r-fd-row-reverse {
    flex-direction: row-reverse;
  }

  .lg\:rt-r-fd-column-reverse {
    flex-direction: column-reverse;
  }
}

@media (min-width: 1640px) {
  .xl\:rt-r-fd-row {
    flex-direction: row;
  }

  .xl\:rt-r-fd-column {
    flex-direction: column;
  }

  .xl\:rt-r-fd-row-reverse {
    flex-direction: row-reverse;
  }

  .xl\:rt-r-fd-column-reverse {
    flex-direction: column-reverse;
  }
}

.rt-r-fg {
  flex-grow: var(--flex-grow);
}

.rt-r-fg-0 {
  flex-grow: 0;
}

.rt-r-fg-1 {
  flex-grow: 1;
}

@media (min-width: 520px) {
  .xs\:rt-r-fg {
    flex-grow: var(--flex-grow-xs);
  }

  .xs\:rt-r-fg-0 {
    flex-grow: 0;
  }

  .xs\:rt-r-fg-1 {
    flex-grow: 1;
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-fg {
    flex-grow: var(--flex-grow-sm);
  }

  .sm\:rt-r-fg-0 {
    flex-grow: 0;
  }

  .sm\:rt-r-fg-1 {
    flex-grow: 1;
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-fg {
    flex-grow: var(--flex-grow-md);
  }

  .md\:rt-r-fg-0 {
    flex-grow: 0;
  }

  .md\:rt-r-fg-1 {
    flex-grow: 1;
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-fg {
    flex-grow: var(--flex-grow-lg);
  }

  .lg\:rt-r-fg-0 {
    flex-grow: 0;
  }

  .lg\:rt-r-fg-1 {
    flex-grow: 1;
  }
}

@media (min-width: 1640px) {
  .xl\:rt-r-fg {
    flex-grow: var(--flex-grow-xl);
  }

  .xl\:rt-r-fg-0 {
    flex-grow: 0;
  }

  .xl\:rt-r-fg-1 {
    flex-grow: 1;
  }
}

.rt-r-fs {
  flex-shrink: var(--flex-shrink);
}

.rt-r-fs-0 {
  flex-shrink: 0;
}

.rt-r-fs-1 {
  flex-shrink: 1;
}

@media (min-width: 520px) {
  .xs\:rt-r-fs {
    flex-shrink: var(--flex-shrink-xs);
  }

  .xs\:rt-r-fs-0 {
    flex-shrink: 0;
  }

  .xs\:rt-r-fs-1 {
    flex-shrink: 1;
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-fs {
    flex-shrink: var(--flex-shrink-sm);
  }

  .sm\:rt-r-fs-0 {
    flex-shrink: 0;
  }

  .sm\:rt-r-fs-1 {
    flex-shrink: 1;
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-fs {
    flex-shrink: var(--flex-shrink-md);
  }

  .md\:rt-r-fs-0 {
    flex-shrink: 0;
  }

  .md\:rt-r-fs-1 {
    flex-shrink: 1;
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-fs {
    flex-shrink: var(--flex-shrink-lg);
  }

  .lg\:rt-r-fs-0 {
    flex-shrink: 0;
  }

  .lg\:rt-r-fs-1 {
    flex-shrink: 1;
  }
}

@media (min-width: 1640px) {
  .xl\:rt-r-fs {
    flex-shrink: var(--flex-shrink-xl);
  }

  .xl\:rt-r-fs-0 {
    flex-shrink: 0;
  }

  .xl\:rt-r-fs-1 {
    flex-shrink: 1;
  }
}

.rt-r-fw-nowrap {
  flex-wrap: nowrap;
}

.rt-r-fw-wrap {
  flex-wrap: wrap;
}

.rt-r-fw-wrap-reverse {
  flex-wrap: wrap-reverse;
}

@media (min-width: 520px) {
  .xs\:rt-r-fw-nowrap {
    flex-wrap: nowrap;
  }

  .xs\:rt-r-fw-wrap {
    flex-wrap: wrap;
  }

  .xs\:rt-r-fw-wrap-reverse {
    flex-wrap: wrap-reverse;
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-fw-nowrap {
    flex-wrap: nowrap;
  }

  .sm\:rt-r-fw-wrap {
    flex-wrap: wrap;
  }

  .sm\:rt-r-fw-wrap-reverse {
    flex-wrap: wrap-reverse;
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-fw-nowrap {
    flex-wrap: nowrap;
  }

  .md\:rt-r-fw-wrap {
    flex-wrap: wrap;
  }

  .md\:rt-r-fw-wrap-reverse {
    flex-wrap: wrap-reverse;
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-fw-nowrap {
    flex-wrap: nowrap;
  }

  .lg\:rt-r-fw-wrap {
    flex-wrap: wrap;
  }

  .lg\:rt-r-fw-wrap-reverse {
    flex-wrap: wrap-reverse;
  }
}

@media (min-width: 1640px) {
  .xl\:rt-r-fw-nowrap {
    flex-wrap: nowrap;
  }

  .xl\:rt-r-fw-wrap {
    flex-wrap: wrap;
  }

  .xl\:rt-r-fw-wrap-reverse {
    flex-wrap: wrap-reverse;
  }
}

.rt-r-gap {
  gap: var(--gap);
}

.rt-r-gap-0 {
  gap: 0;
}

.rt-r-gap-1 {
  gap: var(--space-1);
}

.rt-r-gap-2 {
  gap: var(--space-2);
}

.rt-r-gap-3 {
  gap: var(--space-3);
}

.rt-r-gap-4 {
  gap: var(--space-4);
}

.rt-r-gap-5 {
  gap: var(--space-5);
}

.rt-r-gap-6 {
  gap: var(--space-6);
}

.rt-r-gap-7 {
  gap: var(--space-7);
}

.rt-r-gap-8 {
  gap: var(--space-8);
}

.rt-r-gap-9 {
  gap: var(--space-9);
}

.rt-r-cg {
  column-gap: var(--column-gap);
}

.rt-r-cg-0 {
  column-gap: 0;
}

.rt-r-cg-1 {
  column-gap: var(--space-1);
}

.rt-r-cg-2 {
  column-gap: var(--space-2);
}

.rt-r-cg-3 {
  column-gap: var(--space-3);
}

.rt-r-cg-4 {
  column-gap: var(--space-4);
}

.rt-r-cg-5 {
  column-gap: var(--space-5);
}

.rt-r-cg-6 {
  column-gap: var(--space-6);
}

.rt-r-cg-7 {
  column-gap: var(--space-7);
}

.rt-r-cg-8 {
  column-gap: var(--space-8);
}

.rt-r-cg-9 {
  column-gap: var(--space-9);
}

.rt-r-rg {
  row-gap: var(--row-gap);
}

.rt-r-rg-0 {
  row-gap: 0;
}

.rt-r-rg-1 {
  row-gap: var(--space-1);
}

.rt-r-rg-2 {
  row-gap: var(--space-2);
}

.rt-r-rg-3 {
  row-gap: var(--space-3);
}

.rt-r-rg-4 {
  row-gap: var(--space-4);
}

.rt-r-rg-5 {
  row-gap: var(--space-5);
}

.rt-r-rg-6 {
  row-gap: var(--space-6);
}

.rt-r-rg-7 {
  row-gap: var(--space-7);
}

.rt-r-rg-8 {
  row-gap: var(--space-8);
}

.rt-r-rg-9 {
  row-gap: var(--space-9);
}

@media (min-width: 520px) {
  .xs\:rt-r-gap {
    gap: var(--gap-xs);
  }

  .xs\:rt-r-gap-0 {
    gap: 0;
  }

  .xs\:rt-r-gap-1 {
    gap: var(--space-1);
  }

  .xs\:rt-r-gap-2 {
    gap: var(--space-2);
  }

  .xs\:rt-r-gap-3 {
    gap: var(--space-3);
  }

  .xs\:rt-r-gap-4 {
    gap: var(--space-4);
  }

  .xs\:rt-r-gap-5 {
    gap: var(--space-5);
  }

  .xs\:rt-r-gap-6 {
    gap: var(--space-6);
  }

  .xs\:rt-r-gap-7 {
    gap: var(--space-7);
  }

  .xs\:rt-r-gap-8 {
    gap: var(--space-8);
  }

  .xs\:rt-r-gap-9 {
    gap: var(--space-9);
  }

  .xs\:rt-r-cg {
    column-gap: var(--column-gap-xs);
  }

  .xs\:rt-r-cg-0 {
    column-gap: 0;
  }

  .xs\:rt-r-cg-1 {
    column-gap: var(--space-1);
  }

  .xs\:rt-r-cg-2 {
    column-gap: var(--space-2);
  }

  .xs\:rt-r-cg-3 {
    column-gap: var(--space-3);
  }

  .xs\:rt-r-cg-4 {
    column-gap: var(--space-4);
  }

  .xs\:rt-r-cg-5 {
    column-gap: var(--space-5);
  }

  .xs\:rt-r-cg-6 {
    column-gap: var(--space-6);
  }

  .xs\:rt-r-cg-7 {
    column-gap: var(--space-7);
  }

  .xs\:rt-r-cg-8 {
    column-gap: var(--space-8);
  }

  .xs\:rt-r-cg-9 {
    column-gap: var(--space-9);
  }

  .xs\:rt-r-rg {
    row-gap: var(--row-gap-xs);
  }

  .xs\:rt-r-rg-0 {
    row-gap: 0;
  }

  .xs\:rt-r-rg-1 {
    row-gap: var(--space-1);
  }

  .xs\:rt-r-rg-2 {
    row-gap: var(--space-2);
  }

  .xs\:rt-r-rg-3 {
    row-gap: var(--space-3);
  }

  .xs\:rt-r-rg-4 {
    row-gap: var(--space-4);
  }

  .xs\:rt-r-rg-5 {
    row-gap: var(--space-5);
  }

  .xs\:rt-r-rg-6 {
    row-gap: var(--space-6);
  }

  .xs\:rt-r-rg-7 {
    row-gap: var(--space-7);
  }

  .xs\:rt-r-rg-8 {
    row-gap: var(--space-8);
  }

  .xs\:rt-r-rg-9 {
    row-gap: var(--space-9);
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-gap {
    gap: var(--gap-sm);
  }

  .sm\:rt-r-gap-0 {
    gap: 0;
  }

  .sm\:rt-r-gap-1 {
    gap: var(--space-1);
  }

  .sm\:rt-r-gap-2 {
    gap: var(--space-2);
  }

  .sm\:rt-r-gap-3 {
    gap: var(--space-3);
  }

  .sm\:rt-r-gap-4 {
    gap: var(--space-4);
  }

  .sm\:rt-r-gap-5 {
    gap: var(--space-5);
  }

  .sm\:rt-r-gap-6 {
    gap: var(--space-6);
  }

  .sm\:rt-r-gap-7 {
    gap: var(--space-7);
  }

  .sm\:rt-r-gap-8 {
    gap: var(--space-8);
  }

  .sm\:rt-r-gap-9 {
    gap: var(--space-9);
  }

  .sm\:rt-r-cg {
    column-gap: var(--column-gap-sm);
  }

  .sm\:rt-r-cg-0 {
    column-gap: 0;
  }

  .sm\:rt-r-cg-1 {
    column-gap: var(--space-1);
  }

  .sm\:rt-r-cg-2 {
    column-gap: var(--space-2);
  }

  .sm\:rt-r-cg-3 {
    column-gap: var(--space-3);
  }

  .sm\:rt-r-cg-4 {
    column-gap: var(--space-4);
  }

  .sm\:rt-r-cg-5 {
    column-gap: var(--space-5);
  }

  .sm\:rt-r-cg-6 {
    column-gap: var(--space-6);
  }

  .sm\:rt-r-cg-7 {
    column-gap: var(--space-7);
  }

  .sm\:rt-r-cg-8 {
    column-gap: var(--space-8);
  }

  .sm\:rt-r-cg-9 {
    column-gap: var(--space-9);
  }

  .sm\:rt-r-rg {
    row-gap: var(--row-gap-sm);
  }

  .sm\:rt-r-rg-0 {
    row-gap: 0;
  }

  .sm\:rt-r-rg-1 {
    row-gap: var(--space-1);
  }

  .sm\:rt-r-rg-2 {
    row-gap: var(--space-2);
  }

  .sm\:rt-r-rg-3 {
    row-gap: var(--space-3);
  }

  .sm\:rt-r-rg-4 {
    row-gap: var(--space-4);
  }

  .sm\:rt-r-rg-5 {
    row-gap: var(--space-5);
  }

  .sm\:rt-r-rg-6 {
    row-gap: var(--space-6);
  }

  .sm\:rt-r-rg-7 {
    row-gap: var(--space-7);
  }

  .sm\:rt-r-rg-8 {
    row-gap: var(--space-8);
  }

  .sm\:rt-r-rg-9 {
    row-gap: var(--space-9);
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-gap {
    gap: var(--gap-md);
  }

  .md\:rt-r-gap-0 {
    gap: 0;
  }

  .md\:rt-r-gap-1 {
    gap: var(--space-1);
  }

  .md\:rt-r-gap-2 {
    gap: var(--space-2);
  }

  .md\:rt-r-gap-3 {
    gap: var(--space-3);
  }

  .md\:rt-r-gap-4 {
    gap: var(--space-4);
  }

  .md\:rt-r-gap-5 {
    gap: var(--space-5);
  }

  .md\:rt-r-gap-6 {
    gap: var(--space-6);
  }

  .md\:rt-r-gap-7 {
    gap: var(--space-7);
  }

  .md\:rt-r-gap-8 {
    gap: var(--space-8);
  }

  .md\:rt-r-gap-9 {
    gap: var(--space-9);
  }

  .md\:rt-r-cg {
    column-gap: var(--column-gap-md);
  }

  .md\:rt-r-cg-0 {
    column-gap: 0;
  }

  .md\:rt-r-cg-1 {
    column-gap: var(--space-1);
  }

  .md\:rt-r-cg-2 {
    column-gap: var(--space-2);
  }

  .md\:rt-r-cg-3 {
    column-gap: var(--space-3);
  }

  .md\:rt-r-cg-4 {
    column-gap: var(--space-4);
  }

  .md\:rt-r-cg-5 {
    column-gap: var(--space-5);
  }

  .md\:rt-r-cg-6 {
    column-gap: var(--space-6);
  }

  .md\:rt-r-cg-7 {
    column-gap: var(--space-7);
  }

  .md\:rt-r-cg-8 {
    column-gap: var(--space-8);
  }

  .md\:rt-r-cg-9 {
    column-gap: var(--space-9);
  }

  .md\:rt-r-rg {
    row-gap: var(--row-gap-md);
  }

  .md\:rt-r-rg-0 {
    row-gap: 0;
  }

  .md\:rt-r-rg-1 {
    row-gap: var(--space-1);
  }

  .md\:rt-r-rg-2 {
    row-gap: var(--space-2);
  }

  .md\:rt-r-rg-3 {
    row-gap: var(--space-3);
  }

  .md\:rt-r-rg-4 {
    row-gap: var(--space-4);
  }

  .md\:rt-r-rg-5 {
    row-gap: var(--space-5);
  }

  .md\:rt-r-rg-6 {
    row-gap: var(--space-6);
  }

  .md\:rt-r-rg-7 {
    row-gap: var(--space-7);
  }

  .md\:rt-r-rg-8 {
    row-gap: var(--space-8);
  }

  .md\:rt-r-rg-9 {
    row-gap: var(--space-9);
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-gap {
    gap: var(--gap-lg);
  }

  .lg\:rt-r-gap-0 {
    gap: 0;
  }

  .lg\:rt-r-gap-1 {
    gap: var(--space-1);
  }

  .lg\:rt-r-gap-2 {
    gap: var(--space-2);
  }

  .lg\:rt-r-gap-3 {
    gap: var(--space-3);
  }

  .lg\:rt-r-gap-4 {
    gap: var(--space-4);
  }

  .lg\:rt-r-gap-5 {
    gap: var(--space-5);
  }

  .lg\:rt-r-gap-6 {
    gap: var(--space-6);
  }

  .lg\:rt-r-gap-7 {
    gap: var(--space-7);
  }

  .lg\:rt-r-gap-8 {
    gap: var(--space-8);
  }

  .lg\:rt-r-gap-9 {
    gap: var(--space-9);
  }

  .lg\:rt-r-cg {
    column-gap: var(--column-gap-lg);
  }

  .lg\:rt-r-cg-0 {
    column-gap: 0;
  }

  .lg\:rt-r-cg-1 {
    column-gap: var(--space-1);
  }

  .lg\:rt-r-cg-2 {
    column-gap: var(--space-2);
  }

  .lg\:rt-r-cg-3 {
    column-gap: var(--space-3);
  }

  .lg\:rt-r-cg-4 {
    column-gap: var(--space-4);
  }

  .lg\:rt-r-cg-5 {
    column-gap: var(--space-5);
  }

  .lg\:rt-r-cg-6 {
    column-gap: var(--space-6);
  }

  .lg\:rt-r-cg-7 {
    column-gap: var(--space-7);
  }

  .lg\:rt-r-cg-8 {
    column-gap: var(--space-8);
  }

  .lg\:rt-r-cg-9 {
    column-gap: var(--space-9);
  }

  .lg\:rt-r-rg {
    row-gap: var(--row-gap-lg);
  }

  .lg\:rt-r-rg-0 {
    row-gap: 0;
  }

  .lg\:rt-r-rg-1 {
    row-gap: var(--space-1);
  }

  .lg\:rt-r-rg-2 {
    row-gap: var(--space-2);
  }

  .lg\:rt-r-rg-3 {
    row-gap: var(--space-3);
  }

  .lg\:rt-r-rg-4 {
    row-gap: var(--space-4);
  }

  .lg\:rt-r-rg-5 {
    row-gap: var(--space-5);
  }

  .lg\:rt-r-rg-6 {
    row-gap: var(--space-6);
  }

  .lg\:rt-r-rg-7 {
    row-gap: var(--space-7);
  }

  .lg\:rt-r-rg-8 {
    row-gap: var(--space-8);
  }

  .lg\:rt-r-rg-9 {
    row-gap: var(--space-9);
  }
}

@media (min-width: 1640px) {
  .xl\:rt-r-gap {
    gap: var(--gap-xl);
  }

  .xl\:rt-r-gap-0 {
    gap: 0;
  }

  .xl\:rt-r-gap-1 {
    gap: var(--space-1);
  }

  .xl\:rt-r-gap-2 {
    gap: var(--space-2);
  }

  .xl\:rt-r-gap-3 {
    gap: var(--space-3);
  }

  .xl\:rt-r-gap-4 {
    gap: var(--space-4);
  }

  .xl\:rt-r-gap-5 {
    gap: var(--space-5);
  }

  .xl\:rt-r-gap-6 {
    gap: var(--space-6);
  }

  .xl\:rt-r-gap-7 {
    gap: var(--space-7);
  }

  .xl\:rt-r-gap-8 {
    gap: var(--space-8);
  }

  .xl\:rt-r-gap-9 {
    gap: var(--space-9);
  }

  .xl\:rt-r-cg {
    column-gap: var(--column-gap-xl);
  }

  .xl\:rt-r-cg-0 {
    column-gap: 0;
  }

  .xl\:rt-r-cg-1 {
    column-gap: var(--space-1);
  }

  .xl\:rt-r-cg-2 {
    column-gap: var(--space-2);
  }

  .xl\:rt-r-cg-3 {
    column-gap: var(--space-3);
  }

  .xl\:rt-r-cg-4 {
    column-gap: var(--space-4);
  }

  .xl\:rt-r-cg-5 {
    column-gap: var(--space-5);
  }

  .xl\:rt-r-cg-6 {
    column-gap: var(--space-6);
  }

  .xl\:rt-r-cg-7 {
    column-gap: var(--space-7);
  }

  .xl\:rt-r-cg-8 {
    column-gap: var(--space-8);
  }

  .xl\:rt-r-cg-9 {
    column-gap: var(--space-9);
  }

  .xl\:rt-r-rg {
    row-gap: var(--row-gap-xl);
  }

  .xl\:rt-r-rg-0 {
    row-gap: 0;
  }

  .xl\:rt-r-rg-1 {
    row-gap: var(--space-1);
  }

  .xl\:rt-r-rg-2 {
    row-gap: var(--space-2);
  }

  .xl\:rt-r-rg-3 {
    row-gap: var(--space-3);
  }

  .xl\:rt-r-rg-4 {
    row-gap: var(--space-4);
  }

  .xl\:rt-r-rg-5 {
    row-gap: var(--space-5);
  }

  .xl\:rt-r-rg-6 {
    row-gap: var(--space-6);
  }

  .xl\:rt-r-rg-7 {
    row-gap: var(--space-7);
  }

  .xl\:rt-r-rg-8 {
    row-gap: var(--space-8);
  }

  .xl\:rt-r-rg-9 {
    row-gap: var(--space-9);
  }
}

.rt-r-ga {
  grid-area: var(--grid-area);
}

@media (min-width: 520px) {
  .xs\:rt-r-ga {
    grid-area: var(--grid-area-xs);
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-ga {
    grid-area: var(--grid-area-sm);
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-ga {
    grid-area: var(--grid-area-md);
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-ga {
    grid-area: var(--grid-area-lg);
  }
}

@media (min-width: 1640px) {
  .xl\:rt-r-ga {
    grid-area: var(--grid-area-xl);
  }
}

.rt-r-gaf-row {
  grid-auto-flow: row;
}

.rt-r-gaf-column {
  grid-auto-flow: column;
}

.rt-r-gaf-dense {
  grid-auto-flow: dense;
}

.rt-r-gaf-row-dense {
  grid-auto-flow: row dense;
}

.rt-r-gaf-column-dense {
  grid-auto-flow: column dense;
}

@media (min-width: 520px) {
  .xs\:rt-r-gaf-row {
    grid-auto-flow: row;
  }

  .xs\:rt-r-gaf-column {
    grid-auto-flow: column;
  }

  .xs\:rt-r-gaf-dense {
    grid-auto-flow: dense;
  }

  .xs\:rt-r-gaf-row-dense {
    grid-auto-flow: row dense;
  }

  .xs\:rt-r-gaf-column-dense {
    grid-auto-flow: column dense;
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-gaf-row {
    grid-auto-flow: row;
  }

  .sm\:rt-r-gaf-column {
    grid-auto-flow: column;
  }

  .sm\:rt-r-gaf-dense {
    grid-auto-flow: dense;
  }

  .sm\:rt-r-gaf-row-dense {
    grid-auto-flow: row dense;
  }

  .sm\:rt-r-gaf-column-dense {
    grid-auto-flow: column dense;
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-gaf-row {
    grid-auto-flow: row;
  }

  .md\:rt-r-gaf-column {
    grid-auto-flow: column;
  }

  .md\:rt-r-gaf-dense {
    grid-auto-flow: dense;
  }

  .md\:rt-r-gaf-row-dense {
    grid-auto-flow: row dense;
  }

  .md\:rt-r-gaf-column-dense {
    grid-auto-flow: column dense;
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-gaf-row {
    grid-auto-flow: row;
  }

  .lg\:rt-r-gaf-column {
    grid-auto-flow: column;
  }

  .lg\:rt-r-gaf-dense {
    grid-auto-flow: dense;
  }

  .lg\:rt-r-gaf-row-dense {
    grid-auto-flow: row dense;
  }

  .lg\:rt-r-gaf-column-dense {
    grid-auto-flow: column dense;
  }
}

@media (min-width: 1640px) {
  .xl\:rt-r-gaf-row {
    grid-auto-flow: row;
  }

  .xl\:rt-r-gaf-column {
    grid-auto-flow: column;
  }

  .xl\:rt-r-gaf-dense {
    grid-auto-flow: dense;
  }

  .xl\:rt-r-gaf-row-dense {
    grid-auto-flow: row dense;
  }

  .xl\:rt-r-gaf-column-dense {
    grid-auto-flow: column dense;
  }
}

.rt-r-gc {
  grid-column: var(--grid-column);
}

.rt-r-gc-1 {
  grid-column: 1;
}

.rt-r-gc-2 {
  grid-column: 2;
}

.rt-r-gc-3 {
  grid-column: 3;
}

.rt-r-gc-4 {
  grid-column: 4;
}

.rt-r-gc-5 {
  grid-column: 5;
}

.rt-r-gc-6 {
  grid-column: 6;
}

.rt-r-gc-7 {
  grid-column: 7;
}

.rt-r-gc-8 {
  grid-column: 8;
}

.rt-r-gc-9 {
  grid-column: 9;
}

@media (min-width: 520px) {
  .xs\:rt-r-gc {
    grid-column: var(--grid-column-xs);
  }

  .xs\:rt-r-gc-1 {
    grid-column: 1;
  }

  .xs\:rt-r-gc-2 {
    grid-column: 2;
  }

  .xs\:rt-r-gc-3 {
    grid-column: 3;
  }

  .xs\:rt-r-gc-4 {
    grid-column: 4;
  }

  .xs\:rt-r-gc-5 {
    grid-column: 5;
  }

  .xs\:rt-r-gc-6 {
    grid-column: 6;
  }

  .xs\:rt-r-gc-7 {
    grid-column: 7;
  }

  .xs\:rt-r-gc-8 {
    grid-column: 8;
  }

  .xs\:rt-r-gc-9 {
    grid-column: 9;
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-gc {
    grid-column: var(--grid-column-sm);
  }

  .sm\:rt-r-gc-1 {
    grid-column: 1;
  }

  .sm\:rt-r-gc-2 {
    grid-column: 2;
  }

  .sm\:rt-r-gc-3 {
    grid-column: 3;
  }

  .sm\:rt-r-gc-4 {
    grid-column: 4;
  }

  .sm\:rt-r-gc-5 {
    grid-column: 5;
  }

  .sm\:rt-r-gc-6 {
    grid-column: 6;
  }

  .sm\:rt-r-gc-7 {
    grid-column: 7;
  }

  .sm\:rt-r-gc-8 {
    grid-column: 8;
  }

  .sm\:rt-r-gc-9 {
    grid-column: 9;
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-gc {
    grid-column: var(--grid-column-md);
  }

  .md\:rt-r-gc-1 {
    grid-column: 1;
  }

  .md\:rt-r-gc-2 {
    grid-column: 2;
  }

  .md\:rt-r-gc-3 {
    grid-column: 3;
  }

  .md\:rt-r-gc-4 {
    grid-column: 4;
  }

  .md\:rt-r-gc-5 {
    grid-column: 5;
  }

  .md\:rt-r-gc-6 {
    grid-column: 6;
  }

  .md\:rt-r-gc-7 {
    grid-column: 7;
  }

  .md\:rt-r-gc-8 {
    grid-column: 8;
  }

  .md\:rt-r-gc-9 {
    grid-column: 9;
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-gc {
    grid-column: var(--grid-column-lg);
  }

  .lg\:rt-r-gc-1 {
    grid-column: 1;
  }

  .lg\:rt-r-gc-2 {
    grid-column: 2;
  }

  .lg\:rt-r-gc-3 {
    grid-column: 3;
  }

  .lg\:rt-r-gc-4 {
    grid-column: 4;
  }

  .lg\:rt-r-gc-5 {
    grid-column: 5;
  }

  .lg\:rt-r-gc-6 {
    grid-column: 6;
  }

  .lg\:rt-r-gc-7 {
    grid-column: 7;
  }

  .lg\:rt-r-gc-8 {
    grid-column: 8;
  }

  .lg\:rt-r-gc-9 {
    grid-column: 9;
  }
}

@media (min-width: 1640px) {
  .xl\:rt-r-gc {
    grid-column: var(--grid-column-xl);
  }

  .xl\:rt-r-gc-1 {
    grid-column: 1;
  }

  .xl\:rt-r-gc-2 {
    grid-column: 2;
  }

  .xl\:rt-r-gc-3 {
    grid-column: 3;
  }

  .xl\:rt-r-gc-4 {
    grid-column: 4;
  }

  .xl\:rt-r-gc-5 {
    grid-column: 5;
  }

  .xl\:rt-r-gc-6 {
    grid-column: 6;
  }

  .xl\:rt-r-gc-7 {
    grid-column: 7;
  }

  .xl\:rt-r-gc-8 {
    grid-column: 8;
  }

  .xl\:rt-r-gc-9 {
    grid-column: 9;
  }
}

.rt-r-gcs {
  grid-column-start: var(--grid-column-start);
}

.rt-r-gcs-1 {
  grid-column-start: 1;
}

.rt-r-gcs-2 {
  grid-column-start: 2;
}

.rt-r-gcs-3 {
  grid-column-start: 3;
}

.rt-r-gcs-4 {
  grid-column-start: 4;
}

.rt-r-gcs-5 {
  grid-column-start: 5;
}

.rt-r-gcs-6 {
  grid-column-start: 6;
}

.rt-r-gcs-7 {
  grid-column-start: 7;
}

.rt-r-gcs-8 {
  grid-column-start: 8;
}

.rt-r-gcs-9 {
  grid-column-start: 9;
}

@media (min-width: 520px) {
  .xs\:rt-r-gcs {
    grid-column-start: var(--grid-column-start-xs);
  }

  .xs\:rt-r-gcs-1 {
    grid-column-start: 1;
  }

  .xs\:rt-r-gcs-2 {
    grid-column-start: 2;
  }

  .xs\:rt-r-gcs-3 {
    grid-column-start: 3;
  }

  .xs\:rt-r-gcs-4 {
    grid-column-start: 4;
  }

  .xs\:rt-r-gcs-5 {
    grid-column-start: 5;
  }

  .xs\:rt-r-gcs-6 {
    grid-column-start: 6;
  }

  .xs\:rt-r-gcs-7 {
    grid-column-start: 7;
  }

  .xs\:rt-r-gcs-8 {
    grid-column-start: 8;
  }

  .xs\:rt-r-gcs-9 {
    grid-column-start: 9;
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-gcs {
    grid-column-start: var(--grid-column-start-sm);
  }

  .sm\:rt-r-gcs-1 {
    grid-column-start: 1;
  }

  .sm\:rt-r-gcs-2 {
    grid-column-start: 2;
  }

  .sm\:rt-r-gcs-3 {
    grid-column-start: 3;
  }

  .sm\:rt-r-gcs-4 {
    grid-column-start: 4;
  }

  .sm\:rt-r-gcs-5 {
    grid-column-start: 5;
  }

  .sm\:rt-r-gcs-6 {
    grid-column-start: 6;
  }

  .sm\:rt-r-gcs-7 {
    grid-column-start: 7;
  }

  .sm\:rt-r-gcs-8 {
    grid-column-start: 8;
  }

  .sm\:rt-r-gcs-9 {
    grid-column-start: 9;
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-gcs {
    grid-column-start: var(--grid-column-start-md);
  }

  .md\:rt-r-gcs-1 {
    grid-column-start: 1;
  }

  .md\:rt-r-gcs-2 {
    grid-column-start: 2;
  }

  .md\:rt-r-gcs-3 {
    grid-column-start: 3;
  }

  .md\:rt-r-gcs-4 {
    grid-column-start: 4;
  }

  .md\:rt-r-gcs-5 {
    grid-column-start: 5;
  }

  .md\:rt-r-gcs-6 {
    grid-column-start: 6;
  }

  .md\:rt-r-gcs-7 {
    grid-column-start: 7;
  }

  .md\:rt-r-gcs-8 {
    grid-column-start: 8;
  }

  .md\:rt-r-gcs-9 {
    grid-column-start: 9;
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-gcs {
    grid-column-start: var(--grid-column-start-lg);
  }

  .lg\:rt-r-gcs-1 {
    grid-column-start: 1;
  }

  .lg\:rt-r-gcs-2 {
    grid-column-start: 2;
  }

  .lg\:rt-r-gcs-3 {
    grid-column-start: 3;
  }

  .lg\:rt-r-gcs-4 {
    grid-column-start: 4;
  }

  .lg\:rt-r-gcs-5 {
    grid-column-start: 5;
  }

  .lg\:rt-r-gcs-6 {
    grid-column-start: 6;
  }

  .lg\:rt-r-gcs-7 {
    grid-column-start: 7;
  }

  .lg\:rt-r-gcs-8 {
    grid-column-start: 8;
  }

  .lg\:rt-r-gcs-9 {
    grid-column-start: 9;
  }
}

@media (min-width: 1640px) {
  .xl\:rt-r-gcs {
    grid-column-start: var(--grid-column-start-xl);
  }

  .xl\:rt-r-gcs-1 {
    grid-column-start: 1;
  }

  .xl\:rt-r-gcs-2 {
    grid-column-start: 2;
  }

  .xl\:rt-r-gcs-3 {
    grid-column-start: 3;
  }

  .xl\:rt-r-gcs-4 {
    grid-column-start: 4;
  }

  .xl\:rt-r-gcs-5 {
    grid-column-start: 5;
  }

  .xl\:rt-r-gcs-6 {
    grid-column-start: 6;
  }

  .xl\:rt-r-gcs-7 {
    grid-column-start: 7;
  }

  .xl\:rt-r-gcs-8 {
    grid-column-start: 8;
  }

  .xl\:rt-r-gcs-9 {
    grid-column-start: 9;
  }
}

.rt-r-gce {
  grid-column-end: var(--grid-column-end);
}

.rt-r-gce-1 {
  grid-column-end: 1;
}

.rt-r-gce-2 {
  grid-column-end: 2;
}

.rt-r-gce-3 {
  grid-column-end: 3;
}

.rt-r-gce-4 {
  grid-column-end: 4;
}

.rt-r-gce-5 {
  grid-column-end: 5;
}

.rt-r-gce-6 {
  grid-column-end: 6;
}

.rt-r-gce-7 {
  grid-column-end: 7;
}

.rt-r-gce-8 {
  grid-column-end: 8;
}

.rt-r-gce-9 {
  grid-column-end: 9;
}

@media (min-width: 520px) {
  .xs\:rt-r-gce {
    grid-column-end: var(--grid-column-end-xs);
  }

  .xs\:rt-r-gce-1 {
    grid-column-end: 1;
  }

  .xs\:rt-r-gce-2 {
    grid-column-end: 2;
  }

  .xs\:rt-r-gce-3 {
    grid-column-end: 3;
  }

  .xs\:rt-r-gce-4 {
    grid-column-end: 4;
  }

  .xs\:rt-r-gce-5 {
    grid-column-end: 5;
  }

  .xs\:rt-r-gce-6 {
    grid-column-end: 6;
  }

  .xs\:rt-r-gce-7 {
    grid-column-end: 7;
  }

  .xs\:rt-r-gce-8 {
    grid-column-end: 8;
  }

  .xs\:rt-r-gce-9 {
    grid-column-end: 9;
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-gce {
    grid-column-end: var(--grid-column-end-sm);
  }

  .sm\:rt-r-gce-1 {
    grid-column-end: 1;
  }

  .sm\:rt-r-gce-2 {
    grid-column-end: 2;
  }

  .sm\:rt-r-gce-3 {
    grid-column-end: 3;
  }

  .sm\:rt-r-gce-4 {
    grid-column-end: 4;
  }

  .sm\:rt-r-gce-5 {
    grid-column-end: 5;
  }

  .sm\:rt-r-gce-6 {
    grid-column-end: 6;
  }

  .sm\:rt-r-gce-7 {
    grid-column-end: 7;
  }

  .sm\:rt-r-gce-8 {
    grid-column-end: 8;
  }

  .sm\:rt-r-gce-9 {
    grid-column-end: 9;
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-gce {
    grid-column-end: var(--grid-column-end-md);
  }

  .md\:rt-r-gce-1 {
    grid-column-end: 1;
  }

  .md\:rt-r-gce-2 {
    grid-column-end: 2;
  }

  .md\:rt-r-gce-3 {
    grid-column-end: 3;
  }

  .md\:rt-r-gce-4 {
    grid-column-end: 4;
  }

  .md\:rt-r-gce-5 {
    grid-column-end: 5;
  }

  .md\:rt-r-gce-6 {
    grid-column-end: 6;
  }

  .md\:rt-r-gce-7 {
    grid-column-end: 7;
  }

  .md\:rt-r-gce-8 {
    grid-column-end: 8;
  }

  .md\:rt-r-gce-9 {
    grid-column-end: 9;
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-gce {
    grid-column-end: var(--grid-column-end-lg);
  }

  .lg\:rt-r-gce-1 {
    grid-column-end: 1;
  }

  .lg\:rt-r-gce-2 {
    grid-column-end: 2;
  }

  .lg\:rt-r-gce-3 {
    grid-column-end: 3;
  }

  .lg\:rt-r-gce-4 {
    grid-column-end: 4;
  }

  .lg\:rt-r-gce-5 {
    grid-column-end: 5;
  }

  .lg\:rt-r-gce-6 {
    grid-column-end: 6;
  }

  .lg\:rt-r-gce-7 {
    grid-column-end: 7;
  }

  .lg\:rt-r-gce-8 {
    grid-column-end: 8;
  }

  .lg\:rt-r-gce-9 {
    grid-column-end: 9;
  }
}

@media (min-width: 1640px) {
  .xl\:rt-r-gce {
    grid-column-end: var(--grid-column-end-xl);
  }

  .xl\:rt-r-gce-1 {
    grid-column-end: 1;
  }

  .xl\:rt-r-gce-2 {
    grid-column-end: 2;
  }

  .xl\:rt-r-gce-3 {
    grid-column-end: 3;
  }

  .xl\:rt-r-gce-4 {
    grid-column-end: 4;
  }

  .xl\:rt-r-gce-5 {
    grid-column-end: 5;
  }

  .xl\:rt-r-gce-6 {
    grid-column-end: 6;
  }

  .xl\:rt-r-gce-7 {
    grid-column-end: 7;
  }

  .xl\:rt-r-gce-8 {
    grid-column-end: 8;
  }

  .xl\:rt-r-gce-9 {
    grid-column-end: 9;
  }
}

.rt-r-gr {
  grid-row: var(--grid-row);
}

.rt-r-gr-1 {
  grid-row: 1;
}

.rt-r-gr-2 {
  grid-row: 2;
}

.rt-r-gr-3 {
  grid-row: 3;
}

.rt-r-gr-4 {
  grid-row: 4;
}

.rt-r-gr-5 {
  grid-row: 5;
}

.rt-r-gr-6 {
  grid-row: 6;
}

.rt-r-gr-7 {
  grid-row: 7;
}

.rt-r-gr-8 {
  grid-row: 8;
}

.rt-r-gr-9 {
  grid-row: 9;
}

@media (min-width: 520px) {
  .xs\:rt-r-gr {
    grid-row: var(--grid-row-xs);
  }

  .xs\:rt-r-gr-1 {
    grid-row: 1;
  }

  .xs\:rt-r-gr-2 {
    grid-row: 2;
  }

  .xs\:rt-r-gr-3 {
    grid-row: 3;
  }

  .xs\:rt-r-gr-4 {
    grid-row: 4;
  }

  .xs\:rt-r-gr-5 {
    grid-row: 5;
  }

  .xs\:rt-r-gr-6 {
    grid-row: 6;
  }

  .xs\:rt-r-gr-7 {
    grid-row: 7;
  }

  .xs\:rt-r-gr-8 {
    grid-row: 8;
  }

  .xs\:rt-r-gr-9 {
    grid-row: 9;
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-gr {
    grid-row: var(--grid-row-sm);
  }

  .sm\:rt-r-gr-1 {
    grid-row: 1;
  }

  .sm\:rt-r-gr-2 {
    grid-row: 2;
  }

  .sm\:rt-r-gr-3 {
    grid-row: 3;
  }

  .sm\:rt-r-gr-4 {
    grid-row: 4;
  }

  .sm\:rt-r-gr-5 {
    grid-row: 5;
  }

  .sm\:rt-r-gr-6 {
    grid-row: 6;
  }

  .sm\:rt-r-gr-7 {
    grid-row: 7;
  }

  .sm\:rt-r-gr-8 {
    grid-row: 8;
  }

  .sm\:rt-r-gr-9 {
    grid-row: 9;
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-gr {
    grid-row: var(--grid-row-md);
  }

  .md\:rt-r-gr-1 {
    grid-row: 1;
  }

  .md\:rt-r-gr-2 {
    grid-row: 2;
  }

  .md\:rt-r-gr-3 {
    grid-row: 3;
  }

  .md\:rt-r-gr-4 {
    grid-row: 4;
  }

  .md\:rt-r-gr-5 {
    grid-row: 5;
  }

  .md\:rt-r-gr-6 {
    grid-row: 6;
  }

  .md\:rt-r-gr-7 {
    grid-row: 7;
  }

  .md\:rt-r-gr-8 {
    grid-row: 8;
  }

  .md\:rt-r-gr-9 {
    grid-row: 9;
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-gr {
    grid-row: var(--grid-row-lg);
  }

  .lg\:rt-r-gr-1 {
    grid-row: 1;
  }

  .lg\:rt-r-gr-2 {
    grid-row: 2;
  }

  .lg\:rt-r-gr-3 {
    grid-row: 3;
  }

  .lg\:rt-r-gr-4 {
    grid-row: 4;
  }

  .lg\:rt-r-gr-5 {
    grid-row: 5;
  }

  .lg\:rt-r-gr-6 {
    grid-row: 6;
  }

  .lg\:rt-r-gr-7 {
    grid-row: 7;
  }

  .lg\:rt-r-gr-8 {
    grid-row: 8;
  }

  .lg\:rt-r-gr-9 {
    grid-row: 9;
  }
}

@media (min-width: 1640px) {
  .xl\:rt-r-gr {
    grid-row: var(--grid-row-xl);
  }

  .xl\:rt-r-gr-1 {
    grid-row: 1;
  }

  .xl\:rt-r-gr-2 {
    grid-row: 2;
  }

  .xl\:rt-r-gr-3 {
    grid-row: 3;
  }

  .xl\:rt-r-gr-4 {
    grid-row: 4;
  }

  .xl\:rt-r-gr-5 {
    grid-row: 5;
  }

  .xl\:rt-r-gr-6 {
    grid-row: 6;
  }

  .xl\:rt-r-gr-7 {
    grid-row: 7;
  }

  .xl\:rt-r-gr-8 {
    grid-row: 8;
  }

  .xl\:rt-r-gr-9 {
    grid-row: 9;
  }
}

.rt-r-grs {
  grid-row-start: var(--grid-row-start);
}

.rt-r-grs-1 {
  grid-row-start: 1;
}

.rt-r-grs-2 {
  grid-row-start: 2;
}

.rt-r-grs-3 {
  grid-row-start: 3;
}

.rt-r-grs-4 {
  grid-row-start: 4;
}

.rt-r-grs-5 {
  grid-row-start: 5;
}

.rt-r-grs-6 {
  grid-row-start: 6;
}

.rt-r-grs-7 {
  grid-row-start: 7;
}

.rt-r-grs-8 {
  grid-row-start: 8;
}

.rt-r-grs-9 {
  grid-row-start: 9;
}

@media (min-width: 520px) {
  .xs\:rt-r-grs {
    grid-row-start: var(--grid-row-start-xs);
  }

  .xs\:rt-r-grs-1 {
    grid-row-start: 1;
  }

  .xs\:rt-r-grs-2 {
    grid-row-start: 2;
  }

  .xs\:rt-r-grs-3 {
    grid-row-start: 3;
  }

  .xs\:rt-r-grs-4 {
    grid-row-start: 4;
  }

  .xs\:rt-r-grs-5 {
    grid-row-start: 5;
  }

  .xs\:rt-r-grs-6 {
    grid-row-start: 6;
  }

  .xs\:rt-r-grs-7 {
    grid-row-start: 7;
  }

  .xs\:rt-r-grs-8 {
    grid-row-start: 8;
  }

  .xs\:rt-r-grs-9 {
    grid-row-start: 9;
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-grs {
    grid-row-start: var(--grid-row-start-sm);
  }

  .sm\:rt-r-grs-1 {
    grid-row-start: 1;
  }

  .sm\:rt-r-grs-2 {
    grid-row-start: 2;
  }

  .sm\:rt-r-grs-3 {
    grid-row-start: 3;
  }

  .sm\:rt-r-grs-4 {
    grid-row-start: 4;
  }

  .sm\:rt-r-grs-5 {
    grid-row-start: 5;
  }

  .sm\:rt-r-grs-6 {
    grid-row-start: 6;
  }

  .sm\:rt-r-grs-7 {
    grid-row-start: 7;
  }

  .sm\:rt-r-grs-8 {
    grid-row-start: 8;
  }

  .sm\:rt-r-grs-9 {
    grid-row-start: 9;
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-grs {
    grid-row-start: var(--grid-row-start-md);
  }

  .md\:rt-r-grs-1 {
    grid-row-start: 1;
  }

  .md\:rt-r-grs-2 {
    grid-row-start: 2;
  }

  .md\:rt-r-grs-3 {
    grid-row-start: 3;
  }

  .md\:rt-r-grs-4 {
    grid-row-start: 4;
  }

  .md\:rt-r-grs-5 {
    grid-row-start: 5;
  }

  .md\:rt-r-grs-6 {
    grid-row-start: 6;
  }

  .md\:rt-r-grs-7 {
    grid-row-start: 7;
  }

  .md\:rt-r-grs-8 {
    grid-row-start: 8;
  }

  .md\:rt-r-grs-9 {
    grid-row-start: 9;
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-grs {
    grid-row-start: var(--grid-row-start-lg);
  }

  .lg\:rt-r-grs-1 {
    grid-row-start: 1;
  }

  .lg\:rt-r-grs-2 {
    grid-row-start: 2;
  }

  .lg\:rt-r-grs-3 {
    grid-row-start: 3;
  }

  .lg\:rt-r-grs-4 {
    grid-row-start: 4;
  }

  .lg\:rt-r-grs-5 {
    grid-row-start: 5;
  }

  .lg\:rt-r-grs-6 {
    grid-row-start: 6;
  }

  .lg\:rt-r-grs-7 {
    grid-row-start: 7;
  }

  .lg\:rt-r-grs-8 {
    grid-row-start: 8;
  }

  .lg\:rt-r-grs-9 {
    grid-row-start: 9;
  }
}

@media (min-width: 1640px) {
  .xl\:rt-r-grs {
    grid-row-start: var(--grid-row-start-xl);
  }

  .xl\:rt-r-grs-1 {
    grid-row-start: 1;
  }

  .xl\:rt-r-grs-2 {
    grid-row-start: 2;
  }

  .xl\:rt-r-grs-3 {
    grid-row-start: 3;
  }

  .xl\:rt-r-grs-4 {
    grid-row-start: 4;
  }

  .xl\:rt-r-grs-5 {
    grid-row-start: 5;
  }

  .xl\:rt-r-grs-6 {
    grid-row-start: 6;
  }

  .xl\:rt-r-grs-7 {
    grid-row-start: 7;
  }

  .xl\:rt-r-grs-8 {
    grid-row-start: 8;
  }

  .xl\:rt-r-grs-9 {
    grid-row-start: 9;
  }
}

.rt-r-gre {
  grid-row-end: var(--grid-row-end);
}

.rt-r-gre-1 {
  grid-row-end: 1;
}

.rt-r-gre-2 {
  grid-row-end: 2;
}

.rt-r-gre-3 {
  grid-row-end: 3;
}

.rt-r-gre-4 {
  grid-row-end: 4;
}

.rt-r-gre-5 {
  grid-row-end: 5;
}

.rt-r-gre-6 {
  grid-row-end: 6;
}

.rt-r-gre-7 {
  grid-row-end: 7;
}

.rt-r-gre-8 {
  grid-row-end: 8;
}

.rt-r-gre-9 {
  grid-row-end: 9;
}

@media (min-width: 520px) {
  .xs\:rt-r-gre {
    grid-row-end: var(--grid-row-end-xs);
  }

  .xs\:rt-r-gre-1 {
    grid-row-end: 1;
  }

  .xs\:rt-r-gre-2 {
    grid-row-end: 2;
  }

  .xs\:rt-r-gre-3 {
    grid-row-end: 3;
  }

  .xs\:rt-r-gre-4 {
    grid-row-end: 4;
  }

  .xs\:rt-r-gre-5 {
    grid-row-end: 5;
  }

  .xs\:rt-r-gre-6 {
    grid-row-end: 6;
  }

  .xs\:rt-r-gre-7 {
    grid-row-end: 7;
  }

  .xs\:rt-r-gre-8 {
    grid-row-end: 8;
  }

  .xs\:rt-r-gre-9 {
    grid-row-end: 9;
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-gre {
    grid-row-end: var(--grid-row-end-sm);
  }

  .sm\:rt-r-gre-1 {
    grid-row-end: 1;
  }

  .sm\:rt-r-gre-2 {
    grid-row-end: 2;
  }

  .sm\:rt-r-gre-3 {
    grid-row-end: 3;
  }

  .sm\:rt-r-gre-4 {
    grid-row-end: 4;
  }

  .sm\:rt-r-gre-5 {
    grid-row-end: 5;
  }

  .sm\:rt-r-gre-6 {
    grid-row-end: 6;
  }

  .sm\:rt-r-gre-7 {
    grid-row-end: 7;
  }

  .sm\:rt-r-gre-8 {
    grid-row-end: 8;
  }

  .sm\:rt-r-gre-9 {
    grid-row-end: 9;
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-gre {
    grid-row-end: var(--grid-row-end-md);
  }

  .md\:rt-r-gre-1 {
    grid-row-end: 1;
  }

  .md\:rt-r-gre-2 {
    grid-row-end: 2;
  }

  .md\:rt-r-gre-3 {
    grid-row-end: 3;
  }

  .md\:rt-r-gre-4 {
    grid-row-end: 4;
  }

  .md\:rt-r-gre-5 {
    grid-row-end: 5;
  }

  .md\:rt-r-gre-6 {
    grid-row-end: 6;
  }

  .md\:rt-r-gre-7 {
    grid-row-end: 7;
  }

  .md\:rt-r-gre-8 {
    grid-row-end: 8;
  }

  .md\:rt-r-gre-9 {
    grid-row-end: 9;
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-gre {
    grid-row-end: var(--grid-row-end-lg);
  }

  .lg\:rt-r-gre-1 {
    grid-row-end: 1;
  }

  .lg\:rt-r-gre-2 {
    grid-row-end: 2;
  }

  .lg\:rt-r-gre-3 {
    grid-row-end: 3;
  }

  .lg\:rt-r-gre-4 {
    grid-row-end: 4;
  }

  .lg\:rt-r-gre-5 {
    grid-row-end: 5;
  }

  .lg\:rt-r-gre-6 {
    grid-row-end: 6;
  }

  .lg\:rt-r-gre-7 {
    grid-row-end: 7;
  }

  .lg\:rt-r-gre-8 {
    grid-row-end: 8;
  }

  .lg\:rt-r-gre-9 {
    grid-row-end: 9;
  }
}

@media (min-width: 1640px) {
  .xl\:rt-r-gre {
    grid-row-end: var(--grid-row-end-xl);
  }

  .xl\:rt-r-gre-1 {
    grid-row-end: 1;
  }

  .xl\:rt-r-gre-2 {
    grid-row-end: 2;
  }

  .xl\:rt-r-gre-3 {
    grid-row-end: 3;
  }

  .xl\:rt-r-gre-4 {
    grid-row-end: 4;
  }

  .xl\:rt-r-gre-5 {
    grid-row-end: 5;
  }

  .xl\:rt-r-gre-6 {
    grid-row-end: 6;
  }

  .xl\:rt-r-gre-7 {
    grid-row-end: 7;
  }

  .xl\:rt-r-gre-8 {
    grid-row-end: 8;
  }

  .xl\:rt-r-gre-9 {
    grid-row-end: 9;
  }
}

.rt-r-gta {
  grid-template-areas: var(--grid-template-areas);
}

@media (min-width: 520px) {
  .xs\:rt-r-gta {
    grid-template-areas: var(--grid-template-areas-xs);
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-gta {
    grid-template-areas: var(--grid-template-areas-sm);
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-gta {
    grid-template-areas: var(--grid-template-areas-md);
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-gta {
    grid-template-areas: var(--grid-template-areas-lg);
  }
}

@media (min-width: 1640px) {
  .xl\:rt-r-gta {
    grid-template-areas: var(--grid-template-areas-xl);
  }
}

.rt-r-gtc {
  grid-template-columns: var(--grid-template-columns);
}

.rt-r-gtc-1 {
  grid-template-columns: minmax(0, 1fr);
}

.rt-r-gtc-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.rt-r-gtc-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.rt-r-gtc-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.rt-r-gtc-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

.rt-r-gtc-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}

.rt-r-gtc-7 {
  grid-template-columns: repeat(7, minmax(0, 1fr));
}

.rt-r-gtc-8 {
  grid-template-columns: repeat(8, minmax(0, 1fr));
}

.rt-r-gtc-9 {
  grid-template-columns: repeat(9, minmax(0, 1fr));
}

@media (min-width: 520px) {
  .xs\:rt-r-gtc {
    grid-template-columns: var(--grid-template-columns-xs);
  }

  .xs\:rt-r-gtc-1 {
    grid-template-columns: minmax(0, 1fr);
  }

  .xs\:rt-r-gtc-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .xs\:rt-r-gtc-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .xs\:rt-r-gtc-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .xs\:rt-r-gtc-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .xs\:rt-r-gtc-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  .xs\:rt-r-gtc-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }

  .xs\:rt-r-gtc-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }

  .xs\:rt-r-gtc-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-gtc {
    grid-template-columns: var(--grid-template-columns-sm);
  }

  .sm\:rt-r-gtc-1 {
    grid-template-columns: minmax(0, 1fr);
  }

  .sm\:rt-r-gtc-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .sm\:rt-r-gtc-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .sm\:rt-r-gtc-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .sm\:rt-r-gtc-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .sm\:rt-r-gtc-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  .sm\:rt-r-gtc-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }

  .sm\:rt-r-gtc-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }

  .sm\:rt-r-gtc-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-gtc {
    grid-template-columns: var(--grid-template-columns-md);
  }

  .md\:rt-r-gtc-1 {
    grid-template-columns: minmax(0, 1fr);
  }

  .md\:rt-r-gtc-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:rt-r-gtc-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .md\:rt-r-gtc-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .md\:rt-r-gtc-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .md\:rt-r-gtc-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  .md\:rt-r-gtc-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }

  .md\:rt-r-gtc-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }

  .md\:rt-r-gtc-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-gtc {
    grid-template-columns: var(--grid-template-columns-lg);
  }

  .lg\:rt-r-gtc-1 {
    grid-template-columns: minmax(0, 1fr);
  }

  .lg\:rt-r-gtc-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .lg\:rt-r-gtc-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .lg\:rt-r-gtc-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .lg\:rt-r-gtc-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .lg\:rt-r-gtc-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  .lg\:rt-r-gtc-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }

  .lg\:rt-r-gtc-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }

  .lg\:rt-r-gtc-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }
}

@media (min-width: 1640px) {
  .xl\:rt-r-gtc {
    grid-template-columns: var(--grid-template-columns-xl);
  }

  .xl\:rt-r-gtc-1 {
    grid-template-columns: minmax(0, 1fr);
  }

  .xl\:rt-r-gtc-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .xl\:rt-r-gtc-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .xl\:rt-r-gtc-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .xl\:rt-r-gtc-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .xl\:rt-r-gtc-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  .xl\:rt-r-gtc-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }

  .xl\:rt-r-gtc-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }

  .xl\:rt-r-gtc-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }
}

.rt-r-gtr {
  grid-template-rows: var(--grid-template-rows);
}

.rt-r-gtr-1 {
  grid-template-rows: minmax(0, 1fr);
}

.rt-r-gtr-2 {
  grid-template-rows: repeat(2, minmax(0, 1fr));
}

.rt-r-gtr-3 {
  grid-template-rows: repeat(3, minmax(0, 1fr));
}

.rt-r-gtr-4 {
  grid-template-rows: repeat(4, minmax(0, 1fr));
}

.rt-r-gtr-5 {
  grid-template-rows: repeat(5, minmax(0, 1fr));
}

.rt-r-gtr-6 {
  grid-template-rows: repeat(6, minmax(0, 1fr));
}

.rt-r-gtr-7 {
  grid-template-rows: repeat(7, minmax(0, 1fr));
}

.rt-r-gtr-8 {
  grid-template-rows: repeat(8, minmax(0, 1fr));
}

.rt-r-gtr-9 {
  grid-template-rows: repeat(9, minmax(0, 1fr));
}

@media (min-width: 520px) {
  .xs\:rt-r-gtr {
    grid-template-rows: var(--grid-template-rows-xs);
  }

  .xs\:rt-r-gtr-1 {
    grid-template-rows: minmax(0, 1fr);
  }

  .xs\:rt-r-gtr-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr));
  }

  .xs\:rt-r-gtr-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr));
  }

  .xs\:rt-r-gtr-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr));
  }

  .xs\:rt-r-gtr-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr));
  }

  .xs\:rt-r-gtr-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr));
  }

  .xs\:rt-r-gtr-7 {
    grid-template-rows: repeat(7, minmax(0, 1fr));
  }

  .xs\:rt-r-gtr-8 {
    grid-template-rows: repeat(8, minmax(0, 1fr));
  }

  .xs\:rt-r-gtr-9 {
    grid-template-rows: repeat(9, minmax(0, 1fr));
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-gtr {
    grid-template-rows: var(--grid-template-rows-sm);
  }

  .sm\:rt-r-gtr-1 {
    grid-template-rows: minmax(0, 1fr);
  }

  .sm\:rt-r-gtr-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr));
  }

  .sm\:rt-r-gtr-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr));
  }

  .sm\:rt-r-gtr-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr));
  }

  .sm\:rt-r-gtr-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr));
  }

  .sm\:rt-r-gtr-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr));
  }

  .sm\:rt-r-gtr-7 {
    grid-template-rows: repeat(7, minmax(0, 1fr));
  }

  .sm\:rt-r-gtr-8 {
    grid-template-rows: repeat(8, minmax(0, 1fr));
  }

  .sm\:rt-r-gtr-9 {
    grid-template-rows: repeat(9, minmax(0, 1fr));
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-gtr {
    grid-template-rows: var(--grid-template-rows-md);
  }

  .md\:rt-r-gtr-1 {
    grid-template-rows: minmax(0, 1fr);
  }

  .md\:rt-r-gtr-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr));
  }

  .md\:rt-r-gtr-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr));
  }

  .md\:rt-r-gtr-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr));
  }

  .md\:rt-r-gtr-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr));
  }

  .md\:rt-r-gtr-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr));
  }

  .md\:rt-r-gtr-7 {
    grid-template-rows: repeat(7, minmax(0, 1fr));
  }

  .md\:rt-r-gtr-8 {
    grid-template-rows: repeat(8, minmax(0, 1fr));
  }

  .md\:rt-r-gtr-9 {
    grid-template-rows: repeat(9, minmax(0, 1fr));
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-gtr {
    grid-template-rows: var(--grid-template-rows-lg);
  }

  .lg\:rt-r-gtr-1 {
    grid-template-rows: minmax(0, 1fr);
  }

  .lg\:rt-r-gtr-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr));
  }

  .lg\:rt-r-gtr-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr));
  }

  .lg\:rt-r-gtr-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr));
  }

  .lg\:rt-r-gtr-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr));
  }

  .lg\:rt-r-gtr-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr));
  }

  .lg\:rt-r-gtr-7 {
    grid-template-rows: repeat(7, minmax(0, 1fr));
  }

  .lg\:rt-r-gtr-8 {
    grid-template-rows: repeat(8, minmax(0, 1fr));
  }

  .lg\:rt-r-gtr-9 {
    grid-template-rows: repeat(9, minmax(0, 1fr));
  }
}

@media (min-width: 1640px) {
  .xl\:rt-r-gtr {
    grid-template-rows: var(--grid-template-rows-xl);
  }

  .xl\:rt-r-gtr-1 {
    grid-template-rows: minmax(0, 1fr);
  }

  .xl\:rt-r-gtr-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr));
  }

  .xl\:rt-r-gtr-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr));
  }

  .xl\:rt-r-gtr-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr));
  }

  .xl\:rt-r-gtr-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr));
  }

  .xl\:rt-r-gtr-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr));
  }

  .xl\:rt-r-gtr-7 {
    grid-template-rows: repeat(7, minmax(0, 1fr));
  }

  .xl\:rt-r-gtr-8 {
    grid-template-rows: repeat(8, minmax(0, 1fr));
  }

  .xl\:rt-r-gtr-9 {
    grid-template-rows: repeat(9, minmax(0, 1fr));
  }
}

.rt-r-h {
  height: var(--height);
}

@media (min-width: 520px) {
  .xs\:rt-r-h {
    height: var(--height-xs);
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-h {
    height: var(--height-sm);
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-h {
    height: var(--height-md);
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-h {
    height: var(--height-lg);
  }
}

@media (min-width: 1640px) {
  .xl\:rt-r-h {
    height: var(--height-xl);
  }
}

.rt-r-min-h {
  min-height: var(--min-height);
}

@media (min-width: 520px) {
  .xs\:rt-r-min-h {
    min-height: var(--min-height-xs);
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-min-h {
    min-height: var(--min-height-sm);
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-min-h {
    min-height: var(--min-height-md);
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-min-h {
    min-height: var(--min-height-lg);
  }
}

@media (min-width: 1640px) {
  .xl\:rt-r-min-h {
    min-height: var(--min-height-xl);
  }
}

.rt-r-max-h {
  max-height: var(--max-height);
}

@media (min-width: 520px) {
  .xs\:rt-r-max-h {
    max-height: var(--max-height-xs);
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-max-h {
    max-height: var(--max-height-sm);
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-max-h {
    max-height: var(--max-height-md);
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-max-h {
    max-height: var(--max-height-lg);
  }
}

@media (min-width: 1640px) {
  .xl\:rt-r-max-h {
    max-height: var(--max-height-xl);
  }
}

.rt-r-inset {
  inset: var(--inset);
}

.rt-r-inset-0 {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.rt-r-inset-1 {
  inset: var(--space-1);
}

.rt-r-inset-2 {
  inset: var(--space-2);
}

.rt-r-inset-3 {
  inset: var(--space-3);
}

.rt-r-inset-4 {
  inset: var(--space-4);
}

.rt-r-inset-5 {
  inset: var(--space-5);
}

.rt-r-inset-6 {
  inset: var(--space-6);
}

.rt-r-inset-7 {
  inset: var(--space-7);
}

.rt-r-inset-8 {
  inset: var(--space-8);
}

.rt-r-inset-9 {
  inset: var(--space-9);
}

@media (min-width: 520px) {
  .xs\:rt-r-inset {
    inset: var(--inset-xs);
  }

  .xs\:rt-r-inset-0 {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .xs\:rt-r-inset-1 {
    inset: var(--space-1);
  }

  .xs\:rt-r-inset-2 {
    inset: var(--space-2);
  }

  .xs\:rt-r-inset-3 {
    inset: var(--space-3);
  }

  .xs\:rt-r-inset-4 {
    inset: var(--space-4);
  }

  .xs\:rt-r-inset-5 {
    inset: var(--space-5);
  }

  .xs\:rt-r-inset-6 {
    inset: var(--space-6);
  }

  .xs\:rt-r-inset-7 {
    inset: var(--space-7);
  }

  .xs\:rt-r-inset-8 {
    inset: var(--space-8);
  }

  .xs\:rt-r-inset-9 {
    inset: var(--space-9);
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-inset {
    inset: var(--inset-sm);
  }

  .sm\:rt-r-inset-0 {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .sm\:rt-r-inset-1 {
    inset: var(--space-1);
  }

  .sm\:rt-r-inset-2 {
    inset: var(--space-2);
  }

  .sm\:rt-r-inset-3 {
    inset: var(--space-3);
  }

  .sm\:rt-r-inset-4 {
    inset: var(--space-4);
  }

  .sm\:rt-r-inset-5 {
    inset: var(--space-5);
  }

  .sm\:rt-r-inset-6 {
    inset: var(--space-6);
  }

  .sm\:rt-r-inset-7 {
    inset: var(--space-7);
  }

  .sm\:rt-r-inset-8 {
    inset: var(--space-8);
  }

  .sm\:rt-r-inset-9 {
    inset: var(--space-9);
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-inset {
    inset: var(--inset-md);
  }

  .md\:rt-r-inset-0 {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .md\:rt-r-inset-1 {
    inset: var(--space-1);
  }

  .md\:rt-r-inset-2 {
    inset: var(--space-2);
  }

  .md\:rt-r-inset-3 {
    inset: var(--space-3);
  }

  .md\:rt-r-inset-4 {
    inset: var(--space-4);
  }

  .md\:rt-r-inset-5 {
    inset: var(--space-5);
  }

  .md\:rt-r-inset-6 {
    inset: var(--space-6);
  }

  .md\:rt-r-inset-7 {
    inset: var(--space-7);
  }

  .md\:rt-r-inset-8 {
    inset: var(--space-8);
  }

  .md\:rt-r-inset-9 {
    inset: var(--space-9);
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-inset {
    inset: var(--inset-lg);
  }

  .lg\:rt-r-inset-0 {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .lg\:rt-r-inset-1 {
    inset: var(--space-1);
  }

  .lg\:rt-r-inset-2 {
    inset: var(--space-2);
  }

  .lg\:rt-r-inset-3 {
    inset: var(--space-3);
  }

  .lg\:rt-r-inset-4 {
    inset: var(--space-4);
  }

  .lg\:rt-r-inset-5 {
    inset: var(--space-5);
  }

  .lg\:rt-r-inset-6 {
    inset: var(--space-6);
  }

  .lg\:rt-r-inset-7 {
    inset: var(--space-7);
  }

  .lg\:rt-r-inset-8 {
    inset: var(--space-8);
  }

  .lg\:rt-r-inset-9 {
    inset: var(--space-9);
  }
}

@media (min-width: 1640px) {
  .xl\:rt-r-inset {
    inset: var(--inset-xl);
  }

  .xl\:rt-r-inset-0 {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .xl\:rt-r-inset-1 {
    inset: var(--space-1);
  }

  .xl\:rt-r-inset-2 {
    inset: var(--space-2);
  }

  .xl\:rt-r-inset-3 {
    inset: var(--space-3);
  }

  .xl\:rt-r-inset-4 {
    inset: var(--space-4);
  }

  .xl\:rt-r-inset-5 {
    inset: var(--space-5);
  }

  .xl\:rt-r-inset-6 {
    inset: var(--space-6);
  }

  .xl\:rt-r-inset-7 {
    inset: var(--space-7);
  }

  .xl\:rt-r-inset-8 {
    inset: var(--space-8);
  }

  .xl\:rt-r-inset-9 {
    inset: var(--space-9);
  }
}

.rt-r-top {
  top: var(--top);
}

.rt-r-top-0 {
  top: 0;
}

.rt-r-top-1 {
  top: var(--space-1);
}

.rt-r-top-2 {
  top: var(--space-2);
}

.rt-r-top-3 {
  top: var(--space-3);
}

.rt-r-top-4 {
  top: var(--space-4);
}

.rt-r-top-5 {
  top: var(--space-5);
}

.rt-r-top-6 {
  top: var(--space-6);
}

.rt-r-top-7 {
  top: var(--space-7);
}

.rt-r-top-8 {
  top: var(--space-8);
}

.rt-r-top-9 {
  top: var(--space-9);
}

@media (min-width: 520px) {
  .xs\:rt-r-top {
    top: var(--top-xs);
  }

  .xs\:rt-r-top-0 {
    top: 0;
  }

  .xs\:rt-r-top-1 {
    top: var(--space-1);
  }

  .xs\:rt-r-top-2 {
    top: var(--space-2);
  }

  .xs\:rt-r-top-3 {
    top: var(--space-3);
  }

  .xs\:rt-r-top-4 {
    top: var(--space-4);
  }

  .xs\:rt-r-top-5 {
    top: var(--space-5);
  }

  .xs\:rt-r-top-6 {
    top: var(--space-6);
  }

  .xs\:rt-r-top-7 {
    top: var(--space-7);
  }

  .xs\:rt-r-top-8 {
    top: var(--space-8);
  }

  .xs\:rt-r-top-9 {
    top: var(--space-9);
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-top {
    top: var(--top-sm);
  }

  .sm\:rt-r-top-0 {
    top: 0;
  }

  .sm\:rt-r-top-1 {
    top: var(--space-1);
  }

  .sm\:rt-r-top-2 {
    top: var(--space-2);
  }

  .sm\:rt-r-top-3 {
    top: var(--space-3);
  }

  .sm\:rt-r-top-4 {
    top: var(--space-4);
  }

  .sm\:rt-r-top-5 {
    top: var(--space-5);
  }

  .sm\:rt-r-top-6 {
    top: var(--space-6);
  }

  .sm\:rt-r-top-7 {
    top: var(--space-7);
  }

  .sm\:rt-r-top-8 {
    top: var(--space-8);
  }

  .sm\:rt-r-top-9 {
    top: var(--space-9);
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-top {
    top: var(--top-md);
  }

  .md\:rt-r-top-0 {
    top: 0;
  }

  .md\:rt-r-top-1 {
    top: var(--space-1);
  }

  .md\:rt-r-top-2 {
    top: var(--space-2);
  }

  .md\:rt-r-top-3 {
    top: var(--space-3);
  }

  .md\:rt-r-top-4 {
    top: var(--space-4);
  }

  .md\:rt-r-top-5 {
    top: var(--space-5);
  }

  .md\:rt-r-top-6 {
    top: var(--space-6);
  }

  .md\:rt-r-top-7 {
    top: var(--space-7);
  }

  .md\:rt-r-top-8 {
    top: var(--space-8);
  }

  .md\:rt-r-top-9 {
    top: var(--space-9);
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-top {
    top: var(--top-lg);
  }

  .lg\:rt-r-top-0 {
    top: 0;
  }

  .lg\:rt-r-top-1 {
    top: var(--space-1);
  }

  .lg\:rt-r-top-2 {
    top: var(--space-2);
  }

  .lg\:rt-r-top-3 {
    top: var(--space-3);
  }

  .lg\:rt-r-top-4 {
    top: var(--space-4);
  }

  .lg\:rt-r-top-5 {
    top: var(--space-5);
  }

  .lg\:rt-r-top-6 {
    top: var(--space-6);
  }

  .lg\:rt-r-top-7 {
    top: var(--space-7);
  }

  .lg\:rt-r-top-8 {
    top: var(--space-8);
  }

  .lg\:rt-r-top-9 {
    top: var(--space-9);
  }
}

@media (min-width: 1640px) {
  .xl\:rt-r-top {
    top: var(--top-xl);
  }

  .xl\:rt-r-top-0 {
    top: 0;
  }

  .xl\:rt-r-top-1 {
    top: var(--space-1);
  }

  .xl\:rt-r-top-2 {
    top: var(--space-2);
  }

  .xl\:rt-r-top-3 {
    top: var(--space-3);
  }

  .xl\:rt-r-top-4 {
    top: var(--space-4);
  }

  .xl\:rt-r-top-5 {
    top: var(--space-5);
  }

  .xl\:rt-r-top-6 {
    top: var(--space-6);
  }

  .xl\:rt-r-top-7 {
    top: var(--space-7);
  }

  .xl\:rt-r-top-8 {
    top: var(--space-8);
  }

  .xl\:rt-r-top-9 {
    top: var(--space-9);
  }
}

.rt-r-right {
  right: var(--right);
}

.rt-r-right-0 {
  right: 0;
}

.rt-r-right-1 {
  right: var(--space-1);
}

.rt-r-right-2 {
  right: var(--space-2);
}

.rt-r-right-3 {
  right: var(--space-3);
}

.rt-r-right-4 {
  right: var(--space-4);
}

.rt-r-right-5 {
  right: var(--space-5);
}

.rt-r-right-6 {
  right: var(--space-6);
}

.rt-r-right-7 {
  right: var(--space-7);
}

.rt-r-right-8 {
  right: var(--space-8);
}

.rt-r-right-9 {
  right: var(--space-9);
}

@media (min-width: 520px) {
  .xs\:rt-r-right {
    right: var(--right-xs);
  }

  .xs\:rt-r-right-0 {
    right: 0;
  }

  .xs\:rt-r-right-1 {
    right: var(--space-1);
  }

  .xs\:rt-r-right-2 {
    right: var(--space-2);
  }

  .xs\:rt-r-right-3 {
    right: var(--space-3);
  }

  .xs\:rt-r-right-4 {
    right: var(--space-4);
  }

  .xs\:rt-r-right-5 {
    right: var(--space-5);
  }

  .xs\:rt-r-right-6 {
    right: var(--space-6);
  }

  .xs\:rt-r-right-7 {
    right: var(--space-7);
  }

  .xs\:rt-r-right-8 {
    right: var(--space-8);
  }

  .xs\:rt-r-right-9 {
    right: var(--space-9);
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-right {
    right: var(--right-sm);
  }

  .sm\:rt-r-right-0 {
    right: 0;
  }

  .sm\:rt-r-right-1 {
    right: var(--space-1);
  }

  .sm\:rt-r-right-2 {
    right: var(--space-2);
  }

  .sm\:rt-r-right-3 {
    right: var(--space-3);
  }

  .sm\:rt-r-right-4 {
    right: var(--space-4);
  }

  .sm\:rt-r-right-5 {
    right: var(--space-5);
  }

  .sm\:rt-r-right-6 {
    right: var(--space-6);
  }

  .sm\:rt-r-right-7 {
    right: var(--space-7);
  }

  .sm\:rt-r-right-8 {
    right: var(--space-8);
  }

  .sm\:rt-r-right-9 {
    right: var(--space-9);
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-right {
    right: var(--right-md);
  }

  .md\:rt-r-right-0 {
    right: 0;
  }

  .md\:rt-r-right-1 {
    right: var(--space-1);
  }

  .md\:rt-r-right-2 {
    right: var(--space-2);
  }

  .md\:rt-r-right-3 {
    right: var(--space-3);
  }

  .md\:rt-r-right-4 {
    right: var(--space-4);
  }

  .md\:rt-r-right-5 {
    right: var(--space-5);
  }

  .md\:rt-r-right-6 {
    right: var(--space-6);
  }

  .md\:rt-r-right-7 {
    right: var(--space-7);
  }

  .md\:rt-r-right-8 {
    right: var(--space-8);
  }

  .md\:rt-r-right-9 {
    right: var(--space-9);
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-right {
    right: var(--right-lg);
  }

  .lg\:rt-r-right-0 {
    right: 0;
  }

  .lg\:rt-r-right-1 {
    right: var(--space-1);
  }

  .lg\:rt-r-right-2 {
    right: var(--space-2);
  }

  .lg\:rt-r-right-3 {
    right: var(--space-3);
  }

  .lg\:rt-r-right-4 {
    right: var(--space-4);
  }

  .lg\:rt-r-right-5 {
    right: var(--space-5);
  }

  .lg\:rt-r-right-6 {
    right: var(--space-6);
  }

  .lg\:rt-r-right-7 {
    right: var(--space-7);
  }

  .lg\:rt-r-right-8 {
    right: var(--space-8);
  }

  .lg\:rt-r-right-9 {
    right: var(--space-9);
  }
}

@media (min-width: 1640px) {
  .xl\:rt-r-right {
    right: var(--right-xl);
  }

  .xl\:rt-r-right-0 {
    right: 0;
  }

  .xl\:rt-r-right-1 {
    right: var(--space-1);
  }

  .xl\:rt-r-right-2 {
    right: var(--space-2);
  }

  .xl\:rt-r-right-3 {
    right: var(--space-3);
  }

  .xl\:rt-r-right-4 {
    right: var(--space-4);
  }

  .xl\:rt-r-right-5 {
    right: var(--space-5);
  }

  .xl\:rt-r-right-6 {
    right: var(--space-6);
  }

  .xl\:rt-r-right-7 {
    right: var(--space-7);
  }

  .xl\:rt-r-right-8 {
    right: var(--space-8);
  }

  .xl\:rt-r-right-9 {
    right: var(--space-9);
  }
}

.rt-r-bottom {
  bottom: var(--bottom);
}

.rt-r-bottom-0 {
  bottom: 0;
}

.rt-r-bottom-1 {
  bottom: var(--space-1);
}

.rt-r-bottom-2 {
  bottom: var(--space-2);
}

.rt-r-bottom-3 {
  bottom: var(--space-3);
}

.rt-r-bottom-4 {
  bottom: var(--space-4);
}

.rt-r-bottom-5 {
  bottom: var(--space-5);
}

.rt-r-bottom-6 {
  bottom: var(--space-6);
}

.rt-r-bottom-7 {
  bottom: var(--space-7);
}

.rt-r-bottom-8 {
  bottom: var(--space-8);
}

.rt-r-bottom-9 {
  bottom: var(--space-9);
}

@media (min-width: 520px) {
  .xs\:rt-r-bottom {
    bottom: var(--bottom-xs);
  }

  .xs\:rt-r-bottom-0 {
    bottom: 0;
  }

  .xs\:rt-r-bottom-1 {
    bottom: var(--space-1);
  }

  .xs\:rt-r-bottom-2 {
    bottom: var(--space-2);
  }

  .xs\:rt-r-bottom-3 {
    bottom: var(--space-3);
  }

  .xs\:rt-r-bottom-4 {
    bottom: var(--space-4);
  }

  .xs\:rt-r-bottom-5 {
    bottom: var(--space-5);
  }

  .xs\:rt-r-bottom-6 {
    bottom: var(--space-6);
  }

  .xs\:rt-r-bottom-7 {
    bottom: var(--space-7);
  }

  .xs\:rt-r-bottom-8 {
    bottom: var(--space-8);
  }

  .xs\:rt-r-bottom-9 {
    bottom: var(--space-9);
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-bottom {
    bottom: var(--bottom-sm);
  }

  .sm\:rt-r-bottom-0 {
    bottom: 0;
  }

  .sm\:rt-r-bottom-1 {
    bottom: var(--space-1);
  }

  .sm\:rt-r-bottom-2 {
    bottom: var(--space-2);
  }

  .sm\:rt-r-bottom-3 {
    bottom: var(--space-3);
  }

  .sm\:rt-r-bottom-4 {
    bottom: var(--space-4);
  }

  .sm\:rt-r-bottom-5 {
    bottom: var(--space-5);
  }

  .sm\:rt-r-bottom-6 {
    bottom: var(--space-6);
  }

  .sm\:rt-r-bottom-7 {
    bottom: var(--space-7);
  }

  .sm\:rt-r-bottom-8 {
    bottom: var(--space-8);
  }

  .sm\:rt-r-bottom-9 {
    bottom: var(--space-9);
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-bottom {
    bottom: var(--bottom-md);
  }

  .md\:rt-r-bottom-0 {
    bottom: 0;
  }

  .md\:rt-r-bottom-1 {
    bottom: var(--space-1);
  }

  .md\:rt-r-bottom-2 {
    bottom: var(--space-2);
  }

  .md\:rt-r-bottom-3 {
    bottom: var(--space-3);
  }

  .md\:rt-r-bottom-4 {
    bottom: var(--space-4);
  }

  .md\:rt-r-bottom-5 {
    bottom: var(--space-5);
  }

  .md\:rt-r-bottom-6 {
    bottom: var(--space-6);
  }

  .md\:rt-r-bottom-7 {
    bottom: var(--space-7);
  }

  .md\:rt-r-bottom-8 {
    bottom: var(--space-8);
  }

  .md\:rt-r-bottom-9 {
    bottom: var(--space-9);
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-bottom {
    bottom: var(--bottom-lg);
  }

  .lg\:rt-r-bottom-0 {
    bottom: 0;
  }

  .lg\:rt-r-bottom-1 {
    bottom: var(--space-1);
  }

  .lg\:rt-r-bottom-2 {
    bottom: var(--space-2);
  }

  .lg\:rt-r-bottom-3 {
    bottom: var(--space-3);
  }

  .lg\:rt-r-bottom-4 {
    bottom: var(--space-4);
  }

  .lg\:rt-r-bottom-5 {
    bottom: var(--space-5);
  }

  .lg\:rt-r-bottom-6 {
    bottom: var(--space-6);
  }

  .lg\:rt-r-bottom-7 {
    bottom: var(--space-7);
  }

  .lg\:rt-r-bottom-8 {
    bottom: var(--space-8);
  }

  .lg\:rt-r-bottom-9 {
    bottom: var(--space-9);
  }
}

@media (min-width: 1640px) {
  .xl\:rt-r-bottom {
    bottom: var(--bottom-xl);
  }

  .xl\:rt-r-bottom-0 {
    bottom: 0;
  }

  .xl\:rt-r-bottom-1 {
    bottom: var(--space-1);
  }

  .xl\:rt-r-bottom-2 {
    bottom: var(--space-2);
  }

  .xl\:rt-r-bottom-3 {
    bottom: var(--space-3);
  }

  .xl\:rt-r-bottom-4 {
    bottom: var(--space-4);
  }

  .xl\:rt-r-bottom-5 {
    bottom: var(--space-5);
  }

  .xl\:rt-r-bottom-6 {
    bottom: var(--space-6);
  }

  .xl\:rt-r-bottom-7 {
    bottom: var(--space-7);
  }

  .xl\:rt-r-bottom-8 {
    bottom: var(--space-8);
  }

  .xl\:rt-r-bottom-9 {
    bottom: var(--space-9);
  }
}

.rt-r-left {
  left: var(--left);
}

.rt-r-left-0 {
  left: 0;
}

.rt-r-left-1 {
  left: var(--space-1);
}

.rt-r-left-2 {
  left: var(--space-2);
}

.rt-r-left-3 {
  left: var(--space-3);
}

.rt-r-left-4 {
  left: var(--space-4);
}

.rt-r-left-5 {
  left: var(--space-5);
}

.rt-r-left-6 {
  left: var(--space-6);
}

.rt-r-left-7 {
  left: var(--space-7);
}

.rt-r-left-8 {
  left: var(--space-8);
}

.rt-r-left-9 {
  left: var(--space-9);
}

@media (min-width: 520px) {
  .xs\:rt-r-left {
    left: var(--left-xs);
  }

  .xs\:rt-r-left-0 {
    left: 0;
  }

  .xs\:rt-r-left-1 {
    left: var(--space-1);
  }

  .xs\:rt-r-left-2 {
    left: var(--space-2);
  }

  .xs\:rt-r-left-3 {
    left: var(--space-3);
  }

  .xs\:rt-r-left-4 {
    left: var(--space-4);
  }

  .xs\:rt-r-left-5 {
    left: var(--space-5);
  }

  .xs\:rt-r-left-6 {
    left: var(--space-6);
  }

  .xs\:rt-r-left-7 {
    left: var(--space-7);
  }

  .xs\:rt-r-left-8 {
    left: var(--space-8);
  }

  .xs\:rt-r-left-9 {
    left: var(--space-9);
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-left {
    left: var(--left-sm);
  }

  .sm\:rt-r-left-0 {
    left: 0;
  }

  .sm\:rt-r-left-1 {
    left: var(--space-1);
  }

  .sm\:rt-r-left-2 {
    left: var(--space-2);
  }

  .sm\:rt-r-left-3 {
    left: var(--space-3);
  }

  .sm\:rt-r-left-4 {
    left: var(--space-4);
  }

  .sm\:rt-r-left-5 {
    left: var(--space-5);
  }

  .sm\:rt-r-left-6 {
    left: var(--space-6);
  }

  .sm\:rt-r-left-7 {
    left: var(--space-7);
  }

  .sm\:rt-r-left-8 {
    left: var(--space-8);
  }

  .sm\:rt-r-left-9 {
    left: var(--space-9);
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-left {
    left: var(--left-md);
  }

  .md\:rt-r-left-0 {
    left: 0;
  }

  .md\:rt-r-left-1 {
    left: var(--space-1);
  }

  .md\:rt-r-left-2 {
    left: var(--space-2);
  }

  .md\:rt-r-left-3 {
    left: var(--space-3);
  }

  .md\:rt-r-left-4 {
    left: var(--space-4);
  }

  .md\:rt-r-left-5 {
    left: var(--space-5);
  }

  .md\:rt-r-left-6 {
    left: var(--space-6);
  }

  .md\:rt-r-left-7 {
    left: var(--space-7);
  }

  .md\:rt-r-left-8 {
    left: var(--space-8);
  }

  .md\:rt-r-left-9 {
    left: var(--space-9);
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-left {
    left: var(--left-lg);
  }

  .lg\:rt-r-left-0 {
    left: 0;
  }

  .lg\:rt-r-left-1 {
    left: var(--space-1);
  }

  .lg\:rt-r-left-2 {
    left: var(--space-2);
  }

  .lg\:rt-r-left-3 {
    left: var(--space-3);
  }

  .lg\:rt-r-left-4 {
    left: var(--space-4);
  }

  .lg\:rt-r-left-5 {
    left: var(--space-5);
  }

  .lg\:rt-r-left-6 {
    left: var(--space-6);
  }

  .lg\:rt-r-left-7 {
    left: var(--space-7);
  }

  .lg\:rt-r-left-8 {
    left: var(--space-8);
  }

  .lg\:rt-r-left-9 {
    left: var(--space-9);
  }
}

@media (min-width: 1640px) {
  .xl\:rt-r-left {
    left: var(--left-xl);
  }

  .xl\:rt-r-left-0 {
    left: 0;
  }

  .xl\:rt-r-left-1 {
    left: var(--space-1);
  }

  .xl\:rt-r-left-2 {
    left: var(--space-2);
  }

  .xl\:rt-r-left-3 {
    left: var(--space-3);
  }

  .xl\:rt-r-left-4 {
    left: var(--space-4);
  }

  .xl\:rt-r-left-5 {
    left: var(--space-5);
  }

  .xl\:rt-r-left-6 {
    left: var(--space-6);
  }

  .xl\:rt-r-left-7 {
    left: var(--space-7);
  }

  .xl\:rt-r-left-8 {
    left: var(--space-8);
  }

  .xl\:rt-r-left-9 {
    left: var(--space-9);
  }
}

.rt-r-jc-start {
  justify-content: flex-start;
}

.rt-r-jc-center {
  justify-content: center;
}

.rt-r-jc-end {
  justify-content: flex-end;
}

.rt-r-jc-space-between {
  justify-content: space-between;
}

@media (min-width: 520px) {
  .xs\:rt-r-jc-start {
    justify-content: flex-start;
  }

  .xs\:rt-r-jc-center {
    justify-content: center;
  }

  .xs\:rt-r-jc-end {
    justify-content: flex-end;
  }

  .xs\:rt-r-jc-space-between {
    justify-content: space-between;
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-jc-start {
    justify-content: flex-start;
  }

  .sm\:rt-r-jc-center {
    justify-content: center;
  }

  .sm\:rt-r-jc-end {
    justify-content: flex-end;
  }

  .sm\:rt-r-jc-space-between {
    justify-content: space-between;
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-jc-start {
    justify-content: flex-start;
  }

  .md\:rt-r-jc-center {
    justify-content: center;
  }

  .md\:rt-r-jc-end {
    justify-content: flex-end;
  }

  .md\:rt-r-jc-space-between {
    justify-content: space-between;
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-jc-start {
    justify-content: flex-start;
  }

  .lg\:rt-r-jc-center {
    justify-content: center;
  }

  .lg\:rt-r-jc-end {
    justify-content: flex-end;
  }

  .lg\:rt-r-jc-space-between {
    justify-content: space-between;
  }
}

@media (min-width: 1640px) {
  .xl\:rt-r-jc-start {
    justify-content: flex-start;
  }

  .xl\:rt-r-jc-center {
    justify-content: center;
  }

  .xl\:rt-r-jc-end {
    justify-content: flex-end;
  }

  .xl\:rt-r-jc-space-between {
    justify-content: space-between;
  }
}

.rt-r-m, .rt-r-m-0, .rt-r-m-1, .rt-r-m-2, .rt-r-m-3, .rt-r-m-4, .rt-r-m-5, .rt-r-m-6, .rt-r-m-7, .rt-r-m-8, .rt-r-m-9, .-rt-r-m-1, .-rt-r-m-2, .-rt-r-m-3, .-rt-r-m-4, .-rt-r-m-5, .-rt-r-m-6, .-rt-r-m-7, .-rt-r-m-8, .-rt-r-m-9 {
  margin-top: var(--margin-top-override, var(--margin-top));
  margin-right: var(--margin-right-override, var(--margin-right));
  margin-bottom: var(--margin-bottom-override, var(--margin-bottom));
  margin-left: var(--margin-left-override, var(--margin-left));
}

.rt-r-m {
  --margin-top: var(--m);
  --margin-right: var(--m);
  --margin-bottom: var(--m);
  --margin-left: var(--m);
}

.rt-r-m-0 {
  --margin-top: 0px;
  --margin-right: 0px;
  --margin-bottom: 0px;
  --margin-left: 0px;
}

.rt-r-m-1 {
  --margin-top: var(--space-1);
  --margin-right: var(--space-1);
  --margin-bottom: var(--space-1);
  --margin-left: var(--space-1);
}

.rt-r-m-2 {
  --margin-top: var(--space-2);
  --margin-right: var(--space-2);
  --margin-bottom: var(--space-2);
  --margin-left: var(--space-2);
}

.rt-r-m-3 {
  --margin-top: var(--space-3);
  --margin-right: var(--space-3);
  --margin-bottom: var(--space-3);
  --margin-left: var(--space-3);
}

.rt-r-m-4 {
  --margin-top: var(--space-4);
  --margin-right: var(--space-4);
  --margin-bottom: var(--space-4);
  --margin-left: var(--space-4);
}

.rt-r-m-5 {
  --margin-top: var(--space-5);
  --margin-right: var(--space-5);
  --margin-bottom: var(--space-5);
  --margin-left: var(--space-5);
}

.rt-r-m-6 {
  --margin-top: var(--space-6);
  --margin-right: var(--space-6);
  --margin-bottom: var(--space-6);
  --margin-left: var(--space-6);
}

.rt-r-m-7 {
  --margin-top: var(--space-7);
  --margin-right: var(--space-7);
  --margin-bottom: var(--space-7);
  --margin-left: var(--space-7);
}

.rt-r-m-8 {
  --margin-top: var(--space-8);
  --margin-right: var(--space-8);
  --margin-bottom: var(--space-8);
  --margin-left: var(--space-8);
}

.rt-r-m-9 {
  --margin-top: var(--space-9);
  --margin-right: var(--space-9);
  --margin-bottom: var(--space-9);
  --margin-left: var(--space-9);
}

.-rt-r-m-1 {
  --margin-top: calc(-1 * var(--space-1));
  --margin-right: calc(-1 * var(--space-1));
  --margin-bottom: calc(-1 * var(--space-1));
  --margin-left: calc(-1 * var(--space-1));
}

.-rt-r-m-2 {
  --margin-top: calc(-1 * var(--space-2));
  --margin-right: calc(-1 * var(--space-2));
  --margin-bottom: calc(-1 * var(--space-2));
  --margin-left: calc(-1 * var(--space-2));
}

.-rt-r-m-3 {
  --margin-top: calc(-1 * var(--space-3));
  --margin-right: calc(-1 * var(--space-3));
  --margin-bottom: calc(-1 * var(--space-3));
  --margin-left: calc(-1 * var(--space-3));
}

.-rt-r-m-4 {
  --margin-top: calc(-1 * var(--space-4));
  --margin-right: calc(-1 * var(--space-4));
  --margin-bottom: calc(-1 * var(--space-4));
  --margin-left: calc(-1 * var(--space-4));
}

.-rt-r-m-5 {
  --margin-top: calc(-1 * var(--space-5));
  --margin-right: calc(-1 * var(--space-5));
  --margin-bottom: calc(-1 * var(--space-5));
  --margin-left: calc(-1 * var(--space-5));
}

.-rt-r-m-6 {
  --margin-top: calc(-1 * var(--space-6));
  --margin-right: calc(-1 * var(--space-6));
  --margin-bottom: calc(-1 * var(--space-6));
  --margin-left: calc(-1 * var(--space-6));
}

.-rt-r-m-7 {
  --margin-top: calc(-1 * var(--space-7));
  --margin-right: calc(-1 * var(--space-7));
  --margin-bottom: calc(-1 * var(--space-7));
  --margin-left: calc(-1 * var(--space-7));
}

.-rt-r-m-8 {
  --margin-top: calc(-1 * var(--space-8));
  --margin-right: calc(-1 * var(--space-8));
  --margin-bottom: calc(-1 * var(--space-8));
  --margin-left: calc(-1 * var(--space-8));
}

.-rt-r-m-9 {
  --margin-top: calc(-1 * var(--space-9));
  --margin-right: calc(-1 * var(--space-9));
  --margin-bottom: calc(-1 * var(--space-9));
  --margin-left: calc(-1 * var(--space-9));
}

@media (min-width: 520px) {
  .xs\:rt-r-m, .xs\:rt-r-m-0, .xs\:rt-r-m-1, .xs\:rt-r-m-2, .xs\:rt-r-m-3, .xs\:rt-r-m-4, .xs\:rt-r-m-5, .xs\:rt-r-m-6, .xs\:rt-r-m-7, .xs\:rt-r-m-8, .xs\:rt-r-m-9, .xs\:-rt-r-m-1, .xs\:-rt-r-m-2, .xs\:-rt-r-m-3, .xs\:-rt-r-m-4, .xs\:-rt-r-m-5, .xs\:-rt-r-m-6, .xs\:-rt-r-m-7, .xs\:-rt-r-m-8, .xs\:-rt-r-m-9 {
    margin-top: var(--margin-top-override, var(--margin-top));
    margin-right: var(--margin-right-override, var(--margin-right));
    margin-bottom: var(--margin-bottom-override, var(--margin-bottom));
    margin-left: var(--margin-left-override, var(--margin-left));
  }

  .xs\:rt-r-m {
    --margin-top: var(--m-xs);
    --margin-right: var(--m-xs);
    --margin-bottom: var(--m-xs);
    --margin-left: var(--m-xs);
  }

  .xs\:rt-r-m-0 {
    --margin-top: 0px;
    --margin-right: 0px;
    --margin-bottom: 0px;
    --margin-left: 0px;
  }

  .xs\:rt-r-m-1 {
    --margin-top: var(--space-1);
    --margin-right: var(--space-1);
    --margin-bottom: var(--space-1);
    --margin-left: var(--space-1);
  }

  .xs\:rt-r-m-2 {
    --margin-top: var(--space-2);
    --margin-right: var(--space-2);
    --margin-bottom: var(--space-2);
    --margin-left: var(--space-2);
  }

  .xs\:rt-r-m-3 {
    --margin-top: var(--space-3);
    --margin-right: var(--space-3);
    --margin-bottom: var(--space-3);
    --margin-left: var(--space-3);
  }

  .xs\:rt-r-m-4 {
    --margin-top: var(--space-4);
    --margin-right: var(--space-4);
    --margin-bottom: var(--space-4);
    --margin-left: var(--space-4);
  }

  .xs\:rt-r-m-5 {
    --margin-top: var(--space-5);
    --margin-right: var(--space-5);
    --margin-bottom: var(--space-5);
    --margin-left: var(--space-5);
  }

  .xs\:rt-r-m-6 {
    --margin-top: var(--space-6);
    --margin-right: var(--space-6);
    --margin-bottom: var(--space-6);
    --margin-left: var(--space-6);
  }

  .xs\:rt-r-m-7 {
    --margin-top: var(--space-7);
    --margin-right: var(--space-7);
    --margin-bottom: var(--space-7);
    --margin-left: var(--space-7);
  }

  .xs\:rt-r-m-8 {
    --margin-top: var(--space-8);
    --margin-right: var(--space-8);
    --margin-bottom: var(--space-8);
    --margin-left: var(--space-8);
  }

  .xs\:rt-r-m-9 {
    --margin-top: var(--space-9);
    --margin-right: var(--space-9);
    --margin-bottom: var(--space-9);
    --margin-left: var(--space-9);
  }

  .xs\:-rt-r-m-1 {
    --margin-top: calc(-1 * var(--space-1));
    --margin-right: calc(-1 * var(--space-1));
    --margin-bottom: calc(-1 * var(--space-1));
    --margin-left: calc(-1 * var(--space-1));
  }

  .xs\:-rt-r-m-2 {
    --margin-top: calc(-1 * var(--space-2));
    --margin-right: calc(-1 * var(--space-2));
    --margin-bottom: calc(-1 * var(--space-2));
    --margin-left: calc(-1 * var(--space-2));
  }

  .xs\:-rt-r-m-3 {
    --margin-top: calc(-1 * var(--space-3));
    --margin-right: calc(-1 * var(--space-3));
    --margin-bottom: calc(-1 * var(--space-3));
    --margin-left: calc(-1 * var(--space-3));
  }

  .xs\:-rt-r-m-4 {
    --margin-top: calc(-1 * var(--space-4));
    --margin-right: calc(-1 * var(--space-4));
    --margin-bottom: calc(-1 * var(--space-4));
    --margin-left: calc(-1 * var(--space-4));
  }

  .xs\:-rt-r-m-5 {
    --margin-top: calc(-1 * var(--space-5));
    --margin-right: calc(-1 * var(--space-5));
    --margin-bottom: calc(-1 * var(--space-5));
    --margin-left: calc(-1 * var(--space-5));
  }

  .xs\:-rt-r-m-6 {
    --margin-top: calc(-1 * var(--space-6));
    --margin-right: calc(-1 * var(--space-6));
    --margin-bottom: calc(-1 * var(--space-6));
    --margin-left: calc(-1 * var(--space-6));
  }

  .xs\:-rt-r-m-7 {
    --margin-top: calc(-1 * var(--space-7));
    --margin-right: calc(-1 * var(--space-7));
    --margin-bottom: calc(-1 * var(--space-7));
    --margin-left: calc(-1 * var(--space-7));
  }

  .xs\:-rt-r-m-8 {
    --margin-top: calc(-1 * var(--space-8));
    --margin-right: calc(-1 * var(--space-8));
    --margin-bottom: calc(-1 * var(--space-8));
    --margin-left: calc(-1 * var(--space-8));
  }

  .xs\:-rt-r-m-9 {
    --margin-top: calc(-1 * var(--space-9));
    --margin-right: calc(-1 * var(--space-9));
    --margin-bottom: calc(-1 * var(--space-9));
    --margin-left: calc(-1 * var(--space-9));
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-m, .sm\:rt-r-m-0, .sm\:rt-r-m-1, .sm\:rt-r-m-2, .sm\:rt-r-m-3, .sm\:rt-r-m-4, .sm\:rt-r-m-5, .sm\:rt-r-m-6, .sm\:rt-r-m-7, .sm\:rt-r-m-8, .sm\:rt-r-m-9, .sm\:-rt-r-m-1, .sm\:-rt-r-m-2, .sm\:-rt-r-m-3, .sm\:-rt-r-m-4, .sm\:-rt-r-m-5, .sm\:-rt-r-m-6, .sm\:-rt-r-m-7, .sm\:-rt-r-m-8, .sm\:-rt-r-m-9 {
    margin-top: var(--margin-top-override, var(--margin-top));
    margin-right: var(--margin-right-override, var(--margin-right));
    margin-bottom: var(--margin-bottom-override, var(--margin-bottom));
    margin-left: var(--margin-left-override, var(--margin-left));
  }

  .sm\:rt-r-m {
    --margin-top: var(--m-sm);
    --margin-right: var(--m-sm);
    --margin-bottom: var(--m-sm);
    --margin-left: var(--m-sm);
  }

  .sm\:rt-r-m-0 {
    --margin-top: 0px;
    --margin-right: 0px;
    --margin-bottom: 0px;
    --margin-left: 0px;
  }

  .sm\:rt-r-m-1 {
    --margin-top: var(--space-1);
    --margin-right: var(--space-1);
    --margin-bottom: var(--space-1);
    --margin-left: var(--space-1);
  }

  .sm\:rt-r-m-2 {
    --margin-top: var(--space-2);
    --margin-right: var(--space-2);
    --margin-bottom: var(--space-2);
    --margin-left: var(--space-2);
  }

  .sm\:rt-r-m-3 {
    --margin-top: var(--space-3);
    --margin-right: var(--space-3);
    --margin-bottom: var(--space-3);
    --margin-left: var(--space-3);
  }

  .sm\:rt-r-m-4 {
    --margin-top: var(--space-4);
    --margin-right: var(--space-4);
    --margin-bottom: var(--space-4);
    --margin-left: var(--space-4);
  }

  .sm\:rt-r-m-5 {
    --margin-top: var(--space-5);
    --margin-right: var(--space-5);
    --margin-bottom: var(--space-5);
    --margin-left: var(--space-5);
  }

  .sm\:rt-r-m-6 {
    --margin-top: var(--space-6);
    --margin-right: var(--space-6);
    --margin-bottom: var(--space-6);
    --margin-left: var(--space-6);
  }

  .sm\:rt-r-m-7 {
    --margin-top: var(--space-7);
    --margin-right: var(--space-7);
    --margin-bottom: var(--space-7);
    --margin-left: var(--space-7);
  }

  .sm\:rt-r-m-8 {
    --margin-top: var(--space-8);
    --margin-right: var(--space-8);
    --margin-bottom: var(--space-8);
    --margin-left: var(--space-8);
  }

  .sm\:rt-r-m-9 {
    --margin-top: var(--space-9);
    --margin-right: var(--space-9);
    --margin-bottom: var(--space-9);
    --margin-left: var(--space-9);
  }

  .sm\:-rt-r-m-1 {
    --margin-top: calc(-1 * var(--space-1));
    --margin-right: calc(-1 * var(--space-1));
    --margin-bottom: calc(-1 * var(--space-1));
    --margin-left: calc(-1 * var(--space-1));
  }

  .sm\:-rt-r-m-2 {
    --margin-top: calc(-1 * var(--space-2));
    --margin-right: calc(-1 * var(--space-2));
    --margin-bottom: calc(-1 * var(--space-2));
    --margin-left: calc(-1 * var(--space-2));
  }

  .sm\:-rt-r-m-3 {
    --margin-top: calc(-1 * var(--space-3));
    --margin-right: calc(-1 * var(--space-3));
    --margin-bottom: calc(-1 * var(--space-3));
    --margin-left: calc(-1 * var(--space-3));
  }

  .sm\:-rt-r-m-4 {
    --margin-top: calc(-1 * var(--space-4));
    --margin-right: calc(-1 * var(--space-4));
    --margin-bottom: calc(-1 * var(--space-4));
    --margin-left: calc(-1 * var(--space-4));
  }

  .sm\:-rt-r-m-5 {
    --margin-top: calc(-1 * var(--space-5));
    --margin-right: calc(-1 * var(--space-5));
    --margin-bottom: calc(-1 * var(--space-5));
    --margin-left: calc(-1 * var(--space-5));
  }

  .sm\:-rt-r-m-6 {
    --margin-top: calc(-1 * var(--space-6));
    --margin-right: calc(-1 * var(--space-6));
    --margin-bottom: calc(-1 * var(--space-6));
    --margin-left: calc(-1 * var(--space-6));
  }

  .sm\:-rt-r-m-7 {
    --margin-top: calc(-1 * var(--space-7));
    --margin-right: calc(-1 * var(--space-7));
    --margin-bottom: calc(-1 * var(--space-7));
    --margin-left: calc(-1 * var(--space-7));
  }

  .sm\:-rt-r-m-8 {
    --margin-top: calc(-1 * var(--space-8));
    --margin-right: calc(-1 * var(--space-8));
    --margin-bottom: calc(-1 * var(--space-8));
    --margin-left: calc(-1 * var(--space-8));
  }

  .sm\:-rt-r-m-9 {
    --margin-top: calc(-1 * var(--space-9));
    --margin-right: calc(-1 * var(--space-9));
    --margin-bottom: calc(-1 * var(--space-9));
    --margin-left: calc(-1 * var(--space-9));
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-m, .md\:rt-r-m-0, .md\:rt-r-m-1, .md\:rt-r-m-2, .md\:rt-r-m-3, .md\:rt-r-m-4, .md\:rt-r-m-5, .md\:rt-r-m-6, .md\:rt-r-m-7, .md\:rt-r-m-8, .md\:rt-r-m-9, .md\:-rt-r-m-1, .md\:-rt-r-m-2, .md\:-rt-r-m-3, .md\:-rt-r-m-4, .md\:-rt-r-m-5, .md\:-rt-r-m-6, .md\:-rt-r-m-7, .md\:-rt-r-m-8, .md\:-rt-r-m-9 {
    margin-top: var(--margin-top-override, var(--margin-top));
    margin-right: var(--margin-right-override, var(--margin-right));
    margin-bottom: var(--margin-bottom-override, var(--margin-bottom));
    margin-left: var(--margin-left-override, var(--margin-left));
  }

  .md\:rt-r-m {
    --margin-top: var(--m-md);
    --margin-right: var(--m-md);
    --margin-bottom: var(--m-md);
    --margin-left: var(--m-md);
  }

  .md\:rt-r-m-0 {
    --margin-top: 0px;
    --margin-right: 0px;
    --margin-bottom: 0px;
    --margin-left: 0px;
  }

  .md\:rt-r-m-1 {
    --margin-top: var(--space-1);
    --margin-right: var(--space-1);
    --margin-bottom: var(--space-1);
    --margin-left: var(--space-1);
  }

  .md\:rt-r-m-2 {
    --margin-top: var(--space-2);
    --margin-right: var(--space-2);
    --margin-bottom: var(--space-2);
    --margin-left: var(--space-2);
  }

  .md\:rt-r-m-3 {
    --margin-top: var(--space-3);
    --margin-right: var(--space-3);
    --margin-bottom: var(--space-3);
    --margin-left: var(--space-3);
  }

  .md\:rt-r-m-4 {
    --margin-top: var(--space-4);
    --margin-right: var(--space-4);
    --margin-bottom: var(--space-4);
    --margin-left: var(--space-4);
  }

  .md\:rt-r-m-5 {
    --margin-top: var(--space-5);
    --margin-right: var(--space-5);
    --margin-bottom: var(--space-5);
    --margin-left: var(--space-5);
  }

  .md\:rt-r-m-6 {
    --margin-top: var(--space-6);
    --margin-right: var(--space-6);
    --margin-bottom: var(--space-6);
    --margin-left: var(--space-6);
  }

  .md\:rt-r-m-7 {
    --margin-top: var(--space-7);
    --margin-right: var(--space-7);
    --margin-bottom: var(--space-7);
    --margin-left: var(--space-7);
  }

  .md\:rt-r-m-8 {
    --margin-top: var(--space-8);
    --margin-right: var(--space-8);
    --margin-bottom: var(--space-8);
    --margin-left: var(--space-8);
  }

  .md\:rt-r-m-9 {
    --margin-top: var(--space-9);
    --margin-right: var(--space-9);
    --margin-bottom: var(--space-9);
    --margin-left: var(--space-9);
  }

  .md\:-rt-r-m-1 {
    --margin-top: calc(-1 * var(--space-1));
    --margin-right: calc(-1 * var(--space-1));
    --margin-bottom: calc(-1 * var(--space-1));
    --margin-left: calc(-1 * var(--space-1));
  }

  .md\:-rt-r-m-2 {
    --margin-top: calc(-1 * var(--space-2));
    --margin-right: calc(-1 * var(--space-2));
    --margin-bottom: calc(-1 * var(--space-2));
    --margin-left: calc(-1 * var(--space-2));
  }

  .md\:-rt-r-m-3 {
    --margin-top: calc(-1 * var(--space-3));
    --margin-right: calc(-1 * var(--space-3));
    --margin-bottom: calc(-1 * var(--space-3));
    --margin-left: calc(-1 * var(--space-3));
  }

  .md\:-rt-r-m-4 {
    --margin-top: calc(-1 * var(--space-4));
    --margin-right: calc(-1 * var(--space-4));
    --margin-bottom: calc(-1 * var(--space-4));
    --margin-left: calc(-1 * var(--space-4));
  }

  .md\:-rt-r-m-5 {
    --margin-top: calc(-1 * var(--space-5));
    --margin-right: calc(-1 * var(--space-5));
    --margin-bottom: calc(-1 * var(--space-5));
    --margin-left: calc(-1 * var(--space-5));
  }

  .md\:-rt-r-m-6 {
    --margin-top: calc(-1 * var(--space-6));
    --margin-right: calc(-1 * var(--space-6));
    --margin-bottom: calc(-1 * var(--space-6));
    --margin-left: calc(-1 * var(--space-6));
  }

  .md\:-rt-r-m-7 {
    --margin-top: calc(-1 * var(--space-7));
    --margin-right: calc(-1 * var(--space-7));
    --margin-bottom: calc(-1 * var(--space-7));
    --margin-left: calc(-1 * var(--space-7));
  }

  .md\:-rt-r-m-8 {
    --margin-top: calc(-1 * var(--space-8));
    --margin-right: calc(-1 * var(--space-8));
    --margin-bottom: calc(-1 * var(--space-8));
    --margin-left: calc(-1 * var(--space-8));
  }

  .md\:-rt-r-m-9 {
    --margin-top: calc(-1 * var(--space-9));
    --margin-right: calc(-1 * var(--space-9));
    --margin-bottom: calc(-1 * var(--space-9));
    --margin-left: calc(-1 * var(--space-9));
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-m, .lg\:rt-r-m-0, .lg\:rt-r-m-1, .lg\:rt-r-m-2, .lg\:rt-r-m-3, .lg\:rt-r-m-4, .lg\:rt-r-m-5, .lg\:rt-r-m-6, .lg\:rt-r-m-7, .lg\:rt-r-m-8, .lg\:rt-r-m-9, .lg\:-rt-r-m-1, .lg\:-rt-r-m-2, .lg\:-rt-r-m-3, .lg\:-rt-r-m-4, .lg\:-rt-r-m-5, .lg\:-rt-r-m-6, .lg\:-rt-r-m-7, .lg\:-rt-r-m-8, .lg\:-rt-r-m-9 {
    margin-top: var(--margin-top-override, var(--margin-top));
    margin-right: var(--margin-right-override, var(--margin-right));
    margin-bottom: var(--margin-bottom-override, var(--margin-bottom));
    margin-left: var(--margin-left-override, var(--margin-left));
  }

  .lg\:rt-r-m {
    --margin-top: var(--m-lg);
    --margin-right: var(--m-lg);
    --margin-bottom: var(--m-lg);
    --margin-left: var(--m-lg);
  }

  .lg\:rt-r-m-0 {
    --margin-top: 0px;
    --margin-right: 0px;
    --margin-bottom: 0px;
    --margin-left: 0px;
  }

  .lg\:rt-r-m-1 {
    --margin-top: var(--space-1);
    --margin-right: var(--space-1);
    --margin-bottom: var(--space-1);
    --margin-left: var(--space-1);
  }

  .lg\:rt-r-m-2 {
    --margin-top: var(--space-2);
    --margin-right: var(--space-2);
    --margin-bottom: var(--space-2);
    --margin-left: var(--space-2);
  }

  .lg\:rt-r-m-3 {
    --margin-top: var(--space-3);
    --margin-right: var(--space-3);
    --margin-bottom: var(--space-3);
    --margin-left: var(--space-3);
  }

  .lg\:rt-r-m-4 {
    --margin-top: var(--space-4);
    --margin-right: var(--space-4);
    --margin-bottom: var(--space-4);
    --margin-left: var(--space-4);
  }

  .lg\:rt-r-m-5 {
    --margin-top: var(--space-5);
    --margin-right: var(--space-5);
    --margin-bottom: var(--space-5);
    --margin-left: var(--space-5);
  }

  .lg\:rt-r-m-6 {
    --margin-top: var(--space-6);
    --margin-right: var(--space-6);
    --margin-bottom: var(--space-6);
    --margin-left: var(--space-6);
  }

  .lg\:rt-r-m-7 {
    --margin-top: var(--space-7);
    --margin-right: var(--space-7);
    --margin-bottom: var(--space-7);
    --margin-left: var(--space-7);
  }

  .lg\:rt-r-m-8 {
    --margin-top: var(--space-8);
    --margin-right: var(--space-8);
    --margin-bottom: var(--space-8);
    --margin-left: var(--space-8);
  }

  .lg\:rt-r-m-9 {
    --margin-top: var(--space-9);
    --margin-right: var(--space-9);
    --margin-bottom: var(--space-9);
    --margin-left: var(--space-9);
  }

  .lg\:-rt-r-m-1 {
    --margin-top: calc(-1 * var(--space-1));
    --margin-right: calc(-1 * var(--space-1));
    --margin-bottom: calc(-1 * var(--space-1));
    --margin-left: calc(-1 * var(--space-1));
  }

  .lg\:-rt-r-m-2 {
    --margin-top: calc(-1 * var(--space-2));
    --margin-right: calc(-1 * var(--space-2));
    --margin-bottom: calc(-1 * var(--space-2));
    --margin-left: calc(-1 * var(--space-2));
  }

  .lg\:-rt-r-m-3 {
    --margin-top: calc(-1 * var(--space-3));
    --margin-right: calc(-1 * var(--space-3));
    --margin-bottom: calc(-1 * var(--space-3));
    --margin-left: calc(-1 * var(--space-3));
  }

  .lg\:-rt-r-m-4 {
    --margin-top: calc(-1 * var(--space-4));
    --margin-right: calc(-1 * var(--space-4));
    --margin-bottom: calc(-1 * var(--space-4));
    --margin-left: calc(-1 * var(--space-4));
  }

  .lg\:-rt-r-m-5 {
    --margin-top: calc(-1 * var(--space-5));
    --margin-right: calc(-1 * var(--space-5));
    --margin-bottom: calc(-1 * var(--space-5));
    --margin-left: calc(-1 * var(--space-5));
  }

  .lg\:-rt-r-m-6 {
    --margin-top: calc(-1 * var(--space-6));
    --margin-right: calc(-1 * var(--space-6));
    --margin-bottom: calc(-1 * var(--space-6));
    --margin-left: calc(-1 * var(--space-6));
  }

  .lg\:-rt-r-m-7 {
    --margin-top: calc(-1 * var(--space-7));
    --margin-right: calc(-1 * var(--space-7));
    --margin-bottom: calc(-1 * var(--space-7));
    --margin-left: calc(-1 * var(--space-7));
  }

  .lg\:-rt-r-m-8 {
    --margin-top: calc(-1 * var(--space-8));
    --margin-right: calc(-1 * var(--space-8));
    --margin-bottom: calc(-1 * var(--space-8));
    --margin-left: calc(-1 * var(--space-8));
  }

  .lg\:-rt-r-m-9 {
    --margin-top: calc(-1 * var(--space-9));
    --margin-right: calc(-1 * var(--space-9));
    --margin-bottom: calc(-1 * var(--space-9));
    --margin-left: calc(-1 * var(--space-9));
  }
}

@media (min-width: 1640px) {
  .xl\:rt-r-m, .xl\:rt-r-m-0, .xl\:rt-r-m-1, .xl\:rt-r-m-2, .xl\:rt-r-m-3, .xl\:rt-r-m-4, .xl\:rt-r-m-5, .xl\:rt-r-m-6, .xl\:rt-r-m-7, .xl\:rt-r-m-8, .xl\:rt-r-m-9, .xl\:-rt-r-m-1, .xl\:-rt-r-m-2, .xl\:-rt-r-m-3, .xl\:-rt-r-m-4, .xl\:-rt-r-m-5, .xl\:-rt-r-m-6, .xl\:-rt-r-m-7, .xl\:-rt-r-m-8, .xl\:-rt-r-m-9 {
    margin-top: var(--margin-top-override, var(--margin-top));
    margin-right: var(--margin-right-override, var(--margin-right));
    margin-bottom: var(--margin-bottom-override, var(--margin-bottom));
    margin-left: var(--margin-left-override, var(--margin-left));
  }

  .xl\:rt-r-m {
    --margin-top: var(--m-xl);
    --margin-right: var(--m-xl);
    --margin-bottom: var(--m-xl);
    --margin-left: var(--m-xl);
  }

  .xl\:rt-r-m-0 {
    --margin-top: 0px;
    --margin-right: 0px;
    --margin-bottom: 0px;
    --margin-left: 0px;
  }

  .xl\:rt-r-m-1 {
    --margin-top: var(--space-1);
    --margin-right: var(--space-1);
    --margin-bottom: var(--space-1);
    --margin-left: var(--space-1);
  }

  .xl\:rt-r-m-2 {
    --margin-top: var(--space-2);
    --margin-right: var(--space-2);
    --margin-bottom: var(--space-2);
    --margin-left: var(--space-2);
  }

  .xl\:rt-r-m-3 {
    --margin-top: var(--space-3);
    --margin-right: var(--space-3);
    --margin-bottom: var(--space-3);
    --margin-left: var(--space-3);
  }

  .xl\:rt-r-m-4 {
    --margin-top: var(--space-4);
    --margin-right: var(--space-4);
    --margin-bottom: var(--space-4);
    --margin-left: var(--space-4);
  }

  .xl\:rt-r-m-5 {
    --margin-top: var(--space-5);
    --margin-right: var(--space-5);
    --margin-bottom: var(--space-5);
    --margin-left: var(--space-5);
  }

  .xl\:rt-r-m-6 {
    --margin-top: var(--space-6);
    --margin-right: var(--space-6);
    --margin-bottom: var(--space-6);
    --margin-left: var(--space-6);
  }

  .xl\:rt-r-m-7 {
    --margin-top: var(--space-7);
    --margin-right: var(--space-7);
    --margin-bottom: var(--space-7);
    --margin-left: var(--space-7);
  }

  .xl\:rt-r-m-8 {
    --margin-top: var(--space-8);
    --margin-right: var(--space-8);
    --margin-bottom: var(--space-8);
    --margin-left: var(--space-8);
  }

  .xl\:rt-r-m-9 {
    --margin-top: var(--space-9);
    --margin-right: var(--space-9);
    --margin-bottom: var(--space-9);
    --margin-left: var(--space-9);
  }

  .xl\:-rt-r-m-1 {
    --margin-top: calc(-1 * var(--space-1));
    --margin-right: calc(-1 * var(--space-1));
    --margin-bottom: calc(-1 * var(--space-1));
    --margin-left: calc(-1 * var(--space-1));
  }

  .xl\:-rt-r-m-2 {
    --margin-top: calc(-1 * var(--space-2));
    --margin-right: calc(-1 * var(--space-2));
    --margin-bottom: calc(-1 * var(--space-2));
    --margin-left: calc(-1 * var(--space-2));
  }

  .xl\:-rt-r-m-3 {
    --margin-top: calc(-1 * var(--space-3));
    --margin-right: calc(-1 * var(--space-3));
    --margin-bottom: calc(-1 * var(--space-3));
    --margin-left: calc(-1 * var(--space-3));
  }

  .xl\:-rt-r-m-4 {
    --margin-top: calc(-1 * var(--space-4));
    --margin-right: calc(-1 * var(--space-4));
    --margin-bottom: calc(-1 * var(--space-4));
    --margin-left: calc(-1 * var(--space-4));
  }

  .xl\:-rt-r-m-5 {
    --margin-top: calc(-1 * var(--space-5));
    --margin-right: calc(-1 * var(--space-5));
    --margin-bottom: calc(-1 * var(--space-5));
    --margin-left: calc(-1 * var(--space-5));
  }

  .xl\:-rt-r-m-6 {
    --margin-top: calc(-1 * var(--space-6));
    --margin-right: calc(-1 * var(--space-6));
    --margin-bottom: calc(-1 * var(--space-6));
    --margin-left: calc(-1 * var(--space-6));
  }

  .xl\:-rt-r-m-7 {
    --margin-top: calc(-1 * var(--space-7));
    --margin-right: calc(-1 * var(--space-7));
    --margin-bottom: calc(-1 * var(--space-7));
    --margin-left: calc(-1 * var(--space-7));
  }

  .xl\:-rt-r-m-8 {
    --margin-top: calc(-1 * var(--space-8));
    --margin-right: calc(-1 * var(--space-8));
    --margin-bottom: calc(-1 * var(--space-8));
    --margin-left: calc(-1 * var(--space-8));
  }

  .xl\:-rt-r-m-9 {
    --margin-top: calc(-1 * var(--space-9));
    --margin-right: calc(-1 * var(--space-9));
    --margin-bottom: calc(-1 * var(--space-9));
    --margin-left: calc(-1 * var(--space-9));
  }
}

.rt-r-mx, .rt-r-mx-0, .rt-r-mx-1, .rt-r-mx-2, .rt-r-mx-3, .rt-r-mx-4, .rt-r-mx-5, .rt-r-mx-6, .rt-r-mx-7, .rt-r-mx-8, .rt-r-mx-9, .-rt-r-mx-1, .-rt-r-mx-2, .-rt-r-mx-3, .-rt-r-mx-4, .-rt-r-mx-5, .-rt-r-mx-6, .-rt-r-mx-7, .-rt-r-mx-8, .-rt-r-mx-9 {
  margin-left: var(--margin-left-override, var(--margin-left));
  margin-right: var(--margin-right-override, var(--margin-right));
}

.rt-r-mx {
  --margin-left: var(--ml);
  --margin-right: var(--mr);
}

.rt-r-mx-0 {
  --margin-left: 0px;
  --margin-right: 0px;
}

.rt-r-mx-1 {
  --margin-left: var(--space-1);
  --margin-right: var(--space-1);
}

.rt-r-mx-2 {
  --margin-left: var(--space-2);
  --margin-right: var(--space-2);
}

.rt-r-mx-3 {
  --margin-left: var(--space-3);
  --margin-right: var(--space-3);
}

.rt-r-mx-4 {
  --margin-left: var(--space-4);
  --margin-right: var(--space-4);
}

.rt-r-mx-5 {
  --margin-left: var(--space-5);
  --margin-right: var(--space-5);
}

.rt-r-mx-6 {
  --margin-left: var(--space-6);
  --margin-right: var(--space-6);
}

.rt-r-mx-7 {
  --margin-left: var(--space-7);
  --margin-right: var(--space-7);
}

.rt-r-mx-8 {
  --margin-left: var(--space-8);
  --margin-right: var(--space-8);
}

.rt-r-mx-9 {
  --margin-left: var(--space-9);
  --margin-right: var(--space-9);
}

.-rt-r-mx-1 {
  --margin-left: calc(-1 * var(--space-1));
  --margin-right: calc(-1 * var(--space-1));
}

.-rt-r-mx-2 {
  --margin-left: calc(-1 * var(--space-2));
  --margin-right: calc(-1 * var(--space-2));
}

.-rt-r-mx-3 {
  --margin-left: calc(-1 * var(--space-3));
  --margin-right: calc(-1 * var(--space-3));
}

.-rt-r-mx-4 {
  --margin-left: calc(-1 * var(--space-4));
  --margin-right: calc(-1 * var(--space-4));
}

.-rt-r-mx-5 {
  --margin-left: calc(-1 * var(--space-5));
  --margin-right: calc(-1 * var(--space-5));
}

.-rt-r-mx-6 {
  --margin-left: calc(-1 * var(--space-6));
  --margin-right: calc(-1 * var(--space-6));
}

.-rt-r-mx-7 {
  --margin-left: calc(-1 * var(--space-7));
  --margin-right: calc(-1 * var(--space-7));
}

.-rt-r-mx-8 {
  --margin-left: calc(-1 * var(--space-8));
  --margin-right: calc(-1 * var(--space-8));
}

.-rt-r-mx-9 {
  --margin-left: calc(-1 * var(--space-9));
  --margin-right: calc(-1 * var(--space-9));
}

@media (min-width: 520px) {
  .xs\:rt-r-mx, .xs\:rt-r-mx-0, .xs\:rt-r-mx-1, .xs\:rt-r-mx-2, .xs\:rt-r-mx-3, .xs\:rt-r-mx-4, .xs\:rt-r-mx-5, .xs\:rt-r-mx-6, .xs\:rt-r-mx-7, .xs\:rt-r-mx-8, .xs\:rt-r-mx-9, .xs\:-rt-r-mx-1, .xs\:-rt-r-mx-2, .xs\:-rt-r-mx-3, .xs\:-rt-r-mx-4, .xs\:-rt-r-mx-5, .xs\:-rt-r-mx-6, .xs\:-rt-r-mx-7, .xs\:-rt-r-mx-8, .xs\:-rt-r-mx-9 {
    margin-left: var(--margin-left-override, var(--margin-left));
    margin-right: var(--margin-right-override, var(--margin-right));
  }

  .xs\:rt-r-mx {
    --margin-left: var(--ml-xs);
    --margin-right: var(--mr-xs);
  }

  .xs\:rt-r-mx-0 {
    --margin-left: 0px;
    --margin-right: 0px;
  }

  .xs\:rt-r-mx-1 {
    --margin-left: var(--space-1);
    --margin-right: var(--space-1);
  }

  .xs\:rt-r-mx-2 {
    --margin-left: var(--space-2);
    --margin-right: var(--space-2);
  }

  .xs\:rt-r-mx-3 {
    --margin-left: var(--space-3);
    --margin-right: var(--space-3);
  }

  .xs\:rt-r-mx-4 {
    --margin-left: var(--space-4);
    --margin-right: var(--space-4);
  }

  .xs\:rt-r-mx-5 {
    --margin-left: var(--space-5);
    --margin-right: var(--space-5);
  }

  .xs\:rt-r-mx-6 {
    --margin-left: var(--space-6);
    --margin-right: var(--space-6);
  }

  .xs\:rt-r-mx-7 {
    --margin-left: var(--space-7);
    --margin-right: var(--space-7);
  }

  .xs\:rt-r-mx-8 {
    --margin-left: var(--space-8);
    --margin-right: var(--space-8);
  }

  .xs\:rt-r-mx-9 {
    --margin-left: var(--space-9);
    --margin-right: var(--space-9);
  }

  .xs\:-rt-r-mx-1 {
    --margin-left: calc(-1 * var(--space-1));
    --margin-right: calc(-1 * var(--space-1));
  }

  .xs\:-rt-r-mx-2 {
    --margin-left: calc(-1 * var(--space-2));
    --margin-right: calc(-1 * var(--space-2));
  }

  .xs\:-rt-r-mx-3 {
    --margin-left: calc(-1 * var(--space-3));
    --margin-right: calc(-1 * var(--space-3));
  }

  .xs\:-rt-r-mx-4 {
    --margin-left: calc(-1 * var(--space-4));
    --margin-right: calc(-1 * var(--space-4));
  }

  .xs\:-rt-r-mx-5 {
    --margin-left: calc(-1 * var(--space-5));
    --margin-right: calc(-1 * var(--space-5));
  }

  .xs\:-rt-r-mx-6 {
    --margin-left: calc(-1 * var(--space-6));
    --margin-right: calc(-1 * var(--space-6));
  }

  .xs\:-rt-r-mx-7 {
    --margin-left: calc(-1 * var(--space-7));
    --margin-right: calc(-1 * var(--space-7));
  }

  .xs\:-rt-r-mx-8 {
    --margin-left: calc(-1 * var(--space-8));
    --margin-right: calc(-1 * var(--space-8));
  }

  .xs\:-rt-r-mx-9 {
    --margin-left: calc(-1 * var(--space-9));
    --margin-right: calc(-1 * var(--space-9));
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-mx, .sm\:rt-r-mx-0, .sm\:rt-r-mx-1, .sm\:rt-r-mx-2, .sm\:rt-r-mx-3, .sm\:rt-r-mx-4, .sm\:rt-r-mx-5, .sm\:rt-r-mx-6, .sm\:rt-r-mx-7, .sm\:rt-r-mx-8, .sm\:rt-r-mx-9, .sm\:-rt-r-mx-1, .sm\:-rt-r-mx-2, .sm\:-rt-r-mx-3, .sm\:-rt-r-mx-4, .sm\:-rt-r-mx-5, .sm\:-rt-r-mx-6, .sm\:-rt-r-mx-7, .sm\:-rt-r-mx-8, .sm\:-rt-r-mx-9 {
    margin-left: var(--margin-left-override, var(--margin-left));
    margin-right: var(--margin-right-override, var(--margin-right));
  }

  .sm\:rt-r-mx {
    --margin-left: var(--ml-md);
    --margin-right: var(--mr-md);
  }

  .sm\:rt-r-mx-0 {
    --margin-left: 0px;
    --margin-right: 0px;
  }

  .sm\:rt-r-mx-1 {
    --margin-left: var(--space-1);
    --margin-right: var(--space-1);
  }

  .sm\:rt-r-mx-2 {
    --margin-left: var(--space-2);
    --margin-right: var(--space-2);
  }

  .sm\:rt-r-mx-3 {
    --margin-left: var(--space-3);
    --margin-right: var(--space-3);
  }

  .sm\:rt-r-mx-4 {
    --margin-left: var(--space-4);
    --margin-right: var(--space-4);
  }

  .sm\:rt-r-mx-5 {
    --margin-left: var(--space-5);
    --margin-right: var(--space-5);
  }

  .sm\:rt-r-mx-6 {
    --margin-left: var(--space-6);
    --margin-right: var(--space-6);
  }

  .sm\:rt-r-mx-7 {
    --margin-left: var(--space-7);
    --margin-right: var(--space-7);
  }

  .sm\:rt-r-mx-8 {
    --margin-left: var(--space-8);
    --margin-right: var(--space-8);
  }

  .sm\:rt-r-mx-9 {
    --margin-left: var(--space-9);
    --margin-right: var(--space-9);
  }

  .sm\:-rt-r-mx-1 {
    --margin-left: calc(-1 * var(--space-1));
    --margin-right: calc(-1 * var(--space-1));
  }

  .sm\:-rt-r-mx-2 {
    --margin-left: calc(-1 * var(--space-2));
    --margin-right: calc(-1 * var(--space-2));
  }

  .sm\:-rt-r-mx-3 {
    --margin-left: calc(-1 * var(--space-3));
    --margin-right: calc(-1 * var(--space-3));
  }

  .sm\:-rt-r-mx-4 {
    --margin-left: calc(-1 * var(--space-4));
    --margin-right: calc(-1 * var(--space-4));
  }

  .sm\:-rt-r-mx-5 {
    --margin-left: calc(-1 * var(--space-5));
    --margin-right: calc(-1 * var(--space-5));
  }

  .sm\:-rt-r-mx-6 {
    --margin-left: calc(-1 * var(--space-6));
    --margin-right: calc(-1 * var(--space-6));
  }

  .sm\:-rt-r-mx-7 {
    --margin-left: calc(-1 * var(--space-7));
    --margin-right: calc(-1 * var(--space-7));
  }

  .sm\:-rt-r-mx-8 {
    --margin-left: calc(-1 * var(--space-8));
    --margin-right: calc(-1 * var(--space-8));
  }

  .sm\:-rt-r-mx-9 {
    --margin-left: calc(-1 * var(--space-9));
    --margin-right: calc(-1 * var(--space-9));
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-mx, .md\:rt-r-mx-0, .md\:rt-r-mx-1, .md\:rt-r-mx-2, .md\:rt-r-mx-3, .md\:rt-r-mx-4, .md\:rt-r-mx-5, .md\:rt-r-mx-6, .md\:rt-r-mx-7, .md\:rt-r-mx-8, .md\:rt-r-mx-9, .md\:-rt-r-mx-1, .md\:-rt-r-mx-2, .md\:-rt-r-mx-3, .md\:-rt-r-mx-4, .md\:-rt-r-mx-5, .md\:-rt-r-mx-6, .md\:-rt-r-mx-7, .md\:-rt-r-mx-8, .md\:-rt-r-mx-9 {
    margin-left: var(--margin-left-override, var(--margin-left));
    margin-right: var(--margin-right-override, var(--margin-right));
  }

  .md\:rt-r-mx {
    --margin-left: var(--ml-md);
    --margin-right: var(--mr-md);
  }

  .md\:rt-r-mx-0 {
    --margin-left: 0px;
    --margin-right: 0px;
  }

  .md\:rt-r-mx-1 {
    --margin-left: var(--space-1);
    --margin-right: var(--space-1);
  }

  .md\:rt-r-mx-2 {
    --margin-left: var(--space-2);
    --margin-right: var(--space-2);
  }

  .md\:rt-r-mx-3 {
    --margin-left: var(--space-3);
    --margin-right: var(--space-3);
  }

  .md\:rt-r-mx-4 {
    --margin-left: var(--space-4);
    --margin-right: var(--space-4);
  }

  .md\:rt-r-mx-5 {
    --margin-left: var(--space-5);
    --margin-right: var(--space-5);
  }

  .md\:rt-r-mx-6 {
    --margin-left: var(--space-6);
    --margin-right: var(--space-6);
  }

  .md\:rt-r-mx-7 {
    --margin-left: var(--space-7);
    --margin-right: var(--space-7);
  }

  .md\:rt-r-mx-8 {
    --margin-left: var(--space-8);
    --margin-right: var(--space-8);
  }

  .md\:rt-r-mx-9 {
    --margin-left: var(--space-9);
    --margin-right: var(--space-9);
  }

  .md\:-rt-r-mx-1 {
    --margin-left: calc(-1 * var(--space-1));
    --margin-right: calc(-1 * var(--space-1));
  }

  .md\:-rt-r-mx-2 {
    --margin-left: calc(-1 * var(--space-2));
    --margin-right: calc(-1 * var(--space-2));
  }

  .md\:-rt-r-mx-3 {
    --margin-left: calc(-1 * var(--space-3));
    --margin-right: calc(-1 * var(--space-3));
  }

  .md\:-rt-r-mx-4 {
    --margin-left: calc(-1 * var(--space-4));
    --margin-right: calc(-1 * var(--space-4));
  }

  .md\:-rt-r-mx-5 {
    --margin-left: calc(-1 * var(--space-5));
    --margin-right: calc(-1 * var(--space-5));
  }

  .md\:-rt-r-mx-6 {
    --margin-left: calc(-1 * var(--space-6));
    --margin-right: calc(-1 * var(--space-6));
  }

  .md\:-rt-r-mx-7 {
    --margin-left: calc(-1 * var(--space-7));
    --margin-right: calc(-1 * var(--space-7));
  }

  .md\:-rt-r-mx-8 {
    --margin-left: calc(-1 * var(--space-8));
    --margin-right: calc(-1 * var(--space-8));
  }

  .md\:-rt-r-mx-9 {
    --margin-left: calc(-1 * var(--space-9));
    --margin-right: calc(-1 * var(--space-9));
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-mx, .lg\:rt-r-mx-0, .lg\:rt-r-mx-1, .lg\:rt-r-mx-2, .lg\:rt-r-mx-3, .lg\:rt-r-mx-4, .lg\:rt-r-mx-5, .lg\:rt-r-mx-6, .lg\:rt-r-mx-7, .lg\:rt-r-mx-8, .lg\:rt-r-mx-9, .lg\:-rt-r-mx-1, .lg\:-rt-r-mx-2, .lg\:-rt-r-mx-3, .lg\:-rt-r-mx-4, .lg\:-rt-r-mx-5, .lg\:-rt-r-mx-6, .lg\:-rt-r-mx-7, .lg\:-rt-r-mx-8, .lg\:-rt-r-mx-9 {
    margin-left: var(--margin-left-override, var(--margin-left));
    margin-right: var(--margin-right-override, var(--margin-right));
  }

  .lg\:rt-r-mx {
    --margin-left: var(--ml-lg);
    --margin-right: var(--mr-lg);
  }

  .lg\:rt-r-mx-0 {
    --margin-left: 0px;
    --margin-right: 0px;
  }

  .lg\:rt-r-mx-1 {
    --margin-left: var(--space-1);
    --margin-right: var(--space-1);
  }

  .lg\:rt-r-mx-2 {
    --margin-left: var(--space-2);
    --margin-right: var(--space-2);
  }

  .lg\:rt-r-mx-3 {
    --margin-left: var(--space-3);
    --margin-right: var(--space-3);
  }

  .lg\:rt-r-mx-4 {
    --margin-left: var(--space-4);
    --margin-right: var(--space-4);
  }

  .lg\:rt-r-mx-5 {
    --margin-left: var(--space-5);
    --margin-right: var(--space-5);
  }

  .lg\:rt-r-mx-6 {
    --margin-left: var(--space-6);
    --margin-right: var(--space-6);
  }

  .lg\:rt-r-mx-7 {
    --margin-left: var(--space-7);
    --margin-right: var(--space-7);
  }

  .lg\:rt-r-mx-8 {
    --margin-left: var(--space-8);
    --margin-right: var(--space-8);
  }

  .lg\:rt-r-mx-9 {
    --margin-left: var(--space-9);
    --margin-right: var(--space-9);
  }

  .lg\:-rt-r-mx-1 {
    --margin-left: calc(-1 * var(--space-1));
    --margin-right: calc(-1 * var(--space-1));
  }

  .lg\:-rt-r-mx-2 {
    --margin-left: calc(-1 * var(--space-2));
    --margin-right: calc(-1 * var(--space-2));
  }

  .lg\:-rt-r-mx-3 {
    --margin-left: calc(-1 * var(--space-3));
    --margin-right: calc(-1 * var(--space-3));
  }

  .lg\:-rt-r-mx-4 {
    --margin-left: calc(-1 * var(--space-4));
    --margin-right: calc(-1 * var(--space-4));
  }

  .lg\:-rt-r-mx-5 {
    --margin-left: calc(-1 * var(--space-5));
    --margin-right: calc(-1 * var(--space-5));
  }

  .lg\:-rt-r-mx-6 {
    --margin-left: calc(-1 * var(--space-6));
    --margin-right: calc(-1 * var(--space-6));
  }

  .lg\:-rt-r-mx-7 {
    --margin-left: calc(-1 * var(--space-7));
    --margin-right: calc(-1 * var(--space-7));
  }

  .lg\:-rt-r-mx-8 {
    --margin-left: calc(-1 * var(--space-8));
    --margin-right: calc(-1 * var(--space-8));
  }

  .lg\:-rt-r-mx-9 {
    --margin-left: calc(-1 * var(--space-9));
    --margin-right: calc(-1 * var(--space-9));
  }
}

@media (min-width: 1640px) {
  .xl\:rt-r-mx, .xl\:rt-r-mx-0, .xl\:rt-r-mx-1, .xl\:rt-r-mx-2, .xl\:rt-r-mx-3, .xl\:rt-r-mx-4, .xl\:rt-r-mx-5, .xl\:rt-r-mx-6, .xl\:rt-r-mx-7, .xl\:rt-r-mx-8, .xl\:rt-r-mx-9, .xl\:-rt-r-mx-1, .xl\:-rt-r-mx-2, .xl\:-rt-r-mx-3, .xl\:-rt-r-mx-4, .xl\:-rt-r-mx-5, .xl\:-rt-r-mx-6, .xl\:-rt-r-mx-7, .xl\:-rt-r-mx-8, .xl\:-rt-r-mx-9 {
    margin-left: var(--margin-left-override, var(--margin-left));
    margin-right: var(--margin-right-override, var(--margin-right));
  }

  .xl\:rt-r-mx {
    --margin-left: var(--ml-xl);
    --margin-right: var(--mr-xl);
  }

  .xl\:rt-r-mx-0 {
    --margin-left: 0px;
    --margin-right: 0px;
  }

  .xl\:rt-r-mx-1 {
    --margin-left: var(--space-1);
    --margin-right: var(--space-1);
  }

  .xl\:rt-r-mx-2 {
    --margin-left: var(--space-2);
    --margin-right: var(--space-2);
  }

  .xl\:rt-r-mx-3 {
    --margin-left: var(--space-3);
    --margin-right: var(--space-3);
  }

  .xl\:rt-r-mx-4 {
    --margin-left: var(--space-4);
    --margin-right: var(--space-4);
  }

  .xl\:rt-r-mx-5 {
    --margin-left: var(--space-5);
    --margin-right: var(--space-5);
  }

  .xl\:rt-r-mx-6 {
    --margin-left: var(--space-6);
    --margin-right: var(--space-6);
  }

  .xl\:rt-r-mx-7 {
    --margin-left: var(--space-7);
    --margin-right: var(--space-7);
  }

  .xl\:rt-r-mx-8 {
    --margin-left: var(--space-8);
    --margin-right: var(--space-8);
  }

  .xl\:rt-r-mx-9 {
    --margin-left: var(--space-9);
    --margin-right: var(--space-9);
  }

  .xl\:-rt-r-mx-1 {
    --margin-left: calc(-1 * var(--space-1));
    --margin-right: calc(-1 * var(--space-1));
  }

  .xl\:-rt-r-mx-2 {
    --margin-left: calc(-1 * var(--space-2));
    --margin-right: calc(-1 * var(--space-2));
  }

  .xl\:-rt-r-mx-3 {
    --margin-left: calc(-1 * var(--space-3));
    --margin-right: calc(-1 * var(--space-3));
  }

  .xl\:-rt-r-mx-4 {
    --margin-left: calc(-1 * var(--space-4));
    --margin-right: calc(-1 * var(--space-4));
  }

  .xl\:-rt-r-mx-5 {
    --margin-left: calc(-1 * var(--space-5));
    --margin-right: calc(-1 * var(--space-5));
  }

  .xl\:-rt-r-mx-6 {
    --margin-left: calc(-1 * var(--space-6));
    --margin-right: calc(-1 * var(--space-6));
  }

  .xl\:-rt-r-mx-7 {
    --margin-left: calc(-1 * var(--space-7));
    --margin-right: calc(-1 * var(--space-7));
  }

  .xl\:-rt-r-mx-8 {
    --margin-left: calc(-1 * var(--space-8));
    --margin-right: calc(-1 * var(--space-8));
  }

  .xl\:-rt-r-mx-9 {
    --margin-left: calc(-1 * var(--space-9));
    --margin-right: calc(-1 * var(--space-9));
  }
}

.rt-r-my, .rt-r-my-0, .rt-r-my-1, .rt-r-my-2, .rt-r-my-3, .rt-r-my-4, .rt-r-my-5, .rt-r-my-6, .rt-r-my-7, .rt-r-my-8, .rt-r-my-9, .-rt-r-my-1, .-rt-r-my-2, .-rt-r-my-3, .-rt-r-my-4, .-rt-r-my-5, .-rt-r-my-6, .-rt-r-my-7, .-rt-r-my-8, .-rt-r-my-9 {
  margin-top: var(--margin-top-override, var(--margin-top));
  margin-bottom: var(--margin-bottom-override, var(--margin-bottom));
}

.rt-r-my {
  --margin-top: var(--mt);
  --margin-bottom: var(--mb);
}

.rt-r-my-0 {
  --margin-top: 0px;
  --margin-bottom: 0px;
}

.rt-r-my-1 {
  --margin-top: var(--space-1);
  --margin-bottom: var(--space-1);
}

.rt-r-my-2 {
  --margin-top: var(--space-2);
  --margin-bottom: var(--space-2);
}

.rt-r-my-3 {
  --margin-top: var(--space-3);
  --margin-bottom: var(--space-3);
}

.rt-r-my-4 {
  --margin-top: var(--space-4);
  --margin-bottom: var(--space-4);
}

.rt-r-my-5 {
  --margin-top: var(--space-5);
  --margin-bottom: var(--space-5);
}

.rt-r-my-6 {
  --margin-top: var(--space-6);
  --margin-bottom: var(--space-6);
}

.rt-r-my-7 {
  --margin-top: var(--space-7);
  --margin-bottom: var(--space-7);
}

.rt-r-my-8 {
  --margin-top: var(--space-8);
  --margin-bottom: var(--space-8);
}

.rt-r-my-9 {
  --margin-top: var(--space-9);
  --margin-bottom: var(--space-9);
}

.-rt-r-my-1 {
  --margin-top: calc(-1 * var(--space-1));
  --margin-bottom: calc(-1 * var(--space-1));
}

.-rt-r-my-2 {
  --margin-top: calc(-1 * var(--space-2));
  --margin-bottom: calc(-1 * var(--space-2));
}

.-rt-r-my-3 {
  --margin-top: calc(-1 * var(--space-3));
  --margin-bottom: calc(-1 * var(--space-3));
}

.-rt-r-my-4 {
  --margin-top: calc(-1 * var(--space-4));
  --margin-bottom: calc(-1 * var(--space-4));
}

.-rt-r-my-5 {
  --margin-top: calc(-1 * var(--space-5));
  --margin-bottom: calc(-1 * var(--space-5));
}

.-rt-r-my-6 {
  --margin-top: calc(-1 * var(--space-6));
  --margin-bottom: calc(-1 * var(--space-6));
}

.-rt-r-my-7 {
  --margin-top: calc(-1 * var(--space-7));
  --margin-bottom: calc(-1 * var(--space-7));
}

.-rt-r-my-8 {
  --margin-top: calc(-1 * var(--space-8));
  --margin-bottom: calc(-1 * var(--space-8));
}

.-rt-r-my-9 {
  --margin-top: calc(-1 * var(--space-9));
  --margin-bottom: calc(-1 * var(--space-9));
}

@media (min-width: 520px) {
  .xs\:rt-r-my, .xs\:rt-r-my-0, .xs\:rt-r-my-1, .xs\:rt-r-my-2, .xs\:rt-r-my-3, .xs\:rt-r-my-4, .xs\:rt-r-my-5, .xs\:rt-r-my-6, .xs\:rt-r-my-7, .xs\:rt-r-my-8, .xs\:rt-r-my-9, .xs\:-rt-r-my-1, .xs\:-rt-r-my-2, .xs\:-rt-r-my-3, .xs\:-rt-r-my-4, .xs\:-rt-r-my-5, .xs\:-rt-r-my-6, .xs\:-rt-r-my-7, .xs\:-rt-r-my-8, .xs\:-rt-r-my-9 {
    margin-top: var(--margin-top-override, var(--margin-top));
    margin-bottom: var(--margin-bottom-override, var(--margin-bottom));
  }

  .xs\:rt-r-my {
    --margin-top: var(--mt-xs);
    --margin-bottom: var(--mb-xs);
  }

  .xs\:rt-r-my-0 {
    --margin-top: 0px;
    --margin-bottom: 0px;
  }

  .xs\:rt-r-my-1 {
    --margin-top: var(--space-1);
    --margin-bottom: var(--space-1);
  }

  .xs\:rt-r-my-2 {
    --margin-top: var(--space-2);
    --margin-bottom: var(--space-2);
  }

  .xs\:rt-r-my-3 {
    --margin-top: var(--space-3);
    --margin-bottom: var(--space-3);
  }

  .xs\:rt-r-my-4 {
    --margin-top: var(--space-4);
    --margin-bottom: var(--space-4);
  }

  .xs\:rt-r-my-5 {
    --margin-top: var(--space-5);
    --margin-bottom: var(--space-5);
  }

  .xs\:rt-r-my-6 {
    --margin-top: var(--space-6);
    --margin-bottom: var(--space-6);
  }

  .xs\:rt-r-my-7 {
    --margin-top: var(--space-7);
    --margin-bottom: var(--space-7);
  }

  .xs\:rt-r-my-8 {
    --margin-top: var(--space-8);
    --margin-bottom: var(--space-8);
  }

  .xs\:rt-r-my-9 {
    --margin-top: var(--space-9);
    --margin-bottom: var(--space-9);
  }

  .xs\:-rt-r-my-1 {
    --margin-top: calc(-1 * var(--space-1));
    --margin-bottom: calc(-1 * var(--space-1));
  }

  .xs\:-rt-r-my-2 {
    --margin-top: calc(-1 * var(--space-2));
    --margin-bottom: calc(-1 * var(--space-2));
  }

  .xs\:-rt-r-my-3 {
    --margin-top: calc(-1 * var(--space-3));
    --margin-bottom: calc(-1 * var(--space-3));
  }

  .xs\:-rt-r-my-4 {
    --margin-top: calc(-1 * var(--space-4));
    --margin-bottom: calc(-1 * var(--space-4));
  }

  .xs\:-rt-r-my-5 {
    --margin-top: calc(-1 * var(--space-5));
    --margin-bottom: calc(-1 * var(--space-5));
  }

  .xs\:-rt-r-my-6 {
    --margin-top: calc(-1 * var(--space-6));
    --margin-bottom: calc(-1 * var(--space-6));
  }

  .xs\:-rt-r-my-7 {
    --margin-top: calc(-1 * var(--space-7));
    --margin-bottom: calc(-1 * var(--space-7));
  }

  .xs\:-rt-r-my-8 {
    --margin-top: calc(-1 * var(--space-8));
    --margin-bottom: calc(-1 * var(--space-8));
  }

  .xs\:-rt-r-my-9 {
    --margin-top: calc(-1 * var(--space-9));
    --margin-bottom: calc(-1 * var(--space-9));
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-my, .sm\:rt-r-my-0, .sm\:rt-r-my-1, .sm\:rt-r-my-2, .sm\:rt-r-my-3, .sm\:rt-r-my-4, .sm\:rt-r-my-5, .sm\:rt-r-my-6, .sm\:rt-r-my-7, .sm\:rt-r-my-8, .sm\:rt-r-my-9, .sm\:-rt-r-my-1, .sm\:-rt-r-my-2, .sm\:-rt-r-my-3, .sm\:-rt-r-my-4, .sm\:-rt-r-my-5, .sm\:-rt-r-my-6, .sm\:-rt-r-my-7, .sm\:-rt-r-my-8, .sm\:-rt-r-my-9 {
    margin-top: var(--margin-top-override, var(--margin-top));
    margin-bottom: var(--margin-bottom-override, var(--margin-bottom));
  }

  .sm\:rt-r-my {
    --margin-top: var(--mt-sm);
    --margin-bottom: var(--mb-sm);
  }

  .sm\:rt-r-my-0 {
    --margin-top: 0px;
    --margin-bottom: 0px;
  }

  .sm\:rt-r-my-1 {
    --margin-top: var(--space-1);
    --margin-bottom: var(--space-1);
  }

  .sm\:rt-r-my-2 {
    --margin-top: var(--space-2);
    --margin-bottom: var(--space-2);
  }

  .sm\:rt-r-my-3 {
    --margin-top: var(--space-3);
    --margin-bottom: var(--space-3);
  }

  .sm\:rt-r-my-4 {
    --margin-top: var(--space-4);
    --margin-bottom: var(--space-4);
  }

  .sm\:rt-r-my-5 {
    --margin-top: var(--space-5);
    --margin-bottom: var(--space-5);
  }

  .sm\:rt-r-my-6 {
    --margin-top: var(--space-6);
    --margin-bottom: var(--space-6);
  }

  .sm\:rt-r-my-7 {
    --margin-top: var(--space-7);
    --margin-bottom: var(--space-7);
  }

  .sm\:rt-r-my-8 {
    --margin-top: var(--space-8);
    --margin-bottom: var(--space-8);
  }

  .sm\:rt-r-my-9 {
    --margin-top: var(--space-9);
    --margin-bottom: var(--space-9);
  }

  .sm\:-rt-r-my-1 {
    --margin-top: calc(-1 * var(--space-1));
    --margin-bottom: calc(-1 * var(--space-1));
  }

  .sm\:-rt-r-my-2 {
    --margin-top: calc(-1 * var(--space-2));
    --margin-bottom: calc(-1 * var(--space-2));
  }

  .sm\:-rt-r-my-3 {
    --margin-top: calc(-1 * var(--space-3));
    --margin-bottom: calc(-1 * var(--space-3));
  }

  .sm\:-rt-r-my-4 {
    --margin-top: calc(-1 * var(--space-4));
    --margin-bottom: calc(-1 * var(--space-4));
  }

  .sm\:-rt-r-my-5 {
    --margin-top: calc(-1 * var(--space-5));
    --margin-bottom: calc(-1 * var(--space-5));
  }

  .sm\:-rt-r-my-6 {
    --margin-top: calc(-1 * var(--space-6));
    --margin-bottom: calc(-1 * var(--space-6));
  }

  .sm\:-rt-r-my-7 {
    --margin-top: calc(-1 * var(--space-7));
    --margin-bottom: calc(-1 * var(--space-7));
  }

  .sm\:-rt-r-my-8 {
    --margin-top: calc(-1 * var(--space-8));
    --margin-bottom: calc(-1 * var(--space-8));
  }

  .sm\:-rt-r-my-9 {
    --margin-top: calc(-1 * var(--space-9));
    --margin-bottom: calc(-1 * var(--space-9));
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-my, .md\:rt-r-my-0, .md\:rt-r-my-1, .md\:rt-r-my-2, .md\:rt-r-my-3, .md\:rt-r-my-4, .md\:rt-r-my-5, .md\:rt-r-my-6, .md\:rt-r-my-7, .md\:rt-r-my-8, .md\:rt-r-my-9, .md\:-rt-r-my-1, .md\:-rt-r-my-2, .md\:-rt-r-my-3, .md\:-rt-r-my-4, .md\:-rt-r-my-5, .md\:-rt-r-my-6, .md\:-rt-r-my-7, .md\:-rt-r-my-8, .md\:-rt-r-my-9 {
    margin-top: var(--margin-top-override, var(--margin-top));
    margin-bottom: var(--margin-bottom-override, var(--margin-bottom));
  }

  .md\:rt-r-my {
    --margin-top: var(--mt-md);
    --margin-bottom: var(--mb-md);
  }

  .md\:rt-r-my-0 {
    --margin-top: 0px;
    --margin-bottom: 0px;
  }

  .md\:rt-r-my-1 {
    --margin-top: var(--space-1);
    --margin-bottom: var(--space-1);
  }

  .md\:rt-r-my-2 {
    --margin-top: var(--space-2);
    --margin-bottom: var(--space-2);
  }

  .md\:rt-r-my-3 {
    --margin-top: var(--space-3);
    --margin-bottom: var(--space-3);
  }

  .md\:rt-r-my-4 {
    --margin-top: var(--space-4);
    --margin-bottom: var(--space-4);
  }

  .md\:rt-r-my-5 {
    --margin-top: var(--space-5);
    --margin-bottom: var(--space-5);
  }

  .md\:rt-r-my-6 {
    --margin-top: var(--space-6);
    --margin-bottom: var(--space-6);
  }

  .md\:rt-r-my-7 {
    --margin-top: var(--space-7);
    --margin-bottom: var(--space-7);
  }

  .md\:rt-r-my-8 {
    --margin-top: var(--space-8);
    --margin-bottom: var(--space-8);
  }

  .md\:rt-r-my-9 {
    --margin-top: var(--space-9);
    --margin-bottom: var(--space-9);
  }

  .md\:-rt-r-my-1 {
    --margin-top: calc(-1 * var(--space-1));
    --margin-bottom: calc(-1 * var(--space-1));
  }

  .md\:-rt-r-my-2 {
    --margin-top: calc(-1 * var(--space-2));
    --margin-bottom: calc(-1 * var(--space-2));
  }

  .md\:-rt-r-my-3 {
    --margin-top: calc(-1 * var(--space-3));
    --margin-bottom: calc(-1 * var(--space-3));
  }

  .md\:-rt-r-my-4 {
    --margin-top: calc(-1 * var(--space-4));
    --margin-bottom: calc(-1 * var(--space-4));
  }

  .md\:-rt-r-my-5 {
    --margin-top: calc(-1 * var(--space-5));
    --margin-bottom: calc(-1 * var(--space-5));
  }

  .md\:-rt-r-my-6 {
    --margin-top: calc(-1 * var(--space-6));
    --margin-bottom: calc(-1 * var(--space-6));
  }

  .md\:-rt-r-my-7 {
    --margin-top: calc(-1 * var(--space-7));
    --margin-bottom: calc(-1 * var(--space-7));
  }

  .md\:-rt-r-my-8 {
    --margin-top: calc(-1 * var(--space-8));
    --margin-bottom: calc(-1 * var(--space-8));
  }

  .md\:-rt-r-my-9 {
    --margin-top: calc(-1 * var(--space-9));
    --margin-bottom: calc(-1 * var(--space-9));
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-my, .lg\:rt-r-my-0, .lg\:rt-r-my-1, .lg\:rt-r-my-2, .lg\:rt-r-my-3, .lg\:rt-r-my-4, .lg\:rt-r-my-5, .lg\:rt-r-my-6, .lg\:rt-r-my-7, .lg\:rt-r-my-8, .lg\:rt-r-my-9, .lg\:-rt-r-my-1, .lg\:-rt-r-my-2, .lg\:-rt-r-my-3, .lg\:-rt-r-my-4, .lg\:-rt-r-my-5, .lg\:-rt-r-my-6, .lg\:-rt-r-my-7, .lg\:-rt-r-my-8, .lg\:-rt-r-my-9 {
    margin-top: var(--margin-top-override, var(--margin-top));
    margin-bottom: var(--margin-bottom-override, var(--margin-bottom));
  }

  .lg\:rt-r-my {
    --margin-top: var(--mt-lg);
    --margin-bottom: var(--mb-lg);
  }

  .lg\:rt-r-my-0 {
    --margin-top: 0px;
    --margin-bottom: 0px;
  }

  .lg\:rt-r-my-1 {
    --margin-top: var(--space-1);
    --margin-bottom: var(--space-1);
  }

  .lg\:rt-r-my-2 {
    --margin-top: var(--space-2);
    --margin-bottom: var(--space-2);
  }

  .lg\:rt-r-my-3 {
    --margin-top: var(--space-3);
    --margin-bottom: var(--space-3);
  }

  .lg\:rt-r-my-4 {
    --margin-top: var(--space-4);
    --margin-bottom: var(--space-4);
  }

  .lg\:rt-r-my-5 {
    --margin-top: var(--space-5);
    --margin-bottom: var(--space-5);
  }

  .lg\:rt-r-my-6 {
    --margin-top: var(--space-6);
    --margin-bottom: var(--space-6);
  }

  .lg\:rt-r-my-7 {
    --margin-top: var(--space-7);
    --margin-bottom: var(--space-7);
  }

  .lg\:rt-r-my-8 {
    --margin-top: var(--space-8);
    --margin-bottom: var(--space-8);
  }

  .lg\:rt-r-my-9 {
    --margin-top: var(--space-9);
    --margin-bottom: var(--space-9);
  }

  .lg\:-rt-r-my-1 {
    --margin-top: calc(-1 * var(--space-1));
    --margin-bottom: calc(-1 * var(--space-1));
  }

  .lg\:-rt-r-my-2 {
    --margin-top: calc(-1 * var(--space-2));
    --margin-bottom: calc(-1 * var(--space-2));
  }

  .lg\:-rt-r-my-3 {
    --margin-top: calc(-1 * var(--space-3));
    --margin-bottom: calc(-1 * var(--space-3));
  }

  .lg\:-rt-r-my-4 {
    --margin-top: calc(-1 * var(--space-4));
    --margin-bottom: calc(-1 * var(--space-4));
  }

  .lg\:-rt-r-my-5 {
    --margin-top: calc(-1 * var(--space-5));
    --margin-bottom: calc(-1 * var(--space-5));
  }

  .lg\:-rt-r-my-6 {
    --margin-top: calc(-1 * var(--space-6));
    --margin-bottom: calc(-1 * var(--space-6));
  }

  .lg\:-rt-r-my-7 {
    --margin-top: calc(-1 * var(--space-7));
    --margin-bottom: calc(-1 * var(--space-7));
  }

  .lg\:-rt-r-my-8 {
    --margin-top: calc(-1 * var(--space-8));
    --margin-bottom: calc(-1 * var(--space-8));
  }

  .lg\:-rt-r-my-9 {
    --margin-top: calc(-1 * var(--space-9));
    --margin-bottom: calc(-1 * var(--space-9));
  }
}

@media (min-width: 1640px) {
  .xl\:rt-r-my, .xl\:rt-r-my-0, .xl\:rt-r-my-1, .xl\:rt-r-my-2, .xl\:rt-r-my-3, .xl\:rt-r-my-4, .xl\:rt-r-my-5, .xl\:rt-r-my-6, .xl\:rt-r-my-7, .xl\:rt-r-my-8, .xl\:rt-r-my-9, .xl\:-rt-r-my-1, .xl\:-rt-r-my-2, .xl\:-rt-r-my-3, .xl\:-rt-r-my-4, .xl\:-rt-r-my-5, .xl\:-rt-r-my-6, .xl\:-rt-r-my-7, .xl\:-rt-r-my-8, .xl\:-rt-r-my-9 {
    margin-top: var(--margin-top-override, var(--margin-top));
    margin-bottom: var(--margin-bottom-override, var(--margin-bottom));
  }

  .xl\:rt-r-my {
    --margin-top: var(--mt-xl);
    --margin-bottom: var(--mb-xl);
  }

  .xl\:rt-r-my-0 {
    --margin-top: 0px;
    --margin-bottom: 0px;
  }

  .xl\:rt-r-my-1 {
    --margin-top: var(--space-1);
    --margin-bottom: var(--space-1);
  }

  .xl\:rt-r-my-2 {
    --margin-top: var(--space-2);
    --margin-bottom: var(--space-2);
  }

  .xl\:rt-r-my-3 {
    --margin-top: var(--space-3);
    --margin-bottom: var(--space-3);
  }

  .xl\:rt-r-my-4 {
    --margin-top: var(--space-4);
    --margin-bottom: var(--space-4);
  }

  .xl\:rt-r-my-5 {
    --margin-top: var(--space-5);
    --margin-bottom: var(--space-5);
  }

  .xl\:rt-r-my-6 {
    --margin-top: var(--space-6);
    --margin-bottom: var(--space-6);
  }

  .xl\:rt-r-my-7 {
    --margin-top: var(--space-7);
    --margin-bottom: var(--space-7);
  }

  .xl\:rt-r-my-8 {
    --margin-top: var(--space-8);
    --margin-bottom: var(--space-8);
  }

  .xl\:rt-r-my-9 {
    --margin-top: var(--space-9);
    --margin-bottom: var(--space-9);
  }

  .xl\:-rt-r-my-1 {
    --margin-top: calc(-1 * var(--space-1));
    --margin-bottom: calc(-1 * var(--space-1));
  }

  .xl\:-rt-r-my-2 {
    --margin-top: calc(-1 * var(--space-2));
    --margin-bottom: calc(-1 * var(--space-2));
  }

  .xl\:-rt-r-my-3 {
    --margin-top: calc(-1 * var(--space-3));
    --margin-bottom: calc(-1 * var(--space-3));
  }

  .xl\:-rt-r-my-4 {
    --margin-top: calc(-1 * var(--space-4));
    --margin-bottom: calc(-1 * var(--space-4));
  }

  .xl\:-rt-r-my-5 {
    --margin-top: calc(-1 * var(--space-5));
    --margin-bottom: calc(-1 * var(--space-5));
  }

  .xl\:-rt-r-my-6 {
    --margin-top: calc(-1 * var(--space-6));
    --margin-bottom: calc(-1 * var(--space-6));
  }

  .xl\:-rt-r-my-7 {
    --margin-top: calc(-1 * var(--space-7));
    --margin-bottom: calc(-1 * var(--space-7));
  }

  .xl\:-rt-r-my-8 {
    --margin-top: calc(-1 * var(--space-8));
    --margin-bottom: calc(-1 * var(--space-8));
  }

  .xl\:-rt-r-my-9 {
    --margin-top: calc(-1 * var(--space-9));
    --margin-bottom: calc(-1 * var(--space-9));
  }
}

.rt-r-mt, .rt-r-mt-0, .rt-r-mt-1, .rt-r-mt-2, .rt-r-mt-3, .rt-r-mt-4, .rt-r-mt-5, .rt-r-mt-6, .rt-r-mt-7, .rt-r-mt-8, .rt-r-mt-9, .-rt-r-mt-1, .-rt-r-mt-2, .-rt-r-mt-3, .-rt-r-mt-4, .-rt-r-mt-5, .-rt-r-mt-6, .-rt-r-mt-7, .-rt-r-mt-8, .-rt-r-mt-9 {
  margin-top: var(--margin-top-override, var(--margin-top));
}

.rt-r-mt {
  --margin-top: var(--mt);
}

.rt-r-mt-0 {
  --margin-top: 0px;
}

.rt-r-mt-1 {
  --margin-top: var(--space-1);
}

.rt-r-mt-2 {
  --margin-top: var(--space-2);
}

.rt-r-mt-3 {
  --margin-top: var(--space-3);
}

.rt-r-mt-4 {
  --margin-top: var(--space-4);
}

.rt-r-mt-5 {
  --margin-top: var(--space-5);
}

.rt-r-mt-6 {
  --margin-top: var(--space-6);
}

.rt-r-mt-7 {
  --margin-top: var(--space-7);
}

.rt-r-mt-8 {
  --margin-top: var(--space-8);
}

.rt-r-mt-9 {
  --margin-top: var(--space-9);
}

.-rt-r-mt-1 {
  --margin-top: calc(-1 * var(--space-1));
}

.-rt-r-mt-2 {
  --margin-top: calc(-1 * var(--space-2));
}

.-rt-r-mt-3 {
  --margin-top: calc(-1 * var(--space-3));
}

.-rt-r-mt-4 {
  --margin-top: calc(-1 * var(--space-4));
}

.-rt-r-mt-5 {
  --margin-top: calc(-1 * var(--space-5));
}

.-rt-r-mt-6 {
  --margin-top: calc(-1 * var(--space-6));
}

.-rt-r-mt-7 {
  --margin-top: calc(-1 * var(--space-7));
}

.-rt-r-mt-8 {
  --margin-top: calc(-1 * var(--space-8));
}

.-rt-r-mt-9 {
  --margin-top: calc(-1 * var(--space-9));
}

@media (min-width: 520px) {
  .xs\:rt-r-mt, .xs\:rt-r-mt-0, .xs\:rt-r-mt-1, .xs\:rt-r-mt-2, .xs\:rt-r-mt-3, .xs\:rt-r-mt-4, .xs\:rt-r-mt-5, .xs\:rt-r-mt-6, .xs\:rt-r-mt-7, .xs\:rt-r-mt-8, .xs\:rt-r-mt-9, .xs\:-rt-r-mt-1, .xs\:-rt-r-mt-2, .xs\:-rt-r-mt-3, .xs\:-rt-r-mt-4, .xs\:-rt-r-mt-5, .xs\:-rt-r-mt-6, .xs\:-rt-r-mt-7, .xs\:-rt-r-mt-8, .xs\:-rt-r-mt-9 {
    margin-top: var(--margin-top-override, var(--margin-top));
  }

  .xs\:rt-r-mt {
    --margin-top: var(--mt-xs);
  }

  .xs\:rt-r-mt-0 {
    --margin-top: 0px;
  }

  .xs\:rt-r-mt-1 {
    --margin-top: var(--space-1);
  }

  .xs\:rt-r-mt-2 {
    --margin-top: var(--space-2);
  }

  .xs\:rt-r-mt-3 {
    --margin-top: var(--space-3);
  }

  .xs\:rt-r-mt-4 {
    --margin-top: var(--space-4);
  }

  .xs\:rt-r-mt-5 {
    --margin-top: var(--space-5);
  }

  .xs\:rt-r-mt-6 {
    --margin-top: var(--space-6);
  }

  .xs\:rt-r-mt-7 {
    --margin-top: var(--space-7);
  }

  .xs\:rt-r-mt-8 {
    --margin-top: var(--space-8);
  }

  .xs\:rt-r-mt-9 {
    --margin-top: var(--space-9);
  }

  .xs\:-rt-r-mt-1 {
    --margin-top: calc(-1 * var(--space-1));
  }

  .xs\:-rt-r-mt-2 {
    --margin-top: calc(-1 * var(--space-2));
  }

  .xs\:-rt-r-mt-3 {
    --margin-top: calc(-1 * var(--space-3));
  }

  .xs\:-rt-r-mt-4 {
    --margin-top: calc(-1 * var(--space-4));
  }

  .xs\:-rt-r-mt-5 {
    --margin-top: calc(-1 * var(--space-5));
  }

  .xs\:-rt-r-mt-6 {
    --margin-top: calc(-1 * var(--space-6));
  }

  .xs\:-rt-r-mt-7 {
    --margin-top: calc(-1 * var(--space-7));
  }

  .xs\:-rt-r-mt-8 {
    --margin-top: calc(-1 * var(--space-8));
  }

  .xs\:-rt-r-mt-9 {
    --margin-top: calc(-1 * var(--space-9));
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-mt, .sm\:rt-r-mt-0, .sm\:rt-r-mt-1, .sm\:rt-r-mt-2, .sm\:rt-r-mt-3, .sm\:rt-r-mt-4, .sm\:rt-r-mt-5, .sm\:rt-r-mt-6, .sm\:rt-r-mt-7, .sm\:rt-r-mt-8, .sm\:rt-r-mt-9, .sm\:-rt-r-mt-1, .sm\:-rt-r-mt-2, .sm\:-rt-r-mt-3, .sm\:-rt-r-mt-4, .sm\:-rt-r-mt-5, .sm\:-rt-r-mt-6, .sm\:-rt-r-mt-7, .sm\:-rt-r-mt-8, .sm\:-rt-r-mt-9 {
    margin-top: var(--margin-top-override, var(--margin-top));
  }

  .sm\:rt-r-mt {
    --margin-top: var(--mt-sm);
  }

  .sm\:rt-r-mt-0 {
    --margin-top: 0px;
  }

  .sm\:rt-r-mt-1 {
    --margin-top: var(--space-1);
  }

  .sm\:rt-r-mt-2 {
    --margin-top: var(--space-2);
  }

  .sm\:rt-r-mt-3 {
    --margin-top: var(--space-3);
  }

  .sm\:rt-r-mt-4 {
    --margin-top: var(--space-4);
  }

  .sm\:rt-r-mt-5 {
    --margin-top: var(--space-5);
  }

  .sm\:rt-r-mt-6 {
    --margin-top: var(--space-6);
  }

  .sm\:rt-r-mt-7 {
    --margin-top: var(--space-7);
  }

  .sm\:rt-r-mt-8 {
    --margin-top: var(--space-8);
  }

  .sm\:rt-r-mt-9 {
    --margin-top: var(--space-9);
  }

  .sm\:-rt-r-mt-1 {
    --margin-top: calc(-1 * var(--space-1));
  }

  .sm\:-rt-r-mt-2 {
    --margin-top: calc(-1 * var(--space-2));
  }

  .sm\:-rt-r-mt-3 {
    --margin-top: calc(-1 * var(--space-3));
  }

  .sm\:-rt-r-mt-4 {
    --margin-top: calc(-1 * var(--space-4));
  }

  .sm\:-rt-r-mt-5 {
    --margin-top: calc(-1 * var(--space-5));
  }

  .sm\:-rt-r-mt-6 {
    --margin-top: calc(-1 * var(--space-6));
  }

  .sm\:-rt-r-mt-7 {
    --margin-top: calc(-1 * var(--space-7));
  }

  .sm\:-rt-r-mt-8 {
    --margin-top: calc(-1 * var(--space-8));
  }

  .sm\:-rt-r-mt-9 {
    --margin-top: calc(-1 * var(--space-9));
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-mt, .md\:rt-r-mt-0, .md\:rt-r-mt-1, .md\:rt-r-mt-2, .md\:rt-r-mt-3, .md\:rt-r-mt-4, .md\:rt-r-mt-5, .md\:rt-r-mt-6, .md\:rt-r-mt-7, .md\:rt-r-mt-8, .md\:rt-r-mt-9, .md\:-rt-r-mt-1, .md\:-rt-r-mt-2, .md\:-rt-r-mt-3, .md\:-rt-r-mt-4, .md\:-rt-r-mt-5, .md\:-rt-r-mt-6, .md\:-rt-r-mt-7, .md\:-rt-r-mt-8, .md\:-rt-r-mt-9 {
    margin-top: var(--margin-top-override, var(--margin-top));
  }

  .md\:rt-r-mt {
    --margin-top: var(--mt-md);
  }

  .md\:rt-r-mt-0 {
    --margin-top: 0px;
  }

  .md\:rt-r-mt-1 {
    --margin-top: var(--space-1);
  }

  .md\:rt-r-mt-2 {
    --margin-top: var(--space-2);
  }

  .md\:rt-r-mt-3 {
    --margin-top: var(--space-3);
  }

  .md\:rt-r-mt-4 {
    --margin-top: var(--space-4);
  }

  .md\:rt-r-mt-5 {
    --margin-top: var(--space-5);
  }

  .md\:rt-r-mt-6 {
    --margin-top: var(--space-6);
  }

  .md\:rt-r-mt-7 {
    --margin-top: var(--space-7);
  }

  .md\:rt-r-mt-8 {
    --margin-top: var(--space-8);
  }

  .md\:rt-r-mt-9 {
    --margin-top: var(--space-9);
  }

  .md\:-rt-r-mt-1 {
    --margin-top: calc(-1 * var(--space-1));
  }

  .md\:-rt-r-mt-2 {
    --margin-top: calc(-1 * var(--space-2));
  }

  .md\:-rt-r-mt-3 {
    --margin-top: calc(-1 * var(--space-3));
  }

  .md\:-rt-r-mt-4 {
    --margin-top: calc(-1 * var(--space-4));
  }

  .md\:-rt-r-mt-5 {
    --margin-top: calc(-1 * var(--space-5));
  }

  .md\:-rt-r-mt-6 {
    --margin-top: calc(-1 * var(--space-6));
  }

  .md\:-rt-r-mt-7 {
    --margin-top: calc(-1 * var(--space-7));
  }

  .md\:-rt-r-mt-8 {
    --margin-top: calc(-1 * var(--space-8));
  }

  .md\:-rt-r-mt-9 {
    --margin-top: calc(-1 * var(--space-9));
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-mt, .lg\:rt-r-mt-0, .lg\:rt-r-mt-1, .lg\:rt-r-mt-2, .lg\:rt-r-mt-3, .lg\:rt-r-mt-4, .lg\:rt-r-mt-5, .lg\:rt-r-mt-6, .lg\:rt-r-mt-7, .lg\:rt-r-mt-8, .lg\:rt-r-mt-9, .lg\:-rt-r-mt-1, .lg\:-rt-r-mt-2, .lg\:-rt-r-mt-3, .lg\:-rt-r-mt-4, .lg\:-rt-r-mt-5, .lg\:-rt-r-mt-6, .lg\:-rt-r-mt-7, .lg\:-rt-r-mt-8, .lg\:-rt-r-mt-9 {
    margin-top: var(--margin-top-override, var(--margin-top));
  }

  .lg\:rt-r-mt {
    --margin-top: var(--mt-lg);
  }

  .lg\:rt-r-mt-0 {
    --margin-top: 0px;
  }

  .lg\:rt-r-mt-1 {
    --margin-top: var(--space-1);
  }

  .lg\:rt-r-mt-2 {
    --margin-top: var(--space-2);
  }

  .lg\:rt-r-mt-3 {
    --margin-top: var(--space-3);
  }

  .lg\:rt-r-mt-4 {
    --margin-top: var(--space-4);
  }

  .lg\:rt-r-mt-5 {
    --margin-top: var(--space-5);
  }

  .lg\:rt-r-mt-6 {
    --margin-top: var(--space-6);
  }

  .lg\:rt-r-mt-7 {
    --margin-top: var(--space-7);
  }

  .lg\:rt-r-mt-8 {
    --margin-top: var(--space-8);
  }

  .lg\:rt-r-mt-9 {
    --margin-top: var(--space-9);
  }

  .lg\:-rt-r-mt-1 {
    --margin-top: calc(-1 * var(--space-1));
  }

  .lg\:-rt-r-mt-2 {
    --margin-top: calc(-1 * var(--space-2));
  }

  .lg\:-rt-r-mt-3 {
    --margin-top: calc(-1 * var(--space-3));
  }

  .lg\:-rt-r-mt-4 {
    --margin-top: calc(-1 * var(--space-4));
  }

  .lg\:-rt-r-mt-5 {
    --margin-top: calc(-1 * var(--space-5));
  }

  .lg\:-rt-r-mt-6 {
    --margin-top: calc(-1 * var(--space-6));
  }

  .lg\:-rt-r-mt-7 {
    --margin-top: calc(-1 * var(--space-7));
  }

  .lg\:-rt-r-mt-8 {
    --margin-top: calc(-1 * var(--space-8));
  }

  .lg\:-rt-r-mt-9 {
    --margin-top: calc(-1 * var(--space-9));
  }
}

@media (min-width: 1640px) {
  .xl\:rt-r-mt, .xl\:rt-r-mt-0, .xl\:rt-r-mt-1, .xl\:rt-r-mt-2, .xl\:rt-r-mt-3, .xl\:rt-r-mt-4, .xl\:rt-r-mt-5, .xl\:rt-r-mt-6, .xl\:rt-r-mt-7, .xl\:rt-r-mt-8, .xl\:rt-r-mt-9, .xl\:-rt-r-mt-1, .xl\:-rt-r-mt-2, .xl\:-rt-r-mt-3, .xl\:-rt-r-mt-4, .xl\:-rt-r-mt-5, .xl\:-rt-r-mt-6, .xl\:-rt-r-mt-7, .xl\:-rt-r-mt-8, .xl\:-rt-r-mt-9 {
    margin-top: var(--margin-top-override, var(--margin-top));
  }

  .xl\:rt-r-mt {
    --margin-top: var(--mt-xl);
  }

  .xl\:rt-r-mt-0 {
    --margin-top: 0px;
  }

  .xl\:rt-r-mt-1 {
    --margin-top: var(--space-1);
  }

  .xl\:rt-r-mt-2 {
    --margin-top: var(--space-2);
  }

  .xl\:rt-r-mt-3 {
    --margin-top: var(--space-3);
  }

  .xl\:rt-r-mt-4 {
    --margin-top: var(--space-4);
  }

  .xl\:rt-r-mt-5 {
    --margin-top: var(--space-5);
  }

  .xl\:rt-r-mt-6 {
    --margin-top: var(--space-6);
  }

  .xl\:rt-r-mt-7 {
    --margin-top: var(--space-7);
  }

  .xl\:rt-r-mt-8 {
    --margin-top: var(--space-8);
  }

  .xl\:rt-r-mt-9 {
    --margin-top: var(--space-9);
  }

  .xl\:-rt-r-mt-1 {
    --margin-top: calc(-1 * var(--space-1));
  }

  .xl\:-rt-r-mt-2 {
    --margin-top: calc(-1 * var(--space-2));
  }

  .xl\:-rt-r-mt-3 {
    --margin-top: calc(-1 * var(--space-3));
  }

  .xl\:-rt-r-mt-4 {
    --margin-top: calc(-1 * var(--space-4));
  }

  .xl\:-rt-r-mt-5 {
    --margin-top: calc(-1 * var(--space-5));
  }

  .xl\:-rt-r-mt-6 {
    --margin-top: calc(-1 * var(--space-6));
  }

  .xl\:-rt-r-mt-7 {
    --margin-top: calc(-1 * var(--space-7));
  }

  .xl\:-rt-r-mt-8 {
    --margin-top: calc(-1 * var(--space-8));
  }

  .xl\:-rt-r-mt-9 {
    --margin-top: calc(-1 * var(--space-9));
  }
}

.rt-r-mr, .rt-r-mr-0, .rt-r-mr-1, .rt-r-mr-2, .rt-r-mr-3, .rt-r-mr-4, .rt-r-mr-5, .rt-r-mr-6, .rt-r-mr-7, .rt-r-mr-8, .rt-r-mr-9, .-rt-r-mr-1, .-rt-r-mr-2, .-rt-r-mr-3, .-rt-r-mr-4, .-rt-r-mr-5, .-rt-r-mr-6, .-rt-r-mr-7, .-rt-r-mr-8, .-rt-r-mr-9 {
  margin-right: var(--margin-right-override, var(--margin-right));
}

.rt-r-mr {
  --margin-right: var(--mr);
}

.rt-r-mr-0 {
  --margin-right: 0px;
}

.rt-r-mr-1 {
  --margin-right: var(--space-1);
}

.rt-r-mr-2 {
  --margin-right: var(--space-2);
}

.rt-r-mr-3 {
  --margin-right: var(--space-3);
}

.rt-r-mr-4 {
  --margin-right: var(--space-4);
}

.rt-r-mr-5 {
  --margin-right: var(--space-5);
}

.rt-r-mr-6 {
  --margin-right: var(--space-6);
}

.rt-r-mr-7 {
  --margin-right: var(--space-7);
}

.rt-r-mr-8 {
  --margin-right: var(--space-8);
}

.rt-r-mr-9 {
  --margin-right: var(--space-9);
}

.-rt-r-mr-1 {
  --margin-right: calc(-1 * var(--space-1));
}

.-rt-r-mr-2 {
  --margin-right: calc(-1 * var(--space-2));
}

.-rt-r-mr-3 {
  --margin-right: calc(-1 * var(--space-3));
}

.-rt-r-mr-4 {
  --margin-right: calc(-1 * var(--space-4));
}

.-rt-r-mr-5 {
  --margin-right: calc(-1 * var(--space-5));
}

.-rt-r-mr-6 {
  --margin-right: calc(-1 * var(--space-6));
}

.-rt-r-mr-7 {
  --margin-right: calc(-1 * var(--space-7));
}

.-rt-r-mr-8 {
  --margin-right: calc(-1 * var(--space-8));
}

.-rt-r-mr-9 {
  --margin-right: calc(-1 * var(--space-9));
}

@media (min-width: 520px) {
  .xs\:rt-r-mr, .xs\:rt-r-mr-0, .xs\:rt-r-mr-1, .xs\:rt-r-mr-2, .xs\:rt-r-mr-3, .xs\:rt-r-mr-4, .xs\:rt-r-mr-5, .xs\:rt-r-mr-6, .xs\:rt-r-mr-7, .xs\:rt-r-mr-8, .xs\:rt-r-mr-9, .xs\:-rt-r-mr-1, .xs\:-rt-r-mr-2, .xs\:-rt-r-mr-3, .xs\:-rt-r-mr-4, .xs\:-rt-r-mr-5, .xs\:-rt-r-mr-6, .xs\:-rt-r-mr-7, .xs\:-rt-r-mr-8, .xs\:-rt-r-mr-9 {
    margin-right: var(--margin-right-override, var(--margin-right));
  }

  .xs\:rt-r-mr {
    --margin-right: var(--mr-xs);
  }

  .xs\:rt-r-mr-0 {
    --margin-right: 0px;
  }

  .xs\:rt-r-mr-1 {
    --margin-right: var(--space-1);
  }

  .xs\:rt-r-mr-2 {
    --margin-right: var(--space-2);
  }

  .xs\:rt-r-mr-3 {
    --margin-right: var(--space-3);
  }

  .xs\:rt-r-mr-4 {
    --margin-right: var(--space-4);
  }

  .xs\:rt-r-mr-5 {
    --margin-right: var(--space-5);
  }

  .xs\:rt-r-mr-6 {
    --margin-right: var(--space-6);
  }

  .xs\:rt-r-mr-7 {
    --margin-right: var(--space-7);
  }

  .xs\:rt-r-mr-8 {
    --margin-right: var(--space-8);
  }

  .xs\:rt-r-mr-9 {
    --margin-right: var(--space-9);
  }

  .xs\:-rt-r-mr-1 {
    --margin-right: calc(-1 * var(--space-1));
  }

  .xs\:-rt-r-mr-2 {
    --margin-right: calc(-1 * var(--space-2));
  }

  .xs\:-rt-r-mr-3 {
    --margin-right: calc(-1 * var(--space-3));
  }

  .xs\:-rt-r-mr-4 {
    --margin-right: calc(-1 * var(--space-4));
  }

  .xs\:-rt-r-mr-5 {
    --margin-right: calc(-1 * var(--space-5));
  }

  .xs\:-rt-r-mr-6 {
    --margin-right: calc(-1 * var(--space-6));
  }

  .xs\:-rt-r-mr-7 {
    --margin-right: calc(-1 * var(--space-7));
  }

  .xs\:-rt-r-mr-8 {
    --margin-right: calc(-1 * var(--space-8));
  }

  .xs\:-rt-r-mr-9 {
    --margin-right: calc(-1 * var(--space-9));
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-mr, .sm\:rt-r-mr-0, .sm\:rt-r-mr-1, .sm\:rt-r-mr-2, .sm\:rt-r-mr-3, .sm\:rt-r-mr-4, .sm\:rt-r-mr-5, .sm\:rt-r-mr-6, .sm\:rt-r-mr-7, .sm\:rt-r-mr-8, .sm\:rt-r-mr-9, .sm\:-rt-r-mr-1, .sm\:-rt-r-mr-2, .sm\:-rt-r-mr-3, .sm\:-rt-r-mr-4, .sm\:-rt-r-mr-5, .sm\:-rt-r-mr-6, .sm\:-rt-r-mr-7, .sm\:-rt-r-mr-8, .sm\:-rt-r-mr-9 {
    margin-right: var(--margin-right-override, var(--margin-right));
  }

  .sm\:rt-r-mr {
    --margin-right: var(--mr-sm);
  }

  .sm\:rt-r-mr-0 {
    --margin-right: 0px;
  }

  .sm\:rt-r-mr-1 {
    --margin-right: var(--space-1);
  }

  .sm\:rt-r-mr-2 {
    --margin-right: var(--space-2);
  }

  .sm\:rt-r-mr-3 {
    --margin-right: var(--space-3);
  }

  .sm\:rt-r-mr-4 {
    --margin-right: var(--space-4);
  }

  .sm\:rt-r-mr-5 {
    --margin-right: var(--space-5);
  }

  .sm\:rt-r-mr-6 {
    --margin-right: var(--space-6);
  }

  .sm\:rt-r-mr-7 {
    --margin-right: var(--space-7);
  }

  .sm\:rt-r-mr-8 {
    --margin-right: var(--space-8);
  }

  .sm\:rt-r-mr-9 {
    --margin-right: var(--space-9);
  }

  .sm\:-rt-r-mr-1 {
    --margin-right: calc(-1 * var(--space-1));
  }

  .sm\:-rt-r-mr-2 {
    --margin-right: calc(-1 * var(--space-2));
  }

  .sm\:-rt-r-mr-3 {
    --margin-right: calc(-1 * var(--space-3));
  }

  .sm\:-rt-r-mr-4 {
    --margin-right: calc(-1 * var(--space-4));
  }

  .sm\:-rt-r-mr-5 {
    --margin-right: calc(-1 * var(--space-5));
  }

  .sm\:-rt-r-mr-6 {
    --margin-right: calc(-1 * var(--space-6));
  }

  .sm\:-rt-r-mr-7 {
    --margin-right: calc(-1 * var(--space-7));
  }

  .sm\:-rt-r-mr-8 {
    --margin-right: calc(-1 * var(--space-8));
  }

  .sm\:-rt-r-mr-9 {
    --margin-right: calc(-1 * var(--space-9));
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-mr, .md\:rt-r-mr-0, .md\:rt-r-mr-1, .md\:rt-r-mr-2, .md\:rt-r-mr-3, .md\:rt-r-mr-4, .md\:rt-r-mr-5, .md\:rt-r-mr-6, .md\:rt-r-mr-7, .md\:rt-r-mr-8, .md\:rt-r-mr-9, .md\:-rt-r-mr-1, .md\:-rt-r-mr-2, .md\:-rt-r-mr-3, .md\:-rt-r-mr-4, .md\:-rt-r-mr-5, .md\:-rt-r-mr-6, .md\:-rt-r-mr-7, .md\:-rt-r-mr-8, .md\:-rt-r-mr-9 {
    margin-right: var(--margin-right-override, var(--margin-right));
  }

  .md\:rt-r-mr {
    --margin-right: var(--mr-md);
  }

  .md\:rt-r-mr-0 {
    --margin-right: 0px;
  }

  .md\:rt-r-mr-1 {
    --margin-right: var(--space-1);
  }

  .md\:rt-r-mr-2 {
    --margin-right: var(--space-2);
  }

  .md\:rt-r-mr-3 {
    --margin-right: var(--space-3);
  }

  .md\:rt-r-mr-4 {
    --margin-right: var(--space-4);
  }

  .md\:rt-r-mr-5 {
    --margin-right: var(--space-5);
  }

  .md\:rt-r-mr-6 {
    --margin-right: var(--space-6);
  }

  .md\:rt-r-mr-7 {
    --margin-right: var(--space-7);
  }

  .md\:rt-r-mr-8 {
    --margin-right: var(--space-8);
  }

  .md\:rt-r-mr-9 {
    --margin-right: var(--space-9);
  }

  .md\:-rt-r-mr-1 {
    --margin-right: calc(-1 * var(--space-1));
  }

  .md\:-rt-r-mr-2 {
    --margin-right: calc(-1 * var(--space-2));
  }

  .md\:-rt-r-mr-3 {
    --margin-right: calc(-1 * var(--space-3));
  }

  .md\:-rt-r-mr-4 {
    --margin-right: calc(-1 * var(--space-4));
  }

  .md\:-rt-r-mr-5 {
    --margin-right: calc(-1 * var(--space-5));
  }

  .md\:-rt-r-mr-6 {
    --margin-right: calc(-1 * var(--space-6));
  }

  .md\:-rt-r-mr-7 {
    --margin-right: calc(-1 * var(--space-7));
  }

  .md\:-rt-r-mr-8 {
    --margin-right: calc(-1 * var(--space-8));
  }

  .md\:-rt-r-mr-9 {
    --margin-right: calc(-1 * var(--space-9));
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-mr, .lg\:rt-r-mr-0, .lg\:rt-r-mr-1, .lg\:rt-r-mr-2, .lg\:rt-r-mr-3, .lg\:rt-r-mr-4, .lg\:rt-r-mr-5, .lg\:rt-r-mr-6, .lg\:rt-r-mr-7, .lg\:rt-r-mr-8, .lg\:rt-r-mr-9, .lg\:-rt-r-mr-1, .lg\:-rt-r-mr-2, .lg\:-rt-r-mr-3, .lg\:-rt-r-mr-4, .lg\:-rt-r-mr-5, .lg\:-rt-r-mr-6, .lg\:-rt-r-mr-7, .lg\:-rt-r-mr-8, .lg\:-rt-r-mr-9 {
    margin-right: var(--margin-right-override, var(--margin-right));
  }

  .lg\:rt-r-mr {
    --margin-right: var(--mr-lg);
  }

  .lg\:rt-r-mr-0 {
    --margin-right: 0px;
  }

  .lg\:rt-r-mr-1 {
    --margin-right: var(--space-1);
  }

  .lg\:rt-r-mr-2 {
    --margin-right: var(--space-2);
  }

  .lg\:rt-r-mr-3 {
    --margin-right: var(--space-3);
  }

  .lg\:rt-r-mr-4 {
    --margin-right: var(--space-4);
  }

  .lg\:rt-r-mr-5 {
    --margin-right: var(--space-5);
  }

  .lg\:rt-r-mr-6 {
    --margin-right: var(--space-6);
  }

  .lg\:rt-r-mr-7 {
    --margin-right: var(--space-7);
  }

  .lg\:rt-r-mr-8 {
    --margin-right: var(--space-8);
  }

  .lg\:rt-r-mr-9 {
    --margin-right: var(--space-9);
  }

  .lg\:-rt-r-mr-1 {
    --margin-right: calc(-1 * var(--space-1));
  }

  .lg\:-rt-r-mr-2 {
    --margin-right: calc(-1 * var(--space-2));
  }

  .lg\:-rt-r-mr-3 {
    --margin-right: calc(-1 * var(--space-3));
  }

  .lg\:-rt-r-mr-4 {
    --margin-right: calc(-1 * var(--space-4));
  }

  .lg\:-rt-r-mr-5 {
    --margin-right: calc(-1 * var(--space-5));
  }

  .lg\:-rt-r-mr-6 {
    --margin-right: calc(-1 * var(--space-6));
  }

  .lg\:-rt-r-mr-7 {
    --margin-right: calc(-1 * var(--space-7));
  }

  .lg\:-rt-r-mr-8 {
    --margin-right: calc(-1 * var(--space-8));
  }

  .lg\:-rt-r-mr-9 {
    --margin-right: calc(-1 * var(--space-9));
  }
}

@media (min-width: 1640px) {
  .xl\:rt-r-mr, .xl\:rt-r-mr-0, .xl\:rt-r-mr-1, .xl\:rt-r-mr-2, .xl\:rt-r-mr-3, .xl\:rt-r-mr-4, .xl\:rt-r-mr-5, .xl\:rt-r-mr-6, .xl\:rt-r-mr-7, .xl\:rt-r-mr-8, .xl\:rt-r-mr-9, .xl\:-rt-r-mr-1, .xl\:-rt-r-mr-2, .xl\:-rt-r-mr-3, .xl\:-rt-r-mr-4, .xl\:-rt-r-mr-5, .xl\:-rt-r-mr-6, .xl\:-rt-r-mr-7, .xl\:-rt-r-mr-8, .xl\:-rt-r-mr-9 {
    margin-right: var(--margin-right-override, var(--margin-right));
  }

  .xl\:rt-r-mr {
    --margin-right: var(--mr-xl);
  }

  .xl\:rt-r-mr-0 {
    --margin-right: 0px;
  }

  .xl\:rt-r-mr-1 {
    --margin-right: var(--space-1);
  }

  .xl\:rt-r-mr-2 {
    --margin-right: var(--space-2);
  }

  .xl\:rt-r-mr-3 {
    --margin-right: var(--space-3);
  }

  .xl\:rt-r-mr-4 {
    --margin-right: var(--space-4);
  }

  .xl\:rt-r-mr-5 {
    --margin-right: var(--space-5);
  }

  .xl\:rt-r-mr-6 {
    --margin-right: var(--space-6);
  }

  .xl\:rt-r-mr-7 {
    --margin-right: var(--space-7);
  }

  .xl\:rt-r-mr-8 {
    --margin-right: var(--space-8);
  }

  .xl\:rt-r-mr-9 {
    --margin-right: var(--space-9);
  }

  .xl\:-rt-r-mr-1 {
    --margin-right: calc(-1 * var(--space-1));
  }

  .xl\:-rt-r-mr-2 {
    --margin-right: calc(-1 * var(--space-2));
  }

  .xl\:-rt-r-mr-3 {
    --margin-right: calc(-1 * var(--space-3));
  }

  .xl\:-rt-r-mr-4 {
    --margin-right: calc(-1 * var(--space-4));
  }

  .xl\:-rt-r-mr-5 {
    --margin-right: calc(-1 * var(--space-5));
  }

  .xl\:-rt-r-mr-6 {
    --margin-right: calc(-1 * var(--space-6));
  }

  .xl\:-rt-r-mr-7 {
    --margin-right: calc(-1 * var(--space-7));
  }

  .xl\:-rt-r-mr-8 {
    --margin-right: calc(-1 * var(--space-8));
  }

  .xl\:-rt-r-mr-9 {
    --margin-right: calc(-1 * var(--space-9));
  }
}

.rt-r-mb, .rt-r-mb-0, .rt-r-mb-1, .rt-r-mb-2, .rt-r-mb-3, .rt-r-mb-4, .rt-r-mb-5, .rt-r-mb-6, .rt-r-mb-7, .rt-r-mb-8, .rt-r-mb-9, .-rt-r-mb-1, .-rt-r-mb-2, .-rt-r-mb-3, .-rt-r-mb-4, .-rt-r-mb-5, .-rt-r-mb-6, .-rt-r-mb-7, .-rt-r-mb-8, .-rt-r-mb-9 {
  margin-bottom: var(--margin-bottom-override, var(--margin-bottom));
}

.rt-r-mb {
  --margin-bottom: var(--mb);
}

.rt-r-mb-0 {
  --margin-bottom: 0px;
}

.rt-r-mb-1 {
  --margin-bottom: var(--space-1);
}

.rt-r-mb-2 {
  --margin-bottom: var(--space-2);
}

.rt-r-mb-3 {
  --margin-bottom: var(--space-3);
}

.rt-r-mb-4 {
  --margin-bottom: var(--space-4);
}

.rt-r-mb-5 {
  --margin-bottom: var(--space-5);
}

.rt-r-mb-6 {
  --margin-bottom: var(--space-6);
}

.rt-r-mb-7 {
  --margin-bottom: var(--space-7);
}

.rt-r-mb-8 {
  --margin-bottom: var(--space-8);
}

.rt-r-mb-9 {
  --margin-bottom: var(--space-9);
}

.-rt-r-mb-1 {
  --margin-bottom: calc(-1 * var(--space-1));
}

.-rt-r-mb-2 {
  --margin-bottom: calc(-1 * var(--space-2));
}

.-rt-r-mb-3 {
  --margin-bottom: calc(-1 * var(--space-3));
}

.-rt-r-mb-4 {
  --margin-bottom: calc(-1 * var(--space-4));
}

.-rt-r-mb-5 {
  --margin-bottom: calc(-1 * var(--space-5));
}

.-rt-r-mb-6 {
  --margin-bottom: calc(-1 * var(--space-6));
}

.-rt-r-mb-7 {
  --margin-bottom: calc(-1 * var(--space-7));
}

.-rt-r-mb-8 {
  --margin-bottom: calc(-1 * var(--space-8));
}

.-rt-r-mb-9 {
  --margin-bottom: calc(-1 * var(--space-9));
}

@media (min-width: 520px) {
  .xs\:rt-r-mb, .xs\:rt-r-mb-0, .xs\:rt-r-mb-1, .xs\:rt-r-mb-2, .xs\:rt-r-mb-3, .xs\:rt-r-mb-4, .xs\:rt-r-mb-5, .xs\:rt-r-mb-6, .xs\:rt-r-mb-7, .xs\:rt-r-mb-8, .xs\:rt-r-mb-9, .xs\:-rt-r-mb-1, .xs\:-rt-r-mb-2, .xs\:-rt-r-mb-3, .xs\:-rt-r-mb-4, .xs\:-rt-r-mb-5, .xs\:-rt-r-mb-6, .xs\:-rt-r-mb-7, .xs\:-rt-r-mb-8, .xs\:-rt-r-mb-9 {
    margin-bottom: var(--margin-bottom-override, var(--margin-bottom));
  }

  .xs\:rt-r-mb {
    --margin-bottom: var(--mb-xs);
  }

  .xs\:rt-r-mb-0 {
    --margin-bottom: 0px;
  }

  .xs\:rt-r-mb-1 {
    --margin-bottom: var(--space-1);
  }

  .xs\:rt-r-mb-2 {
    --margin-bottom: var(--space-2);
  }

  .xs\:rt-r-mb-3 {
    --margin-bottom: var(--space-3);
  }

  .xs\:rt-r-mb-4 {
    --margin-bottom: var(--space-4);
  }

  .xs\:rt-r-mb-5 {
    --margin-bottom: var(--space-5);
  }

  .xs\:rt-r-mb-6 {
    --margin-bottom: var(--space-6);
  }

  .xs\:rt-r-mb-7 {
    --margin-bottom: var(--space-7);
  }

  .xs\:rt-r-mb-8 {
    --margin-bottom: var(--space-8);
  }

  .xs\:rt-r-mb-9 {
    --margin-bottom: var(--space-9);
  }

  .xs\:-rt-r-mb-1 {
    --margin-bottom: calc(-1 * var(--space-1));
  }

  .xs\:-rt-r-mb-2 {
    --margin-bottom: calc(-1 * var(--space-2));
  }

  .xs\:-rt-r-mb-3 {
    --margin-bottom: calc(-1 * var(--space-3));
  }

  .xs\:-rt-r-mb-4 {
    --margin-bottom: calc(-1 * var(--space-4));
  }

  .xs\:-rt-r-mb-5 {
    --margin-bottom: calc(-1 * var(--space-5));
  }

  .xs\:-rt-r-mb-6 {
    --margin-bottom: calc(-1 * var(--space-6));
  }

  .xs\:-rt-r-mb-7 {
    --margin-bottom: calc(-1 * var(--space-7));
  }

  .xs\:-rt-r-mb-8 {
    --margin-bottom: calc(-1 * var(--space-8));
  }

  .xs\:-rt-r-mb-9 {
    --margin-bottom: calc(-1 * var(--space-9));
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-mb, .sm\:rt-r-mb-0, .sm\:rt-r-mb-1, .sm\:rt-r-mb-2, .sm\:rt-r-mb-3, .sm\:rt-r-mb-4, .sm\:rt-r-mb-5, .sm\:rt-r-mb-6, .sm\:rt-r-mb-7, .sm\:rt-r-mb-8, .sm\:rt-r-mb-9, .sm\:-rt-r-mb-1, .sm\:-rt-r-mb-2, .sm\:-rt-r-mb-3, .sm\:-rt-r-mb-4, .sm\:-rt-r-mb-5, .sm\:-rt-r-mb-6, .sm\:-rt-r-mb-7, .sm\:-rt-r-mb-8, .sm\:-rt-r-mb-9 {
    margin-bottom: var(--margin-bottom-override, var(--margin-bottom));
  }

  .sm\:rt-r-mb {
    --margin-bottom: var(--mb-sm);
  }

  .sm\:rt-r-mb-0 {
    --margin-bottom: 0px;
  }

  .sm\:rt-r-mb-1 {
    --margin-bottom: var(--space-1);
  }

  .sm\:rt-r-mb-2 {
    --margin-bottom: var(--space-2);
  }

  .sm\:rt-r-mb-3 {
    --margin-bottom: var(--space-3);
  }

  .sm\:rt-r-mb-4 {
    --margin-bottom: var(--space-4);
  }

  .sm\:rt-r-mb-5 {
    --margin-bottom: var(--space-5);
  }

  .sm\:rt-r-mb-6 {
    --margin-bottom: var(--space-6);
  }

  .sm\:rt-r-mb-7 {
    --margin-bottom: var(--space-7);
  }

  .sm\:rt-r-mb-8 {
    --margin-bottom: var(--space-8);
  }

  .sm\:rt-r-mb-9 {
    --margin-bottom: var(--space-9);
  }

  .sm\:-rt-r-mb-1 {
    --margin-bottom: calc(-1 * var(--space-1));
  }

  .sm\:-rt-r-mb-2 {
    --margin-bottom: calc(-1 * var(--space-2));
  }

  .sm\:-rt-r-mb-3 {
    --margin-bottom: calc(-1 * var(--space-3));
  }

  .sm\:-rt-r-mb-4 {
    --margin-bottom: calc(-1 * var(--space-4));
  }

  .sm\:-rt-r-mb-5 {
    --margin-bottom: calc(-1 * var(--space-5));
  }

  .sm\:-rt-r-mb-6 {
    --margin-bottom: calc(-1 * var(--space-6));
  }

  .sm\:-rt-r-mb-7 {
    --margin-bottom: calc(-1 * var(--space-7));
  }

  .sm\:-rt-r-mb-8 {
    --margin-bottom: calc(-1 * var(--space-8));
  }

  .sm\:-rt-r-mb-9 {
    --margin-bottom: calc(-1 * var(--space-9));
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-mb, .md\:rt-r-mb-0, .md\:rt-r-mb-1, .md\:rt-r-mb-2, .md\:rt-r-mb-3, .md\:rt-r-mb-4, .md\:rt-r-mb-5, .md\:rt-r-mb-6, .md\:rt-r-mb-7, .md\:rt-r-mb-8, .md\:rt-r-mb-9, .md\:-rt-r-mb-1, .md\:-rt-r-mb-2, .md\:-rt-r-mb-3, .md\:-rt-r-mb-4, .md\:-rt-r-mb-5, .md\:-rt-r-mb-6, .md\:-rt-r-mb-7, .md\:-rt-r-mb-8, .md\:-rt-r-mb-9 {
    margin-bottom: var(--margin-bottom-override, var(--margin-bottom));
  }

  .md\:rt-r-mb {
    --margin-bottom: var(--mb-md);
  }

  .md\:rt-r-mb-0 {
    --margin-bottom: 0px;
  }

  .md\:rt-r-mb-1 {
    --margin-bottom: var(--space-1);
  }

  .md\:rt-r-mb-2 {
    --margin-bottom: var(--space-2);
  }

  .md\:rt-r-mb-3 {
    --margin-bottom: var(--space-3);
  }

  .md\:rt-r-mb-4 {
    --margin-bottom: var(--space-4);
  }

  .md\:rt-r-mb-5 {
    --margin-bottom: var(--space-5);
  }

  .md\:rt-r-mb-6 {
    --margin-bottom: var(--space-6);
  }

  .md\:rt-r-mb-7 {
    --margin-bottom: var(--space-7);
  }

  .md\:rt-r-mb-8 {
    --margin-bottom: var(--space-8);
  }

  .md\:rt-r-mb-9 {
    --margin-bottom: var(--space-9);
  }

  .md\:-rt-r-mb-1 {
    --margin-bottom: calc(-1 * var(--space-1));
  }

  .md\:-rt-r-mb-2 {
    --margin-bottom: calc(-1 * var(--space-2));
  }

  .md\:-rt-r-mb-3 {
    --margin-bottom: calc(-1 * var(--space-3));
  }

  .md\:-rt-r-mb-4 {
    --margin-bottom: calc(-1 * var(--space-4));
  }

  .md\:-rt-r-mb-5 {
    --margin-bottom: calc(-1 * var(--space-5));
  }

  .md\:-rt-r-mb-6 {
    --margin-bottom: calc(-1 * var(--space-6));
  }

  .md\:-rt-r-mb-7 {
    --margin-bottom: calc(-1 * var(--space-7));
  }

  .md\:-rt-r-mb-8 {
    --margin-bottom: calc(-1 * var(--space-8));
  }

  .md\:-rt-r-mb-9 {
    --margin-bottom: calc(-1 * var(--space-9));
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-mb, .lg\:rt-r-mb-0, .lg\:rt-r-mb-1, .lg\:rt-r-mb-2, .lg\:rt-r-mb-3, .lg\:rt-r-mb-4, .lg\:rt-r-mb-5, .lg\:rt-r-mb-6, .lg\:rt-r-mb-7, .lg\:rt-r-mb-8, .lg\:rt-r-mb-9, .lg\:-rt-r-mb-1, .lg\:-rt-r-mb-2, .lg\:-rt-r-mb-3, .lg\:-rt-r-mb-4, .lg\:-rt-r-mb-5, .lg\:-rt-r-mb-6, .lg\:-rt-r-mb-7, .lg\:-rt-r-mb-8, .lg\:-rt-r-mb-9 {
    margin-bottom: var(--margin-bottom-override, var(--margin-bottom));
  }

  .lg\:rt-r-mb {
    --margin-bottom: var(--mb-lg);
  }

  .lg\:rt-r-mb-0 {
    --margin-bottom: 0px;
  }

  .lg\:rt-r-mb-1 {
    --margin-bottom: var(--space-1);
  }

  .lg\:rt-r-mb-2 {
    --margin-bottom: var(--space-2);
  }

  .lg\:rt-r-mb-3 {
    --margin-bottom: var(--space-3);
  }

  .lg\:rt-r-mb-4 {
    --margin-bottom: var(--space-4);
  }

  .lg\:rt-r-mb-5 {
    --margin-bottom: var(--space-5);
  }

  .lg\:rt-r-mb-6 {
    --margin-bottom: var(--space-6);
  }

  .lg\:rt-r-mb-7 {
    --margin-bottom: var(--space-7);
  }

  .lg\:rt-r-mb-8 {
    --margin-bottom: var(--space-8);
  }

  .lg\:rt-r-mb-9 {
    --margin-bottom: var(--space-9);
  }

  .lg\:-rt-r-mb-1 {
    --margin-bottom: calc(-1 * var(--space-1));
  }

  .lg\:-rt-r-mb-2 {
    --margin-bottom: calc(-1 * var(--space-2));
  }

  .lg\:-rt-r-mb-3 {
    --margin-bottom: calc(-1 * var(--space-3));
  }

  .lg\:-rt-r-mb-4 {
    --margin-bottom: calc(-1 * var(--space-4));
  }

  .lg\:-rt-r-mb-5 {
    --margin-bottom: calc(-1 * var(--space-5));
  }

  .lg\:-rt-r-mb-6 {
    --margin-bottom: calc(-1 * var(--space-6));
  }

  .lg\:-rt-r-mb-7 {
    --margin-bottom: calc(-1 * var(--space-7));
  }

  .lg\:-rt-r-mb-8 {
    --margin-bottom: calc(-1 * var(--space-8));
  }

  .lg\:-rt-r-mb-9 {
    --margin-bottom: calc(-1 * var(--space-9));
  }
}

@media (min-width: 1640px) {
  .xl\:rt-r-mb, .xl\:rt-r-mb-0, .xl\:rt-r-mb-1, .xl\:rt-r-mb-2, .xl\:rt-r-mb-3, .xl\:rt-r-mb-4, .xl\:rt-r-mb-5, .xl\:rt-r-mb-6, .xl\:rt-r-mb-7, .xl\:rt-r-mb-8, .xl\:rt-r-mb-9, .xl\:-rt-r-mb-1, .xl\:-rt-r-mb-2, .xl\:-rt-r-mb-3, .xl\:-rt-r-mb-4, .xl\:-rt-r-mb-5, .xl\:-rt-r-mb-6, .xl\:-rt-r-mb-7, .xl\:-rt-r-mb-8, .xl\:-rt-r-mb-9 {
    margin-bottom: var(--margin-bottom-override, var(--margin-bottom));
  }

  .xl\:rt-r-mb {
    --margin-bottom: var(--mb-xl);
  }

  .xl\:rt-r-mb-0 {
    --margin-bottom: 0px;
  }

  .xl\:rt-r-mb-1 {
    --margin-bottom: var(--space-1);
  }

  .xl\:rt-r-mb-2 {
    --margin-bottom: var(--space-2);
  }

  .xl\:rt-r-mb-3 {
    --margin-bottom: var(--space-3);
  }

  .xl\:rt-r-mb-4 {
    --margin-bottom: var(--space-4);
  }

  .xl\:rt-r-mb-5 {
    --margin-bottom: var(--space-5);
  }

  .xl\:rt-r-mb-6 {
    --margin-bottom: var(--space-6);
  }

  .xl\:rt-r-mb-7 {
    --margin-bottom: var(--space-7);
  }

  .xl\:rt-r-mb-8 {
    --margin-bottom: var(--space-8);
  }

  .xl\:rt-r-mb-9 {
    --margin-bottom: var(--space-9);
  }

  .xl\:-rt-r-mb-1 {
    --margin-bottom: calc(-1 * var(--space-1));
  }

  .xl\:-rt-r-mb-2 {
    --margin-bottom: calc(-1 * var(--space-2));
  }

  .xl\:-rt-r-mb-3 {
    --margin-bottom: calc(-1 * var(--space-3));
  }

  .xl\:-rt-r-mb-4 {
    --margin-bottom: calc(-1 * var(--space-4));
  }

  .xl\:-rt-r-mb-5 {
    --margin-bottom: calc(-1 * var(--space-5));
  }

  .xl\:-rt-r-mb-6 {
    --margin-bottom: calc(-1 * var(--space-6));
  }

  .xl\:-rt-r-mb-7 {
    --margin-bottom: calc(-1 * var(--space-7));
  }

  .xl\:-rt-r-mb-8 {
    --margin-bottom: calc(-1 * var(--space-8));
  }

  .xl\:-rt-r-mb-9 {
    --margin-bottom: calc(-1 * var(--space-9));
  }
}

.rt-r-ml, .rt-r-ml-0, .rt-r-ml-1, .rt-r-ml-2, .rt-r-ml-3, .rt-r-ml-4, .rt-r-ml-5, .rt-r-ml-6, .rt-r-ml-7, .rt-r-ml-8, .rt-r-ml-9, .-rt-r-ml-1, .-rt-r-ml-2, .-rt-r-ml-3, .-rt-r-ml-4, .-rt-r-ml-5, .-rt-r-ml-6, .-rt-r-ml-7, .-rt-r-ml-8, .-rt-r-ml-9 {
  margin-left: var(--margin-left-override, var(--margin-left));
}

.rt-r-ml {
  --margin-left: var(--ml);
}

.rt-r-ml-0 {
  --margin-left: 0px;
}

.rt-r-ml-1 {
  --margin-left: var(--space-1);
}

.rt-r-ml-2 {
  --margin-left: var(--space-2);
}

.rt-r-ml-3 {
  --margin-left: var(--space-3);
}

.rt-r-ml-4 {
  --margin-left: var(--space-4);
}

.rt-r-ml-5 {
  --margin-left: var(--space-5);
}

.rt-r-ml-6 {
  --margin-left: var(--space-6);
}

.rt-r-ml-7 {
  --margin-left: var(--space-7);
}

.rt-r-ml-8 {
  --margin-left: var(--space-8);
}

.rt-r-ml-9 {
  --margin-left: var(--space-9);
}

.-rt-r-ml-1 {
  --margin-left: calc(-1 * var(--space-1));
}

.-rt-r-ml-2 {
  --margin-left: calc(-1 * var(--space-2));
}

.-rt-r-ml-3 {
  --margin-left: calc(-1 * var(--space-3));
}

.-rt-r-ml-4 {
  --margin-left: calc(-1 * var(--space-4));
}

.-rt-r-ml-5 {
  --margin-left: calc(-1 * var(--space-5));
}

.-rt-r-ml-6 {
  --margin-left: calc(-1 * var(--space-6));
}

.-rt-r-ml-7 {
  --margin-left: calc(-1 * var(--space-7));
}

.-rt-r-ml-8 {
  --margin-left: calc(-1 * var(--space-8));
}

.-rt-r-ml-9 {
  --margin-left: calc(-1 * var(--space-9));
}

@media (min-width: 520px) {
  .xs\:rt-r-ml, .xs\:rt-r-ml-0, .xs\:rt-r-ml-1, .xs\:rt-r-ml-2, .xs\:rt-r-ml-3, .xs\:rt-r-ml-4, .xs\:rt-r-ml-5, .xs\:rt-r-ml-6, .xs\:rt-r-ml-7, .xs\:rt-r-ml-8, .xs\:rt-r-ml-9, .xs\:-rt-r-ml-1, .xs\:-rt-r-ml-2, .xs\:-rt-r-ml-3, .xs\:-rt-r-ml-4, .xs\:-rt-r-ml-5, .xs\:-rt-r-ml-6, .xs\:-rt-r-ml-7, .xs\:-rt-r-ml-8, .xs\:-rt-r-ml-9 {
    margin-left: var(--margin-left-override, var(--margin-left));
  }

  .xs\:rt-r-ml {
    --margin-left: var(--ml-xs);
  }

  .xs\:rt-r-ml-0 {
    --margin-left: 0px;
  }

  .xs\:rt-r-ml-1 {
    --margin-left: var(--space-1);
  }

  .xs\:rt-r-ml-2 {
    --margin-left: var(--space-2);
  }

  .xs\:rt-r-ml-3 {
    --margin-left: var(--space-3);
  }

  .xs\:rt-r-ml-4 {
    --margin-left: var(--space-4);
  }

  .xs\:rt-r-ml-5 {
    --margin-left: var(--space-5);
  }

  .xs\:rt-r-ml-6 {
    --margin-left: var(--space-6);
  }

  .xs\:rt-r-ml-7 {
    --margin-left: var(--space-7);
  }

  .xs\:rt-r-ml-8 {
    --margin-left: var(--space-8);
  }

  .xs\:rt-r-ml-9 {
    --margin-left: var(--space-9);
  }

  .xs\:-rt-r-ml-1 {
    --margin-left: calc(-1 * var(--space-1));
  }

  .xs\:-rt-r-ml-2 {
    --margin-left: calc(-1 * var(--space-2));
  }

  .xs\:-rt-r-ml-3 {
    --margin-left: calc(-1 * var(--space-3));
  }

  .xs\:-rt-r-ml-4 {
    --margin-left: calc(-1 * var(--space-4));
  }

  .xs\:-rt-r-ml-5 {
    --margin-left: calc(-1 * var(--space-5));
  }

  .xs\:-rt-r-ml-6 {
    --margin-left: calc(-1 * var(--space-6));
  }

  .xs\:-rt-r-ml-7 {
    --margin-left: calc(-1 * var(--space-7));
  }

  .xs\:-rt-r-ml-8 {
    --margin-left: calc(-1 * var(--space-8));
  }

  .xs\:-rt-r-ml-9 {
    --margin-left: calc(-1 * var(--space-9));
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-ml, .sm\:rt-r-ml-0, .sm\:rt-r-ml-1, .sm\:rt-r-ml-2, .sm\:rt-r-ml-3, .sm\:rt-r-ml-4, .sm\:rt-r-ml-5, .sm\:rt-r-ml-6, .sm\:rt-r-ml-7, .sm\:rt-r-ml-8, .sm\:rt-r-ml-9, .sm\:-rt-r-ml-1, .sm\:-rt-r-ml-2, .sm\:-rt-r-ml-3, .sm\:-rt-r-ml-4, .sm\:-rt-r-ml-5, .sm\:-rt-r-ml-6, .sm\:-rt-r-ml-7, .sm\:-rt-r-ml-8, .sm\:-rt-r-ml-9 {
    margin-left: var(--margin-left-override, var(--margin-left));
  }

  .sm\:rt-r-ml {
    --margin-left: var(--ml-sm);
  }

  .sm\:rt-r-ml-0 {
    --margin-left: 0px;
  }

  .sm\:rt-r-ml-1 {
    --margin-left: var(--space-1);
  }

  .sm\:rt-r-ml-2 {
    --margin-left: var(--space-2);
  }

  .sm\:rt-r-ml-3 {
    --margin-left: var(--space-3);
  }

  .sm\:rt-r-ml-4 {
    --margin-left: var(--space-4);
  }

  .sm\:rt-r-ml-5 {
    --margin-left: var(--space-5);
  }

  .sm\:rt-r-ml-6 {
    --margin-left: var(--space-6);
  }

  .sm\:rt-r-ml-7 {
    --margin-left: var(--space-7);
  }

  .sm\:rt-r-ml-8 {
    --margin-left: var(--space-8);
  }

  .sm\:rt-r-ml-9 {
    --margin-left: var(--space-9);
  }

  .sm\:-rt-r-ml-1 {
    --margin-left: calc(-1 * var(--space-1));
  }

  .sm\:-rt-r-ml-2 {
    --margin-left: calc(-1 * var(--space-2));
  }

  .sm\:-rt-r-ml-3 {
    --margin-left: calc(-1 * var(--space-3));
  }

  .sm\:-rt-r-ml-4 {
    --margin-left: calc(-1 * var(--space-4));
  }

  .sm\:-rt-r-ml-5 {
    --margin-left: calc(-1 * var(--space-5));
  }

  .sm\:-rt-r-ml-6 {
    --margin-left: calc(-1 * var(--space-6));
  }

  .sm\:-rt-r-ml-7 {
    --margin-left: calc(-1 * var(--space-7));
  }

  .sm\:-rt-r-ml-8 {
    --margin-left: calc(-1 * var(--space-8));
  }

  .sm\:-rt-r-ml-9 {
    --margin-left: calc(-1 * var(--space-9));
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-ml, .md\:rt-r-ml-0, .md\:rt-r-ml-1, .md\:rt-r-ml-2, .md\:rt-r-ml-3, .md\:rt-r-ml-4, .md\:rt-r-ml-5, .md\:rt-r-ml-6, .md\:rt-r-ml-7, .md\:rt-r-ml-8, .md\:rt-r-ml-9, .md\:-rt-r-ml-1, .md\:-rt-r-ml-2, .md\:-rt-r-ml-3, .md\:-rt-r-ml-4, .md\:-rt-r-ml-5, .md\:-rt-r-ml-6, .md\:-rt-r-ml-7, .md\:-rt-r-ml-8, .md\:-rt-r-ml-9 {
    margin-left: var(--margin-left-override, var(--margin-left));
  }

  .md\:rt-r-ml {
    --margin-left: var(--ml-md);
  }

  .md\:rt-r-ml-0 {
    --margin-left: 0px;
  }

  .md\:rt-r-ml-1 {
    --margin-left: var(--space-1);
  }

  .md\:rt-r-ml-2 {
    --margin-left: var(--space-2);
  }

  .md\:rt-r-ml-3 {
    --margin-left: var(--space-3);
  }

  .md\:rt-r-ml-4 {
    --margin-left: var(--space-4);
  }

  .md\:rt-r-ml-5 {
    --margin-left: var(--space-5);
  }

  .md\:rt-r-ml-6 {
    --margin-left: var(--space-6);
  }

  .md\:rt-r-ml-7 {
    --margin-left: var(--space-7);
  }

  .md\:rt-r-ml-8 {
    --margin-left: var(--space-8);
  }

  .md\:rt-r-ml-9 {
    --margin-left: var(--space-9);
  }

  .md\:-rt-r-ml-1 {
    --margin-left: calc(-1 * var(--space-1));
  }

  .md\:-rt-r-ml-2 {
    --margin-left: calc(-1 * var(--space-2));
  }

  .md\:-rt-r-ml-3 {
    --margin-left: calc(-1 * var(--space-3));
  }

  .md\:-rt-r-ml-4 {
    --margin-left: calc(-1 * var(--space-4));
  }

  .md\:-rt-r-ml-5 {
    --margin-left: calc(-1 * var(--space-5));
  }

  .md\:-rt-r-ml-6 {
    --margin-left: calc(-1 * var(--space-6));
  }

  .md\:-rt-r-ml-7 {
    --margin-left: calc(-1 * var(--space-7));
  }

  .md\:-rt-r-ml-8 {
    --margin-left: calc(-1 * var(--space-8));
  }

  .md\:-rt-r-ml-9 {
    --margin-left: calc(-1 * var(--space-9));
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-ml, .lg\:rt-r-ml-0, .lg\:rt-r-ml-1, .lg\:rt-r-ml-2, .lg\:rt-r-ml-3, .lg\:rt-r-ml-4, .lg\:rt-r-ml-5, .lg\:rt-r-ml-6, .lg\:rt-r-ml-7, .lg\:rt-r-ml-8, .lg\:rt-r-ml-9, .lg\:-rt-r-ml-1, .lg\:-rt-r-ml-2, .lg\:-rt-r-ml-3, .lg\:-rt-r-ml-4, .lg\:-rt-r-ml-5, .lg\:-rt-r-ml-6, .lg\:-rt-r-ml-7, .lg\:-rt-r-ml-8, .lg\:-rt-r-ml-9 {
    margin-left: var(--margin-left-override, var(--margin-left));
  }

  .lg\:rt-r-ml {
    --margin-left: var(--ml-lg);
  }

  .lg\:rt-r-ml-0 {
    --margin-left: 0px;
  }

  .lg\:rt-r-ml-1 {
    --margin-left: var(--space-1);
  }

  .lg\:rt-r-ml-2 {
    --margin-left: var(--space-2);
  }

  .lg\:rt-r-ml-3 {
    --margin-left: var(--space-3);
  }

  .lg\:rt-r-ml-4 {
    --margin-left: var(--space-4);
  }

  .lg\:rt-r-ml-5 {
    --margin-left: var(--space-5);
  }

  .lg\:rt-r-ml-6 {
    --margin-left: var(--space-6);
  }

  .lg\:rt-r-ml-7 {
    --margin-left: var(--space-7);
  }

  .lg\:rt-r-ml-8 {
    --margin-left: var(--space-8);
  }

  .lg\:rt-r-ml-9 {
    --margin-left: var(--space-9);
  }

  .lg\:-rt-r-ml-1 {
    --margin-left: calc(-1 * var(--space-1));
  }

  .lg\:-rt-r-ml-2 {
    --margin-left: calc(-1 * var(--space-2));
  }

  .lg\:-rt-r-ml-3 {
    --margin-left: calc(-1 * var(--space-3));
  }

  .lg\:-rt-r-ml-4 {
    --margin-left: calc(-1 * var(--space-4));
  }

  .lg\:-rt-r-ml-5 {
    --margin-left: calc(-1 * var(--space-5));
  }

  .lg\:-rt-r-ml-6 {
    --margin-left: calc(-1 * var(--space-6));
  }

  .lg\:-rt-r-ml-7 {
    --margin-left: calc(-1 * var(--space-7));
  }

  .lg\:-rt-r-ml-8 {
    --margin-left: calc(-1 * var(--space-8));
  }

  .lg\:-rt-r-ml-9 {
    --margin-left: calc(-1 * var(--space-9));
  }
}

@media (min-width: 1640px) {
  .xl\:rt-r-ml, .xl\:rt-r-ml-0, .xl\:rt-r-ml-1, .xl\:rt-r-ml-2, .xl\:rt-r-ml-3, .xl\:rt-r-ml-4, .xl\:rt-r-ml-5, .xl\:rt-r-ml-6, .xl\:rt-r-ml-7, .xl\:rt-r-ml-8, .xl\:rt-r-ml-9, .xl\:-rt-r-ml-1, .xl\:-rt-r-ml-2, .xl\:-rt-r-ml-3, .xl\:-rt-r-ml-4, .xl\:-rt-r-ml-5, .xl\:-rt-r-ml-6, .xl\:-rt-r-ml-7, .xl\:-rt-r-ml-8, .xl\:-rt-r-ml-9 {
    margin-left: var(--margin-left-override, var(--margin-left));
  }

  .xl\:rt-r-ml {
    --margin-left: var(--ml-xl);
  }

  .xl\:rt-r-ml-0 {
    --margin-left: 0px;
  }

  .xl\:rt-r-ml-1 {
    --margin-left: var(--space-1);
  }

  .xl\:rt-r-ml-2 {
    --margin-left: var(--space-2);
  }

  .xl\:rt-r-ml-3 {
    --margin-left: var(--space-3);
  }

  .xl\:rt-r-ml-4 {
    --margin-left: var(--space-4);
  }

  .xl\:rt-r-ml-5 {
    --margin-left: var(--space-5);
  }

  .xl\:rt-r-ml-6 {
    --margin-left: var(--space-6);
  }

  .xl\:rt-r-ml-7 {
    --margin-left: var(--space-7);
  }

  .xl\:rt-r-ml-8 {
    --margin-left: var(--space-8);
  }

  .xl\:rt-r-ml-9 {
    --margin-left: var(--space-9);
  }

  .xl\:-rt-r-ml-1 {
    --margin-left: calc(-1 * var(--space-1));
  }

  .xl\:-rt-r-ml-2 {
    --margin-left: calc(-1 * var(--space-2));
  }

  .xl\:-rt-r-ml-3 {
    --margin-left: calc(-1 * var(--space-3));
  }

  .xl\:-rt-r-ml-4 {
    --margin-left: calc(-1 * var(--space-4));
  }

  .xl\:-rt-r-ml-5 {
    --margin-left: calc(-1 * var(--space-5));
  }

  .xl\:-rt-r-ml-6 {
    --margin-left: calc(-1 * var(--space-6));
  }

  .xl\:-rt-r-ml-7 {
    --margin-left: calc(-1 * var(--space-7));
  }

  .xl\:-rt-r-ml-8 {
    --margin-left: calc(-1 * var(--space-8));
  }

  .xl\:-rt-r-ml-9 {
    --margin-left: calc(-1 * var(--space-9));
  }
}

.rt-r-overflow-visible {
  overflow: visible;
}

.rt-r-overflow-hidden {
  overflow: hidden;
}

.rt-r-overflow-clip {
  overflow: clip;
}

.rt-r-overflow-scroll {
  overflow: scroll;
}

.rt-r-overflow-auto {
  overflow: auto;
}

.rt-r-ox-visible {
  overflow-x: visible;
}

.rt-r-ox-hidden {
  overflow-x: hidden;
}

.rt-r-ox-clip {
  overflow-x: clip;
}

.rt-r-ox-scroll {
  overflow-x: scroll;
}

.rt-r-ox-auto {
  overflow-x: auto;
}

.rt-r-oy-visible {
  overflow-y: visible;
}

.rt-r-oy-hidden {
  overflow-y: hidden;
}

.rt-r-oy-clip {
  overflow-y: clip;
}

.rt-r-oy-scroll {
  overflow-y: scroll;
}

.rt-r-oy-auto {
  overflow-y: auto;
}

@media (min-width: 520px) {
  .xs\:rt-r-overflow-visible {
    overflow: visible;
  }

  .xs\:rt-r-overflow-hidden {
    overflow: hidden;
  }

  .xs\:rt-r-overflow-clip {
    overflow: clip;
  }

  .xs\:rt-r-overflow-scroll {
    overflow: scroll;
  }

  .xs\:rt-r-overflow-auto {
    overflow: auto;
  }

  .xs\:rt-r-ox-visible {
    overflow-x: visible;
  }

  .xs\:rt-r-ox-hidden {
    overflow-x: hidden;
  }

  .xs\:rt-r-ox-clip {
    overflow-x: clip;
  }

  .xs\:rt-r-ox-scroll {
    overflow-x: scroll;
  }

  .xs\:rt-r-ox-auto {
    overflow-x: auto;
  }

  .xs\:rt-r-oy-visible {
    overflow-y: visible;
  }

  .xs\:rt-r-oy-hidden {
    overflow-y: hidden;
  }

  .xs\:rt-r-oy-clip {
    overflow-y: clip;
  }

  .xs\:rt-r-oy-scroll {
    overflow-y: scroll;
  }

  .xs\:rt-r-oy-auto {
    overflow-y: auto;
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-overflow-visible {
    overflow: visible;
  }

  .sm\:rt-r-overflow-hidden {
    overflow: hidden;
  }

  .sm\:rt-r-overflow-clip {
    overflow: clip;
  }

  .sm\:rt-r-overflow-scroll {
    overflow: scroll;
  }

  .sm\:rt-r-overflow-auto {
    overflow: auto;
  }

  .sm\:rt-r-ox-visible {
    overflow-x: visible;
  }

  .sm\:rt-r-ox-hidden {
    overflow-x: hidden;
  }

  .sm\:rt-r-ox-clip {
    overflow-x: clip;
  }

  .sm\:rt-r-ox-scroll {
    overflow-x: scroll;
  }

  .sm\:rt-r-ox-auto {
    overflow-x: auto;
  }

  .sm\:rt-r-oy-visible {
    overflow-y: visible;
  }

  .sm\:rt-r-oy-hidden {
    overflow-y: hidden;
  }

  .sm\:rt-r-oy-clip {
    overflow-y: clip;
  }

  .sm\:rt-r-oy-scroll {
    overflow-y: scroll;
  }

  .sm\:rt-r-oy-auto {
    overflow-y: auto;
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-overflow-visible {
    overflow: visible;
  }

  .md\:rt-r-overflow-hidden {
    overflow: hidden;
  }

  .md\:rt-r-overflow-clip {
    overflow: clip;
  }

  .md\:rt-r-overflow-scroll {
    overflow: scroll;
  }

  .md\:rt-r-overflow-auto {
    overflow: auto;
  }

  .md\:rt-r-ox-visible {
    overflow-x: visible;
  }

  .md\:rt-r-ox-hidden {
    overflow-x: hidden;
  }

  .md\:rt-r-ox-clip {
    overflow-x: clip;
  }

  .md\:rt-r-ox-scroll {
    overflow-x: scroll;
  }

  .md\:rt-r-ox-auto {
    overflow-x: auto;
  }

  .md\:rt-r-oy-visible {
    overflow-y: visible;
  }

  .md\:rt-r-oy-hidden {
    overflow-y: hidden;
  }

  .md\:rt-r-oy-clip {
    overflow-y: clip;
  }

  .md\:rt-r-oy-scroll {
    overflow-y: scroll;
  }

  .md\:rt-r-oy-auto {
    overflow-y: auto;
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-overflow-visible {
    overflow: visible;
  }

  .lg\:rt-r-overflow-hidden {
    overflow: hidden;
  }

  .lg\:rt-r-overflow-clip {
    overflow: clip;
  }

  .lg\:rt-r-overflow-scroll {
    overflow: scroll;
  }

  .lg\:rt-r-overflow-auto {
    overflow: auto;
  }

  .lg\:rt-r-ox-visible {
    overflow-x: visible;
  }

  .lg\:rt-r-ox-hidden {
    overflow-x: hidden;
  }

  .lg\:rt-r-ox-clip {
    overflow-x: clip;
  }

  .lg\:rt-r-ox-scroll {
    overflow-x: scroll;
  }

  .lg\:rt-r-ox-auto {
    overflow-x: auto;
  }

  .lg\:rt-r-oy-visible {
    overflow-y: visible;
  }

  .lg\:rt-r-oy-hidden {
    overflow-y: hidden;
  }

  .lg\:rt-r-oy-clip {
    overflow-y: clip;
  }

  .lg\:rt-r-oy-scroll {
    overflow-y: scroll;
  }

  .lg\:rt-r-oy-auto {
    overflow-y: auto;
  }
}

@media (min-width: 1640px) {
  .xl\:rt-r-overflow-visible {
    overflow: visible;
  }

  .xl\:rt-r-overflow-hidden {
    overflow: hidden;
  }

  .xl\:rt-r-overflow-clip {
    overflow: clip;
  }

  .xl\:rt-r-overflow-scroll {
    overflow: scroll;
  }

  .xl\:rt-r-overflow-auto {
    overflow: auto;
  }

  .xl\:rt-r-ox-visible {
    overflow-x: visible;
  }

  .xl\:rt-r-ox-hidden {
    overflow-x: hidden;
  }

  .xl\:rt-r-ox-clip {
    overflow-x: clip;
  }

  .xl\:rt-r-ox-scroll {
    overflow-x: scroll;
  }

  .xl\:rt-r-ox-auto {
    overflow-x: auto;
  }

  .xl\:rt-r-oy-visible {
    overflow-y: visible;
  }

  .xl\:rt-r-oy-hidden {
    overflow-y: hidden;
  }

  .xl\:rt-r-oy-clip {
    overflow-y: clip;
  }

  .xl\:rt-r-oy-scroll {
    overflow-y: scroll;
  }

  .xl\:rt-r-oy-auto {
    overflow-y: auto;
  }
}

.rt-r-p {
  padding: var(--p);
}

.rt-r-p-0 {
  padding: 0;
}

.rt-r-p-1 {
  padding: var(--space-1);
}

.rt-r-p-2 {
  padding: var(--space-2);
}

.rt-r-p-3 {
  padding: var(--space-3);
}

.rt-r-p-4 {
  padding: var(--space-4);
}

.rt-r-p-5 {
  padding: var(--space-5);
}

.rt-r-p-6 {
  padding: var(--space-6);
}

.rt-r-p-7 {
  padding: var(--space-7);
}

.rt-r-p-8 {
  padding: var(--space-8);
}

.rt-r-p-9 {
  padding: var(--space-9);
}

.rt-r-p-inset {
  padding-top: var(--inset-padding-top);
  padding-right: var(--inset-padding-right);
  padding-bottom: var(--inset-padding-bottom);
  padding-left: var(--inset-padding-left);
}

@media (min-width: 520px) {
  .xs\:rt-r-p {
    padding: var(--p-xs);
  }

  .xs\:rt-r-p-0 {
    padding: 0;
  }

  .xs\:rt-r-p-1 {
    padding: var(--space-1);
  }

  .xs\:rt-r-p-2 {
    padding: var(--space-2);
  }

  .xs\:rt-r-p-3 {
    padding: var(--space-3);
  }

  .xs\:rt-r-p-4 {
    padding: var(--space-4);
  }

  .xs\:rt-r-p-5 {
    padding: var(--space-5);
  }

  .xs\:rt-r-p-6 {
    padding: var(--space-6);
  }

  .xs\:rt-r-p-7 {
    padding: var(--space-7);
  }

  .xs\:rt-r-p-8 {
    padding: var(--space-8);
  }

  .xs\:rt-r-p-9 {
    padding: var(--space-9);
  }

  .xs\:rt-r-p-inset {
    padding-top: var(--inset-padding-top);
    padding-right: var(--inset-padding-right);
    padding-bottom: var(--inset-padding-bottom);
    padding-left: var(--inset-padding-left);
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-p {
    padding: var(--p-sm);
  }

  .sm\:rt-r-p-0 {
    padding: 0;
  }

  .sm\:rt-r-p-1 {
    padding: var(--space-1);
  }

  .sm\:rt-r-p-2 {
    padding: var(--space-2);
  }

  .sm\:rt-r-p-3 {
    padding: var(--space-3);
  }

  .sm\:rt-r-p-4 {
    padding: var(--space-4);
  }

  .sm\:rt-r-p-5 {
    padding: var(--space-5);
  }

  .sm\:rt-r-p-6 {
    padding: var(--space-6);
  }

  .sm\:rt-r-p-7 {
    padding: var(--space-7);
  }

  .sm\:rt-r-p-8 {
    padding: var(--space-8);
  }

  .sm\:rt-r-p-9 {
    padding: var(--space-9);
  }

  .sm\:rt-r-p-inset {
    padding-top: var(--inset-padding-top);
    padding-right: var(--inset-padding-right);
    padding-bottom: var(--inset-padding-bottom);
    padding-left: var(--inset-padding-left);
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-p {
    padding: var(--p-md);
  }

  .md\:rt-r-p-0 {
    padding: 0;
  }

  .md\:rt-r-p-1 {
    padding: var(--space-1);
  }

  .md\:rt-r-p-2 {
    padding: var(--space-2);
  }

  .md\:rt-r-p-3 {
    padding: var(--space-3);
  }

  .md\:rt-r-p-4 {
    padding: var(--space-4);
  }

  .md\:rt-r-p-5 {
    padding: var(--space-5);
  }

  .md\:rt-r-p-6 {
    padding: var(--space-6);
  }

  .md\:rt-r-p-7 {
    padding: var(--space-7);
  }

  .md\:rt-r-p-8 {
    padding: var(--space-8);
  }

  .md\:rt-r-p-9 {
    padding: var(--space-9);
  }

  .md\:rt-r-p-inset {
    padding-top: var(--inset-padding-top);
    padding-right: var(--inset-padding-right);
    padding-bottom: var(--inset-padding-bottom);
    padding-left: var(--inset-padding-left);
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-p {
    padding: var(--p-lg);
  }

  .lg\:rt-r-p-0 {
    padding: 0;
  }

  .lg\:rt-r-p-1 {
    padding: var(--space-1);
  }

  .lg\:rt-r-p-2 {
    padding: var(--space-2);
  }

  .lg\:rt-r-p-3 {
    padding: var(--space-3);
  }

  .lg\:rt-r-p-4 {
    padding: var(--space-4);
  }

  .lg\:rt-r-p-5 {
    padding: var(--space-5);
  }

  .lg\:rt-r-p-6 {
    padding: var(--space-6);
  }

  .lg\:rt-r-p-7 {
    padding: var(--space-7);
  }

  .lg\:rt-r-p-8 {
    padding: var(--space-8);
  }

  .lg\:rt-r-p-9 {
    padding: var(--space-9);
  }

  .lg\:rt-r-p-inset {
    padding-top: var(--inset-padding-top);
    padding-right: var(--inset-padding-right);
    padding-bottom: var(--inset-padding-bottom);
    padding-left: var(--inset-padding-left);
  }
}

@media (min-width: 1640px) {
  .xl\:rt-r-p {
    padding: var(--p-xl);
  }

  .xl\:rt-r-p-0 {
    padding: 0;
  }

  .xl\:rt-r-p-1 {
    padding: var(--space-1);
  }

  .xl\:rt-r-p-2 {
    padding: var(--space-2);
  }

  .xl\:rt-r-p-3 {
    padding: var(--space-3);
  }

  .xl\:rt-r-p-4 {
    padding: var(--space-4);
  }

  .xl\:rt-r-p-5 {
    padding: var(--space-5);
  }

  .xl\:rt-r-p-6 {
    padding: var(--space-6);
  }

  .xl\:rt-r-p-7 {
    padding: var(--space-7);
  }

  .xl\:rt-r-p-8 {
    padding: var(--space-8);
  }

  .xl\:rt-r-p-9 {
    padding: var(--space-9);
  }

  .xl\:rt-r-p-inset {
    padding-top: var(--inset-padding-top);
    padding-right: var(--inset-padding-right);
    padding-bottom: var(--inset-padding-bottom);
    padding-left: var(--inset-padding-left);
  }
}

.rt-r-px {
  padding-left: var(--pl);
  padding-right: var(--pr);
}

.rt-r-px-0 {
  padding-left: 0;
  padding-right: 0;
}

.rt-r-px-1 {
  padding-left: var(--space-1);
  padding-right: var(--space-1);
}

.rt-r-px-2 {
  padding-left: var(--space-2);
  padding-right: var(--space-2);
}

.rt-r-px-3 {
  padding-left: var(--space-3);
  padding-right: var(--space-3);
}

.rt-r-px-4 {
  padding-left: var(--space-4);
  padding-right: var(--space-4);
}

.rt-r-px-5 {
  padding-left: var(--space-5);
  padding-right: var(--space-5);
}

.rt-r-px-6 {
  padding-left: var(--space-6);
  padding-right: var(--space-6);
}

.rt-r-px-7 {
  padding-left: var(--space-7);
  padding-right: var(--space-7);
}

.rt-r-px-8 {
  padding-left: var(--space-8);
  padding-right: var(--space-8);
}

.rt-r-px-9 {
  padding-left: var(--space-9);
  padding-right: var(--space-9);
}

.rt-r-px-inset {
  padding-left: var(--inset-padding-left);
  padding-right: var(--inset-padding-right);
}

@media (min-width: 520px) {
  .xs\:rt-r-px {
    padding-left: var(--pl-xs);
    padding-right: var(--pr-xs);
  }

  .xs\:rt-r-px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .xs\:rt-r-px-1 {
    padding-left: var(--space-1);
    padding-right: var(--space-1);
  }

  .xs\:rt-r-px-2 {
    padding-left: var(--space-2);
    padding-right: var(--space-2);
  }

  .xs\:rt-r-px-3 {
    padding-left: var(--space-3);
    padding-right: var(--space-3);
  }

  .xs\:rt-r-px-4 {
    padding-left: var(--space-4);
    padding-right: var(--space-4);
  }

  .xs\:rt-r-px-5 {
    padding-left: var(--space-5);
    padding-right: var(--space-5);
  }

  .xs\:rt-r-px-6 {
    padding-left: var(--space-6);
    padding-right: var(--space-6);
  }

  .xs\:rt-r-px-7 {
    padding-left: var(--space-7);
    padding-right: var(--space-7);
  }

  .xs\:rt-r-px-8 {
    padding-left: var(--space-8);
    padding-right: var(--space-8);
  }

  .xs\:rt-r-px-9 {
    padding-left: var(--space-9);
    padding-right: var(--space-9);
  }

  .xs\:rt-r-px-inset {
    padding-left: var(--inset-padding-left);
    padding-right: var(--inset-padding-right);
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-px {
    padding-left: var(--pl-sm);
    padding-right: var(--pr-sm);
  }

  .sm\:rt-r-px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .sm\:rt-r-px-1 {
    padding-left: var(--space-1);
    padding-right: var(--space-1);
  }

  .sm\:rt-r-px-2 {
    padding-left: var(--space-2);
    padding-right: var(--space-2);
  }

  .sm\:rt-r-px-3 {
    padding-left: var(--space-3);
    padding-right: var(--space-3);
  }

  .sm\:rt-r-px-4 {
    padding-left: var(--space-4);
    padding-right: var(--space-4);
  }

  .sm\:rt-r-px-5 {
    padding-left: var(--space-5);
    padding-right: var(--space-5);
  }

  .sm\:rt-r-px-6 {
    padding-left: var(--space-6);
    padding-right: var(--space-6);
  }

  .sm\:rt-r-px-7 {
    padding-left: var(--space-7);
    padding-right: var(--space-7);
  }

  .sm\:rt-r-px-8 {
    padding-left: var(--space-8);
    padding-right: var(--space-8);
  }

  .sm\:rt-r-px-9 {
    padding-left: var(--space-9);
    padding-right: var(--space-9);
  }

  .sm\:rt-r-px-inset {
    padding-left: var(--inset-padding-left);
    padding-right: var(--inset-padding-right);
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-px {
    padding-left: var(--pl-md);
    padding-right: var(--pr-md);
  }

  .md\:rt-r-px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .md\:rt-r-px-1 {
    padding-left: var(--space-1);
    padding-right: var(--space-1);
  }

  .md\:rt-r-px-2 {
    padding-left: var(--space-2);
    padding-right: var(--space-2);
  }

  .md\:rt-r-px-3 {
    padding-left: var(--space-3);
    padding-right: var(--space-3);
  }

  .md\:rt-r-px-4 {
    padding-left: var(--space-4);
    padding-right: var(--space-4);
  }

  .md\:rt-r-px-5 {
    padding-left: var(--space-5);
    padding-right: var(--space-5);
  }

  .md\:rt-r-px-6 {
    padding-left: var(--space-6);
    padding-right: var(--space-6);
  }

  .md\:rt-r-px-7 {
    padding-left: var(--space-7);
    padding-right: var(--space-7);
  }

  .md\:rt-r-px-8 {
    padding-left: var(--space-8);
    padding-right: var(--space-8);
  }

  .md\:rt-r-px-9 {
    padding-left: var(--space-9);
    padding-right: var(--space-9);
  }

  .md\:rt-r-px-inset {
    padding-left: var(--inset-padding-left);
    padding-right: var(--inset-padding-right);
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-px {
    padding-left: var(--pl-lg);
    padding-right: var(--pr-lg);
  }

  .lg\:rt-r-px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .lg\:rt-r-px-1 {
    padding-left: var(--space-1);
    padding-right: var(--space-1);
  }

  .lg\:rt-r-px-2 {
    padding-left: var(--space-2);
    padding-right: var(--space-2);
  }

  .lg\:rt-r-px-3 {
    padding-left: var(--space-3);
    padding-right: var(--space-3);
  }

  .lg\:rt-r-px-4 {
    padding-left: var(--space-4);
    padding-right: var(--space-4);
  }

  .lg\:rt-r-px-5 {
    padding-left: var(--space-5);
    padding-right: var(--space-5);
  }

  .lg\:rt-r-px-6 {
    padding-left: var(--space-6);
    padding-right: var(--space-6);
  }

  .lg\:rt-r-px-7 {
    padding-left: var(--space-7);
    padding-right: var(--space-7);
  }

  .lg\:rt-r-px-8 {
    padding-left: var(--space-8);
    padding-right: var(--space-8);
  }

  .lg\:rt-r-px-9 {
    padding-left: var(--space-9);
    padding-right: var(--space-9);
  }

  .lg\:rt-r-px-inset {
    padding-left: var(--inset-padding-left);
    padding-right: var(--inset-padding-right);
  }
}

@media (min-width: 1640px) {
  .xl\:rt-r-px {
    padding-left: var(--pl-xl);
    padding-right: var(--pr-xl);
  }

  .xl\:rt-r-px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .xl\:rt-r-px-1 {
    padding-left: var(--space-1);
    padding-right: var(--space-1);
  }

  .xl\:rt-r-px-2 {
    padding-left: var(--space-2);
    padding-right: var(--space-2);
  }

  .xl\:rt-r-px-3 {
    padding-left: var(--space-3);
    padding-right: var(--space-3);
  }

  .xl\:rt-r-px-4 {
    padding-left: var(--space-4);
    padding-right: var(--space-4);
  }

  .xl\:rt-r-px-5 {
    padding-left: var(--space-5);
    padding-right: var(--space-5);
  }

  .xl\:rt-r-px-6 {
    padding-left: var(--space-6);
    padding-right: var(--space-6);
  }

  .xl\:rt-r-px-7 {
    padding-left: var(--space-7);
    padding-right: var(--space-7);
  }

  .xl\:rt-r-px-8 {
    padding-left: var(--space-8);
    padding-right: var(--space-8);
  }

  .xl\:rt-r-px-9 {
    padding-left: var(--space-9);
    padding-right: var(--space-9);
  }

  .xl\:rt-r-px-inset {
    padding-left: var(--inset-padding-left);
    padding-right: var(--inset-padding-right);
  }
}

.rt-r-py {
  padding-top: var(--pt);
  padding-bottom: var(--pb);
}

.rt-r-py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.rt-r-py-1 {
  padding-top: var(--space-1);
  padding-bottom: var(--space-1);
}

.rt-r-py-2 {
  padding-top: var(--space-2);
  padding-bottom: var(--space-2);
}

.rt-r-py-3 {
  padding-top: var(--space-3);
  padding-bottom: var(--space-3);
}

.rt-r-py-4 {
  padding-top: var(--space-4);
  padding-bottom: var(--space-4);
}

.rt-r-py-5 {
  padding-top: var(--space-5);
  padding-bottom: var(--space-5);
}

.rt-r-py-6 {
  padding-top: var(--space-6);
  padding-bottom: var(--space-6);
}

.rt-r-py-7 {
  padding-top: var(--space-7);
  padding-bottom: var(--space-7);
}

.rt-r-py-8 {
  padding-top: var(--space-8);
  padding-bottom: var(--space-8);
}

.rt-r-py-9 {
  padding-top: var(--space-9);
  padding-bottom: var(--space-9);
}

.rt-r-py-inset {
  padding-top: var(--inset-padding-top);
  padding-bottom: var(--inset-padding-bottom);
}

@media (min-width: 520px) {
  .xs\:rt-r-py {
    padding-top: var(--pt-xs);
    padding-bottom: var(--pb-xs);
  }

  .xs\:rt-r-py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .xs\:rt-r-py-1 {
    padding-top: var(--space-1);
    padding-bottom: var(--space-1);
  }

  .xs\:rt-r-py-2 {
    padding-top: var(--space-2);
    padding-bottom: var(--space-2);
  }

  .xs\:rt-r-py-3 {
    padding-top: var(--space-3);
    padding-bottom: var(--space-3);
  }

  .xs\:rt-r-py-4 {
    padding-top: var(--space-4);
    padding-bottom: var(--space-4);
  }

  .xs\:rt-r-py-5 {
    padding-top: var(--space-5);
    padding-bottom: var(--space-5);
  }

  .xs\:rt-r-py-6 {
    padding-top: var(--space-6);
    padding-bottom: var(--space-6);
  }

  .xs\:rt-r-py-7 {
    padding-top: var(--space-7);
    padding-bottom: var(--space-7);
  }

  .xs\:rt-r-py-8 {
    padding-top: var(--space-8);
    padding-bottom: var(--space-8);
  }

  .xs\:rt-r-py-9 {
    padding-top: var(--space-9);
    padding-bottom: var(--space-9);
  }

  .xs\:rt-r-py-inset {
    padding-top: var(--inset-padding-top);
    padding-bottom: var(--inset-padding-bottom);
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-py {
    padding-top: var(--pt-sm);
    padding-bottom: var(--pb-sm);
  }

  .sm\:rt-r-py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .sm\:rt-r-py-1 {
    padding-top: var(--space-1);
    padding-bottom: var(--space-1);
  }

  .sm\:rt-r-py-2 {
    padding-top: var(--space-2);
    padding-bottom: var(--space-2);
  }

  .sm\:rt-r-py-3 {
    padding-top: var(--space-3);
    padding-bottom: var(--space-3);
  }

  .sm\:rt-r-py-4 {
    padding-top: var(--space-4);
    padding-bottom: var(--space-4);
  }

  .sm\:rt-r-py-5 {
    padding-top: var(--space-5);
    padding-bottom: var(--space-5);
  }

  .sm\:rt-r-py-6 {
    padding-top: var(--space-6);
    padding-bottom: var(--space-6);
  }

  .sm\:rt-r-py-7 {
    padding-top: var(--space-7);
    padding-bottom: var(--space-7);
  }

  .sm\:rt-r-py-8 {
    padding-top: var(--space-8);
    padding-bottom: var(--space-8);
  }

  .sm\:rt-r-py-9 {
    padding-top: var(--space-9);
    padding-bottom: var(--space-9);
  }

  .sm\:rt-r-py-inset {
    padding-top: var(--inset-padding-top);
    padding-bottom: var(--inset-padding-bottom);
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-py {
    padding-top: var(--pt-md);
    padding-bottom: var(--pb-md);
  }

  .md\:rt-r-py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .md\:rt-r-py-1 {
    padding-top: var(--space-1);
    padding-bottom: var(--space-1);
  }

  .md\:rt-r-py-2 {
    padding-top: var(--space-2);
    padding-bottom: var(--space-2);
  }

  .md\:rt-r-py-3 {
    padding-top: var(--space-3);
    padding-bottom: var(--space-3);
  }

  .md\:rt-r-py-4 {
    padding-top: var(--space-4);
    padding-bottom: var(--space-4);
  }

  .md\:rt-r-py-5 {
    padding-top: var(--space-5);
    padding-bottom: var(--space-5);
  }

  .md\:rt-r-py-6 {
    padding-top: var(--space-6);
    padding-bottom: var(--space-6);
  }

  .md\:rt-r-py-7 {
    padding-top: var(--space-7);
    padding-bottom: var(--space-7);
  }

  .md\:rt-r-py-8 {
    padding-top: var(--space-8);
    padding-bottom: var(--space-8);
  }

  .md\:rt-r-py-9 {
    padding-top: var(--space-9);
    padding-bottom: var(--space-9);
  }

  .md\:rt-r-py-inset {
    padding-top: var(--inset-padding-top);
    padding-bottom: var(--inset-padding-bottom);
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-py {
    padding-top: var(--pt-lg);
    padding-bottom: var(--pb-lg);
  }

  .lg\:rt-r-py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .lg\:rt-r-py-1 {
    padding-top: var(--space-1);
    padding-bottom: var(--space-1);
  }

  .lg\:rt-r-py-2 {
    padding-top: var(--space-2);
    padding-bottom: var(--space-2);
  }

  .lg\:rt-r-py-3 {
    padding-top: var(--space-3);
    padding-bottom: var(--space-3);
  }

  .lg\:rt-r-py-4 {
    padding-top: var(--space-4);
    padding-bottom: var(--space-4);
  }

  .lg\:rt-r-py-5 {
    padding-top: var(--space-5);
    padding-bottom: var(--space-5);
  }

  .lg\:rt-r-py-6 {
    padding-top: var(--space-6);
    padding-bottom: var(--space-6);
  }

  .lg\:rt-r-py-7 {
    padding-top: var(--space-7);
    padding-bottom: var(--space-7);
  }

  .lg\:rt-r-py-8 {
    padding-top: var(--space-8);
    padding-bottom: var(--space-8);
  }

  .lg\:rt-r-py-9 {
    padding-top: var(--space-9);
    padding-bottom: var(--space-9);
  }

  .lg\:rt-r-py-inset {
    padding-top: var(--inset-padding-top);
    padding-bottom: var(--inset-padding-bottom);
  }
}

@media (min-width: 1640px) {
  .xl\:rt-r-py {
    padding-top: var(--pt-xl);
    padding-bottom: var(--pb-xl);
  }

  .xl\:rt-r-py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .xl\:rt-r-py-1 {
    padding-top: var(--space-1);
    padding-bottom: var(--space-1);
  }

  .xl\:rt-r-py-2 {
    padding-top: var(--space-2);
    padding-bottom: var(--space-2);
  }

  .xl\:rt-r-py-3 {
    padding-top: var(--space-3);
    padding-bottom: var(--space-3);
  }

  .xl\:rt-r-py-4 {
    padding-top: var(--space-4);
    padding-bottom: var(--space-4);
  }

  .xl\:rt-r-py-5 {
    padding-top: var(--space-5);
    padding-bottom: var(--space-5);
  }

  .xl\:rt-r-py-6 {
    padding-top: var(--space-6);
    padding-bottom: var(--space-6);
  }

  .xl\:rt-r-py-7 {
    padding-top: var(--space-7);
    padding-bottom: var(--space-7);
  }

  .xl\:rt-r-py-8 {
    padding-top: var(--space-8);
    padding-bottom: var(--space-8);
  }

  .xl\:rt-r-py-9 {
    padding-top: var(--space-9);
    padding-bottom: var(--space-9);
  }

  .xl\:rt-r-py-inset {
    padding-top: var(--inset-padding-top);
    padding-bottom: var(--inset-padding-bottom);
  }
}

.rt-r-pt {
  padding-top: var(--pt);
}

.rt-r-pt-0 {
  padding-top: 0;
}

.rt-r-pt-1 {
  padding-top: var(--space-1);
}

.rt-r-pt-2 {
  padding-top: var(--space-2);
}

.rt-r-pt-3 {
  padding-top: var(--space-3);
}

.rt-r-pt-4 {
  padding-top: var(--space-4);
}

.rt-r-pt-5 {
  padding-top: var(--space-5);
}

.rt-r-pt-6 {
  padding-top: var(--space-6);
}

.rt-r-pt-7 {
  padding-top: var(--space-7);
}

.rt-r-pt-8 {
  padding-top: var(--space-8);
}

.rt-r-pt-9 {
  padding-top: var(--space-9);
}

.rt-r-pt-inset {
  padding-top: var(--inset-padding-top);
}

@media (min-width: 520px) {
  .xs\:rt-r-pt {
    padding-top: var(--pt-xs);
  }

  .xs\:rt-r-pt-0 {
    padding-top: 0;
  }

  .xs\:rt-r-pt-1 {
    padding-top: var(--space-1);
  }

  .xs\:rt-r-pt-2 {
    padding-top: var(--space-2);
  }

  .xs\:rt-r-pt-3 {
    padding-top: var(--space-3);
  }

  .xs\:rt-r-pt-4 {
    padding-top: var(--space-4);
  }

  .xs\:rt-r-pt-5 {
    padding-top: var(--space-5);
  }

  .xs\:rt-r-pt-6 {
    padding-top: var(--space-6);
  }

  .xs\:rt-r-pt-7 {
    padding-top: var(--space-7);
  }

  .xs\:rt-r-pt-8 {
    padding-top: var(--space-8);
  }

  .xs\:rt-r-pt-9 {
    padding-top: var(--space-9);
  }

  .xs\:rt-r-pt-inset {
    padding-top: var(--inset-padding-top);
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-pt {
    padding-top: var(--pt-sm);
  }

  .sm\:rt-r-pt-0 {
    padding-top: 0;
  }

  .sm\:rt-r-pt-1 {
    padding-top: var(--space-1);
  }

  .sm\:rt-r-pt-2 {
    padding-top: var(--space-2);
  }

  .sm\:rt-r-pt-3 {
    padding-top: var(--space-3);
  }

  .sm\:rt-r-pt-4 {
    padding-top: var(--space-4);
  }

  .sm\:rt-r-pt-5 {
    padding-top: var(--space-5);
  }

  .sm\:rt-r-pt-6 {
    padding-top: var(--space-6);
  }

  .sm\:rt-r-pt-7 {
    padding-top: var(--space-7);
  }

  .sm\:rt-r-pt-8 {
    padding-top: var(--space-8);
  }

  .sm\:rt-r-pt-9 {
    padding-top: var(--space-9);
  }

  .sm\:rt-r-pt-inset {
    padding-top: var(--inset-padding-top);
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-pt {
    padding-top: var(--pt-md);
  }

  .md\:rt-r-pt-0 {
    padding-top: 0;
  }

  .md\:rt-r-pt-1 {
    padding-top: var(--space-1);
  }

  .md\:rt-r-pt-2 {
    padding-top: var(--space-2);
  }

  .md\:rt-r-pt-3 {
    padding-top: var(--space-3);
  }

  .md\:rt-r-pt-4 {
    padding-top: var(--space-4);
  }

  .md\:rt-r-pt-5 {
    padding-top: var(--space-5);
  }

  .md\:rt-r-pt-6 {
    padding-top: var(--space-6);
  }

  .md\:rt-r-pt-7 {
    padding-top: var(--space-7);
  }

  .md\:rt-r-pt-8 {
    padding-top: var(--space-8);
  }

  .md\:rt-r-pt-9 {
    padding-top: var(--space-9);
  }

  .md\:rt-r-pt-inset {
    padding-top: var(--inset-padding-top);
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-pt {
    padding-top: var(--pt-lg);
  }

  .lg\:rt-r-pt-0 {
    padding-top: 0;
  }

  .lg\:rt-r-pt-1 {
    padding-top: var(--space-1);
  }

  .lg\:rt-r-pt-2 {
    padding-top: var(--space-2);
  }

  .lg\:rt-r-pt-3 {
    padding-top: var(--space-3);
  }

  .lg\:rt-r-pt-4 {
    padding-top: var(--space-4);
  }

  .lg\:rt-r-pt-5 {
    padding-top: var(--space-5);
  }

  .lg\:rt-r-pt-6 {
    padding-top: var(--space-6);
  }

  .lg\:rt-r-pt-7 {
    padding-top: var(--space-7);
  }

  .lg\:rt-r-pt-8 {
    padding-top: var(--space-8);
  }

  .lg\:rt-r-pt-9 {
    padding-top: var(--space-9);
  }

  .lg\:rt-r-pt-inset {
    padding-top: var(--inset-padding-top);
  }
}

@media (min-width: 1640px) {
  .xl\:rt-r-pt {
    padding-top: var(--pt-xl);
  }

  .xl\:rt-r-pt-0 {
    padding-top: 0;
  }

  .xl\:rt-r-pt-1 {
    padding-top: var(--space-1);
  }

  .xl\:rt-r-pt-2 {
    padding-top: var(--space-2);
  }

  .xl\:rt-r-pt-3 {
    padding-top: var(--space-3);
  }

  .xl\:rt-r-pt-4 {
    padding-top: var(--space-4);
  }

  .xl\:rt-r-pt-5 {
    padding-top: var(--space-5);
  }

  .xl\:rt-r-pt-6 {
    padding-top: var(--space-6);
  }

  .xl\:rt-r-pt-7 {
    padding-top: var(--space-7);
  }

  .xl\:rt-r-pt-8 {
    padding-top: var(--space-8);
  }

  .xl\:rt-r-pt-9 {
    padding-top: var(--space-9);
  }

  .xl\:rt-r-pt-inset {
    padding-top: var(--inset-padding-top);
  }
}

.rt-r-pr {
  padding-right: var(--pr);
}

.rt-r-pr-0 {
  padding-right: 0;
}

.rt-r-pr-1 {
  padding-right: var(--space-1);
}

.rt-r-pr-2 {
  padding-right: var(--space-2);
}

.rt-r-pr-3 {
  padding-right: var(--space-3);
}

.rt-r-pr-4 {
  padding-right: var(--space-4);
}

.rt-r-pr-5 {
  padding-right: var(--space-5);
}

.rt-r-pr-6 {
  padding-right: var(--space-6);
}

.rt-r-pr-7 {
  padding-right: var(--space-7);
}

.rt-r-pr-8 {
  padding-right: var(--space-8);
}

.rt-r-pr-9 {
  padding-right: var(--space-9);
}

.rt-r-pr-inset {
  padding-right: var(--inset-padding-right);
}

@media (min-width: 520px) {
  .xs\:rt-r-pr {
    padding-right: var(--pr-xs);
  }

  .xs\:rt-r-pr-0 {
    padding-right: 0;
  }

  .xs\:rt-r-pr-1 {
    padding-right: var(--space-1);
  }

  .xs\:rt-r-pr-2 {
    padding-right: var(--space-2);
  }

  .xs\:rt-r-pr-3 {
    padding-right: var(--space-3);
  }

  .xs\:rt-r-pr-4 {
    padding-right: var(--space-4);
  }

  .xs\:rt-r-pr-5 {
    padding-right: var(--space-5);
  }

  .xs\:rt-r-pr-6 {
    padding-right: var(--space-6);
  }

  .xs\:rt-r-pr-7 {
    padding-right: var(--space-7);
  }

  .xs\:rt-r-pr-8 {
    padding-right: var(--space-8);
  }

  .xs\:rt-r-pr-9 {
    padding-right: var(--space-9);
  }

  .xs\:rt-r-pr-inset {
    padding-right: var(--inset-padding-right);
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-pr {
    padding-right: var(--pr-sm);
  }

  .sm\:rt-r-pr-0 {
    padding-right: 0;
  }

  .sm\:rt-r-pr-1 {
    padding-right: var(--space-1);
  }

  .sm\:rt-r-pr-2 {
    padding-right: var(--space-2);
  }

  .sm\:rt-r-pr-3 {
    padding-right: var(--space-3);
  }

  .sm\:rt-r-pr-4 {
    padding-right: var(--space-4);
  }

  .sm\:rt-r-pr-5 {
    padding-right: var(--space-5);
  }

  .sm\:rt-r-pr-6 {
    padding-right: var(--space-6);
  }

  .sm\:rt-r-pr-7 {
    padding-right: var(--space-7);
  }

  .sm\:rt-r-pr-8 {
    padding-right: var(--space-8);
  }

  .sm\:rt-r-pr-9 {
    padding-right: var(--space-9);
  }

  .sm\:rt-r-pr-inset {
    padding-right: var(--inset-padding-right);
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-pr {
    padding-right: var(--pr-md);
  }

  .md\:rt-r-pr-0 {
    padding-right: 0;
  }

  .md\:rt-r-pr-1 {
    padding-right: var(--space-1);
  }

  .md\:rt-r-pr-2 {
    padding-right: var(--space-2);
  }

  .md\:rt-r-pr-3 {
    padding-right: var(--space-3);
  }

  .md\:rt-r-pr-4 {
    padding-right: var(--space-4);
  }

  .md\:rt-r-pr-5 {
    padding-right: var(--space-5);
  }

  .md\:rt-r-pr-6 {
    padding-right: var(--space-6);
  }

  .md\:rt-r-pr-7 {
    padding-right: var(--space-7);
  }

  .md\:rt-r-pr-8 {
    padding-right: var(--space-8);
  }

  .md\:rt-r-pr-9 {
    padding-right: var(--space-9);
  }

  .md\:rt-r-pr-inset {
    padding-right: var(--inset-padding-right);
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-pr {
    padding-right: var(--pr-lg);
  }

  .lg\:rt-r-pr-0 {
    padding-right: 0;
  }

  .lg\:rt-r-pr-1 {
    padding-right: var(--space-1);
  }

  .lg\:rt-r-pr-2 {
    padding-right: var(--space-2);
  }

  .lg\:rt-r-pr-3 {
    padding-right: var(--space-3);
  }

  .lg\:rt-r-pr-4 {
    padding-right: var(--space-4);
  }

  .lg\:rt-r-pr-5 {
    padding-right: var(--space-5);
  }

  .lg\:rt-r-pr-6 {
    padding-right: var(--space-6);
  }

  .lg\:rt-r-pr-7 {
    padding-right: var(--space-7);
  }

  .lg\:rt-r-pr-8 {
    padding-right: var(--space-8);
  }

  .lg\:rt-r-pr-9 {
    padding-right: var(--space-9);
  }

  .lg\:rt-r-pr-inset {
    padding-right: var(--inset-padding-right);
  }
}

@media (min-width: 1640px) {
  .xl\:rt-r-pr {
    padding-right: var(--pr-xl);
  }

  .xl\:rt-r-pr-0 {
    padding-right: 0;
  }

  .xl\:rt-r-pr-1 {
    padding-right: var(--space-1);
  }

  .xl\:rt-r-pr-2 {
    padding-right: var(--space-2);
  }

  .xl\:rt-r-pr-3 {
    padding-right: var(--space-3);
  }

  .xl\:rt-r-pr-4 {
    padding-right: var(--space-4);
  }

  .xl\:rt-r-pr-5 {
    padding-right: var(--space-5);
  }

  .xl\:rt-r-pr-6 {
    padding-right: var(--space-6);
  }

  .xl\:rt-r-pr-7 {
    padding-right: var(--space-7);
  }

  .xl\:rt-r-pr-8 {
    padding-right: var(--space-8);
  }

  .xl\:rt-r-pr-9 {
    padding-right: var(--space-9);
  }

  .xl\:rt-r-pr-inset {
    padding-right: var(--inset-padding-right);
  }
}

.rt-r-pb {
  padding-bottom: var(--pb);
}

.rt-r-pb-0 {
  padding-bottom: 0;
}

.rt-r-pb-1 {
  padding-bottom: var(--space-1);
}

.rt-r-pb-2 {
  padding-bottom: var(--space-2);
}

.rt-r-pb-3 {
  padding-bottom: var(--space-3);
}

.rt-r-pb-4 {
  padding-bottom: var(--space-4);
}

.rt-r-pb-5 {
  padding-bottom: var(--space-5);
}

.rt-r-pb-6 {
  padding-bottom: var(--space-6);
}

.rt-r-pb-7 {
  padding-bottom: var(--space-7);
}

.rt-r-pb-8 {
  padding-bottom: var(--space-8);
}

.rt-r-pb-9 {
  padding-bottom: var(--space-9);
}

.rt-r-pb-inset {
  padding-bottom: var(--inset-padding-bottom);
}

@media (min-width: 520px) {
  .xs\:rt-r-pb {
    padding-bottom: var(--pb-xs);
  }

  .xs\:rt-r-pb-0 {
    padding-bottom: 0;
  }

  .xs\:rt-r-pb-1 {
    padding-bottom: var(--space-1);
  }

  .xs\:rt-r-pb-2 {
    padding-bottom: var(--space-2);
  }

  .xs\:rt-r-pb-3 {
    padding-bottom: var(--space-3);
  }

  .xs\:rt-r-pb-4 {
    padding-bottom: var(--space-4);
  }

  .xs\:rt-r-pb-5 {
    padding-bottom: var(--space-5);
  }

  .xs\:rt-r-pb-6 {
    padding-bottom: var(--space-6);
  }

  .xs\:rt-r-pb-7 {
    padding-bottom: var(--space-7);
  }

  .xs\:rt-r-pb-8 {
    padding-bottom: var(--space-8);
  }

  .xs\:rt-r-pb-9 {
    padding-bottom: var(--space-9);
  }

  .xs\:rt-r-pb-inset {
    padding-bottom: var(--inset-padding-bottom);
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-pb {
    padding-bottom: var(--pb-sm);
  }

  .sm\:rt-r-pb-0 {
    padding-bottom: 0;
  }

  .sm\:rt-r-pb-1 {
    padding-bottom: var(--space-1);
  }

  .sm\:rt-r-pb-2 {
    padding-bottom: var(--space-2);
  }

  .sm\:rt-r-pb-3 {
    padding-bottom: var(--space-3);
  }

  .sm\:rt-r-pb-4 {
    padding-bottom: var(--space-4);
  }

  .sm\:rt-r-pb-5 {
    padding-bottom: var(--space-5);
  }

  .sm\:rt-r-pb-6 {
    padding-bottom: var(--space-6);
  }

  .sm\:rt-r-pb-7 {
    padding-bottom: var(--space-7);
  }

  .sm\:rt-r-pb-8 {
    padding-bottom: var(--space-8);
  }

  .sm\:rt-r-pb-9 {
    padding-bottom: var(--space-9);
  }

  .sm\:rt-r-pb-inset {
    padding-bottom: var(--inset-padding-bottom);
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-pb {
    padding-bottom: var(--pb-md);
  }

  .md\:rt-r-pb-0 {
    padding-bottom: 0;
  }

  .md\:rt-r-pb-1 {
    padding-bottom: var(--space-1);
  }

  .md\:rt-r-pb-2 {
    padding-bottom: var(--space-2);
  }

  .md\:rt-r-pb-3 {
    padding-bottom: var(--space-3);
  }

  .md\:rt-r-pb-4 {
    padding-bottom: var(--space-4);
  }

  .md\:rt-r-pb-5 {
    padding-bottom: var(--space-5);
  }

  .md\:rt-r-pb-6 {
    padding-bottom: var(--space-6);
  }

  .md\:rt-r-pb-7 {
    padding-bottom: var(--space-7);
  }

  .md\:rt-r-pb-8 {
    padding-bottom: var(--space-8);
  }

  .md\:rt-r-pb-9 {
    padding-bottom: var(--space-9);
  }

  .md\:rt-r-pb-inset {
    padding-bottom: var(--inset-padding-bottom);
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-pb {
    padding-bottom: var(--pb-lg);
  }

  .lg\:rt-r-pb-0 {
    padding-bottom: 0;
  }

  .lg\:rt-r-pb-1 {
    padding-bottom: var(--space-1);
  }

  .lg\:rt-r-pb-2 {
    padding-bottom: var(--space-2);
  }

  .lg\:rt-r-pb-3 {
    padding-bottom: var(--space-3);
  }

  .lg\:rt-r-pb-4 {
    padding-bottom: var(--space-4);
  }

  .lg\:rt-r-pb-5 {
    padding-bottom: var(--space-5);
  }

  .lg\:rt-r-pb-6 {
    padding-bottom: var(--space-6);
  }

  .lg\:rt-r-pb-7 {
    padding-bottom: var(--space-7);
  }

  .lg\:rt-r-pb-8 {
    padding-bottom: var(--space-8);
  }

  .lg\:rt-r-pb-9 {
    padding-bottom: var(--space-9);
  }

  .lg\:rt-r-pb-inset {
    padding-bottom: var(--inset-padding-bottom);
  }
}

@media (min-width: 1640px) {
  .xl\:rt-r-pb {
    padding-bottom: var(--pb-xl);
  }

  .xl\:rt-r-pb-0 {
    padding-bottom: 0;
  }

  .xl\:rt-r-pb-1 {
    padding-bottom: var(--space-1);
  }

  .xl\:rt-r-pb-2 {
    padding-bottom: var(--space-2);
  }

  .xl\:rt-r-pb-3 {
    padding-bottom: var(--space-3);
  }

  .xl\:rt-r-pb-4 {
    padding-bottom: var(--space-4);
  }

  .xl\:rt-r-pb-5 {
    padding-bottom: var(--space-5);
  }

  .xl\:rt-r-pb-6 {
    padding-bottom: var(--space-6);
  }

  .xl\:rt-r-pb-7 {
    padding-bottom: var(--space-7);
  }

  .xl\:rt-r-pb-8 {
    padding-bottom: var(--space-8);
  }

  .xl\:rt-r-pb-9 {
    padding-bottom: var(--space-9);
  }

  .xl\:rt-r-pb-inset {
    padding-bottom: var(--inset-padding-bottom);
  }
}

.rt-r-pl {
  padding-left: var(--pl);
}

.rt-r-pl-0 {
  padding-left: 0;
}

.rt-r-pl-1 {
  padding-left: var(--space-1);
}

.rt-r-pl-2 {
  padding-left: var(--space-2);
}

.rt-r-pl-3 {
  padding-left: var(--space-3);
}

.rt-r-pl-4 {
  padding-left: var(--space-4);
}

.rt-r-pl-5 {
  padding-left: var(--space-5);
}

.rt-r-pl-6 {
  padding-left: var(--space-6);
}

.rt-r-pl-7 {
  padding-left: var(--space-7);
}

.rt-r-pl-8 {
  padding-left: var(--space-8);
}

.rt-r-pl-9 {
  padding-left: var(--space-9);
}

.rt-r-pl-inset {
  padding-left: var(--inset-padding-left);
}

@media (min-width: 520px) {
  .xs\:rt-r-pl {
    padding-left: var(--pl-xs);
  }

  .xs\:rt-r-pl-0 {
    padding-left: 0;
  }

  .xs\:rt-r-pl-1 {
    padding-left: var(--space-1);
  }

  .xs\:rt-r-pl-2 {
    padding-left: var(--space-2);
  }

  .xs\:rt-r-pl-3 {
    padding-left: var(--space-3);
  }

  .xs\:rt-r-pl-4 {
    padding-left: var(--space-4);
  }

  .xs\:rt-r-pl-5 {
    padding-left: var(--space-5);
  }

  .xs\:rt-r-pl-6 {
    padding-left: var(--space-6);
  }

  .xs\:rt-r-pl-7 {
    padding-left: var(--space-7);
  }

  .xs\:rt-r-pl-8 {
    padding-left: var(--space-8);
  }

  .xs\:rt-r-pl-9 {
    padding-left: var(--space-9);
  }

  .xs\:rt-r-pl-inset {
    padding-left: var(--inset-padding-left);
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-pl {
    padding-left: var(--pl-sm);
  }

  .sm\:rt-r-pl-0 {
    padding-left: 0;
  }

  .sm\:rt-r-pl-1 {
    padding-left: var(--space-1);
  }

  .sm\:rt-r-pl-2 {
    padding-left: var(--space-2);
  }

  .sm\:rt-r-pl-3 {
    padding-left: var(--space-3);
  }

  .sm\:rt-r-pl-4 {
    padding-left: var(--space-4);
  }

  .sm\:rt-r-pl-5 {
    padding-left: var(--space-5);
  }

  .sm\:rt-r-pl-6 {
    padding-left: var(--space-6);
  }

  .sm\:rt-r-pl-7 {
    padding-left: var(--space-7);
  }

  .sm\:rt-r-pl-8 {
    padding-left: var(--space-8);
  }

  .sm\:rt-r-pl-9 {
    padding-left: var(--space-9);
  }

  .sm\:rt-r-pl-inset {
    padding-left: var(--inset-padding-left);
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-pl {
    padding-left: var(--pl-md);
  }

  .md\:rt-r-pl-0 {
    padding-left: 0;
  }

  .md\:rt-r-pl-1 {
    padding-left: var(--space-1);
  }

  .md\:rt-r-pl-2 {
    padding-left: var(--space-2);
  }

  .md\:rt-r-pl-3 {
    padding-left: var(--space-3);
  }

  .md\:rt-r-pl-4 {
    padding-left: var(--space-4);
  }

  .md\:rt-r-pl-5 {
    padding-left: var(--space-5);
  }

  .md\:rt-r-pl-6 {
    padding-left: var(--space-6);
  }

  .md\:rt-r-pl-7 {
    padding-left: var(--space-7);
  }

  .md\:rt-r-pl-8 {
    padding-left: var(--space-8);
  }

  .md\:rt-r-pl-9 {
    padding-left: var(--space-9);
  }

  .md\:rt-r-pl-inset {
    padding-left: var(--inset-padding-left);
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-pl {
    padding-left: var(--pl-lg);
  }

  .lg\:rt-r-pl-0 {
    padding-left: 0;
  }

  .lg\:rt-r-pl-1 {
    padding-left: var(--space-1);
  }

  .lg\:rt-r-pl-2 {
    padding-left: var(--space-2);
  }

  .lg\:rt-r-pl-3 {
    padding-left: var(--space-3);
  }

  .lg\:rt-r-pl-4 {
    padding-left: var(--space-4);
  }

  .lg\:rt-r-pl-5 {
    padding-left: var(--space-5);
  }

  .lg\:rt-r-pl-6 {
    padding-left: var(--space-6);
  }

  .lg\:rt-r-pl-7 {
    padding-left: var(--space-7);
  }

  .lg\:rt-r-pl-8 {
    padding-left: var(--space-8);
  }

  .lg\:rt-r-pl-9 {
    padding-left: var(--space-9);
  }

  .lg\:rt-r-pl-inset {
    padding-left: var(--inset-padding-left);
  }
}

@media (min-width: 1640px) {
  .xl\:rt-r-pl {
    padding-left: var(--pl-xl);
  }

  .xl\:rt-r-pl-0 {
    padding-left: 0;
  }

  .xl\:rt-r-pl-1 {
    padding-left: var(--space-1);
  }

  .xl\:rt-r-pl-2 {
    padding-left: var(--space-2);
  }

  .xl\:rt-r-pl-3 {
    padding-left: var(--space-3);
  }

  .xl\:rt-r-pl-4 {
    padding-left: var(--space-4);
  }

  .xl\:rt-r-pl-5 {
    padding-left: var(--space-5);
  }

  .xl\:rt-r-pl-6 {
    padding-left: var(--space-6);
  }

  .xl\:rt-r-pl-7 {
    padding-left: var(--space-7);
  }

  .xl\:rt-r-pl-8 {
    padding-left: var(--space-8);
  }

  .xl\:rt-r-pl-9 {
    padding-left: var(--space-9);
  }

  .xl\:rt-r-pl-inset {
    padding-left: var(--inset-padding-left);
  }
}

.rt-r-position-static {
  position: static;
}

.rt-r-position-absolute {
  position: absolute;
}

.rt-r-position-relative {
  position: relative;
}

.rt-r-position-fixed {
  position: fixed;
}

.rt-r-position-sticky {
  position: -webkit-sticky;
  position: sticky;
}

@media (min-width: 520px) {
  .xs\:rt-r-position-static {
    position: static;
  }

  .xs\:rt-r-position-absolute {
    position: absolute;
  }

  .xs\:rt-r-position-relative {
    position: relative;
  }

  .xs\:rt-r-position-fixed {
    position: fixed;
  }

  .xs\:rt-r-position-sticky {
    position: -webkit-sticky;
    position: sticky;
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-position-static {
    position: static;
  }

  .sm\:rt-r-position-absolute {
    position: absolute;
  }

  .sm\:rt-r-position-relative {
    position: relative;
  }

  .sm\:rt-r-position-fixed {
    position: fixed;
  }

  .sm\:rt-r-position-sticky {
    position: -webkit-sticky;
    position: sticky;
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-position-static {
    position: static;
  }

  .md\:rt-r-position-absolute {
    position: absolute;
  }

  .md\:rt-r-position-relative {
    position: relative;
  }

  .md\:rt-r-position-fixed {
    position: fixed;
  }

  .md\:rt-r-position-sticky {
    position: -webkit-sticky;
    position: sticky;
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-position-static {
    position: static;
  }

  .lg\:rt-r-position-absolute {
    position: absolute;
  }

  .lg\:rt-r-position-relative {
    position: relative;
  }

  .lg\:rt-r-position-fixed {
    position: fixed;
  }

  .lg\:rt-r-position-sticky {
    position: -webkit-sticky;
    position: sticky;
  }
}

@media (min-width: 1640px) {
  .xl\:rt-r-position-static {
    position: static;
  }

  .xl\:rt-r-position-absolute {
    position: absolute;
  }

  .xl\:rt-r-position-relative {
    position: relative;
  }

  .xl\:rt-r-position-fixed {
    position: fixed;
  }

  .xl\:rt-r-position-sticky {
    position: -webkit-sticky;
    position: sticky;
  }
}

.rt-r-w {
  width: var(--width);
}

@media (min-width: 520px) {
  .xs\:rt-r-w {
    width: var(--width-xs);
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-w {
    width: var(--width-sm);
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-w {
    width: var(--width-md);
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-w {
    width: var(--width-lg);
  }
}

@media (min-width: 1640px) {
  .xl\:rt-r-w {
    width: var(--width-xl);
  }
}

.rt-r-min-w {
  min-width: var(--min-width);
}

@media (min-width: 520px) {
  .xs\:rt-r-min-w {
    min-width: var(--min-width-xs);
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-min-w {
    min-width: var(--min-width-sm);
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-min-w {
    min-width: var(--min-width-md);
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-min-w {
    min-width: var(--min-width-lg);
  }
}

@media (min-width: 1640px) {
  .xl\:rt-r-min-w {
    min-width: var(--min-width-xl);
  }
}

.rt-r-max-w {
  max-width: var(--max-width);
}

@media (min-width: 520px) {
  .xs\:rt-r-max-w {
    max-width: var(--max-width-xs);
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-max-w {
    max-width: var(--max-width-sm);
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-max-w {
    max-width: var(--max-width-md);
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-max-w {
    max-width: var(--max-width-lg);
  }
}

@media (min-width: 1640px) {
  .xl\:rt-r-max-w {
    max-width: var(--max-width-xl);
  }
}

.rt-r-weight-light {
  font-weight: var(--font-weight-light);
}

.rt-r-weight-regular {
  font-weight: var(--font-weight-regular);
}

.rt-r-weight-medium {
  font-weight: var(--font-weight-medium);
}

.rt-r-weight-bold {
  font-weight: var(--font-weight-bold);
}

@media (min-width: 520px) {
  .xs\:rt-r-weight-light {
    font-weight: var(--font-weight-light);
  }

  .xs\:rt-r-weight-regular {
    font-weight: var(--font-weight-regular);
  }

  .xs\:rt-r-weight-medium {
    font-weight: var(--font-weight-medium);
  }

  .xs\:rt-r-weight-bold {
    font-weight: var(--font-weight-bold);
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-weight-light {
    font-weight: var(--font-weight-light);
  }

  .sm\:rt-r-weight-regular {
    font-weight: var(--font-weight-regular);
  }

  .sm\:rt-r-weight-medium {
    font-weight: var(--font-weight-medium);
  }

  .sm\:rt-r-weight-bold {
    font-weight: var(--font-weight-bold);
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-weight-light {
    font-weight: var(--font-weight-light);
  }

  .md\:rt-r-weight-regular {
    font-weight: var(--font-weight-regular);
  }

  .md\:rt-r-weight-medium {
    font-weight: var(--font-weight-medium);
  }

  .md\:rt-r-weight-bold {
    font-weight: var(--font-weight-bold);
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-weight-light {
    font-weight: var(--font-weight-light);
  }

  .lg\:rt-r-weight-regular {
    font-weight: var(--font-weight-regular);
  }

  .lg\:rt-r-weight-medium {
    font-weight: var(--font-weight-medium);
  }

  .lg\:rt-r-weight-bold {
    font-weight: var(--font-weight-bold);
  }
}

@media (min-width: 1640px) {
  .xl\:rt-r-weight-light {
    font-weight: var(--font-weight-light);
  }

  .xl\:rt-r-weight-regular {
    font-weight: var(--font-weight-regular);
  }

  .xl\:rt-r-weight-medium {
    font-weight: var(--font-weight-medium);
  }

  .xl\:rt-r-weight-bold {
    font-weight: var(--font-weight-bold);
  }
}

.rt-r-lt-normal:before, .rt-r-lt-end:before, .rt-r-lt-normal:after, .rt-r-lt-start:after {
  content: none;
}

.rt-r-lt-start:before, .rt-r-lt-both:before, .rt-r-lt-end:after, .rt-r-lt-both:after {
  content: "";
  display: table;
}

.rt-r-lt-start:before, .rt-r-lt-both:before {
  margin-bottom: calc(var(--leading-trim-start, var(--default-leading-trim-start))  - var(--line-height, calc(1em * var(--default-line-height))) / 2);
}

.rt-r-lt-end:after, .rt-r-lt-both:after {
  margin-top: calc(var(--leading-trim-end, var(--default-leading-trim-end))  - var(--line-height, calc(1em * var(--default-line-height))) / 2);
}

@media (min-width: 520px) {
  .xs\:rt-r-lt-normal:before, .xs\:rt-r-lt-end:before, .xs\:rt-r-lt-normal:after, .xs\:rt-r-lt-start:after {
    content: none;
  }

  .xs\:rt-r-lt-start:before, .xs\:rt-r-lt-both:before, .xs\:rt-r-lt-end:after, .xs\:rt-r-lt-both:after {
    content: "";
    display: table;
  }

  .xs\:rt-r-lt-start:before, .xs\:rt-r-lt-both:before {
    margin-bottom: calc(var(--leading-trim-start, var(--default-leading-trim-start))  - var(--line-height, calc(1em * var(--default-line-height))) / 2);
  }

  .xs\:rt-r-lt-end:after, .xs\:rt-r-lt-both:after {
    margin-top: calc(var(--leading-trim-end, var(--default-leading-trim-end))  - var(--line-height, calc(1em * var(--default-line-height))) / 2);
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-lt-normal:before, .sm\:rt-r-lt-end:before, .sm\:rt-r-lt-normal:after, .sm\:rt-r-lt-start:after {
    content: none;
  }

  .sm\:rt-r-lt-start:before, .sm\:rt-r-lt-both:before, .sm\:rt-r-lt-end:after, .sm\:rt-r-lt-both:after {
    content: "";
    display: table;
  }

  .sm\:rt-r-lt-start:before, .sm\:rt-r-lt-both:before {
    margin-bottom: calc(var(--leading-trim-start, var(--default-leading-trim-start))  - var(--line-height, calc(1em * var(--default-line-height))) / 2);
  }

  .sm\:rt-r-lt-end:after, .sm\:rt-r-lt-both:after {
    margin-top: calc(var(--leading-trim-end, var(--default-leading-trim-end))  - var(--line-height, calc(1em * var(--default-line-height))) / 2);
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-lt-normal:before, .md\:rt-r-lt-end:before, .md\:rt-r-lt-normal:after, .md\:rt-r-lt-start:after {
    content: none;
  }

  .md\:rt-r-lt-start:before, .md\:rt-r-lt-both:before, .md\:rt-r-lt-end:after, .md\:rt-r-lt-both:after {
    content: "";
    display: table;
  }

  .md\:rt-r-lt-start:before, .md\:rt-r-lt-both:before {
    margin-bottom: calc(var(--leading-trim-start, var(--default-leading-trim-start))  - var(--line-height, calc(1em * var(--default-line-height))) / 2);
  }

  .md\:rt-r-lt-end:after, .md\:rt-r-lt-both:after {
    margin-top: calc(var(--leading-trim-end, var(--default-leading-trim-end))  - var(--line-height, calc(1em * var(--default-line-height))) / 2);
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-lt-normal:before, .lg\:rt-r-lt-end:before, .lg\:rt-r-lt-normal:after, .lg\:rt-r-lt-start:after {
    content: none;
  }

  .lg\:rt-r-lt-start:before, .lg\:rt-r-lt-both:before, .lg\:rt-r-lt-end:after, .lg\:rt-r-lt-both:after {
    content: "";
    display: table;
  }

  .lg\:rt-r-lt-start:before, .lg\:rt-r-lt-both:before {
    margin-bottom: calc(var(--leading-trim-start, var(--default-leading-trim-start))  - var(--line-height, calc(1em * var(--default-line-height))) / 2);
  }

  .lg\:rt-r-lt-end:after, .lg\:rt-r-lt-both:after {
    margin-top: calc(var(--leading-trim-end, var(--default-leading-trim-end))  - var(--line-height, calc(1em * var(--default-line-height))) / 2);
  }
}

@media (min-width: 1640px) {
  .xl\:rt-r-lt-normal:before, .xl\:rt-r-lt-end:before, .xl\:rt-r-lt-normal:after, .xl\:rt-r-lt-start:after {
    content: none;
  }

  .xl\:rt-r-lt-start:before, .xl\:rt-r-lt-both:before, .xl\:rt-r-lt-end:after, .xl\:rt-r-lt-both:after {
    content: "";
    display: table;
  }

  .xl\:rt-r-lt-start:before, .xl\:rt-r-lt-both:before {
    margin-bottom: calc(var(--leading-trim-start, var(--default-leading-trim-start))  - var(--line-height, calc(1em * var(--default-line-height))) / 2);
  }

  .xl\:rt-r-lt-end:after, .xl\:rt-r-lt-both:after {
    margin-top: calc(var(--leading-trim-end, var(--default-leading-trim-end))  - var(--line-height, calc(1em * var(--default-line-height))) / 2);
  }
}

.rt-r-resize-none {
  resize: none;
}

.rt-r-resize-vertical {
  resize: vertical;
}

.rt-r-resize-horizontal {
  resize: horizontal;
}

.rt-r-resize-both {
  resize: both;
}

@media (min-width: 520px) {
  .xs\:rt-r-resize-none {
    resize: none;
  }

  .xs\:rt-r-resize-vertical {
    resize: vertical;
  }

  .xs\:rt-r-resize-horizontal {
    resize: horizontal;
  }

  .xs\:rt-r-resize-both {
    resize: both;
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-resize-none {
    resize: none;
  }

  .sm\:rt-r-resize-vertical {
    resize: vertical;
  }

  .sm\:rt-r-resize-horizontal {
    resize: horizontal;
  }

  .sm\:rt-r-resize-both {
    resize: both;
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-resize-none {
    resize: none;
  }

  .md\:rt-r-resize-vertical {
    resize: vertical;
  }

  .md\:rt-r-resize-horizontal {
    resize: horizontal;
  }

  .md\:rt-r-resize-both {
    resize: both;
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-resize-none {
    resize: none;
  }

  .lg\:rt-r-resize-vertical {
    resize: vertical;
  }

  .lg\:rt-r-resize-horizontal {
    resize: horizontal;
  }

  .lg\:rt-r-resize-both {
    resize: both;
  }
}

@media (min-width: 1640px) {
  .xl\:rt-r-resize-none {
    resize: none;
  }

  .xl\:rt-r-resize-vertical {
    resize: vertical;
  }

  .xl\:rt-r-resize-horizontal {
    resize: horizontal;
  }

  .xl\:rt-r-resize-both {
    resize: both;
  }
}

.rt-r-tl-auto {
  table-layout: auto;
}

.rt-r-tl-fixed {
  table-layout: fixed;
}

@media (min-width: 520px) {
  .xs\:rt-r-tl-auto {
    table-layout: auto;
  }

  .xs\:rt-r-tl-fixed {
    table-layout: fixed;
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-tl-auto {
    table-layout: auto;
  }

  .sm\:rt-r-tl-fixed {
    table-layout: fixed;
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-tl-auto {
    table-layout: auto;
  }

  .md\:rt-r-tl-fixed {
    table-layout: fixed;
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-tl-auto {
    table-layout: auto;
  }

  .lg\:rt-r-tl-fixed {
    table-layout: fixed;
  }
}

@media (min-width: 1640px) {
  .xl\:rt-r-tl-auto {
    table-layout: auto;
  }

  .xl\:rt-r-tl-fixed {
    table-layout: fixed;
  }
}

.rt-r-ta-left {
  text-align: left;
}

.rt-r-ta-center {
  text-align: center;
}

.rt-r-ta-right {
  text-align: right;
}

@media (min-width: 520px) {
  .xs\:rt-r-ta-left {
    text-align: left;
  }

  .xs\:rt-r-ta-center {
    text-align: center;
  }

  .xs\:rt-r-ta-right {
    text-align: right;
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-ta-left {
    text-align: left;
  }

  .sm\:rt-r-ta-center {
    text-align: center;
  }

  .sm\:rt-r-ta-right {
    text-align: right;
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-ta-left {
    text-align: left;
  }

  .md\:rt-r-ta-center {
    text-align: center;
  }

  .md\:rt-r-ta-right {
    text-align: right;
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-ta-left {
    text-align: left;
  }

  .lg\:rt-r-ta-center {
    text-align: center;
  }

  .lg\:rt-r-ta-right {
    text-align: right;
  }
}

@media (min-width: 1640px) {
  .xl\:rt-r-ta-left {
    text-align: left;
  }

  .xl\:rt-r-ta-center {
    text-align: center;
  }

  .xl\:rt-r-ta-right {
    text-align: right;
  }
}

.rt-r-tw-wrap {
  white-space: normal;
}

.rt-r-tw-nowrap {
  white-space: nowrap;
}

.rt-r-tw-pretty {
  white-space: normal;
  text-wrap: pretty;
}

.rt-r-tw-balance {
  white-space: normal;
  text-wrap: balance;
}

@media (min-width: 520px) {
  .xs\:rt-r-tw-wrap {
    white-space: normal;
  }

  .xs\:rt-r-tw-nowrap {
    white-space: nowrap;
  }

  .xs\:rt-r-tw-pretty {
    white-space: normal;
    text-wrap: pretty;
  }

  .xs\:rt-r-tw-balance {
    white-space: normal;
    text-wrap: balance;
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-tw-wrap {
    white-space: normal;
  }

  .sm\:rt-r-tw-nowrap {
    white-space: nowrap;
  }

  .sm\:rt-r-tw-pretty {
    white-space: normal;
    text-wrap: pretty;
  }

  .sm\:rt-r-tw-balance {
    white-space: normal;
    text-wrap: balance;
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-tw-wrap {
    white-space: normal;
  }

  .md\:rt-r-tw-nowrap {
    white-space: nowrap;
  }

  .md\:rt-r-tw-pretty {
    white-space: normal;
    text-wrap: pretty;
  }

  .md\:rt-r-tw-balance {
    white-space: normal;
    text-wrap: balance;
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-tw-wrap {
    white-space: normal;
  }

  .lg\:rt-r-tw-nowrap {
    white-space: nowrap;
  }

  .lg\:rt-r-tw-pretty {
    white-space: normal;
    text-wrap: pretty;
  }

  .lg\:rt-r-tw-balance {
    white-space: normal;
    text-wrap: balance;
  }
}

@media (min-width: 1640px) {
  .xl\:rt-r-tw-wrap {
    white-space: normal;
  }

  .xl\:rt-r-tw-nowrap {
    white-space: nowrap;
  }

  .xl\:rt-r-tw-pretty {
    white-space: normal;
    text-wrap: pretty;
  }

  .xl\:rt-r-tw-balance {
    white-space: normal;
    text-wrap: balance;
  }
}

.rt-truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.rt-r-va-baseline {
  vertical-align: baseline;
}

.rt-r-va-top {
  vertical-align: top;
}

.rt-r-va-middle {
  vertical-align: middle;
}

.rt-r-va-bottom {
  vertical-align: bottom;
}

@media (min-width: 520px) {
  .xs\:rt-r-va-baseline {
    vertical-align: baseline;
  }

  .xs\:rt-r-va-top {
    vertical-align: top;
  }

  .xs\:rt-r-va-middle {
    vertical-align: middle;
  }

  .xs\:rt-r-va-bottom {
    vertical-align: bottom;
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-va-baseline {
    vertical-align: baseline;
  }

  .sm\:rt-r-va-top {
    vertical-align: top;
  }

  .sm\:rt-r-va-middle {
    vertical-align: middle;
  }

  .sm\:rt-r-va-bottom {
    vertical-align: bottom;
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-va-baseline {
    vertical-align: baseline;
  }

  .md\:rt-r-va-top {
    vertical-align: top;
  }

  .md\:rt-r-va-middle {
    vertical-align: middle;
  }

  .md\:rt-r-va-bottom {
    vertical-align: bottom;
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-va-baseline {
    vertical-align: baseline;
  }

  .lg\:rt-r-va-top {
    vertical-align: top;
  }

  .lg\:rt-r-va-middle {
    vertical-align: middle;
  }

  .lg\:rt-r-va-bottom {
    vertical-align: bottom;
  }
}

@media (min-width: 1640px) {
  .xl\:rt-r-va-baseline {
    vertical-align: baseline;
  }

  .xl\:rt-r-va-top {
    vertical-align: top;
  }

  .xl\:rt-r-va-middle {
    vertical-align: middle;
  }

  .xl\:rt-r-va-bottom {
    vertical-align: bottom;
  }
}

